/** 高德地址数据 */
export type GaodeAddrDataType = {
  location: {
    lng: number;
    lat: number;
  };
  address: string;
  name: string; // 名称
  pname: string; // 省
  cityname: string; // 市
  adname: string; // 区
  /**
   * 行政区划
   * 看起来不能用直接用，获取到的citycode 杭州是: 0571
   * 拿adcode来拆开用
   */
  // pcode: string; // 行政区划-省
  // citycode: string; // 行政区划-市
  adcode: string; // 行政区划-区
};

export const converGaodeDataToSysLocationData = (data: GaodeAddrDataType): SysLocationItemType => {
  const distCode = data.adcode;
  const provCode = distCode.substring(0, 2) + '0000';
  const cityCode = distCode.substring(0, 4) + '00';

  return {
    /** 经度 */
    lng: data.location.lng,
    /** 纬度 */
    lat: data.location.lat,
    /** 名称 */
    name: data.name,
    /** 地址 */
    add: data.address,
    /** 省 */
    prov: data.pname,
    /** 市 */
    city: data.cityname,
    /** 区 */
    dist: data.adname,
    /** 行政区划 */
    provCode: provCode,
    cityCode: cityCode,
    distCode: distCode,
  };
};

export const converSysLocationDataToGaodeData = (data: SysLocationItemType): GaodeAddrDataType => {
  return {
    location: {
      lng: data.lng,
      lat: data.lat,
    },
    address: data.add,
    name: data.name,
    pname: data.prov,
    cityname: data.city,
    adname: data.dist,
    adcode: data.distCode,
  };
};
// 经纬度以及名称一致
export const isEqualPoint = (
  nodeLocation: SysLocationItemType | null,
  gaodeAddr: GaodeAddrDataType,
) => {
  if (!nodeLocation || !gaodeAddr) return false;
  return (
    nodeLocation.lng === gaodeAddr.location.lng &&
    nodeLocation.lat === gaodeAddr.location.lat &&
    nodeLocation.name === gaodeAddr.name
  );
};
