import { useControllableValue } from 'ahooks';
import { Popover } from 'antd';
import cls from 'classnames';
import type { FC } from 'react';
import type { Color, SketchPickerProps } from 'react-color';
import { SketchPicker } from 'react-color';
import { match, P } from 'ts-pattern';

import styles from './styles.less';

export type ColorPickerProp = {
  value?: SketchPickerProps['color'];
  defaultValue?: SketchPickerProps['color'];
  onChange?: (c: Color) => void;
  className?: string;
  style?: React.CSSProperties;
  itemRender?: (c?: string) => React.ReactNode;
};

export const ColorPicker: FC<ColorPickerProp> = (props) => {
  const [value, onChange] = useControllableValue(props) as [Color | undefined, (c: Color) => void];
  const content = (
    <SketchPicker
      presetColors={[
        'rgba(0,0,0,0)',
        '#f5222d',
        '#fa541c',
        '#fa8c16',
        '#faad14',
        '#fadb14',
        '#a0d911',
        '#52c41a',
        '#13c2c2',
        '#1677ff',
        '#2f54eb',
        '#722ed1',
        '#eb2f96',
        '#fff',
        '#000',
      ]}
      className={styles.picker}
      color={value === 'none' ? 'rgba(0,0,0,0)' : value}
      onChange={({ rgb: { r, g, b, a } }) => onChange(`rgba(${r},${g},${b},${a})`)}
    />
  );

  const renderValue = match(value)
    .with({ b: P.number }, (v) => {
      const { r, g, b, a } = v;
      return `rgba(${r},${g},${b},${a})`;
    })
    .with({ h: P.number }, (v) => {
      const { h, s, l, a } = v;
      return `hsla(${h},${s}%,${l}%,${a})`;
    })
    .with(P.string, (v) => v)
    .with(P.nullish, () => '')
    .exhaustive();

  return (
    <Popover content={content} trigger="click">
      {props.itemRender?.(renderValue) ?? (
        <span
          className={cls(props.className, styles.content)}
          style={{
            ...props.style,
            background: `url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAADFJREFUOE9jZGBgEGHAD97gk2YcNYBhmIQBgWSAP52AwoAQwJvQRg1gACckQoC2gQgAIF8IscwEtKYAAAAASUVORK5CYII=') left center`,
          }}
        >
          <span
            style={{
              background: value?.toString(),
              display: 'inline-flex',
              height: '100%',
              width: '100%',
            }}
          />
        </span>
      )}
    </Popover>
  );
};
