export const btnConfig: any = {
  text: {
    large: 14,
    small: 14,
    middle: 14,
  },
  rectangle: {
    large: { height: 40, padding: 16 },
    small: { height: 20, padding: 4 },
    middle: { height: 28, padding: 8 },
  },
  square: {
    large: 60,
    small: 60,
    middle: 60,
  },
  circle: {
    large: 60,
    small: 60,
    middle: 60,
  },
  picture: {
    max: 100,
  },
};

export const classToUnicode = {
  iconzianicon01: '&#xe784;',
  iconzianicon02: '&#xe787;',
  iconzianicon03: '&#xe78d;',
  iconzianicon04: '&#xe781;',
  iconzianicon05: '&#xe788;',
  iconzianicon06: '&#xe78a;',
  iconzianicon07: '&#xe78b;',
  iconzianicon08: '&#xe78f;',
  iconzianicon09: '&#xe782;',
  iconzianicon10: '&#xe77f;',
  iconzianicon11: '&#xe790;',
  iconzianicon12: '&#xe786;',
  iconzianicon13: '&#xe783;',
  iconzianicon14: '&#xe78c;',
  iconzianicon15: '&#xe780;',
  iconzianicon16: '&#xe785;',
  iconzianicon17: '&#xe791;',
  iconzianicon18: '&#xe78e;',
  iconzianicon19: '&#xe789;',
};
