import HeaderCellMore from '../components/headerCellMore';
import Log from '../helper/log';
import BaseModule from './_baseModule';
import type { HeaderCellType, RowType } from './dataManager';

interface rectType {
  x: number;
  y: number;
  width: number;
  height: number;
}

type pointType = [number, number];

export default class Draw extends BaseModule {
  // @ts-ignore
  private el: HTMLCanvasElement;
  // @ts-ignore
  public ctx: CanvasRenderingContext2D;

  public headerHeight = 44;
  public footerHeight = 44;
  public collapseRowTextGap = 20;
  public headerCellMoreWidth = 10;

  public selectRowBg = '#f0f6ff';
  public hoverRowBg = '#fafafa';
  public hoverHeaderCellBg = '#f0f0f0';
  public headerCellBg = '#f8f9fb';
  public footerCellBg = '#f8f9fb';
  public hoverCollapseGroupFunctionCellBg = '#f0f0f0';
  public hoverFooterFunctionCell = '#f0f0f0';

  public get dpr() {
    return window.devicePixelRatio || 1;
  }
  public get drawWidth() {
    return this.el.width;
  }
  public get drawHeight() {
    return this.el.height;
  }
  public get canvasWidth() {
    return Number(this.el.style.width.split('px')[0]);
  }
  public get canvasHeight() {
    return Number(this.el.style.height.split('px')[0]);
  }

  public npx(px: number) {
    return px * this.dpr;
  }

  public npxLine(px: number) {
    const n = this.npx(px);
    return n > 0 ? n - 0.5 : 0.5;
  }

  public prepare() {
    const { Render } = this.moduleInstances;

    this.el = Render.doms.canvas as HTMLCanvasElement;
    this.ctx = this.el.getContext('2d') as CanvasRenderingContext2D;
  }

  public resize(width: number, height: number) {
    const floorWidth = Math.floor(width);
    const floorHeight = Math.floor(height);
    this.el.style.width = `${floorWidth}px`;
    this.el.style.height = `${floorHeight}px`;

    this.el.width = this.npx(floorWidth);
    this.el.height = this.npx(floorHeight);

    return this;
  }

  public clear() {
    const { width, height } = this.el;
    this.ctx.clearRect(0, 0, width, height);
    return this;
  }

  public attr(options: any) {
    Object.assign(this.ctx, options);
    return this;
  }

  public save() {
    this.ctx.save();
    this.ctx.beginPath();
    return this;
  }

  public restore() {
    this.ctx.restore();
    return this;
  }

  public iconFont(unicode: string): string {
    if (unicode.indexOf('&amp;') !== -1) {
      unicode = unicode.replace('&amp;', '&');
    }
    if (unicode.indexOf('&amp') !== -1) {
      unicode = unicode.replace('&amp', '&');
    }
    const icon = eval('("' + `${unicode}`.replace('&#x', '\\u').replace(';', '') + '")');
    return icon;
  }

  public fillRect(x: number, y: number, w: number, h: number, fillColor: string = '#fff') {
    this.ctx.fillStyle = fillColor;
    this.ctx.fillRect(this.npx(x) - 0.5, this.npx(y) - 0.5, this.npx(w), this.npx(h));
    return this;
  }

  // 绘制icon
  public drawIcon(
    x: number,
    y: number,
    color: string,
    unicode: string,
    fontSize: number,
    textBaseline?: string,
    textAlign?: string,
  ) {
    const { ctx } = this;

    ctx.save();
    this.attr({
      fillStyle: color,
      textBaseline: textBaseline || 'middle',
      textAlign: textAlign || 'start',
      font: `${this.npx(fontSize)}px iconfont`,
    });

    this.fillText(this.iconFont(unicode), x, y);
    ctx.restore();
  }

  /**
   * clipX 源图片从x轴哪里开始裁剪
   * clipY 从y轴哪里开始裁剪
   * clipHei 裁剪的高度
   * clipWid 裁剪的宽度
   */
  public drawRoundImg(
    r: number,
    x: number,
    y: number,
    w: number,
    h: number,
    img: any,
    clipX?: number,
    clipY?: number,
    clipHei?: number,
    clipWid?: number,
  ) {
    const { ctx } = this;
    r = this.npx(r);
    x = this.npx(x);
    y = this.npx(y);
    w = this.npx(w);
    h = this.npx(h);

    ctx.save();
    if (w < 2 * r) r = w / 2;
    if (h < 2 * r) r = h / 2;
    ctx.beginPath();
    ctx.moveTo(x + r, y);
    ctx.arcTo(x + w, y, x + w, y + h, r);
    ctx.arcTo(x + w, y + h, x, y + h, r);
    ctx.arcTo(x, y + h, x, y, r);
    ctx.arcTo(x, y, x + w, y, r);
    ctx.closePath();
    ctx.clip();
    if (clipX !== undefined) ctx.drawImage(img, clipX, clipY, clipWid, clipHei, x, y, w, h);
    else ctx.drawImage(img, x, y, w, h);
    ctx.restore();
  }

  public fillText(text: string, x: number, y: number, maxWidth?: number) {
    this.ctx.fillText(text, this.npx(x), this.npx(y), maxWidth ? this.npx(maxWidth) : undefined);
    return this;
  }

  public line(points: pointType[], strokeColor: string = '#f6f6f6', lineWidth: number = 1) {
    if (points.length < 2) {
      Log.warn('line: length < 2');
      return;
    }

    this.attr({ lineWidth: this.npx(lineWidth), strokeStyle: strokeColor });

    this.ctx.beginPath();
    const [sx, sy] = points[0];
    this.ctx.moveTo(this.npxLine(sx), this.npxLine(sy));
    for (let i = 1; i < points.length; i += 1) {
      const [x1, y1] = points[i];
      this.ctx.lineTo(this.npxLine(x1), this.npxLine(y1));
    }
    this.ctx.stroke();
    return this;
  }

  /**
   * 圆角边框矩形
   */
  public strokeRoundRect(rect: rectType, radius: number, lineWidth: number, strokeColor: string) {
    if (2 * radius > rect.width || 2 * radius > rect.height) {
      return false;
    }

    this.ctx.save();
    this.ctx.translate(this.npx(rect.x), this.npx(rect.y));
    //绘制圆角矩形的各个边
    drawRoundRectPath(this.ctx, this.npx(rect.width), this.npx(rect.height), this.npx(radius));
    this.ctx.lineWidth = this.npx(lineWidth || 2);
    this.ctx.strokeStyle = strokeColor || '#000';
    this.ctx.stroke();
    this.ctx.restore();
  }

  /**
   * 圆角填充矩形
   */
  public fillRoundRect(rect: rectType, radius: number, fillColor: string = '#000') {
    //圆的直径必然要小于矩形的宽高
    if (2 * radius > rect.width || 2 * radius > rect.height) {
      Log.error('fillRoundRect', '2 * radius > rect.width || 2 * radius > rect.height');
      return false;
    }
    this.ctx.save();
    this.ctx.translate(this.npx(rect.x), this.npx(rect.y));
    //绘制圆角矩形的各个边
    drawRoundRectPath(this.ctx, this.npx(rect.width), this.npx(rect.height), this.npx(radius));
    this.ctx.fillStyle = fillColor; //若是给定了值就用给定的值否则给予默认值
    this.ctx.fill();
    this.ctx.restore();
  }

  public measureTextWidth(str: string) {
    return this.ctx.measureText(str).width / this.dpr;
  }

  // 画组件border
  public drawComponentBorder(rect: rectType, directions: string[], color: string = '#EBEDF0') {
    const { x, y, height, width } = rect;
    // 画左线
    if (directions.includes('left')) {
      this.line(
        [
          [x, y],
          [x, y + height],
        ],
        color,
      );
    }
    // 画右线
    if (directions.includes('right')) {
      this.line(
        [
          [x + width, y],
          [x + width, y + height],
        ],
        color,
      );
    }
    // 画上线
    if (directions.includes('top')) {
      this.line(
        [
          [x, y],
          [x + width, y],
        ],
        color,
      );
    }
    // 画下线
    if (directions.includes('bottom')) {
      this.line(
        [
          [x, y + height],
          [x + width, y + height],
        ],
        color,
      );
    }
  }

  /**
   * 文字超出省略
   */
  public fittingString(str: string, maxWidth: number) {
    const maxWidthNpx = this.npx(maxWidth);
    let strWidth = this.ctx.measureText(str).width;
    const ellipsis = '...';
    const ellipsisWidth = this.ctx.measureText(ellipsis).width;
    if (strWidth <= maxWidthNpx || maxWidthNpx <= ellipsisWidth) {
      return str;
    } else {
      let len = str.length;
      while (strWidth >= maxWidthNpx - ellipsisWidth && len-- > 0) {
        str = str.slice(0, len);
        strWidth = this.ctx.measureText(str).width;
      }
      return str + ellipsis;
    }
  }

  /**
   * 文字超出省略, 上面那个性能太差
   */
  public newFittingString(str: string, maxWidth: number) {
    const maxWidthNpx = this.npx(maxWidth);
    const strWidth = this.ctx.measureText(str).width;
    const ellipsis = '...';
    const ellipsisWidth = this.ctx.measureText(ellipsis).width;
    if (strWidth <= maxWidthNpx) return str;

    const len = str.length;
    let w = ellipsisWidth;
    let newStr = '';
    for (let i = 0; i < len; i++) {
      w += this.ctx.measureText(str[i]).width;
      newStr += str[i];
      if (w >= maxWidthNpx) return newStr + '...';
    }
    return str;
  }

  // 写字
  public drawText(
    text: string,
    fillStyle: string,
    textBaseline: string,
    textAlign: string,
    fontSize: number,
    x: number,
    y: number,
  ) {
    this.save();
    this.attr({
      fillStyle,
      textBaseline,
      textAlign,
      font: `${this.npx(fontSize)}px  sans-serif`,
    });

    this.fillText(text, x, y);

    this.restore();
  }

  /**
   * 三角形
   */
  public fillTriangle(rect: rectType, fillColor: string, direction: 'top' | 'bottom' | 'right') {
    const width = this.npx(rect.width);
    const height = this.npx(rect.height);
    const x = this.npx(rect.x);
    const y = this.npx(rect.y);

    this.ctx.beginPath();

    if (direction === 'top') {
      this.ctx.moveTo(x + width / 2, y);
      this.ctx.lineTo(x, y + height);
      this.ctx.lineTo(x + width, y + height);
    }

    if (direction === 'bottom') {
      this.ctx.moveTo(x, y);
      this.ctx.lineTo(x + width, y);
      this.ctx.lineTo(x + width / 2, y + height);
    }

    if (direction === 'right') {
      this.ctx.moveTo(x, y);
      this.ctx.lineTo(x + width, y + height / 2);
      this.ctx.lineTo(x, y + height);
    }
    this.ctx.fillStyle = fillColor;
    this.ctx.fill(); //闭合
  }

  /**
   * drawLine
   */
  drawLine(row: RowType<any>, type: 'node' | 'group' | 'addRow', fixed: boolean) {
    const currentTop = this.moduleInstances.VerticalScrollbar.getScrollTop();
    const h = this.headerHeight;

    const lineY = row.y + this.headerHeight - currentTop + h;
    const lineX = fixed ? 0 : this.getFixedWidth();
    const lineWidth = fixed ? this.getFixedWidth() : this.canvasWidth;
    this.line([
      [lineX, lineY],
      [lineWidth, lineY],
    ]);
  }

  /**
   * renderLine
   */
  renderLine(fixed: boolean) {
    this.moduleInstances.DataManager.virtualList.forEach((row: RowType<any>) => {
      this.drawLine(row, row.type, fixed);
    });
  }

  getFixedWidth() {
    const headerCells = this.moduleInstances.DataManager.headerCells;
    const fixedCells = headerCells.slice(0, this.config.freeze + 1);

    return fixedCells.reduce(
      (sum: number, headerCell: HeaderCellType) => sum + headerCell.width,
      0,
    );
  }

  renderHeaderCellMore(headerCell: HeaderCellType, type: string, fixed: boolean) {
    let currentLeft = this.moduleInstances.HorizontalScrollbar.getScrollLeft();
    if (fixed) currentLeft = 0;

    const bg =
      headerCell.key === this.moduleInstances.DataManager.hoverHeaderCellKey
        ? this.hoverHeaderCellBg
        : this.headerCellBg;

    const startX = headerCell.x - currentLeft + headerCell.width - this.headerCellMoreWidth;
    this.fillRect(startX, 0, this.headerCellMoreWidth, headerCell.height, bg);

    new HeaderCellMore(
      {
        // startX,
        // endX: startX + this.headerCellMoreWidth,
        // startY: 0,
        // endY: headerCell.height,
        x: startX,
        y: 0,
        width: this.headerCellMoreWidth,
        height: headerCell.height,
      },
      this.moduleInstances,
      type,
      fixed,
    );
  }

  renderHeaderCells(headerCells: HeaderCellType[], fixed: boolean) {}
}

function drawRoundRectPath(
  ctx: CanvasRenderingContext2D,
  width: number,
  height: number,
  radius: number,
) {
  ctx.beginPath();
  //从右下角顺时针绘制，弧度从0到1/2PI
  ctx.arc(width - radius, height - radius, radius, 0, Math.PI / 2);

  //矩形下边线
  ctx.lineTo(radius, height);

  //左下角圆弧，弧度从1/2PI到PI
  ctx.arc(radius, height - radius, radius, Math.PI / 2, Math.PI);

  //矩形左边线
  ctx.lineTo(0, radius);

  //左上角圆弧，弧度从PI到3/2PI
  ctx.arc(radius, radius, radius, Math.PI, (Math.PI * 3) / 2);

  //上边线
  ctx.lineTo(width - radius, 0);

  //右上角圆弧
  ctx.arc(width - radius, radius, radius, (Math.PI * 3) / 2, Math.PI * 2);

  //右边线
  ctx.lineTo(width, height - radius);
  ctx.closePath();
}
