import type { ProFormSelectProps } from '@ant-design/pro-form';
import { ProForm, ProFormSelect } from '@ant-design/pro-form';
import { THEME_STATUS } from '@linkpi/core';
import type { SelectProps } from 'antd';
import { Select } from 'antd';
import { isFunction } from 'lodash';
import type { FC, ReactElement } from 'react';
import { useMemo } from 'react';

import SimplePopover from '@/components/SimplePopover';
import { useOrgTemplatesSettingInfo } from '@/hook/useOrgSetting';

import { selectSearchFn } from '../utils';

const ENABLE_TEMPLATE = [THEME_STATUS.init, THEME_STATUS.lock];

type TemplateSelectProps = {
  orgId?: string;
  includeTempIds?: string[];
  excludeTempIds?: string[];
  className?: string;
  labelRender?: (label: string) => string | ReactElement;
};

export const TemplatePureSelect: FC<SelectProps & TemplateSelectProps> = (props) => {
  const [templates] = useOrgTemplatesSettingInfo();

  const tempOptions = useMemo(() => {
    const checkTemplate = (tempId: string) => {
      // 白名单
      if (Array.isArray(props.includeTempIds)) {
        const tempMap = props.includeTempIds.reduce((r, t) => ({ ...r, [t]: true }), {}) as any;
        return tempMap[tempId] as boolean;
      }

      // 黑名单
      if (Array.isArray(props.excludeTempIds)) {
        const tempMap = props.excludeTempIds.reduce((r, t) => ({ ...r, [t]: true }), {}) as any;
        return !tempMap[tempId] as boolean;
      }

      return true;
    };

    return templates
      .filter((t) => ENABLE_TEMPLATE.includes(t.status) && checkTemplate(t.template_id))
      .map((t) => ({
        label: t.name,
        value: t.template_id,
      }));
  }, [templates, props.includeTempIds, props.excludeTempIds]);

  const selectProps = {
    ...props,
    placeholder: '请选择主题类型',
    filterOption: selectSearchFn,
    showSearch: true,
    options: tempOptions,
  };

  return <Select {...selectProps} />;
};

export function TemplateSelect<T>(props: ProFormSelectProps<T> & TemplateSelectProps) {
  const form = ProForm.useFormInstance();
  const value = ProForm.useWatch(props.name || '@@@null', form);

  const [templates] = useOrgTemplatesSettingInfo();

  const tempOptions = useMemo(() => {
    const checkTemplate = (tempId: string) => {
      // 白名单
      if (Array.isArray(props.includeTempIds)) {
        const tempMap = props.includeTempIds.reduce((r, t) => ({ ...r, [t]: true }), {}) as any;
        return tempMap[tempId] as boolean;
      }

      // 黑名单
      if (Array.isArray(props.excludeTempIds)) {
        const tempMap = props.excludeTempIds.reduce((r, t) => ({ ...r, [t]: true }), {}) as any;
        return !tempMap[tempId] as boolean;
      }

      return true;
    };

    return templates
      .filter((t) => ENABLE_TEMPLATE.includes(t.status) && checkTemplate(t.template_id))
      .reduce(
        (r, t) => ({
          ...r,
          [t.template_id]: isFunction(props.labelRender) ? props.labelRender?.(t.name) : t.name,
        }),
        {},
      );
  }, [templates, props.includeTempIds, props.excludeTempIds, props.labelRender]);

  const isLock = useMemo(() => {
    const temp = templates.find((t) => t.template_id === value);

    return temp?.status === THEME_STATUS.lock;
  }, [templates, value]);

  return (
    <SimplePopover showPopover={isLock} content="该主题类型已锁定，请从左侧列表解锁">
      <ProFormSelect
        {...props}
        fieldProps={{
          ...props.fieldProps,
          placeholder: '请选择主题类型',
          filterOption: selectSearchFn,
          showSearch: true,
        }}
        valueEnum={tempOptions}
      />
    </SimplePopover>
  );
}
