import { cloneDeep } from 'lodash';

import { useOrgTempMap } from '@/hook';
import { getCustomTableChildTemplateIds, getRealPropConfig } from '@/utils/utils';

import styles from './styles.less';
const CustomTableChildTemplatesSort = (compProps: any) => {
  const { orgInfo, curViewData, template, onChange } = compProps;

  const tempMap = useOrgTempMap(orgInfo.orgId);

  if (!orgInfo || !curViewData || !template) return null;
  const childOrderBy = curViewData.view_info.childOrderBy;

  const customTableChildTemplateIds = getCustomTableChildTemplateIds(
    curViewData.view_info.customTableId as string,
    template,
  );
  if (customTableChildTemplateIds.length === 0) return null;

  const customTableChildTemplates: any[] = [];

  customTableChildTemplateIds.forEach((i) => {
    const t = tempMap[i];
    if (!t) return;

    const { name } = t;

    const prop = (t.prop || [])
      .map((p: any, index: number) => ({ ...p, index }))
      .filter((p: any) => p.type);

    const payload: any = { name, props: [], tempId: i };

    prop.forEach((p) => {
      //日期属性、数字属性、数字公式、引用（数字-原值）、引用（数字-统计）、引用（全部-计数）
      if (['datetime', 'date'].includes(p.type)) return payload.props.push(p);
      if (p.type === 'number') return payload.props.push(p);
      if (p.type === 'formula' && p.formulaFormat === 0) return payload.props.push(p);
      if (p.type === 'quote') {
        const realPropConfig = getRealPropConfig(p, tempMap);
        if (typeof realPropConfig !== 'string' && realPropConfig?.type === 'number')
          return payload.props.push(p);
      }
    });

    customTableChildTemplates.push(payload);
  });

  const isPropActive = (tempId: string, prop: any) => {
    if (Object.prototype.toString.call(childOrderBy) !== '[object Object]') return false;

    return childOrderBy[tempId] && childOrderBy[tempId].pIndex == prop.index;
  };

  const changeCustomTableChildNodesOrder = (tempId: string, prop: any) => {
    let newChildOrderBy = cloneDeep(childOrderBy);
    if (Object.prototype.toString.call(newChildOrderBy) !== '[object Object]') {
      newChildOrderBy = {};
      newChildOrderBy[tempId] = { pIndex: prop.index, sort: 'desc', pType: prop.type };
    } else {
      if (!newChildOrderBy[tempId])
        newChildOrderBy[tempId] = { pIndex: prop.index, sort: 'desc', pType: prop.type };
      else {
        if (newChildOrderBy[tempId].pIndex == prop.index) return;
        newChildOrderBy[tempId] = { pIndex: prop.index, sort: 'desc', pType: prop.type };
      }
    }

    onChange(newChildOrderBy);
  };

  const changePropSort = (tempId: string) => {
    const newChildOrderBy = cloneDeep(childOrderBy);
    const nowSort = newChildOrderBy[tempId].sort;
    newChildOrderBy[tempId].sort = nowSort === 'desc' ? 'asc' : 'desc';
    onChange(newChildOrderBy);
  };

  const getPropSort = (tempId: string, prop: any) => {
    if (Object.prototype.toString.call(childOrderBy) !== '[object Object]') return 'desc';

    if (!childOrderBy[tempId]) return 'desc';

    if (childOrderBy[tempId].pIndex != prop.index) return 'desc';

    return childOrderBy[tempId].sort;
  };

  return (
    <>
      {customTableChildTemplates.map((i) => {
        const { name, props, tempId } = i;

        return (
          <>
            <li key={name}>{name}</li>
            {props.map((p: any) => (
              <li
                key={p.index}
                className={`view-setting-item${isPropActive(tempId, p) ? ' active' : ''}`}
                onClick={() => {
                  changeCustomTableChildNodesOrder(tempId, p);
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    alignItems: 'center',
                  }}
                >
                  <span>{p.name}</span>
                  {isPropActive(tempId, p) && (
                    <i
                      className={`${styles.sortIcon} iconfont ${
                        getPropSort(tempId, p) === 'desc' ? 'iconDescending' : 'iconAscending'
                      }`}
                      onClick={(e) => {
                        e.stopPropagation();
                        changePropSort(tempId);
                      }}
                    />
                  )}
                </div>
              </li>
            ))}
          </>
        );
      })}
    </>
  );
};

export default CustomTableChildTemplatesSort;
