import { mergeDeepRight } from 'ramda';

import type Core from '../core';
import type { PiGanttModules } from '../core';

export default class BaseModule {
  public Core: Core;

  // @ts-ignore
  public moduleInstances: PiGanttModules;

  public doms: domMapType = {};

  constructor(Core: Core) {
    this.Core = Core;
  }

  public get config() {
    return this.Core.config;
  }

  public inject(moduleInstances: any) {
    this.moduleInstances = moduleInstances;
  }
}
