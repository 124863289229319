import type { PiGridModules } from '../core';
import { statisticsLabels } from '../helper/config';
import { parseStatisticNumber } from '../helper/utils';
import type { PositionType } from './_baseComponent';
import GridBaseComponent from './_baseComponent';

class StatisticsRowFunctionCell extends GridBaseComponent {
  public data: any;
  public type: string = 'statisticsRowFunctionCell';
  private STYLE = {
    hoverBg: '#f0f0f0',
    darknessFontColor: '#919AB5',
  };
  constructor(position: PositionType, moduleInstances: PiGridModules, data: any) {
    super(position, moduleInstances);

    this.data = data;
  }

  public click() {
    const {
      DataManager: { config },
    } = this.moduleInstances;
    const {
      cell: { stat, key },
      row: { groupKey },
    } = this.data;

    config.showCalcPopover({ stat, cellType: key, groupKey }, this.position, 'bottom');
  }

  public hover() {}

  public render() {
    const {
      Draw,
      DataManager: { statConfig, statData, templateMap },
    } = this.moduleInstances;
    const {
      cell: { key, config },
      row: { groupKey },
    } = this.data;
    const { x, y, height, width } = this.position;
    const startY = y + height / 2;
    const gap = 10;

    const styleMode = Draw.config.styleMode;
    const containerBgColor = Draw.config.contentPageGeneralTableConfig?.containerBgColor || '#fff';

    const cellBg = styleMode === 'darkness' ? containerBgColor : '#fff';
    // rect
    const bg = this.isHover
      ? styleMode === 'darkness'
        ? containerBgColor
        : this.STYLE.hoverBg
      : cellBg;
    Draw.fillRect(x, y, width, height, bg);

    // 存在统计
    const statisticsType = statConfig[key];
    if (statisticsType) {
      //@ts-ignore
      const text = statisticsLabels[statisticsType].label;
      let result = statData[key]?.[groupKey] || 0;
      result = parseStatisticNumber(key, config, templateMap, result, statisticsType);

      Draw.attr({
        fillStyle: styleMode === 'darkness' ? this.STYLE.darknessFontColor : '#97a1b5',
        textBaseline: 'middle',
        textAlign: 'start',
        font: `${Draw.npx(13)}px sans-serif`,
      });
      // draw text
      Draw.fillText(text, x + gap, startY);

      // draw result
      Draw.ctx.textAlign = 'end';
      Draw.fillText(result + '', x + width - gap, startY);
      return;
    }

    // 不存在统计 就是白色的格子 但是hover上去显示计算
    if (!this.isHover) return;
    Draw.attr({
      fillStyle: styleMode === 'darkness' ? '#316EF5' : '#97a1b5',
      textBaseline: 'middle',
      textAlign: 'start',
      font: `${Draw.npx(13)}px sans-serif`,
    });

    // text
    Draw.fillText('计算', x + gap, startY);

    // icon
    Draw.ctx.font = `${Draw.npx(13)}px iconfont`;
    Draw.fillText(Draw.iconFont('&#xe6f3;'), x + 40, startY);
  }
}

export default StatisticsRowFunctionCell;
