import type { ApiResponse } from '@linkpi/core';
import type { CurrentUser } from '@linkpi/core';
import { CAS_PROP, getMatchingOriginProp, NICK_NAME, PROP, PROP_TYPE } from '@linkpi/core';
import { Input, InputNumber, message } from 'antd';
import { useMemo, useState } from 'react';

import LinkPiPop from '@/components/LinkPiPop';
import type { PropOption } from '@/pages/space/components/TemplateProp/components/TempPropModal/types';

interface IDefaultFormula {
  propType: ApiResponse.CurrentUser.propType;
  tempProps: PropOption[];
  tempMap: Record<string, ApiResponse.CurrentUser.TemplateInfo>;
  numberLimit?: ApiResponse.CurrentUser.propNumberLimit;
  defaultFormula: ApiResponse.CurrentUser.propDefaultFormula;
  setDefaultFormula: (formula: CurrentUser.propDefaultFormula) => void;
  precision: number;
  numericalFormat: number;
}

interface IMenuOption {
  label: string;
  index: number;
  handler: () => void;
  icon: string;
  type: string;
}

export const DefaultFormula = (props: IDefaultFormula) => {
  const {
    propType,
    tempProps,
    tempMap,
    numberLimit,
    defaultFormula,
    setDefaultFormula,
    precision,
    numericalFormat,
  } = props;

  const [popOpen, setPopOpen] = useState(false);

  const defaultPropIndex = useMemo(() => {
    if (defaultFormula.type === 'prop') return defaultFormula.prop;
    if (defaultFormula.type === 'formula' && defaultFormula.formula) {
      if ('input' in defaultFormula.formula) {
        return defaultFormula.formula.input[0].prop;
      } else if (defaultFormula.formula.type === 'cas_prop') {
        return defaultFormula.formula.prop;
      }
    }
    return -1;
  }, [defaultFormula]);

  const defaultOptions = {
    placeholder: `请${propType === 'date' || propType === 'datetime' ? '选择' : '输入'}，或引用`,
    bordered: false,
  };

  const filterMenu = (p: PropOption) => {
    switch (propType) {
      case 'text':
        return p.type !== 'attachment';
      case 'number':
        let type = p.type;
        if (type === 'quote') {
          // @ts-ignore
          const originProp: PropOption = getMatchingOriginProp(p, tempMap);
          if (originProp)
            type =
              originProp.type === 'formula' && originProp.formulaFormat === 0
                ? 'number'
                : originProp.type;
        }
        return type === 'number' || (p.type === 'formula' && p.formulaFormat === 0);
      case 'date':
      case 'datetime':
        return p.type === 'date' || p.type === 'datetime';
      default:
        return p.type === propType;
    }
  };

  const menus: IMenuOption[] = tempProps
    .filter((p) => filterMenu(p))
    .map((p) => {
      return {
        label: p.name,
        index: p.index,
        handler: () => {},
        // @ts-ignore
        icon: PROP_TYPE[p.type]?.icon || 'iconattribute_selected_value',
        type: p.type,
      };
    });

  const handleDefaultChange = (e: number | string | null) => {
    if (propType === 'number' && e !== null) {
      if (numberLimit) {
        if (
          numberLimit.upper.type === 'const' &&
          numberLimit.upper.const !== undefined &&
          numberLimit.upper.const !== null &&
          e > numberLimit.upper.const
        )
          return message.warn('默认值需小于上限');
        if (
          numberLimit.lower.type === 'const' &&
          numberLimit.lower.const !== undefined &&
          numberLimit.lower.const !== null &&
          e < numberLimit.lower.const
        )
          return message.warn('默认值需大于下限');
      }
    }
    setDefaultFormula({
      ...defaultFormula,
      const: e,
    });
  };

  const inputDom = () => {
    switch (propType) {
      case 'text':
        return (
          <Input
            value={defaultFormula.const}
            {...defaultOptions}
            onChange={(e) => handleDefaultChange(e.target.value)}
          />
        );
      case 'currencyV2':
      case 'number':
        return (
          <InputNumber
            precision={precision || 0}
            value={defaultFormula.const}
            {...defaultOptions}
            onChange={(e) => handleDefaultChange(e)}
          />
        );
    }
  };

  const defaultHandler = (menu: IMenuOption) => {
    setPopOpen(false);
    switch (menu.type) {
      case 'cascade':
        return setDefaultFormula({
          ...defaultFormula,
          type: 'formula',
          formula: CAS_PROP(menu.index, '/'),
        });
      case 'user':
        return setDefaultFormula({
          ...defaultFormula,
          type: 'formula',
          formula: propType === 'user' ? menu.index : NICK_NAME(PROP(menu.index)).formula,
        });
      default:
        setDefaultFormula({
          ...defaultFormula,
          type: 'prop',
          prop: menu.index,
        });
    }
  };

  return (
    <div className={`form-item${numericalFormat ? ' numerical' : ''}`}>
      <div className={'form-label'}>默认值</div>
      <LinkPiPop
        menus={menus.map((m) => {
          return {
            ...m,
            active: defaultPropIndex === m.index,
            handler: () => defaultHandler(m),
          };
        })}
        menuWidth={690}
        menuHeight={menus.length > 6 ? 220 : menus.length * 40}
        visible={popOpen}
        onVisibleChange={(e: boolean) => (defaultFormula.type !== 'const' || !e) && setPopOpen(e)}
        trigger={'click'}
        insideDom={
          <div className={`form-input default${defaultFormula.type !== 'const' ? ' quote' : ''}`}>
            {defaultFormula.type === 'const'
              ? inputDom()
              : tempProps.find((p) => p.index === defaultPropIndex)?.name}
            <div className={'form-item-addon'}>
              <i
                className={`iconfont ${
                  defaultFormula.type !== 'const' ? 'iconformula-subtract' : 'iconformula-add'
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  if (defaultFormula.type !== 'const') {
                    setPopOpen(false);
                    setDefaultFormula({
                      ...defaultFormula,
                      type: 'const',
                    });
                  } else {
                    if (!menus.length) return message.warn('当前主题类型没有可引用的属性');
                    setPopOpen(true);
                  }
                }}
              />
              {numericalFormat ? <div className={'form-item-symbol'}>%</div> : null}
            </div>
          </div>
        }
      />
    </div>
  );
};
