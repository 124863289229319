import { FullscreenOutlined } from '@ant-design/icons';
import { antdModalV5, create, show, useModal } from '@ebay/nice-modal-react';
import { useMount, useToggle } from 'ahooks';
import { Modal, Row, Tooltip, Typography } from 'antd';
import cls from 'classnames';
import { atom, createStore, Provider, useAtomValue, useStore } from 'jotai';
import { nanoid } from 'nanoid';
import { createContext, type FC, type PropsWithChildren, type ReactNode, useContext } from 'react';

import { PiButton, RegularIcon } from '@/components';
import { usePageThemeMode } from '@/hook';

import styles from './ComponentViewer.less';

type ComponentViewerProps = {
  content: ReactNode;
  title?: string;
  width?: number | string;
};

const viewerStore = createStore();
const viewerIdAtom = atom(nanoid());
const ComponentViewerContent: FC<PropsWithChildren> = ({ children }) => {
  const store = useStore();

  useMount(() => {
    store.set(viewerIdAtom, nanoid());
  });

  return children;
};

export function useViewerId() {
  const viewerId = useAtomValue(viewerIdAtom);
  return viewerId;
}

const ComponentViewer: FC<ComponentViewerProps> = ({ content, width, title = '查看详情' }) => {
  const modal = useModal();
  const [fullScreen, { toggle: toggleFullScreen }] = useToggle();

  const titleContent = (
    <Row justify="space-between">
      <Typography.Text>{title}</Typography.Text>
      <div>
        <Tooltip title="最大化">
          <PiButton
            type="secondary"
            shape="circle"
            style={{ position: 'absolute', right: 64, top: 10 }}
            icon={<FullscreenOutlined type="iconContent_Download" style={{ color: '#7b8aa3' }} />}
            onClick={toggleFullScreen}
          />
        </Tooltip>
      </div>
    </Row>
  );

  const modalSizeProps = fullScreen
    ? {
        width: '100vw',
        height: '100%',
      }
    : {
        width: width || 'min(1200px, 80vw)',
      };

  const contentSizeProps = {
    height: '100%',
  };

  return (
    <Modal
      {...antdModalV5(modal)}
      {...modalSizeProps}
      title={titleContent}
      className={cls(styles.wrapper, fullScreen && styles.fullScreen)}
      centered
      destroyOnClose
      footer={null}
    >
      <Provider store={viewerStore}>
        <div style={contentSizeProps}>
          <ComponentViewerContent>{content}</ComponentViewerContent>
        </div>
      </Provider>
    </Modal>
  );
};

const modal = create(ComponentViewer);

export const viewComponent = (o: ComponentViewerProps) => show(modal, o);

export const ViewComponentActionsContext = createContext({
  previewComponent: () => {},
  viewable: false,
});

export const useViewComponentActions = () => {
  return useContext(ViewComponentActionsContext);
};

export const ViewerButton = () => {
  const { isDark } = usePageThemeMode();
  const { viewable, previewComponent } = useViewComponentActions();
  if (!viewable) return null;
  return (
    <Tooltip title="弹窗查看">
      <PiButton
        className={cls(styles.button, isDark && styles.darkBtn)}
        type="secondary"
        onClick={previewComponent}
        icon={<RegularIcon type="iconneirongye-dakai2" size={14} />}
      />
    </Tooltip>
  );
};
