import { Switch } from 'antd';

import '../GroupForView/styles.less';
export default (props: any) => {
  const { curViewData, onChange, isDark } = props;

  const showLevelInFirstCol = curViewData?.view_info?.showLevelInFirstCol;

  return (
    <div className={`view-setting-block${isDark ? ' view-setting-block-dark' : ''}`}>
      <Switch size="small" checked={showLevelInFirstCol} onChange={onChange} />
      <div style={{ marginLeft: 4 }}>展示树层</div>
    </div>
  );
};
