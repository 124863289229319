import { InfoCircleOutlined } from '@ant-design/icons';
import ProForm, { ProFormDigit, ProFormSelect } from '@ant-design/pro-form';
import { EXTENTIONS_MAP } from '@linkpi/core';
import { useMemoizedFn } from 'ahooks';
import { Space, Tooltip } from 'antd';
import type { FC } from 'react';

import { UserGroupSelect } from '@/components/LinkPiForm';
import type { TemplateProp } from '@/pages/space/components/TemplateProp/components/TempPropModal/types';

import { useTempPropConfig } from '../hooks';

import styles from './AttachmentsConfig.less';

type Writeable<T> = { -readonly [P in keyof T]: T[P] };

const FILE_TYPE_OPTIONS = [
  {
    label: (
      <>
        文档格式{' '}
        <Tooltip title={'包括 ' + EXTENTIONS_MAP.doc.join('、')}>
          <InfoCircleOutlined />
        </Tooltip>
      </>
    ),
    pureLabel: '文档格式',
    value: 'doc',
  },
  {
    label: (
      <>
        图片格式{' '}
        <Tooltip title={'包括 ' + EXTENTIONS_MAP.img.join('、')}>
          <InfoCircleOutlined />
        </Tooltip>
      </>
    ),
    pureLabel: '图片格式',
    value: 'img',
  },
  {
    label: (
      <>
        多媒体格式{' '}
        <Tooltip title={'包括 ' + EXTENTIONS_MAP.media.join('、')}>
          <InfoCircleOutlined />
        </Tooltip>
      </>
    ),
    pureLabel: '多媒体格式',
    value: 'media',
  },
] as const;

const DEFAULT_CONFIG: TemplateProp['attachmentConfig'] = {
  countLimit: 10,
  sizeLimit: 50,
  allowUploadExtensions: FILE_TYPE_OPTIONS.map((i) => i.value),
  allowDownloadGroups: ['-1'],
};

export const AttachmentsConfig: FC = () => {
  const { savePropParams, propParams } = useTempPropConfig();

  const onChange = useMemoizedFn((_, values) => {
    savePropParams(values, 'attachmentConfig');
  });

  return (
    <ProForm
      layout="horizontal"
      submitter={false}
      onValuesChange={onChange}
      initialValues={{ ...DEFAULT_CONFIG, ...propParams?.attachmentConfig }}
    >
      <ProForm.Group>
        <ProForm.Item className={styles.group}>
          <Space>
            附件个数限制
            <ProFormDigit noStyle name="countLimit" max={100} min={0} width={140} />个
          </Space>
        </ProForm.Item>
        <ProForm.Item className={styles.group}>
          <Space>
            单个附件大小限制
            {/* @露凡 要求移除最大值的最大值 */}
            <ProFormDigit noStyle name="sizeLimit" min={0} width={140} />
            Mb
          </Space>
        </ProForm.Item>
      </ProForm.Group>
      <ProFormSelect
        name="allowUploadExtensions"
        label="上传格式"
        mode="multiple"
        options={FILE_TYPE_OPTIONS as Writeable<typeof FILE_TYPE_OPTIONS>}
        fieldProps={{
          placeholder: '不限制',
          optionLabelProp: 'pureLabel',
        }}
      />
      <UserGroupSelect name="allowDownloadGroups" label="可下载分组" />
    </ProForm>
  );
};
