import { Divider, Popover } from 'antd';
import cls from 'classnames';
import PerfectScrollbar from 'react-perfect-scrollbar';

import './index.less';

const LinkPiPop = (props) => {
  const {
    trigger,
    insideDom,
    placement,
    content,
    onVisibleChange,
    menus,
    menuWidth,
    noPadding,
    menuHeight,
    menuMaxHeight,
    menuWrapperStyle,
    overlayInnerStyle,
    overlayClassName = '',
    visible,
    getPopupContainer = true,
    extraComponent = null,
    children,
  } = props;

  const genPopMenus = (popMenus) => {
    const menuStyle = {
      width: 156,
    };
    let psStyle = {};
    if (menuWidth) {
      menuStyle.width = menuWidth;
    }
    if (menuHeight) {
      psStyle.height = menuHeight;
    }
    if (menuMaxHeight) {
      psStyle.maxHeight = menuMaxHeight;
    }
    if (menuWrapperStyle) {
      psStyle = {
        ...psStyle,
        ...menuWrapperStyle,
      };
    }
    return (
      <PerfectScrollbar style={psStyle} options={{ suppressScrollX: true }}>
        {extraComponent !== null && <div className="extra-component">{extraComponent}</div>}
        <ul className="link-menus" style={menuStyle}>
          {popMenus.map((menu, i) => {
            if (menu.divider) {
              const style = {};
              if (menu.margin) {
                style.margin = menu.margin;
              }
              return <Divider key={i} type={menu.type} style={style} />;
            }

            const li = (
              <li
                className={cls(
                  menu.isSubtitle ? 'subtitle' : '',
                  menu.active ? 'active' : '',
                  menu.subLabel ? 'hasSub' : '',
                )}
                key={i}
                onClick={() => !menu.popConfirm && menu.handler && menu.handler(menu)}
              >
                <div className="link-menus-main">
                  {menu.icon ? (
                    <i
                      className={`iconfont ${menu.icon}`}
                      style={menu.iconColor ? { color: menu.iconColor } : {}}
                    />
                  ) : null}
                  <div className="text-omit">{menu.label}</div>
                  {menu.labelExtra}
                </div>
                {menu.labelAddon ? (
                  <div
                    className="label-addon"
                    onClick={(e) => {
                      e.stopPropagation();
                      menu.addonHandler && menu.addonHandler(menu);
                    }}
                  >
                    {menu.labelAddon}
                  </div>
                ) : null}
                {menu.subLabel ? <div className="link-menus-sub">{menu.subLabel}</div> : null}
              </li>
            );

            if (menu.pop) {
              return (
                <Popover
                  key={i}
                  overlayClassName={'link-pi-pop'}
                  trigger={menu.pop.trigger || 'hover'}
                  content={menu.pop.content}
                  placement={menu.pop.placement}
                >
                  {li}
                </Popover>
              );
            }

            if (menu.popMenus) {
              return (
                <Popover
                  key={i}
                  overlayClassName={'link-pi-pop'}
                  trigger={menu.trigger || 'hover'}
                  content={genPopMenus(menu.popMenus)}
                  placement={menu.placement}
                >
                  {li}
                </Popover>
              );
            }

            return li;
          })}
        </ul>
      </PerfectScrollbar>
    );
  };

  const popContent = () => {
    if (menus) {
      return genPopMenus(menus);
    }
    return content;
  };

  const popProps = {
    overlayClassName: `link-pi-pop ${noPadding ? 'no-padding' : ''} ${overlayClassName}`,
    trigger: trigger || 'hover',
    placement,
    // onVisibleChange,
    onOpenChange: onVisibleChange,
    overlayInnerStyle: overlayInnerStyle || {},
  };
  if (getPopupContainer) {
    popProps['getPopupContainer'] = (node) => node.parentNode;
  }

  if (onVisibleChange) {
    popProps['open'] = visible;
  }

  return (
    <Popover {...popProps} content={popContent} destroyTooltipOnHide>
      {insideDom || children}
    </Popover>
  );
};

export default LinkPiPop;
