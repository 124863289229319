import type { ApiResponse } from '@linkpi/core';
import { execCalcFormula, propIsNull } from '@linkpi/core';
import { useMemoizedFn, useRequest } from 'ahooks';
import { isNil } from 'ramda';
import { useMemo } from 'react';

import request from '@/utils/request';
import { getRealPropConfig } from '@/utils/utils';

import { useOrgTempMap } from './useTemplate';

const ENABLE_TYPE = ['enum', 'tag'];
/**
 * @description 返回 主题类型所有的 匹配 或 引用 选值范围
 */
export const useQuoteOptions = (
  orgId: string,
  template: ApiResponse.CurrentUser.TemplateInfo | undefined,
  /**
   * @description 指定部分属性
   */
  propIndex?: number,
) => {
  const tempMap = useOrgTempMap(orgId);
  const propIndexList = useMemo(() => {
    if (!template || !orgId) return [];

    if (Number.isInteger(propIndex)) return [propIndex];

    // 获取主题类型的选值属性index
    const result: number[] = [];
    template.prop.forEach((p, i) => {
      if (!p || !p.type) return;

      // 获取真实的属性类型
      const realPropConfig = getRealPropConfig(p, tempMap);
      if (typeof realPropConfig === 'string' || !realPropConfig) return;

      if (ENABLE_TYPE.includes(realPropConfig.type!) && p.conditionMatching && p.matchingType <= 0)
        result.push(i);
    });

    return result;
  }, [orgId, propIndex, tempMap, template]);

  // 获取匹配或引用属性的选项
  const fetchQuoteOptions = useMemoizedFn(async () => {
    if (!template || !orgId || template.template_id === 'ff') return {};

    const enums: Record<number, string[]> = {};

    const [_, res] = await execCalcFormula(request, {
      temp_id: template.template_id,
      org_id: orgId,
      index: propIndexList,
      ignoreQuotaCondition: true,
      getQuoteArray: true,
    });

    if (res && res.status === 'ok' && Array.isArray(res.data)) {
      propIndexList.forEach((index, i) => {
        if (isNil(index)) return;
        if (propIsNull(res.data[i])) return;
        const d = Array.isArray(res.data[i]) ? res.data[i] : [res.data[i]];
        enums[index] = [];
        d.forEach((d: any) => {
          if (typeof d === 'string') {
            if (d && !enums[index].includes(d)) enums[index].push(d);
          } else if (typeof d === 'object' && d !== null && 'v' in d) {
            if (typeof d.v === 'string') {
              if (d.v && !enums[index].includes(d.v)) enums[index].push(d.v);
            } else if (Array.isArray(d.v)) {
              d.v.forEach((v: any) => {
                if (typeof v === 'string' && v && !enums[index].includes(v)) enums[index].push(v);
              });
            }
          }
        });
      });
    }

    return enums;
  });

  // 匹配或引用属性的选项
  const { data: quoteOptions = {}, loading } = useRequest(fetchQuoteOptions, {
    refreshDeps: [orgId, template],
    cacheKey: [orgId, template?.template_id, ...propIndexList].join('@'),
    loadingDelay: 100,
  });

  return [quoteOptions, { loading }];
};
