import { addNewNode } from '@linkpi/core';
import { message, Popover } from 'antd';
import moment from 'moment';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import React, { useEffect, useRef } from 'react';

import { previewImages } from '@/components/ImageViewer';
import request from '@/utils/request';

import styles from './styles.less';

interface IProps {
  messages: any[];
  currentUser: any;
  userMap: any;
  currentSelection: any;
  node: any;
}

const defaultUserAvatar = 'https://ljp-h5.oss-cn-hangzhou.aliyuncs.com/static/imgs/avatar.png';
const unknowFileImg = 'https://www.mylinkpi.com/icon/unknown%20file.png';

export default (props: IProps) => {
  const { messages, currentUser, userMap, currentSelection, node } = props;

  const isMySelf = (i: any): boolean => currentUser.userid === i.s;

  const areaRef = useRef<any>(null);

  useEffect(() => {
    setTimeout(() => {
      if (!areaRef.current) return;
      areaRef.current.scroll({
        // behavior: 'smooth',
        top: areaRef.current.scrollHeight,
      });
    }, 500);
  }, [messages]);

  const userInfo = (info: any) => {
    const { nick_name, avatar } = info || {};

    return {
      avatar: (
        <img
          data-type="avatar"
          style={{ height: 36, width: 36, borderRadius: '100%' }}
          src={avatar || defaultUserAvatar}
        />
      ),
      name: <span children={nick_name || '未知用户'} />,
    };
  };

  // 对消息的操作
  const actions = [
    // {
    //     name: '复制',
    //     key: 'copy'
    // },
    {
      name: '转为子主题',
      key: 'changeToTheme',
      cb: async (i: any) => {
        const speaker = userMap[i.s] ? userMap[i.s].nick_name : '未知用户';

        const payload: any = {
          org_id: currentSelection.selectSpace,
          parentId: node.value.id,
          node: {
            prop: {
              发言人: speaker,
              _sys_creator: currentUser.userid,
            },
            title: speaker,
            content: {
              ops: i.c.ops,
            },
          },
        };

        const res = await addNewNode(request, payload);

        if (Array.isArray(res) && (res[1] || {}).status === 'ok') message.success('创建子主题成功');
        else message.error('添加失败');
      },
    },
  ];

  // 自己的消息
  const mySelf = (i: any) => {
    const { avatar, name } = userInfo(userMap[i.s]);

    return (
      <div className={styles.mySelf}>
        {avatar}
        <div className={styles.content}>
          {name}
          {renderContent(i)}
        </div>
        <div className={styles.more}>
          <Popover
            // getPopupContainer={(node) => node.parentNode as HTMLElement}
            content={actions.map((a: any) => (
              <div onClick={() => a.cb(i)} className={styles.action} key={a.key}>
                {a.name}
              </div>
            ))}
            placement="top"
          >
            <i className={`iconcommunity_more ${styles.dot} iconfont`} />
          </Popover>
        </div>
      </div>
    );
  };

  const formatFileSize = (size: number) => {
    return Math.ceil(size / 1024) > 1024
      ? Math.round(size / 1024 / 1024) + 'MB'
      : Math.ceil(size / 1024) + 'KB';
  };

  const renderFileBlot = (config: any) => {
    const { fileName, fileSize, href } = config;

    return `
            <div class="${styles.fileWrapper}">
                <div>
                    <img class="${styles.unknowFileImg}" src="${unknowFileImg}"></img>
                    <span>${fileName}</span>
                </div>
                <div class="${styles.download}">
                    <span>${formatFileSize(Number(fileSize))}</span>
                    <a target="_blank" href="${href}" download="${fileName}">
                        <i class="iconTopic_Download iconfont"></i>
                    </a>
                </div>
            </div>
        `;
  };

  const renderMention = (config: any) => {
    const { denotationChar, id, value } = config;

    const className = id === currentUser.userid ? styles.self : styles.at;
    return `<span class="${className}">${denotationChar + value}</span>`;
  };

  // 消息内容
  const renderContent = (i: any) => {
    const ops = i.c?.ops || [];
    const converter = new QuillDeltaToHtmlConverter(ops, {});

    converter.renderCustomWith((customOp: any) => {
      const { insert } = customOp;
      const { type, value } = insert;

      if (type === 'fileBlot') return renderFileBlot(value);
      if (type === 'mention') return renderMention(value);

      return '未定义的文件类型';
    });

    return (
      <div
        className={styles.contentBox}
        dangerouslySetInnerHTML={{ __html: converter.convert() }}
      />
    );
  };

  // 别人的消息
  const others = (i: any) => {
    const { avatar, name } = userInfo(userMap[i.s]);

    return (
      <div className={styles.others}>
        {avatar}
        <div className={styles.content}>
          {name}
          {renderContent(i)}
        </div>
        <div className={styles.more}>
          <Popover
            getPopupContainer={(node) => node.parentNode as HTMLElement}
            content={actions.map((a: any) => (
              <div onClick={() => a.cb(i)} className={styles.action} key={a.key}>
                {a.name}
              </div>
            ))}
            placement="top"
          >
            <i className={`iconcommunity_more ${styles.dot} iconfont`} />
          </Popover>
        </div>
      </div>
    );
  };

  // 处理时间
  const weeks = [undefined, '星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期日'];
  const handleTime = (time: any) => {
    // 礼拜几
    let week = moment(time).day();
    week = week === 0 ? 7 : week;
    // 和现在相差多久
    const days = moment().diff(moment(time), 'days');

    // 不在一个礼拜，显示具体时间
    if (days >= week) return moment(time).format('YYYY/MM/DD HH:mm');
    // 今天
    if (days < 1) return moment(time).format('HH:mm');
    // 当前礼拜
    if (days < week) return weeks[week] + ' ' + moment(time).format('HH:mm');
  };

  // 消息间隔是否超过 5 分钟
  let lastRenderTime: any;
  const showTime = (time: any) => <div className={styles.time}>{handleTime(time)}</div>;
  const ifRenderTime = (i: any, index: number) => {
    const currentTime = i.d;
    if (index === 0) {
      lastRenderTime = currentTime;
      return showTime(currentTime);
    }

    if (moment(currentTime).diff(moment(lastRenderTime), 'minutes') > 5) {
      lastRenderTime = currentTime;
      return showTime(currentTime);
    }

    return null;
  };

  //
  const clickMessagesArea = (e: any) => {
    const { target } = e;
    if (target instanceof HTMLImageElement) {
      const { type } = target.dataset;
      if (type === 'avatar') return;

      previewImages({
        images: [{ src: target.src, alt: '' }],
        noNavbar: true,
        changeable: false,
        attribute: false,
        scalable: false,
        noClose: true,
      });
    }
  };

  return (
    <div ref={areaRef} onClick={clickMessagesArea} className={styles.messagesArea}>
      {messages.map((m: any, index: number) => (
        <React.Fragment key={index}>
          {ifRenderTime(m, index)}
          {isMySelf(m) ? mySelf(m) : others(m)}
        </React.Fragment>
      ))}
    </div>
  );
};
