import { convertDeltaToPureText } from '@/utils/utils';

import type { PositionType } from '../components/_baseComponent';
import GridBaseComponent from '../components/_baseComponent';
import type { PiGridModules } from '../core';
class MainbodyContent extends GridBaseComponent {
  public data: { node: PiNode };
  public type: string = 'mainbodyContent';
  public captrue: boolean = false;

  constructor(
    position: PositionType,
    moduleInstances: PiGridModules,
    data: any,
  ) {
    super(position, moduleInstances);
    this.data = data;
  }

  public click() {
    const content = convertDeltaToPureText(this.data.node.metadata.content);
    if (!content) return;
    this.moduleInstances.DataManager.config.setNodeMainbodyContent({
      content,
      position: this.position,
    });
  }

  public getColorAndBold() {
    const attrsStyle =
      this.moduleInstances.Draw.config.viewInfo.attrsStyle || {};
    const styleMode = this.moduleInstances.ChildNodesLoader.config.styleMode;
    if (!attrsStyle.mainbody)
      return {
        color: styleMode === 'darkness' ? 'white' : '#242D3F',
        bold: false,
      };
    return {
      color: attrsStyle.mainbody.content.color,
      bold: attrsStyle.mainbody.content.bold,
    };
  }

  public drawContent(text: string) {
    const { Draw } = this.moduleInstances;
    const { attrContentAlign } = Draw.config;
    let { x, y, height, width } = this.position;

    const textY = y + height / 2;

    const colorAndBold = this.getColorAndBold();

    Draw.attr({
      fillStyle: colorAndBold.color,
      textBaseline: 'middle',
      textAlign: attrContentAlign.mainbody === 'right' ? 'end' : 'start',
      font: `${colorAndBold.bold ? 'bold ' : ''}${Draw.npx(14)}px  sans-serif`,
    });

    const t = Draw.newFittingString(text || '', width);

    if (attrContentAlign.mainbody === 'right') x = x + width;
    if (attrContentAlign.mainbody === 'center')
      x = x - 10 + (width + 20 - Draw.measureTextWidth(t)) / 2;
    Draw.fillText(t, x, textY);
    return;
  }

  public render() {
    if (this.data.node.metadata.content)
      this.drawContent(convertDeltaToPureText(this.data.node.metadata.content));

    if (this.isHover) {
      this.click();
    }
  }
}

export default MainbodyContent;
