import BaseComponent from './_baseComponent';

export default class CurDateSelect extends BaseComponent {
  public render() {
    const { Draw, Render } = this.moduleInstances;

    let textColor = Draw.style.rangeSelect.color;
    if (this.isHover) {
      Render.setCursor('pointer');
      textColor = Draw.style.rangeSelect.hoverColor;
    }

    Draw.fillRoundRect(this.position, 5, Draw.style.rangeSelect.backgroundColor);
    Draw.ctx.textBaseline = 'middle';
    Draw.ctx.textAlign = 'center';
    Draw.ctx.font = `${Draw.npx(13)}px  Arial`;
    Draw.attr({ fillStyle: textColor });
    Draw.fillText(
      '今日',
      this.position.x + this.position.width / 2,
      this.position.y + this.position.height / 2 + 1,
    );
    Draw.restore();
  }

  public onClick() {
    const { Event, DateColumnManager } = this.moduleInstances;
    Event.handleMoveX(DateColumnManager.getTodayLineScrollOffSet(), true);
  }
}
