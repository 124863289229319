import { ArrowLeftOutlined } from '@ant-design/icons';
import type { ApiResponse, CurrentUser } from '@linkpi/core';
import { CASCADE_CONNECTOR, THEME_STATUS } from '@linkpi/core';
import type { RadioChangeEvent } from 'antd';
import { Input, message, Popconfirm, Radio, Select, Tree, TreeSelect } from 'antd';
import crypto from 'crypto-browserify';
import { Fragment, useEffect, useMemo, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import LinkPiModal from '@/components/LinkPiModal';

import './index.less';
interface propsType {
  cascade: CurrentUser.cascadeSetting;
  visible: boolean;
  setCascade: Function;
  quoteNodes: any[];
  templates: CurrentUser.TemplateInfo[];
}

function sha256(a: string) {
  const hash = crypto.createHash('sha256');
  hash.update(a);
  return hash.digest('hex');
}

const CascadeSetting = (props: propsType) => {
  const { visible, cascade, setCascade, quoteNodes, templates } = props;
  const [cascadeType, setCascadeType] = useState(0);
  const [cascadeQuoteNode, setCascadeQuoteNode] = useState<string>('');
  const [cascadeQuoteTemplate, setCascadeQuoteTemplate] = useState<string>('');
  const [cascadeTitles, setCascadeTitles] = useState<string[]>([]);
  const [cascadeNodes, setCascadeNodes] = useState<CurrentUser.cascadeNode[]>([]);
  const [nodesMap, setNodesMap] = useState<Record<string, ApiResponse.CurrentUser.cascadeNode>>({});
  const [nodeTitle, setNodeTitle] = useState('');
  const [editingIndex, setEditingIndex] = useState(-1);

  useEffect(() => {
    if (visible) {
      if (cascade) {
        handleCascadeNodes(JSON.parse(JSON.stringify(cascade.nodes)), cascade.type === 1);
        setCascadeTitles(cascade.titles);
        setCascadeType(cascade.type || 0);
        setCascadeQuoteNode(cascade.quoteNode || '');
        setCascadeQuoteTemplate(cascade.quoteTemp || '');
      } else {
        setCascadeNodes([
          {
            key: 'add',
            title: '新增',
          },
        ]);
        setCascadeTitles([]);
        setNodesMap({});
        setCascadeType(0);
        setCascadeQuoteNode('');
        setCascadeQuoteTemplate('');
      }
    }
  }, [visible, cascade]);

  const handleCascadeNodes = (newNodes: CurrentUser.cascadeNode[], hasAdd = false) => {
    const newMap: Record<string, ApiResponse.CurrentUser.cascadeNode> = {};
    function deep(nodes: CurrentUser.cascadeNode[], parentKey = '') {
      let cl = 0;
      nodes.forEach((n) => {
        if (n.routeKey) {
          newMap[n.key] = n;
        }
        if (n.children) {
          const nl = deep(n.children, n.key);
          if (cl < nl) cl = nl;
        }
      });
      if (!hasAdd)
        nodes.push({
          key: 'add'.concat(parentKey),
          title: '新增',
        });
      if (nodes.length === 0 || (nodes.length === 1 && hasAdd)) return cl;
      return cl + 1;
    }
    const level = deep(newNodes);
    setNodesMap(newMap);
    setCascadeNodes(newNodes);
    return level;
  };

  const handleTitleChange = (title: string, index: number) => {
    const newTitles = [...cascadeTitles];
    newTitles[index] = title;
    setCascadeTitles(newTitles);
  };

  const findNodePath = (
    node: CurrentUser.cascadeNode,
    routes: string[],
    nMap: Record<string, ApiResponse.CurrentUser.cascadeNode>,
  ) => {
    if (node.parentKey) {
      const parentNode = nMap[node.parentKey];
      if (parentNode) {
        routes.push(parentNode.title);
        findNodePath(parentNode, routes, nMap);
      }
    }
  };

  const handleAddNode = (e: CurrentUser.cascadeNode) => {
    const title = nodeTitle;
    const newNodes = [...cascadeNodes];
    const newTitles = [...cascadeTitles];
    let newNode: CurrentUser.cascadeNode = {
      key: '',
      title: '',
    };
    if (e.key === 'add') {
      const key = '0-'.concat(sha256(title));
      if (nodesMap[key]) return message.error('已存在相同名称的选项');
      const addingNode = newNodes.pop();
      newNode = {
        key: key,
        routeKey: '1-'.concat(sha256(title.concat(CASCADE_CONNECTOR))),
        title: title,
        children: [
          {
            key: 'add' + key,
            title: '新增',
          },
        ],
      };
      newNodes.push(newNode);
      if (addingNode) newNodes.push(addingNode);
      if (newTitles.length === 0) newTitles.push('第1级');
    } else if (e.key.startsWith('add')) {
      const addingKey = e.key.substring(3);
      let node: CurrentUser.cascadeNode = {
        key: '',
        title: '',
      };
      const deep = (children: CurrentUser.cascadeNode[]) => {
        children.some((item) => {
          if (node.key) return true;
          if (item.key === addingKey) {
            node = item;
            return true;
          }
          if (item.children && item.children?.length > 1) {
            deep(item.children);
          }
          return false;
        });
      };
      deep(newNodes);
      if (node.children) {
        const routes = [node.title];
        findNodePath(node, routes, nodesMap);
        const levelPre = (routes.length + 1).toString().concat('-');
        const key = '0-'.concat(
          sha256(routes.reverse().join(CASCADE_CONNECTOR).concat(CASCADE_CONNECTOR, title)),
        );
        if (nodesMap[key]) return message.warn('已存在相同名称的选项');
        const addingNode = node.children.pop();
        newNode = {
          key: key,
          routeKey: levelPre.concat(
            sha256(
              routes
                .reverse()
                .join(CASCADE_CONNECTOR)
                .concat(CASCADE_CONNECTOR, title, CASCADE_CONNECTOR),
            ),
          ),
          title: title,
          parentKey: node.key,
          children: [
            {
              key: 'add' + key,
              title: '新增',
            },
          ],
        };
        node.children.push(newNode);
        if (addingNode) node.children.push(addingNode);
        if (newTitles.length < routes.length + 1) newTitles.push(`第${routes.length + 1}级`);
      }
    }
    if (newNode.routeKey) {
      setCascadeNodes(newNodes);
      setNodesMap({
        ...nodesMap,
        [newNode.key]: newNode,
      });
      setCascadeTitles(newTitles);
      setNodeTitle('');
    }
  };

  const handleDelNode = (node: CurrentUser.cascadeNode) => {
    const newNodes = [...cascadeNodes];
    if (node.parentKey) {
      let parentNode: CurrentUser.cascadeNode = {
        key: '',
        title: '',
      };

      const deep = (children: CurrentUser.cascadeNode[]) => {
        children.some((item) => {
          if (parentNode.key) return true;
          if (item.key === node.parentKey) {
            parentNode = item;
            return true;
          }
          if (item.children && item.children?.length > 1) {
            deep(item.children);
          }
          return false;
        });
      };

      deep(newNodes);
      if (parentNode.children) {
        const index = parentNode.children.findIndex((x) => x.key === node.key);
        if (~index) parentNode.children.splice(index, 1);
      }
    } else {
      const index = newNodes.findIndex((x) => x.key === node.key);
      if (~index) newNodes.splice(index, 1);
    }
    const currentLevel = handleCascadeNodes(newNodes, true);
    if (cascadeTitles.length > currentLevel) {
      setCascadeTitles(cascadeTitles.slice(0, currentLevel));
    }
  };

  const handleEditNode = (node: CurrentUser.cascadeNode, title: string) => {
    const routes = [title];
    if (node.parentKey) {
      findNodePath(node, routes, nodesMap);
    }
    const levelPre = routes.length + '-';
    const key = '0-'.concat(sha256(routes.reverse().join(CASCADE_CONNECTOR)));
    const routeKey = levelPre.concat(
      sha256(routes.reverse().join(CASCADE_CONNECTOR).concat(CASCADE_CONNECTOR)),
    );
    if (nodesMap[key]) return;
    const newMap = JSON.parse(JSON.stringify(nodesMap));
    if (newMap[node.key]) {
      newMap[node.key].title = title;
    }
    function genNewKey(nodes: CurrentUser.cascadeNode[], parentKey: string) {
      nodes.forEach((n) => {
        if (!n.routeKey) return;
        const r = [n.title];
        if (n.parentKey) {
          findNodePath(n, r, newMap);
        }
        const lp = r.length + '-';
        const k = '0-'.concat(sha256(r.reverse().join(CASCADE_CONNECTOR)));
        const rk = lp.concat(sha256(r.reverse().join(CASCADE_CONNECTOR).concat(CASCADE_CONNECTOR)));
        if (n.children) {
          n.children.pop();
          genNewKey(n.children, k);
          n.children.push({
            key: 'add'.concat(k),
            title: '新增',
          });
        }
        delete newMap[n.key];
        n.key = k;
        n.routeKey = rk;
        n.parentKey = parentKey;
        newMap[k] = n;
      });
    }
    if (node.children) {
      node.children.pop();
      genNewKey(node.children, key);
      node.children.push({
        key: 'add'.concat(key),
        title: '新增',
      });
    }
    delete newMap[node.key];
    node.key = key;
    node.title = title;
    node.routeKey = routeKey;
    newMap[key] = node;
    setNodesMap(newMap);
  };

  const onTypeChange = (e: RadioChangeEvent) => {
    setCascadeType(e.target.value);
    if (e.target.value === cascade?.type) {
      handleCascadeNodes(JSON.parse(JSON.stringify(cascade.nodes)), cascade.type === 1);
      setCascadeTitles(cascade.titles);
      setCascadeType(cascade.type);
      setCascadeQuoteNode(cascade.quoteNode || '');
      setCascadeQuoteTemplate(cascade.quoteTemp || '');
    } else {
      setCascadeNodes(
        e.target.value === 0
          ? [
              {
                key: 'add',
                title: '新增',
              },
            ]
          : [],
      );
      setCascadeTitles([]);
      setNodesMap({});
      setCascadeQuoteNode('');
      setCascadeQuoteTemplate('');
    }
  };

  const modalContent = useMemo(() => {
    return (
      // @ts-ignore
      <PerfectScrollbar>
        <div id={'CascadeSetting'}>
          <div className={'cascade-type'}>
            <Radio.Group onChange={onTypeChange} value={cascadeType}>
              <Radio value={0}>自定义选项</Radio>
              <Radio value={1}>引用主题树作为选项</Radio>
            </Radio.Group>
          </div>
          {cascadeType === 1 ? (
            <Fragment>
              <div className={'cascade-quote'}>
                <div className={'cascade-quote-label'}>
                  选择引用的主题树<span style={{ color: '#F0665E', marginLeft: 4 }}>*</span>
                </div>
                <TreeSelect
                  placeholder="引用的主题树的标题将作为选项"
                  value={cascadeQuoteNode || undefined}
                  treeData={quoteNodes}
                  showSearch={true}
                  treeDefaultExpandedKeys={[cascadeQuoteNode || quoteNodes[0]?.value]}
                  filterTreeNode={(inputValue, treeNode) =>
                    typeof treeNode.title === 'string' &&
                    !!~treeNode.title.toLowerCase().indexOf(inputValue.toLowerCase())
                  }
                  onChange={(e) => setCascadeQuoteNode(e)}
                />
              </div>
              <div className={'cascade-quote'}>
                <div className={'cascade-quote-label'}>选择主题类型</div>
                <Select
                  placeholder="请选择主题类型（非必填）"
                  value={cascadeQuoteTemplate || undefined}
                  showSearch={true}
                  options={templates
                    .filter((t) => t.status === THEME_STATUS.init || t.status === THEME_STATUS.lock)
                    .map((template, i) => {
                      return {
                        label: template.name,
                        value: template.template_id,
                      };
                    })}
                  filterOption={(input, option) =>
                    typeof option?.label === 'string' &&
                    option?.label.toLowerCase().indexOf(input?.toLowerCase()) >= 0
                  }
                  onChange={(e) => setCascadeQuoteTemplate(e)}
                />
              </div>
              {cascade?.type === 1 &&
              cascadeNodes.length &&
              cascade.quoteNode === cascadeQuoteNode ? (
                <Tree
                  treeData={cascade.nodes}
                  rootStyle={{ background: '#f5f5f5', marginRight: 52, padding: '8px 0' }}
                />
              ) : cascadeQuoteNode ? (
                <div className={'cascade-quote-tips'}>选项生成中，请稍后查看</div>
              ) : null}
            </Fragment>
          ) : (
            <Fragment>
              <div className={'title-setting'}>
                {cascadeTitles.map((t: string, i) => {
                  return editingIndex === i ? (
                    <Input
                      className={'title-setting-item'}
                      value={t}
                      onChange={(e) => handleTitleChange(e.target.value, i)}
                      onPressEnter={() => setEditingIndex(-1)}
                      onBlur={() => setEditingIndex(-1)}
                    />
                  ) : (
                    <div className={'title-setting-item'} key={i}>
                      <div className={'text-omit'}>{t}</div>
                      <i className={'iconfont iconedit1'} onClick={() => setEditingIndex(i)} />
                    </div>
                  );
                })}
              </div>
              <div className={'tree-setting'}>
                <Tree
                  treeData={cascadeNodes}
                  titleRender={(e) => {
                    if (!e.routeKey) {
                      return (
                        <Popconfirm
                          title={
                            <Input
                              value={nodeTitle}
                              onChange={(c) => setNodeTitle(c.target.value)}
                            />
                          }
                          okText={'确认'}
                          onConfirm={() => handleAddNode(e)}
                          icon={null}
                          placement={'bottomLeft'}
                          overlayClassName={'cascade-confirm-pop'}
                        >
                          <div className={'tree-setting-node add'}>
                            <i className={'iconfont iconparticipants_add_h'} /> 新增
                          </div>
                        </Popconfirm>
                      );
                    }
                    return (
                      <div className={'tree-setting-node'}>
                        <Input
                          defaultValue={e.title}
                          // @ts-ignore
                          onPressEnter={(v) => handleEditNode(e, v.target.value)}
                          onBlur={(v) => handleEditNode(e, v.target.value)}
                        />
                        <i
                          className={'iconfont iconButton_cancel'}
                          onClick={() => handleDelNode(e)}
                        />
                      </div>
                    );
                  }}
                />
              </div>
            </Fragment>
          )}
        </div>
      </PerfectScrollbar>
    );
  }, [cascadeTitles, cascadeNodes, nodeTitle, editingIndex, cascadeType, cascadeQuoteNode]);

  const handleSettingSave = () => {
    const newNodes = [...cascadeNodes];
    function delAdding(nodes: CurrentUser.cascadeNode[]) {
      nodes.pop();
      nodes.forEach((n: CurrentUser.cascadeNode) => {
        if (n.children) {
          delAdding(n.children);
        }
      });
    }
    if (cascadeType === 0) delAdding(newNodes);
    setCascade &&
      setCascade({
        nodes: newNodes,
        titles: cascadeTitles,
        nodesMap: nodesMap,
        type: cascadeType,
        quoteNode: cascadeType === 0 ? '' : cascadeQuoteNode,
        quoteTemp: cascadeType === 0 ? '' : cascadeQuoteTemplate,
      });
  };

  return (
    <LinkPiModal
      title={
        <div onClick={handleSettingSave}>
          <ArrowLeftOutlined style={{ marginRight: 12, cursor: 'pointer' }} />
          选项设置
        </div>
      }
      visible={visible}
      onCancel={handleSettingSave}
      modalContent={modalContent}
      width={760}
      noFooter={true}
      className={'cascade-setting-modal'}
    />
  );
};

export default CascadeSetting;
