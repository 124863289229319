import type { PiGridModules } from '../core';
import type { HeaderCellType } from '../modules/dataManager';
import type { PositionType } from './_baseComponent';
import GridBaseComponent from './_baseComponent';

export default class HeaderMenu extends GridBaseComponent {
  private data: HeaderCellType;
  constructor(position: PositionType, moduleInstances: PiGridModules, data: HeaderCellType) {
    super(position, moduleInstances);
    this.data = data;
  }
  public captrue: boolean = false;

  public click() {
    const { DataManager } = this.moduleInstances;

    DataManager.config.setShowHeaderMoreActions({
      index: this.data.index,
      key: this.data.key,
      position: this.position,
    });
  }

  public drawIcon(x: number, y: number, color: string, unicode: string) {
    const { Draw } = this.moduleInstances;
    const { ctx } = Draw;

    ctx.save();
    Draw.attr({
      fillStyle: color,
      textBaseline: 'middle',
      textAlign: 'center',
      font: `${Draw.npx(14)}px iconfont`,
    });

    Draw.fillText(Draw.iconFont(unicode), x, y);
    ctx.restore();
  }

  public render() {
    const { Render } = this.moduleInstances;
    const { x, y, height, width } = this.position;

    this.drawIcon(x + width / 2, y + height / 2, '#666', '&#xe65e;');

    if (this.isHover) {
      Render.setCursor('pointer');
    }
  }
}
