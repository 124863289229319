import type { GetterPiNode } from '@linkpi/core/web';
import { useMemo } from 'react';
import { match, P } from 'ts-pattern';

import type { IPictureConfig } from '@/components/PageModelEditor/src/widgets/PictureWidget/Setting';

interface PictureProps {
  data: IPictureConfig;
  node: GetterPiNode;
}

export const Picture = (props: PictureProps) => {
  const { data, node } = props;

  const url = useMemo(() => {
    return match([data.type, data.value])
      .with([P._, P.nullish], () => null)
      .with(['link', P.string], ['upload', P.string], () => {
        return data.value;
      })
      .with(['prop', P.string], () => {
        const attachment = node.value.prop._sys_attach?.[data.value!]?.[0];

        return attachment;
      })
      .exhaustive();
  }, [data.type, data.value, node.value.prop._sys_attach]);

  return (
    <div className="w-full h-full">
      {url ? (
        <img
          src={url}
          alt="picture component"
          style={{
            objectFit: data.fit || 'none',
            objectPosition: data.align,
            width: '100%',
          }}
        />
      ) : (
        <div className="w-full h-full flex items-center justify-center text-[#BFC6D2] text-[14px]">
          暂无图片
        </div>
      )}
    </div>
  );
};
