import { Dropdown, Menu } from 'antd';
import { useState } from 'react';

interface CalcProps {
  stat: string[];
  groupKey: string;
  cellType: string;
}

interface CalcPosition {
  x: number;
  y: number;
  width: number;
  height: number;
}

interface IProps {
  calcProps: CalcProps;
  calcPosition: CalcPosition;
  setStatConfig: (cellType: string, key: UseStatType) => void;
  placement: 'top' | 'bottom';
  setCalcPopover: (comp: null) => void;
  saveTableConfig: (value: any, key: 'tLineheight' | 'tShowFieldsIndex' | 'stat') => void;
}

type UseStatType = 'none' | 'count' | 'sum' | 'ave' | 'max' | 'min' | undefined | null;

const labels = {
  none: { label: '不展示', key: 'none' },
  count: { label: '记录总数', key: 'count' },
  sum: { label: '求和', key: 'sum' },
  ave: { label: '平均值', key: 'ave' },
  max: { label: '最大值', key: 'max' },
  min: { label: '最小值', key: 'min' },
  uniqueCount: { label: '去重计数', key: 'uniqueCount' },
  notEmptyUniqueCount: { label: '非空去重计数', key: 'notEmptyUniqueCount' },
  enumUniqueCount: { label: '选值去重计数', key: 'enumUniqueCount' },
};

export default (props: IProps) => {
  const { calcPosition, calcProps, setStatConfig, placement, setCalcPopover, saveTableConfig } =
    props;
  const { x, y, height, width } = calcPosition;

  const [open, setOpen] = useState<boolean>(true);

  const changeOpen = (v: boolean) => {
    if (!v) return setCalcPopover(null);
    setOpen(v);
  };

  const menuClick = (e: any) => {
    const { cellType } = calcProps;
    const { key } = e;

    const statConfig = setStatConfig(cellType, key);
    saveTableConfig(statConfig, 'stat');
    setCalcPopover(null);
  };

  const renderMenu = () => {
    const { stat } = calcProps;
    const ary: string[] = ['none', ...stat];

    return (
      <Menu onClick={menuClick}>
        {ary.map((key) => (
          <Menu.Item
            key={key}
            //@ts-ignore
            children={labels[key].label}
          />
        ))}
      </Menu>
    );
  };

  return (
    <Dropdown
      trigger={['click']}
      overlay={renderMenu()}
      placement={placement}
      visible={open}
      onVisibleChange={changeOpen}
    >
      <div
        style={{
          position: 'absolute',
          height,
          width,
          left: x,
          top: y,
        }}
      />
    </Dropdown>
  );
};
