const typeMap = {
  localhost: 'local', // 本地
  '127.0.0.1': 'local', // 本地
  '0.0.0.0': 'local', // 本地
  'test-inner.linkerpi.com': 'pre', // 预发
  'cfg.mylinkpi.com': 'pre',
  'pre-release.linkerpi.com': 'pre',
  'lab.mylinkpi.com': 'iso1', // 孤岛1
} as const;
type ENV_TYPE = (typeof typeMap)[keyof typeof typeMap];

export function getBuildNumber() {
  const hostname = (window.location?.hostname || 'localhost') as keyof typeof typeMap;
  let type: ENV_TYPE = typeMap[hostname] || 'local';
  let visible = Object.keys(typeMap).includes(hostname);

  if (!visible) {
    const result = hostname.match(/(.*)\.test\.mylinkpi\.com/);
    visible = !!result;
    type = (result?.[1] as ENV_TYPE) || 'local';
  }

  return [__BUILD_NUMBER__, visible, type] as const;
}
