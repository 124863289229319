import { InputNumber, Select } from 'antd';
import type { FC } from 'react';

import { useTempPropConfig } from '../hooks';
import type { PropOption } from '../types';

interface IAutoType {
  tempProps: PropOption[];
}
export const AutoIncType: FC<IAutoType> = (props) => {
  const { tempProps } = props;
  const { isLock, propParams, setPropParams } = useTempPropConfig();

  const saveInc = (value: any, key: 'inc_prop' | 'start' | 'step' | 'cycle') => {
    // @ts-ignore
    setPropParams({ ...propParams, inc: { ...propParams.inc, [key]: value } });
  };

  return (
    <>
      <div className={'prop-item'} key={'incProp'}>
        <div className={'prop-item-label'}>自增依据</div>
        <div className={'prop-item-input'}>
          <Select
            disabled={isLock}
            showSearch={true}
            placeholder={'请选择属性（不选择时，将按当前主题类型生成自增ID）'}
            filterOption={(input, option) =>
              typeof option?.label === 'string' &&
              !!~option?.label.toLowerCase().indexOf(input.toLowerCase())
            }
            options={tempProps.map((p) => {
              return {
                label: p.name,
                value: p.index,
              };
            })}
            mode={'multiple'}
            value={propParams.inc?.inc_prop}
            onChange={(e) => saveInc(e, 'inc_prop')}
          />
        </div>
      </div>
      <div className={'prop-item'} key={'incStart'}>
        <div className={'prop-item-label'}>起始值</div>
        <div className={'prop-item-input'}>
          <InputNumber
            disabled={isLock}
            value={propParams.inc?.start}
            onChange={(e) => saveInc(e, 'start')}
          />
        </div>
      </div>
      <div className={'prop-item'} key={'incStep'}>
        <div className={'prop-item-label'}>步进值</div>
        <div className={'prop-item-input'}>
          <div className={'prop-item-input-btn active'}>自定义</div>
          <div className={'prop-item-input-btn'}>随机值</div>
        </div>
        <div className={'prop-item-space small'} />
        <div className={'prop-item-input'}>
          <InputNumber
            disabled={isLock}
            value={propParams.inc?.step}
            onChange={(e) => saveInc(e, 'step')}
          />
        </div>
      </div>
      <div className={'prop-item'} key={'incCycle'}>
        <div className={'prop-item-label'}>重置</div>
        <div className={'prop-item-input'}>
          <Select
            disabled={isLock}
            placeholder={'请选择'}
            value={propParams.inc?.cycle}
            defaultValue={null}
            onChange={(e) => saveInc(e, 'cycle')}
            options={[
              {
                label: '不重置',
                value: null,
              },
              {
                label: '每小时',
                value: 'hour',
              },
              {
                label: '每日',
                value: 'day',
              },
              {
                label: '每月',
                value: 'mon',
              },
              {
                label: '每年',
                value: 'year',
              },
            ]}
          />
        </div>
      </div>
    </>
  );
};
