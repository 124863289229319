import type { CurrentUser } from '@linkpi/core';

import request from '@/utils/request';

import { updateTemplate } from './space';

type EditTemplateParams = {
  tempId: string;
  orgId: string;
  prop?: CurrentUser.TemplateProp[];
  tempOption?: CurrentUser.TempOption;
};

/**
 * 更新主题类型属性, 全量的喔
 */
export function editTemplatePropV2(params: EditTemplateParams) {
  const data: any = {
    template_id: params.tempId,
    org_id: params.orgId,
  };

  if (params.prop) {
    data!.prop = JSON.stringify(params.prop);
  }

  if (params.tempOption) {
    data.temp_option = params.tempOption;
  }

  return updateTemplate(data).then((res) => {
    if (res.status === 'error') return Promise.reject(new Error('接口错误'));
    return res;
  });
}

export interface ICheckingProp {
  name: string;
  type: 'text';
  value: string;
}
export function getOrgBindInfo(params: unknown) {
  return request<
    {
      name: string;
      checkProps: ICheckingProp[];
    }[]
  >('/api/organization/getBindInfo', {
    method: 'POST',
    data: params,
  });
}

type ICheckUpload = {
  org_id: string;
  node_id?: string;
  file_length: number | string;
  file_name?: string;
  md5: string;
};
export const checkUpload = (params: ICheckUpload) => {
  return request('/api/file/check_upload', {
    method: 'POST',
    data: params,
  }) as Promise<
    (
      | {
          status: 'ok';
          data: string;
        }
      | { status: 'error'; message: string }
    ) & {
      status: string;
      bucket: string;
      region: string;
      token: {
        AccessKeyId: string;
        AccessKeySecret: string;
        SecurityToken: string;
      };
    }
  >;
};

export const autoImportThemeToTableHeader = (params: { org_id: string; temp_id: string }) => {
  return request('/api/tableHeader/tempImport', {
    method: 'POST',
    data: params,
  });
};
