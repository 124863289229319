import { SIDE_MODE } from '@linkpi/core';
import { useDispatch } from '@umijs/max';
import { Button } from 'antd';

const NodeDeleteGuideButton = () => {
  const dispatch = useDispatch();

  const goto = () => {
    dispatch({
      type: 'workspace/setCurrentSelection',
      payload: {
        selectSideMode: SIDE_MODE.RECYCLEBIN,
      },
    });
  };
  return (
    <Button type="ghost" onClick={goto}>
      前往回收站查看
    </Button>
  );
};

export default NodeDeleteGuideButton;
