import { MenuOutlined, PlusOutlined } from '@ant-design/icons';
import { DragSortTable, type ProColumnType } from '@ant-design/pro-table';
import type { ConditionRuleConfigV2 } from '@linkpi/core';
import { delay } from '@linkpi/utils';
import { useSelections } from 'ahooks';
import { Button, Row, Space, Switch, Typography } from 'antd';
import dayjs from 'dayjs';
import type { ReactNode } from 'react';
import { type FC, useEffect, useMemo } from 'react';
import { match, P } from 'ts-pattern';

import ConfirmButton from '@/components/ConfirmButton';

import { useSortedRuleList } from './hooks';

import styles from './DragTable.less';

const { Paragraph, Text } = Typography;

const dragSortHandlerRender = () => (
  <div className="cursor-grab text-gray-950 rounded-sm hover:bg-blue-100 active:cursor-grabbing transition-colors justify-center items-center size-[21px] text-[13px]">
    <MenuOutlined />
  </div>
);

export type RuleList<T extends ConditionRuleConfigV2.ConditionRuleConfigV2> = {
  ruleList: T[];
  updateList: (rule: T[]) => void;
  updateRule: (rule: T) => void;
  addRule: (rule: T, copy?: boolean) => Promise<void>;
  toggleRules: (ids: string[], enable: boolean) => Promise<void>;
  deleteRules: (ids: string[]) => Promise<void>;
  loading: boolean;
};

export type UseRuleList<T extends ConditionRuleConfigV2.ConditionRuleConfigV2, P> = (
  ...args: P[]
) => RuleList<T>;

const arr: any[] = [];

export const createConditionRulesTable = <T extends ConditionRuleConfigV2.ConditionRuleConfigV2, P>(
  useRuleList: UseRuleList<T, P>,
) => {
  const ConditionRulesTable: FC<
    {
      /**
       * 是否立即排序
       * 默认： false
       *
       * 开启后，移除独立保存按钮的应用
       */
      immediateSorting?: boolean;
      title?: string | ReactNode;
      ruleListInstance?: RuleList<T>;
      columns?: ProColumnType<T>[];
      onEdit: (rule: T) => Promise<T>;
      onCreate: () => Promise<T>;
    } & ({ useRuleListArgs: P[] } | { ruleListInstance: RuleList<T> })
  > = ({
    immediateSorting = false,
    onEdit,
    onCreate,
    columns: customColumns = arr,
    title,
    ...restProps
  }) => {
    const { ruleList, updateList, updateRule, addRule, deleteRules, toggleRules, loading } = match(
      restProps,
    )
      .with({ ruleListInstance: P.nonNullable }, (c) => c.ruleListInstance)
      // eslint-disable-next-line react-hooks/rules-of-hooks
      .otherwise((c) => useRuleList(...c.useRuleListArgs));

    const { sortedList, sorted, setSorted, setSortedList } = useSortedRuleList({
      list: ruleList,
      setList: updateList,
      immediateSorting,
    });

    const { selected, setSelected } = useSelections(
      ruleList.map((i) => i.ruleId),
      [],
    );

    useEffect(() => {
      setSelected([]);
    }, [setSelected, sorted]);

    const enableNum = ruleList.filter((i) => i.enable).length;

    const columns = useMemo(
      () =>
        [
          {
            title: '排序',
            align: 'center',
            dataIndex: 'sort',
            width: 48,
          },
          ...customColumns,
          {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            width: 160,
            render: (v: string | number) => <Text>{dayjs(v).format('YYYY-MM-DD HH:mm')}</Text>,
          },
          {
            title: '操作',
            key: 'actions',
            width: 228,
            render: (record: T) => {
              return (
                <Space align="center" className={styles.actions}>
                  <Switch
                    onChange={(checked) => toggleRules([record.ruleId], checked)}
                    checked={record.enable}
                    size="small"
                    checkedChildren="开"
                    unCheckedChildren="关"
                  />
                  <Button
                    type="link"
                    size="small"
                    onClick={() => {
                      onEdit(record).then((r) => {
                        updateRule({
                          ...record,
                          ...r,
                        });
                      });
                    }}
                  >
                    编辑
                  </Button>
                  <Button
                    type="link"
                    size="small"
                    disabled={sorted}
                    onClick={() => {
                      addRule(record, true);
                    }}
                  >
                    复制
                  </Button>
                  <ConfirmButton
                    type="link"
                    size="small"
                    disabled={sorted}
                    onConfirm={() => deleteRules([record.ruleId])}
                  >
                    {sorted ? '删除' : <Text type="danger">删除</Text>}
                  </ConfirmButton>
                </Space>
              );
            },
          },
        ] as ProColumnType<T>[],
      [addRule, customColumns, deleteRules, onEdit, sorted, toggleRules, updateRule],
    );

    return (
      <div>
        {title && <Paragraph type="secondary">{title}</Paragraph>}
        <Row className={styles.buttons}>
          <Space align="center" size={20}>
            <Text strong className={styles.title}>
              规则列表
            </Text>
            <Text>
              <span className={styles.enableNum}>{enableNum}</span> 个开启中
            </Text>
          </Space>
          <Space align="center" size={16}>
            {sorted && (
              <>
                <ConfirmButton
                  type="primary"
                  loading={loading}
                  onConfirm={async () => {
                    await updateList(sortedList);
                    await delay(1000);
                    setSorted(false);
                  }}
                >
                  保存排序
                </ConfirmButton>
                <ConfirmButton
                  type="primary"
                  ghost
                  onConfirm={async () => {
                    setSorted(false);
                  }}
                >
                  取消排序
                </ConfirmButton>
              </>
            )}
            {!!selected.length && (
              <>
                <ConfirmButton
                  type="primary"
                  ghost
                  onConfirm={async () => {
                    await toggleRules(selected, true);
                  }}
                >
                  开启规则
                </ConfirmButton>
                <ConfirmButton
                  type="primary"
                  ghost
                  onConfirm={async () => {
                    await toggleRules(selected, false);
                  }}
                >
                  关闭规则
                </ConfirmButton>
                <ConfirmButton
                  type="default"
                  danger
                  onConfirm={async () => {
                    await deleteRules(selected);
                    setSelected([]);
                  }}
                >
                  删除规则
                </ConfirmButton>
              </>
            )}
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                onCreate().then(addRule);
              }}
            >
              创建规则
            </Button>
          </Space>
        </Row>
        <DragSortTable
          search={false}
          toolBarRender={false}
          tableAlertRender={false}
          loading={loading}
          columns={columns}
          rowKey="ruleId"
          rowSelection={{
            selections: true,
            selectedRowKeys: selected,
            onChange: (v) => setSelected(v as string[]),
            getCheckboxProps: () => (sorted ? { disabled: true } : {}),
          }}
          className={styles.ruleTable}
          dataSource={sortedList}
          dragSortKey={selected.length ? undefined : 'sort'}
          dragSortHandlerRender={dragSortHandlerRender}
          onDragSortEnd={setSortedList}
          pagination={false}
        />
      </div>
    );
  };

  return { ConditionRulesTable };
};
