import { GetterPiNode } from '@linkpi/core/web';
import { message, Modal, Popover, Progress } from 'antd';
import { useRef, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import EditProps from '@/components/EditProps';
import { useCurrentUser } from '@/hook';
import PropsEditModal from '@/pages/home/components/View/ListTable/PropsEditModal';
import EditRepeatTaskModal from '@/pages/home/components/View/ViewSetting/EditRepeatTaskModal';
import EditSysTag from '@/pages/home/components/View/ViewSetting/EditSysTag';
import request from '@/utils/request';

export default (props: any) => {
  const { template, checkId, groupData, getterPiNode, orgInfo, userMap, setCheckId, templateList } =
    props;

  const propsEditRef = useRef<any>();
  const currentUser = useCurrentUser();
  const [propEditVisible, setPropEditVisible] = useState(false);
  // 批量编辑系统标签
  const [editSysTagVisible, setEditSysTagVisible] = useState(false);
  // 批量编辑重复
  const [editRepeatTaskModalVisible, setEditRepeatTaskModalVisible] = useState(false);

  // 节点批量操作的进度
  const [showProgressModal, setShowProgressModal] = useState(false);
  const [progressFinished, setProgressFinished] = useState(0);
  const [progressTotal, setProgressTotal] = useState(0);
  const [progressOver, setProgressOver] = useState(false);
  const [failed, setFailed] = useState<any>(0);

  // 批量更新状态属性相关
  const [showStatusAttrModal, setShowStatusAttrModal] = useState(false);
  const [statusAttrIndex, setStatusAttrIndex] = useState(0);
  const [statusAttrConfig, setStatusAttrConfig] = useState<any>({});
  const [statusAttrValue, setStatusAttrValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const renderPopoverContent = () => {
    // 去除不显示的属性
    const showAttrs = (template.prop || []).map((p: any, index: number) => ({ ...p, index }));
    const hasShowAttrs = showAttrs.filter(
      (i: any) => !i.hide && i.type && i.type !== 'quote' && i.type !== 'formula',
    );

    // 表格全部的节点
    const nodes: any[] = [];
    Object.values(groupData || {}).forEach((i: any) => nodes.push(...i.list));

    // 该主题类型的所有的状态
    const task_status = template.task_status;

    // 选择的节点的所有状态
    const indexs: any[] = [];
    nodes.forEach((n: any) => {
      const _sys_task_status = n.prop._sys_task_status || {};
      // 被选中的节点
      if (checkId.includes(n.id) && !indexs.includes(_sys_task_status.index)) {
        indexs.push(_sys_task_status.index);
      }
    });

    // 未被删除的状态
    const status = task_status.filter(
      (s: any, index: number) => !s.delete && indexs.includes(index),
    );

    // 共同的状态属性
    const ary = new Array(4).fill(true);
    status.forEach((s: any) => {
      const { prop = [] } = s;

      prop.forEach((p: any, i: number) => {
        if (p.display === false) ary[i] = false;
      });
    });

    // 共同的状态属性
    const commonStatusAttrs = status[0] ? status[0].prop : [];
    const hasCommonStatusAttrs = commonStatusAttrs.filter((_: any, i: number) => ary[i]).length;

    // 是否都有开始时间
    const hasStartDate = ary[2] || false;

    return (
      <div style={{ maxHeight: 600 }} className="view-setting-list">
        <h4>标签</h4>
        <li
          key="systag"
          className="view-setting-item"
          onClick={() => {
            setEditSysTagVisible(true);
            setPropEditVisible(false);
          }}
        >
          标签
        </li>
        {/* 主题类型属性 */}
        <h4>属性</h4>
        {hasShowAttrs.length ? (
          hasShowAttrs.map((item: any) => (
            <li
              key={item.index}
              className="view-setting-item"
              onClick={() => {
                propsEditRef.current.open(`prop_${item.index}`, checkId);
                setPropEditVisible(false);
              }}
            >
              {item.name}
            </li>
          ))
        ) : (
          <li>暂无属性</li>
        )}

        {/* 主题类型状态属性 */}
        <h4>状态属性</h4>
        {hasCommonStatusAttrs ? (
          commonStatusAttrs.map(
            (c: any, i: number) =>
              ary[i] && (
                <li
                  key={i}
                  className="view-setting-item"
                  onClick={() => {
                    setStatusAttrIndex(i);
                    setStatusAttrConfig(c);
                    setShowStatusAttrModal(true);

                    setPropEditVisible(false);
                  }}
                >
                  {c.name}
                </li>
              ),
          )
        ) : (
          <li children="暂无共同的状态属性" />
        )}
        {hasStartDate ? (
          <>
            <h4>重复</h4>
            <li
              key="repeat"
              className="view-setting-item"
              onClick={() => {
                setEditRepeatTaskModalVisible(true);
                setPropEditVisible(false);
              }}
            >
              重复
            </li>
          </>
        ) : null}
      </div>
    );
  };
  const getFirstEditNode = () =>
    new GetterPiNode(getterPiNode.value.nodeManager.findChildren(checkId[0]));
  const initProgress = () => {
    setProgressFinished(0);
    setProgressTotal(0);
    setProgressOver(false);
    setFailed(0);
  };
  const getProgress = async (async_id: string | number, total: number, type: string) => {
    const res: any = await request('/docapi/getBatchProgress', {
      method: 'POST',
      data: { type, async_id, total },
    });

    const { status, data, success } = res;
    if (status === 'ok') {
      setProgressFinished(data);
      if (data < total) {
        setTimeout(() => {
          getProgress(async_id, total, type);
        }, 1200);
      } else {
        // 已经全部处理结束
        setProgressOver(true);
        if (data !== Number(success)) return setFailed(data - Number(success));
        setCheckId([]);
        setTimeout(() => {
          setShowProgressModal(false);
          initProgress();
        }, 2000);
      }
    } else {
      setTimeout(() => {
        getProgress(async_id, total, type);
      }, 1200);
    }
  };

  const batchUpdateProp = async (payload: any) => {
    const res: any = await request('/docapi/batchUpdateProp', {
      method: 'POST',
      data: payload,
    });

    const { total, status, async_id } = res;
    if (status === 'ok') {
      setShowProgressModal(true);
      setProgressTotal(total);
      setTimeout(() => {
        getProgress(async_id, total, 'update');
      }, 1200);
    }
  };

  const saveStatusAttr = async () => {
    const { template_id } = template;

    const payload = {
      nodeId: checkId,
      org_id: orgInfo.orgId,
      temp_id: template_id,
      modifyProp: [
        {
          key: statusAttrIndex,
          type: 's',
          value: statusAttrValue,
        },
      ],
    };

    setLoading(true);

    const res = await request('/docapi/batchSetAction', {
      method: 'POST',
      data: payload,
    });

    setLoading(false);

    if (res.status === 'ok') {
      message.success('编辑成功～');
      setShowStatusAttrModal(false);
      setStatusAttrValue(null);
    }
  };

  if (checkId.length < 1 || !template || !Array.isArray(template.prop)) return null;

  return (
    <>
      <Popover
        content={<PerfectScrollbar>{renderPopoverContent()}</PerfectScrollbar>}
        trigger={['click']}
        overlayClassName="view-setting-pop"
        placement="bottomRight"
        visible={propEditVisible}
        onVisibleChange={() => setPropEditVisible(!propEditVisible)}
      >
        <div className="view-setting-block">
          <i className="iconfont iconView_Edit" />
          &nbsp;编辑属性
        </div>
      </Popover>
      <EditSysTag
        visible={editSysTagVisible}
        onCancel={() => setEditSysTagVisible(false)}
        onOk={() => setEditSysTagVisible(false)}
        nodeId={checkId}
        node={getterPiNode}
        orgId={orgInfo.orgId}
        userId={currentUser?.userid}
      />
      <PropsEditModal
        ref={propsEditRef}
        tempId={template.template_id}
        tempProps={template.prop || []}
        userMap={userMap}
        node={getFirstEditNode()}
        orgId={orgInfo.orgId}
        userId={currentUser?.userid}
        onHandle={batchUpdateProp}
      />
      <Modal
        footer={null}
        open={showProgressModal}
        closable={progressOver}
        maskClosable={false}
        onCancel={() => {
          setCheckId([]);
          setShowProgressModal(false);
          initProgress();
        }}
      >
        <div style={{ padding: '24px 0 12px 0' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: 8 }}>{progressOver ? '修改完成!' : '修改中'}</div>
            {failed > 0 && (
              <>
                <div>成功{progressTotal - failed}条，</div>
                <div style={{ color: 'red' }}>失败：{failed}条</div>
              </>
            )}
          </div>
          <Progress percent={Math.ceil((progressFinished * 100) / progressTotal)} />
        </div>
      </Modal>
      <Modal
        open={showStatusAttrModal}
        onCancel={() => {
          setShowStatusAttrModal(false);
          setStatusAttrValue(null);
        }}
        title={`编辑 ${statusAttrConfig.name}`}
        onOk={saveStatusAttr}
        confirmLoading={loading}
      >
        <EditProps
          config={statusAttrConfig}
          userMap={userMap}
          value={statusAttrValue}
          onChange={setStatusAttrValue}
        />
      </Modal>
      <EditRepeatTaskModal
        visible={editRepeatTaskModalVisible}
        onCancel={() => setEditRepeatTaskModalVisible(false)}
        onOk={() => setEditRepeatTaskModalVisible(false)}
        nodeId={checkId}
        orgId={orgInfo.orgId}
        templateList={templateList}
      />
    </>
  );
};
