import { updateProp } from '@linkpi/core';
import { vaildExt, vaildMaxCount, vaildMaxSize } from '@linkpi/core';
import { message } from 'antd';

import request from '@/utils/request';
import { getTemplateProp, uploadFileInOrgNode } from '@/utils/utils';

import type { PositionType } from '../components/_baseComponent';
import GridBaseComponent from '../components/_baseComponent';
import type { PiGridModules } from '../core';
class FixAttachmentUpload extends GridBaseComponent {
  public node: PiNode;
  public chunk: any;
  public propConfig: any;
  public type: string = 'fixAttachmentUpload';
  public captrue: boolean = false;

  constructor(position: PositionType, moduleInstances: PiGridModules, node: any, chunk: any) {
    super(position, moduleInstances);
    this.node = node;
    this.chunk = chunk;
    this.propConfig = getTemplateProp(
      chunk.theme,
      chunk.propIndex,
      moduleInstances.DataManager.config.templateList,
    );
  }

  public click() {
    this.upload();
  }

  public upload() {
    const {
      config: { viewTemplate, orgInfo },
    } = this.moduleInstances.DataManager;
    const node = this.node;
    const index = this.chunk.propIndex;

    const input = document.createElement('input');

    input.setAttribute('type', 'file');
    input.setAttribute('multiple', 'multiple');
    input.setAttribute('style', 'visibility:hidden;');

    document.body.appendChild(input);
    input.click();

    input.onchange = async (e: any) => {
      const fileList = Array.from(e.target.files);
      // 附件上传限制
      const useableFileList: any[] = [];

      const nodeSysAttach: any = this.node.prop._sys_attach || {};
      // 当前节点已有的附件数
      let attachCount = nodeSysAttach[this.chunk.propIndex]?.length || 0;

      fileList.forEach((file: any) => {
        // 附件个数限制
        const validCountResult = vaildMaxCount(attachCount + 1, this.propConfig);
        if (validCountResult.status === 'error') {
          message.error(
            `文件数量不能多于${validCountResult.maxCount}个, ${file.name}文件上传失败! `,
          );
          return;
        }

        // 文件类型限制
        const vaildExtResult = vaildExt(file, this.propConfig);
        if (vaildExtResult.status === 'error') {
          message.error(`${file.name}文件类型不支持上传! `);
          return;
        }

        // 文件大小限制
        const vaildMaxSizeResult = vaildMaxSize(file, this.propConfig);
        if (vaildMaxSizeResult.status === 'error') {
          message.error(`${file.name}文件大小超过${vaildMaxSizeResult.maxSize}MB, 请重新选择! `);
          return;
        }

        useableFileList.push(file);
        attachCount++;
      });

      const uploadReq = useableFileList.map((f: any) => {
        return uploadFileInOrgNode(f, orgInfo.orgId, node.id);
      });

      const uploadRes = await Promise.all(uploadReq);

      // 老的节点附件属性
      const tempInfo = node.tempInfo;
      const fileNames = tempInfo.prop[index] || [];
      const _sys_attach = (node.prop || {})._sys_attach || {};
      const urls = _sys_attach[index] || [];
      // 更改节点属性
      const newValue = [...uploadRes.map((x: any) => x.fileName), ...fileNames];
      const newAttachment = [...uploadRes.map((x: any) => x.fileUrl), ...urls];
      const req = {
        org_id: orgInfo.orgId,
        temp_id: viewTemplate.template_id,
        node_id: node.id,
        index: [Number(index)],
        value: [newValue],
        attachment: {
          [index]: newAttachment,
        },
      };

      await updateProp(request, req);

      // 删除input
      document.body.removeChild(input);
    };
  }

  public drawIcon(x: number, y: number, color: string, unicode: string) {
    const { Draw } = this.moduleInstances;
    const { ctx } = Draw;

    ctx.save();
    Draw.attr({
      fillStyle: color,
      textBaseline: 'start',
      textAlign: 'start',
      font: `${Draw.npx(15)}px iconfont`,
    });

    Draw.fillText(Draw.iconFont(unicode), x, y);
    ctx.restore();
  }

  public render() {
    const { x, y, height } = this.position;
    const { Draw } = this.moduleInstances;

    Draw.fillRoundRect(this.position, 0, '#F8F9FB');
    Draw.strokeRoundRect(this.position, 0, 1, '#C9D0D9');

    // icon
    this.drawIcon(x + (height - 15) / 2, y + (height - 15) / 2, '#767C88', '&#xe728;');

    if (this.isHover) {
      this.moduleInstances.Render.setCursor('pointer');
    }
  }
}

export default FixAttachmentUpload;
