import type { CurrentUser, OrgRole, OrgUser } from '@linkpi/core';
import { buildRecord } from '@linkpi/utils';
import { useSelector } from '@umijs/max';
import { atom, getDefaultStore, useAtomValue } from 'jotai';

export const useCurrentUser = () => {
  const currentUser: CurrentUser = useSelector((s: any) => s.user.currentUser);

  return currentUser;
};

export const useCurrentUserRole = () => {
  const currentUserRole: OrgRole = useSelector(
    (s: any) => s.space.currentUserRole,
  );

  return currentUserRole;
};

const currentUserIdAtom = atom<string | undefined>(undefined);

export const setCurrentUserId = (id: string) => {
  const store = getDefaultStore();
  store.set(currentUserIdAtom, id);
};

const orgUserListAtom = atom<OrgUser.OrgUserItem[]>([]);
export const setUserList = (list: OrgUser.OrgUserItem[]) => {
  const store = getDefaultStore();
  store.set(orgUserListAtom, list);
};
export const getUserList = () => {
  const store = getDefaultStore();
  return store.get(orgUserListAtom);
};
export const useOrgUserList = () => {
  const userList = useAtomValue(orgUserListAtom, { store: getDefaultStore() });

  return userList;
};

const orgUserListMapAtom = atom((get) => {
  const userList = get(orgUserListAtom);
  return buildRecord(userList, 'account_id');
});
export const useOrgUserMap = () => {
  const userMap = useAtomValue(orgUserListMapAtom, {
    store: getDefaultStore(),
  });

  return userMap;
};

const currentUserInfoAtom = atom((get) => {
  const userId = get(currentUserIdAtom);
  if (!userId) return undefined;
  const userMap = get(orgUserListMapAtom);
  return userMap[userId];
});
export const getCurrentUserInfo = () => {
  const store = getDefaultStore();
  return store.get(currentUserInfoAtom);
};

/**
 * 获取当前用户在当前空间的用户信息
 */
export const useCurrentUserInfo = () => {
  const info = useAtomValue(currentUserInfoAtom, { store: getDefaultStore() });
  return info;
};
