import type { CurrentUser } from '@linkpi/core';
import type { OrgUser } from '@linkpi/core';
import { notEmpty } from '@linkpi/utils';
import { useControllableValue } from 'ahooks';
import type { SelectProps } from 'antd';
import { Select } from 'antd';
import type { FunctionComponent } from 'react';
import { useMemo } from 'react';

import { selectSearchFn } from '@/components/LinkPiForm/utils';
import { useOrgInfo, useOrgUserList } from '@/hook';

const checkGroup = (
  groupId: string,
  orgInfo: CurrentUser.OrgInfo,
  userInfo: OrgUser.OrgUserItem,
) => {
  if (groupId === '-1') return true;
  if (groupId === '-2' && (orgInfo.role === 1 || orgInfo.role === 3)) return true;
  return userInfo.group_ids?.includes(groupId);
};
const checkGroups = (
  groupIdList: string[],
  orgInfo: CurrentUser.OrgInfo,
  userInfo: OrgUser.OrgUserItem,
) => {
  for (let index = 0; index < groupIdList.length; index++) {
    const groupId = groupIdList[index];
    if (checkGroup(groupId, orgInfo, userInfo)) return true;
  }
  return false;
};

const SpaceUserSelect: FunctionComponent<
  React.PropsWithChildren<
    SelectProps & {
      groups?: string[];
    }
  >
> = ({ groups, ...props }) => {
  const [value, onChange] = useControllableValue(props);
  const userList = useOrgUserList();
  const orgInfo = useOrgInfo()[0]!;

  const options = useMemo(() => {
    return userList
      .map((i) => {
        const userName = i.org_nick_name || i.nick_name || i.user_name;
        if (Array.isArray(groups) && !checkGroups(groups, orgInfo, i)) {
          return null;
        }

        return {
          label: userName,
          value: i.account_id,
          name: userName,
        };
      })
      .filter(notEmpty);
  }, [groups, orgInfo, userList]);

  const finalProps: SelectProps = {
    ...props,
    value,
    onChange,
    options,
    filterOption: selectSearchFn,
  };
  return <Select {...finalProps} />;
};

export default SpaceUserSelect;
