/**
 * TODO: 使用 immer 复制数据, 检查表格排序功能 @yz @yuan7
 */

/* eslint-disable no-param-reassign */
import { isArray } from 'lodash';
import arrayMap from 'lodash/_arrayMap';
import baseGet from 'lodash/_baseGet';
import baseIteratee from 'lodash/_baseIteratee';
import baseMap from 'lodash/_baseMap';
import baseSortBy from 'lodash/_baseSortBy';
import baseUnary from 'lodash/_baseUnary';
import compareAscending from 'lodash/_compareAscending';
import identity from 'lodash/identity';

function compareMultiple(object, other, orders) {
  var index = -1,
    objCriteria = object.criteria,
    othCriteria = other.criteria,
    length = objCriteria.length,
    ordersLength = orders.length;
  while (++index < length) {
    const order = index < ordersLength ? orders[index] : null;
    const cmpFn = order && typeof order === 'function' ? order : compareAscending;
    const result = cmpFn(objCriteria[index], othCriteria[index]);
    if (result) {
      if (order && typeof order !== 'function') {
        return result * (order == 'desc' ? -1 : 1);
      }
      return result;
    }
  }
  // Fixes an `Array#sort` bug in the JS engine embedded in Adobe applications
  // that causes it, under certain circumstances, to provide the same value for
  // `object` and `other`. See https://github.com/jashkenas/underscore/pull/1247
  // for more details.
  //
  // This also ensures a stable sort in V8 and other engines.
  // See https://bugs.chromium.org/p/v8/issues/detail?id=90 for more details.
  return object.index - other.index;
}

function baseOrderBy(collection, iteratees, orders) {
  if (iteratees.length) {
    iteratees = arrayMap(iteratees, function (iteratee) {
      if (isArray(iteratee)) {
        return function (value) {
          return baseGet(value, iteratee.length === 1 ? iteratee[0] : iteratee);
        };
      }
      return iteratee;
    });
  } else {
    iteratees = [identity];
  }

  var index = -1;
  iteratees = arrayMap(iteratees, baseUnary(baseIteratee));

  var result = baseMap(collection, function (value, key, collection) {
    var criteria = arrayMap(iteratees, function (iteratee) {
      return iteratee(value);
    });
    return { criteria: criteria, index: ++index, value: value };
  });

  return baseSortBy(result, function (object, other) {
    return compareMultiple(object, other, orders);
  });
}

function orderBy(collection, iteratees, orders, guard) {
  if (collection == null) {
    return [];
  }
  if (!isArray(iteratees)) {
    iteratees = iteratees == null ? [] : [iteratees];
  }
  orders = guard ? undefined : orders;
  if (!isArray(orders)) {
    orders = orders == null ? [] : [orders];
  }
  return baseOrderBy(collection, iteratees, orders);
}

export default orderBy;
