import type { ApiResponse } from '@linkpi/core';
import { PROP_TYPE } from '@linkpi/core';
import { has } from 'ramda';

export type TokenType = {
  name: string;
  icon?: string;
  /** 说明 */
  desc: string;
  /** 示例代码 会通过codemirror转html显示 */
  example: {
    code?: string;
    result: string;
  };
  /** 插入的代码 */
  insert: {
    code: string | Function;
    /** 插入代码的光标位置 没有则插入光标到最后 */
    cursor?: number;
  };
  /** 特殊的pick */
  pickType?:
    | 'time' // 时间要选择时间格式
    | 'cascade' // 多级选值 选择完整路径和最后一级
    | 'randomNumber'; // 随机数字 选择位数
  /** 特殊 */
  filterFn?: (options: { type: 'all' | 'props' | 'function'; filterKey: string }) => boolean;
};

// 系统属性
export const SYSTEM_PROPS: TokenType[] = [
  {
    name: '标题',
    icon: 'iconattribute_text',
    example: {
      result: '{标题}',
    },
    desc: `返回 标题 的值`,
    insert: {
      code: '{标题}',
    },
  },
  {
    name: '创建者',
    icon: 'iconCRM',
    example: {
      result: '{创建者}',
    },
    desc: '返回 创建者 的值',
    insert: {
      code: '{创建者}',
    },
  },
  {
    name: '创建时间',
    icon: 'iconattribute_time',
    example: {
      result: '{创建时间}',
    },
    desc: '返回 创建时间 的 毫秒时间戳',
    insert: {
      code: (label: string) => getTimePickCode('{创建时间}', label),
    },
    pickType: 'time',
  },
  {
    name: '当前时间',
    icon: 'iconattribute_time',
    example: {
      result: 'NOW()',
    },
    desc: '返回 今天 的 毫秒时间戳',
    insert: {
      code: (label: string) => getTimePickCode('NOW()', label),
    },
    pickType: 'time',
    filterFn: (filterOptions) => {
      return 'NOW'.toLocaleLowerCase().includes(filterOptions.filterKey.toLocaleLowerCase());
    },
  },
  {
    name: '随机编号',
    icon: 'iconCRM',
    example: {
      code: 'RANDOM_ID(3)',
      result: '=> 123',
    },
    desc: '返回n位的随机编号',
    insert: {
      code: (count: number) => `RANDOM_ID(${count})`,
      cursor: 12,
    },
    pickType: 'randomNumber',
    filterFn: (filterOptions) => {
      return (
        'RANDOM_ID'.toLocaleLowerCase().includes(filterOptions.filterKey.toLocaleLowerCase()) &&
        filterOptions.filterKey !== 'RANDOM'
      );
    },
  },
];

// 状态相关
export const STATUS_RELATED__PROPS: TokenType[] = [
  {
    name: '当前状态',
    icon: 'iconattribute_selected_value',
    example: {
      result: '{当前状态}',
    },
    insert: {
      code: '{当前状态}',
    },
    desc: '返回节点的 当前状态',
  },
  {
    name: '负责人',
    icon: 'iconCRM',
    example: {
      result: '{负责人}',
    },
    insert: {
      code: '{负责人}',
    },
    desc: '返回 负责人 的值',
  },
  {
    name: '参与者',
    icon: 'iconCRM',
    example: {
      result: '{参与者}',
    },
    insert: {
      code: '{参与者}',
    },
    desc: '返回 参与者 的值',
  },
  {
    name: '开始时间',
    icon: 'iconattribute_time',
    example: {
      result: '{开始时间}',
    },
    insert: {
      code: (label: string) => getTimePickCode('{开始时间}', label),
    },
    desc: '返回 开始时间 的 毫秒时间戳',
    pickType: 'time',
  },
  {
    name: '结束时间',
    icon: 'iconattribute_time',
    example: {
      result: '{结束时间}',
    },
    insert: {
      code: (label: string) => getTimePickCode('{结束时间}', label),
    },
    desc: '返回 结束时间 的 毫秒时间戳',
    pickType: 'time',
  },
  {
    name: '备注',
    icon: 'iconattribute_text',
    example: {
      result: '{备注}',
    },
    insert: {
      code: '{备注}',
    },
    desc: '返回 备注 的值',
  },
];

// 数字函数
export const NUMBER_FUNCTION: TokenType[] = [
  {
    name: 'RAND',
    desc: `RAND(n)
返回随机数，0<= RAND(n)<n， RAND保留小数
注：公式触发计算时会更新随机数`,
    example: {
      code: 'PRECISION(RAND(2), 4)',
      result: '=> 1.312',
    },
    insert: {
      code: 'RAND()',
      cursor: 5,
    },
  },
  {
    name: 'UNHEX',
    desc: `UNHEX(n）
将16进制的数字，转换成10进制的数字`,
    example: {
      code: 'UNHEX("123A456B")',
      result: '=> 305808747',
    },
    insert: {
      code: 'UNHEX()',
      cursor: 6,
    },
  },
  {
    name: 'PRECISION',
    desc: `PRECISION(a, b)
对 a 保留 b 位有效数字，四舍五入`,
    example: {
      code: 'PRECISION(1.55, 2)',
      result: '=> 1.6',
    },
    insert: {
      code: 'PRECISION()',
      cursor: 10,
    },
  },
  {
    name: 'FIXED',
    desc: `FIXED(a, b)
对 a 保留 b位小数，（b+1）位小数会四舍五入`,
    example: {
      code: 'FIXED (1.3768, 2)',
      result: '=> 1.38',
    },
    insert: {
      code: 'FIXED()',
      cursor: 6,
    },
  },
  {
    name: 'CEIL',
    desc: `CEIL(a, b)
对 a 保留 b位小数，（b+1）位小数会向上取整`,
    example: {
      code: 'CEIL (1.3768, 2)',
      result: '=> 1.38',
    },
    insert: {
      code: 'CEIL()',
      cursor: 5,
    },
  },
  {
    name: 'FLOOR',
    desc: `FLOOR(a, b)
对 a 保留 b位小数，（b+1）位小数会向下取整`,
    example: {
      code: 'FLOOR (1.3768, 2)',
      result: '=> 1.37',
    },
    insert: {
      code: 'FLOOR()',
      cursor: 6,
    },
  },
  {
    name: 'SUM',
    desc: `SUM(...input))
对输入的参数求和
...input 表示 1 个及以上的输入参数，用逗号分隔`,
    example: {
      code: 'SUM(1, 2, 3)',
      result: '=> 6',
    },
    insert: {
      code: 'SUM()',
      cursor: 4,
    },
  },
  {
    name: 'AVG',
    desc: `AVG(...input))
返回输入的参数的算术平均数
...input 表示 1 个及以上的输入参数，用逗号分隔`,
    example: {
      code: 'AVG(2, 4, 6)',
      result: '=> 4',
    },
    insert: {
      code: 'AVG()',
      cursor: 4,
    },
  },
  {
    name: 'MAX',
    desc: `MAX(...input)
返回输入的参数的最大值
...input 表示 1 个及以上的输入参数，用逗号分隔`,
    example: {
      code: 'MAX(1, 2, 3)',
      result: '=> 3',
    },
    insert: {
      code: 'MAX()',
      cursor: 4,
    },
  },
  {
    name: 'MIN',
    desc: `MIN(...input)
返回输入的参数的最小值
...input 表示 1 个及以上的输入参数，用逗号分隔`,
    example: {
      code: 'MIN(1, 2, 3)',
      result: '=> 1',
    },
    insert: {
      code: 'MIN()',
      cursor: 4,
    },
  },
  {
    name: 'COUNT',
    desc: `COUNT(...input)
对输入的参数计数，不去重，不含null/undfined
...input 表示 1 个及以上的输入参数，用逗号分隔`,
    example: {
      code: 'COUNT(1, 2, 3, "", "七", 3)',
      result: '=> 5',
    },
    insert: {
      code: 'COUNT()',
      cursor: 6,
    },
  },
  //1. 两个时间戳相减 2. 0.5天最小的小时数  3. 1天最小的小时数
  {
    name: 'N_DAY',
    desc: `N_DAY(a, b, c)
根据规则，返回毫秒时间差a的天数
a表示毫秒精度的时间差，由日期属性相减获得，见例子
b表示最少算半天的小时数，例如 4表示4小时及以上算半天
c表示最少算一天的小时数，例如 16表示16小时及以上算半天 `,
    example: {
      code: 'N_DAY({开始时间} - {结束时间}, 4, 16 )',
      result: '=> 0.5',
    },
    insert: {
      code: `N_DAY()`,
      cursor: 6,
    },
  },
  {
    name: 'M_DAY',
    desc: `M_DAY(a, b)
按上午/下午的天数计算规则，返回从a到b的天数
a是用上午/下午格式表达的日期属性，表达开始日期，如2022/12/06 上午
b是用上午/下午格式表达的日期属性，表达结束日期，如2022/12/07 下午`,
    example: {
      code: `M_DAY({开始时间},{结束时间})`,
      result: '=> 2',
    },
    insert: {
      code: `M_DAY()`,
      cursor: 6,
    },
  },
  {
    name: 'GET_DAY_STAMP',
    desc: `GET_DAY_STAMP(日期属性)
返回 日期属性 当天0点的 时间戳`,
    example: {
      code: 'GET_DAY_STAMP({开始时间})',
      result: '1665936000000',
    },
    insert: {
      code: `GET_DAY_STAMP()`,
      cursor: 14,
    },
  },
  {
    name: 'DISTANCE',
    desc: `DISTANCE(a, b)
返回两个定位属性之间的直线距离，单位为公里（KM）
a和b，可以是定位属性,如例子，也可以是经纬度数组，如: [120.14002,30.27233]`,
    example: {
      code: ``,
      result: `
定位属性1: {起点} = 杭州黄龙体育中心
定位属性2: {终点} = 杭州西湖文化广场
DISTANCE({起点}, {终点}) => 3.08`,
    },
    insert: {
      code: `DISTANCE()`,
      cursor: 9,
    },
  },
  {
    name: 'PRC_ID_CARD_BIRTHDAY',
    desc: `PRC_ID_CARD_BIRTHDAY(a)
返回身份证号里出生年月的毫秒时间戳`,
    example: {
      code: `DATE(PRC_ID_CARD_BIRTHDAY({身份证号}), "年-月-日")`,
      result: `
=> "2000-11-18"`,
    },
    insert: {
      code: `PRC_ID_CARD_BIRTHDAY()`,
      cursor: 21,
    },
  },
];

// 文本函数
export const STRING_FUNCTION: TokenType[] = [
  {
    name: 'MD5',
    desc: `MD5 (n)
计算并返回 n 的 MD5 值`,
    example: {
      code: 'MD5 ("hello")',
      result: `=> 5d41402abc4b2a76b9719d911017c592`,
    },
    insert: {
      code: 'MD5()',
      cursor: 4,
    },
  },
  {
    name: 'INT_TO_STRING',
    desc: `INT_TO_STRING(a, b)
数字转字符串，b 是位数，如果不足位数，会自动补零`,
    example: {
      code: 'INT_TO_STRING(1, 6) ',
      result: `=> “000001”`,
    },
    insert: {
      code: 'INT_TO_STRING()',
      cursor: 14,
    },
  },
  {
    name: 'DATE',
    desc: `DATE(a,b)
按照b表达的日期格式，如“年/月/日 时分“，返回a（日期属性或毫秒时间戳）的日期字符串
更多日期格式，具体请参考日期属性的格式选项`,
    example: {
      code: `DATE(1668761416000, "年/月/日 时分")
=> "2022/11/18 16:50"
DATE({开始日期},"年-月-日")
=> "2022-12-10"`,
      result: ``,
    },
    insert: {
      code: 'DATE(,"")',
      cursor: 5,
    },
  },
  {
    name: 'SLICE',
    desc: `SLICE(a, b, c)
对字符串 a 取子串,从 b 位开始到 c 位结(不含 c)，0 是开头`,
    example: {
      code: 'SLICE(“这是文本”, 0,2)',
      result: `=> "这是"`,
    },
    insert: {
      code: 'SLICE()',
      cursor: 6,
    },
  },
  {
    name: 'CONCAT',
    desc: `CONCAT(...input)
拼接字符串
……input 表示 1 个及以上的输入参数，用逗号分隔`,
    example: {
      code: 'CONCAT(“小连” , “周一” , “20221010”)',
      result: `=> 小连周一20221010`,
    },
    insert: {
      code: 'CONCAT()',
      cursor: 7,
    },
  },
  {
    name: 'JOIN',
    desc: `JOIN(sep, ...input)
用 seq 为分隔符连接字符串
……input 表示 1 个及以上的输入参数，用逗号分隔`,
    example: {
      code: 'JOIN(“/”, “小连” , “周一” , “20221010”)',
      result: `=> 小连/周一/20221010`,
    },
    insert: {
      code: 'JOIN()',
      cursor: 5,
    },
  },
  {
    name: 'ADD_PREFIX',
    desc: `=>"LJP小连" , “LJP周一”`,
    example: {
      code: 'ADD_PREFIX(“LJP” , “小连” , “周一” )',
      result: `=>"LJP小连" , “LJP周一”`,
    },
    insert: {
      code: 'ADD_PREFIX()',
      cursor: 11,
    },
  },
  {
    name: 'LEFT',
    desc: `LEFT(a，n)
    从 a 的第一个字符开始，从右往左返回 n 个字符
    `,
    example: {
      code: 'LEFT( 「这是文本」, 2 )',
      result: `=>"这是"`,
    },
    insert: {
      code: 'LEFT()',
      cursor: 5,
    },
  },
  {
    name: 'RIGHT',
    desc: `RIGHT( a, n )
    从 a 的最后一个字符开始，从右往左返回 n 个字符
    `,
    example: {
      code: 'RIGHT( 「这是文本」, 2 )',
      result: `=>"文本"`,
    },
    insert: {
      code: 'RIGHT()',
      cursor: 6,
    },
  },
];

// 逻辑函数
export const LOGIC_FUNCTION: TokenType[] = [
  {
    name: 'EMPTY',
    desc: `EMPTY(n)
判断 n 是否为空，一般搭配 IF 使用`,
    example: {
      code: 'IF( EMPTY({开始时间}), "尚未确定时间", "已确定开始时间")',
      result: `=> 如果属性{开始时间}的值是有效时间/日期属性, 则返回“已确定开始时间”
如果属性{开始时间}的值为50,则返回“尚未确定时间“ `,
    },
    insert: {
      code: 'EMPTY()',
      cursor: 6,
    },
  },
  {
    name: 'IF',
    desc: `IF(a, b, c)
如果满足a，则返回b，否则返回c`,
    example: {
      code: 'IF({分数} > 60, "及格", "不及格")',
      result: `=> 如果属性{分数}的值为100, 则返回“及格”
如果属性{分数}的值为50,则返回“不及格“ `,
    },
    insert: {
      code: 'IF()',
      cursor: 3,
    },
  },
  {
    name: 'NOT',
    desc: `NOT (n)
对 n 取反，例如 n= true，返回 false`,
    example: {
      code: `NOT('') => true
NOT('abcd') => false
NOT(0) => true
NOT(其他数字) => false`,
      result: ``,
    },
    insert: {
      code: 'NOT()',
      cursor: 4,
    },
  },
  {
    name: 'OR',
    desc: `OR  (...input)
如果任何一个参数为真（true），则返回真（true），否则返回假（false）
……input 表示 1 个及以上的输入参数，用逗号分隔`,
    example: {
      code: 'OR(1>2, 1=1) ',
      result: `=> true`,
    },
    insert: {
      code: 'OR()',
      cursor: 3,
    },
  },
  {
    name: 'AND',
    desc: `AND  (...input)
如果所有参数均为真（true），则返回真（true），否则返回假（false）
……input 表示 1 个及以上的输入参数，用逗号分隔`,
    example: {
      code: 'AND(1>2, 1=1)',
      result: `=> false`,
    },
    insert: {
      code: 'AND()',
      cursor: 4,
    },
  },
  {
    name: 'XOR',
    desc: `XOR  (...input)
如果奇数个参数为真（true），则返回真（true），否则返回假（false)
……input 表示 1 个及以上的输入参数，用逗号分隔`,
    example: {
      code: 'XOR(2>1, 3>2, 1=2)',
      result: `=> false`,
    },
    insert: {
      code: 'XOR()',
      cursor: 4,
    },
  },
  {
    name: 'INCLUDE',
    desc: `INCLUDE  (data, ...input)
判断包含，input 包含 data
……input 表示 1 个及以上的输入参数，用逗号分隔`,
    example: {
      code: 'IF(INCLUDE (“P1” ,  {优先级}), ”高优先级”，“普通“)',
      result: `=> 如果优先级的值包含“P1”，则返回“高优先级”，否则返回“普通”`,
    },
    insert: {
      code: 'INCLUDE()',
      cursor: 8,
    },
  },
];

// 通用函数
export const COMMON_FUNCTION: TokenType[] = [
  {
    name: 'FVV',
    desc: `FVV (...input)
取第一个有效值
...input 表示 1 个及以上的输入参数，用逗号分隔`,
    example: {
      code: 'FVV ({工时}, 0)',
      result: `=> 如果{工时}的值有效，例如8，则返回8
如果{工时}的值无效，则返回0`,
    },
    insert: {
      code: 'FVV()',
      cursor: 4,
    },
  },
];

/** 获取主题属性 */
export const getTempPropTokens = (template: ApiResponse.CurrentUser.TemplateInfo) => {
  const tempPropTokens: TokenType[] = (template?.prop || [])
    .filter((x) => x.type && has('name', x))
    .map((x) => {
      const token: TokenType = {
        name: x.name,
        icon: getTempPropIcon(x.type),
        example: {
          result: `{${x.name}}`,
        },
        desc: `返回 ${x.name} 的${
          x.type === 'date' || x.type === 'datetime' ? ' 毫秒时间戳' : '值'
        }`,
        insert: {
          code: `{${x.name}}`,
        },
      };
      if (x.type === 'date' || x.type === 'datetime') {
        token.pickType = 'time';
        token.insert.code = (label: string) => getTimePickCode(`{${x.name}}`, label);
      }
      if (x.type === 'cascade') {
        token.pickType = 'cascade';
        token.insert.code = (label: '完整路径' | '最后一级') => {
          return label === '完整路径'
            ? `CAS_PROP({${x.name}}, '/')`
            : `CAS_PROP_N({${x.name}}, -1)`;
        };
      }
      return token;
    });

  return tempPropTokens;
};

const getTempPropIcon = (type: ApiResponse.CurrentUser.propType) => {
  return (PROP_TYPE as any)[type]?.icon || 'iconattribute_selected_value';
};

const getTimePickCode = (name: string, label: string) => {
  if (label === '时间戳') {
    return name;
  } else {
    return `DATE(${name},"${label}")`;
  }
};
