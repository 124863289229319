import type { OrgGroups } from '@linkpi/core';
import { DEFAULT_AVATAR, getDefaultValueType } from '@linkpi/core';
import { useMemoizedFn } from 'ahooks';
import type { SelectProps } from 'antd';
import { Avatar, Select } from 'antd';
import type { FC } from 'react';
import { useEffect, useMemo } from 'react';
import { match } from 'ts-pattern';

import { RegularIcon } from '@/components';
import { useOrgUserGroup, useOrgUserMap } from '@/hook';
import { useOrgDepartmentNodes } from '@/hook/useOrgStructure';
import { cn, getUserNickName, toArray, toRecord } from '@/utils/utils';

import { useStatusUserCustomPropSelectOptions } from './CustomPropSelect';
import { getDynamicDepartmentLabel } from './DynamicDepartmentSelect';
import type { StatusUserPropConfigModalProp } from './Modal';
import { showStatusUserPropConfigModal } from './Modal';
import { getSupervisorLabel } from './SupervisorSelect';

import styles from './index.less';

const { Option } = Select;

export const StatusUserPropConfigSelect: FC<
  React.PropsWithChildren<
    SelectProps & {
      title?: string | (() => string);
      disabledIndex?: number;
      departmentCheckStrictly?: boolean;
    } & Pick<StatusUserPropConfigModalProp, 'allowTypes'>
  >
> = ({
  title = '',
  children,
  disabledIndex,
  allowTypes,
  autoFocus,
  departmentCheckStrictly,
  className,
  ...rest
}) => {
  const userMap = useOrgUserMap();

  const groups = useOrgUserGroup();
  const groupOptionMap = useMemo(() => {
    return toRecord<OrgGroups.OrgGroupsItem>((o) => ({ [o.group_id]: o }))(groups);
  }, [groups]);

  const departmentNodes = useOrgDepartmentNodes();
  const departmentNodeMap = useMemo(() => {
    return toRecord<OrgGroups.OrgDepartmentNodeInfo>((o) => ({ [o.id]: o }))(departmentNodes);
  }, [departmentNodes]);

  const propOptions = useStatusUserCustomPropSelectOptions();
  const propOptionMap = useMemo(() => {
    return toRecord<{ label: string; value: string }>((o) => ({ [o.value]: o }))(
      propOptions.map((o) => o.options).flat(),
    );
  }, [propOptions]);

  const restOptions = useMemo(() => {
    return toArray(rest.value)
      ?.map((v: string) => {
        if (!v) return null;

        const type = getDefaultValueType(v, { userMap, groupMap: groupOptionMap });

        return match(type)
          .with('supervisor', () => {
            const [, propStr, levelStr] = v.split('_');
            const level = Number(levelStr);
            const propName = propOptionMap[propStr]?.label || '未知属性';
            const text = [propName, getSupervisorLabel(level)].join('-');
            return (
              <Option value={v} key={v} label={text}>
                {text}
              </Option>
            );
          })
          .with('dynamicDepartment', () => {
            const [, propStr, levelStr] = v.split('_');
            const level = Number(levelStr);
            const propName = propOptionMap[propStr]?.label || '未知属性';
            const text = [propName, getDynamicDepartmentLabel(level)].join('-');
            return (
              <Option value={v} key={v} label={text}>
                {text}
              </Option>
            );
          })
          .with('department', () => {
            const id = v.replace('group_', '');
            if (!departmentNodeMap[id]) return null;
            return (
              <Option value={v} key={v} label={departmentNodeMap[id].name}>
                {departmentNodeMap[id].name}
              </Option>
            );
          })
          .with('group', () => {
            const id = v.replace('group_', '');
            if (!groupOptionMap[id]) return null;
            return (
              <Option value={v} key={v} label={groupOptionMap[id].group_name}>
                {groupOptionMap[id].group_name}
              </Option>
            );
          })
          .with('user', () => {
            const user = userMap[v];
            if (!user) return null;

            return (
              <Option value={v} key={v} label={getUserNickName(user)}>
                <Avatar className="mr-1" src={user.avatar || DEFAULT_AVATAR} size={20} />
                {getUserNickName(user)}
              </Option>
            );
          })
          .with('prop', () => {
            const tip = v.startsWith('status')
              ? '（负责人）'
              : v.startsWith('pStatus')
                ? '（参与者）'
                : null;

            return (
              <Option value={v} key={v} label={propOptionMap[v]?.label || '已删除'}>
                <Avatar
                  src={<RegularIcon type="iconOrg_Member" size={14} color="#fff" />}
                  size={20}
                  style={{ marginRight: 4, background: '#316EF5' }}
                />
                {propOptionMap[v]?.label || '已删除'}
                {tip}
              </Option>
            );
          })
          .otherwise(() => null);
      })
      .filter(Boolean);
  }, [departmentNodeMap, groupOptionMap, propOptionMap, rest.value, userMap]);

  const showModal = useMemoizedFn(() => {
    let _title = '';
    if (typeof title === 'function') {
      _title = title();
    } else if (typeof title === 'string') {
      _title = title;
    }

    return (
      showStatusUserPropConfigModal({
        mode: rest.mode === 'multiple' ? 'multiple' : 'single',
        title: '设置' + _title,
        defaultValue: rest.value,
        disabledIndex,
        allowTypes,
        departmentCheckStrictly,
      })
        .then((res) => rest.onChange?.(res, []))
        // @ts-expect-error
        .catch(() => rest.onBlur?.({}))
    );
  });

  useEffect(() => {
    if (autoFocus) showModal();
  }, [autoFocus, showModal]);

  return (
    <Select {...rest} open={false} onClick={showModal} className={cn(styles.select, className)}>
      {children}
      {restOptions}
    </Select>
  );
};
