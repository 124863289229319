import { userInviteRegister, userRegister } from './service';

const Model = {
  namespace: 'userAndregister',
  state: {
    status: undefined,
  },
  effects: {
    *submit({ payload }, { call, put }) {
      const response = yield call(userRegister, payload);
      yield put({
        type: 'registerHandle',
        payload: response,
      });
      return response;
    },
    *inviteSubmit({ payload }, { call }) {
      const res = yield call(userInviteRegister, payload);
      return res;
    },
  },
  reducers: {
    registerHandle(state, { payload }) {
      return { ...state, status: payload.status };
    },
  },
};
export default Model;
