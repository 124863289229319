import type { ApiResponse } from '@linkpi/core';
import {
  ADD,
  AND,
  CREATE_TIME,
  CREATOR,
  DISTANCE,
  EQUAL,
  EXACTLY_SAME,
  GET_DAY_STAMP,
  GET_MONTH_STAMP,
  GET_QUARTER_STAMP,
  GET_WEEK_STAMP,
  GET_YEAR_STAMP,
  GREAT,
  GREAT_EQUAL,
  INCLUDE,
  LESS,
  LESS_EQUAL,
  NOW,
  OR,
  PROP,
  STATUS,
  STATUS_PROP,
  SUB,
  TAG,
  TEXT_INCLUDE,
} from '@linkpi/core';
import dayjs from 'dayjs';
import { defaultTo, isNil } from 'ramda';

import type { BasicProp } from '@/pages/space/components/TemplateProp/components/TempPropModal/types';
import { FORMULA_SYMBOL } from '@/pages/space/components/TemplateProp/constants';

export const genMatchingCondition = (params: BasicProp) => {
  const matchingConditions: any = [];
  params.conditionProp?.forEach((v, i) => {
    console.log(params.quoteProp?.[i]);
    const q = (
      params.quoteProp?.[i]
        ? Array.isArray(params.quoteProp[i])
          ? params.quoteProp[i]
          : [params.quoteProp[i]]
        : []
    ).filter((qv: any) => typeof qv === 'string' && !!qv);
    if (!Number.isFinite(v) || !params.matchingOp?.[i] || !q.length) return;
    const condition: ApiResponse.ViewList.ViewconditionV2Item = {
      key: v === -3 ? 'title' : v === -1 ? 'sysCreator' : 'prop',
      // @ts-ignore
      op: params.matchingOp[i],
      match: q.map((qv: any) => ({
        key: 'prop',
        index: Number(qv.split('-')[1]),
      })),
    };
    if (condition.key === 'prop') condition.index = v;
    if (
      params.conditionCascade?.[i]?.condition !== undefined &&
      Number.isFinite(params.conditionCascade[i].condition)
    )
      condition.level = params.conditionCascade[i].condition;
    const conditions: ApiResponse.ViewList.ViewconditionV2Item[] = q.map((qv: any) => ({
      ...condition,
      match: {
        key: 'prop',
        index: Number(qv.split('-')[1]),
      },
    }));
    matchingConditions.push(
      conditions.length > 1
        ? {
            op: 'or',
            input: conditions,
          }
        : conditions[0],
    );
  });
  return matchingConditions;
};

export const genQuoteConditionsFormula = (propParams: BasicProp) => {
  const quoteConditionsFormula: any[] = [];
  propParams.quoteConditions
    ?.filter((c) => c.key && c.type && c[c.type]?.length)
    .forEach((condition) => {
      let opObject: any = null;
      switch (condition.key) {
        case 'creator':
          opObject = CREATOR();
          break;
        case 'createTime':
          opObject = CREATE_TIME();
          break;
        case 'sysTag':
          opObject = TAG();
          break;
        case 'status':
          opObject = STATUS();
          break;
        default: {
          const [prefix, index] = condition.key.split('_');
          opObject = prefix === 'status' ? STATUS_PROP(Number(index)) : PROP(Number(index));
        }
      }
      const conditionValue = condition[condition.type];
      if (Array.isArray(conditionValue) && conditionValue.length) {
        switch (condition.type) {
          case 'user':
          case 'enum':
            if (!condition.symbol || condition.symbol === 'INCLUDE') {
              const conditionFormula = conditionValue?.map((id) => INCLUDE(opObject, id));
              quoteConditionsFormula.push(
                conditionFormula.length > 1 ? OR(...conditionFormula) : conditionFormula[0],
              );
            } else if (condition.symbol === 'EQUAL') {
              quoteConditionsFormula.push(EXACTLY_SAME(conditionValue, opObject));
            }
            break;
          case 'text': {
            let conditionFormula = [];
            if (!condition.symbol || condition.symbol === 'INCLUDE') {
              conditionFormula = conditionValue.map((text) => TEXT_INCLUDE(opObject, text));
            } else {
              conditionFormula = conditionValue.map((text) => EQUAL(opObject, text));
            }
            quoteConditionsFormula.push(
              conditionFormula.length > 1 ? OR(...conditionFormula) : conditionFormula[0],
            );
            break;
          }
          case 'date':
            if (!condition.symbol || condition.symbol === 'INSIDE') {
              if (conditionValue.length > 2) {
                if (typeof conditionValue[2] === 'number') {
                  quoteConditionsFormula.push(
                    AND(
                      GREAT_EQUAL(
                        opObject,
                        ADD(GET_DAY_STAMP(NOW()), 86400_000 * conditionValue[2]),
                      ),
                      LESS(opObject, ADD(GET_DAY_STAMP(NOW()), 86400_000)),
                    ),
                  );
                } else if (typeof conditionValue[2] === 'string') {
                  switch (conditionValue[2]) {
                    case 'week':
                      quoteConditionsFormula.push(
                        AND(
                          GREAT_EQUAL(opObject, GET_WEEK_STAMP(NOW(), 0)),
                          LESS(opObject, GET_WEEK_STAMP(NOW(), 1)),
                        ),
                      );
                      break;
                    case 'month':
                      quoteConditionsFormula.push(
                        AND(
                          GREAT_EQUAL(opObject, GET_MONTH_STAMP(NOW(), 0)),
                          LESS(opObject, GET_MONTH_STAMP(NOW(), 1)),
                        ),
                      );
                      break;
                    case 'year':
                      quoteConditionsFormula.push(
                        AND(
                          GREAT_EQUAL(opObject, GET_YEAR_STAMP(NOW(), 0)),
                          LESS(opObject, GET_YEAR_STAMP(NOW(), 1)),
                        ),
                      );
                      break;
                    case 'quarter':
                      quoteConditionsFormula.push(
                        AND(
                          GREAT_EQUAL(opObject, GET_QUARTER_STAMP(NOW(), 0)),
                          LESS(opObject, GET_QUARTER_STAMP(NOW(), 1)),
                        ),
                      );
                      break;
                    case 'yesterday':
                      quoteConditionsFormula.push(
                        AND(
                          GREAT_EQUAL(opObject, ADD(GET_DAY_STAMP(NOW()), 86400_000 * -1)),
                          LESS(opObject, GET_DAY_STAMP(NOW())),
                        ),
                      );
                      break;
                    case 'lastYear':
                      quoteConditionsFormula.push(
                        AND(
                          GREAT_EQUAL(opObject, GET_YEAR_STAMP(NOW(), -1)),
                          LESS(opObject, GET_YEAR_STAMP(NOW(), 0)),
                        ),
                      );
                      break;
                  }
                }
              } else if (
                conditionValue[0] !== undefined &&
                conditionValue[1] !== undefined &&
                Number.isFinite(conditionValue[0]) &&
                Number.isFinite(conditionValue[1]) &&
                conditionValue[1] >= conditionValue[0]
              ) {
                quoteConditionsFormula.push(
                  AND(GREAT(opObject, conditionValue[0]), LESS(opObject, conditionValue[1])),
                );
              }
            } else if (
              conditionValue[0] !== undefined &&
              Number.isFinite(conditionValue[0]) &&
              condition.symbol in FORMULA_SYMBOL
            ) {
              quoteConditionsFormula.push(
                // @ts-ignore
                FORMULA_SYMBOL[condition.symbol].formula(opObject, conditionValue[0]),
              );
            }
            break;
          case 'number':
            if (
              Number.isFinite(conditionValue?.[0].number) &&
              conditionValue?.[0].symbol in FORMULA_SYMBOL
            ) {
              quoteConditionsFormula.push(
                // @ts-ignore
                FORMULA_SYMBOL[conditionValue?.[0].symbol].formula(
                  opObject,
                  conditionValue[0].number,
                ),
              );
            }
            break;
          case 'positioning':
            if (
              ((conditionValue[0].type !== 'prop' && conditionValue[0].position) ||
                (conditionValue[0].type === 'prop' && Number.isFinite(conditionValue[0].prop))) &&
              Number.isFinite(conditionValue[0].distance)
            ) {
              quoteConditionsFormula.push(
                LESS_EQUAL(
                  DISTANCE(
                    opObject.prop,
                    conditionValue[0].type === 'prop' && Number.isFinite(conditionValue[0].prop)
                      ? conditionValue[0].prop
                      : [conditionValue[0].position.lat, conditionValue[0].position.lng],
                  ),
                  conditionValue[0].distance,
                ),
              );
            }
            break;
          default:
            break;
        }
      }
    });
  return quoteConditionsFormula;
};

export const checkDefaultValueIsQuote = (v?: string | number | null) => {
  if (!v) return false;
  if (typeof v !== 'string') return false;

  if (v.indexOf('status') >= 0) return true;
  if (v.indexOf('prop') >= 0) return true;
  if (v === 'createTime') return true;
  if (v === 'updateTime') return true;
  if (v === 'updator') return true;
  if (v === 'creator') return true;

  return false;
};

export const transRangeDate = (range: string | number) => {
  let value: [number | null, number | null, number | string | null] = [null, null, null];
  if (typeof range === 'number') {
    value = [
      dayjs().add(range, 'day').startOf('day').valueOf(),
      dayjs().endOf('day').valueOf(),
      range,
    ];
  } else {
    switch (range) {
      case 'week':
      case 'month':
      case 'year':
      case 'quarter':
        // @ts-ignore
        value = [dayjs().startOf(range).valueOf(), dayjs().endOf(range).valueOf(), range];
        break;
      case 'yesterday':
        value = [
          dayjs().add(-1, 'day').startOf('day').valueOf(),
          dayjs().add(-1, 'day').endOf('day').valueOf(),
          range,
        ];
        break;
      case 'lastYear':
        value = [
          dayjs().add(-1, 'year').startOf('year').valueOf(),
          dayjs().add(-1, 'year').endOf('year').valueOf(),
          range,
        ];
        break;
    }
  }
  return value;
};

export const checkPropDefaultValueIsQuote = (params: BasicProp) => {
  return (
    checkDefaultValueIsQuote(params.defaultValue) ||
    (!isNil(params.defaultFormula) && params.defaultFormula?.type !== 'const')
  );
};

// allowManualUpdate
export const DEFAULT_ALLOW_MANUAL_UPDATE = [
  -2, 0, 1, 2, 3,
] as ApiResponse.CurrentUser.NodeAreaNumberType[];

export const getDefaultAllowManualUpdate = defaultTo<ApiResponse.CurrentUser.NodeAreaNumberType[]>(
  DEFAULT_ALLOW_MANUAL_UPDATE,
);
