import type { TreeNodeProps } from 'antd';
import { defaultTo } from 'ramda';
import { useMemo } from 'react';

import { useCurrentOrgId, useOrgConnection } from './useOrg';

const deep = (root: PiNode) => {
  const childs: TreeNodeProps[] = [];
  const { children } = root;
  if (Array.isArray(children) && children.length) {
    children.forEach((i) => {
      const { key, title } = i;
      childs.push({
        value: key,
        title: title || '无标题',
        children: deep(i),
      });
    });
  }

  return childs;
};

type Option = {
  orgId?: string;
  rootTitle?: string;
  enableRoot?: boolean;
};

export const useNodeTreeData = (option?: Option) => {
  const defaultOrgId = useCurrentOrgId();
  const orgId = option?.orgId || defaultOrgId;

  const orgConnection = useOrgConnection(orgId);
  const nodeTreeData = useMemo(() => {
    return [
      {
        value: orgConnection!.nodeManager._rootId,
        title: option?.rootTitle || '根目录（所有主题）',
        children: deep(orgConnection!.nodeManager.getRoot()),
        disabled: defaultTo(true)(!option?.enableRoot),
      },
    ];
  }, [option?.enableRoot, option?.rootTitle, orgConnection]);

  return nodeTreeData;
};
