import type { PaginationProps } from 'antd';
import { Pagination } from 'antd';

const WattPagination = (
  props: PaginationProps & {
    checkNum?: number;
  },
) => {
  const { checkNum, ...paginationProps } = props;

  return (
    <div className="flex items-center shrink-0 pb-8">
      <div className="flex-1 text-black">{checkNum ? `已勾选 ${checkNum} 条` : ''}</div>
      <Pagination
        showSizeChanger
        showTotal={(total) => `共 ${total} 条`}
        className="text-right"
        {...paginationProps}
      />
    </div>
  );
};

export default WattPagination;
