import { Popover } from 'antd';
import PerfectScrollbar from 'react-perfect-scrollbar';

import CustomTableChildTemplatesSort from '@/components/CustomTableChildTemplatesSort';

import '../GroupForView/styles.less';
import styles from './styles.less';
// 子主题排序
const customTableChildNodesOrders = [
  {
    name: '主题树顺序',
    key: '_child_treeLevel',
  },
  {
    name: '创建时间',
    key: '_child_createTime',
  },
  {
    name: '更新时间',
    key: '_child_updateTime',
  },
];
export default (props: any) => {
  const {
    curViewData,
    changeOrderBy,
    changeChildNodesOrderBy,
    changeOrderDesc,
    isDark,
    orgInfo,
    template,
  } = props;

  const getSortName = () => {
    if (curViewData.view_info.orderBy === 'treeLevel') return '主题树顺序';
    if (curViewData.view_info.orderBy === '_sys_createTime') return '创建时间';
    if (curViewData.view_info.orderBy === '_sys_updateTime') return '更新时间';

    return '主题树顺序';
  };

  const sortCustomTableChildTemplates = (v: any) => {
    changeChildNodesOrderBy(v);
  };
  return (
    <Popover
      trigger={['click']}
      overlayClassName="view-setting-pop"
      placement={'bottomRight'}
      content={
        <PerfectScrollbar>
          <ul className="view-setting-list">
            <li style={{ fontWeight: 600 }} key={0}>
              父主题排序
            </li>
            <li
              key="treeLevel"
              className={`view-setting-item${
                curViewData.view_info.orderBy === 'treeLevel' ? ' active' : ''
              }`}
              onClick={() => {
                changeOrderBy('treeLevel');
              }}
            >
              主题树顺序
            </li>

            <li
              key="_sys_createTime"
              className={`view-setting-item${
                curViewData.view_info.orderBy === '_sys_createTime' ? ' active' : ''
              }`}
              onClick={() => {
                changeOrderBy('_sys_createTime');
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  alignItems: 'center',
                }}
              >
                <span>创建时间</span>
                {curViewData.view_info.orderBy === '_sys_createTime' && (
                  <i
                    className={`${styles.sortIcon} iconfont ${
                      curViewData.view_info.orderDesc ? 'iconDescending' : 'iconAscending'
                    }`}
                    onClick={(e) => {
                      e.stopPropagation();
                      changeOrderDesc(!curViewData.view_info.orderDesc);
                    }}
                  />
                )}
              </div>
            </li>

            <li
              key="_sys_updateTime"
              className={`view-setting-item${
                curViewData.view_info.orderBy === '_sys_updateTime' ? ' active' : ''
              }`}
              onClick={() => {
                changeOrderBy('_sys_updateTime');
              }}
            >
              更新时间
            </li>

            <li style={{ fontWeight: 600 }} key={1}>
              子主题排序
            </li>

            {customTableChildNodesOrders.map((c) => (
              <li
                key={c.key}
                className={`view-setting-item${
                  curViewData.view_info.childOrderBy === c.key ? ' active' : ''
                }`}
                onClick={() => {
                  changeChildNodesOrderBy(c.key);
                }}
                children={c.name}
              />
            ))}
            <CustomTableChildTemplatesSort
              onChange={sortCustomTableChildTemplates}
              curViewData={curViewData}
              orgInfo={orgInfo}
              template={template}
            />
          </ul>
        </PerfectScrollbar>
      }
    >
      <div className={`view-setting-block${isDark ? ' view-setting-block-dark' : ''}`}>
        <div className="text-omit">排序： {getSortName()}</div>

        <i
          className={`iconfont ${
            curViewData.view_info.orderDesc ? 'iconDescending' : 'iconAscending'
          } icon-btn`}
          onClick={(e) => {
            e.stopPropagation();
            if (['treeLevel', '_sys_updateTime'].includes(curViewData.view_info.orderBy)) return;

            changeOrderDesc(!curViewData.view_info.orderDesc);
          }}
        />
      </div>
    </Popover>
  );
};
