import { getDvaApp } from '@umijs/max';
import introJs from 'intro.js';

/**
 *
 *
 * 这个状态，控制需要全局显示的引导
 *
 *
 */

const IntroModel = {
  namespace: 'intro',
  state: {},
  effects: {
    showIntro({ name, callback }, { put }) {
      if (name === 'more') {
        introJs()
          .setOptions({
            showBullets: false,
            doneLabel: '完成',
            steps: [
              {
                intro: '点击可查看更多快捷键',
                element: document.querySelector('#moreKeyMap'),
              },
            ],
          })

          .start()
          .oncomplete(() => {
            getDvaApp()._store.runSaga(function* () {
              yield put({
                type: 'finishStep',
                name: 'more',
              });
              if (callback) {
                callback();
              }
            });
          });
      }
    },
  },
  reducers: {
    finishStep(state, { name }) {
      introJs().exit();
      const pioTreeIntroStr = window.localStorage.getItem('pio-intro-new');
      const newRecord = {
        ...JSON.parse(pioTreeIntroStr),
        [name]: true,
      };
      window.localStorage.setItem('pio-intro-new', JSON.stringify(newRecord));
      return {};
    },
  },
};

export default IntroModel;
