// import LinkPiModal from '@/components/LinkPiModal';
import { create, hide, show, useModal } from '@ebay/nice-modal-react';
import { useDispatch, useSelector } from '@umijs/max';
import { Modal, Switch } from 'antd';
import classnames from 'classnames';

import './index.less';

export enum ModelStatus {
  Default = 'default',
  Simple = 'simple',
}

export interface DARK_MODE {
  isDark?: boolean;
  layoutMode?: ModelStatus;
}

const LayoutModal = create(() => {
  const dispatch = useDispatch();
  const modal = useModal();

  const { isDark, layoutMode = ModelStatus.Default } = useSelector(
    (state: any) => state.user.currentUser,
  ) as DARK_MODE;

  const handleModelItemClick = (value: ModelStatus) => {
    dispatch({ type: 'user/updateLayoutProperty', payload: { key: 'layoutMode', value } });
  };

  const layoutRender = () => {
    const model = [
      {
        title: '默认模式',
        icon: 'iconLayout_Default',
        key: ModelStatus.Default,
      },
      {
        title: '极简模式',
        icon: 'iconLayout_simple',
        key: ModelStatus.Simple,
      },
    ];

    return (
      <div className="layout-container">
        {model.map((m) => (
          <div
            key={m.key}
            className={classnames([
              'layout-item',
              {
                active: m.key === layoutMode,
              },
            ])}
            onClick={() => handleModelItemClick(m.key)}
          >
            <i className={classnames('layout-item-icon', 'iconfont', m.icon)} />
            <span>{m.title}</span>
          </div>
        ))}
      </div>
    );
  };

  const handleSwitchChange = (value: boolean) => {
    dispatch({ type: 'user/updateLayoutProperty', payload: { key: 'isDark', value } });
  };

  const getModalContent = () => {
    return (
      <div className="config-container">
        <div className="config-item no-wrap">
          <span className="config-item-label">深色模式</span>
          <Switch
            checkedChildren="开启"
            unCheckedChildren="关闭"
            checked={isDark}
            onChange={handleSwitchChange}
          />
        </div>
        <div className="config-item no-wrap">
          <span className="config-item-label">布局</span>
          {layoutRender()}
        </div>
      </div>
    );
  };

  const handleOkClick = () => {
    modal.resolve({
      isDark,
      layoutMode,
    });
  };

  const handleCancelClick = () => {
    modal.resolve();
  };

  return (
    <Modal
      title="布局和样式设置"
      wrapClassName="layout-modal"
      getContainer={false}
      width={548}
      destroyOnClose
      okText="确认"
      cancelText="取消"
      open={modal.visible}
      onOk={handleOkClick}
      onCancel={handleCancelClick}
    >
      {getModalContent()}
    </Modal>
  );
});

export const openLayoutModal = () => show(LayoutModal);
export const hideLayoutModal = () => hide(LayoutModal);

export default LayoutModal;
