import { ACT } from '@linkpi/core';
import { message } from 'antd';

import request from '@/utils/request';
import { isNodeAllowCopy, isNodeAllowPaste } from '@/utils/utils';

import '../GroupForView/styles.less';
export default (props: any) => {
  const { getterPiNode, orgInfo, checkId, setCheckId, checkedNodesDetail } = props;

  const isShowCopy = () => {
    const root_node = getterPiNode.value.nodeManager.getRoot();
    const { _sys_allow_copy, _sys_allow_copy_detail } = root_node.prop;
    const { role } = orgInfo;
    const isAdmin = [ACT.组织Onwer, ACT.组织编辑].includes(role);

    if (_sys_allow_copy_detail === 'all' && !_sys_allow_copy && !isAdmin) return false;
    return true;
  };

  if (checkId.length < 1 || !isShowCopy()) return null;

  const copyRequest = async (parentId: any, copys: any) => {
    const res = await request('/docapi/node/insert', {
      method: 'POST',
      data: {
        org_id: orgInfo.orgId,
        parentId,
        siblingId: null,
        copyId: copys,
        enableUnique: true,
      },
    });
    if (res.status === 'error') message.error(res.message);
  };

  const copyNodes = async (parentId?: any) => {
    // setCheckId([]);

    const allowCheckId = checkId.filter((i: any) => {
      const n = getterPiNode.value.nodeManager.findChildren(i);
      let pId = parentId;
      if (!pId) pId = n.parent ? n.parent.id : orgInfo.rootId;
      return (
        isNodeAllowCopy(orgInfo, getterPiNode.value.nodeManager.findChildren(i)) &&
        isNodeAllowPaste(orgInfo, getterPiNode.value.nodeManager.findChildren(pId))
      );
    });

    if (allowCheckId.length < checkId.length) message.warning('部分主题无权限复制，已跳过');

    if (parentId) {
      // 装修表格
      allowCheckId.forEach((node_id: string) => {
        copyRequest(parentId, [{ org_id: orgInfo.orgId, node_id }]);
      });
      return;
    }

    // 普通表格
    const nodes = allowCheckId.map((nId: string) =>
      getterPiNode.value.nodeManager.findChildren(nId),
    );

    if (nodes.length > 20) return message.warning('复制条数不可以超过20条');
    nodes.forEach((n: any) => {
      copyRequest(n.parent ? n.parent.id : orgInfo.rootId, [
        { org_id: orgInfo.orgId, node_id: n.id },
      ]);
    });
  };

  return (
    <div
      onClick={() => {
        if (typeof checkedNodesDetail === 'object') return copyNodes(checkedNodesDetail.parentId);
        copyNodes();
      }}
      className="view-setting-block"
    >
      <i className="iconfont iconfuzhizhuti" />
      &nbsp;复制主题
    </div>
  );
};
