/**
 *
 * node handle provider
 * 无状态组件
 *
 * 尽可能避免
 * 1. useSelector redux的数据
 * 2. useState
 * 从而避免useContext引发组件渲染
 * 有副作用的状态 通过useContext的方法传进来
 *
 * 也可以用useRef
 *
 */

import type { ApiResponse } from '@linkpi/core';
import {
  addNewNode,
  batchAddNewNode,
  generateId,
  getDefaultTempProp,
  moveNode,
  NODE_TYPE,
  recycleNode,
  removeNode,
} from '@linkpi/core';
import { useDispatch } from '@umijs/max';
import { useEventEmitter } from 'ahooks';
import type { EventEmitter } from 'ahooks/lib/useEventEmitter';
import { message } from 'antd';
import Delta from 'quill-delta';
import type { MutableRefObject } from 'react';
import { createContext, useContext, useRef } from 'react';

import request from '@/utils/request';
import { clipboardCopy, getNodeMainText, isEmptyStr } from '@/utils/utils';

type NodeContextType = {
  copiedNode: MutableRefObject<CopiedNodeType>;
  handleSubscribe: (nodeId: string, status?: boolean) => any;
  handleShare: (orgId: string, nodeId: string) => any;
  handleCopy: (orgId: string, nodeId: string) => any;
  handleShear: (orgId: string, nodeId: string) => any;
  handlePaste: (orgId: string, parentId: string) => any;
  handleDelete: (
    orgId: string,
    nodeId: string,
    curSelectNode?: string,
    nodeManager?: PiNodeManager,
    isDraft?: boolean,
  ) => any;
  themeTreeExpandedKeysEvent: EventEmitter<any>;
};

type CopiedNodeType = {
  type: null | 'copy' | 'shear';
  orgId: string;
  nodeId: string;
} | null;

// @ts-ignore
const NodeContext = createContext<NodeContextType>(null);

const NodeHandleProvider = ({ children }: any) => {
  const dispatch = useDispatch<any>();

  const copiedNode = useRef<CopiedNodeType>(null);
  const themeTreeExpandedKeysEvent = useEventEmitter<string[]>();

  /**
   * 复制
   */
  const handleCopy = (orgId: string, nodeId: string) => {
    message.success('主题已复制');
    copiedNode.current = {
      type: 'copy',
      orgId: orgId,
      nodeId: nodeId,
    };
    const url = `${window.location.origin}/home/${orgId}/${orgId}/${nodeId}`;
    clipboardCopy(url);
  };
  /**
   * 剪切
   */
  const handleShear = (orgId: string, nodeId: string) => {
    message.success('主题已剪切');
    copiedNode.current = {
      type: 'shear',
      nodeId: nodeId,
      orgId: orgId,
    };
  };

  /**
   * 粘贴
   */
  const handlePaste = async (orgId: string, parentId: string) => {
    if (!copiedNode.current) {
      message.warning('请先复制主题');
      return;
    }

    // 复制粘贴
    if (copiedNode.current.type === 'copy') {
      const [err, res] = await addNewNode(request, {
        org_id: orgId,
        parentId: parentId,
        copyId: [
          {
            org_id: copiedNode.current.orgId,
            node_id: copiedNode.current.nodeId,
          },
        ],
      });
      if (err || res?.status !== 'ok') {
        message.error(res.message || '粘贴失败');
      } else {
        message.success('粘贴成功');
        themeTreeExpandedKeysEvent.emit([parentId]);
      }
    }

    // 剪切粘贴
    if (copiedNode.current.type === 'shear') {
      if (copiedNode.current.orgId === orgId && copiedNode.current.nodeId === parentId) {
        message.warning('粘自己身上不大好吧😆');
        return;
      }
      // 跨空间剪切粘贴 == 复制+删除
      if (copiedNode.current.orgId !== orgId) {
        const [err, res] = await addNewNode(request, {
          org_id: orgId,
          parentId: parentId,
          copyId: [
            {
              org_id: copiedNode.current.orgId,
              node_id: copiedNode.current.nodeId,
            },
          ],
        });
        if (err || res?.status !== 'ok') {
          return message.error(res.message || '粘贴失败');
        } else {
          message.success('粘贴成功');
          themeTreeExpandedKeysEvent.emit([parentId]);
        }
        await recycleNode(request, {
          org_id: copiedNode.current.orgId,
          node_id: copiedNode.current.nodeId,
        });
      } else {
        // 同空间剪切粘贴 == 移动
        const [err, res] = await moveNode(request, {
          org_id: copiedNode.current.orgId,
          nodeId: copiedNode.current.nodeId,
          parentId: parentId,
        });
        if (err || res?.status !== 'ok') {
          message.error(res.message || '粘贴失败');
        } else {
          message.success('粘贴成功');
          copiedNode.current = null;

          themeTreeExpandedKeysEvent.emit([parentId]);
        }
      }
    }
  };

  /**
   * 分享
   */
  const handleShare = async (orgId: string, nodeId: string) => {
    // shareSettingRef.current.open(nodeId, orgId);

    const response = await request('/api/share/token', {
      method: 'POST',
      data: {
        node_id: nodeId,
        org_id: orgId,
        public: 1,
      },
    });

    if (response?.status === 'ok') {
      await navigator.clipboard.writeText(
        window.location.protocol + '//' + window.location.host + response.data,
      );
      message.success('分享链接已复制');
    }
  };

  /**
   * 收藏
   */
  const handleSubscribe = async (nodeId: string, status = true) => {
    const data: any = { node_id: nodeId };
    if (!status) {
      data.unsub = '';
    }

    const res = await dispatch({ type: 'workspace/subscribeNode', payload: data });
    if (res.status === 'ok') {
      message.success(status ? '收藏成功' : '已取消收藏');
    } else {
      message.warn('出错了，快去锤开发吧😭');
    }
  };

  /**
   * 删除
   */
  const handleDelete = async (
    orgId: string,
    nodeId: string,
    curSelectNode?: string,
    nodeManager?: PiNodeManager,
    isDraft?: boolean,
  ) => {
    let isEmpty = false;
    let targetNodeId = '';

    if (nodeManager) {
      const node = nodeManager.findChildren(nodeId);
      if (
        !node.children?.length &&
        !node.title &&
        !node.prop?._sys_cover?.length &&
        !node.document?._cover?.length
      ) {
        const mainText = await getNodeMainText(node.id);
        if (mainText) {
          const delta = mainText?.ops?.filter((item: any) => {
            return !(item.insert === '\n');
          });
          if (!delta?.length) {
            isEmpty = true;
          }
        }
      }
      if (isEmpty) {
        const res: any = await request('/api/view/get', {
          method: 'POST',
          data: { node_id: node.id },
        });

        if (res?.status === 'ok')
          isEmpty = !res.data?.filter((i: any) => i.default_view === 0)?.length;
      }
      const targetNode = node.getPrevNode() || node.getParentNode();
      if (targetNode && targetNode.nodeType !== NODE_TYPE.CLEARING) {
        targetNodeId = targetNode.id;
      } else {
        targetNodeId = nodeManager._rootId;
      }
    }
    if (isEmpty) {
      const [err, res] = await removeNode(request, {
        items: { [orgId]: [nodeId] },
      });
      if (!!err || res?.status !== 'ok') return false;
    } else {
      const [err, res] = await recycleNode(request, {
        org_id: orgId,
        node_id: nodeId,
      });
      if (!!err || res?.status !== 'ok') return false;
      message.success('节点已移至回收站');
    }

    // 如果删除当前节点，切换到兄或父
    // 草稿节点无
    if (!isDraft && curSelectNode && nodeId === curSelectNode) {
      dispatch({
        type: 'workspace/setCurrentSelection',
        payload: {
          selectNode: targetNodeId,
        },
      });
    }

    // 清空剪切板
    if (copiedNode.current && copiedNode.current.nodeId === nodeId) {
      copiedNode.current = null;
    }
    return true;
  };

  return (
    <NodeContext.Provider
      value={{
        copiedNode,
        handleShare,
        handleSubscribe,
        handleCopy,
        handlePaste,
        handleShear,
        handleDelete,
        themeTreeExpandedKeysEvent,
      }}
    >
      {children}
    </NodeContext.Provider>
  );
};

export default NodeHandleProvider;
export const useNodeHandle = () => useContext(NodeContext);

// 通过 正文拆分 创建节点
export const handleSplitToNode = async (props: {
  countType: 'single' | 'more';
  text: string;
  delta: any;
  userMap: Record<string, ApiResponse.OrgUser.OrgUserItem>;
  departmentNodes: any;
  orgInfo: ApiResponse.CurrentUser.OrgInfo;
  nodeManager: PiNodeManager;
  parentId: string;
  userId: string;
}) => {
  console.log(props);
  const {
    countType,
    text,
    delta,
    nodeManager,
    orgInfo,
    parentId,
    userId,
    userMap,
    departmentNodes,
  } = props;
  // 超出层数限制
  const level = nodeManager.findChildrenPathDoc(parentId) || [];
  if (level.length > 18) {
    return message.error('当前节点超出层数限制，拆分失败');
  }

  // 过滤空行
  const normalTextLine = text.split('\n').filter((x) => !isEmptyStr(x));
  if (!normalTextLine.length) {
    return message.error('请选择有效的内容');
  }

  const template =
    orgInfo.templateList.find((x) => x.template_id === orgInfo.defaultTemplate) || null;

  // 节点属性
  const prop = getDefaultTempProp(
    { _sys_creator: userId },
    template as any,
    { userid: userId },
    null,
    userMap,
    departmentNodes,
  );

  if (countType === 'single') {
    // 标题 取多行只取第一行
    const title = normalTextLine[0];

    // 拆分单个主题 带样式
    let hasClean = false;
    const ops = delta.ops
      .map((op: any) => {
        if (
          typeof op.insert === 'string' &&
          !hasClean &&
          op.insert.indexOf(normalTextLine[0]) >= 0
        ) {
          hasClean = true;
          return { insert: op.insert.replace(normalTextLine[0], '').replace('\n', '') };
        }
        return op;
      })
      .filter((op: any) => {
        return !(typeof op.insert === 'string' && op.insert === '');
      });

    const nodeId = generateId();
    const newDelta = new Delta(ops);
    const [err, res] = await addNewNode(request, {
      org_id: orgInfo.orgId,
      parentId: parentId,
      siblingId: null,
      node: {
        node_id: nodeId,
        prop: prop,
        title: title,
        content: newDelta,
      },
    });
  } else {
    const [err, res] = await batchAddNewNode(request, {
      org_id: orgInfo.orgId,
      parentId: parentId,
      siblingId: null,
      currentUser: userId,
      texts: normalTextLine,
      sysTemp: prop?._sys_temp,
      sysTaskStatus: prop?._sys_task_status,
    });
  }
};
