export const FILE_TYPE_MAP: Record<string, 'w' | 's' | 'p' | 'f'> = {
  doc: 'w',
  dot: 'w',
  wps: 'w',
  wpt: 'w',
  docx: 'w',
  dotx: 'w',
  docm: 'w',
  dotm: 'w',
  rtf: 'w',
  xls: 's',
  xlt: 's',
  et: 's',
  xlsx: 's',
  xltx: 's',
  csv: 's',
  xlsm: 's',
  xltm: 's',
  excel: 's',
  ppt: 'p',
  pptx: 'p',
  pptm: 'p',
  ppsx: 'p',
  ppsm: 'p',
  pps: 'p',
  potx: 'p',
  potm: 'p',
  dpt: 'p',
  dps: 'p',
  pdf: 'f',
};

export const WEB_OFFICE_TYPES = ['excel', 'pdf', 'doc', 'ppt'];

export const WPS_APP_ID = '0ba687bb1a074a17a6f41d5e76e6876d';

export const WPS_APP_KEY = 'cf49ceca54a04302b1ea504abc99fbe2';
