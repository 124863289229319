import { SIDE_MODE } from '@linkpi/core';
import { usePiNode } from '@linkpi/core/web';
import { useSelector } from '@umijs/max';
import { type FC, useMemo, useRef } from 'react';

import { useCurrentSelection, useCurrentUser, useOrgConnection, useOrgInfo } from '@/hook';
import { useWorkbenchViewList, WorkbenchViewListContext } from '@/hook/useViewList';
import WorkbenchContent from '@/pages/home/Workbench/WorkbenchContent';

type ViewComponentProp = {
  nodeId: string;
  viewId: string;
};

/**
 * 渲染视图的组件
 */
export const NodeViewComponent: FC<ViewComponentProp> = ({ nodeId, viewId }) => {
  const tabContentRef = useRef<any>({});
  const [orgInfo] = useOrgInfo();
  const orgConnection = useOrgConnection();
  const [node] = usePiNode(orgConnection, nodeId);
  const currentSelection = useCurrentSelection();
  const currentUser = useCurrentUser();
  const { readOnlyMode } = useSelector((state: any) => state.workspace);

  const [viewList, getViewList, authMap, _handleSortViewList, mutateViewInfo] =
    useWorkbenchViewList(node!, orgInfo!.orgId, orgInfo!.role, currentUser.userid, () => {});

  const WorkbenchViewListContextValue = useMemo(
    () => ({
      mutateViewInfo,
      refreshViewList: getViewList,
    }),
    [getViewList, mutateViewInfo],
  );

  return (
    <WorkbenchViewListContext.Provider value={WorkbenchViewListContextValue as any}>
      <div
        style={{
          flex: 1,
          height: '100%',
          display: 'flex',
          // 放置全屏时，背景颜色消失
          backgroundColor: '#fff',
        }}
        id="WorkbenchContent"
      >
        <WorkbenchContent
          node={node!}
          orgInfo={orgInfo}
          selectViewId={viewId}
          viewList={viewList}
          leftViewCollapse={true}
          leftViewWidth={400}
          showMessage={false}
          getViewList={getViewList}
          authMap={authMap}
          readOnly={readOnlyMode}
          isDrafts={currentSelection.selectSideMode === SIDE_MODE.DRAFTSBOX}
          linkRef={tabContentRef}
        />
      </div>
    </WorkbenchViewListContext.Provider>
  );
};
