import type { InputNumberProps } from 'antd';
import { InputNumber, message } from 'antd';
import { isNil, omit } from 'ramda';
import type { ForwardRefRenderFunction } from 'react';
import { useCallback } from 'react';
import { forwardRef } from 'react';

const _PiInputNumber: ForwardRefRenderFunction<any, InputNumberProps> = (props, ref) => {
  const rest = omit(['min', 'max'], props);
  const onBlur = useCallback(
    (e) => {
      const value = Number(e.target.value);
      if (isNil(value) || !isFinite(value)) {
        props.onChange?.(null);
        props.onBlur?.(e);
        return;
      }

      if (!isNil(props.max)) {
        if (value > (props.max as number)) {
          props.onChange?.(null);
          message.error('超出范围');
        }
      }

      if (!isNil(props.min)) {
        if (value < (props.min as number)) {
          props.onChange?.(null);
          message.error('超出范围');
        }
      }

      props.onBlur?.(e);
    },
    [props],
  );

  return <InputNumber ref={ref} {...rest} onPressEnter={onBlur} onBlur={onBlur} />;
};

export const PiInputNumber = forwardRef(_PiInputNumber);
