import type { ApiResponse } from '@linkpi/core';
import { getQuoteOriginProp, MATCHING_TYPES, QUOTE_SYS_PROP } from '@linkpi/core';

import { MATCHING_PROP_OPTION_RULE, MATCHING_PROP_TYPE_RULE } from './matchingPropOptionRules';

type tempPropWithStringIndex = ApiResponse.CurrentUser.TemplateProp & { index: string };

export const getMatchingPropOption = (
  curTempType: ApiResponse.CurrentUser.TemplateProp['type'],
  tempProp: tempPropWithStringIndex[],
  tempMap: Record<string, ApiResponse.CurrentUser.TemplateInfo>,
) => {
  if (!curTempType || !tempProp.length) return [];

  const enableConfig = MATCHING_PROP_OPTION_RULE[curTempType];
  if (!enableConfig) return [];
  return tempProp
    .filter((x) => {
      // 是否支持引用标题
      if (Number.isFinite(Number(x.index)) && enableConfig.supportType.includes(x.index)) {
        return true;
      }

      // 支持引用的类型
      if (enableConfig.supportType === 'all') {
        return true;
      }

      if (!enableConfig.supportType.includes(x.type)) {
        return false;
      }

      // 公式类型
      if (x.type === 'formula') {
        const formulaFormat = x.formulaFormat || 0;
        return enableConfig.supportFormulaFormat.includes(formulaFormat);
      }

      // 引用类型
      if (x.type === 'quote') {
        // 引用属性的引用方式
        const quoteMatchingType = (x.matchingType || 0) as 0 | 1 | 2 | 3 | 4 | 5 | -1;
        if (!enableConfig.supportQuoteMatchingType.includes(quoteMatchingType)) {
          return false;
        }

        // 引用属性引用原值的的属性类型
        if (quoteMatchingType === 0) {
          if (enableConfig.supportQuoteMatchingRealDataType === 'all') {
            return true;
          }
          const originProp = getQuoteOriginProp(x, tempMap);
          if (!originProp) return false;

          if (originProp === '-3') {
            return enableConfig.supportQuoteMatchingRealDataType.includes('-3');
          }

          // 支持的属性
          if (!enableConfig.supportQuoteMatchingRealDataType.includes(originProp.type)) {
            return false;
          }

          // 公式类型
          if (originProp.type === 'formula') {
            const formulaFormat = originProp.formulaFormat || 0;
            return enableConfig.supportQuoteMatchingRealDataFormulaFormat.includes(formulaFormat);
          }
        }
      }

      return true;
    })
    .map((x) => ({
      label: x.name,
      value: x.index,
    }));
};

export const getMatchingTypeOption = (
  curTempType: ApiResponse.CurrentUser.propType, // 当前属性类型
  matchingPropMark: string, // 当前引用属性 mark
  tempProps: ApiResponse.CurrentUser.TemplateProp[],
  tempMap: Record<string, ApiResponse.CurrentUser.TemplateInfo>,
) => {
  // matchingProp -3 || tempId-index
  if (!curTempType || !matchingPropMark || !tempProps?.length) {
    return [];
  }

  const curTempRule = MATCHING_PROP_TYPE_RULE[curTempType];
  if (!curTempRule) return [];
  let matchingProp: any = null;

  if (!Number.isFinite(Number(matchingPropMark))) {
    const [_tempId, _index] = matchingPropMark.split('-');
    matchingProp = tempProps[Number(_index)];
  } else if (matchingPropMark in QUOTE_SYS_PROP) {
    // @ts-ignore
    matchingProp = QUOTE_SYS_PROP[matchingPropMark];
  }

  if (!matchingProp) return [];

  return MATCHING_TYPES.filter((x) => {
    const typeRule = curTempRule[String(x.value)];
    if (!typeRule) return false;

    if (typeRule.supportType === 'all') {
      return true;
    }

    // 匹配属性所开启的引用条件
    const matchingType = (matchingProp.matchingType || 0) as 0 | 1 | 2 | 3 | 4 | 5 | -1;

    // 引用属性的引用统计 当做number类型
    let matchingPropMatchingType = matchingProp.type;
    if (matchingProp.type === 'quote' && matchingType > 0) {
      matchingPropMatchingType = 'number';
    }

    // 是否支持的属性类型
    if (!typeRule.supportType.includes(matchingPropMatchingType) && matchingType <= 0) {
      return false;
    }

    // 公式
    if (matchingPropMatchingType === 'formula') {
      const formulaFormat = matchingProp.formulaFormat || 0;
      return typeRule.supportFormulaFormat.includes(formulaFormat);
    }

    // 引用
    if (matchingPropMatchingType === 'quote') {
      if (typeRule.supportQuoteMatchingType === 'all') {
        if (matchingType !== 0) return true;
      } else if (!typeRule.supportQuoteMatchingType.includes(matchingType)) {
        return false;
      }

      // 匹配原值
      if (matchingType === 0) {
        if (typeRule.supportQuoteMatchingRealDataType === 'all') {
          return true;
        }

        const originProp = getQuoteOriginProp(matchingProp, tempMap);
        if (!originProp) return false;

        if (originProp === '-3') {
          return typeRule.supportQuoteMatchingRealDataType.includes('-3');
        }

        let originPropMatchingType = originProp.type;
        if (originProp.matchingType > 0) {
          originPropMatchingType = 'number';
        }

        if (!typeRule.supportQuoteMatchingRealDataType.includes(originPropMatchingType)) {
          return false;
        }

        if (originProp.type === 'formula') {
          const formulaFormat = originProp.formulaFormat || 0;
          return typeRule.supportQuoteMatchingRealDataFormulaFormat.includes(formulaFormat);
        }
      }
    }
    return true;
  }).map((x) => {
    if (
      (x.value === 3 || x.value === 4) &&
      (matchingProp.type === 'date' || matchingProp.type === 'datetime')
    )
      return {
        label: x.dateLabel,
        value: x.value,
      };
    return x;
  });
};
