import request from '@/utils/request';

export async function queryMyPosts() {
  return request('/api/queryMyPosts', {
    method: 'POST',
  });
}

export async function queryMyReplies() {
  return request('/api/queryMyReplies', {
    method: 'POST',
  });
}
