import {
  ProForm,
  ProFormCheckbox,
  ProFormGroup,
  ProFormRadio,
  ProFormSelect,
} from '@ant-design/pro-form';
import { useMemoizedFn } from 'ahooks';
import { Form } from 'antd';
import { isNil } from 'lodash';
import type { FC } from 'react';
import { useEffect, useState } from 'react';

import { useTempPropConfig } from '../hooks';

import styles from './CanExportGraphicCodeParams.less';

const exportFileNameList = ['标题', '属性值'];

const formatList = {
  二维码: '二维码',
  CODE128: 'CODE128',
  'EAN-13': 'EAN-13',
  'EAN-8': 'EAN-8',
  'UPC-A': 'UPC-A',
  'UPC-E': 'UPC-E',
  CODE39: 'CODE39',
  CODABAR: 'CODABAR',
  ITF: 'ITF',
};

const exportContent = [
  {
    label: '单码',
    value: 0,
  },
  {
    label: '码和属性值',
    value: 1,
  },
];

export const CanExportGraphicCodeParams: FC = () => {
  const [lastCheckedOption, setLastCheckedOption] = useState(exportFileNameList[1]);
  const { isLock, propParams, setPropParams } = useTempPropConfig();

  const saveCanExportGraphicCodeParams = (values: any) => {
    let title = propParams?.canExportGraphicCodeParams?.title ?? false;
    let propValue = propParams?.canExportGraphicCodeParams?.propValue ?? false;
    let exportContent = propParams?.canExportGraphicCodeParams?.exportContent ?? 1;
    let graphicCodeFormat = propParams?.canExportGraphicCodeParams?.graphicCodeFormat ?? 'Code128';
    graphicCodeFormat = values.select ?? graphicCodeFormat;
    exportContent = values.radioGroup ?? exportContent;
    if (values?.checkboxGroup) {
      if (values?.checkboxGroup.length > 0) {
        title = values.checkboxGroup.includes('标题') ? true : false;
        propValue = values.checkboxGroup.includes('属性值') ? true : false;
      }
    }
    setPropParams({
      ...propParams,
      canExportGraphicCodeParams: {
        title: title,
        propValue: propValue,
        exportContent: exportContent,
        graphicCodeFormat: graphicCodeFormat,
      },
    });
  };

  const exportNameList = useMemoizedFn(() => {
    const list = [];
    const params = propParams?.canExportGraphicCodeParams;
    if (isNil(params?.title) && isNil(params?.propValue)) {
      list.push(exportFileNameList[1]);
    } else {
      if (params?.title) {
        list.push(exportFileNameList[0]);
      }
      if (params?.propValue) {
        list.push(exportFileNameList[1]);
      }
    }
    setLastCheckedOption(list[list.length - 1]);
    return list;
  });

  const [form] = Form.useForm();

  useEffect(() => {
    const initialValues = {
      select: propParams?.canExportGraphicCodeParams?.graphicCodeFormat ?? 'Code128',
      radioGroup: propParams?.canExportGraphicCodeParams?.exportContent ?? 1,
      checkboxGroup: exportNameList(),
    };

    form.setFieldsValue(initialValues);
  }, [exportNameList, form, propParams]);

  return (
    <div className={styles.graphicCodeParent} key={'canExportGraphicCodeParams'}>
      <ProForm
        form={form}
        submitter={false}
        onValuesChange={(_, values) => {
          saveCanExportGraphicCodeParams(values);
        }}
      >
        <ProFormSelect
          label="图形码格式"
          className="full-w"
          name="select"
          valueEnum={formatList}
          disabled={isLock}
          placeholder={'请选择'}
        />

        <ProFormGroup size={120}>
          <ProFormRadio.Group label="导出内容" name="radioGroup" options={exportContent} />

          <Form.Item
            name="checkboxGroup"
            label="导出文件名"
            dependencies={['checkboxGroup']}
            getValueFromEvent={(checkedValues: string[]) => {
              if (checkedValues.length < 1) {
                checkedValues.push(lastCheckedOption);
              } else {
                setLastCheckedOption(checkedValues[checkedValues.length - 1]);
              }
              return checkedValues;
            }}
          >
            <ProFormCheckbox.Group options={exportFileNameList} />
          </Form.Item>
        </ProFormGroup>
      </ProForm>
    </div>
  );
};
