import type { PositionType } from '../components/_baseComponent';
import GridBaseComponent from '../components/_baseComponent';
import type { PiGridModules } from '../core';

class StatusFlowButton extends GridBaseComponent {
  public data: any;
  public type: string = 'statusFlowButton';
  public node: PiNode;
  public fontSize: number;
  public captrue: boolean = false;

  constructor(
    position: PositionType,
    moduleInstances: PiGridModules,
    data: any,
    node: PiNode,
    fontSize: number,
  ) {
    super(position, moduleInstances);

    this.data = data;
    this.node = node;
    this.fontSize = fontSize;
  }

  public drawText(text: string, color: string, x: number, y: number, fontSize?: number) {
    const { Draw } = this.moduleInstances;

    Draw.attr({
      fillStyle: color,
      textBaseline: 'top',
      textAlign: 'start',
      font: `${Draw.npx(fontSize || 14)}px  sans-serif`,
    });

    Draw.fillText(text, x, y);
    return Draw.measureTextWidth(text);
  }

  public async click() {
    const { templateMap, config } = this.moduleInstances.DataManager;
    const currentTemplate = templateMap[this.node.tempInfo.id];
    const payload = {
      task_status: currentTemplate.task_status[this.data.index],
      index: this.data.index,
      type: '状态转换',
      currentTemplate,
    };
    config.handleStatusChange(this.node, payload);
  }

  public render() {
    const { Render, Draw } = this.moduleInstances;
    const { x, y } = this.position;

    Draw.fillRoundRect(this.position, 6, this.data.color);

    this.drawText(this.data.name, 'white', x + 6, y + 6, this.fontSize);

    if (this.isHover) {
      Render.setCursor('pointer');
    }
  }
}

export default StatusFlowButton;
