import type { PositionType } from '../components/_baseComponent';
import GridBaseComponent from '../components/_baseComponent';
import type { PiGridModules } from '../core';

class EditAttrGroup extends GridBaseComponent {
  public data: {
    node: PiNode;
    groupProps: any[];
    unitPosition: PositionType;
  };
  public type: string = 'editAttrGroup';
  public captrue: boolean = false;
  constructor(
    position: PositionType,
    moduleInstances: PiGridModules,
    data: {
      node: PiNode;
      groupProps: any[];
      unitPosition: PositionType;
    },
  ) {
    super(position, moduleInstances);

    this.data = data;
  }

  public click() {
    this.moduleInstances.DataManager.config.setAttrGroupConfig(this.data);
  }

  public drawIcon(x: number, y: number, unicode: string, fontSize: number) {
    const { Draw } = this.moduleInstances;
    const { ctx } = Draw;
    const styleMode = Draw.config.styleMode;

    ctx.save();
    Draw.attr({
      fillStyle: styleMode === 'darkness' ? 'rgba(62,112,223,0.5)' : '#316EF5',
      textBaseline: 'top',
      textAlign: 'start',
      font: `${Draw.npx(fontSize)}px iconfont`,
    });

    Draw.fillText(Draw.iconFont(unicode), x, y);
    ctx.restore();
  }

  public render() {
    const { Render } = this.moduleInstances;
    const { x, y, height } = this.position;

    if (this.isHover) {
      Render.setCursor('pointer');
    }

    this.drawIcon(x, y, '&#xe723;', height);
  }
}

export default EditAttrGroup;
