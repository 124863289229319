import { addNewNode } from '@linkpi/core';
import { docapiQueryNodeStatusLog } from '@linkpi/core/pi';
import { omit } from 'ramda';

import request from '@/utils/request';

interface IBatchOP {
  ops: [string, unknown][];
  async: boolean;
  /**
   * @default 1200
   */
  async_timeout?: number;
  org_id?: string;
  /**
   * @default 1
   */
  concur?: number;
}
export function _batchOp(params: IBatchOP) {
  return request('/docapi/batchOp', { method: 'POST', data: params });
}

interface IBatchUpdateProps {
  orgId: string;
  tempId: string;
  nodes: { propIndexes: number[]; values: unknown[]; id: string }[];
}
export function batchUpdateProps({ orgId, tempId, nodes }: IBatchUpdateProps) {
  const opParams: IBatchOP = {
    org_id: orgId,
    ops: nodes.map((node) => [
      '/docapi/updateProp',
      {
        org_id: orgId,
        node_id: node.id,
        temp_id: tempId,
        index: node.propIndexes,
        value: node.values,
      },
    ]),
    concur: 3,
    async: false,
  };

  return _batchOp(opParams);
}
export interface ICopyNodes {
  orgId: string;
  parentId: string;
  copyId: unknown[];
  copyMode: 'NoChild' | 'OnlyChildren' | 'AllChildren';
  /** @description 主题类型转换关系 */
  temp_map?: Record<string, string>;
  /** @description 转换目标主题类型时，设置属性默认值 */
  set_prop?: Record<string, Record<string | number, unknown>>;
}
export function copyNodes(params: ICopyNodes) {
  const { orgId, parentId, copyId } = params;

  return addNewNode(request, {
    ...omit(['orgId', 'parentId', 'copyId'])(params),
    org_id: orgId,
    parentId: parentId,
    copyId: copyId,
  });
}

type SearchNodesProps = {
  org_id: string[];
  includeDelete?: boolean;
  keyWords: string[];
  hardlimit?: number;
};
export function searchNodes(params: SearchNodesProps) {
  return request<PiNodeMetaData[]>('/docapi/getNodeByFilter', {
    method: 'POST',
    data: params,
  }).then((res) => {
    if (res.status === 'ok') return res.data;
  });
}

interface batchUpdateNodePermissionProps {
  orgId: string;
  nodeIds: string[];
  nodes: { propIndexes: number[]; values: unknown[]; id: string }[];
  open: boolean;
}
export function batchUpdateNodePermission({ orgId, nodes, open }: batchUpdateNodePermissionProps) {
  const opParams: IBatchOP = {
    org_id: orgId,
    ops: nodes.map((node) => [
      '/docapi/node/modifySysProp',
      {
        key: [
          '_sys_public',
          '_sys_na_readonly',
          '_sys_readonly',
          '_sys_protect',
          '_sys_na_banCopy',
          '_sys_banCopy',
          '_sys_na_banPaste',
          '_sys_banPaste',
        ],
        value: [open, false, false, false, false, false, false, false],
        org_id: orgId,
        node_id: node,
      },
    ]),
    concur: 3,
    async: false,
  };

  return _batchOp(opParams);
}

export const queryNodeStatusLog = (params: Parameters<typeof docapiQueryNodeStatusLog>[1]) => {
  return docapiQueryNodeStatusLog(request as any, params).then((res) => {
    if (res.status === 'ok')
      return res.data as {
        status: number;
        create_time: number;
        status_name: string;
        task_status: [
          {
            name: string;
            type: 'user';
            value: string | null;
            display: boolean;
            required: boolean;
          },
          {
            name: string;
            type: 'user';
            value: string[] | null;
            display: boolean;
            required: boolean;
          },
          {
            name: string;
            type: 'datetime';
            value: number | null;
            display: boolean;
            required: boolean;
          },
          {
            name: string;
            type: 'datetime';
            value: number | null;
            display: boolean;
            required: boolean;
          },
        ];
      }[];
    return [];
  });
};
