import { message, Modal } from 'antd';
import { useState } from 'react';

import RepeatTask from '@/pages/home/components/NewNodeProp/RepeatTask';
import request from '@/utils/request';

type nodeId = string;
type propsType = {
  visible: boolean;
  nodeId: nodeId | nodeId[];
  orgId: string;
  onOk: () => void;
  onCancel: () => void;
  templateList: any;
};

const EditRepeatTaskModal = (props: propsType) => {
  const { visible, nodeId, orgId, onOk, onCancel, templateList } = props;

  const [saveLoading, setSaveLoading] = useState(false);

  const onSend = async (data: any) => {
    setSaveLoading(true);
    const res = await request('/docapi/setPeriodic', {
      method: 'POST',
      data: {
        node_id: nodeId,
        org_id: orgId,
        _sys_periodic: data,
      },
    });
    setSaveLoading(false);

    if (res.status === 'ok') {
      onOk();
    } else {
      message.error('批量设置失败,请重试');
    }
  };

  return (
    <Modal
      title={nodeId instanceof Array ? '批量编辑 重复' : '编辑重复'}
      open={visible}
      footer={false}
      destroyOnClose={true}
      onCancel={onCancel}
      confirmLoading={saveLoading}
    >
      <RepeatTask
        node={{}}
        visible={true}
        orgId={orgId}
        onlyReturnData={onSend}
        onCancel={onCancel}
        templateList={templateList}
      />
    </Modal>
  );
};

export default EditRepeatTaskModal;
