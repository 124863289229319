import BaseModule from './_baseModule';

export default class Event extends BaseModule {
  public mousedownX: number = 0;
  public shiftDown: boolean = false;
  public bootstrap() {
    const { Listeners, Render } = this.moduleInstances;
    Listeners.on(window, 'resize', Render.render);
    Listeners.on(Render.doms.container, 'mousewheel', this.onWrapperMouseWheel);
    // 兼容火狐
    Listeners.on(Render.doms.container, 'DOMMouseScroll', this.onWrapperFireFoxMouseWheel);
    Listeners.on(Render.doms.container, 'mousemove', this.onWrapperMouseMove);
    Listeners.on(Render.doms.container, 'mouseleave', this.onWrapperMouseLeave);
    Listeners.on(Render.doms.container, 'mousedown', this.onWrapperMouseDown);
    Listeners.on(Render.doms.container, 'click', this.onWrapperClick);
    Listeners.on(Render.doms.container, 'contextmenu', (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
    });

    Listeners.on(document, 'keydown', this.onWrapperKeyDown);
    Listeners.on(document, 'keyup', this.onWrapperKeyUp);
  }

  public destroy() {
    const { Listeners, Render } = this.moduleInstances;
    Listeners.off(window, 'resize', Render.render);
    Listeners.off(Render.doms.container, 'mousewheel', this.onWrapperMouseWheel);
    Listeners.off(Render.doms.container, 'mousemove', this.onWrapperMouseMove);
    Listeners.off(Render.doms.container, 'mouseleave', this.onWrapperMouseLeave);
    Listeners.off(Render.doms.container, 'mousedown', this.onWrapperMouseDown);
    Listeners.off(Render.doms.container, 'click', this.onWrapperClick);
    Listeners.off(Render.doms.container, 'contextmenu', (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
    });
    Listeners.off(document, 'keydown', this.onWrapperKeyDown);
    Listeners.off(document, 'keyup', this.onWrapperKeyUp);
  }

  private onWrapperKeyDown = (e: any) => {
    if (e.keyCode === 16) this.shiftDown = true;
  };

  private onWrapperKeyUp = (e: any) => {
    if (e.keyCode === 16) this.shiftDown = false;
  };

  // 鼠标滚轮
  private onWrapperMouseWheel = (e: WheelEvent) => {
    let preventDefault = true;

    const { HorizontalScrollbar, VerticalScrollbar } = this.moduleInstances;
    const { deltaY, deltaX, shiftKey } = e;

    // 不允许斜着滚动
    const temp = Math.max(Math.abs(deltaX), Math.abs(deltaY));

    // x轴
    if (temp === Math.abs(deltaX) || (temp === Math.abs(deltaY) && shiftKey)) {
      const currentLeft = HorizontalScrollbar.getScrollLeft() + deltaX;

      HorizontalScrollbar.move({
        left: currentLeft < 0 ? 0 : currentLeft,
      });
    }

    // y轴
    if (temp === Math.abs(deltaY)) {
      const currentTop = VerticalScrollbar.getScrollTop() + deltaY;

      if (currentTop < 0) preventDefault = false;
      if (
        currentTop >=
        VerticalScrollbar.doms.child.offsetHeight - VerticalScrollbar.doms.container.offsetHeight
      )
        preventDefault = false;

      VerticalScrollbar.move({
        top: currentTop < 0 ? 0 : currentTop,
      });
    }

    if (preventDefault) {
      e.preventDefault();
    }
  };

  private onWrapperFireFoxMouseWheel = (e: any) => {
    const { HorizontalScrollbar, VerticalScrollbar } = this.moduleInstances;
    const { detail, axis } = e;

    // x轴
    if (axis === 1) {
      const currentLeft = HorizontalScrollbar.getScrollLeft() + detail * 10;

      HorizontalScrollbar.move({
        left: currentLeft < 0 ? 0 : currentLeft,
      });
    }

    // y轴
    if (axis === 2) {
      const currentTop = VerticalScrollbar.getScrollTop() + detail * 10;

      VerticalScrollbar.move({
        top: currentTop < 0 ? 0 : currentTop,
      });
    }
  };

  private resizingAttr = (e: MouseEvent) => {
    const { DataManager, Render } = this.moduleInstances;
    const { key, width, minWidth } = DataManager.resizingAttr;
    const resizeWidth =key === 'actions' ? this.mousedownX - e.clientX : e.clientX - this.mousedownX;
    if (width + resizeWidth >= 100) {
      DataManager.headerCellWidthMap[key] = width + resizeWidth;
      DataManager.getColCells();
      Render.render();
    }
  };

  private onWrapperMouseMove = (e: MouseEvent) => {
    const { Render, DataManager } = this.moduleInstances;

    const { x, y } = this.getCanvasPosition(e);
    window.__PI__GRID.mousePosition = { x, y };

    if (DataManager.resizingAttr) this.resizingAttr(e);

    this.moduleInstances.DataManager.hoverHeaderCellKey = '';
    this.moduleInstances.DataManager.hoverCollapseGroupFunctionCell = '';
    this.moduleInstances.DataManager.hoverFooterFunctionCell = '';
    this.moduleInstances.DataManager.hoverRow = '';

    Render.render();
  };

  private onWrapperMouseDown = (e: MouseEvent) => {
    const mouseComponents = window.__PI__GRID.mouseComponents;
    this.mousedownX = e.clientX;

    mouseComponents.forEach((component) => {
      if (component.mousedown) component.mousedown();
    });
  };

  private onWrapperMouseLeave = (e: MouseEvent) => {
    const { Render } = this.moduleInstances;

    window.__PI__GRID.mousePosition = { x: -100, y: -100 };

    this.moduleInstances.DataManager.hoverHeaderCellKey = '';
    this.moduleInstances.DataManager.hoverCollapseGroupFunctionCell = '';
    this.moduleInstances.DataManager.hoverFooterFunctionCell = '';
    this.moduleInstances.DataManager.hoverRow = '';

    Render.render();
  };

  /**
   * 计算 canvas 坐标
   */
  private getCanvasPosition(e: MouseEvent) {
    const { clientX, clientY } = e;
    const { x, y } = this.moduleInstances.Render.doms.container.getBoundingClientRect();
    return {
      x: clientX - x,
      y: clientY - y,
    };
  }

  private onWrapperClick = (e: MouseEvent) => {
    if (this.moduleInstances.DataManager.resizingAttr) {
      this.moduleInstances.DataManager.resizingAttr = null;
      this.moduleInstances.DataManager.config.saveTableConfig(
        this.moduleInstances.DataManager.headerCellWidthMap,
        'attrsWidth',
      );
      return;
    }

    const mouseComponents = window.__PI__GRID.mouseComponents;
    const len = mouseComponents.length;
    let index = 0;
    let captrue = false;

    while (!captrue && index < len) {
      const component = mouseComponents[index];

      component.click();
      index++;

      if (!component.captrue) {
        captrue = true;
      }
    }
  };
}
