import { propIsNull, updateProp } from '@linkpi/core';
import { tempValueDisplay } from '@linkpi/core';
import { generateId, getDefaultTempProp, getQuoteOriginProp } from '@linkpi/core';
import { usePiNode } from '@linkpi/core/web';
import { request, useDispatch, useSelector } from '@umijs/max';
import { Input, InputNumber, message } from 'antd';
import { cloneDeep, isEqual } from 'lodash';
import type { Dispatch, FC, SetStateAction } from 'react';
import { useRef } from 'react';
import { useState } from 'react';

import { useOrgConnection, useOrgUserMap } from '@/hook';
import { useOrgDepartmentNodesMap } from '@/hook/useOrgStructure';
import { useOrgTempMap } from '@/hook/useTemplate';
import type { FileIconTypes } from '@/utils/utils';
import { delay } from '@/utils/utils';
import { getFileTypeIconByUrl } from '@/utils/utils';

import styles from './styles.less';

type AttachmentValue = {
  name: string;
  src: string;
  type: FileIconTypes;
  preview: string;
};

type FileName = string;
interface PropType {
  value: FileName[];
  fileSrcArr: string[];
}
interface ScanAddData {
  isEditingCol: false | undefined;
  editFrequency: false;
  editRepeatStep: false;
  key: string | undefined;
  recordProp: string | undefined;
  frequency: number | undefined;
  repeatStep: number | undefined;
}

const ScanAddModalItem: FC<{
  data: any;
  index: number;
  scanAllPropList: number[] | undefined;
  recordList: any[];
  setRecordList: Dispatch<SetStateAction<ScanAddData[]>>;
  widths: number[];
  marginRight: number | undefined;
  initStatus: number | undefined;
  repeatType: number | undefined;
  repeatStep: number | undefined;
  countProp: string | number | undefined;
  recordProp: string | number | undefined;
  initTempId: string;
  initParentId: string;
  orgId: string;
  propList: any[];
  t: any[];
}> = (props) => {
  const {
    data,
    index,
    scanAllPropList,
    recordList,
    setRecordList,
    widths,
    marginRight,
    initStatus,
    repeatType,
    repeatStep,
    countProp,
    recordProp,
    initTempId,
    initParentId,
    orgId,
    propList,
    t,
  } = props;
  const dispatch = useDispatch();
  const orgUserMap = useOrgUserMap();
  const { currentUser } = useSelector((state: any) => state.user);
  const orgConnection = useOrgConnection(orgId);
  const [nodeId, setNodeId] = useState(null);
  const [getterNode] = usePiNode(orgConnection, nodeId);
  const tempMap = useOrgTempMap(orgId);
  const departmentNodeMap = useOrgDepartmentNodesMap();
  const addInputRef = useRef<any>();
  const [addInputValue, setAddInputValue] = useState('');
  const handleAddRecord = async (e: any) => {
    const value = e.target.value;
    if (!value) return message.error('输入无效');
    const newRecordList = [...recordList];
    let newRecordKey = '';
    let frequency = 1;
    if (repeatType === 1) {
      const hasRecord = newRecordList.find((f) => f.recordProp === value);
      if (hasRecord) {
        hasRecord.frequency++;
        newRecordKey = hasRecord.key;
        frequency = hasRecord.frequency * repeatStep;

        await checkIsAddFinish(newRecordKey);
        const node = orgConnection?.nodeManager.getNode(newRecordKey);
        const oldProps = node?.prop?._sys_temp?.[1]
          ? JSON.parse(JSON.stringify(node?.prop?._sys_temp?.[1]))
          : {};
        const newProps = cloneDeep(oldProps);
        newProps[countProp] = frequency;
        const oldTempProp = oldProps;
        const newTempProp = newProps;
        const diffIndex = newTempProp
          .map((x, i) => {
            return !isEqual(x, oldTempProp[i]) ? i : false;
          })
          .filter((x) => x !== false);
        const diffValue = diffIndex.reduce((a, b) => {
          a[b] = newTempProp[b];
          return a;
        }, {});
        const newActionReq = {
          org_id: orgId,
          node_id: newRecordKey,
          temp_id: node?.prop?._sys_temp?.[0],
          index: diffIndex,
          value: Object.values(diffValue),
        };
        const [err, res] = await updateProp(request, newActionReq);
        if (res.status === 'ok') {
          setAddInputValue('');

          setTimeout(() => {
            addInputRef.current.focus();
          });
        } else {
          return message.error(err.message);
        }
      } else {
        newRecordKey = generateId();
        const newRecord = {
          key: newRecordKey,
          recordProp: value,
          frequency: 1,
          repeatStep: repeatStep,
        };
        newRecordList.splice(recordList.length - 1, 0, newRecord);
        draftInsert(value, frequency, newRecordKey, newRecordList, handleSucess);
      }
    } else if (repeatType === 2) {
      // 直接新增
      newRecordKey = generateId();
      newRecordList.splice(recordList.length - 1, 0, {
        key: newRecordKey,
        recordProp: value,
        frequency: 1,
      });
      draftInsert(value, frequency, newRecordKey, newRecordList, handleSucess);
    }
  };

  const handleEdit = async (newRecordKey: string, value: string, type: string) => {
    const newRecordList = [...recordList];
    const item = newRecordList.find((x) => x.key === newRecordKey);
    if (!item) return;
    if (type === 'frequency') {
      item.frequency = Number(value);
    } else {
      item.repeatStep = Number(value);
    }
    const result = item.frequency * item.repeatStep;

    await checkIsAddFinish(newRecordKey);
    const node = orgConnection?.nodeManager.getNode(newRecordKey);
    const oldProps = node?.prop?._sys_temp?.[1]
      ? JSON.parse(JSON.stringify(node?.prop?._sys_temp?.[1]))
      : {};
    const newProps = cloneDeep(oldProps);
    newProps[countProp] = result;
    const oldTempProp = oldProps;
    const newTempProp = newProps;
    const diffIndex = newTempProp
      .map((x, i) => {
        return !isEqual(x, oldTempProp[i]) ? i : false;
      })
      .filter((x) => x !== false);
    const diffValue = diffIndex.reduce((a, b) => {
      a[b] = newTempProp[b];
      return a;
    }, {});
    const newActionReq = {
      org_id: orgId,
      node_id: newRecordKey,
      temp_id: node?.prop?._sys_temp?.[0],
      index: diffIndex,
      value: Object.values(diffValue),
    };
    const [err, res] = await updateProp(request, newActionReq);
    if (res.status === 'ok') {
      setRecordList(newRecordList);
    } else {
      return message.error(err.message);
    }
  };

  function handleSucess(newRecordKey: any, newRecordList: any[]) {
    setRecordList(newRecordList);

    setAddInputValue('');

    setTimeout(() => {
      addInputRef?.current?.focus();
      setNodeId(newRecordKey);
    });
  }

  const draftInsert = async (
    value: any,
    frequency: any,
    newRecordKey: any,
    newRecordList: any[],
    handleSucess: (arg0: any, arg1: any) => void,
  ) => {
    const matchConfig = await dispatch({
      type: 'workspace/fetchMatchProps',
      payload: { org_id: orgId, temp_id: initTempId },
    });

    const addNodeDatas: any = {
      org_id: orgId,
      parentId: initParentId,
      siblingId: null,
      draft: true,
      node: {},
    };
    const template = { ...t };

    const prop = new Array(template?.prop.length).fill(null);
    const type = propList.find((i) => i.index === recordProp)?.type;
    prop[recordProp] = type !== 'text' ? parseInt(value) : value;
    prop[countProp] = repeatType === 1 ? frequency * repeatStep : 1;

    const newProps = getDefaultTempProp(
      {},
      template,
      currentUser,
      null,
      orgUserMap,
      matchConfig as any,
      { initProp: prop, initStatus: initStatus },
    );

    addNodeDatas.node = {
      node_id: newRecordKey,
      prop: newProps,
      title: '',
    };

    const parentNode = orgConnection?.nodeManager.getNode(addNodeDatas.parentId);
    await parentNode?.insertNode(
      0,
      { ...addNodeDatas.node, id: addNodeDatas.node.node_id },
      null,
      true,
    );
    handleSucess(newRecordKey, newRecordList);
  };

  const checkIsAddFinish = async (newId: string): Promise<PiNode> => {
    const has = orgConnection?.nodeManager.hasData(newId) as PiNode;
    if (has) {
      return has;
    } else {
      await delay(20);
      return checkIsAddFinish(newId);
    }
  };

  const handleRemoveRecord = (index: number, record) => {
    orgConnection?.nodeManager.delete(record.key, true);
    const newRecordList = [...recordList];
    newRecordList.splice(index, 1);
    setRecordList(newRecordList);
  };

  const renderText = (record, itemIndex, startIndex) => {
    let res = null;
    const prop = propList.find((i) => itemIndex === i.index);
    const index = prop?.index;
    let type = prop?.item?.type;
    const node = getterNode?.value?.data;
    if (!node) return '';
    const nodeValue = node?.e?._sys_temp[1][index];
    let attachmentList: any[] | null = null;
    if (type === 'quote') {
      const originProp = getQuoteOriginProp(prop?.item, tempMap);
      if (originProp) {
        // 引用标题
        if (typeof originProp === 'string') {
          type = 'text';
        } else {
          type = originProp.type;
        }
      }
    }
    if (type === 'attachment') {
      const fileSrcArr = node?.e?._sys_attach?.[index];
      if (nodeValue?.length && typeof nodeValue[0] === 'object') {
        attachmentList = [];
      } else {
        attachmentList = formatToAttachmentList(nodeValue, fileSrcArr);
      }
      return (
        <div
          className={styles.item}
          style={{
            width: widths[startIndex],
            marginRight: marginRight,
            display: 'flex',
            overflowX: 'auto',
          }}
        >
          {attachmentList?.map((x, i) => <AttachmentItem value={x} key={i} />)}
        </div>
      );
    } else {
      res = tempValueDisplay({
        propConfig: prop?.item,
        propValue: nodeValue,
        propIndex: index,
        sysCascade: node?.e?._sys_cascade,
        userMap: orgUserMap,
        tempMap,
        departmentMap: departmentNodeMap,
      });
      return (
        <div
          className={styles.item}
          style={{ width: widths[startIndex], marginRight: marginRight }}
        >
          {res}
        </div>
      );
    }
  };

  type AttachmentItemPropsType = {
    value: AttachmentValue;
  };

  const AttachmentItem: FC<AttachmentItemPropsType> = (props) => {
    const { value } = props;
    let IconPreview;
    if (value.type === 'image') {
      IconPreview = (
        <div
          style={{
            backgroundImage: `url(${value.src})`,
            width: '100%',
            height: '100%',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
      );
    } else {
      IconPreview = <img src={value.preview} style={{ width: 20 }} />;
    }
    return (
      <div
        style={{
          display: 'flex',
          flexShrink: '0',
          alignItems: 'center',
          justifyContent: 'center',
          width: '32px',
          height: '32px',
          marginLeft: '2px',
          border: '1px solid #c9d0d9',
        }}
      >
        {IconPreview}
      </div>
    );
  };

  const formatToAttachmentList = (
    fileValue: PropType['value'],
    fileSrcArr: PropType['fileSrcArr'],
  ): AttachmentValue[] => {
    if (propIsNull(fileValue)) {
      return [];
    }

    if (fileValue instanceof Array) {
      return fileValue
        ?.filter((x, i) => !!x && !!fileSrcArr?.[i])
        ?.map((x, i) => {
          const fileSrc = fileSrcArr[i];
          const fileType = getFileTypeIconByUrl(fileSrc);
          return {
            name: x,
            src: fileSrc,
            type: fileType.type,
            preview: fileType.src,
          };
        });
    } else {
      return [];
    }
  };

  const content = (() => {
    let startIndex = 5;
    const contentlist = [];
    //序号
    contentlist.push(
      <span className={styles.item} style={{ width: widths[0], marginRight: marginRight }}>
        {index + 1}
      </span>,
    );
    //扫码值
    if (data?.isEditingCol) {
      contentlist.push(
        <Input
          autoFocus
          className={styles.item}
          style={{ width: widths[1], marginRight: marginRight }}
          ref={addInputRef}
          value={addInputValue}
          onPressEnter={handleAddRecord}
          onChange={(e) => setAddInputValue(e.target.value)}
        />,
      );
    } else {
      contentlist.push(
        <span
          className={styles.item}
          style={{ width: widths[1], marginRight: marginRight, fontWeight: 500, fontSize: '16px' }}
        >
          {data.recordProp}
        </span>,
      );
    }
    //扫码次数
    if (data?.isEditingCol) {
      contentlist.push(
        <span className={styles.item} style={{ width: widths[2], marginRight: marginRight }} />,
      );
    } else {
      if (data?.editFrequency) {
        contentlist.push(
          <InputNumber
            min={1}
            className={styles.item}
            style={{ width: widths[2], marginRight: marginRight }}
            autoFocus
            defaultValue={data.frequency}
            onBlur={(e) => {
              const newRecordList = [...recordList];
              newRecordList[index].editFrequency = false;
              setRecordList(newRecordList);
              handleEdit(data.key, e.target.value, 'frequency');
            }}
            onPressEnter={(e) => {
              const newRecordList = [...recordList];
              newRecordList[index].editFrequency = false;
              setRecordList(newRecordList);
              handleEdit(data.key, (e.target as any).value, 'frequency');
            }}
          />,
        );
      } else {
        contentlist.push(
          <div
            className={styles.item}
            style={{
              width: widths[2],
              marginRight: marginRight,
              fontWeight: 500,
              fontSize: '16px',
            }}
            onClick={() => {
              const newRecordList = [...recordList];
              newRecordList[index].editFrequency = true;
              setRecordList(newRecordList);
            }}
          >
            {data.frequency}
          </div>,
        );
      }
    }
    if (repeatType === 1) {
      //步进值
      if (data?.isEditingCol) {
        contentlist.push(
          <span className={styles.item} style={{ width: widths[3], marginRight: marginRight }} />,
        );
      } else {
        if (data?.editRepeatStep) {
          contentlist.push(
            <InputNumber
              min={1}
              className={styles.item}
              style={{ width: widths[3], marginRight: marginRight }}
              autoFocus
              defaultValue={data.repeatStep}
              onBlur={(e) => {
                const newRecordList = [...recordList];
                newRecordList[index].editRepeatStep = false;
                setRecordList(newRecordList);
                handleEdit(data.key, e.target.value, 'repeatStep');
              }}
              onPressEnter={(e) => {
                const newRecordList = [...recordList];
                newRecordList[index].editRepeatStep = false;
                setRecordList(newRecordList);
                handleEdit(data.key, (e.target as any).value, 'repeatStep');
              }}
            />,
          );
        } else {
          contentlist.push(
            <div
              className={styles.item}
              style={{
                width: widths[3],
                marginRight: marginRight,
                fontWeight: 500,
                fontSize: '16px',
              }}
              onClick={() => {
                const newRecordList = [...recordList];
                newRecordList[index].editRepeatStep = true;
                setRecordList(newRecordList);
              }}
            >
              {data.repeatStep}
            </div>,
          );
        }
      }
      //计数属性
      if (data?.isEditingCol) {
        contentlist.push(
          <span className={styles.item} style={{ width: widths[4], marginRight: marginRight }} />,
        );
      } else {
        contentlist.push(
          <div
            className={styles.item}
            style={{
              width: widths[4],
              marginRight: marginRight,
              fontWeight: 500,
              fontSize: '16px',
            }}
          >
            {'+ ' + data.frequency * data.repeatStep}
          </div>,
        );
      }
    } else {
      startIndex = 3;
    }

    //配置用户选择的属性
    scanAllPropList?.map((itemIndex, index) => {
      if (data?.isEditingCol) {
        contentlist.push(
          <span
            className={styles.item}
            style={{ width: widths[startIndex + index], marginRight: marginRight }}
          />,
        );
      } else {
        contentlist.push(renderText(data, itemIndex, startIndex + index));
      }
    });
    //操作
    if (data?.isEditingCol) {
      contentlist.push(
        <span
          className={styles.item}
          style={{ width: widths[widths.length - 1], marginRight: marginRight }}
        />,
      );
    } else {
      contentlist.push(
        <div
          className={styles.item}
          style={{
            width: widths[widths.length - 1],
            marginRight: marginRight,
            color: '#1677FF',
            fontSize: '14px',
          }}
          onClick={() => handleRemoveRecord(index, data)}
        >
          删除
        </div>,
      );
    }
    return contentlist;
  })();

  return (
    <div
      className={styles.itemParent}
      style={{
        justifyContent: marginRight === 0 ? 'space-between' : 'flex-start',
        minWidth: marginRight === 0 ? '100%' : 'min-content',
      }}
    >
      {content}
    </div>
  );
};

export default ScanAddModalItem;
