import type { ResponseResult } from '@/utils/request';
import { delay } from '@/utils/utils';

interface IMakeProgressIterator {
  startFn: () => Promise<ResponseResult<{ status: 'ok'; total: number; async_id: string }>>;
  checkFn: (arg: {
    total: number;
    async_id: string;
  }) => Promise<ResponseResult<{ finished: number }>>;
}
export async function* makeProgressIterator(arg: IMakeProgressIterator) {
  try {
    const startFnRes = await arg.startFn();
    if (startFnRes.status === 'error') {
      return { status: 'error' } as const;
    }
    if (!startFnRes.data.total) return { status: 'error' } as const;

    yield { progress: 0 };

    while (true) {
      const checkFnRes = await arg.checkFn({
        total: startFnRes.data.total,
        async_id: startFnRes.data.async_id,
      });
      if (checkFnRes.status === 'ok') {
        if (checkFnRes.data.finished >= startFnRes.data.total) {
          return { progress: 1, result: checkFnRes };
        }
        yield { progress: checkFnRes.data.finished / startFnRes.data.total };
      }
      await delay(1200);
    }
  } catch (error) {
    return { status: 'error' } as const;
  }
}

export type IProgressIterator = ReturnType<typeof makeProgressIterator>;

/**
 * @todo unit test
 */
// const checkFnMocker = (init = 10) => {
//   let count = init;
//   return (a: any) => {
//     count--;
//     return Promise.resolve({
//       status: 'ok',
//       data: {
//         finished: init - count,
//       },
//     } as const);
//   };
// };
// const progressIterator = makeProgressIterator({
//   startFn: () => Promise.resolve({ status: 'ok', data: { total: 3, async_id: '123' } } as any),
//   checkFn: checkFnMocker(3),
// });

// async function test() {
//   while (true) {
//     const res = await progressIterator.next();
//     console.log(res.value);
//     if (res.done) {
//       break;
//     }
//   }
// }
