import type { ApiResponse } from '@linkpi/core';
import { DEFAULT_AVATAR } from '@linkpi/core';
import { getMatchingOriginProp } from '@linkpi/core';
import { v4 as uuid4 } from 'uuid';

/**
 * 生成随机uuid
 */
export const generateId = () => uuid4().replace(/-/g, '');

export const hexToRgba = (hex: string, opacity: number) => {
  const RGBA =
    'rgba(' +
    parseInt('0x' + hex.slice(1, 3)) +
    ',' +
    parseInt('0x' + hex.slice(3, 5)) +
    ',' +
    parseInt('0x' + hex.slice(5, 7)) +
    ',' +
    opacity +
    ')';
  return {
    red: parseInt('0x' + hex.slice(1, 3)),
    green: parseInt('0x' + hex.slice(3, 5)),
    blue: parseInt('0x' + hex.slice(5, 7)),
    rgba: RGBA,
  };
};

// 分割数组
export const splitArray = <U>(arr: U[], size: number): [U[], U[]] => {
  return [arr.slice(0, size), arr.slice(size)];
};

// 判断对象是否为空
export const isEmptyObject = (obj: any) => {
  const l = Object.keys(obj).length;
  return l === 0;
};

export const getUser = (id: string, userMap: Record<string, ApiResponse.OrgUser.OrgUserItem>) => {
  return (
    userMap[id] || {
      avatar: DEFAULT_AVATAR,
      nick_name: '未知用户',
    }
  );
};

// 统计数据加上 %
export const parseStatisticNumber = (
  key: string,
  config: any,
  templateMap: any,
  result: any,
  statisticsType: string,
) => {
  if (statisticsType === 'count') return result;
  if (key.slice(0, 4) === 'prop') {
    const originProp = getMatchingOriginProp(config, templateMap, []);
    if (originProp !== '-3' && originProp?.number && originProp.number.numericalFormat)
      return result * 100 + '%';

    return result;
  }

  return result;
};
