/**
 * 用于关闭 ViewContent 弹窗
 *
 * TODO
 *
 * 基于 nice-modal 管理内容页弹窗
 */

import type { FC, PropsWithChildren } from 'react';
import { createContext, createElement, useContext } from 'react';

const initialValue = {
  close: () => {},
};

const NodeContentModalContext = createContext(initialValue);

export const NodeContentModalProvider: FC<PropsWithChildren<{ value?: { close: () => void } }>> = ({
  children,
  value = initialValue,
}) => {
  return createElement(NodeContentModalContext.Provider, { value }, children);
};

export const useNodeContentModalClose = () => {
  const { close } = useContext(NodeContentModalContext);
  return { close };
};
