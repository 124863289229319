import { antdModalV5, create as ModalCreate, show, useModal } from '@ebay/nice-modal-react';
import { useControllableValue, useMemoizedFn } from 'ahooks';
import type { FC } from 'react';

import { SelectModal } from '@/components';
import { DepartmentSelect } from '@/pages/space/components/TaskStatus/components/StatusUserPropConfigSelect/DepartmentSelect';
import { TagList } from '@/pages/space/components/TaskStatus/components/StatusUserPropConfigSelect/TagList';

type DepartmentSelectModalProp = {
  title?: string;
  mode?: 'single' | 'multiple';
  value?: any;
  defaultValue?: any;
  filterIds?: string[];
  checkStrictly?: boolean;
  onChange?: (v: any) => void;
};
export const DepartmentSelectModal: FC<DepartmentSelectModalProp> = ({
  mode = 'multiple',
  checkStrictly,
  filterIds,
  ...rest
}) => {
  const modal = useModal();
  const [value, onChange] = useControllableValue(rest);

  const onOk = useMemoizedFn(() => {
    modal.resolve(value);
    modal.hide();
  });

  const onCancel = useMemoizedFn(() => {
    modal.reject();
    modal.hide();
  });

  return (
    <SelectModal
      {...antdModalV5(modal)}
      title="设置"
      width={860}
      pure
      {...rest}
      onOk={onOk}
      onCancel={onCancel}
      value={value}
      onChange={onChange}
      selectedCountRender={(list) => list.length + '个部门'}
      valueRender={(list) => <TagList value={list} onChange={onChange} layout="list" />}
    >
      <div style={{ padding: '0 8px', paddingTop: 8, height: '100%', overflow: 'auto' }}>
        <DepartmentSelect
          allowSearch
          allowSelectAll
          checkStrictly={checkStrictly}
          mode={mode}
          value={value}
          filterIds={filterIds}
          onChange={onChange}
        />
      </div>
    </SelectModal>
  );
};

const _DepartmentSelectModal = ModalCreate(DepartmentSelectModal);

export const showDepartmentSelectModal = (
  props: DepartmentSelectModalProp,
): Promise<`group_${string}`[] | `group_${string}`> => {
  return show(_DepartmentSelectModal, props);
};
