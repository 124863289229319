import type { ApiResponse } from '@linkpi/core';
import { getQuoteOriginProp } from '@linkpi/core';
import type { GetterPiNode } from '@linkpi/core/web';

import { displayGroupName } from '@/utils/utils';

export const convertGroupByToGetTempFilterNodeGroupBy = (groupBy: string) => {
  /**
   * dayHours 日历视图分组??数据应该怎么输出
   */
  if (groupBy === 'sysCreator') {
    return 'createUser';
  } else if (groupBy === '_sys_tag') {
    return 't';
  } else if (groupBy === 'templateStatus') {
    return 'si';
  } else if (groupBy.startsWith('templateProp_')) {
    const [_, tempId, index, userGroupType] = groupBy.split('_');
    return `p${Number(userGroupType) === 1 ? 'g' : ''}${index}`;
  } else if (groupBy.startsWith('templateStatusPropBase_')) {
    const [_, index, userGroupType] = groupBy.split('_');
    return `s${Number(userGroupType) === 1 ? 'g' : ''}${index}`;
  } else if (groupBy.startsWith('templateStatusOwnerAndParticipant')) {
    const [_, userGroupType] = groupBy.split('_');
    return `so${Number(userGroupType) === 1 ? 'g' : ''}`;
  } else {
    return groupBy;
  }
};

// filterNodes 添加分组信息
export const convertTempFilterNodesToGroupData = (props: {
  filterNodes: Record<string, string[]>;
  userMap: Record<string, ApiResponse.OrgUser.OrgUserItem>;
  groups: any;
  orderBy?: string;
  orderDesc: boolean;
  node: GetterPiNode;
  groupBy?: string;
  groupOrderDesc?: ApiResponse.ViewList.ViewInfo['groupOrderDesc'];
  template?: ApiResponse.CurrentUser.TemplateInfo;
  templateMap: Record<string, ApiResponse.CurrentUser.TemplateInfo>;
}) => {
  const {
    filterNodes,
    userMap,
    groupBy,
    orderBy = '',
    orderDesc,
    groups,
    node,
    template,
    groupOrderDesc = false,
    templateMap,
  } = props;

  const groupKeys = Object.keys(filterNodes);
  // default
  if (groupKeys.length === 1 && groupKeys[0] === 'default') {
    return {
      default: {
        name: '',
        info: {},
        // list: filterNodes.default.map((x) => node.value.nodeManager.findChildren(x)),
        list: node.value.nodeManager.sortNode(filterNodes.default, orderBy, orderDesc),
      },
    };
  }

  // 分组
  function genUserGroupMap(groupType: string) {
    const gm: any = {};
    if (groupType === '0') {
      Object.keys(userMap).map((account_id: any) => {
        gm[account_id] = {
          name: account_id,
          list: [],
          nameType: 'user',
        };
      });
    } else {
      groups?.map((g: any) => {
        gm[g.group_id] = {
          name: g.group_id,
          list: [],
          nameType: 'group',
        };
      });
    }
    gm.default = {
      name: '未定义',
      list: [],
      nameType: groupType === '0' ? 'user' : 'group',
    };
    return gm;
  }

  function genUserGroupData(groupType: string) {
    const groupData = genUserGroupMap(groupType);
    Object.keys(groupData).map((userIdOrGroupId) => {
      const filterNodesKey = userIdOrGroupId === 'default' ? '(未定义)' : userIdOrGroupId;

      if (filterNodes[filterNodesKey]) {
        groupData[userIdOrGroupId].list = filterNodes[filterNodesKey];
      }
    });
    return groupData;
  }

  let res: any = {};
  // 负责人或参与者
  if (groupBy?.startsWith('templateStatusOwnerAndParticipant')) {
    const groupType = groupBy.split('_')[1] || '0';
    res = genUserGroupData(groupType);
  } else if (groupBy === 'templateStatus') {
    const statusList = template?.task_status || [];
    res = statusList.reduce((a, b, index) => {
      const key = `${template?.template_id}_${index}`;
      const name = b.name;
      a[key] = {
        name: b.name,
        list: filterNodes[name] ? filterNodes[name] : [],
        info: {
          tempId: template?.template_id,
          status: index,
        },
        sort: b.sort ?? index,
      };
      return a;
    }, {} as any);
  } else if (groupBy === 'sysCreator') {
    res = genUserGroupData('0');
  } else if (groupBy === '_sys_tag') {
    res = groupKeys.reduce((a, b) => {
      const key = b === '(未定义)' ? 'default' : b;
      a[key] = {
        name: b,
        list: filterNodes[b],
        nameType: 'tag',
      };
      return a;
    }, {} as any);
  } else if (groupBy?.startsWith('templateStatusPropBase_')) {
    // 状态属性 （负责人、参与者）
    const groupByInfo = groupBy.split('_');
    const statusPropIndex = Number(groupByInfo[1]);
    const propType = groupByInfo[2] || '0';
    if (statusPropIndex === 0 || statusPropIndex === 1) {
      res = genUserGroupData(propType);
    }
  } else if (groupBy?.startsWith('templateProp_')) {
    const [mark, tempId, index, type] = groupBy.split('_');
    const propIndex = Number(index);
    const propType = type || '0';
    const propInfo = template?.prop?.[propIndex];

    const getNameType = () => {
      if (propInfo?.type !== 'quote') return propInfo?.type;

      const origin = getQuoteOriginProp(propInfo, templateMap);
      if (typeof origin !== 'string' && origin?.type === 'user') return 'user';

      return propInfo?.type;
    };

    if (propInfo?.type === 'user') {
      res = genUserGroupData(propType);
    } else {
      res = groupKeys.reduce((a, b) => {
        const key = b === '(未定义)' ? 'default' : b;
        a[key] = {
          name: b,
          list: filterNodes[b],
          nameType: getNameType(),
          info: {
            tempId,
            propIndex,
            prop: template?.prop[propIndex],
          },
        };
        return a;
      }, {} as any);
    }
  }

  if (!Object.keys(res).length && Object.keys(filterNodes).length) {
    console.error('convertTempFilterNodesToGroupData 未处理的groupBy', groupBy);
    return {};
  }

  Object.keys(res).map((key) => {
    if (!res[key].list.length) {
      delete res[key];
    }
  });
  return Object.keys(res)
    .sort((a, b) => {
      if ('sort' in res[a]) return res[a].sort - res[b].sort;
      const groupNameA = String(displayGroupName(res[a], userMap, groups)),
        groupNameB = String(displayGroupName(res[b], userMap, groups));

      if (groupNameA === '未定义') return -1;
      if (groupNameB === '未定义') return 1;
      if (groupNameA === '未知成员') return -1;
      if (groupNameB === '未知成员') return 1;

      if (res[a].nameType === 'number')
        return (
          (Number(res[a].name) - Number(res[b].name)) *
          // 根据分组配置排序
          (groupOrderDesc ? -1 : 1)
        );

      return (
        groupNameA.localeCompare(groupNameB) *
        // 根据分组配置排序
        (groupOrderDesc ? -1 : 1)
      );
    })
    .reduce((map, groupKey, index) => {
      map[groupKey] = {
        ...res[groupKey],
        // list: res[groupKey].list.map((x: any) => node.value.nodeManager.findChildren(x)),
        list: node.value.nodeManager.sortNode(res[groupKey].list, orderBy, orderDesc),
        sort: index,
      };
      return map;
    }, {} as any);
};
