import type { ButtonProps, PopconfirmProps } from 'antd';
import { Button, Popconfirm } from 'antd';
import type { FunctionComponent } from 'react';

type Props = ButtonProps & Omit<PopconfirmProps, 'title'>;
const ConfirmButton: FunctionComponent<React.PropsWithChildren<Props>> = ({
  children,
  ...props
}) => {
  return (
    <Popconfirm {...props} title={<span>确认{children}</span>}>
      <Button {...props}>{children}</Button>
    </Popconfirm>
  );
};

export default ConfirmButton;
