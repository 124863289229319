import type { ApiResponse, PageModelConfig, ViewList } from '@linkpi/core';
import { message } from 'antd';

import request from '@/utils/request';

type BaseViewConfig = {
  view_name?: string;
  public?: boolean;
  public_groups?: string[];
  modify_permission?: number;
  org_id?: string;
  view_id?: string;
  template_id?: string;
  charts_info?: ViewList.ViewChartsInfo;
  /**
   * 普通视图
   * ViewList.ViewInfo
   * 大模型视图
   * PageModelConfig
   */
  view_info?: Partial<ViewList.ViewInfo> | PageModelConfig;
  view_type?: ViewList.ViewType;
  // 被仪表盘或者大模型创建的视图
  dash_view_id?: string;
  header_info?: Partial<ViewList.HeaderInfo>;
};

type UpdateViewConfig = BaseViewConfig & {
  view_id: string;
};
export const updateViewConfig = (data: UpdateViewConfig) =>
  request('/api/view/update', {
    method: 'POST',
    data,
  }).then((res) => {
    if (res.status === 'error') {
      message.error(res.message || '更新失败');
      return res;
    }
    return res;
  });

type CreateViewConfig = BaseViewConfig & {
  node_id: string;
  view_type: ViewList.ViewType;
};
export const createView = (data: CreateViewConfig) =>
  request<string>('/api/view/create', {
    method: 'POST',
    data,
  });

type DeleteViewConfig = {
  view_id: string;
};
export const deleteView = (data: DeleteViewConfig) =>
  request<string>('/api/view/delete', {
    method: 'POST',
    data,
  });

type CopyViewConfig = {
  org_id: string;
  copy_id: string;
  node_id: string;
};
export const copyView = (data: CopyViewConfig) =>
  request<unknown>('/api/view/copy', {
    method: 'POST',
    data,
  });

type GetViewListConfig = {
  org_id?: string;
  node_id?: string;
  view_id?: string[];
};
export const getViewList = (data: GetViewListConfig) =>
  request<ApiResponse.ViewList>('/api/view/get', {
    method: 'POST',
    data,
  }).then((res) => {
    if (res.status === 'ok') return res.data;

    return Promise.reject(res);
  });

export const getViewListByManager = (data: { org_id: string }) =>
  request<ApiResponse.ViewList>('/api/view/getAllPublic', {
    method: 'POST',
    data,
  }).then((res) => {
    if (res.status === 'ok') return res.data;

    return Promise.reject(res);
  });
