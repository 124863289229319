import type { PiGanttModules } from '../core';
import { hexToRgba } from '../helper/utils';
import type { nodeRowData } from '../modules/nodeRowManager';
import type { positionType } from './_baseComponent';
import BaseComponent from './_baseComponent';

const CELL_HEIGHT = 12;

export default class GroupRow extends BaseComponent {
  public data: nodeRowData<'group'>;

  constructor(position: positionType, moduleInstances: PiGanttModules, data: nodeRowData<'group'>) {
    super(position, moduleInstances);
    this.data = data;
  }

  public onClick() {
    const { NodeRowManager, Render } = this.moduleInstances;
    NodeRowManager.onCollapse(this.data.groupKey);
    Render.render();
  }

  public render() {
    const { Draw, Render, NodeRowManager, DateColumnManager } = this.moduleInstances;
    const { groupInfo } = this.data;
    Draw.save();

    // render scope
    if (this.data.groupDetail) {
      const { minDate, maxDate } = this.data.groupDetail;
      const startOffset = DateColumnManager.getDateOffset(minDate);
      const startX = DateColumnManager.offsetToX(startOffset);
      const dayCount = Math.max(
        1,
        maxDate
          .startOf(DateColumnManager.columnUnit)
          .diff(minDate.startOf(DateColumnManager.columnUnit), DateColumnManager.columnUnit) + 1,
      );

      const position = {
        x: startX - DateColumnManager.columnWidth / 2,
        y: this.position.y + (this.position.height - CELL_HEIGHT) / 2,
        width: dayCount * DateColumnManager.columnWidth,
        height: CELL_HEIGHT,
      };

      const cell = new GroupCell(position, this.moduleInstances, this);
      cell.render();
    }

    let bgFillColor = Draw.style.backgroundColor;
    const paddingLeft = 20;
    const paddingRight = 20;

    // render cell
    Draw.fillRect(
      0,
      this.position.y + 1,
      Render.leftPanelStyle.width - 1,
      this.position.height,
      bgFillColor,
    );

    if (this.isHover) {
      bgFillColor = Draw.style.node.hoverBackgroundColor;
      Render.setCursor('pointer');
      // bg
      Draw.fillRect(0, this.position.y + 1, this.position.width, this.position.height, bgFillColor);
    }

    // bottom line
    Draw.line(
      [
        [0, this.position.y + this.position.height],
        [Render.leftPanelStyle.width, this.position.y + this.position.height],
      ],
      Draw.style.borderColor,
    );

    // arrow
    // width 8 heigh 6
    const direction = NodeRowManager.collapseState[this.data.groupKey] ? 'bottom' : 'top';
    Draw.fillTriangle(
      {
        width: 8,
        height: 6,
        x: paddingLeft,
        y: this.position.y + (this.position.height - 6) / 2,
      },
      Draw.style.node.groupArrowColor,
      direction,
    );

    Draw.attr({ fillStyle: Draw.style.node.groupNumColor });
    Draw.ctx.textBaseline = 'middle';
    Draw.ctx.textAlign = 'left';
    Draw.ctx.font = `${Draw.npx(13)}px  Arial`;

    const count = `（${this.data.groupInfo.list.length}）`;
    const countWidth = Draw.measureTextWidth(count);
    Draw.fillText(
      count,
      Render.leftPanelStyle.width - paddingRight - countWidth,
      this.position.y + this.position.height / 2,
    );

    // title
    Draw.attr({ fillStyle: Draw.style.node.groupColor });
    Draw.ctx.textBaseline = 'middle';
    Draw.ctx.textAlign = 'left';
    Draw.ctx.font = `bold ${Draw.npx(15)}px Arial`;

    const titleWidth =
      Render.leftPanelStyle.width - paddingLeft - paddingRight - 8 - paddingRight - countWidth;
    const title = NodeRowManager.config.getGroupName(this.data.groupInfo);

    const realTitleWidth = Draw.measureTextWidth(title);
    let extendColorMode = false;
    if (groupInfo.nameType === 'enum' && groupInfo.info?.prop) {
      const { extendColorMode: _extendColorMode, extendColor, extend } = groupInfo.info.prop;
      extendColorMode = _extendColorMode;
      const index = extend.findIndex((e: string) => e === groupInfo.name);
      if (extendColorMode) {
        Draw.fillRoundRect(
          {
            x: paddingLeft + 10 + 10 - 5,
            y: this.position.y + this.position.height / 2 - Draw.npx(15) / 2 - 3,
            width: realTitleWidth + 14,
            height: 20,
          },
          8,
          extendColor[index],
        );
      }
    }

    Draw.fillText(
      Draw.fittingString(title, titleWidth),
      paddingLeft + 10 + 10,
      this.position.y + this.position.height / 2,
      titleWidth,
    );

    Draw.restore();
  }
}

class GroupCell extends BaseComponent {
  private groupRow: GroupRow;
  constructor(position: positionType, moduleInstances: PiGanttModules, groupRow: GroupRow) {
    super(position, moduleInstances);
    this.groupRow = groupRow;
  }

  public onClick() {
    const { NodeRowManager, Render } = this.moduleInstances;
    NodeRowManager.onCollapse(this.groupRow.data.groupKey);
    Render.render();
  }

  public render() {
    if (!this.groupRow.data.groupDetail) return;

    const { minDate, maxDate } = this.groupRow.data.groupDetail;
    const { Draw, Render } = this.moduleInstances;
    const primaryColor = '#316ef5';
    Draw.fillRoundRect(this.position, 6, hexToRgba(primaryColor, 0.5).rgba);
    Draw.strokeRoundRect(this.position, 6, 2, primaryColor);

    if (this.isHover) {
      Render.setCursor('pointer');
      Render.setRenderAfterAction(() => {
        Draw.save();
        Draw.attr({ fillStyle: '#fff' });
        Draw.ctx.textBaseline = 'top';
        Draw.ctx.textAlign = 'left';
        Draw.ctx.font = `${Draw.npx(13)}px  sans-serif`;

        const text = `共${this.groupRow.data.groupInfo.list.length}个任务，${minDate.format(
          'YYYY/MM/DD',
        )} - ${maxDate.format('YYYY/MM/DD')}`;
        const displayInfo = Draw.ctx.measureText(text);

        const padding = 4 * window.devicePixelRatio;
        const width = displayInfo.width / window.devicePixelRatio + padding * 2;
        const height =
          (displayInfo.actualBoundingBoxAscent + displayInfo.actualBoundingBoxDescent) /
            window.devicePixelRatio +
          padding * 2;
        let x = window.__PI__GANTT.mousePosition.x - width / 2;
        let y = this.position.y + this.position.height + padding;

        // TODO: use Draw
        const canvas = this.moduleInstances.Render.doms.container;
        // Check for overflow
        if (x + width > canvas.clientWidth) {
          x = canvas.clientWidth - width;
        }

        if (y + height > canvas.clientHeight) {
          y = canvas.clientHeight - height * 2;
        }

        Draw.fillRoundRect(
          {
            x,
            y,
            width,
            height,
          },
          5,
          '#4a4b4f',
        );
        Draw.fillText(text, x + padding, y + padding + displayInfo.actualBoundingBoxAscent);

        Draw.restore();
      });
    }
  }
}
