import { NODE_TYPE } from '@linkpi/core';
import { getVirtualNodeOriginId } from '@linkpi/core/web';
import { assertExists } from '@linkpi/utils';
import { useDispatch } from '@umijs/max';
import { useMemoizedFn } from 'ahooks';
import { atom, useAtom } from 'jotai';

import { useCurrentSelection, useOrgConnection } from '@/hook';

export interface ITab {
  id: string;
  title: string;
}

export const NodeTabList = atom<ITab[]>([]);

export const useJumpNode = () => {
  const dispatch = useDispatch();
  const orgConnection = useOrgConnection();
  const currentSelection = useCurrentSelection();

  const jump = (nodeId: string, viewId?: string) => {
    assertExists(orgConnection);
    const data = orgConnection.nodeManager.getNode(nodeId);
    if (window.location.host !== 'mylinkpi.com') console.log(data);

    // 收集箱无法点击
    if (data.nodeType === NODE_TYPE.CLEARING) {
      return;
    }

    // 跳过当前
    if (data.key === currentSelection.selectNode) {
      return;
    }

    // 虚拟节点点击
    // 如果当前节点是同源的虚拟节点（同一个父级，或者就是父级），并且不是Content视图，切换到切换到当前视图
    // 否则 切换到虚拟节点源节点的第一个视图
    const selection: any = {
      selectNode: data.key,
    };
    if (
      data.nodeType === NODE_TYPE.FILTERNODE_ROOT ||
      data.nodeType === NODE_TYPE.FILTERNODE_PATH
    ) {
      const [originSelectNodeId] = getVirtualNodeOriginId(
        orgConnection.nodeManager,
        currentSelection.selectNode!,
      );
      const [originId] = getVirtualNodeOriginId(orgConnection.nodeManager, data.key);

      if (originSelectNodeId === originId && currentSelection.selectViewId !== 'content') {
        // 当前视图
        selection.selectViewId = currentSelection.selectViewId;
      } else {
        // TODO 源节点第一个视图
        // 获取源节点的视图列表
      }
    }

    if (viewId) {
      selection.selectViewId = viewId;
    }

    dispatch({
      type: 'workspace/setCurrentSelection',
      payload: selection,
    });
  };

  return {
    jump,
  };
};

export const useCloseCurrentNodeTab = () => {
  const [tabList, setTabList] = useAtom(NodeTabList);
  const { selectNode } = useCurrentSelection();
  const { jump } = useJumpNode();

  const close = useMemoizedFn(() => {
    if (tabList.length <= 1) {
      return;
    }

    const index = tabList.findIndex((t) => t.id === selectNode);
    if (index === 0) {
      jump(tabList[index + 1].id);
    } else {
      jump(tabList[index - 1].id);
    }

    setTabList((_tabList) => {
      return _tabList.filter((t) => t.id !== selectNode);
    });
  });

  return { close };
};
