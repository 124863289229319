import type { DatePickerProps } from 'antd';
import { DatePicker } from 'antd';
import type { Moment } from 'moment';
import moment from 'moment';
import type { FC } from 'react';
import { useEffect, useState } from 'react';

export type CustomDateProps = DatePickerProps & {
  type: 'end' | 'start';
  isDate: boolean;
};

const CustomDate: FC<CustomDateProps> = (props) => {
  const { onChange, value, type } = props;

  // defaultTime
  let defaultTime = value || null;
  if (type === 'start') {
    defaultTime = value || moment();
  }

  useEffect(() => {
    setTime(value);
  }, [value]);

  // defaultHM
  const defaultHM = type === 'end' ? '18:00' : '08:00';

  const [time, setTime] = useState<any>(defaultTime);

  const clickCell = (current: any) => {
    const isToday = current.isSame(moment(), 'day');
    const date = current.format('YYYY-MM-DD');

    setTime(isToday ? moment() : moment(`${date} ${defaultHM}`, 'YYYY-MM-DD HH:mm'));
  };

  const renderCell = (current: any) => (
    <div className="ant-picker-cell-inner" onClick={() => clickCell(current)}>
      {current.date()}
    </div>
  );

  const changeTime = (v: Moment) => {
    setTime(v);
    onChange?.(v, v.toLocaleString());
  };

  const openChange = (v: boolean) => {
    if (v === false) setTime(value || null);
  };

  if (props.isDate) return <DatePicker {...props} />;

  return (
    <DatePicker
      format="YYYY-MM-DD HH:mm"
      {...props}
      value={time}
      dateRender={renderCell}
      onOk={changeTime}
      onOpenChange={openChange}
      showTime
    />
  );
};

export default CustomDate;
