import type { ProFormSelectProps } from '@ant-design/pro-form';
import { ProForm, ProFormSelect } from '@ant-design/pro-form';
import type { ApiResponse } from '@linkpi/core';
import { getQuoteOriginPropInfo } from '@linkpi/core';
import type { SelectProps } from 'antd';
import { Typography } from 'antd';
import type { DefaultOptionType } from 'antd/lib/select';
import { isNil, prop, sortBy } from 'ramda';
import type { ReactElement } from 'react';
import { memo, useCallback, useMemo } from 'react';

import { useOrgTemplatesSettingInfoMap } from '@/hook';

import { selectSearchFn } from '../utils';

const { Paragraph } = Typography;

const STATUS_ENUM = { value: 'status', label: '状态' };
const CREATOR_ENUM = { value: 'creator', label: '创建者' };

const getRealPropConfig = (...args: Parameters<typeof getQuoteOriginPropInfo>) => {
  const [p] = args;
  if ((p as ApiResponse.CurrentUser.TemplateProp).type !== 'quote') return p;

  return getQuoteOriginPropInfo(...args);
};

export type IOption = { label: ReactElement | string; value: string };

export type TemplatePropSelectProps = {
  templateName?: ProFormSelectProps['name'];
  templateId?: string;
  templateInfo?: TemplateInfo;
  placeholder?: SelectProps['placeholder'];
  allowStatus?: boolean;
  allowCreator?: boolean;
  genValue?: (p: TemplateProp, id: number) => string | number;
  /**
   * @description 优先级高于 disallowPropTypes
   */
  allowPropTypes?: ApiResponse.CurrentUser.propType[];
  /**
   * @description 优先级低于 allowPropTypes
   */
  disallowPropTypes?: ApiResponse.CurrentUser.propType[];
  extendOptions?: IOption[];
  /**
   * 生成有分组的选项
   */
  genGroupOptions?: (options: DefaultOptionType[]) => {
    label: ReactElement | string;
    options: TemplatePropSelectProps['extendOptions'];
  }[];
};

const defaultGenVal: TemplatePropSelectProps['genValue'] = (p, index) => `p${index}`;

const EMPTY_ARRAY: any[] = [];

export const useTemplatePropsOption = ({
  allowCreator,
  allowStatus,
  allowPropTypes,
  disallowPropTypes,
  templateId,
  templateInfo,
  extendOptions = EMPTY_ARRAY,
  genValue,
}: Pick<
  TemplatePropSelectProps,
  | 'allowCreator'
  | 'allowPropTypes'
  | 'allowStatus'
  | 'extendOptions'
  | 'disallowPropTypes'
  | 'templateId'
  | 'templateInfo'
  | 'genValue'
>) => {
  const tempMap = useOrgTemplatesSettingInfoMap();
  const result = useMemo(() => {
    const defaultOptions: IOption[] = [...extendOptions];

    if (allowStatus) defaultOptions.push(STATUS_ENUM);
    if (allowCreator) defaultOptions.push(CREATOR_ENUM);

    const temp = templateInfo || tempMap[templateId!];

    if (isNil(temp)) return [];
    if (!Array.isArray(temp.prop)) return defaultOptions;

    const checkPropType = (
      originType: ApiResponse.CurrentUser.propType,
      realType: ApiResponse.CurrentUser.propType,
    ) => {
      // 白名单
      if (Array.isArray(allowPropTypes)) {
        const typeMap = allowPropTypes.reduce((r, t) => ({ ...r, [t]: true }), {}) as any;
        return typeMap[realType] as boolean;
      }

      // 黑名单
      if (Array.isArray(disallowPropTypes)) {
        const typeMap = disallowPropTypes.reduce((r, t) => ({ ...r, [t]: true }), {}) as any;
        return !typeMap[originType] as boolean;
      }

      return true;
    };

    const tempProps = temp.prop
      .map((p, i) => ({ ...p, propIndex: i }))
      .filter((p) => {
        const propConfig = getRealPropConfig(p, tempMap) as ApiResponse.CurrentUser.TemplateProp;

        return p.name && checkPropType(p.type, propConfig?.type);
      });

    return sortBy(prop('sort'))(tempProps).reduce(
      (r, p) =>
        [
          ...r,
          {
            value: (genValue || defaultGenVal)!(p, p.propIndex),
            label: p.name,
          },
        ] as IOption[],
      defaultOptions,
    );
  }, [
    extendOptions,
    allowStatus,
    allowCreator,
    templateInfo,
    tempMap,
    templateId,
    allowPropTypes,
    disallowPropTypes,
    genValue,
  ]);
  return result;
};

function _TemplatePropSelect<T>(props: ProFormSelectProps<T> & TemplatePropSelectProps) {
  const {
    allowPropTypes,
    disallowPropTypes,
    allowStatus,
    allowCreator,
    templateInfo,
    extendOptions,
    genValue = defaultGenVal,
    genGroupOptions,
  } = props;
  // FIXME: formTempId 会冗余触发
  const formTempId = ProForm.useWatch(props.templateName);
  const tempId = props.templateId || formTempId;

  const tempMap = useOrgTemplatesSettingInfoMap();
  const temp = templateInfo || tempMap[tempId];

  const _tempPropOptions = useTemplatePropsOption({
    allowStatus,
    allowCreator,
    templateInfo: temp,
    allowPropTypes,
    disallowPropTypes,
    extendOptions,
    genValue,
  });

  /**
   * add group
   */
  const tempPropOptions = useMemo(() => {
    if (typeof genGroupOptions === 'function') {
      const result = genGroupOptions(_tempPropOptions);
      if (!Array.isArray(result)) return _tempPropOptions;
      return result;
    }
    return _tempPropOptions;
  }, [_tempPropOptions, genGroupOptions]);

  const dropdownRender = useCallback(
    (menu: ReactElement) => {
      const name = temp?.name || '未知模板';

      return (
        <>
          <Paragraph type="secondary" style={{ padding: '6px 12px', margin: 0 }}>
            {name}
          </Paragraph>
          {menu}
        </>
      );
    },
    [temp?.name],
  );

  return (
    <ProFormSelect
      {...props}
      fieldProps={{
        ...props.fieldProps,
        placeholder: tempPropOptions?.length ? props.placeholder || '请选择属性' : '暂无属性',
        dropdownRender,
        filterOption: selectSearchFn,
        showSearch: true,
      }}
      options={tempPropOptions}
    />
  );
}

export const TemplatePropSelect = memo(_TemplatePropSelect);
