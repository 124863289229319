import type { ApiResponse } from '@linkpi/core';
import {
  addCascadeNode,
  displayCascadeValue,
  genCascadeKeyMap,
  genCascadeRouteTitleMap,
} from '@linkpi/core';
import { useDispatch } from '@umijs/max';
import { useControllableValue } from 'ahooks';
import { Cascader } from 'antd';
import type { DefaultOptionType } from 'antd/es/cascader';
import { cloneDeep } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
const { SHOW_CHILD, SHOW_PARENT } = Cascader;
type NodesMap = Record<string, ApiResponse.CurrentUser.cascadeNode>;
const CascadeSelect = (props: {
  node?: PiNode;
  prop: ApiResponse.CurrentUser.TemplateProp;
  defaultValue?: string[];
  value?: string[];
  multiple: boolean;
  handleChange: (newValue: string[], newTitle: string[]) => void;
  onBlur?: Function;
  changeOnSelect: boolean;
  bordered?: boolean;
  placeholder?: string;
  template: ApiResponse.CurrentUser.TemplateInfo | undefined;
  propIndex: number;
  orgId: string;
  autoFocus?: boolean;
  allowCreate?: boolean;
  onPressEnter?: Function;
  disabled?: boolean;
}) => {
  const {
    node,
    prop,
    multiple,
    handleChange,
    onBlur,
    changeOnSelect,
    bordered = true,
    placeholder = '搜索或添加，可用“/”区分层级，如：浙江省/杭州',
    template,
    propIndex,
    orgId,
    autoFocus = false,
    allowCreate = false,
    onPressEnter,
    disabled,
  } = props;

  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState('');
  const [cascade, setCascade] = useState<Partial<ApiResponse.CurrentUser.cascadeSetting>>({
    nodes: [],
    titles: [],
  });
  /**
   * nodesmap在cascade里有的。但是看逻辑，是要当作cascade里没有nodesMap
   * 可能是哪里修改了prop.cascade的引用数据
   */
  const [nodesMap, setNodesMap] = useState<NodesMap>({});
  const [cascadeValue, setCascadeValue] = useControllableValue(
    { ...props, onChange: handleChange },
    {},
  );
  const [routeTitlesMap, setRouteTitlesMap] = useState<NodesMap>({});
  const [hasAdding, setHasAdding] = useState(false);

  useEffect(() => {
    if (prop.type === 'cascade' && prop.cascade) {
      setCascade(prop.cascade);
      setNodesMap(genCascadeKeyMap(prop.cascade.nodes));
      setRouteTitlesMap(genCascadeRouteTitleMap(prop));
    } else {
      setCascade({
        nodes: [],
        titles: [],
      });
      setNodesMap({});
      setRouteTitlesMap({});
    }
    setHasAdding(false);
  }, [prop]);

  const genRouteKey = (key: string) => {
    const routes: string[] = [];
    function genParentKey(ck: string) {
      const parent = nodesMap[ck];
      if (parent.parentKey) {
        genParentKey(parent.parentKey);
      }
      routes.push(ck);
    }
    genParentKey(key);
    return routes;
  };

  const cascadeSelect = useMemo(() => {
    let cs: Array<any> = [];
    if (Array.isArray(cascadeValue) && cascadeValue.length) {
      if (multiple) {
        cascadeValue.forEach((v) => {
          if (nodesMap[v]) {
            cs.push(genRouteKey(v));
          }
        });
      } else {
        const first = cascadeValue.find((v: string) => nodesMap[v]);
        if (first) cs = genRouteKey(first);
      }
    }
    return cs;
  }, [cascadeValue, nodesMap]);

  const onChange = (e: Array<any>, nMap: NodesMap = nodesMap) => {
    if (!template) return;
    if (!e || !e.length) {
      setCascadeValue([], []);
      // handleChange([], []);
      return;
    }
    let value = JSON.parse(JSON.stringify(e));
    if (!multiple) {
      value = [value];
    }
    const cValue = cascadeKeysConvertRouteKeys(value, nMap);

    const titles = displayCascadeValue({
      value: cValue,
      cascadeMap: nMap,
      multiple: prop.multiple,
      hideRoutes: prop.hideRoutes,
    });

    setCascadeValue(cValue, titles);
    // handleChange(cValue, titles);
  };

  const displayRender = (labels: string[], selectedOptions?: DefaultOptionType[]) =>
    labels.map((label, i) => {
      const option = selectedOptions?.[i];
      if (i === labels.length - 1) {
        return <span key={option?.key}>{label}</span>;
      }
      return <span key={option?.key}>{label} / </span>;
    });
  const filter = (inputValue: string, path: DefaultOptionType[]) =>
    path.some(
      (option) => (option.title as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1,
    );
  const dropdownRender = (menus: React.ReactNode) => (
    <div>
      {menus}
      {searchInput &&
      allowCreate &&
      !searchInput.endsWith('/') &&
      !Object.keys(routeTitlesMap).includes(searchInput) ? (
        <div style={{ padding: 8, color: '#BFC6D2' }}>按回车创建"{searchInput}"</div>
      ) : null}
    </div>
  );

  // 按回车创建
  const handleInputKeyDown = async (v: any) => {
    const { nativeEvent } = v;
    const { isComposing } = nativeEvent;

    if (v.keyCode === 13 && !isComposing) {
      if (onPressEnter) return onPressEnter(cascadeValue);
    }

    if (isComposing) return;
    if (v.key !== 'Enter') return;
    if (!searchInput) return;
    if (!allowCreate) return;
    const [newCascade, addKey, newNodesMap, newRouteTitlesMap] = await addCascadeNode(
      {
        cascade: cascade,
      },
      searchInput,
      nodesMap,
      routeTitlesMap,
    );
    if (addKey) {
      setHasAdding(true);
      setCascade(newCascade);
      setNodesMap(newNodesMap);
      setRouteTitlesMap(newRouteTitlesMap);
      onChange(multiple ? [...cascadeSelect, [addKey]] : [addKey], newNodesMap);
      setSearchInput('');
    }
  };

  const handleBlur = () => {
    if (hasAdding && template && orgId) {
      dispatch({
        type: 'space/editTemplateProp',
        payload: {
          org_id: orgId,
          template_id: template.template_id,
          prop: JSON.stringify([
            { ...template.prop[propIndex], index: propIndex, cascade: cascade },
          ]),
        },
      });
    }
    onBlur && onBlur(cascadeValue);
  };

  const filteredNodes = useMemo(() => {
    const filterNodeByLevel = (
      cascadeNodes: ApiResponse.CurrentUser.cascadeNode[],
      levelIndex = 0,
    ): ApiResponse.CurrentUser.cascadeNode[] => {
      return cascadeNodes.reduce<ApiResponse.CurrentUser.cascadeNode[]>((prev, cNodes) => {
        if (cascade.filters && cascade.filters[levelIndex]) {
          const [, pIndex] = (cascade.filters[levelIndex] as string).split('p');
          if (cNodes.title !== node?.tempInfo?.prop?.[+pIndex]) {
            return prev;
          }
        }
        let newChildren = cNodes.children;
        if (cNodes.children) {
          newChildren = filterNodeByLevel(cNodes.children, levelIndex + 1);
        }
        if (cNodes.children?.length && !newChildren?.length) {
          return prev;
        }
        return [...prev, cNodes.children ? { ...cNodes, children: newChildren } : cNodes];
      }, []);
    };

    return cascade.filters?.length && cascade.nodes
      ? filterNodeByLevel(cloneDeep(cascade.nodes)).filter((n) => n)
      : cascade.nodes;
  }, [cascade.filters, cascade.nodes, node?.tempInfo?.prop]);

  return (
    <Cascader
      disabled={disabled}
      autoFocus={autoFocus}
      showAction={['focus', 'click']} // from rc-select
      placeholder={placeholder}
      expandTrigger="hover"
      bordered={bordered}
      style={{ width: '100%' }}
      options={filteredNodes}
      onChange={(e: any) => onChange(e, nodesMap)}
      multiple={multiple}
      maxTagCount="responsive"
      displayRender={displayRender}
      value={cascadeSelect}
      showCheckedStrategy={changeOnSelect ? SHOW_PARENT : SHOW_CHILD}
      onBlur={handleBlur}
      changeOnSelect={changeOnSelect}
      // getPopupContainer={(triggerNode) => triggerNode.parentNode.parentNode}
      showSearch={{ filter }}
      dropdownRender={dropdownRender}
      onSearch={(value) => setSearchInput(value)}
      searchValue={searchInput}
      onInputKeyDown={handleInputKeyDown}
      fieldNames={{
        label: 'title',
        value: 'key',
        children: 'children',
      }}
    />
  );
};

export default CascadeSelect;

/**
 * key 转成routeKey+叶子节点的key
 */
export const cascadeKeysConvertRouteKeys = (value: string[][], nMap: any) => {
  const cValue: string[] = [];
  for (let i = 0; i < value.length; i++) {
    if (Array.isArray(value[i])) {
      for (let j = 0; j < value[i].length; j++) {
        if (typeof value[i][j] === 'string') {
          const n = nMap[value[i][j]];
          if (n && n.routeKey) {
            !cValue.includes(n.routeKey) && cValue.push(n.routeKey);
            if (j === value[i].length - 1) {
              !cValue.includes(value[i][j]) && cValue.push(value[i][j]);
            }
          }
        }
      }
    }
  }
  return cValue;
};
