import type { PageModelConfig } from '@linkpi/core';
import { head } from 'ramda';
import type { FC } from 'react';

import styles from './styles.less';

export const BgImgBox: FC<
  Pick<PageModelConfig['pageConfig'], 'bgImgFit' | 'bgImgPosition'> & {
    height?: number;
    bgImg?: string | PageModelConfig['pageConfig']['bgImg'];
  }
> = ({ bgImg, bgImgFit, bgImgPosition, height }) => {
  const _bgImg: string | undefined =
    typeof bgImg === 'string' ? bgImg : head(bgImg || [])?.response;
  if (!_bgImg) return null;

  return (
    <img
      className={styles.bgImg}
      src={_bgImg}
      style={{
        objectFit: bgImgFit || 'unset',
        objectPosition: bgImgPosition || 'left top',
        height: height || '100%',
      }}
    />
  );
};
