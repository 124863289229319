/* eslint-disable react-refresh/only-export-components */
import { antdModalV5, create, show, useModal } from '@ebay/nice-modal-react';
import type { GetterPiNode } from '@linkpi/core/web';
import type { FC } from 'react';
import { useEffect, useState } from 'react';

import { useCurrentOrgId, useOrgConnection } from '@/hook/useOrg';

import QuoteSelector from '../QuoteSelector';

interface PropEditModalProps {
  editProps: string[];
  node: GetterPiNode;
}

const PropEditModal: FC<PropEditModalProps> = ({ editProps, node }) => {
  const modal = useModal();
  const modalProps = antdModalV5(modal);
  const orgId = useCurrentOrgId();
  const [quoteIndex, setQuoteIndex] = useState(-1);
  const orgConnection = useOrgConnection(orgId);

  useEffect(() => {
    if (editProps && editProps.length > 0 && modalProps.open) {
      setQuoteIndex(Number(editProps[0].slice(5)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalProps.open]);

  return (
    <QuoteSelector
      node={node.value}
      onCancel={() => {
        modalProps.onCancel();
        modal.resolve();
      }}
      orgConnection={orgConnection!}
      orgId={orgId}
      propIndex={quoteIndex}
      mode={'edit'}
      visible={modalProps.open}
      afterClose={modalProps.afterClose}
    />
  );
};

const PropEditNiceModal = create(PropEditModal);

export const showPropEditModal = async (props: PropEditModalProps) => {
  return show(PropEditNiceModal, props);
};

export default PropEditModal;
