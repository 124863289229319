import type { ApiResponse } from '@linkpi/core';
import { Modal } from 'antd';

import NodeHistoryStatus from '../../NodeHistoryStatus';

interface propsType {
  visible: boolean;
  node: PiNode;
  onCancel: any;
  orgId: string;
  template: ApiResponse.CurrentUser.TemplateInfo;
}

const HistoryStatus = (props: propsType) => {
  const { visible, node, onCancel, orgId, template } = props;

  return (
    <Modal
      centered
      open={visible}
      title={'状态变更历史'}
      onCancel={onCancel}
      footer={null}
      bodyStyle={{ height: 680, padding: 0 }}
      width={626}
    >
      <NodeHistoryStatus node={{ value: node }} orgId={orgId} template={template} disablePreview />
    </Modal>
  );
};

export default HistoryStatus;
