import type { ApiResponse } from '@linkpi/core';
import { useEffect, useRef, useState } from 'react';

// 如果属性里 有需要自动更新的日期
// 可以通过这个Hook的mark来更新
// 也可以通过onUpdate
export const UseUpdateDateBeforePresentMark = (
  template: ApiResponse.CurrentUser.TemplateInfo | null,
  onUpdate?: () => void,
) => {
  const [mark, setMark] = useState(0);
  const timerRef = useRef<ReturnType<typeof setTimeout>>(0 as any);

  useEffect(() => {
    unload();

    if (!template) {
      return;
    }

    // 挂载定时器
    load();

    return unload;
  }, [template]);

  const load = () => {
    if (!template) return;
    const propList = template.prop;
    if (!propList) return;

    const time = getMinIntervalType(template);
    if (time === false) return;

    timerRef.current = setInterval(() => {
      setMark((x) => x + 1);
      onUpdate?.();
    }, time);
  };
  const unload = () => {
    clearInterval(timerRef.current);
  };

  return [mark];
};

// 获取最小的更新间隔
const getMinIntervalType = (template: ApiResponse.CurrentUser.TemplateInfo): false | number => {
  const propList = template.prop;
  const typeMinSort: ApiResponse.CurrentUser.TemplateProp['intervalType'][] = [
    'min',
    'hour',
    'day',
  ];

  let minType: false | number = false;
  propList.map((x) => {
    if ((x.type === 'date' || x.type === 'datetime') && x.showInterval && !x.hide) {
      const sort = typeMinSort.indexOf(x.intervalType);
      minType = minType === false ? sort : Math.min(minType, sort);
    }
  });

  if (minType === false) {
    return false;
  } else {
    switch (minType) {
      case 2:
        return 60 * 60 * 24 * 1000;
      case 1:
        return 60 * 60 * 1000;
      case 0:
        return 60 * 1000;
      default:
        return 60 * 1000;
    }
  }
};
