import type { ApiResponse } from '@linkpi/core';
import { getDateBeforePresent } from '@linkpi/core';
import cls from 'classnames';

import styles from './styles.less';

const DateBeforePresent = (props: {
  prop: ApiResponse.CurrentUser.TemplateProp;
  value: null | undefined | number;
}) => {
  const { prop, value } = props;

  const isDate = prop.type === 'date' || prop.type === 'datetime';

  if (!isDate || !prop.showInterval || !value) {
    return null;
  }

  const beforePresent = getDateBeforePresent({ prop, value });
  if (!beforePresent.display) {
    return null;
  }

  return (
    <div className={cls(styles.dateBeforePresent, 'text-omit')}>
      <i className="iconfont iconattribute_time" style={{ color: beforePresent.color }} />
      <span style={{ color: beforePresent.color }}>{beforePresent.toString}</span>
    </div>
  );
};

export default DateBeforePresent;
