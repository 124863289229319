import { matchRoutes } from '@umijs/max';
import queryString from 'query-string';

export function parseOrgIdFromLocation(location: Location) {
  let spaceId = queryString.parse(location.search).spaceId as string;

  if (!spaceId) {
    const result = matchRoutes(
      [
        { path: '/home/:spaceId' },
        {
          path: '/home/:spaceId/:focusId/:nodeId',
        },
      ],
      location.pathname,
    );

    if (result?.[0]) {
      spaceId = result[0].params.spaceId!;
    }
  }

  return spaceId;
}
