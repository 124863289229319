import type { CSSProperties, ReactElement } from 'react';
import { Children, cloneElement } from 'react';

import { cn } from '@/utils/utils';

interface GridProps {
  cols?: string[];
  rows?: string[];
  areas?: string[][];
  /**
   * 需要有 key 去处理 grid-area
   */
  children: ReactElement | ReactElement[];
  className?: string;
  style?: CSSProperties;
}

const Grid = (props: GridProps) => {
  const { cols = [], rows = [], areas = [], children, className, style = {} } = props;
  return (
    <div
      className={cn('grid w-full h-full', className)}
      style={
        {
          display: 'grid',
          gridTemplateColumns: cols.join(' '),
          gridTemplateRows: rows.join(' '),
          gridTemplateAreas: areas.map((row) => '"' + row.join(' ') + '"').join(' '),
          ...style,
        } as CSSProperties
      }
    >
      {Children.map(children, (child) => {
        return cloneElement(child, {
          ...child.props,
          style: {
            gridArea: child.key,
            ...child.props.style,
          } as CSSProperties,
        });
      })}
    </div>
  );
};

export default Grid;
