import type { ApiResponse } from '@linkpi/core';
import { getQuoteOriginPropInfo } from '@linkpi/core';
import { match } from 'ts-pattern';

export type HorizontalAxisOption = {
  value: number;
  label: string;
  option?: unknown;
  originType?: ApiResponse.CurrentUser.propType | null;
  propType?: ApiResponse.CurrentUser.propType;
  multiple?: boolean;
};

export type VerticalAxisOption = {
  value: number | string;
  label: string;
  option?: unknown;
  originType?: ApiResponse.CurrentUser.propType | null;
  propType?: ApiResponse.CurrentUser.propType;
};

export const getFormOptions = (props: {
  templateMap: Record<string, ApiResponse.CurrentUser.TemplateInfo>;
  currentTemplate: string;
  viewType: number;
  userMap: Record<string, ApiResponse.OrgUser.OrgUserItem>;
}) => {
  const { templateMap, currentTemplate, viewType, userMap } = props;

  const horizontalOptions: HorizontalAxisOption[] = [], // x轴配置项 y轴图例类别
    conditionOptions: any[] = [], // 筛选配置项
    columnOptionGroup: any = {}, // 多维视图 筛选配置项 分组
    verticalGranularityGroup: any = {}, // 多维视图 统计维度
    verticalAxisOptions: VerticalAxisOption[] = [{ label: '系统标签', value: 'SysTag' }]; // 图例类别

  const template = templateMap[currentTemplate];
  if (template) {
    if (Array.isArray(template.prop) && template.prop.length) {
      const verticalGranularityGroupProp: any[] = [];

      template.prop.map((propInfo, i) => {
        if (!propInfo || !propInfo.type) return;
        let propType: ApiResponse.CurrentUser.propType = propInfo.type;
        let originType: ApiResponse.CurrentUser.propType | null = null;
        let multiple = propInfo.multiple || false;
        if (propInfo.type === 'quote') {
          const origin = getQuoteOriginPropInfo(propInfo, templateMap);
          multiple = origin.multiple || false;
          originType = origin.type || 'text';
          if (originType) {
            propType = originType;
          }
        }

        match(propType)
          .with('enum', 'tag', 'user', 'text', 'auto_inc', () => {
            horizontalOptions.push({
              value: i,
              label: propInfo.name,
              option: propInfo,
              originType,
              propType,
              multiple,
            });
            verticalAxisOptions.push({
              value: i,
              label: propInfo.name,
              option: propInfo,
              originType,
              propType,
            });
            if (!['text', 'auto_inc'].includes(propType))
              conditionOptions.push({
                value: 'prop' + i,
                label: propInfo.name,
              });
            verticalGranularityGroupProp.push({
              value: 'p' + i,
              label: propInfo.name,
            });
          })
          .with('date', 'datetime', () => {
            horizontalOptions.push({
              value: i,
              label: propInfo.name,
              option: propInfo,
              originType,
              propType,
              multiple,
            });
            verticalAxisOptions.push({
              value: i,
              label: propInfo.name,
              option: propInfo,
              originType,
              propType,
            });
            verticalGranularityGroupProp.push({
              value: 'p' + i + '-day',
              label: propInfo.name.concat('（按天统计）'),
            });
          })
          .with('address', () => {
            horizontalOptions.push({
              value: i,
              label: propInfo.name,
              option: propInfo,
              originType,
              propType,
              multiple,
            });
          })
          .with('cascade', () => {
            horizontalOptions.push({
              value: i,
              label: propInfo.name,
              option: propInfo,
              originType,
              propType,
              multiple,
            });
            verticalAxisOptions.push({
              value: i,
              label: propInfo.name,
              option: propInfo,
              originType,
              propType,
            });
          })
          .with('formula', () => {
            if (propInfo.formulaFormat === 1) {
              horizontalOptions.push({
                value: i,
                label: propInfo.name,
                option: propInfo,
                originType,
                propType,
                multiple,
              });
            }
          })
          .with('number', 'currency', () => {
            verticalAxisOptions.push({
              value: i,
              label: propInfo.name,
              option: propInfo,
              originType,
              propType,
            });
          })
          .otherwise(() => null);
      });
      verticalGranularityGroup.prop = {
        label: '属性',
        options: verticalGranularityGroupProp,
      };
    }
    if (conditionOptions.length) {
      columnOptionGroup.prop = {
        label: '属性',
        options: JSON.parse(JSON.stringify(conditionOptions)),
      };
    }
    if (Array.isArray(template.task_status) && template.task_status.length) {
      const to: any = [];
      const verticalGranularityGroupStatus = [
        {
          value: 'si',
          label: '状态',
        },
        {
          value: 's2-day',
          label: '开始时间（按天统计）',
        },
        {
          value: 's3-day',
          label: '结束时间（按天统计）',
        },
      ];
      template.task_status.map((x, i) => {
        if (x && !x.delete) {
          to.push({
            label: x.name,
            value: i,
          });
        }
      });
      conditionOptions.push({
        value: 'status',
        label: '状态',
      });
      conditionOptions.push({
        value: 'statusOwner',
        label: '负责人',
      });
      conditionOptions.push({
        value: 'startTime',
        label: '开始时间',
      });
      conditionOptions.push({
        value: 'endTime',
        label: '结束时间',
      });

      // horizontalOptions.push({
      //   value: -1,
      //   label: '状态',
      // });

      verticalAxisOptions.push({
        value: -1,
        label: '状态',
      });

      // options.push({
      //   value: -3,
      //   label: '负责人',
      // });
      columnOptionGroup.stat = {
        label: '状态属性',
        options: [
          {
            label: '状态',
            value: 'status',
            options: to,
          },
          {
            label: '状态转换至',
            value: 'statusFlow',
            options: to,
          },
          {
            label: '开始时间',
            value: 'statusStartTime',
            options: [
              {
                label: '今日',
                value: 1,
              },
              {
                label: '三天',
                value: 3,
              },
              {
                label: '七天',
                value: 7,
              },
            ],
          },
        ],
      };
      verticalGranularityGroup.stat = {
        label: '状态属性',
        options: verticalGranularityGroupStatus,
      };
    }
  }

  conditionOptions.push({
    value: 'creator',
    label: '创建者',
  });

  columnOptionGroup.sysC = {
    label: '系统属性',
    options: [
      {
        label: '创建者',
        value: 'sysCreator',
        options: Object.values(userMap).map((user) => {
          return {
            label: user.nick_name,
            value: user.account_id,
          };
        }),
      },
      {
        label: '创建时间',
        value: 'sysCreateTime',
        options: [
          {
            label: '今日',
            value: 1,
          },
          {
            label: '三天',
            value: 3,
          },
          {
            label: '七天',
            value: 7,
          },
        ],
      },
    ],
  };
  verticalGranularityGroup.sysC = {
    label: '系统属性',
    options: [
      {
        label: '创建者',
        value: 'creator',
      },
      {
        label: '创建时间（按天统计）',
        value: 'createTime-day',
      },
    ],
  };
  if (viewType === 3) {
    //折线图增加时间选项
    horizontalOptions.push({
      value: -2,
      label: '时间',
    });
  }

  return {
    horizontalOptions,
    conditionOptions,
    columnOptionGroup,
    verticalGranularityGroup,
    verticalAxisOptions: verticalAxisOptions,
  };
};
