import type { ApiResponse, QUOTE_SYS_PROP } from '@linkpi/core';
import { getMatchingOriginProp } from '@linkpi/core';
import { Checkbox, Select } from 'antd';
import type { FC } from 'react';
import { useMemo } from 'react';

import type {
  PropOption,
  TemplateInfo,
} from '@/pages/space/components/TemplateProp/components/TempPropModal/types';

import { useTempPropConfig } from '../hooks';

interface IPositioningType {
  tempProps: PropOption[];
  tempMap: Record<string, TemplateInfo>;
}

export const PositioningType: FC<IPositioningType> = (props) => {
  const { tempProps, tempMap } = props;
  const { isLock, propParams, setPropParams } = useTempPropConfig();

  const defaultOptions = useMemo(() => {
    const options: { label: string; value: number }[] = [];
    tempProps.forEach((p) => {
      if (p.type === 'positioning')
        options.push({
          label: p.name,
          value: p.index,
        });
      if (p.type === 'quote') {
        const originProp:
          | ApiResponse.CurrentUser.TemplateProp
          | null
          | (typeof QUOTE_SYS_PROP)[keyof typeof QUOTE_SYS_PROP]
          | { type: string } = getMatchingOriginProp(
          // @ts-ignore
          p,
          tempMap,
        );
        if (originProp?.type === 'positioning')
          options.push({
            label: p.name,
            value: p.index,
          });
      }
    });
    return options;
  }, [tempProps]);

  return (
    <>
      <div className={'prop-item'} key={'auxiliaryDisplay'}>
        <div className={'prop-item-label'}>辅助显示</div>
        <div className={'prop-item-input'}>
          <Select
            mode={'multiple'}
            placeholder={'请选择'}
            value={propParams.auxiliaryDisplay}
            options={defaultOptions}
            onChange={(e) => setPropParams({ ...propParams, auxiliaryDisplay: e })}
            filterOption={(input, option) =>
              !!option?.label && !!~option.label.toLowerCase().indexOf(input.toLowerCase())
            }
            allowClear={true}
            showSearch
          />
        </div>
      </div>
      <div className={'prop-item no-padding'} key={'showDistance'}>
        <div className={'prop-item-input'}>
          <Checkbox
            disabled={isLock}
            checked={propParams.showDistance}
            onChange={(e) =>
              setPropParams({
                ...propParams,
                showDistance: e.target.checked,
              })
            }
          >
            显示目标地点与当前地点之间的距离
          </Checkbox>
        </div>
      </div>
    </>
  );
};
