import type { TooltipProps } from 'antd';
import { Tooltip } from 'antd';

interface IProps {
  showTooltip: boolean;
  title: string;
  children: any;
  placement?: TooltipProps['placement'];
}

export default (props: IProps) => {
  const { children, showTooltip, title, placement } = props;

  if (!showTooltip) return children;

  return (
    <Tooltip title={title} placement={placement || 'top'}>
      {children}
    </Tooltip>
  );
};
