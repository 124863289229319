import { getPiSDK } from '@linkpi/sdk';
import { getWidgetUtilsContext } from '@mylinkpi/widget-react';
import { omit, pick } from 'ramda';
import { type ContextType, type FC, type PropsWithChildren, useMemo } from 'react';

import {
  useCurrentNodeContext,
  useCurrentOrgId,
  useCurrentUser as _useCurrentUser,
  useJumpNode,
  useNodeContentModalClose,
  useNodeTreeData,
  useOrgTempInfo,
  useTemplateList as _useTemplateList,
  useUrlQuerys,
} from '@/hook';

import { NodeViewComponent } from '../ViewComponent';

const _context = getWidgetUtilsContext();
type UtilsContextType = ContextType<typeof _context>;

const useCurrentUser = () => {
  const currentUser = _useCurrentUser();

  const result = useMemo(() => omit(['organization'], currentUser), [currentUser]);

  return result;
};

const useTemplateList = () => {
  const list = _useTemplateList();

  const result = useMemo(
    () => list.map((item) => pick(['name', 'status', 'template_id'], item)),
    [list],
  );

  return result;
};

const useTemplateInfo = (templateId: string, orgId: string) => {
  return useOrgTempInfo(orgId, templateId);
};

const useCurrentNode = () => {
  return useCurrentNodeContext().node!;
};

type NodeTreeData = {
  value: string;
  title: string;
  children: NodeTreeData[];
  disabled?: boolean;
};

const value: UtilsContextType = {
  useUrlQuerys,
  useCurrentOrgId: useCurrentOrgId,
  useCurrentUser,
  useTemplateInfo: useTemplateInfo as any,
  useTemplateList,
  piSDK: getPiSDK() as any,
  useCurrentNode,
  useNodeTreeData: useNodeTreeData as () => NodeTreeData[],
  useJumpNode,
  useNodeContentModalClose,
  NodeViewComponent,
};

export const CustomWidgeUtilsProvider: FC<PropsWithChildren> = ({ children }) => {
  const WidgetUtilsConetext = getWidgetUtilsContext();

  return <WidgetUtilsConetext.Provider value={value}>{children}</WidgetUtilsConetext.Provider>;
};
