import type { PiGridModules } from '../core';
import type { PositionType } from './_baseComponent';
import GridBaseComponent from './_baseComponent';

export default class Resizer extends GridBaseComponent {
  public data: any;

  constructor(position: PositionType, moduleInstances: PiGridModules, data: any) {
    super(position, moduleInstances);
    this.data = data;
  }

  public click() {}

  public mousedown(): void {
    const { key, minWidth, width } = this.data;
    this.moduleInstances.DataManager.resizingAttr = {
      key,
      width,
      minWidth,
    };
  }

  public render() {
    const { Render } = this.moduleInstances;

    if (this.isHover) {
      Render.setCursor('col-resize');
    }
  }
}
