import { Tooltip } from 'antd';
import { useState } from 'react';

import styles from './styles.less';
interface IProps {
  setNodeMainbodyContent: (v: any) => void;
  nodeMainbodyContent: null | {
    content: string;
    position: any;
  };
}

export default (props: IProps) => {
  const { nodeMainbodyContent, setNodeMainbodyContent } = props;
  if (!nodeMainbodyContent) return null;

  const { position, content } = nodeMainbodyContent;

  const [open, setOpen] = useState<boolean>(true);

  const changeOpen = (v: boolean) => {
    if (!v) setNodeMainbodyContent(null);
    setOpen(v);
  };

  return (
    <Tooltip
      overlayClassName={styles.nodeMainbodyContentTooltip}
      title={content}
      open={open}
      onOpenChange={changeOpen}
    >
      <div
        style={{
          position: 'absolute',
          height: position.height,
          width: position.width,
          left: position.x,
          top: position.y,
        }}
      />
    </Tooltip>
  );
};
