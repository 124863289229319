import { useMount } from 'ahooks';
import { type FC, type PropsWithChildren, useState } from 'react';

/**
 * 用于避免弹窗打开后，组件挂载了，但是其 dom 尚未挂载
 * 导致依赖 dom 元素的逻辑无法正常执行。
 *
 * 例如： 节点正文-MainBody 组件
 *
 * 相关 issus：
 * - https://github.com/ant-design/ant-design/issues/31242#issuecomment-872785828
 * - https://github.com/ant-design/ant-design/issues/39657
 */
export const AfterModalOpen: FC<PropsWithChildren> = ({ children }) => {
  const [mounted, setMounted] = useState(false);

  useMount(() => {
    setMounted(true);
  });

  if (!mounted) return null;

  return children;
};
