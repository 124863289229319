import { forwardRef, memo, useImperativeHandle } from 'react';

import ReactPiGantt from '../../../../pi/gantt/react';

import './index.less';

const Gantt = (props, ref) => {
  // useImperativeHandle(ref, () => ({}));
  console.log(props, '=====');
  return (
    <div className="gantt">
      <ReactPiGantt  ref={ref}  {...props} />
    </div>
  );
};

export default memo(forwardRef(Gantt));
