/* eslint-disable react-refresh/only-export-components */
import { antdModalV5, create, show, useModal } from '@ebay/nice-modal-react';
import {
  checkDisabledDate,
  convertTimeByModule,
  Date_Format_Options,
} from '@linkpi/core';
import type { GetterPiNode } from '@linkpi/core/web';
import { Button, Input, message, Modal, Select } from 'antd';
import type { FC } from 'react';
import { Fragment, useEffect, useRef, useState } from 'react';

import { useCurrentOrgId, useOrgTempMap, useOrgUserMap } from '@/hook';
import request from '@/utils/request';

import LinkPiDate from '../LinkPiDate';
import CommonForm from './CommonForm';

import styles from './styles.less';

interface NewRepeatTaskModalProps {
  node: GetterPiNode;
  repeatTaskPayload?: any; // 表示自定义按钮重复任务配置
}

const { Option } = Select;

const NewRepeatTaskModal: FC<NewRepeatTaskModalProps> = ({
  node,
  repeatTaskPayload,
}) => {
  const modal = useModal();
  const modalProps = antdModalV5(modal);

  const onCancel = () => {
    modalProps.onCancel();
    modal.resolve();
  };

  const orgId = useCurrentOrgId();
  const tempMap = useOrgTempMap();
  const userMap = useOrgUserMap();

  const [disabled, setDisabled] = useState(!!node.value.prop._sys_periodic);
  const [loading, setLoading] = useState(false);
  const [temp, setTemp] = useState<string>('');
  const [status, setStatus] = useState<any>(undefined);
  const [owner, setOwner] = useState<any>(undefined);
  const [participants, setParticipants] = useState<any[]>([]);
  const [propValueMap, setPropValueMap] = useState<any>({});

  const changeTemp = (v: string) => {
    setStatus(undefined);
    setTemp(v);
  };

  const changeStatus = (v: any) => setStatus(v);

  const myRef = useRef<any>({});

  useEffect(() => {
    const periodic: any = node.value.prop._sys_periodic || {};
    const _sys_task_status = periodic._sys_task_status;
    const _sys_temp = periodic._sys_temp || [];
    // 自定义按钮中设置的主题类型
    const tempByBtnConfig = repeatTaskPayload?.tempId;
    // 自定义按钮中设置的状态
    const statusIndexByBtnConfig = repeatTaskPayload?.status;

    // 计算属性值， 如果存在保存的 就用保存的， 否则就看是否继承了当前节点的值
    if (repeatTaskPayload) {
      const showProps = repeatTaskPayload.props.filter(
        (p: any) => !p.disabled && p.show,
      );
      const map: any = {};

      showProps.forEach((p: any) => {
        const { inherit, key, nowKey, type } = p;
        if (!inherit) map[nowKey] = null;
        else {
          const v = node.value.tempInfo.prop[key]; // 当前节点的属性值
          const nowProp = tempMap[tempByBtnConfig].prop[nowKey];
          const multiple = nowProp.multiple;

          if (type === 'text' || type === 'date') map[nowKey] = v;
          else {
            if (multiple) map[nowKey] = v ? (Array.isArray(v) ? v : [v]) : null;
            else map[nowKey] = v ? (Array.isArray(v) ? v[0] : v) : null;
          }
        }
      });

      if (periodic._sys_temp) {
        showProps.forEach((p: any) => {
          map[p.nowKey] = periodic._sys_temp[1][p.nowKey];
        });
      }

      setPropValueMap(map);
    }

    // 回显数据
    const t = node.value.tempInfo.id;
    const statusIndex = node.value.tempInfo.status;
    setTemp(tempByBtnConfig || _sys_temp[0] || t);
    setStatus(
      statusIndexByBtnConfig
        ? statusIndexByBtnConfig
        : _sys_task_status
          ? _sys_task_status.index
          : statusIndex,
    );
    if (_sys_task_status && _sys_task_status.prop) {
      setOwner(_sys_task_status.prop[0]);
      setParticipants(_sys_task_status.prop[1] || []);
    } else {
      const statusProp = node.value.tempInfo.statusProp;
      setOwner(statusProp[0]);
      setParticipants(statusProp[1] || []);
    }
  }, []);

  const getStatus = () => {
    const t = tempMap[temp];
    if (!t) return [];

    return t.task_status
      .map((s, index) => ({ ...s, statusIndex: index }))
      .filter((s) => {
        if (s.delete) return false;

        return s.prop[2].display;
      });
  };

  const getOddDay = (t: any) => {
    const v = Math.floor((t + 8 * 3600000) / 86400000);
    return v % 2;
  };

  const getOddWeek = (t: any) => {
    const v = Math.floor((t + 8 * 3600000) / 86400000);
    const w = Math.floor((v - 4) / 7);
    return w % 2;
  };

  const renderProp = (item: any) => {
    const { nowKey, type, label } = item;

    let form: any = null;
    const nowProp = tempMap[repeatTaskPayload.tempId].prop[nowKey];
    const multiple = nowProp.multiple;
    const selectProps: any = multiple ? { mode: 'multiple' } : {};

    if (type === 'date') {
      const saveValue = propValueMap[nowKey];
      const dateModule =
        Date_Format_Options.find((x) => x.value === nowProp.dateFormat)
          ?.module || (nowProp.type === 'date' ? 'day' : 'min');

      form = (
        <LinkPiDate
          disabled={disabled}
          dateFormat={
            nowProp.dateFormat ||
            (nowProp.type === 'date' ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm')
          }
          onChange={(e) => {
            setPropValueMap({
              ...propValueMap,
              [nowKey]: convertTimeByModule(e, dateModule),
            });
          }}
          dateValue={
            typeof saveValue === 'string' ? parseInt(saveValue) : saveValue
          }
          disabledDate={(current) =>
            checkDisabledDate(
              current as any,
              dateModule,
              nowProp.numberLimit,
              [],
            )
          }
        />
      );
    }
    if (type === 'text')
      form = (
        <Input
          disabled={disabled}
          style={{ width: 280 }}
          onChange={(e) =>
            setPropValueMap({ ...propValueMap, [nowKey]: e.target.value })
          }
          value={propValueMap[nowKey]}
        />
      );

    if (type === 'user')
      form = (
        <Select
          disabled={disabled}
          {...selectProps}
          value={propValueMap[nowKey]}
          style={{ width: 280 }}
          placeholder="请选择"
          onChange={(e) => setPropValueMap({ ...propValueMap, [nowKey]: e })}
          showSearch
          filterOption={(input, option) =>
            option?.props?.children
              ?.toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
        >
          {Object.values(userMap).map((u) => (
            <Option value={u.account_id} key={u.account_id}>
              {u.nick_name}
            </Option>
          ))}
        </Select>
      );

    if (['enum', 'tag'].includes(type)) {
      const extend = nowProp.extend;
      form = (
        <Select
          disabled={disabled}
          {...selectProps}
          value={propValueMap[nowKey]}
          style={{ width: 280 }}
          placeholder="请选择"
          onChange={(e) => setPropValueMap({ ...propValueMap, [nowKey]: e })}
          showSearch
          filterOption={(input, option) =>
            option?.props?.children
              ?.toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
        >
          {extend.map((i: string) => (
            <Option value={i} key={i}>
              {i}
            </Option>
          ))}
        </Select>
      );
    }

    return (
      <div className={styles.item}>
        <div className={styles.label}>{label}：</div>
        <div className={styles.form}>{form}</div>
      </div>
    );
  };

  const renderProps = () => {
    if (!repeatTaskPayload) return null;
    const showProps = repeatTaskPayload.props.filter(
      (p: any) => !p.disabled && p.show,
    );

    return showProps.map((p: any) => (
      <Fragment key={p.nowKey}>{renderProp(p)}</Fragment>
    ));
  };

  const getSysTemp = () => {
    if (!repeatTaskPayload?.tempId) {
      return tempMap[temp].prop.map((p, index) => null);
    }
    return tempMap[repeatTaskPayload.tempId].prop.map((p, index) =>
      index in propValueMap ? propValueMap[index] : null,
    );
  };

  const ok = async () => {
    const _sys_temp = [
      temp,
      temp === node.value.tempInfo.id
        ? node.value.prop._sys_temp[1]
        : getSysTemp(),
    ];
    const _sys_task_status = {
      index: status,
      prop: [
        owner,
        participants,
        node.value.tempInfo.statusProp[2],
        node.value.tempInfo.statusProp[3],
      ],
    };
    if (myRef.current.rule === 'cycle') {
      const {
        rangeEndPayload,
        rangeStartMinute,
        rangeStartHour,
        startTime,
        earlyTime,
        endType,
        endTime,
        endNumber,
        repeatType,
        months,
        countWeek,
        days,
        countWeekValue,
        dates,
        createType,
      } = myRef.current;
      const duration =
        (rangeEndPayload.day * 24 + rangeEndPayload.hour) * 60 +
        rangeEndPayload.min -
        rangeStartHour * 60 -
        rangeStartMinute;

      const repeat = [
        {
          month: repeatType === 'everyYear' ? months : null,
          countWeek: [countWeek].filter((i) => i),
          hours: rangeStartHour,
          min: rangeStartMinute,
          week: ['everyWeek', 'nextWeek'].includes(repeatType)
            ? days
            : countWeek
              ? [countWeekValue]
              : null,
          day:
            ['everyYear', 'everyMonth'].includes(repeatType) && dates.length
              ? dates
              : null,
          oddDay: repeatType === 'nextDay' ? getOddDay(startTime) : null,
          oddWeek: repeatType === 'nextWeek' ? getOddWeek(startTime) : null,
        },
      ];
      const sysPeriodic: any = {
        _sys_temp,
        _sys_task_status,
        duration,
        startTime,
        repeat,
        beforehand: createType === 'disposable' ? 0 : earlyTime * 60,
        endTime: endType === 'date' ? endTime : null,
        totalCount: endType === 'number' ? endNumber : null,
      };

      sysPeriodic.imm = createType === 'disposable';

      setLoading(true);
      const res = await request('/docapi/setPeriodic', {
        method: 'POST',
        data: {
          node_id: node.value.id,
          org_id: orgId,
          _sys_periodic: sysPeriodic,
          deleteNode: createType === 'disposable',
        },
      });
      setLoading(false);
      if (res.status === 'ok') {
        message.success('设置成功');
      } else {
        message.error('设置失败');
      }
    }
    if (myRef.current.rule === 'exactDate') {
      const { earlyTime, exactDate, createType } = myRef.current;
      setLoading(true);
      const res = await request('/docapi/setPeriodic', {
        method: 'POST',
        data: {
          deleteNode: createType === 'disposable',
          node_id: node.value.id,
          org_id: orgId,
          _sys_periodic: {
            exactDate, // 用来回显
            _sys_temp,
            _sys_task_status,
            repeat: [
              {
                specDay: exactDate.map((t: any) =>
                  Math.floor((t + 8 * 3600_000) / 86400_000),
                ),
              },
            ],
            beforehand:
              createType === 'disposable' ? 999999999 : earlyTime * 60,
          },
        },
      });
      setLoading(false);
      if (res.status === 'ok') {
        message.success('设置成功');
      } else {
        message.error('设置失败');
      }
    }
  };

  const allowStatusProp = (type: string) => {
    if (status === undefined) return false;
    const t = tempMap[temp];
    if (!t) return false;
    const index = type === 'owner' ? 0 : 1;

    return t.task_status[status].prop[index].display;
  };

  const isDisposable = () => {
    const periodic: any = node.value.prop._sys_periodic;
    return periodic && periodic.beforehand > 999999998;
  };
  const stop = () => {
    Modal.confirm({
      title: '确定终止任务？',
      content: isDisposable() ? '已生成的未来任务将自动删除' : '',
      onOk: async () => {
        await request('/docapi/removePeriodic', {
          method: 'POST',
          data: {
            node_id: node.value.id,
            org_id: orgId,
          },
        });
      },
    });
  };

  const getFooter = () => {
    if (!disabled)
      return (
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <Button onClick={onCancel}>取消</Button>
          <Button
            loading={loading}
            type="primary"
            onClick={ok}
            style={{ marginLeft: 24 }}
          >
            确定
          </Button>
        </div>
      );

    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button onClick={() => setDisabled(false)}>编辑</Button>
        <div>
          <Button onClick={onCancel}>取消</Button>
          <Button onClick={stop} type="primary" style={{ marginLeft: 24 }}>
            终止任务
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Modal
      title="重复任务"
      width={596}
      footer={getFooter()}
      {...modalProps}
      onCancel={onCancel}
      centered
    >
      <div className={styles.container}>
        {/* 主题类型： */}
        <div className={styles.item}>
          <div className={styles.label}>主题类型：</div>
          <div className={styles.form}>
            <Select
              disabled={disabled || repeatTaskPayload}
              value={temp}
              style={{ width: '100%' }}
              placeholder="请选择"
              onChange={changeTemp}
              showSearch
              filterOption={(input, option) =>
                option?.props?.children
                  ?.toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {Object.values(tempMap)
                .filter((t) => ![2, 3].includes(t.status))
                .map((t) => (
                  <Option value={t.template_id} key={t.template_id}>
                    {t.name}
                  </Option>
                ))}
            </Select>
          </div>
        </div>
        {/* 选择状态： */}
        <div className={styles.item}>
          <div className={styles.label}>选择状态：</div>
          <div className={styles.form}>
            <Select
              disabled={disabled || repeatTaskPayload}
              value={status}
              style={{ width: '100%' }}
              placeholder="请选择"
              onChange={changeStatus}
              showSearch
              filterOption={(input, option) =>
                option?.props?.children
                  ?.toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {getStatus().map((s) => (
                <Option value={s.statusIndex} key={s.statusIndex}>
                  {s.name}
                </Option>
              ))}
            </Select>
          </div>
        </div>
        <CommonForm disabled={disabled} myRef={myRef} node={node} />
        <div className={styles.divider} />
        {allowStatusProp('owner') && (
          <div className={styles.item}>
            <div className={styles.label}>负责人：</div>
            <div className={styles.form}>
              <Select
                disabled={disabled}
                value={owner}
                style={{ width: 280 }}
                placeholder="请选择"
                onChange={setOwner}
                showSearch
                filterOption={(input, option) =>
                  option?.props?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {Object.values(userMap).map((u) => (
                  <Option value={u.account_id} key={u.account_id}>
                    {u.nick_name}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        )}
        {allowStatusProp('') && (
          <div className={styles.item}>
            <div className={styles.label}>参与者：</div>
            <div className={styles.form}>
              <Select
                disabled={disabled}
                value={participants}
                style={{ width: 280 }}
                placeholder="请选择"
                onChange={setParticipants}
                showSearch
                mode="multiple"
                filterOption={(input, option) =>
                  option?.props?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {Object.values(userMap).map((u) => (
                  <Option value={u.account_id} key={u.account_id}>
                    {u.nick_name}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        )}
        {renderProps()}
      </div>
    </Modal>
  );
};

const NewRepeatTaskNiceModal = create(NewRepeatTaskModal);

export const setNewRepeatTask = (props: NewRepeatTaskModalProps) => {
  return show(NewRepeatTaskNiceModal, props);
};
