import { useEffect, useState } from 'react';

export function useSyncedState<T>(initialValue: T) {
  const [state, setState] = useState(initialValue);

  useEffect(() => {
    setState(initialValue);
  }, [initialValue]);

  return [state, setState] as const;
}
