import { useEffect, useRef } from 'react';

export default (
  eventKey,
  handler,
  _options = {
    useCapture: false,
    element: document.body,
    exact: false,
  },
) => {
  const saveHandler = useRef();
  const options = {
    useCapture: false,
    element: document.body,
    exact: false, // 是否完全匹配
    ..._options,
  };

  useEffect(() => {
    saveHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    const eventListener = (e) => {
      // if (!e.path || e.path[0] !== element) {
      //   return;
      // }
      // fix 包含即生效
      // fix 兼容safari
      const path = e.path || (e.composedPath && e.composedPath());
      if (path && path.length) {
        const isMatch = options.exact
          ? options.element === path[0]
          : options.element.contains(path[0]);
        const alias = getKeyCodeAlias(e);
        if (~alias.indexOf(eventKey)) {
          saveHandler.current(e);
        }
      }
    };

    options.element.addEventListener('keydown', eventListener, options.useCapture);
    return () => {
      options.element.removeEventListener('keydown', eventListener, options.useCapture);
    };
  }, [eventKey, handler]);

  const getKeyCodeAlias = (e) => {
    const { keyCode, ctrlKey, altKey, metaKey, shiftKey } = e;
    const isCtrl = ctrlKey || metaKey;
    const combonationSelfKey = [17, 16, 18, 91];

    const combination = [];
    const res = [];

    if (!~combonationSelfKey.indexOf(keyCode)) {
      if (isCtrl) {
        combination.push('ctrl');
      }
      if (shiftKey) {
        combination.push('shift');
      }
      if (altKey) {
        combination.push('alt');
      }
    }

    // enter
    if (keyCode === 13) {
      res.push([...combination, 'enter'].join('+'));
    }
    // tab
    if (keyCode === 9) {
      res.push([...combination, 'tab'].join('+'));
    }
    // up
    if (keyCode === 38) {
      res.push([...combination, 'up'].join('+'));
    }
    // down
    if (keyCode === 40) {
      res.push([...combination, 'down'].join('+'));
    }
    // f
    if (keyCode === 70) {
      res.push([...combination, 'f'].join('+'));
    }

    res.push([...combination, keyCode].join('+'));
    if (!combination.length) res.push(keyCode);

    return res;
  };
};
