import type { CurrentUser } from '@linkpi/core';
import { DEFAULT_STATUS_PROP } from '@linkpi/core';
import { Checkbox, message, Popover } from 'antd';
import { arrayMoveImmutable } from 'array-move';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import type { OnDragEndResponder } from 'react-beautiful-dnd';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { SearchBar } from '@/components';
import { ScrollArea, ScrollBar } from '@/components/ui/scroll-area';
import type { QuotePropOption } from '@/pages/space/components/TemplateProp/components/TempPropModal/types';

import { useTempPropConfig } from '../hooks';

interface IConditionMatchingEnum {
  matchingTemplate: CurrentUser.TemplateInfo | null;
  matchingProps: QuotePropOption[];
}

export const ConditionMatchingEnum: FC<IConditionMatchingEnum> = (props) => {
  const { propParams: params, setPropParams: setParams } = useTempPropConfig();
  const { matchingTemplate, matchingProps } = props;
  const [enumColumns, setEnumColumns] = useState(params.enumColumns);

  useEffect(() => {
    const options = [
      {
        label: matchingTemplate?.title_setting?.titleAlias || '标题',
        value: 'title',
      },
      {
        label: '状态',
        value: 'status',
      },
    ];
    if (matchingProps.length) {
      options.push.apply(
        options,
        matchingProps.map((p) => ({
          label: p.name,
          value: 'prop-' + p.index.split('-')[1],
        })),
      );
    }
    options.push.apply(
      options,
      DEFAULT_STATUS_PROP.map((s, i) => ({
        label: s.name,
        value: 'status-' + i,
      })),
    );
    setEnumColumns(
      enumColumns
        ? [
            ...enumColumns,
            ...options
              .filter((c) => !enumColumns?.find((e) => e.key === c.value))
              .map((c) => ({
                key: c.value,
                checked: false,
              })),
          ]
        : options.map((c) => ({
            key: c.value,
            checked: true,
          })),
    );
  }, [matchingTemplate, matchingProps]);

  const genColumnTitle = (key: string) => {
    if (key === 'title') return matchingTemplate?.title_setting?.titleAlias || '标题';
    if (key === 'status') return '状态';
    const [prefix, index] = key.split('-');
    switch (prefix) {
      case 'status':
        return DEFAULT_STATUS_PROP[Number(index)]?.name;
      case 'prop':
        return matchingTemplate?.prop[Number(index)]?.name;
    }
  };

  const checkColumn = (key: string, checked: boolean) => {
    if (!enumColumns) return;
    if (key === 'prop-' + params.matchingProp?.[0]?.split('-')?.[1])
      return message.warn('引用属性不支持删除');
    const columns = [...enumColumns];
    columns.forEach((c) => {
      if (c.key === key) {
        c.checked = checked;
      }
    });
    setEnumColumns(columns);
    setParams({
      ...params,
      enumColumns: columns,
    });
  };

  const dragColumn: OnDragEndResponder = (result) => {
    if (
      !enumColumns ||
      !result?.destination ||
      !result.source ||
      result.destination.index === result.source.index
    )
      return;
    const newData = arrayMoveImmutable(enumColumns, result.source.index, result.destination.index);
    setEnumColumns(newData);
    setParams({
      ...params,
      enumColumns: newData,
    });
  };

  const [searchText, setSearchText] = useState('');

  const enumColumnsPop = (
    <div className={'enum-columns'}>
      <div className="mx-2">
        <SearchBar value={searchText} onChange={(e) => setSearchText(e.target.value)} />
      </div>
      <div className={'enum-columns-list'}>
        <DragDropContext onDragEnd={dragColumn}>
          <Droppable droppableId="enumColumn" type="column">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {enumColumns
                  ?.filter((e) => {
                    if (
                      searchText &&
                      !genColumnTitle(e.key)
                        ?.toLocaleLowerCase()
                        .includes(searchText.toLocaleLowerCase())
                    ) {
                      return false;
                    }
                    return !!genColumnTitle(e.key);
                  })
                  ?.map((e, i) => (
                    <Draggable draggableId={e.key} key={e.key} index={i}>
                      {(draggableProvided) => (
                        <div
                          className={'enum-columns-list-item'}
                          onClick={() => checkColumn(e.key, !e.checked)}
                          key={e.key}
                          ref={draggableProvided.innerRef}
                          {...draggableProvided.draggableProps}
                        >
                          <div className={'enum-columns-list-item-name'}>
                            {!searchText && (
                              <i
                                className={'iconfont icondrag'}
                                {...draggableProvided.dragHandleProps}
                              />
                            )}
                            {genColumnTitle(e.key)}
                          </div>
                          <div className={'enum-columns-list-item-checked'}>
                            {e.checked ? <i className={'iconfont iconselected'} /> : null}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <div className={'enum-columns-check'}>
        <Checkbox
          checked={!enumColumns || !~enumColumns.findIndex((c) => !c.checked)}
          onChange={(e) =>
            setEnumColumns(
              enumColumns?.map((c) => ({
                ...c,
                checked:
                  c.key === 'prop-' + params.matchingProp?.[0]?.split('-')?.[1]
                    ? true
                    : e.target.checked,
              })),
            )
          }
        >
          全部显示
        </Checkbox>
      </div>
    </div>
  );

  return (
    <>
      <div className={'prop-condition-item'} key={'selectType'}>
        <div className={'prop-condition-item-label'}>选择方式</div>
        <div
          className={`prop-condition-item-btn${!params.selectType ? ' active' : ''}`}
          onClick={() => setParams({ ...params, selectType: 0 })}
        >
          属性值列表
        </div>
        <div
          className={`prop-condition-item-btn${params.selectType ? ' active' : ''}`}
          onClick={() => setParams({ ...params, selectType: 1 })}
        >
          主题列表
        </div>
      </div>
      {params.selectType === 1 ? (
        <div className={'prop-condition-item'} key={'enumColumns'}>
          <div className={'prop-condition-item-label'}>显示列</div>
          <Popover overlayClassName={'enum-column-pop'} trigger={'click'} content={enumColumnsPop}>
            <div className={'prop-condition-item-input select p-0'}>
              <ScrollArea className="w-full">
                <div className="gap-1 flex px-2">
                  {!enumColumns || !~enumColumns.findIndex((c) => !c.checked)
                    ? '全部显示'
                    : enumColumns.map((x) =>
                        x.checked ? (
                          <div key={x.key} className="prop-condition-item-input-tag select-none">
                            {genColumnTitle(x.key)}
                            {x.key !== 'prop-' + params.matchingProp?.[0]?.split('-')?.[1] ? (
                              <i
                                className={'iconfont iconButton_cancel'}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  checkColumn(x.key, false);
                                }}
                              />
                            ) : null}
                          </div>
                        ) : null,
                      )}
                </div>
                <ScrollBar orientation="horizontal" />
              </ScrollArea>
            </div>
          </Popover>
        </div>
      ) : null}
    </>
  );
};
