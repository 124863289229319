import type { ApiResponse } from '@linkpi/core';
import type { GetterPiNode } from '@linkpi/core/web';
import { useSelector } from '@umijs/max';
import { useSize } from 'ahooks';
import { Modal } from 'antd';
import cls from 'classnames';
import type { Dispatch, SetStateAction } from 'react';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { useOrgUserList, useOrgUserMap } from '@/hook';

import Board from '../../Board';
import Charts from '../../Charts';
import countGroupData from '../../counGroupData';
import Gantt from '../../Gantt';
import Grid from '../../Grid';
import type { ComponentFormRefType } from '../ComponentForm';
import ComponentForm from '../ComponentForm';
import DashBoardRowDisplay from './DashBoardRowDisplay';

import styles from './styles.less';
type PreviewComponentPropsType = {
  visible: boolean;
  viewName: string;
  currentUser: ApiResponse.CurrentUser;
  orgInfo: ApiResponse.CurrentUser.OrgInfo;
  curViewData: ApiResponse.ViewList.ViewListItem<any>;
  cardContent: React.ReactNode;
  node: GetterPiNode;
  dashboardViewInfo: ApiResponse.ViewList.ViewListItem<5>;
  cardIndex: number;
  dashboardEditPermission: boolean;
  cardViewEditPermission: boolean;
  groups: ApiResponse.CurrentUser.orgGroup[];
  onFix: () => void;
  onJump: () => void;
  onClose: (state: boolean) => void | Dispatch<SetStateAction<boolean>>;
  getViewList: () => void;
  getNewCardLayout: (type: 'more' | 'chart') => {
    x: number;
    y: number;
    w: number;
    h: number;
  };
};

const PreviewComponent = (props: PreviewComponentPropsType) => {
  const {
    visible,
    viewName,
    curViewData,
    cardContent,
    dashboardViewInfo,
    node,
    orgInfo,
    cardIndex,
    cardViewEditPermission,
    dashboardEditPermission,
    groups,
    currentUser,
    onFix,
    onJump,
    onClose,
    getViewList,
    getNewCardLayout,
  } = props;
  const spaceUsers = useOrgUserList();
  const userMap = useOrgUserMap();

  const [displayView, setDisplayView] = useState(false);

  // TODO 在预览组件算
  const [groupData, setGroupData] = useState({});

  const componentFromRef = useRef<ComponentFormRefType>(null);

  const handleSetting = () => {
    componentFromRef.current?.open();
  };

  useEffect(() => {
    setDisplayView(true);
    if (visible) {
      getGroupData();
    } else {
      setGroupData({});
    }
  }, [visible]);

  const getGroupData = async () => {
    const data = await countGroupData(
      curViewData,
      [],
      orgInfo,
      node,
      currentUser,
      spaceUsers,
      userMap,
      [],
      [],
      groups,
    );

    setGroupData(data);
  };

  const settings = [];
  let stat = null;
  let view = null;

  if (curViewData.dash_view_id) {
    if (dashboardEditPermission && cardViewEditPermission) {
      settings.push(
        <div key={0} className="link-pi-btn btn-lg" style={{ marginRight: 0 }} onClick={onFix}>
          <i className="iconfont icondingzhu" style={{ color: '#316ef5', marginRight: 3 }} />
          设为固定视图
        </div>,
      );
    }
  } else {
    if (cardViewEditPermission) {
      settings.push(
        <div key={0} className="link-pi-btn btn-lg" style={{ marginRight: 0 }} onClick={onJump}>
          <i className="iconfont iconView_Skip" style={{ color: '#316ef5', marginRight: 3 }} />
          跳转至关联视图
        </div>,
      );
    }
  }

  if (curViewData.view_type !== 3) {
    stat = <div className={styles.stat}>{cardContent}</div>;
  }

  if (displayView) {
    if (curViewData.view_type === 1 && groupData) {
      view = (
        <Board
          groupData={groupData}
          userMap={userMap}
          curViewDataInfo={curViewData.view_info}
          templateList={orgInfo.templateList}
        />
      );
    } else if (curViewData.view_type === 2 && groupData) {
      settings.push(
        <DashBoardRowDisplay
          templateList={orgInfo.templateList}
          userMap={userMap}
          curViewData={curViewData}
        />,
      );
      view = (
        <Grid
          node={node}
          groupData={groupData}
          templateList={orgInfo.templateList}
          userMap={userMap}
          userList={spaceUsers}
          curViewData={curViewData}
          orgInfo={orgInfo}
          checkId={[]}
          setCheckId={() => {}}
          searchTitle={''}
          showNodeSaved={() => {}}
          getViewList={() => {}}
          setGridAttrs={() => {}}
          groups={groups}
        />
      );
    } else if (curViewData.view_type === 3) {
      const showTable = curViewData.charts_info?.type === 4;
      view = (
        <ChartWrapper
          curViewData={curViewData}
          orgInfo={orgInfo}
          showTable={showTable}
          showTools={false}
          showDataZoom={false}
          userMap={userMap}
          node={node}
        />
      );
    } else if (curViewData.view_type === 4 && groupData) {
      view = (
        <Gantt
          node={node}
          userMap={userMap}
          orgId={orgInfo.orgId}
          templateList={orgInfo.templateList}
          groupData={groupData || {}}
          curViewData={curViewData}
        />
      );
    }
  }

  let displaySourceBtn = null;

  if (curViewData.view_type !== 3) {
    if (displayView) {
      displaySourceBtn = (
        <div className={styles.largeBtn} onClick={() => setDisplayView(false)}>
          <i className="iconfont icona-Hide3x" />
          隐藏数据源
        </div>
      );
    } else {
      displaySourceBtn = (
        <div className={styles.largeBtn} onClick={() => setDisplayView(true)}>
          <i className="iconfont iconPublic" />
          显示数据源
        </div>
      );
    }
  }

  return (
    <Modal
      destroyOnClose={true}
      title={null}
      open={visible}
      footer={null}
      width={'100%'}
      style={{ maxWidth: '1160px' }}
      onCancel={() => onClose(false)}
      wrapClassName={styles.modalWrapper}
    >
      <div className={styles.modalContent}>
        <div className={styles.header}>
          <div className={cls(styles.name, 'text-omit')}>{viewName}</div>
          <div className={styles.tools}>
            {displaySourceBtn}
            {dashboardEditPermission && (
              <div className={styles.btn} onClick={handleSetting}>
                <i className="iconfont iconset" />
              </div>
            )}
          </div>
        </div>
        <div className={styles.container}>
          {stat}
          {view ? (
            <div className={styles.view}>
              <div className={styles.settings}>{settings}</div>
              <div className={styles.viewInner}>{view}</div>
            </div>
          ) : null}

          <ComponentForm
            groups={groups}
            ref={componentFromRef}
            orgInfo={orgInfo}
            node={node}
            getViewList={getViewList}
            dashboardViewData={dashboardViewInfo}
            editType="edit"
            displayType="drawer"
            editViewData={curViewData}
            cardIndex={cardIndex}
            userMap={userMap}
            getNewCardLayout={getNewCardLayout}
          />
        </div>
      </div>
    </Modal>
  );
};

const ChartWrapper = (props: any) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const size = useSize(wrapperRef) || { height: 0, width: 0 };

  return (
    <div style={{ width: '100%', height: '100%' }} ref={wrapperRef}>
      {size.width && size.height ? (
        <Charts {...props} node={props.node} contentWidth={size.width} height={size.height} />
      ) : null}
    </div>
  );
};

export default PreviewComponent;
