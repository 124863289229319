import {
  CheckCircleFilled,
  CloseCircleFilled,
  ExclamationCircleFilled,
  InfoCircleFilled,
  InfoOutlined,
} from '@ant-design/icons';
import type { CurrentUser } from '@linkpi/core';
import { history } from '@umijs/max';
import { Modal } from 'antd';
import queryString from 'query-string';
import { isEmpty } from 'ramda';
import { match } from 'ts-pattern';

import { getViewList } from '@/services/view';

export const customButtonConfirm = (config: CurrentUser.CustomButton) => {
  return new Promise((resolve, reject) => {
    const { confirmConfig } = config;

    if (
      !confirmConfig ||
      isEmpty(confirmConfig) ||
      (confirmConfig as any).enableConfirm === false
    ) {
      resolve(true);
      return;
    }

    const icon = match(confirmConfig.type)
      .with('info', () => <InfoCircleFilled style={{ color: '#faad14' }} />)
      .with('error', () => <CloseCircleFilled style={{ color: '#f5222d' }} />)
      .with('warn', 'warning', () => (
        <ExclamationCircleFilled style={{ color: '#f5222d' }} />
      ))
      .with('success', () => <CheckCircleFilled style={{ color: '#52c41a' }} />)
      .with('confirm', () => <InfoOutlined style={{ color: '#f5222d' }} />)
      .otherwise(() => <InfoCircleFilled style={{ color: '#faad14' }} />);

    Modal.confirm({
      icon,
      title: confirmConfig.title,
      content: confirmConfig.content,
      okText: confirmConfig.okText,
      cancelText: confirmConfig.cancelText,
      onOk: () => {
        resolve(true);
      },
      onCancel: () => {
        reject(false);
      },
    });
  });
};

export const jumpToView = async ({
  viewId,
  orgId,
}: {
  viewId: string;
  orgId: string;
}) => {
  const view = await getViewList({ view_id: [viewId], org_id: orgId });

  if (view.length === 0) {
    return;
  }

  const viewConfig = view[0];

  if (!viewConfig.node_id) return;

  const rootId = history.location.pathname.split('/')[2];

  const querys = queryString.stringify({
    ...queryString.parse(history.location.search),
    vid: viewId,
  });

  history.push(`/home/${orgId}/${rootId}/${viewConfig.node_id}?${querys}`);
};
