import type { ProFormSelectProps } from '@ant-design/pro-form';
import ProForm from '@ant-design/pro-form';
import type { FC } from 'react';

import { SapceUserSelect } from '@/components/EnhanceForm';

type IOrgUserSelect = ProFormSelectProps;

export const OrgUserSelect: FC<React.PropsWithChildren<IOrgUserSelect>> = ({
  fieldProps,
  ...props
}) => {
  return (
    <ProForm.Item {...props}>
      <SapceUserSelect {...fieldProps} />
    </ProForm.Item>
  );
};
