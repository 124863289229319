import type { ILineTextConfig } from '@/components/PageModelEditor/src/widgets/LineTextWidget/Setting';

interface LineTextProps {
  data: ILineTextConfig;
}

export const LineText = (props: LineTextProps) => {
  const { data } = props;

  const {
    value,
    fontSize,
    fontBold,
    fontAlign,
    color,
    backgroundColor: bgColor,
    useIcon,
    icon,
    useBgColor,
  } = data;
  const fontColor = `rgba(${color.r},${color.g},${color.b},${color.a})`;
  const backgroundColor = `rgba(${bgColor.r},${bgColor.g},${bgColor.b},${bgColor.a})`;
  const lineHeight = fontSize * 1.5;

  const fontStyle: React.CSSProperties = {
    fontSize,
    fontWeight: fontBold ? 'bold' : 'normal',
    textAlign: fontAlign,
    color: fontColor,
  };

  if (useBgColor) {
    fontStyle.backgroundColor = backgroundColor;
  }

  return (
    <div className="w-full h-full" style={fontStyle}>
      {useIcon && icon && (
        <img src={icon} className="mr-1" style={{ height: lineHeight, verticalAlign: 'middle' }} />
      )}
      {value}
    </div>
  );
};
