import { DEFAULT_AVATAR } from '@linkpi/core';
import { checkGroup } from '@linkpi/core';

import { FILE_TYPE_MAP } from '@/components/WebOfficeModal/constants';
import { getFileTypeIconByUrl } from '@/utils/utils';

import ActionMask from '../commonComponents/actionMask';
import type { PositionType } from '../components/_baseComponent';
import GridBaseComponent from '../components/_baseComponent';
import type { PiGridModules } from '../core';

class FixAttachment extends GridBaseComponent {
  public src: string;
  public file: any;
  public propConfig: any;
  public type: string = 'fixAttachment';
  public captrue: boolean = false;

  constructor(position: PositionType, moduleInstances: PiGridModules, file: any, chunk: any) {
    super(position, moduleInstances);
    this.src = getFileTypeIconByUrl(file.src).src;
    this.file = file;
    this.propConfig = chunk.propConfig;
  }

  public drawImg(src: string, x: number, y: number, size: number) {
    const { Draw, ImageLoader } = this.moduleInstances;

    const img = ImageLoader.loadImage(src || DEFAULT_AVATAR, 'attachment');

    // 图片居中裁剪
    const h = img.height;
    const w = img.width;
    const min = Math.min(h, w);
    const max = Math.max(h, w);

    if (min !== max) {
      Draw.drawRoundImg(
        6,
        x,
        y,
        size,
        size,
        img,
        h >= w ? 0 : (max - min) / 2, // x 裁剪位置
        h >= w ? (max - min) / 2 : 0, // y 裁剪位置
        min,
        min,
      );
    } else Draw.drawRoundImg(6, x, y, size, size, img);
  }

  public click() {
    const ext = this.file.fileName.split('.')[1];
    if (FILE_TYPE_MAP[ext]) {
      return this.moduleInstances.DataManager.config.handleViewDoc({
        src: this.file.src,
        name: this.file.fileName,
      });
    }
    this.moduleInstances.DataManager.config.showBigImg(this.src);
  }

  public drawActionMask() {
    const { x, y, height } = this.position;
    const maskWidth = height;
    const maskHeight = height / 2;

    const actionMask = new ActionMask(
      {
        height: maskHeight,
        width: maskWidth,
        x,
        y: y + maskHeight,
      },
      this.moduleInstances,
      this.file,
    );
    actionMask.render();
  }

  public render() {
    const { x, y, height } = this.position;
    this.drawImg(this.src, x, y, height);

    if (
      this.isHover &&
      checkGroup(
        this.propConfig.attachmentConfig?.allowDownloadGroups || ['-1'],
        this.moduleInstances.DataManager.config.userInfo,
      )
    ) {
      this.moduleInstances.Render.setCursor('pointer');
      this.drawActionMask();
    }
  }
}

export default FixAttachment;
