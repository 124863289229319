import { NODE_PERMISSION } from '@linkpi/core';
import { message, Modal, Progress } from 'antd';
import { useState } from 'react';

import request from '@/utils/request';
import { notAdminDelNodeAcl } from '@/utils/utils';

import '../GroupForView/styles.less';
export default (props: any) => {
  const { getterPiNode, orgInfo, checkId: ids, setCheckId } = props;

  if (ids.length < 1) return null;

  const { confirm } = Modal;

  const [progressModalVisible, setShowProgressModal] = useState(false);
  const [progressTotal, setProgressTotal] = useState<any>(0);
  const [progressOver, setProgressOver] = useState(false);
  const [progressFinished, setProgressFinished] = useState<any>(0);
  const [failed, setFailed] = useState<any>(0);

  const initProgress = () => {
    setProgressFinished(0);
    setProgressTotal(0);
    setProgressOver(false);
    setFailed(0);
  };

  const getProgress = async (async_id: any, total: any, type: any) => {
    const { status, data, success } = (await request('/docapi/getBatchProgress', {
      method: 'POST',
      data: { type, async_id, total },
    })) as any;

    if (status === 'ok') {
      setProgressFinished(data);
      if (data < total) {
        setTimeout(() => {
          getProgress(async_id, total, type);
        }, 1200);
      } else {
        // 已经全部处理结束
        setProgressOver(true);
        setTimeout(() => {
          if (data !== Number(success)) return setFailed(data - Number(success));
          setCheckId([]);
          setShowProgressModal(false);
          initProgress();
        }, 2000);
      }
    } else {
      setTimeout(() => {
        getProgress(async_id, total, type);
      }, 1200);
    }
  };

  const onBatchDelete = async () => {
    confirm({
      title: '提示',
      content: `是否确定删除${ids.length}个主题？`,
      onOk: async (closeModal) => {
        const allowIds = ids.filter((i: string) => {
          const n = getterPiNode.value.nodeManager.findChildren(i);
          return (
            notAdminDelNodeAcl(orgInfo, n) && n.acl === NODE_PERMISSION.管理 && !n.prop._sys_protect
          );
        });

        if (allowIds.length < 1) {
          closeModal();
          message.warning('当前选中的主题无权限删除');
          return;
        }

        if (allowIds.length < ids.length) message.warning('部分主题无权限删除，已跳过');
        // @ts-ignore
        const { async_id, total, status } = await request('/docapi/batchMoveToRecycle', {
          method: 'POST',
          data: { org_id: orgInfo.orgId, node_id: allowIds },
        });
        if (status === 'ok') {
          closeModal();
          setShowProgressModal(true);
          setProgressTotal(total);
          setTimeout(() => {
            getProgress(async_id, total, 'delete');
          }, 1200);
        }
      },
    });
  };

  return (
    <>
      <div className="view-setting-block" onClick={onBatchDelete}>
        <i className="iconfont iconView_Delet" />
        &nbsp;删除
      </div>
      <Modal
        footer={null}
        open={progressModalVisible}
        closable={progressOver}
        maskClosable={false}
        onCancel={() => {
          setCheckId([]);
          setShowProgressModal(false);
          initProgress();
        }}
      >
        <div style={{ padding: '24px 0 12px 0' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: 8 }}>{progressOver ? '删除完成!' : '删除中'}</div>
            {failed > 0 && (
              <>
                <div>成功{progressTotal - failed}条，</div>
                <div style={{ color: 'red' }}>失败：{failed}条</div>
              </>
            )}
          </div>
          <Progress percent={Math.ceil((progressFinished * 100) / progressTotal)} />
        </div>
      </Modal>
    </>
  );
};
