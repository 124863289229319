import type { CurrentUser } from '@linkpi/core';
import { propIsNull } from '@linkpi/core';
import { useDispatch, useSelector } from '@umijs/max';
import { useDebounceEffect } from 'ahooks';
import { Button, Input, message } from 'antd';
import { defaultTo } from 'ramda';
import React, {
  forwardRef,
  Fragment,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import LinkPiPop from '@/components/LinkPiPop';
import { useDebounce } from '@/hook';

import './index.less';

type AreaData = CurrentUser.AreaData;
type Address = {
  prov: string;
  city?: string;
  dist?: string;
  street?: string;
  add?: string;
  provCode: string;
  cityCode?: string;
  distCode?: string;
  lat: number | null;
  lng: number | null;
};
type Props = {
  prop: CurrentUser.TemplateProp;
  address?: Address[];
  onConfirm: (add: Address, cb?: IRef['close']) => void;
  quoteOptions?: any[];
  autoOpen?: boolean;
  disabled?: boolean;
  style?: any;
  addQuoteTheme?: Function;
  quoteTemplate?: string;
  onCancel?: (cb?: IRef['close']) => void;
  getPopupContainer?: boolean;
};
export type IRef = {
  close: () => void;
};
const { TextArea } = Input;
const AddressInput: React.ForwardRefRenderFunction<IRef, Props> = (props, ref) => {
  const dispatch = useDispatch();
  const { provinceFlat } = useSelector((state: any) => state.space);
  const {
    prop,
    address,
    onConfirm,
    quoteOptions,
    autoOpen = true,
    style,
    disabled,
    addQuoteTheme,
    quoteTemplate,
    onCancel,
    getPopupContainer = true,
    controlVisible = true,
  } = props;
  const [provData, setProvData] = useState<AreaData[]>([]);
  const [curProvince, setCurProvince] = useState<AreaData | null>(null);
  const [curCity, setCurCity] = useState<AreaData | null>(null);
  const [curDistrict, setCurDistrict] = useState<AreaData | null>(null);
  const [curStreet, setCurStreet] = useState<AreaData | null>(null);
  const [streetsData, setStreetsData] = useState<AreaData[]>([]);
  const [curAddress, setCurAddress] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [visible, setVisible] = useState(autoOpen);
  const [searchData, setSearchData] = useState<
    { name: string; prov: AreaData; city: AreaData; dist: AreaData | null }[]
  >([]);
  const [addressTab, setAddressTab] = useState('quote');
  const provRef = useRef<HTMLDivElement>(null);
  const cityRef = useRef<HTMLDivElement>(null);
  const distRef = useRef<HTMLDivElement>(null);
  const streetRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (visible) {
      // 需要一个延迟用来避免
      requestAnimationFrame(() => {
        provRef.current?.scrollIntoView({ block: 'nearest', inline: 'nearest' });
        cityRef.current?.scrollIntoView({ block: 'nearest', inline: 'nearest' });
        distRef.current?.scrollIntoView({ block: 'nearest', inline: 'nearest' });
        streetRef.current?.scrollIntoView({ block: 'nearest', inline: 'nearest' });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, provRef.current]);

  const close = () => {
    setVisible(false);
  };

  useImperativeHandle(ref, () => ({
    close,
  }));

  useEffect(() => {
    if (Array.isArray(address) && address.length) {
      setCurAddress(address[0].add || '');
      if (address[0].provCode) {
        const prov = provData.find((p) => p.adcode === address[0].provCode);
        if (prov) {
          setCurProvince(prov);
          if (address[0].cityCode) {
            const city = prov.districts?.find((c) => c.adcode === address[0].cityCode);
            if (city) {
              setCurCity(city);
              if (address[0].distCode) {
                const dist = city.districts?.find((d) => d.adcode === address[0].distCode);
                if (dist) {
                  setCurDistrict(dist);
                  onStreetChange(dist.adcode, address[0].street || '');
                }
              } else if (!city.districts?.length) {
                setCurDistrict(null);
                onStreetChange(city.adcode, address[0].street || '');
              }
            }
          }
        }
      }
    }
  }, [address, provData]);
  useEffect(() => {
    fetchProvData();
  }, [1]);

  useDebounceEffect(
    () => {
      const data: { name: string; prov: AreaData; city: AreaData; dist: AreaData | null }[] = [];
      if (searchInput) {
        provinceFlat
          .filter((x: { prov: AreaData; city: AreaData; dist: AreaData | null }) => {
            return prop.accuracy === 'province'
              ? x.prov.name.includes(searchInput)
              : prop.accuracy === 'city'
                ? x.prov.name.includes(searchInput) || x.city.name.includes(searchInput)
                : x.prov.name.includes(searchInput) ||
                  x.city.name.includes(searchInput) ||
                  x.dist?.name.includes(searchInput);
          })
          .forEach((x: { prov: AreaData; city: AreaData; dist: AreaData | null }) => {
            const name =
              prop.accuracy === 'province'
                ? x.prov.name
                : prop.accuracy === 'city'
                  ? x.prov.name.concat(x.city ? '/' + x.city.name : '')
                  : x.prov.name.concat(
                      x.city ? '/' + x.city.name : '',
                      x.dist ? '/' + x.dist.name : '',
                    );
            if (!~data.findIndex((d) => d.name === name))
              data.push({
                ...x,
                name: name,
              });
          });
      }
      setSearchData(data);
    },
    [searchInput],
    {
      wait: 500,
    },
  );

  const checkChanged = (newAddress: Address) => {
    let changed = false;
    if (!Array.isArray(address) || !address.length) return !!newAddress.provCode;
    changed = newAddress.provCode !== address[0].provCode;
    if (changed || prop.accuracy === 'province') return changed;
    changed = newAddress.cityCode !== address[0].cityCode;
    if (changed || prop.accuracy === 'city') return changed;
    changed = newAddress.distCode !== address[0].distCode;
    if (changed || prop.accuracy === 'district') return changed;
    changed = newAddress.street !== address[0].street;
    if (changed || prop.accuracy === 'street') return changed;
    return newAddress.add !== address[0].add;
  };

  const fetchProvData = () => {
    dispatch({
      type: 'space/fetchProvData',
    }).then((res: AreaData[]) => setProvData(res));
  };

  const [cellWidth, tableWidth] = useMemo(() => {
    switch (prop.accuracy) {
      case 'province':
        return [418, 418];
      case 'city':
        return [224, 448];
      case 'district':
        return [154, 462];
      default:
        return [154, 624];
    }
  }, [prop.accuracy]);

  const [hasCity, hasDistrict, hasStreet, hasAddress] = useMemo(() => {
    let hc = false,
      hd = false,
      hs = false,
      ha = false;
    if (prop.accuracy !== 'province') {
      hc = true;
      if (prop.accuracy !== 'city') {
        hd = true;
        if (prop.accuracy !== 'district') {
          hs = true;
          if (prop.accuracy !== 'street') {
            ha = true;
          }
        }
      }
    }

    return [hc, hd, hs, ha];
  }, [prop.accuracy]);

  const onStreetChange = (adcode: string, streetName = '') => {
    setStreetsData([]);
    dispatch({
      type: 'space/fetchDistData',
      payload: {
        adcode: adcode,
      },
    }).then((res: AreaData[]) => {
      if (!Array.isArray(res)) return;
      setStreetsData(res);
      setCurStreet(res.find((s) => s.name === streetName) || null);
    });
  };

  const provinceList = (
    <div
      key="province"
      className={`address-pop-area-ul${prop.accuracy !== 'province' ? ' bordered' : ''}`}
    >
      <div
        className={`address-pop-area-ul-header ${prop.accuracy === 'province' ? 'only' : 'left'}`}
        style={{ width: cellWidth }}
      >
        省份
      </div>
      <PerfectScrollbar style={{ height: 256 }} options={{ suppressScrollX: true }}>
        <div className={'address-pop-area-ul-list'} style={{ width: cellWidth }}>
          {provData.map((province) => (
            <div
              className={`address-pop-area-ul-list-item${
                province.adcode === curProvince?.adcode ? ' active' : ''
              }`}
              ref={province.adcode === curProvince?.adcode ? provRef : undefined}
              onClick={() => {
                // if (prop.accuracy === 'province') {
                //   handleNoAddConfirm(province);
                //   return;
                // }
                if (province.adcode !== curProvince?.adcode) {
                  setCurProvince(province);
                  setCurCity(null);
                  setCurDistrict(null);
                  setCurStreet(null);
                  setStreetsData([]);
                }
              }}
              key={province.adcode}
            >
              {province.name}
            </div>
          ))}
        </div>
      </PerfectScrollbar>
    </div>
  );

  const cityList = hasCity ? (
    <div key="city" className={`address-pop-area-ul${prop.accuracy !== 'city' ? ' bordered' : ''}`}>
      <div
        className={`address-pop-area-ul-header${prop.accuracy === 'city' ? ' right' : ''}`}
        style={{ width: cellWidth }}
      >
        城市
      </div>
      <PerfectScrollbar style={{ height: 256 }} options={{ suppressScrollX: true }}>
        <div className={'address-pop-area-ul-list'} style={{ width: cellWidth }}>
          {curProvince?.districts?.map((city) => (
            <div
              key={city.adcode}
              ref={city.adcode === curCity?.adcode ? cityRef : undefined}
              className={`address-pop-area-ul-list-item text-omit${
                city.adcode === curCity?.adcode ? ' active' : ''
              }`}
              onClick={() => {
                // if (prop.accuracy === 'city') {
                //   handleNoAddConfirm(curProvince, city);
                //   return;
                // }
                if (city.adcode !== curCity?.adcode) {
                  setCurCity(city);
                  setCurDistrict(null);
                  setCurStreet(null);
                  setStreetsData([]);
                  if (!city.districts?.length) onStreetChange(city.adcode);
                }
              }}
            >
              {city.name}
            </div>
          ))}
        </div>
      </PerfectScrollbar>
    </div>
  ) : null;

  const districtList = hasDistrict ? (
    <div
      key="district"
      className={`address-pop-area-ul${prop.accuracy !== 'district' ? ' bordered' : ''}`}
    >
      <div
        className={`address-pop-area-ul-header${prop.accuracy === 'district' ? ' right' : ''}`}
        style={{ width: cellWidth }}
      >
        区县
      </div>
      <PerfectScrollbar style={{ height: 256 }} options={{ suppressScrollX: true }}>
        <div className={'address-pop-area-ul-list'} style={{ width: cellWidth }}>
          {curCity?.districts?.map((district) => (
            <div
              key={district.adcode}
              className={`address-pop-area-ul-list-item text-omit${
                district.adcode === curDistrict?.adcode ? ' active' : ''
              }`}
              ref={district.adcode === curDistrict?.adcode ? distRef : undefined}
              onClick={() => {
                // if (prop.accuracy === 'district') {
                //   handleNoAddConfirm(curProvince, curCity, district);
                //   return;
                // }
                if (district.adcode !== curDistrict?.adcode) {
                  setCurDistrict(district);
                  onStreetChange(district.adcode);
                }
              }}
            >
              {district.name}
            </div>
          ))}
        </div>
      </PerfectScrollbar>
    </div>
  ) : null;

  const streetList = hasStreet ? (
    <div key="street" className={'address-pop-area-ul'}>
      <div className={'address-pop-area-ul-header right'} style={{ width: cellWidth }}>
        街道
      </div>
      <PerfectScrollbar style={{ height: 256 }} options={{ suppressScrollX: true }}>
        <div className={'address-pop-area-ul-list'} style={{ width: cellWidth }}>
          {streetsData.map((street) => (
            <div
              key={street.name}
              ref={street.name === curStreet?.name ? streetRef : undefined}
              className={`address-pop-area-ul-list-item text-omit${
                street.name === curStreet?.name ? ' active' : ''
              }`}
              onClick={() => {
                // if (prop.accuracy === 'street') {
                //   handleNoAddConfirm(curProvince, curCity, curDistrict, street);
                //   return;
                // }
                if (street.name !== curStreet?.name) {
                  setCurStreet(street);
                }
              }}
            >
              {street.name}
            </div>
          ))}
        </div>
      </PerfectScrollbar>
    </div>
  ) : null;

  const onClickSearch = (x: {
    name: string;
    prov: AreaData;
    city: AreaData;
    dist: AreaData | null;
  }) => {
    setSearchInput('');
    // if (!hasStreet) {
    //   handleNoAddConfirm(x.prov, x.city, x.dist);
    //   return;
    // }
    setCurProvince(x.prov);
    if (hasCity) {
      setCurCity(x.city);
      if (x.dist === null) {
        setCurDistrict(null);
        setCurStreet(null);
        onStreetChange(x.city.adcode);
      }
    }
    if (hasDistrict && x.dist) {
      setCurDistrict(x.dist);
      setCurStreet(null);
      onStreetChange(x.dist.adcode);
    }
  };

  const onClickQuote = (x: any) => {
    const location: Address = Array.isArray(x.l) ? x.l[0] : x.l;
    if (checkChanged(location)) {
      onConfirm(location);
      confirmDebounce();
    }
  };

  const handleNoAddConfirm = (
    prov: AreaData | null,
    city?: AreaData | null,
    dist?: AreaData | null,
    street?: AreaData | null,
  ) => {
    if (!prov) return;
    const center =
      hasStreet && street
        ? street.center
        : hasDistrict && dist
          ? dist.center
          : hasCity && city
            ? city.center
            : prov.center;
    const newAddress = {
      prov: prov.name,
      provCode: prov.adcode,
      city: hasCity && city ? city.name : '',
      cityCode: hasCity && city ? city.adcode : '',
      dist: hasDistrict && dist ? dist.name : '',
      distCode: hasDistrict && dist ? dist.adcode : '',
      street: hasStreet && street ? street.name : '',
      add: '',
      lat: center ? Number(center.split(',')[1]) : null,
      lng: center ? Number(center.split(',')[0]) : null,
    };
    if (checkChanged(newAddress)) {
      onConfirm(newAddress, close);
    }
  };

  const quoteSelect = (
    <PerfectScrollbar
      style={{ height: prop.accuracy === 'address' ? 370 : 296 }}
      options={{ suppressScrollX: true }}
    >
      {quoteOptions?.length ? (
        <div className={'address-pop-search'} style={{ width: tableWidth }}>
          {quoteOptions
            .filter((x) => !propIsNull(x.l) && x.v?.includes(searchInput))
            .map((x) => {
              // const index = x.v.indexOf(searchInput);
              const location = Array.isArray(x.l) ? x.l[0] : x.l;
              return (
                <div
                  key={x.l}
                  className={`address-pop-search-item text-omit${
                    checkChanged(location) ? '' : ' active'
                  }`}
                  onClick={() => onClickQuote(x)}
                >
                  {location.prov.concat(
                    location.city ? '/' + location.city : '',
                    location.dist ? '/' + location.dist : '',
                    location.street ? '/' + location.street : '',
                    location.add ? ' ' + location.add : '',
                  )}
                </div>
              );
            })}
        </div>
      ) : (
        <div />
      )}
    </PerfectScrollbar>
  );

  const addressSelect = (
    <Fragment>
      {searchInput ? (
        <PerfectScrollbar style={{ height: 296 }} options={{ suppressScrollX: true }}>
          {searchData.length ? (
            <div className={'address-pop-search'} style={{ width: tableWidth }}>
              {searchData.map((x) => {
                const index = x.name.indexOf(searchInput);
                return (
                  <div
                    key={x.name}
                    className={'address-pop-search-item text-omit'}
                    onClick={() => onClickSearch(x)}
                  >
                    {x.name.substring(0, index)}
                    <span style={{ color: '#316ef5' }}>{searchInput}</span>
                    {x.name.substring(index + searchInput.length)}
                  </div>
                );
              })}
            </div>
          ) : (
            <div className={'address-pop-empty'} style={{ width: tableWidth }}>
              没有符合条件的地区
            </div>
          )}
        </PerfectScrollbar>
      ) : (
        <div className={'address-pop-area'}>
          {provinceList}
          {cityList}
          {districtList}
          {streetList}
        </div>
      )}
      {prop.accuracy === 'address' ? (
        <div className={'address-pop-add'}>
          <TextArea
            placeholder={'请输入详细的地址信息'}
            value={curAddress}
            onChange={(e) => {
              setCurAddress(e.target.value);
            }}
          />
        </div>
      ) : null}
      <div style={{ margin: '10px 0', textAlign: 'right' }}>
        <Button
          style={{ marginRight: 10 }}
          onClick={() => {
            setVisible(false);
            onCancel && onCancel();
          }}
        >
          取消
        </Button>
        <Button
          type="primary"
          onClick={() => {
            confirmDebounce();
          }}
        >
          确认
        </Button>
      </div>
    </Fragment>
  );

  const popContent = () => {
    return (
      <div className={'address-pop'} style={{ width: tableWidth }}>
        <div className={'address-pop-header'}>
          <Input
            placeholder={'请输入省份/城市/区县搜索'}
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            allowClear
            suffix={<i className={'iconfont iconsearch'} style={{ color: '#EBEDF0' }} />}
          />
          {placeHolder ? (
            <i className={'iconfont iconButton_cancel'} onClick={resetAddress} />
          ) : null}
        </div>
        {prop.conditionMatching ? (
          <div className={'address-pop-tab'}>
            <div
              className={`address-pop-tab-item${addressTab === 'quote' ? ' active' : ''}`}
              onClick={() => setAddressTab('quote')}
            >
              一次性引用
            </div>
            <div
              className={`address-pop-tab-item${addressTab === 'address' ? ' active' : ''}`}
              onClick={() => setAddressTab('address')}
            >
              选择其他地址
            </div>
          </div>
        ) : null}
        {prop.conditionMatching && addressTab === 'quote' ? quoteSelect : null}
        {!prop.conditionMatching || addressTab === 'address' ? addressSelect : null}
        {addQuoteTheme && prop.matchingAdd ? (
          <div
            className={'address-pop-quote'}
            onClick={() => {
              addQuoteTheme();
              confirmDebounce();
            }}
          >
            <i className="iconfont iconButton_add" />
            <div style={{ width: 36 }}>新增</div>
            <span className={'address-pop-quote-name text-omit'}>{quoteTemplate}</span>
          </div>
        ) : null}
      </div>
    );
  };

  const placeHolder = defaultTo('')(curProvince?.name).concat(
    curCity ? curCity.name : '',
    curDistrict ? curDistrict.name : '',
    curStreet ? curStreet.name : '',
    curProvince || curCity || curDistrict || curStreet ? curAddress : '',
  );

  const resetAddress = () => {
    setCurProvince(null);
    setCurCity(null);
    setCurDistrict(null);
    setCurStreet(null);
    setStreetsData([]);
    setCurAddress('');
  };

  const handleConfirm = () => {
    if (prop.required && !prop.conditionMatching) {
      if (!curProvince) return message.warn('请选择省份');
      if (hasCity && !curCity) return message.warn('请选择城市');
      if (hasDistrict && !curDistrict && curCity?.districts?.length)
        return message.warn('请选择区县');
      if (hasStreet && !curStreet && streetsData.length) return message.warn('请选择街道');
      if (hasAddress && !curAddress) return message.warn('请输入详细地址');
    }
    const center = curStreet?.center || curCity?.center || curCity?.center || curProvince?.center;
    const newAddress = {
      prov: curProvince?.name || '',
      provCode: curProvince?.adcode || '',
      city: curCity?.name || '',
      cityCode: curCity?.adcode || '',
      dist: curDistrict?.name || '',
      distCode: curDistrict?.adcode || '',
      street: curStreet?.name || '',
      add: curAddress,
      lat: center ? Number(center.split(',')[1]) : null,
      lng: center ? Number(center.split(',')[0]) : null,
    };
    if (checkChanged(newAddress)) {
      onConfirm(newAddress, close);
    } else {
      onCancel && onCancel(close);
    }
    setVisible(false);
  };

  const inner = (
    <div className={'address-input-display'} style={style}>
      <div className={'address-input-display-name text-omit'}>{placeHolder || '请选择'}</div>
    </div>
  );

  const confirmDebounce = useDebounce(() => {
    if (!disabled) handleConfirm();
  }, 100);

  if (disabled) {
    return inner;
  }

  return (
    <LinkPiPop
      getPopupContainer={getPopupContainer}
      trigger={['click', 'focus']}
      content={popContent()}
      visible={visible}
      // onVisibleChange={(e: boolean) => {
      //   if (!e) {
      //     confirmDebounce();
      //   } else {
      //     setVisible(e);
      //   }
      // }}
      onVisibleChange={(e: boolean) => {
        setVisible(e);
        onCancel && onCancel();
      }}
      insideDom={inner}
    />
  );
};

export default forwardRef<IRef, Props>(AddressInput);
