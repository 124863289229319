import { GetterPiNode } from '@linkpi/core/web';
import { Popover } from 'antd';
import { useState } from 'react';

import NewNodeProp from '@/pages/home/components/NewNodeProp';

import styles from './styles.less';

interface IProps {
  attrGroupConfig: any;
  setAttrGroupConfig: (v: any) => void;
  currentUser: any;
}

export default (props: IProps) => {
  const { attrGroupConfig, setAttrGroupConfig, currentUser } = props;
  if (!attrGroupConfig) return null;

  const { node, unitPosition, groupProps } = attrGroupConfig;

  const [open, setOpen] = useState<boolean>(true);
  const changeOpen = (v: boolean) => {
    if (!v) return setAttrGroupConfig(null);
    setOpen(v);
  };

  const renderContent = () => {
    return (
      <div style={{ width: unitPosition.width }} className={styles.container}>
        <NewNodeProp
          node={new GetterPiNode(node)}
          isSimpleThemeTree={false}
          currentUser={currentUser}
          simpleThemeTreeWidth={0}
          setEditedProps={() => {}}
          mode="edit"
          isDrafts={false}
          draftParentId={null}
          nodePropConfig={{
            showGroup: false,
            style: 'default',
            propGrid: false,
          }}
          editPartPropsInNode={groupProps}
        />
      </div>
    );
  };

  return (
    <Popover
      placement="bottom"
      open={open}
      onOpenChange={changeOpen}
      content={renderContent()}
      overlayClassName={styles.popover}
      trigger="click"
    >
      <div
        style={{
          position: 'absolute',
          height: unitPosition.height,
          width: unitPosition.width,
          left: unitPosition.x,
          top: unitPosition.y,
        }}
      />
    </Popover>
  );
};
