import { cn } from '@/utils/utils';

interface TabsProps {
  active: number;
  onChange: (index: number) => void;
  list: { key: string; color?: string }[];
}

const Tabs = (props: TabsProps) => {
  const { active, onChange, list } = props;

  if (!list.length) {
    return null;
  }

  return (
    <div className="flex h-[30px] mb-4 shrink-0">
      {list.map((item, index) => (
        <span
          className={cn(
            'text-[14px] px-4 mr-4 text-[#767C88] flex items-center justify-center bg-[#F8F9FB] rounded-full cursor-pointer',
            {
              ['text-[#316EF5]']: active === index,
            },
          )}
          // eslint-disable-next-line react/no-array-index-key
          key={item.key + index}
          style={item.color ? { backgroundColor: item.color, color: 'white' } : undefined}
          onClick={() => onChange(index)}
        >
          {item.key}
        </span>
      ))}
    </div>
  );
};

export default Tabs;
