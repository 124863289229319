import { Modal } from 'antd';
interface DataType {
    q: string; //模板ID
    t: string; //操作类型
    ov: any; //历史值
    v: any; //修改值
    time: number; //操作时间
    ad: string[]; //操作人ID
    auto: string; //自动化ID
    i: number; // propIndex
  }
interface IProp {
  closeModal: () => void;
  record: DataType;
}
export default (prop: IProp) => {
  return (
    <Modal
      open
      title="公式详情"
      onCancel={prop.closeModal}
      footer={null}
      bodyStyle={{ padding: 0 }}
      width={548}
    >
      <div style={{ padding: '20px 20px' }}>
        <div style={{ marginBottom: 8, color: '#767C88' }}>运算公式：</div>
      </div>
    </Modal>
  );
};
