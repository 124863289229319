import type { PositionType } from '../components/_baseComponent';
import GridBaseComponent from '../components/_baseComponent';
import type { PiGridModules } from '../core';
import type { RowType } from '../modules/dataManager';

class FixCollapseCell extends GridBaseComponent {
  public data: RowType<'group'>;
  public type: string = 'collapseCell';

  constructor(position: PositionType, moduleInstances: PiGridModules, data: RowType<'group'>) {
    super(position, moduleInstances);
    this.data = data;
  }

  public click() {
    this.moduleInstances.DataManager.changeCollapseState(this.data.groupKey);
  }

  public render() {
    const { Draw, Render, DataManager } = this.moduleInstances;
    const { ctx } = Draw;
    const { groupKey, groupInfo } = this.data;
    const {
      collapseState,
      config: { userMap },
      groupMap,
    } = DataManager;

    const triangleHeight = 7;
    const triangleWidth = 7;

    Draw.fillRect(
      this.position.x,
      this.position.y,
      this.position.width,
      this.position.height,
      '#fafafa',
    );

    // 填充字
    Draw.attr({
      fillStyle: 'black',
      textBaseline: 'middle',
      textAlign: 'start',
      font: `${Draw.npx(14)}px sans-serif`,
    });

    const textX = Render.collapseRowTextGap + 2.5 * triangleWidth;
    const textY = this.position.y + this.position.height / 2;

    Draw.fillTriangle(
      {
        x: Render.collapseRowTextGap,
        y: textY - triangleHeight / 2,
        height: triangleHeight,
        width: triangleWidth,
      },
      '#6b7a96',
      collapseState[groupKey] ? 'right' : 'bottom',
    );

    let name = groupInfo.name;
    if (groupInfo.nameType === 'user' && name !== '未定义') {
      name = userMap[name]?.nick_name || '未知成员';
    }

    if (groupInfo.nameType === 'group') {
      name = groupMap[name]?.group_name || '未知成员组';
    }

    ctx.save();
    ctx.fillStyle = '#316ef5';
    Draw.fillText(name, textX, textY);
    ctx.restore();
  }
}

export default FixCollapseCell;
