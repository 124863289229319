import { createContext, useContext } from 'react';

import {
  useCurrentUser,
  useOrgConnection,
  useOrgInfo,
  useOrgTempMap,
  useOrgUserGroup,
  useOrgUserList,
  useOrgUserMap,
} from '@/hook';
import { useOrgDepartmentNodes } from '@/hook/useOrgStructure';

export const useNodePageModal = () => {
  const currentUser = useCurrentUser();
  const orgConnection = useOrgConnection();
  const [orgInfo] = useOrgInfo();
  const groups = useOrgUserGroup();
  const spaceUsers = useOrgUserList();
  const departmentNodes = useOrgDepartmentNodes();
  const userMap = useOrgUserMap();
  const tempMap = useOrgTempMap();

  return {
    currentUser,
    orgConnection,
    orgInfo,
    groups,
    spaceUsers,
    departmentNodes,
    userMap,
    tempMap,
  };
};

export const NodePageTypeContext = createContext<'theme' | 'view'>('theme');

export const useNodePageType = () => {
  return useContext(NodePageTypeContext);
};
