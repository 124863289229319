import type { PickerTimeProps } from 'antd/es/date-picker/generatePicker';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import * as React from 'react';

import DatePicker from './DatePicker';

dayjs.extend(utc);

export type TimePickerProps = Omit<PickerTimeProps<Dayjs>, 'picker'>;

const TimePicker = React.forwardRef<any, TimePickerProps>((props, ref) => {
  const dataProps = { ...props };
  if (props.value) {
    dataProps.value = dayjs(props.value).utc();
  } else {
    dataProps.defaultValue = props.defaultValue ? dayjs(props.defaultValue).utc() : dayjs().utc();
  }
  return <DatePicker {...dataProps} picker="time" mode={undefined} ref={ref} />;
});

TimePicker.displayName = 'TimePicker';

export default TimePicker;
