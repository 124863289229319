import {
  CloseCircleFilled,
  ExclamationCircleOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import type { ApiResponse } from '@linkpi/core';
import { isPiNode, THEME_STATUS } from '@linkpi/core';
import type { GetterPiNode } from '@linkpi/core/web';
import { useDispatch, useSelector } from '@umijs/max';
import { useMemoizedFn } from 'ahooks';
import type { ModalProps } from 'antd';
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Popover,
  Progress,
  Radio,
  Select,
  Spin,
  Upload,
} from 'antd';
import moment from 'moment';
import { isNil } from 'ramda';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { previewWebOffice, RegularIcon } from '@/components';
import LinkPiPop from '@/components/LinkPiPop';
import NodeImportHistory from '@/components/NodeImportHistory';
import { useCurrentSelection, useCurrentUser, useOrgUserList } from '@/hook';
import { useProgress } from '@/hook/useProgress';
import { getStatusItems } from '@/pages/home/components/TempStatus';
import request from '@/utils/request';
import { isTemplateAllowImport } from '@/utils/utils';

import './index.less';

const { Option } = Select;
const { confirm } = Modal;
let optionUsersMap: any = {};

export type NodeImportProp = {
  templateList: ApiResponse.CurrentUser.TemplateInfo[];
  orgInfo: ApiResponse.CurrentUser.OrgInfo;
  modalTitle?: ModalProps['title'];
  parentId?: string;
  node: GetterPiNode | PiNode;
  visible?: boolean;
  // 隐藏后的触发
  setVisible?: () => void;
  createView?: boolean;
  getViewList?: (...args: any[]) => void;
} & Pick<ModalProps, 'open' | 'visible' | 'onCancel' | 'onOk' | 'zIndex'>;

export const NodeImport: FC<NodeImportProp> = (props) => {
  const {
    modalTitle,
    templateList,
    visible: _visible,
    open,
    setVisible: hideModal,
    parentId,
    createView,
    getViewList,
    node: _node,
    orgInfo,
    zIndex,
    ...rest
  } = props;
  const visible = isNil(open) ? _visible : open;
  const node = isPiNode(_node) ? { value: _node } : _node;

  const setVisible = useMemoizedFn(() => {
    hideModal?.();
    rest.onCancel?.({} as unknown as any);
  });

  const { spaceUserList } = useSelector((state: any) => state.workspace);

  const currentSelection = useCurrentSelection();
  const currentUser = useCurrentUser();
  const spaceUsers = useOrgUserList();

  const dispatch = useDispatch();
  const [
    exportPercent,
    exportLoadingStatus,
    startExportLoading,
    stopExportLoading,
  ] = useProgress();
  const [form] = Form.useForm();
  const curParentId = parentId || currentSelection.selectNode;
  const [statusOptions, setStatusOptions] = useState<any[]>([]);
  const [fileName, setFileName] = useState('');
  const [statusVisible, setStatusVisible] = useState(false);
  const [statusItems, setStatusItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [excelLoading, setExcelLoading] = useState(false);
  const [importType, setImportType] = useState(0);
  const [importExist, setImportExist] = useState(1);
  const [showError, setShowError] = useState(false);
  const [headerError, setHeaderError] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  // 导入主题进度条modal
  const [showImportProgress, setShowImportProgress] = useState(false);
  // 导入文件名
  const [importFilename, setImportFilename] = useState('');
  // 导入总数
  const [importTotal, setImportTotal] = useState(0);
  // 导入进度finished
  const [importFinished, setImportFinished] = useState(0);
  // 导入进度failed
  const [importFailed, setImportFailed] = useState(0);
  // 错误详情
  const [importUrl, setImportUrl] = useState('');
  //
  // 导入历史
  const [showNodeImportHistory, setShowNodeImportHistory] = useState(false);

  // 重置导入的一些参数
  const initImportDetail = () => {
    setImportUrl('');
    setImportFailed(0);
    setImportFinished(0);
    setImportTotal(0);
    setImportFilename('');
  };

  useEffect(() => {
    form.resetFields();
    setStatusOptions([]);
    setFileName('');
    setStatusVisible(false);
    setStatusItems([]);
  }, [visible]);

  useEffect(() => {
    const userList = spaceUserList[currentSelection.selectSpace];
    optionUsersMap = userList || {};
  }, [currentSelection.selectSpace, spaceUserList]);

  const handleUpload = (e) => {
    if (e?.file?.size && e.file.size / 1024 / 1024 > 10) {
      form.resetFields(['nodeExcel']);
      setFileName('');
      return;
    }
    if (fileName !== e?.file?.name) {
      setFileName(e?.file?.name || '');
      if (
        typeof e?.file?.name === 'string' &&
        ~e.file.name.indexOf('.') &&
        ~e.file.name.split('.')[0]?.indexOf('_')
      ) {
        const fileName = e.file.name.split('.')[0];
        let template_id = fileName.substring(fileName.lastIndexOf('_') + 1);
        if (template_id.indexOf(' ')) {
          template_id = template_id.split(' ')[0];
        }
        const template = templateList.find(
          (x) => x.template_id === template_id || x.name === template_id,
        );
        if (
          template &&
          (template.status === THEME_STATUS.init ||
            template.status === THEME_STATUS.lock)
        ) {
          form.setFieldsValue({
            template: template.template_id,
          });
          handleTemplateChange(template.template_id);
        } else {
          // message.warn('未识别的主题类型');
        }
      }
    }
  };

  const handleImport = async () => {
    if (statusItems.length === 0 || statusVisible || importType) {
      form.submit();
    } else {
      if (!form.getFieldValue('template') || !form.getFieldValue('nodeExcel')) {
        await form.validateFields();
        return;
      }
      setStatusVisible(true);
    }
  };

  const handleTemplateChange = (e: any) => {
    checkExcelHeaders(e);
    let options = [
      ...(templateList.find((x) => x.template_id === e)?.task_status || []),
    ];
    options.map((x: any, i) => {
      x.index = i;
      if (!('sort' in x)) {
        x.sort = i;
      }
    });
    options = options.filter((x) => !x?.delete);
    options.sort((b, a) => b.sort - a.sort);
    setStatusOptions(options);
    if (options?.length) {
      form.setFieldsValue({
        status: options[0].index,
      });
      fetchStatusItems(options[0].index);
    } else {
      setStatusItems([]);
    }
  };

  const checkExcelHeaders = async (template_id: string) => {
    const file = form.getFieldValue('nodeExcel')?.file;
    if (file) {
      const originFileObj = await file.originFileObj.arrayBuffer();
      let dataString = '';
      const reg2 = new RegExp(/(XLSX|CSV)/g);
      const fileName = file.originFileObj.name.replace(reg2, function ($1) {
        return $1.toLocaleLowerCase();
      });
      const fileNameInfo = fileName.split('.');
      const fileType = fileNameInfo[fileNameInfo.length - 1];
      if (fileType !== 'xlsx' && fileType !== 'csv') {
        message.error('表格类型有误');
        setLoading(false);
        return;
      }
      try {
        const fileData = new Uint8Array(originFileObj);
        for (let i = 0; i < fileData.length; i++) {
          dataString += String.fromCharCode(fileData[i]);
        }
        const importReq = {
          org_id: currentSelection.selectSpace,
          file_data: window.btoa(dataString),
          file_type: fileType,
          template_id,
        };
        const res = await request('/docapi/import/check', {
          method: 'POST',
          data: importReq,
        });
        setHeaderError(res?.status !== 'ok');
      } catch (e) {}
    }
  };

  const fetchStatusItems = (e: any) => {
    const template_id = form.getFieldValue('template');
    const template = templateList.find(
      (x: any) => x.template_id === template_id,
    );
    if (
      !template ||
      !Array.isArray(template?.task_status) ||
      !template.task_status[e]
    ) {
      setStatusItems([]);
      return;
    }
    const startTime = moment().valueOf(),
      endTime = null;
    const items = getStatusItems(
      currentSelection.selectSpace,
      template.task_status[e],
      spaceUsers,
      optionUsersMap,
      startTime,
      endTime,
      null,
      currentUser,
    );
    setStatusItems(items as unknown as any);
  };

  const exportTemplateExcel = async (template_id: string) => {
    if (excelLoading) return;
    setExcelLoading(true);
    const res = await request<string>('/api/organization/getTemplateExcel', {
      method: 'POST',
      data: {
        org_id: currentSelection.selectSpace,
        template_id: template_id,
      },
    });
    setExcelLoading(false);
    if (res?.status === 'ok') {
      window.open(res.data);
    } else {
      message.error('导出失败');
    }
  };

  const handleStatusCancel = () => {
    confirm({
      title: '状态属性未保存，主题未成功导入，是否放弃导入',
      icon: <ExclamationCircleOutlined />,
      width: 455,
      onOk() {
        setVisible();
      },
      onCancel() {},
    });
  };
  //上传文件前判断文件大小
  const beforeUpload = (file: File) => {
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      message.error('文件大小不能超过10MB!');
    }
    return isLt10M;
  };

  const exportNodeExcel = async (template_id: string) => {
    if (exportLoadingStatus) return;
    startExportLoading();
    const res = await request<string>('/docapi/template/export', {
      method: 'POST',
      data: {
        org_id: currentSelection.selectSpace,
        template_id: template_id,
        node_id: currentSelection.selectNode,
      },
    });
    if (res?.status === 'ok' && res.data) {
      window.open(res.data);
    } else {
      message.error(res.message || '导出失败');
    }

    stopExportLoading();
  };

  const templateMenus = (
    <PerfectScrollbar>
      <ul className="menu-list">
        {templateList
          ?.filter(
            (t) =>
              t.status === THEME_STATUS.init || t.status === THEME_STATUS.lock,
          )
          .map((x, i) => (
            <li
              key={i}
              className="template-item"
              onClick={() => exportNodeExcel(x.template_id)}
            >
              <span className="template-title text-omit" title={x.name}>
                {x.name}
              </span>
            </li>
          ))}
      </ul>
    </PerfectScrollbar>
  );

  // 判断一个主题是否存在去重
  const isTemplateExistUnique = (t) => {
    return (t?.temp_option?.unique || []).flat().length > 0;
  };

  // 轮询查看导入的进度
  const getImportProgress = async (async_id: string, total: number) => {
    const { status, data } = await request<any>('/docapi/getImportProgress', {
      method: 'POST',
      data: { total, async_id },
    });

    if (status === 'ok') {
      const { finished, url, failed } = data;
      setImportFinished(finished);
      if (url === undefined) {
        setTimeout(() => {
          getImportProgress(async_id, total);
        }, 1200);
      } else {
        setImportUrl(url || '--');
        setImportFailed(failed || 0);
      }
    } else {
      setTimeout(() => {
        getImportProgress(async_id, total);
      }, 1200);
    }
  };

  // 导入错误信息
  const showImportErrorDetail = () => {
    previewWebOffice({
      url: importUrl,
      fileName: importFilename,
      orgId: currentSelection.selectSpace,
      zIndex,
    });
  };

  const onFinish = async (values: any) => {
    const currentTemplate = templateList.find(
      (x: any) => x.template_id === values.template,
    );
    if (
      importType !== 1 &&
      importExist &&
      !isTemplateAllowImport(
        currentTemplate,
        node.value.nodeManager.getRoot(),
        orgInfo,
      )
    )
      return message.warning('该主题不允许被导入');
    // if (!currentTemplate) {
    //   message.error('模板不存在');
    //   return;
    // }
    const file = values.nodeExcel?.file;
    if (!file) {
      message.error('请先上传表格');
      return;
    }
    setLoading(true);
    let task_status = null;
    if (!!currentTemplate && 'status' in values && importType === 0) {
      const status_prop: any[] = [];
      currentTemplate.task_status[values.status]?.prop.map(() => {
        status_prop.push(null);
      });
      Object.keys(values).map((x) => {
        if (values[x]) {
          if (x.startsWith('prop')) {
            const index = Number(x.split('prop')[1]);
            status_prop[index] = currentTemplate.task_status[
              values.status
            ]?.prop[index]?.type.startsWith('date')
              ? values[x].valueOf()
              : values[x];
          }
        }
      });
      // if (!!status_prop.find((x) => x !== null)) {
      task_status = {
        index: values.status,
        prop: status_prop,
        sender: currentUser.userid,
        sendTime: new Date().getTime(),
      };
      // }
    }
    const reg2 = new RegExp(/(XLSX|CSV)/g);
    const originFileObj = await file.originFileObj.arrayBuffer();
    let dataString = '';
    const fileName = file.originFileObj.name.replace(reg2, function ($1) {
      return $1.toLocaleLowerCase();
    });
    const fileNameInfo = fileName.split('.');
    const fileType = fileNameInfo[fileNameInfo.length - 1];
    if (fileType !== 'xlsx' && fileType !== 'csv') {
      message.error('表格类型有误');
      setLoading(false);
      return;
    }
    try {
      const fileData = new Uint8Array(originFileObj);
      for (let i = 0; i < fileData.length; i++) {
        dataString += String.fromCharCode(fileData[i]);
      }
      const importReq: any = {
        org_id: currentSelection.selectSpace,
        parentId: curParentId,
        file_type: fileType,
      };
      if (!!currentTemplate && importType === 0) {
        importReq.template_id = values.template;
      }
      if (task_status) {
        importReq._sys_task_status = task_status;
      }
      if (!importReq.template_id && createView) {
        importReq.view_type = 2;
      }
      importReq.file_data = window.btoa(dataString);

      //  导入慢速模式
      if (
        currentTemplate &&
        isTemplateExistUnique(currentTemplate) &&
        importType === 0
      ) {
        const { async_id, total, status, message } = await request<null, any>(
          '/docapi/template/import',
          {
            method: 'POST',
            data: { ...importReq, enableUnique: true, file_name: fileName },
          },
        );
        setLoading(false);

        if (status !== 'ok') {
          console.log('check_upload error ' + message);
          return setShowError(true);
        }

        // 进度条渲染
        setShowImportProgress(true);
        setImportFilename(fileName);
        setImportTotal(total);
        setTimeout(() => {
          getImportProgress(async_id, total);
        }, 1200);

        return;
      }
      const res = await request<any>('/docapi/template/import', {
        method: 'POST',
        data: importReq,
      });
      if (res?.status === 'ok') {
        message.success('导入成功');
        if (res.data?.viewId) {
          getViewList(res.data.viewId);
        }
        setLoading(false);
        setVisible();
        if (!currentTemplate) {
          dispatch({
            type: 'user/fetchCurrent',
          });
        }
      } else {
        console.log('check_upload error ' + res.message);
        setShowError(true);
      }
    } catch (e) {
      // this.setState({ loading: false });
      console.log('upload exception : ' + e.message);
      message.error('导入失败');
    }
    setLoading(false);
  };

  const changeSearchKey = (e) => setSearchKey(e.target.value);

  const showImportHistory = () => {
    setShowNodeImportHistory(true);
  };

  const isInChildTemp = (
    t1: ApiResponse.CurrentUser.TemplateInfo,
    t2: ApiResponse.CurrentUser.TemplateInfo,
  ) => {
    const childTemp = t2?.childTemp;
    if (!childTemp || childTemp.allowAll) return true;

    const allowTemps = childTemp.allow || [];
    return allowTemps.includes(t1.template_id);
  };

  return (
    <Modal
      className="node-import-modal"
      title={statusVisible ? '填写状态属性' : modalTitle}
      open={visible}
      onOk={() => handleImport()}
      cancelText={statusVisible ? '上一步' : '取消'}
      onCancel={() => (statusVisible ? handleStatusCancel() : setVisible())}
      okButtonProps={{ loading: loading }}
      width={600}
      bodyStyle={{ height: 534 }}
      maskClosable={false}
      footer={null}
      centered={true}
      zIndex={zIndex}
    >
      <Modal
        footer={null}
        open={showImportProgress}
        closable={!!importUrl}
        maskClosable={false}
        zIndex={zIndex}
        onCancel={() => {
          setShowImportProgress(false);
          initImportDetail();
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img
            style={{ width: 60, height: 60 }}
            src="https://www.mylinkpi.com/icon/excel.png"
          />
          <div style={{ color: '#B7BAC0', marginBottom: 24 }}>
            {importFilename}
          </div>
          <div style={{ width: '100%' }}>
            {!importUrl && `导入中 ${importFinished}/${importTotal}`}
            {importUrl && (
              <div style={{ display: 'flex' }}>
                <span>导入成功：{importTotal - importFailed}条</span>
                {!!importFailed && (
                  <div>
                    <span style={{ color: 'red', padding: '0 6px' }}>
                      导入失败：{importFailed}条
                    </span>
                    <span
                      onClick={() => showImportErrorDetail()}
                      style={{
                        color: '#1779F9',
                        textDecoration: 'underline',
                        cursor: 'pointer',
                      }}
                    >
                      查看失败详情
                    </span>
                  </div>
                )}
              </div>
            )}
          </div>
          <Progress percent={Math.ceil((importFinished * 100) / importTotal)} />
        </div>
      </Modal>
      <Spin
        spinning={exportLoadingStatus}
        wrapperClassName="schedule-loading"
        indicator={
          <Progress
            type="circle"
            percent={exportPercent}
            status={exportLoadingStatus}
            strokeColor={{
              '0%': '#87d068',
              '100%': '#316ef5',
            }}
          />
        }
      >
        <div
          style={{
            marginBottom: 24,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Radio.Group
            className={`${statusVisible && 'import-hidden'}`}
            buttonStyle="solid"
            value={importType}
            onChange={(e) => {
              setImportType(e.target.value);
              if (e.target.value === 1) {
                setStatusItems([]);
              }
            }}
          >
            <Radio.Button value={0}>导入新增</Radio.Button>
            <Radio.Button value={1}>导入覆盖</Radio.Button>
          </Radio.Group>
          <div
            onClick={showImportHistory}
            style={{
              cursor: 'pointer',
              color: '#4078F5',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <i
              style={{ color: '#4078F5', paddingRight: 6 }}
              className="iconProperty_time iconfont"
            />
            <span>导入历史</span>
          </div>
        </div>

        <Form
          form={form}
          onFinish={onFinish}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
        >
          <div className={`${statusVisible && 'import-hidden'}`}>
            {importType === 1 ? (
              <>
                <div className="import-description">1. 导出主题</div>
                <div className="import-tips">
                  - 导出主题后，可在表格中进行批量修改
                </div>
                <div className="import-tips">
                  - 用*作后缀，标记要修改的模版属性/状态列
                  <strong>（注：系统仅会覆盖更新标*的列）</strong>
                </div>
                <Popover
                  placement={'bottomLeft'}
                  overlayClassName="optionMorePop option-main-pop"
                  content={templateMenus}
                  trigger={'click'}
                >
                  <div className="export-btn">
                    <i className={'iconfont icondoachu'} /> 导出子主题
                  </div>
                </Popover>
                <div
                  className="import-description"
                  style={{ margin: '40px 0 12px 0' }}
                >
                  2. 上传表格
                </div>
              </>
            ) : (
              <>
                <div className="import-exist">
                  <Radio.Group
                    className={`${statusVisible && 'import-hidden'}`}
                    value={importExist}
                    onChange={(e) => {
                      setImportExist(e.target.value);
                    }}
                  >
                    <Radio value={1}>现有主题类型表格</Radio>
                    <Radio value={0}>自定义表格</Radio>
                  </Radio.Group>
                </div>
                <div className="import-tips" style={{ marginBottom: 24 }}>
                  {importExist
                    ? '- 从现有主题类型中下载表格模板后，添加完自己的信息再回传'
                    : '- 自由定义主题，导入成功后将添加至主题类型'}
                </div>
              </>
            )}
            {importType === 0 && importExist === 1 ? (
              <LinkPiPop
                trigger={'click'}
                insideDom={
                  <div className="export-btn import-download">
                    <i className={'iconfont iconTopic_Download'} /> 下载主题类型
                  </div>
                }
                placement={'bottomLeft'}
                menus={templateList
                  .filter(
                    (t) =>
                      t.name.indexOf(searchKey) !== -1 &&
                      node &&
                      isInChildTemp(t, node.value.template) &&
                      isTemplateAllowImport(
                        t,
                        node.value.nodeManager.getRoot(),
                        orgInfo,
                      ),
                  )
                  .filter(
                    (t) =>
                      t.status === THEME_STATUS.init ||
                      t.status === THEME_STATUS.lock,
                  )
                  .map((template, i) => {
                    return {
                      label: template.name,
                      handler: () => exportTemplateExcel(template.template_id),
                      addonHandler: () =>
                        exportTemplateExcel(template.template_id),
                      labelAddon: (
                        <>
                          <i className="iconfont iconTopic_Download" />
                          下载
                        </>
                      ),
                    };
                  })}
                menuWidth={361}
                menuMaxHeight={300}
                noPadding={true}
                extraComponent={
                  templateList.filter(
                    (t) =>
                      t.status === THEME_STATUS.init ||
                      t.status === THEME_STATUS.lock,
                  ).length <= 7 ? null : (
                    <Input
                      className="searchInput"
                      prefix={<SearchOutlined />}
                      value={searchKey}
                      onChange={changeSearchKey}
                      allowClear
                    />
                  )
                }
              />
            ) : null}
            <Form.Item
              name="nodeExcel"
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: '请上传表格',
                },
              ]}
            >
              <Upload.Dragger
                name="nodeExcel"
                maxCount={1}
                accept=".xlsx,.csv"
                showUploadList={false}
                onChange={handleUpload}
                beforeUpload={beforeUpload}
              >
                <p className="ant-upload-drag-icon">
                  {fileName ? (
                    <RegularIcon size={48} type={'iconexcel'} />
                  ) : (
                    <RegularIcon size={48} type={'iconTopic_Upload'} />
                  )}
                </p>
                {fileName ? (
                  <>
                    <p>{fileName}</p>
                    <p className="upload-hint">
                      <span style={{ color: '#316EF5' }}>重新上传</span>
                    </p>
                  </>
                ) : (
                  <>
                    <p className="upload-hint">
                      点击
                      <span className="upload-hint-button">上传文件</span>
                      或拖拽文件进行上传，支持格式：XLSX、XLS
                    </p>
                    {importExist || importType ? (
                      <p className="upload-hint">请勿修改原有主题类型的表头</p>
                    ) : (
                      <LinkPiPop
                        insideDom={
                          <p className="upload-hint illustration">
                            上传说明
                            <QuestionCircleOutlined />
                          </p>
                        }
                        placement={'right'}
                        content={
                          <img
                            src={
                              'https://www.mylinkpi.com/webImg/importllustration.png'
                            }
                            width={504}
                            style={{ height: 626 }}
                          />
                        }
                        overlayInnerStyle={{ padding: 24 }}
                      />
                    )}
                  </>
                )}
              </Upload.Dragger>
            </Form.Item>
            {headerError ? (
              <div className="import-header-error">
                请上传现有主题类型的表格
              </div>
            ) : null}
            {importType === 0 && importExist === 1 ? (
              <>
                {/*<div className="import-description">2. 选择/下载主题类型（可选）</div>
                <div className="import-tips">
                  - 完善导出的主题类型表格后再上传则生成对应主题类型的主题
                </div>
                <div className="import-tips">- 直接上传表格将按照表格创建主题类型并生成主题</div>*/}
                <div className="template-import">
                  <Form.Item
                    name="template"
                    wrapperCol={{ span: 24 }}
                    style={{ width: '45%', marginRight: '5%' }}
                    rules={[
                      {
                        required: true,
                        message: '请选择主题类型',
                      },
                    ]}
                  >
                    <Select
                      placeholder="请选择主题类型"
                      onChange={handleTemplateChange}
                      optionLabelProp="label"
                      showSearch={true}
                      optionFilterProp="label"
                    >
                      {templateList
                        .filter(
                          (t) =>
                            t.status === THEME_STATUS.init ||
                            t.status === THEME_STATUS.lock,
                        )
                        .map((template, i) => (
                          <Option
                            key={i}
                            value={template.template_id}
                            label={template.name}
                          >
                            {template.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                  {statusOptions.length > 0 && (
                    <Form.Item
                      name="status"
                      style={{ width: '45%' }}
                      wrapperCol={{ span: 24 }}
                      rules={[
                        {
                          required: true,
                          message: '请选择状态',
                        },
                      ]}
                    >
                      <Select
                        placeholder="请选择状态"
                        onChange={fetchStatusItems}
                        showSearch={true}
                        optionFilterProp="label"
                      >
                        {statusOptions.map((status, i) => (
                          <Option
                            key={i}
                            value={status.index}
                            label={status.name}
                          >
                            {status.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                </div>
              </>
            ) : (
              <></>
            )}
          </div>

          <div className={`${!statusVisible && 'import-hidden'}`}>
            {statusItems}
          </div>
        </Form>
        <div className="import-footer">
          <div className="footer-buttons">
            <Button
              className="footer-btn"
              onClick={() =>
                statusVisible ? setStatusVisible(false) : setVisible()
              }
            >
              {statusVisible ? '上一步' : '取消'}
            </Button>
            <Button
              type={'primary'}
              loading={loading}
              className="footer-btn"
              onClick={() => handleImport()}
              disabled={headerError}
            >
              确定
            </Button>
          </div>
        </div>
      </Spin>
      <Modal
        zIndex={zIndex}
        className="import-err-modal"
        title={
          <div className="err-title">
            <CloseCircleFilled className="err-title-icon" />
            导入失败，请检查是否符合表格导入要求
          </div>
        }
        open={showError}
        onCancel={() => setShowError(false)}
        footer={null}
        width={455}
        bodyStyle={{ padding: 0, height: 265 }}
        getContainer={false}
        centered={true}
      >
        <img
          src={'https://www.mylinkpi.com/webImg/importErr0.png'}
          width={455}
        />
      </Modal>

      {showNodeImportHistory && (
        <NodeImportHistory
          zIndex={zIndex}
          org_id={currentSelection.selectSpace}
          node_id={curParentId}
          closeModal={() => setShowNodeImportHistory(false)}
          templateList={templateList}
          spaceUserList={spaceUserList}
        />
      )}
    </Modal>
  );
};
