import type { ApiResponse } from '@linkpi/core';

import { tableFixCellPadding } from '@/utils/utils';

import type { statConfigType, statDataType } from '../core';
import { isEmptyObject } from '../helper/utils';
import type { GridAttrItem } from '../react/useGridAttrs';
import BaseModule from './_baseModule';

type RowTypeKeys = 'node' | 'group' | 'addRow' | 'statisticsRow';

interface BaseRowType {
  type: RowTypeKeys;
  index: number;
  y: number;
  height: number;
  groupKey: string;
}

interface NodeRowType extends BaseRowType {
  node: PiNode;
  parentNode?: PiNode;
  handleNowTemplateColConfig?: {
    nowChildNodeIndex: number;
    height: number;
  };
}

interface GroupRowType extends BaseRowType {
  groupInfo: ViewGroupDataItem;
}
type AddRowType = BaseRowType;

interface StatisticsRowType {
  groupKey: string;
  y: number;
  height: number;
  type: 'statisticsRow';
}

type TemplateMap = Record<string, ApiResponse.CurrentUser.TemplateInfo>;

type GroupMap = Record<string, ApiResponse.CurrentUser.orgGroup>;

export type RowType<U extends RowTypeKeys> = U extends 'node'
  ? NodeRowType
  : U extends 'group'
    ? GroupRowType
    : U extends 'statisticsRow'
      ? StatisticsRowType
      : AddRowType;

export interface ColType {
  width: number;
  name: string;
  config: any;
  stat: any; // TODO
  sorter: any; // TODO
}

export interface SelectNodeAttr {
  groupKey?: string;
  headerKey?: string;
  indexInGroup?: number;
  nodeId?: string;
}

export interface HeaderCellType extends GridAttrItem {
  x: number;
  y: number;
  height: number;
}

export type SortInfo = Record<string, { sort: 'asc' | 'desc' }>;

type collapseType = Record<string, boolean>;

let NODE_HEIGHT = 44;
const GROUP_HEIGHT = 44;
const HEADER_HEIGHT = 44;
const FOOTER_HEIGHT = 44;
const STATISTICSROW_HEIGHT = 30;
const CUSTOM_NODE_WIDTH = 200;

export default class DataManager extends BaseModule {
  public virtualList: RowType<any>[] = [];
  public fixVirtualList: any[] = [];
  public collapseState: collapseType = {};
  public collapseChildNodes: collapseType = {};
  public headerCells: Array<HeaderCellType> = [];
  public parentNodeCells: any[] = [];
  public childNodeCellsMap: any = {};
  public selectRow: string = '';
  public hoverHeaderCellKey: string = '';
  public hoverCollapseGroupFunctionCell: string = '';
  public hoverFooterFunctionCell: string = '';
  public hoverRow: string = '';
  public nodeIds: string[] = [];
  public childIdMap: any = {};
  public sortInfo: SortInfo = {};
  // 初次进来
  public firstComing: boolean = true;
  // 统计
  public statData: statDataType = {};
  public statConfig: statConfigType = {};
  // 用户最近的一次checkbox
  public preCheckId: string | null = null;
  public freshHeaderCellWidthMap = true;
  // 选中的点
  public selectNodeAttr: SelectNodeAttr = {};

  // templateMap
  public templateMap: TemplateMap = {};
  // groupMap
  public groupMap: GroupMap = {};

  // 单元格宽度
  public headerCellWidthMap: any = {};
  // 当前正在拖拽的属性
  public resizingAttr: any = null;

  public totalHeight: number = 0;
  public contentWidth: number = 0;

  // 选中节点的信息
  public checkedNodesDetail: any = true;
  // nodecol map 每个属性格子的组件
  public nodeColMap: any = {};
  public isEdittingProp: boolean = false;
  // 节点树层id map
  public treeLevelIdMap: any = {};

  // 是否当前的分组都是折叠状态
  public isAllGroupsCollapsed = false;

  public foldNodesPool: any[] = [];
  public hideNodesMap: any = {};

  public get hasGroup() {
    return !(
      Object.keys(this.config.groupData).length === 1 &&
      this.config.groupData.default &&
      !this.config.groupData.default.name
    );
  }

  public get existStatistics() {
    return !isEmptyObject(this.statConfig);
  }

  public get nodeSum() {
    return Object.values(this.config.groupData).reduce((sum, item) => sum + item.list.length, 0);
  }

  // sort
  public changeSortInfo(groupKey: string) {
    const sort = this.sortInfo[groupKey]?.sort;
    if (!sort) this.sortInfo[groupKey] = { sort: 'asc' };

    if (sort === 'asc') this.sortInfo[groupKey].sort = 'desc';

    if (sort === 'desc') delete this.sortInfo[groupKey];
    this.moduleInstances.Render.render();
  }

  // 判断是否所有分组都折叠了
  public getIsAllGroupsCollapsed() {
    const { groupData } = this.config;
    const { collapseState } = this;

    let isAllGroupsCollapsed = true;

    Object.keys(groupData).forEach((k) => {
      if (!collapseState[k]) isAllGroupsCollapsed = false;
    });

    return isAllGroupsCollapsed;
  }

  // 处理数据
  public prepare() {
    // 计算列属性（要在计算列表之前）
    this.getColCells();
    // 计算列表
    this.getVirtualList();
    // templateMap
    this.templateMap = this.config.templateList.reduce((res, cur) => {
      res[cur.template_id] = cur;
      return res;
    }, {} as TemplateMap);
  }

  public updateData() {
    // this.collapseState = {};
    // this.statData = {};
    // this.statConfig = {};
    if (this.config.mode === 'custom') this.getColCells();

    if (this.isAllGroupsCollapsed) this.updateCollapse(false);

    this.getVirtualList();
    this.moduleInstances.Render.render();
  }

  public updateTableAttrs() {
    // this.collapseState = {};
    // this.statData = {};
    // this.statConfig = {};

    this.getColCells();
    this.getVirtualList();
    this.moduleInstances.Render.render();
  }

  public updateNodeHeight() {
    this.getVirtualList();
    this.moduleInstances.Render.render();
  }

  public updateGroups(groups: ApiResponse.CurrentUser.orgGroup[]) {
    this.groupMap = groups.reduce((res, cur) => {
      res[cur.group_id] = cur;
      return res;
    }, {} as GroupMap);
    this.moduleInstances.Render.render();
  }

  public updateLocalTableConfig() {
    this.getColCells();
    this.moduleInstances.Render.render();
  }

  public updateCheckId() {
    this.checkedNodesDetail = this.getCheckedNodesDetail();
    this.config.setCheckedNodesDetail(this.checkedNodesDetail);
    this.config.setCustomTableNodeMap(this.childIdMap);
    this.moduleInstances.Render.render();
  }

  public changeCollapseState(groupKey: string) {
    this.collapseState[groupKey] = !this.collapseState[groupKey];

    let existCollapse = false;

    Object.values(this.collapseState).forEach((b) => {
      if (b) existCollapse = true;
    });

    this.config.afterCollapseGroup(!existCollapse);

    this.isAllGroupsCollapsed = this.getIsAllGroupsCollapsed();

    this.config.storageGroupCollapse(this.collapseState);
    this.getVirtualList();
    this.moduleInstances.Render.render();
  }

  public setCollapseState(v: any) {
    this.collapseState = v;

    let existCollapse = false;

    Object.values(this.collapseState).forEach((b) => {
      if (b) existCollapse = true;
    });

    this.config.afterCollapseGroup(!existCollapse);

    this.isAllGroupsCollapsed = this.getIsAllGroupsCollapsed();

    this.config.storageGroupCollapse(this.collapseState);
    this.getVirtualList();
    this.moduleInstances.Render.render();
  }

  public changeCollapseChildNodes(ids: string[]) {
    ids.forEach((id) => {
      this.collapseChildNodes[id] = !this.collapseChildNodes[id];
    });
    this.getFixVirtualList();
    this.moduleInstances.Render.render();
  }

  public refreshChildNodes() {
    this.moduleInstances.ChildNodesLoader.cache = {};
    this.getFixVirtualList();
    this.moduleInstances.Render.render();
  }

  // 折叠 展开
  public updateCollapse(collapse: boolean) {
    if (collapse) {
      this.collapseState = {};
      this.isAllGroupsCollapsed = false;
    }

    if (!collapse) {
      this.isAllGroupsCollapsed = true;
      this.collapseState = Object.keys(this.config.groupData).reduce((map, key) => {
        map[key] = true;
        return map;
      }, {} as collapseType);
    }

    this.config.storageGroupCollapse(this.collapseState);
    this.getVirtualList();
    this.moduleInstances.Render.render();
  }

  public edittingProp() {
    const { groupKey, headerKey, indexInGroup } = this.selectNodeAttr;
    if (!headerKey || this.isEdittingProp) return;
    const nodeCol = this.nodeColMap[`${groupKey}&${indexInGroup}&${headerKey}`];
    if (nodeCol) nodeCol.click();
  }

  public changeSelectNodeAttr(direction: 'up' | 'left' | 'down' | 'right') {
    if (this.isEdittingProp) return;
    const { groupKey, indexInGroup = 0, headerKey } = this.selectNodeAttr;
    if (!groupKey) return;

    const {
      headerCells,
      virtualList,
      config: { groupData },
      collapseState,
    } = this;

    // 未折叠的groupKeys
    const notCollapseGroupKeys = virtualList.filter(
      (v) => v.type === 'group' && !collapseState[v.groupKey],
    );
    const nowGroupKeyIndex = notCollapseGroupKeys.findIndex((v) => v.groupKey === groupKey);

    // nowHeaderKeyIndex
    const nowHeaderKeyIndex = headerCells.findIndex((v) => v.key === headerKey);
    const currentHeaderKeyIndex =
      direction === 'left'
        ? nowHeaderKeyIndex - 1
        : direction === 'right'
          ? nowHeaderKeyIndex + 1
          : nowHeaderKeyIndex;

    // 边界
    if (currentHeaderKeyIndex === headerCells.length) return;
    if (headerCells[currentHeaderKeyIndex].key === 'index') return;

    // indexInGroup
    let currentGroupKey = groupKey;
    let currentIndexInGroup =
      direction === 'up'
        ? indexInGroup - 1
        : direction === 'down'
          ? indexInGroup + 1
          : indexInGroup;

    if (currentIndexInGroup === -1) {
      // 上一个 groupKey
      if (nowGroupKeyIndex < 1) return;

      currentGroupKey = notCollapseGroupKeys[nowGroupKeyIndex - 1].groupKey;
      currentIndexInGroup = groupData[currentGroupKey].list.length - 1;
    }

    if (currentIndexInGroup === groupData[groupKey].list.length) {
      // 下一个 groupKey
      if (nowGroupKeyIndex === notCollapseGroupKeys.length - 1) return;

      currentGroupKey = notCollapseGroupKeys[nowGroupKeyIndex + 1].groupKey;
      currentIndexInGroup = 0;
    }

    this.selectNodeAttr = {
      indexInGroup: currentIndexInGroup,
      groupKey: currentGroupKey,
      headerKey: headerCells[currentHeaderKeyIndex].key,
    };

    this.selectRow = `${currentGroupKey}/${currentIndexInGroup}`;

    const nodeCol =
      this.nodeColMap[
        `${currentGroupKey}&${currentIndexInGroup}&${headerCells[currentHeaderKeyIndex].key}`
      ];
    if (nodeCol && ['text', 'number'].includes(headerCells[currentHeaderKeyIndex].type))
      nodeCol.click();

    const {
      Render,
      Draw: { canvasHeight, canvasWidth },
      HorizontalScrollbar,
      VerticalScrollbar,
    } = this.moduleInstances;
    const { x, width } = headerCells[currentHeaderKeyIndex];
    const row = virtualList.find(
      (v: any) =>
        v.groupKey === currentGroupKey && v.index === currentIndexInGroup && v.type === 'node',
    );

    const currentLeft = HorizontalScrollbar.getScrollLeft();
    const currentTop = VerticalScrollbar.getScrollTop();
    const fixedWidth = Render.getFixedWidth();

    // @ts-ignore
    const endY = row.y + this.config.nodeHeight;
    const endX = x + width;

    // 不在可视区域
    if (endY > currentTop + canvasHeight - 44) {
      VerticalScrollbar.move({
        top: endY - canvasHeight + 44,
      });
    }

    if (endX > currentLeft + canvasWidth) {
      HorizontalScrollbar.move({
        left: endX - canvasWidth,
      });
    }

    if (x < currentLeft + fixedWidth) {
      HorizontalScrollbar.move({
        left: x - fixedWidth,
      });
    }

    this.moduleInstances.Render.render();
  }

  // 判断选中的节点的类型, 用于复制节点的限制
  // return true  没有限制
  // return 'parent' 父节点
  // return {} 子节点，只允许勾选当前父节点下的子节点
  public getCheckedNodesDetail() {
    const {
      childIdMap,
      config: { checkId },
    } = this;
    const parentIds = Object.keys(childIdMap);
    const len = parentIds.length;

    const firstNodeId = checkId[0];
    // 没选中、普通表格 没有限制
    if (!firstNodeId || this.config.mode === 'default') return true;

    if (parentIds.includes(firstNodeId)) return 'parent';

    // 子节点
    const detail = { parentId: '' };
    for (let i = 0; i < len; i++) {
      if (childIdMap[parentIds[i]].includes(firstNodeId)) {
        detail.parentId = parentIds[i];
        break;
      }
    }
    return detail;
  }

  // 统计
  public updateStatData(statData: statDataType, statConfig: statConfigType) {
    this.statData = statData;
    this.statConfig = statConfig;

    this.getVirtualList();
    this.moduleInstances.Render.render();
  }

  /*
    计算定制表格的虚拟列表
  */
  public getFixVirtualList() {
    let nodeManager: any = null;
    // start
    const custom_view = this.config.viewTemplate?.custom_view;
    const customTable = this.getCustomTable();
    if (custom_view === null || custom_view?.data === null || !customTable) {
      this.fixVirtualList = [];
      this.moduleInstances.VerticalScrollbar.setInner(0);
      this.totalHeight = 0;
      return;
    }

    // 子区块数据默认展开方式
    const defaultExpandMode = customTable.defaultExpandMode || 'firstExpand';

    const demoChildAreas = customTable.secondaryAreasList;
    const demoAreas = customTable.areas;
    const nodeHeight = demoAreas[0][0].height;
    let childHeight = demoChildAreas[0] ? demoChildAreas[0][0].height : 0;
    // end

    const { groupData, viewTemplate } = this.config;
    const { ChildNodesLoader } = this.moduleInstances;
    const list: any[] = [];
    let index = 0;
    let y = 0;
    const ids: string[] = [];
    const idMap: any = {};

    // 隐藏新增按钮
    const hideAdd = this.config.viewInfo.hideAdd || this.config.forbiddenAddNode;

    Object.keys(groupData)
      .sort((a, b) => groupData[a].sort - groupData[b].sort)
      .map((groupKey, groupIndex) => {
        if (this.hasGroup) {
          list.push({
            type: 'group',
            groupKey,
            groupInfo: groupData[groupKey],
            index,
            y,
            height: GROUP_HEIGHT,
          });
          index++;
          y += GROUP_HEIGHT;
        }

        // node
        groupData[groupKey].list.forEach((node, index) => {
          nodeManager = node.nodeManager;
          ids.push(node.id); // 收集全部的节点id
          idMap[node.id] = []; // 子节点

          if (this.collapseState[groupKey]) return;
          if (this.firstComing) {
            this.firstComing = false;
            if (defaultExpandMode === 'firstExpand' && groupIndex === 0 && index === 0)
              this.changeCollapseChildNodes([node.id]);
            if (defaultExpandMode === 'allExpand') {
              this.changeCollapseChildNodes(
                Object.values(groupData).reduce(
                  (res: string[], group: any) => res.concat(group.list.map((n: any) => n.id)),
                  [],
                ),
              );
            }
          }

          let nodeGap = 1;
          if (index === 0) {
            nodeGap = 0;
          }

          // 节点 child节点的逻辑
          list.push({
            type: 'node',
            node,
            index,
            y: y + nodeGap,
            height: nodeHeight,
            groupKey,
          });
          index++;
          y += nodeHeight + nodeGap;

          if (!this.collapseChildNodes[node.id]) return;

          // childnode
          demoChildAreas.forEach((childArea, areaIndex) => {
            if (demoChildAreas[areaIndex]) {
              const firstNotNowTemplateArea = demoChildAreas[areaIndex].find(
                (area: any) => area.theme !== viewTemplate.template_id,
              );
              if (firstNotNowTemplateArea) childHeight = firstNotNowTemplateArea.height || 0;
              else childHeight = demoChildAreas[areaIndex][0].height;
            }
            // 如果子区块也是当前主题类型
            const childAreaIsNowTemplate = childArea.find(
              (c: any) => c.theme !== viewTemplate.template_id,
            );
            if (childAreaIsNowTemplate === undefined) {
              list.push({
                type: 'childNode',
                node,
                index,
                y,
                height: childHeight,
                groupKey,
                parentNode: node,
                areaIndex,
              });
              index++;
              y += childHeight;
              return;
            }

            const childNodes = ChildNodesLoader.getChildNodes(
              node,
              childAreaIsNowTemplate,
              areaIndex,
            );
            if (childNodes.length === 0) return;

            const maxNowTemplateInnerHeight = childArea.reduce((max: number, cur: any) => {
              if (cur.theme === viewTemplate.template_id) {
                return Math.max(max, cur.nowTemplateInnerHeight);
              }
              return max;
            }, 0);

            const childNodesLength = childNodes.length;
            const nowTemplateHeight =
              tableFixCellPadding[0] + tableFixCellPadding[2] + maxNowTemplateInnerHeight;
            const cHeight =
              childNodesLength * childHeight < nowTemplateHeight
                ? nowTemplateHeight / childNodesLength
                : childHeight;

            childNodes.forEach((childNode, nowChildNodeIndex) => {
              idMap[node.id].push(childNode.id);
              list.push({
                type: 'childNode',
                node: childNode,
                index,
                y,
                height: cHeight,
                groupKey,
                parentNode: node,
                areaIndex,
                // 留给合并单元格使用
                handleNowTemplateColConfig: {
                  height: childNodes.length * cHeight,
                  nowChildNodeIndex,
                },
              });
              index++;
              y += cHeight;
            });
          });
        });

        // 新建
        if (this.hasGroup && !this.collapseState[groupKey] && !hideAdd) {
          list.push({
            type: 'addRow',
            y,
            height: GROUP_HEIGHT,
            groupKey,
          });

          y += GROUP_HEIGHT;
        }
      });

    let noData = Object.keys(this.config.groupData).length === 0;
    if (
      Object.keys(this.config.groupData).length === 1 &&
      this.config.groupData.default &&
      this.config.groupData.default.list.length === 0
    ) {
      noData = true;
    }
    if ((!this.hasGroup || noData) && !hideAdd) {
      list.push({
        type: 'addRow',
        y,
        groupKey: noData ? 'noData' : 'default',
        height: GROUP_HEIGHT,
      });

      y += GROUP_HEIGHT;
    }

    this.fixVirtualList = list;
    this.nodeIds = ids;
    this.childIdMap = idMap;

    // 计算节点的树层ID
    const nodeIdList = Object.keys(idMap).reduce((res: string[], nodeId: string) => {
      res.push(nodeId);
      res.push(...idMap[nodeId]);

      return res;
    }, [] as string[]);

    /**
     * @NOTE
     */
    // if (nodeManager && nodeIdList.length) {
    //   this.treeLevelIdMap = nodeManager.getNodeTreeSerialNumber(nodeIdList);
    // }

    this.moduleInstances.VerticalScrollbar.setInner(y);
    this.totalHeight = y;
  }

  /**
   * 计算虚拟列表
   */
  public getVirtualList() {
    if (this.config.mode === 'custom') {
      this.getFixVirtualList();
      return;
    }
    const list: RowType<any>[] = [];
    let index = 0;
    let y = HEADER_HEIGHT;
    const ids: string[] = [];

    const {
      moduleInstances: {
        DataManager: { hideNodesMap },
      },
    } = this;
    const showLevelInFirstCol = this.config.viewInfo.showLevelInFirstCol;
    let groupData = this.config.groupData;
    if (showLevelInFirstCol) {
      groupData = {};

      Object.keys(this.config.groupData).forEach((k) => {
        const v = this.config.groupData[k];
        const newList = v.list.filter((n) => !hideNodesMap[n.id]);
        if (newList.length > 0) groupData[k] = { ...v, list: newList };
      });
    }

    // 隐藏新增按钮
    const hideAdd =
      this.config.viewInfo.hideAdd ||
      this.config.forbiddenAddNode ||
      this.config.viewInfo.allTemplates;
    // nodeHeight
    NODE_HEIGHT = this.config.nodeHeight || NODE_HEIGHT;
    Object.keys(groupData)
      .sort((a, b) => groupData[a].sort - groupData[b].sort)
      .map((groupKey) => {
        if (this.hasGroup) {
          list.push({
            type: 'group',
            groupKey: groupKey,
            groupInfo: groupData[groupKey],
            index: index,
            y: y,
            height: GROUP_HEIGHT,
          });
          index++;
          y += GROUP_HEIGHT;
        }

        // 统计行
        if (this.hasGroup && this.existStatistics) {
          list.push({
            type: 'statisticsRow',
            y: y,
            height: STATISTICSROW_HEIGHT,
            groupKey,
          });

          y += STATISTICSROW_HEIGHT;
        }

        groupData[groupKey].list.forEach((node, index) => {
          ids.push(node.id);
          if (this.collapseState[groupKey]) return;
          list.push({
            type: 'node',
            node: node,
            index: index,
            y: y,
            height: NODE_HEIGHT,
            groupKey: groupKey,
          });
          index++;
          y += NODE_HEIGHT;
        });

        if (this.hasGroup && !this.collapseState[groupKey] && !hideAdd) {
          list.push({
            type: 'addRow',
            y: y,
            height: NODE_HEIGHT,
            groupKey: groupKey,
          } as RowType<'addRow'>);

          y += NODE_HEIGHT;
        }
      });

    let noData = Object.keys(groupData).length === 0;
    if (
      Object.keys(groupData).length === 1 &&
      groupData.default &&
      groupData.default.list.length === 0
    ) {
      noData = true;
    }

    if ((!this.hasGroup || noData) && !hideAdd) {
      list.push({
        type: 'addRow',
        y: y,
        groupKey: noData ? 'noData' : 'default',
        height: NODE_HEIGHT,
      } as RowType<'addRow'>);

      y += NODE_HEIGHT;
    }

    this.virtualList = list;
    this.nodeIds = ids;
    this.moduleInstances.VerticalScrollbar.setInner(y - HEADER_HEIGHT);
    this.totalHeight = y + FOOTER_HEIGHT;
  }

  public getCustomTable() {
    const custom_view = this.config.viewTemplate?.custom_view || {};
    const customViewData = custom_view?.data || [];

    const customTableId = this.config.viewInfo.customTableId;
    // 兼容老的表格
    if (customTableId === '') return false;
    if (!customTableId) return customViewData[0];
    return customViewData.find((t: any) => t.id === customTableId);
  }

  /*
    计算定制的列的属性
  */
  public getFixColCells() {
    const custom_view = this.config.viewTemplate?.custom_view;
    const customTable = this.getCustomTable();
    if (custom_view === null || custom_view?.data === null || !customTable) {
      this.moduleInstances.HorizontalScrollbar.setInner(0);
      return;
    }
    const demoAreas = customTable.areas;
    let maxUnitCount = demoAreas[0].length;
    const twoLevelAreas = customTable.secondaryAreasList;

    twoLevelAreas.forEach((area) => {
      maxUnitCount = Math.max(maxUnitCount, area.length);
    });
    let maxWidth = maxUnitCount * CUSTOM_NODE_WIDTH;
    const gap = 0;
    maxWidth = Math.max(this.moduleInstances.Draw.canvasWidth - gap, maxWidth);

    let x = 0;

    twoLevelAreas.forEach((area, areaIndex) => {
      x = gap;
      this.childNodeCellsMap[areaIndex] = area.map((unit: any) => {
        const cell = {
          ...unit,
          x,
          cellWidth: (maxWidth * unit.width) / 100,
        };

        x += (maxWidth * unit.width) / 100;

        return cell;
      });
    });

    x = gap;
    this.parentNodeCells = demoAreas[0].map((unit: any) => {
      const cell = {
        ...unit,
        x,
        cellWidth: (maxWidth * unit.width) / 100,
      };

      x += (maxWidth * unit.width) / 100;

      return cell;
    });

    this.contentWidth = maxWidth + gap;
    this.moduleInstances.HorizontalScrollbar.setInner(maxWidth + gap);
  }

  /**
   * 计算列属性
   */
  public getColCells() {
    if (this.config.mode === 'custom') {
      this.getFixColCells();
      return;
    }
    const {
      localTableConfig,
      contentPageGeneralTableConfig: { maxTableHeight },
      viewInfo,
    } = this.config;

    const map = localTableConfig.reduce(
      (res, cur) => {
        res[cur.key] = cur.disable;
        return res;
      },
      {} as Record<string, boolean>,
    );

    // if (viewInfo.allTemplates) map = {};

    // 表头设置
    const headerCells: GridAttrItem[] = [
      {
        disable: false, // 正文表格不需要多选
        type: 'checkbox',
        name: 'checkbox',
        key: 'checkbox',
        config: null,
        width: 75,
        getData: () => null,
      },
      ...this.config.tableAttrs,
    ];

    let x = 0;

    this.headerCells = headerCells
      .map((col) => {
        const cell = {
          ...col,
          x,
          y: 0,
          height: NODE_HEIGHT,
          minWidth: col.width,
        };
        cell.width = this.headerCellWidthMap[col.key] || col.width;
        if (!map[col.key]) x += this.headerCellWidthMap[col.key] || col.width;

        return cell;
      })
      .filter((col) => {
        if (col.key.slice(0, 4) === 'prop') return map[col.key] === false;
        return !map[col.key];
      });

    // 和横向滚动条相关的计算
    this.moduleInstances.HorizontalScrollbar.setInner(
      this.moduleInstances.Render.getContentWidth(),
    );
  }
}
