import { DEFAULT_AVATAR, STATUS_ICON } from '@linkpi/core';
import { propIsNull } from '@linkpi/core';
import { getDateBeforePresent, getQuoteOriginProp, tempValueDisplay } from '@linkpi/core';
import { Popover } from 'antd';
import dayjs from 'dayjs';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import { useState } from 'react';

import { useOrgDepartmentNodesMap } from '@/hook/useOrgStructure';
import {
  getAttachmentValue,
  getCustomBtn,
  getFileTypeIconByUrl,
  getTemplateProp,
  transformFontSizeToAttachmentSize,
} from '@/utils/utils';

import styles from './styles.less';

interface IProps {
  unitDetail: any;
  setUnitDetail: (v: any) => void;
  templateList: any;
  userMap: any;
  templateMap: any;
}

const btnConfig: any = {
  large: { height: 40, padding: 16 },
  small: { height: 20, padding: 4 },
  middle: { height: 28, padding: 8 },
};

export default (props: IProps) => {
  const { unitDetail, setUnitDetail, templateList, userMap, templateMap } = props;
  if (!unitDetail) return null;

  const popoverMaxHeight = window.innerHeight / 2;

  const { node, unitPosition, unit } = unitDetail;

  const [open, setOpen] = useState<boolean>(true);
  const changeOpen = (v: boolean) => {
    if (!v) return setUnitDetail(null);
    setOpen(v);
  };

  // 状态属性
  const getCommonStatusPropValue = (template: any, node: PiNode, index: number) => {
    const status = template.task_status?.[node.tempInfo.status];
    const config = status ? status.prop[index] : null;
    return config && config.display && config.type !== 'quote'
      ? node.tempInfo.statusProp[index]
      : null;
  };

  // 判断属性是否是系统属性
  const checkSysOrStatusAttr = (index: string) => {
    return [
      'title',
      'sysTag',
      'status',
      'createTime',
      'treeLevelId',
      'statusProp_common_0',
      'statusProp_common_1',
      'statusProp_common_2',
      'statusProp_common_3',
      'statusProp_common_4',
    ].includes(index);
  };

  const renderCustomBtn = (value: any) => {
    const config = JSON.parse(value);
    let btn = config;
    if (btn.templateId) btn = getCustomBtn(config.templateId, config.btnId, templateList);
    const {
      text, // 按钮 文案
      size, // 按钮大小
      pictureUrl, // 按钮icon 是否是图片
      color, // 按钮背景色
      icon, // icon
      hideWebBackground,
      hideWebText,
    } = btn;

    const { height, padding } = btnConfig[size];
    let child = '';
    let txt = '';

    if (icon) {
      child = `<i class="iconfont ${icon}" style="margin-right: 2px;color: ${
        hideWebBackground ? color : 'white'
      }"></i>`;
    }
    if (pictureUrl) {
      child = `<span class="${styles.btnPicture}" style="background-image: url(${pictureUrl})"></span>`;
    }

    if (!hideWebText) {
      txt = `<span>${text}</span>`;
    }

    return `<span class="${styles.btn}" style="height: ${height}px;padding: ${padding}px;color: ${
      hideWebBackground ? color : 'white'
    };background-color: ${hideWebBackground ? 'transparent' : color};border-radius: 4px">
    ${child}
    ${txt}
    </span>`;
  };

  const getUserHtmls = (users: string[], userMap: any, fontColor: string, fontSize: string) => {
    let html = '';
    users.forEach((id) => {
      const user = userMap[id] || {
        avatar: DEFAULT_AVATAR,
        nick_name: '未知用户',
      };
      html += `<img style="height: 30px;width: 30px;border-radius: 30px;" src=${user.avatar}></img>`;
      html += `<span style="padding: 0 3px;font-size: ${fontSize};color: ${fontColor};">${user.nick_name}</span>`;
    });
    return html;
  };

  const departmentMap = useOrgDepartmentNodesMap();

  const getSysOrStatusConfig = (value: any) => {
    const { index, fontSize, fontColor } = value;
    const template = templateList.find((t: any) => t.template_id === node.tempInfo.id);

    // title
    if (index === 'title') return { value: node.title || '无标题' };

    // sysTag
    if (index === 'sysTag') {
      const tags =
        node.prop._sys_tag && typeof node.prop._sys_tag === 'object'
          ? Object.keys(node.prop._sys_tag)
          : [];
      return { value: tags.join('、') };
    }

    // 创建时间
    if (index === 'createTime') {
      const createTime = node.createTime ? dayjs(node.createTime).format('YYYY-MM-DD HH:mm') : '';
      return { value: createTime };
    }

    // 树id
    if (index === 'treeLevelId') {
      return { value: 'tree_id' };
    }

    // status
    if (index === 'status') {
      const statusIndex = node.tempInfo.status;
      const statusInfo = template?.task_status?.[statusIndex];
      if (!statusInfo) return { value: '' };

      // @ts-ignore
      const iconInfo = STATUS_ICON[statusInfo.icon];
      return {
        value: `
            <i style="margin-right: 3px;color: ${iconInfo.color};font-size: ${fontSize}" class="iconfont ${statusInfo.icon}"></i>
            <span style="color: ${fontColor};font-size: ${fontSize}">${statusInfo.name}</span>
            `,
      };
    }

    // 开始时间
    if (index === 'statusProp_common_2') {
      let time = getCommonStatusPropValue(template, node, 2);
      time = time ? dayjs(time).format('YYYY-MM-DD HH:mm') : '';
      return { value: time };
    }

    // 结束时间
    if (index === 'statusProp_common_3') {
      let time = getCommonStatusPropValue(template, node, 3);
      time = time ? dayjs(time).format('YYYY-MM-DD HH:mm') : '';
      return { value: time };
    }

    // 备注
    if (index === 'statusProp_common_4') {
      return {
        value: getCommonStatusPropValue(template, node, 4) || '无备注',
      };
    }

    // 负责人
    if (index === 'statusProp_common_0') {
      let users = getCommonStatusPropValue(template, node, 0);
      if (users === null) return { value: '' };
      users = Array.isArray(users) ? users : [users];
      users = users.filter((i: any) => i);
      if (!users.length) return { value: '' };
      return {
        value: getUserHtmls(users, userMap, fontColor, fontSize),
      };
    }

    // 参与者
    if (index === 'statusProp_common_1') {
      let users = getCommonStatusPropValue(template, node, 1);
      if (users === null) return { value: '' };
      users = Array.isArray(users) ? users : [users];
      users = users.filter((i: any) => i);
      if (!users.length) return { value: '' };
      return {
        value: getUserHtmls(users, userMap, fontColor, fontSize),
      };
    }
  };

  const getCustomAttrConfig = (value: any) => {
    const { index: pIndex, templateId, fontColor, fontSize } = value;

    const { tempInfo = {} } = node || {};
    let originValue = tempInfo.prop[pIndex];

    const prop = getTemplateProp(templateId, pIndex, templateList);
    if (!prop.type) return { value: '' };

    // user
    if (prop.type === 'user') {
      let users = Array.isArray(originValue) ? originValue : [originValue];
      users = users.filter((i: any) => i);
      if (!users.length) return { value: '' };
      return {
        value: getUserHtmls(users, userMap, fontColor, fontSize),
      };
    }

    // text
    if (['str', 'text', 'positioning'].includes(prop.type)) return { value: originValue };

    // enum
    if (['tag', 'enum'].includes(prop.type)) {
      if (propIsNull(originValue)) originValue = '';
      return {
        value: originValue instanceof Array ? originValue.join('、') : originValue,
      };
    }

    // number
    if (['number', 'formula', 'cascade'].includes(prop.type))
      return {
        value: tempValueDisplay({
          propConfig: prop,
          propValue: originValue,
          userMap: {},
          tempMap: {},
          departmentMap: {},
          propIndex: pIndex,
          sysCascade: node.prop._sys_cascade,
        }),
      };

    if (['date', 'datetime'].includes(prop.type)) {
      const format = prop.dateFormat || 'YYYY-MM-DD HH:mm';
      const timeStr = originValue ? dayjs(originValue).format(format) : '';
      const dateBeforePresent = getDateBeforePresent({ prop, value: originValue });
      if (!dateBeforePresent.display) return { value: timeStr };

      return {
        value: `
            <span style="font-size: ${fontSize};color: ${fontColor};padding-right: 6px;">${timeStr}</span>
            <i style="font-size: ${fontSize};color: ${dateBeforePresent.color};padding-right: 2px;" class="iconattribute_time iconfont"></i>
            <span style="font-size: ${fontSize};color: ${dateBeforePresent.color};">${dateBeforePresent.toString}</span>
          `,
      };
    }

    if (prop.type === 'attachment') {
      const files = getAttachmentValue(node, pIndex);
      if (!files.length) return { value: '' };
      const s = transformFontSizeToAttachmentSize(fontSize);

      let html = '';

      files.forEach((file) => {
        const { src } = file;
        const bgSrc = getFileTypeIconByUrl(src).src;
        html += `<span class="${styles.attachment}" style="background-image: url('${bgSrc}'); height: ${s}px;width: ${s}px">&nbsp;</span>`;
      });

      return { value: html };
    }

    // quote
    if (prop.type === 'quote') {
      const originProp: any = getQuoteOriginProp(prop, templateMap);
      if (!originProp) return { value: '' };
      if (originProp.type !== 'attachment')
        return {
          value: tempValueDisplay({
            propConfig: prop,
            propValue: originValue,
            propIndex: pIndex,
            userMap,
            tempMap: templateMap,
            departmentMap,
            sysCascade: node.prop._sys_cascade,
          }),
        };
    }
  };

  // 属性值
  const renderThemeAttrSpan = (value: any) => {
    console.log(value);
    const { index: pIndex, fontSize, fontColor } = value;
    const themeAttrConfig = checkSysOrStatusAttr(pIndex)
      ? getSysOrStatusConfig(value)
      : getCustomAttrConfig(value);

    return `<span style="font-size: ${fontSize};color: ${fontColor}">${themeAttrConfig?.value}</span>`;
  };

  const renderOps = () => {
    const {
      attrGroupContent: { ops },
    } = unit;
    const customTag = (format: string) => {
      if (format === 'ThemeLink') return 'a';
    };
    const customCssStyles = (op: any) => {
      const { attributes } = op;
      return `font-size: ${attributes.size || '14px'}`;
    };
    const converter = new QuillDeltaToHtmlConverter(ops, { customTag, customCssStyles });

    converter.renderCustomWith((customOp: any) => {
      const { insert } = customOp;
      const { type, value } = insert;

      if (type === 'CustomBtn') return renderCustomBtn(value);
      if (type === 'ThemeAttrSpan') return renderThemeAttrSpan(value);

      return '未定义的文件类型';
    });

    return (
      <div className={styles.opContent} dangerouslySetInnerHTML={{ __html: converter.convert() }} />
    );
  };

  const renderDetail = () => {
    return (
      <div
        style={{ width: unitPosition.width, maxHeight: popoverMaxHeight }}
        className={styles.container}
      >
        {renderOps()}
      </div>
    );
  };

  return (
    <Popover
      placement="bottom"
      open={open}
      onOpenChange={changeOpen}
      content={renderDetail()}
      overlayClassName={styles.popover}
      trigger="click"
    >
      <div
        style={{
          position: 'absolute',
          height: unitPosition.height,
          width: unitPosition.width,
          left: unitPosition.x,
          top: unitPosition.y,
        }}
      />
    </Popover>
  );
};
