import type { GetterPiNode } from '@linkpi/core/web';
import { usePiNode } from '@linkpi/core/web';
import type { FC } from 'react';
import { useMemo } from 'react';

import { ErrorBoundary } from '@/components';
import type { IWidgetInstanceData } from '@/components/PageModelEditor';
import { useViewConfig } from '@/hook/useOrgViewListRequest';
import type { DashBoardCardType } from '@/pages/home/components/View/DashBoard';
import DashBoardCard from '@/pages/home/components/View/DashBoard/DashBoardCard';

import { useNodePageModal } from '../../hooks';

import styles from './styles.less';

const mockProps = {
  index: 0,
  onRename: () => {},
  getNewCardLayout: () => {
    return {} as any;
  },
  dashboardEditPermission: false,
};

type IStatisticCard = {
  getterPiNode: GetterPiNode;
  data: IWidgetInstanceData & {
    widgetId:
      | 'PieChart'
      | 'LineChart'
      | 'HorizontalBarChart'
      | 'BarChart'
      | 'Indicator'
      | 'MultidimensionalStatistic';
  };
};

const EMPTY_FN = () => {};

export const StatisticCard: FC<IStatisticCard> = ({ data, getterPiNode }) => {
  const { currentUser, orgInfo, userMap, tempMap, groups, orgConnection } = useNodePageModal();

  const [rootNode] = usePiNode(orgConnection, orgConnection?.nodeManager._rootId);

  const mockViewData: any = useMemo(
    () => ({
      dashboard_info: {
        viewList: [],
      },
    }),
    [],
  );

  const viewId = data.config.statistic.viewId;
  const { data: viewConfig } = useViewConfig(viewId);

  const mockCardViewInfo = useMemo(() => {
    const templateId =
      data.config.statistic.filterType === 'relation'
        ? data.config.statistic.statTempId
        : data.config.statistic.conditions.find((i) => i.key === 'templateId')?.key;
    const stat = data.config.statistic.statType === 'count' ? null : data.config.statistic.statProp;
    const config = viewId ? viewConfig : {};

    return {
      config,
      viewId: viewId,
      name: data.config.name || data.config.statistic.name,
      stat,
      statTempId: templateId,
      layout: { x: 0, y: 0, w: 0, h: 0 },
    } as DashBoardCardType;
  }, [
    data.config.name,
    data.config.statistic.conditions,
    data.config.statistic.filterType,
    data.config.statistic.name,
    data.config.statistic.statProp,
    data.config.statistic.statTempId,
    data.config.statistic.statType,
    viewConfig,
    viewId,
  ]);

  if (!orgInfo || !rootNode || !mockCardViewInfo.config) return null;

  // if (data.config.statistic.type === 'chart' && isEmpty(mockCardViewInfo.config)) {
  //   console.log('🛠 图表配置错误，检查一下：', data);
  //   return null;
  // }

  return (
    <div className={styles.wrapper}>
      <ErrorBoundary type="sidebarCrash">
        <DashBoardCard
          {...mockProps}
          curViewData={mockViewData}
          node={getterPiNode}
          data={data}
          cardViewInfo={mockCardViewInfo}
          orgInfo={orgInfo}
          userMap={userMap}
          getViewList={EMPTY_FN}
          currentUser={currentUser}
          templateMap={tempMap}
          groups={groups}
        />
      </ErrorBoundary>
    </div>
  );
};
