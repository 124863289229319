export const ENV_CONFIG = {
  CLOUD_FN_URL: __CLOUD_FN_URL__,
  CLOUD_FN_URL_PROD: 'https://fc-http-g-fc-pyjmofcrlb.cn-hangzhou.fcapp.run',
  CLOUD_FN_URL_DEV: 'https://fc-http-g-fc-qdpkoecdnb.cn-hangzhou.fcapp.run',
  CLOUD_FN_URL_ISO1: 'https://lab.mylinkpi.com/api/mockFc',
  EXPORT_VIEW_NODES_FOR_MP:
    __EXPORT_VIEW_NODES_FOR_MP__ ||
    'https://1199845172035500.cn-hangzhou.fc.aliyuncs.com/2016-08-15/proxy/fc01/exportViewNodesForMP/',
  WX_SET_UNLIMITED:
    __WX_SET_UNLIMITED__ ||
    'https://1199845172035500.cn-hangzhou.fc.aliyuncs.com/2016-08-15/proxy/fc-public/wxSetUnlimited/',
  DING_TALK_WARNING:
    __DING_TALK_WARNING__ ||
    'https://1199845172035500.cn-hangzhou.fc.aliyuncs.com/2016-08-15/proxy/fc-public/dingTalkWarning/',
  EXPORT_PDF: __EXPORT_PDF__ || 'https://pdf-exporter-fc-public-fqnbrtywli.cn-hangzhou.fcapp.run',
};
