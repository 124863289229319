import type { CascaderProps, DatePickerProps, SelectProps, TreeSelectProps } from 'antd';
import type { FunctionComponent, ReactElement } from 'react';
import { Children, cloneElement, isValidElement, useCallback, useState } from 'react';

type WrappedElementProps = DatePickerProps | SelectProps | CascaderProps<unknown> | TreeSelectProps;
type WrappedElement = ReactElement<WrappedElementProps>;

type Props = {
  autoFocus?: boolean;
  children: WrappedElement;
  defaultOpen?: true;
};

const AutoFocus: FunctionComponent<React.PropsWithChildren<Props>> = ({
  children,
  autoFocus,
  defaultOpen,
}) => {
  if (!isValidElement(children)) throw new Error('not a valid children');

  const onOpenChange = useCallback(
    (o: boolean) => {
      children.props.onOpenChange?.(o);
      if (!o) {
        children.props.onBlur?.();
      }
    },
    [children.props],
  );

  const props: WrappedElementProps = {
    autoFocus,
    defaultOpen,
    onOpenChange,
  };

  return Children.only(cloneElement(children, props));
};

export default AutoFocus;
