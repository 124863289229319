type Node = Record<string, any>;

type Mapper<N = Node> = (
  n: N,
  level: number,
  parent: N | 'ROOT',
) => 'break' | 'continue' | null | undefined | void;

/**
 * 广度优先遍历
 */
export const traverseTreeCreator =
  <N extends Node = Node>(action: Mapper<N>, _options: { childrenKeyName?: string } = {}) =>
  (list: N[]) => {
    const childrenKeyName = _options.childrenKeyName || 'children';
    let stack = [...list];
    let level = 1;
    let currentLevelLength = list.length;
    let nextLevelLength = 0;
    const parentQueue: { length: number; ref: 'ROOT' | N }[] = [
      { ref: 'ROOT', length: list.length },
    ];

    while (stack.length) {
      const current = stack.shift()!;

      const parent = parentQueue[0];
      if (parent.length > 1) {
        parent.length -= 1;
      } else {
        parentQueue.shift();
      }

      // action
      const result = action(current, level, parent?.ref);
      currentLevelLength -= 1;
      if (result === 'break') {
        break;
      } else if (result === 'continue') {
        continue;
      }

      if (current[childrenKeyName]?.length) {
        stack = [...stack, ...current[childrenKeyName]];
        nextLevelLength += current[childrenKeyName].length;
        parentQueue.push({
          ref: current,
          length: current[childrenKeyName].length,
        });
      }

      if (currentLevelLength === 0) {
        [currentLevelLength, nextLevelLength] = [nextLevelLength, 0];
        level += 1;
      }
    }
  };
