import sha512 from 'sha512';

import request from '@/utils/request';

export async function userRegister(params) {
  params.userName = params.mobile;
  const password = params.password;
  params.password = sha512(`${password}${params.mail}LinkPi20200903!`).toString('hex');
  params.phonePassword = sha512(`${password}${params.mobile}LinkPi20200903!`).toString('hex');
  params.confirm = sha512(`${params.confirm}${params.userName}LinkPi20200903!`).toString('hex');
  return request('/api/register', {
    method: 'POST',
    data: params,
  });
}

export async function userInviteRegister(params) {
  params.userName = params.mobile;
  const password = params.password;
  params.registerSource = 'invite';
  params.password = sha512(`${password}${params.mail}LinkPi20200903!`).toString('hex');
  params.phonePassword = sha512(`${password}${params.mobile}LinkPi20200903!`).toString('hex');
  params.confirm = sha512(`${params.confirm}${params.userName}LinkPi20200903!`).toString('hex');
  return request('/api/login/account', {
    method: 'POST',
    data: params,
    credentials: 'same-origin',
  });
}
