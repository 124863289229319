import { CheckCircleFilled, PlusOutlined } from '@ant-design/icons';
import { generateAddOpId } from '@linkpi/core';
import { getDefaultTempProp } from '@linkpi/core';
import { useDispatch, useSelector } from '@umijs/max';
import { message } from 'antd';
import { nanoid } from 'nanoid';
import { useMemo } from 'react';

import { addDraftNode, DraftNodeModal } from '@/components/DraftNodeModal';
import { useOrgInfo, useOrgUserMap } from '@/hook';
import useFilterTemplateList from '@/hook/useFilterTemplateList';
import { useOrgDepartmentNodes } from '@/hook/useOrgStructure';
import { getAddInitData } from '@/pages/pi/grid/react/service';

import styles from './styles.less';

export default (props: any) => {
  const { template, curViewData, getterPiNode, templateList, userMap } = props;
  const componentId = useMemo(() => nanoid(), []);

  const { view_info } = curViewData;

  const hideAdd = view_info.hideAdd;

  const dispatch = useDispatch();
  const { currentSelection } = useSelector((state: any) => state.workspace);
  const currentUser = useSelector((state: any) => state.user.currentUser);
  const orgUserMap = useOrgUserMap();
  const departmentNodes = useOrgDepartmentNodes();
  const orgInfo = useOrgInfo()[0]!;
  const filterTemplateList = useFilterTemplateList(orgInfo);

  const addNode = async () => {
    if (!filterTemplateList.find((t) => t.template_id === template.template_id)) {
      return message.error('你不在当前主题类型的可见分组内，无法新建');
    }

    const { addInitData, parentId } = getAddInitData({
      node: getterPiNode,
      currentSelection,
      prevNodeId: null,
      curViewData,
      groupItem: null,
      viewTemplate: template,
      templateList,
      groupKey: '',
      userMap,
      userId: currentUser.userid,
    } as any);

    view_info.condition.forEach((c: any) => {
      if (c.key.slice(0, 12) === 'templateProp' && c.type === 'user') {
        // 单独处理当前用户
        const [_, tempId, propIndex] = c.key.split('_');
        if (c.value[0] === null) addInitData.initProp[propIndex] = currentUser.userid;
      }
    });

    const newProps = getDefaultTempProp(
      {},
      template,
      currentUser,
      null,
      orgUserMap,
      departmentNodes,
      addInitData,
    );
    const id = generateAddOpId();

    const draftNodeData = await addDraftNode(
      {
        mode: 'add',
        orgId: currentSelection.selectSpace,
        draftsNodeData: {
          org_id: currentSelection.selectSpace,
          parentId: getterPiNode.value.id,
          siblingId: null,
          draft: true,
          node: {
            node_id: id,
            prop: newProps as any,
            title: '',
          },
        },
        initParentId: getterPiNode.value.id,
      },
      componentId,
    );

    message.info({
      icon: <CheckCircleFilled style={{ color: '#52c41a' }} />,
      duration: 4,
      content: (
        <>
          <span>新增成功！</span>
          <span
            style={{ textDecoration: 'underline', cursor: 'pointer', color: '#316EF5' }}
            onClick={() => {
              dispatch({
                type: 'workspace/setCurrentSelection',
                payload: { selectNode: draftNodeData.node.node_id },
              });
            }}
          >
            跳转到该主题
          </span>
        </>
      ),
    });
  };

  if (hideAdd === 'all') return null;
  if (!filterTemplateList.find((t) => t.template_id === template.template_id)) return null;

  return (
    <>
      <div className={styles.btn} onClick={addNode}>
        <PlusOutlined />
        <div style={{ marginLeft: 6 }}>新增{template.name}</div>
      </div>
      {/* @ts-ignore */}
      <DraftNodeModal id={componentId} />
    </>
  );
};
