import type { SpaceProps } from 'antd';
import { Space } from 'antd';
import type { FunctionComponent } from 'react';

const VerticalSpace: FunctionComponent<React.PropsWithChildren<SpaceProps>> = (props) => (
  <Space className="full-w" direction="vertical" size={24} {...props}>
    {props.children}
  </Space>
);

export default VerticalSpace;
