import type Core from '../core';
import BaseModule from './_baseModule';

type Direction = 'vertical' | 'horizontal';

class Scrollbar extends BaseModule {
  public direction;

  constructor(core: Core, direction: Direction) {
    super(core);
    this.direction = direction;
  }

  // 纵向距离
  getScrollTop() {
    return this.doms.container.scrollTop;
  }

  // 横向距离
  getScrollLeft() {
    return this.doms.container.scrollLeft;
  }

  // 画滚动条
  prepare() {
    this.doms.container = document.createElement('div');
    this.doms.child = document.createElement('div');

    // 设置 className
    this.doms.container.className = `${this.direction}-container`;
    this.doms.child.className = `${this.direction}-child`;

    if (this.config.viewInfo.tableStyle === 'default')
      this.doms.container.className += ' specialHorizontal';

    // 加入界面中
    this.doms.container.appendChild(this.doms.child);
    const root =
      this.direction === 'horizontal'
        ? document.querySelector(`${this.config.container}-horizontal-scrollbar`) ||
          this.moduleInstances.Render.doms.container
        : this.moduleInstances.Render.doms.container;

    root.appendChild(this.doms.container);

    // 在Render代理了鼠标滚动事件，这里跳过这个事件
    // @ts-ignore
    this.doms.container.onmousewheel = (e) => {
      e.stopPropagation();
    };
    // this.move被调用时触发
    this.doms.container.onscroll = (e) => {
      e.stopPropagation();
      this.moduleInstances.Render.render();
    };
  }

  // 通过设置 child 的宽高让滚动条出现
  setInner(px: number) {
    if (this.direction === 'vertical') this.doms.child.style.height = `${px}px`;
    if (this.direction === 'horizontal') this.doms.child.style.width = `${px}px`;
  }

  // 让滚动条动起来
  move(option: ScrollToOptions) {
    this.doms.container.scroll(option);
  }
}

export default Scrollbar;
