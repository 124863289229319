import { getMatchingOriginProp } from '@linkpi/core';
import { Cascader, InputNumber, Select } from 'antd';
import { Fragment } from 'react';

import type {
  BasicProp,
  PropOption,
  QuotePropOption,
  TemplateInfo,
} from '@/pages/space/components/TemplateProp/components/TempPropModal/types';

const MatchingConditions = (props: {
  params: BasicProp;
  conditionProps: PropOption[];
  matchingProps: QuotePropOption[];
  setParams: (p: BasicProp) => void;
  tempMap: Record<string, TemplateInfo>;
}) => {
  const { params, conditionProps, matchingProps, setParams, tempMap } = props;

  const genOpOptionsByPropType = (conditionType: string | undefined, multiple: boolean) => {
    switch (conditionType) {
      case 'tag':
        return [
          {
            label: '包含',
            value: 'include',
          },
          {
            label: '等于',
            value: 'equal',
          },
          {
            label: '相交',
            value: 'intersect',
          },
        ];
      case 'enum':
      case 'user':
        break;
      // return multiple ? MULTIPLE_OP_OPTIONS : SINGLE_OP_OPTIONS;
      case 'text':
        break;
      // return TEXT_OP_OPTIONS;
      case 'number':
        // return NUMBER_OP_OPTIONS;
        return [
          {
            label: '=',
            value: 'equal',
          },
          {
            label: '>',
            value: 'great',
          },
          {
            label: '<',
            value: 'less',
          },
          {
            label: '≥',
            value: 'great_equal',
          },
          {
            label: '≤',
            value: 'less_equal',
          },
        ];
      case 'date':
      case 'datetime':
        // return DATE_OP_OPTIONS;
        return [
          {
            label: '等于',
            value: 'equal',
          },
          {
            label: '包含',
            value: 'date_include',
          },
          {
            label: '被包含',
            value: 'date_inside',
          },
          {
            label: '晚于',
            value: 'great',
          },
          {
            label: '早于',
            value: 'less',
          },
          {
            label: '晚于等于',
            value: 'great_equal',
          },
          {
            label: '早于等于',
            value: 'less_equal',
          },
        ];
      case 'position':
      case 'address':
        // return POSITION_OP_OPTIONS;
        return [
          {
            label: '距离',
            value: 'dist',
          },
        ];
      case 'cascade':
        break;
    }
    // return [];
    return [
      {
        label: '包含',
        value: 'include',
      },
      {
        label: '等于',
        value: 'equal',
      },
    ];
  };

  const deleteConditions = (index: number) => {
    const newParams = { ...params };
    newParams.conditionProp?.splice(index, 1);
    newParams.quoteProp?.splice(index, 1);
    if (newParams.matchingOp) {
      while (newParams.matchingOp.length < index + 1) {
        newParams.matchingOp.push(null);
      }
    }
    newParams.matchingOp?.splice(index, 1);
    if (newParams.conditionCascade?.[index]) delete newParams.conditionCascade[index];
    if (newParams.matchingDist) delete newParams.matchingDist[index];
    setParams(newParams);
  };

  const addMatchingCondition = () => {
    const matchingOps = params.matchingOp
      ? params.matchingOp
      : (params.conditionProp || []).map(() => null);
    while (matchingOps.length < (params.conditionProp || []).length + 1) {
      matchingOps.push(null);
    }
    setParams({
      ...params,
      conditionProp: [...(params.conditionProp || []), undefined],
      quoteProp: [...(params.quoteProp || []), undefined],
      matchingOp: matchingOps,
    });
  };

  const addQuoteProp = (index: number) => {
    const quoteProp = params.quoteProp;
    if (quoteProp?.[index]) {
      if (!Array.isArray(quoteProp[index])) {
        quoteProp[index] = [quoteProp[index]];
      }
      quoteProp[index].push(null);
      setParams({
        ...params,
        quoteProp: quoteProp,
      });
    }
  };

  const delQuoteProp = (index: number, vIndex: number) => {
    const quoteProp = params.quoteProp;
    if (quoteProp?.[index]) {
      if (!Array.isArray(quoteProp[index])) {
        quoteProp[index] = [quoteProp[index]];
      }
      if (quoteProp[index].length > vIndex) {
        quoteProp[index].splice(vIndex, 1);
      }
      setParams({
        ...params,
        quoteProp: quoteProp,
      });
    }
  };

  return (
    <div className={'prop-condition-item'} key={'condition'}>
      <div className={'prop-condition-item-label'}>匹配条件</div>
      <div className={'prop-condition-item-input'}>
        {params.conditionProp?.map((c: number | undefined, i: number) => {
          const cv = c !== undefined && Number.isFinite(c) ? [c] : [];
          // @ts-ignore
          const qv: (string | number)[] = params.quoteProp?.[i]
            ? Array.isArray(params.quoteProp[i])
              ? params.quoteProp[i]
              : [params.quoteProp[i]]
            : [];
          if (params.conditionCascade?.[i]) {
            if (
              params.conditionCascade[i].condition !== undefined &&
              Number.isFinite(params.conditionCascade[i].condition)
            ) {
              // @ts-ignore
              cv.push(params.conditionCascade[i].condition);
            }
            if (
              params.conditionCascade[i].quote !== undefined &&
              Number.isFinite(params.conditionCascade[i].quote)
            ) {
              // @ts-ignore
              qv.push(params.conditionCascade[i].quote);
            }
          }
          const conditionProp = conditionProps.find((x) => x.index === c);
          let conditionType = conditionProp?.type;
          if (conditionType) {
            // @ts-ignore
            const originProp: PropOption = getMatchingOriginProp(conditionProp, tempMap);
            if (originProp)
              conditionType =
                originProp.type === 'formula'
                  ? originProp.formulaFormat === 0
                    ? 'number'
                    : 'text'
                  : originProp.type;
          }
          const opOptions = genOpOptionsByPropType(conditionType, !!conditionProp?.multiple);
          return (
            <Fragment key={i}>
              <div className={'prop-condition-item-input-item'}>
                <Cascader
                  value={cv}
                  showSearch
                  placeholder="已有属性"
                  allowClear={false}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  onChange={(e) => {
                    const newParams = { ...params };
                    const prop = conditionProps.find((x) => x.index === e[0]);
                    if (prop?.type && typeof e[0] === 'number') {
                      newParams.conditionProp?.splice(i, 1, e[0]);
                      if (e.length === 2 && typeof e[1] === 'number') {
                        newParams.conditionCascade = {
                          ...(newParams.conditionCascade || {}),
                          [i]: {
                            condition: e[1],
                            quote: newParams.conditionCascade?.[i]?.quote,
                          },
                        };
                      }
                      // 自动选择名字一样的匹配项
                      const matchNameProp = matchingProps.find((x) => x.name === prop.name);
                      if (
                        matchNameProp &&
                        typeof matchNameProp.index === 'string' &&
                        matchNameProp.type !== 'cascade'
                      ) {
                        newParams.quoteProp?.splice(i, 1, matchNameProp.index);
                      }
                      if (!newParams.matchingOp)
                        newParams.matchingOp = (newParams.conditionProp || []).map(() => null);
                      while (newParams.matchingOp.length < (newParams.conditionProp || []).length) {
                        newParams.matchingOp.push(null);
                      }
                      newParams.matchingOp.splice(i, 1, null);
                    }
                    setParams(newParams);
                  }}
                  options={conditionProps?.map((prop) => ({
                    label: prop.name,
                    value: prop.index,
                    // disabled: params.conditionProp?.includes(prop.index),
                    children:
                      prop.type === 'cascade' && prop.cascade?.titles
                        ? prop.cascade.titles
                            .map((t, i) => {
                              return {
                                label: prop.cascade?.titles.slice(0, i + 1).join(' / '),
                                value: i + 1,
                              };
                            })
                            .reverse()
                        : [],
                  }))}
                />
                <div className={'prop-condition-item-input-item-symbol'}>
                  <Select
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    options={opOptions}
                    placeholder={'等于'}
                    value={params.matchingOp?.[i]}
                    onChange={(e) => {
                      const newParams = { ...params };
                      if (!newParams.matchingOp)
                        newParams.matchingOp = newParams.conditionProp?.map((_) => null);
                      if (newParams.matchingOp) {
                        while (newParams.matchingOp.length < i + 1) {
                          newParams.matchingOp.push(null);
                        }
                      }
                      newParams.matchingOp?.splice(i, 1, e);
                      setParams(newParams);
                    }}
                  />
                </div>
                {
                  /*conditionType === 'number' && params.matchingOp?.[i] === 'beIncluded' ? (
                  // 文本和数字属性 被包含 支持多行
                  <div className={'prop-condition-item-input-item-value'}>
                    {qv.map((q, j) => (
                      <div className={`prop-condition-item-input-item-value-item`} key={i}>
                        <Select
                          value={q}
                          showSearch
                          placeholder="引用表属性"
                          options={matchingProps.map((prop) => ({
                            label: prop.name,
                            value: prop.index,
                            disabled: qv.includes(prop.index),
                          }))}
                          onChange={(e) => {
                            const newParams = { ...params };
                            const quoteValue = params.quoteProp?.[i]
                              ? Array.isArray(params.quoteProp[i])
                                ? params.quoteProp[i]
                                : [params.quoteProp[i]]
                              : [];
                            quoteValue[j] = e;
                            newParams.quoteProp?.splice(i, 1, quoteValue);
                            setParams(newParams);
                          }}
                        />
                        {qv.length > 1 ? (
                          <i
                            className={'iconfont icona-zujian67'}
                            onClick={() => delQuoteProp(i, j)}
                          />
                        ) : null}
                        {j == 0 ? (
                          <i className="iconfont iconButton_add" onClick={() => addQuoteProp(i)} />
                        ) : null}
                      </div>
                    ))}
                  </div>
                ) : (*/
                  <Cascader
                    value={qv}
                    showSearch
                    placeholder="引用表属性"
                    allowClear={false}
                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                    onChange={(e) => {
                      const newParams = { ...params };
                      const prop = matchingProps.find((x) => x.index === e[0]);
                      if (prop?.type && typeof e[0] === 'string') {
                        newParams.quoteProp?.splice(i, 1, e[0]);
                        if (e.length === 2 && typeof e[1] === 'number') {
                          newParams.conditionCascade = {
                            ...(newParams.conditionCascade || {}),
                            [i]: {
                              quote: e[1],
                              condition: newParams.conditionCascade?.[i]?.condition,
                            },
                          };
                        }
                      }
                      setParams(newParams);
                    }}
                    options={matchingProps.map((prop) => ({
                      label: prop.name,
                      value: prop.index,
                      // disabled: checkQuotePropDisable(conditionPropType[name], prop.type),
                      children:
                        prop.type === 'cascade' && prop.cascade?.titles
                          ? prop.cascade?.titles
                              .map((t, i) => {
                                return {
                                  label: prop.cascade?.titles.slice(0, i + 1).join(' / '),
                                  value: i + 1,
                                };
                              })
                              .reverse()
                          : [],
                    }))}
                  />
                  // )
                }
                <i className={'iconfont icona-zujian67'} onClick={() => deleteConditions(i)} />
              </div>
              {(conditionType === 'positioning' || conditionType === 'address') &&
              params.matchingOp?.[i] === 'dist' ? (
                <div className={'prop-condition-item-input-item'} key={i + 'positioning'}>
                  <InputNumber
                    min={0}
                    value={params.matchingDist?.[i]}
                    style={{ width: 'calc(100% - 64px)' }}
                    onChange={(e) => {
                      setParams({
                        ...params,
                        matchingDist: { ...(params.matchingDist || {}), [i]: e ?? 0 },
                      });
                    }}
                  />
                  公里以内
                </div>
              ) : null}
            </Fragment>
          );
        })}
        <div className={'prop-condition-item-input-add'} onClick={addMatchingCondition}>
          <i className={'iconfont iconButton_add'} />
          新增匹配条件
        </div>
      </div>
    </div>
  );
};

export default MatchingConditions;
