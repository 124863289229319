import type { ApiResponse } from '@linkpi/core';
import { getQuoteOriginPropInfo } from '@linkpi/core';
import { cloneDeep } from 'lodash';
import { head } from 'ramda';

import { NUMBER_OPERATOR } from './ConditionItem/ConditionOperator';

/**
 * 视图数据 转换成最新的数据结构 v2

 */
export const conditionConvertConditionV2 = (
  viewConditions: ApiResponse.ViewList.Viewcondition[],
  viewParentId: ApiResponse.ViewList.ViewInfo['parentId'],
  rootId: string,
  tempMap: Record<string, ApiResponse.CurrentUser.TemplateInfo>,
): ApiResponse.ViewList.ViewconditionV2 => {
  let conditions = cloneDeep(viewConditions ?? []) as ApiResponse.ViewList.Viewcondition[];

  // 1. 把范围 和 主题类型 加进去
  const topItems: ApiResponse.ViewList.Viewcondition[] = [];
  // 范围
  const parentIdKeys = Object.keys(cloneDeep(viewParentId || {}));
  if (!parentIdKeys.length) {
    parentIdKeys.push(rootId);
  }
  if (~parentIdKeys.indexOf('')) {
    parentIdKeys.splice(parentIdKeys.indexOf(''), 1);
    parentIdKeys.push('');
  }
  topItems.push({
    key: 'parentId',
    value: parentIdKeys,
  });
  // 主题类型
  const tempItemIndex = conditions.findIndex((x) => x.key === 'templateId');
  const tempItem = ~tempItemIndex
    ? conditions.splice(tempItemIndex, 1)[0]
    : { key: 'templateId', value: null };
  topItems.push(tempItem);
  conditions = [...topItems, ...conditions];

  // 2. 状态历史记录（historyStatus）和状态（templateStatus）
  // 是不同的condition
  // 要合并成1个
  const historyStatus = conditions.find((x) => x.key === 'historyStatus');
  if (historyStatus) {
    const templateStatus = conditions.find((x) => x.key === 'templateStatus');
    const historyStatusValue = historyStatus.value.map((x: string) => x + '_history');
    // 插入tempStatusValue里 或者 用templateStatus替换
    if (templateStatus) {
      templateStatus.value = [...templateStatus.value, ...historyStatusValue];
    } else {
      historyStatus.key = 'templateStatus';
    }
  }
  conditions = conditions.filter((x) => x.key !== 'historyStatus');

  // 3. 转成 ViewConditionTypeItemV2
  const conditionsV2 = conditions.map((item) => {
    if (!item || !item.key) {
      return {
        key: '',
        op: '',
        input: [],
      };
    }
    if (item.key === 'templateId') {
      return {
        key: 'templateId',
        op: 'intersect',
        input: [item.value],
      };
    }

    // 兼容input为空数组
    if (item.value === null || item.value === undefined) {
      item.value = [];
    }

    if (item.key === 'parentId') {
      return {
        key: 'ancestor',
        op: 'intersect',
        input: item.value,
      };
    }

    if (item.key === 'templateStatusOwnerAndParticipant') {
      return {
        key: 'templateStatusOwnerAndParticipant',
        op: 'beIncluded', // 人员默认时是 被包含
        input: item.value,
      };
    }

    if (item.key === 'statusFlow') {
      return {
        key: 'statusFlow',
        op: 'statusFlow',
        input: [],
        statusFlow: item.value,
      };
    }
    if (item.key === '_sys_tag') {
      return {
        key: 'sysTag',
        op: 'intersect',
        input: item.value,
      };
    }
    if (item.key === '_sys_creator') {
      return {
        key: 'sysCreator',
        op: 'beIncluded',
        input: item.value,
      };
    }
    if (item.key === '_sys_createTime') {
      return {
        key: 'sysCreateTime',
        op: 'timeBein', // 处于
        input: item.value,
      };
    }
    if (item.key === 'templateStatus') {
      return {
        key: 'status',
        op: 'beIncluded',
        input: item.value
          ? item.value.map((x: string) => {
              const [tempId, index, isHistory] = x.split('_');
              if (isHistory) {
                return `${index}_history`;
              } else {
                return Number(index);
              }
            })
          : [],
      };
    }

    if (item.key?.startsWith('templateProp_')) {
      const [mark, templateId, propIndex] = item.key.split('_');

      let op = 'equal';
      let input = item.value;

      const prop = tempMap[tempItem.value]?.prop?.[Number(propIndex)];
      const originalPropInfo = getQuoteOriginPropInfo(prop, tempMap);
      const propType = originalPropInfo.type || 'text';
      const multiple = originalPropInfo.multiple;

      if (propType === 'user' || propType === 'enum' || propType === 'tag') {
        // 多选默认 相交
        // 单选默认 也是相交
        op = 'intersect';
      }

      if (propType === 'date' || propType === 'datetime') {
        op = 'timeBein';
      }
      // 数字和货币属性
      if (propType === 'number' || propType === 'currency' || propType === 'auto_inc') {
        // {number：number , operator:number | string}
        const { number, operator } = item.value;
        op = typeof operator === 'number' ? NUMBER_OPERATOR[operator].value : operator;
        input = [number];
      }
      if (propType === 'text' || propType === 'str') {
        op = 'include';
      }
      if (propType === 'cascade') {
        op = 'intersect';
      }
      if (propType === 'address' || propType === 'positioning') {
        op = 'equal';
      }

      return {
        key: `prop`,
        index: Number(propIndex),
        op: op as ApiResponse.ViewList.ViewconditionV2Item['op'],
        input: input,
        extends: {
          type: item.type,
        },
      };
    }

    // 状态默认属性 只考虑这个 不存在 Custom
    if (item.key?.startsWith('templateStatusPropBase_')) {
      const [mark, propIndex] = item.key.split('_');
      let op = 'beInclude';
      const input = item.value;

      // 负责人 参与者 开始 结束 备注
      switch (Number(propIndex)) {
        case 0:
          op = 'beIncluded';
          break;
        case 1:
          op = 'intersect';
          break;
        case 2:
        case 3:
          op = 'timeBein';
          break;
        case 4:
          op = 'equal';
          break;
      }

      return {
        key: `statusProp`,
        index: Number(propIndex),
        op: op as ApiResponse.ViewList.ViewconditionV2Item['op'],
        input: input,
        extends: {
          type: item.type,
        },
      };
    }

    return false;
  });

  return conditionsV2.filter((x) => !!x) as ApiResponse.ViewList.ViewconditionV2;
};

/**
 * V2数据结构 转成 视图配置
 */
export const conditionV2ConvertCondition = (
  _conditionsV2: ApiResponse.ViewList.ViewconditionV2,
) => {
  const conditionsV2 = cloneDeep(_conditionsV2);
  const conditions: ApiResponse.ViewList.Viewcondition[] = [];
  // 1. 拆分parent 格式化{}
  const parentItem = conditionsV2.find((x) => x.key === 'ancestor');
  const parentId =
    parentItem?.input.reduce(
      (a: any, b: string) => {
        a[b] = 0;
        return a;
      },
      {} as Record<string, number>,
    ) || null;

  const tempIdItem = conditionsV2.find((x) => x.key === 'templateId');
  const templateId: [string | null] = tempIdItem?.input;
  // 2. 状态 拆分状态和 历史状态
  const statusIndex = conditionsV2.findIndex((x) => x.key === 'status');
  if (~statusIndex) {
    const statusItem = conditionsV2.splice(statusIndex, 1)[0];
    const historyStatus: string[] = [];
    statusItem.input = (statusItem.input as (string | number)[])?.filter((x) => {
      if (typeof x === 'string' && x.endsWith('_history')) {
        historyStatus.push(x.substring(0, x.length - 8));
        return false;
      } else {
        return true;
      }
    });

    conditions.push({
      key: 'templateStatus',
      value: statusItem.input.map((x: number) =>
        // templateId 不存在说明是筛选多主题类型，不需要拼接 templateId
        typeof head(templateId) === 'string' ? `${templateId}_${x}` : x,
      ),
    });

    // 筛选多主题类型不会筛选历史状态
    if (historyStatus.length) {
      conditions.push({
        key: 'historyStatus',
        value: historyStatus.map((x) => `${templateId}_${x}`),
      });
    }
  }

  const convertConditions = conditionsV2
    .map((item) => {
      if (!item || !item.key) {
        return {
          key: null,
          value: null,
        };
      }
      if (item.key === 'templateId') {
        return {
          key: 'templateId',
          value: item.input[0],
        };
      }

      if (item.key === 'templateStatusOwnerAndParticipant') {
        return {
          key: 'templateStatusOwnerAndParticipant',
          value: item.input,
          type: 'user',
        };
      }

      if (item.key === 'statusFlow') {
        return {
          key: 'statusFlow',
          value: item.statusFlow,
        };
      }

      if (item.key === 'sysTag') {
        return {
          key: '_sys_tag',
          value: item.input,
          type: 'tag',
        };
      }
      if (item.key === 'sysCreateTime') {
        return {
          key: '_sys_createTime',
          value: item.input,
          type: 'date',
        };
      }
      if (item.key === 'sysCreator') {
        return {
          key: '_sys_creator',
          value: item.input,
          type: 'user',
        };
      }
      if (item.key === 'prop') {
        let itemValue = item.input;
        const itemType = item.extends?.type;
        if (itemType === 'number' || itemType === 'currency') {
          const numberOpIndex = NUMBER_OPERATOR.findIndex((x) => x.value === item.op);
          itemValue = {
            number: item.input[0],
            operator: numberOpIndex,
          };
        }
        return {
          key: `templateProp_${templateId}_${item.index}`,
          value: itemValue,
          type: itemType,
        };
      }
      if (item.key === 'statusProp') {
        return {
          key: `templateStatusPropBase_${item.index}`,
          value: item.input,
          type: item.extends?.type,
        };
      }
    })
    .filter((x) => !!x) as ApiResponse.ViewList.Viewcondition[];

  return {
    parentId: parentId,
    conditions: [...convertConditions, ...conditions],
  };
};

export const conditionKeyConvertV2Key = (
  conditionKey: string,
): ApiResponse.ViewList.ViewconditionV2Item['key'] | `prop_${string}` | `statusProp_${string}` => {
  if (conditionKey === '_sys_tag') {
    return 'sysTag';
  } else if (conditionKey === '_sys_creator') {
    return 'sysCreator';
  } else if (conditionKey === '_sys_createTime') {
    return 'sysCreateTime';
  } else if (conditionKey === 'templateStatus') {
    return 'status';
  } else if (conditionKey.startsWith('templateProp_')) {
    const [mark, templateId, propIndex] = conditionKey.split('_');
    return `prop_${propIndex}`;
  } else if (conditionKey.startsWith('templateStatusPropBase_')) {
    const [mark, propIndex] = conditionKey.split('_');
    return `statusProp_${propIndex}`;
  }

  return conditionKey as ApiResponse.ViewList.ViewconditionV2Item['key'];
};

// 转换属性设置数据
export const propSetConditionConvertConditionV2 = (
  template: ApiResponse.CurrentUser.TemplateInfo,
  tempMap: Record<string, ApiResponse.CurrentUser.TemplateInfo>,
  conditions: ApiResponse.CurrentUser.Condition[],
) => {
  return [
    {
      key: 'templateId',
      op: 'included',
      input: [template.template_id],
    } as any,
  ].concat(
    conditions.map((item) => {
      if (!item || !item.key) {
        return {
          key: '',
          op: '',
          input: [],
        };
      }
      if (item.key === 'creator') {
        return {
          key: 'sysCreator',
          op: 'beIncluded',
          input: item.user,
        };
      }
      if (item.key === 'createTime') {
        return {
          key: 'sysCreateTime',
          op: {
            INSIDE: 'timeBein',
            EQUAL: 'timeEqual',
            GREAT: 'timeAfter',
            GREAT_EQUAL: 'timeAfterEqual',
            LESS: 'timeBefore',
            LESS_EQUAL: 'timeBeforeEqual',
          }[item.symbol as string],
          input: item.date,
        };
      }
      if (item.key === 'sysTag') {
        return {
          key: 'sysTag',
          op: 'intersect',
          input: item.enum,
        };
      }
      if (item.key === 'status') {
        return {
          key: 'status',
          op: 'beIncluded',
          input: item.enum?.map((x) => x),
        };
      }
      if (item.key?.startsWith('prop_')) {
        const [mark, propIndex] = item.key.split('prop_');
        let op = 'equal';
        let input =
          item.enum ||
          item.user ||
          item.number ||
          item.text ||
          item.date ||
          item.address ||
          item.positioning;

        const prop = template?.prop?.[Number(propIndex)];
        const originalPropInfo = getQuoteOriginPropInfo(prop, tempMap);
        const propType = originalPropInfo.type || 'text';
        const multiple = originalPropInfo.multiple;

        if (~['user', 'enum'].indexOf(item.type)) {
          op = { INCLUDE: 'beIncluded', EQUAL: 'equal' }[item.symbol as string] as string;
        }
        if (item.type === 'address') {
          op = 'equal';
          // 单选
          input = item.address;
        }
        if (item.type === 'positioning') {
          op = 'distance';
          input = [item.positioning?.[0].position, item.positioning?.[0].distance];
        }

        if (item.type === 'text') {
          op = { INCLUDE: 'include', EQUAL: 'equal' }[item.symbol as string] as string;
        }
        if (item.type === 'number') {
          const { number } = item.number?.[0] as any;
          input = [number];
          op = {
            EQUAL: 'equal',
            NOT_EQUAL: 'notEqual',
            GREAT: 'greater',
            GREAT_EQUAL: 'greaterEqual',
            LESS: 'less',
            LESS_EQUAL: 'lessEqual',
          }[item.symbol as string] as string;
        }
        if (item.type === 'date') {
          op = {
            INSIDE: 'timeBein',
            EQUAL: 'timeEqual',
            GREAT: 'timeAfter',
            GREAT_EQUAL: 'timeAfterEqual',
            LESS: 'timeBefore',
            LESS_EQUAL: 'timeBeforeEqual',
          }[item.symbol as string] as string;
        }

        // text 多行
        if (item.type === 'text') {
          return {
            key: `prop_${propIndex}`,
            op: 'or',
            index: Number(propIndex),
            extends: {
              type: 'text',
            },
            input: input?.map((x) => ({
              key: `prop_${propIndex}`,
              index: Number(propIndex),
              op: op,
              input: [x],
              extends: {
                type: 'text',
              },
            })),
          };
        }

        return {
          key: `prop_${propIndex}`,
          op: op,
          input,
          index: Number(propIndex),
          extends: {
            type: propType,
          },
        };
      }
      if (item.key?.startsWith('status_')) {
        const [mark, propIndex] = item.key.split('status_');
        let op = 'beInclude';
        const input = item.user || item.date;

        // 负责人 参与者 开始 结束 备注
        if (item.type === 'user') {
          op = { INCLUDE: 'include', EQUAL: 'equal' }[item.symbol as string] as string;
        }
        if (item.type === 'date') {
          op = {
            INSIDE: 'timeBein',
            EQUAL: 'timeEqual',
            GREAT: 'timeAfter',
            GREAT_EQUAL: 'timeAfterEqual',
            LESS: 'timeBefore',
            LESS_EQUAL: 'timeBeforeEqual',
          }[item.symbol as string] as string;
        }

        return {
          key: `statusProp_${propIndex}`,
          index: Number(propIndex),
          op: op as ApiResponse.ViewList.ViewconditionV2Item['op'],
          input: input,
          extends: {
            type: item.type,
          },
        };
      }
    }),
  );
};
