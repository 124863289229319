import { DEFAULT_AVATAR } from '@linkpi/core';

import { getFileTypeIconByUrl } from '@/utils/utils';

import ActionMask from '../commonComponents/actionMask';
import type { PiGridModules } from '../core';
import type { PositionType } from './_baseComponent';
import GridBaseComponent from './_baseComponent';

class Attachment extends GridBaseComponent {
  public file: any;
  public node: PiNode;
  public propIndex: number;
  public propConfig: any;
  public type: string = 'attachment';

  constructor(position: PositionType, moduleInstances: PiGridModules, file: any, data: any) {
    super(position, moduleInstances);
    this.file = file;

    this.node = data.node;
    this.propIndex = data.propIndex;
    this.propConfig = data.propConfig;
  }

  public drawImg(src: string, x: number, y: number) {
    const { Draw, ImageLoader } = this.moduleInstances;

    const img = ImageLoader.loadImage(src || DEFAULT_AVATAR, 'attachment');
    // 图片居中裁剪
    const h = img.height;
    const w = img.width;
    const min = Math.min(h, w);
    const max = Math.max(h, w);
    if (min !== max) {
      Draw.drawRoundImg(
        0,
        x,
        y,
        32,
        32,
        img,
        h >= w ? 0 : (max - min) / 2, // x 裁剪位置
        h >= w ? (max - min) / 2 : 0, // y 裁剪位置
        min,
        min,
      );
    } else Draw.drawRoundImg(0, x, y, 32, 32, img);
  }

  public click() {
    this.moduleInstances.DataManager.config.setShowAttachmentActions({
      node: this.node,
      position: this.position,
      propIndex: this.propIndex,
      file: this.file,
      propConfig: this.propConfig,
    });
    // if (getFileTypeIconByUrl(this.file.src).type !== 'image') return;
    // this.moduleInstances.DataManager.config.showBigImg(this.file.src);
  }

  public drawActionMask() {
    const { x, y, height } = this.position;
    const maskWidth = height;
    const maskHeight = height / 2;

    const actionMask = new ActionMask(
      {
        height: maskHeight,
        width: maskWidth,
        x,
        y: y + maskHeight,
      },
      this.moduleInstances,
      this.file,
    );
    actionMask.render();
  }

  public render() {
    const { x, y } = this.position;

    const s = getFileTypeIconByUrl(this.file.compressSrc).src;
    this.drawImg(s, x, y);

    if (this.isHover) {
      this.moduleInstances.Render.setCursor('pointer');
      // this.drawActionMask();
    }
  }
}

export default Attachment;
