import type { ApiResponse, CurrentUser, ViewList } from '@linkpi/core';
import {
  addNewNode,
  generateAddOpId,
  getDefaultProp,
  NODE_TYPE,
  propIsNull,
  tempValueDisplay,
  transformAddDataToApiData,
} from '@linkpi/core';
import type { GetterPiNode } from '@linkpi/core/web';
import { getAddNodeDataWithViewOption } from '@linkpi/core/web';
import { message } from 'antd';
import dayjs from 'dayjs';

import request from '@/utils/request';
import { mergeArray } from '@/utils/utils';

const getConditionStatus = (
  curViewData: ViewList.ViewListItem<1 | 2 | 4 | 6>,
  template: CurrentUser.TemplateInfo,
) => {
  const condition = curViewData.view_info?.condition;
  if (!condition) return null;

  const status = condition.find((c) => c.key === 'templateStatus');
  if (!status) {
    // 默认状态
    const notDeleteStatus = ([...template.task_status] || [])
      .map((s, index) => ({ ...s, index }))
      .sort((s1, s2) => {
        if ('sort' in s1) return s1.sort - s2.sort;
        return 1;
      })
      .filter((s: any) => !s.delete);

    return notDeleteStatus.length ? notDeleteStatus[0].index : null;
  }

  return status.value.length ? status.value[0].split('_')[1] : null;
};

export const getAddInitData = ({
  node,
  currentSelection,
  prevNodeId,
  curViewData,
  groupItem,
  viewTemplate,
  templateList,
  groupKey,
  userMap,
  userId,
  departmentMap,
}: {
  node: GetterPiNode;
  currentSelection: any;
  prevNodeId: string | null;
  curViewData: ApiResponse.ViewList.ViewListItem<any>;
  groupItem: ViewGroupDataItem | null;
  viewTemplate: ApiResponse.CurrentUser.TemplateInfo;
  templateList: ApiResponse.CurrentUser.TemplateInfo[];
  groupKey: string;
  userMap: Record<string, ApiResponse.OrgUser.OrgUserItem>;
  userId: string;
  departmentMap: Record<string, ApiResponse.OrgGroups.OrgDepartmentNodeInfo>;
}) => {
  const parentNode = node.value.nodeManager.findChildren(currentSelection.selectNode);
  let parentId = parentNode.id;
  if (parentNode.nodeType === NODE_TYPE.FILTERNODE_PATH) {
    const [pid, ..._] = parentNode.id.split('>');
    parentId = pid;
  }

  const prevNode = node.value.nodeManager.findChildren(prevNodeId);

  // 视图新增 节点默认数据
  const dataWithViewOption = getAddNodeDataWithViewOption(
    curViewData.view_info?.condition || [],
    curViewData.view_info?.group,
    groupItem,
    viewTemplate,
    groupKey,
    prevNode,
  );

  // 通过主题类型获取 节点默认数据
  const nodeDefaultProp = getDefaultProp(
    viewTemplate || {},
    null,
    userMap,
    userId,
    userId,
    Date.now(),
    dataWithViewOption.tempProp,
    undefined,
    departmentMap,
  );

  // 合并 节点默认数据和 视图新增数据
  dataWithViewOption.tempProp = mergeArray(nodeDefaultProp, dataWithViewOption.tempProp || []);
  if (prevNodeId === null && groupItem === null && groupKey === 'noData') {
    return {
      parentId,
      addInitData: {
        initProp: dataWithViewOption.tempProp,
        templateList,
        userMap,
        userId: userId,
        orgId: currentSelection.selectSpace,
        parentId,
        initTempId: viewTemplate.template_id,
        initStatus: Number(getConditionStatus(curViewData, viewTemplate)),
      },
    };
  }
  const addInitData: Record<string, any> = {
    templateList,
    userMap,
    userId: userId,
    orgId: currentSelection.selectSpace,
    parentId: parentId,
    initTempId: viewTemplate.template_id,
  };
  if (dataWithViewOption.tempProp !== undefined) {
    addInitData.initProp = dataWithViewOption.tempProp;
  }
  if (dataWithViewOption.status !== undefined) {
    addInitData.initStatus = dataWithViewOption.status;
  }
  if (dataWithViewOption.statusProp !== undefined) {
    addInitData.initStatusProp = dataWithViewOption.statusProp;
  }
  if (dataWithViewOption.sysTag !== undefined) {
    addInitData.initSysTag = dataWithViewOption.sysTag;
  }

  if (dataWithViewOption.sysCascade !== undefined) {
    addInitData.initSysCascade = dataWithViewOption.sysCascade;
  }

  return { parentId, addInitData };
};

export const addOnGrid = async ({
  node,
  currentSelection,
  prevNodeId,
  curViewData,
  groupItem,
  viewTemplate,
  templateList,
  groupKey,
  matchProps,
  userMap,
  userId,
  patchUpdateRecordSorts,
  departmentMap,
}: {
  node: GetterPiNode;
  currentSelection: any;
  prevNodeId: string;
  curViewData: ApiResponse.ViewList.ViewListItem<any>;
  groupItem: ViewGroupDataItem;
  viewTemplate: ApiResponse.CurrentUser.TemplateInfo;
  templateList: ApiResponse.CurrentUser.TemplateInfo[];
  groupKey: string;
  matchProps: any[];
  userMap: Record<string, ApiResponse.OrgUser.OrgUserItem>;
  userId: string;
  patchUpdateRecordSorts: any;
  departmentMap: Record<string, ApiResponse.OrgGroups.OrgDepartmentNodeInfo>;
}) => {
  const tempMap: any = {};
  templateList.forEach((item) => {
    tempMap[item.template_id] = item;
  });

  const { parentId, addInitData } = getAddInitData({
    node,
    currentSelection,
    prevNodeId,
    curViewData,
    groupItem,
    viewTemplate,
    templateList,
    groupKey,
    matchProps,
    userMap,
    userId,
    departmentMap,
  });

  // 表格视图可以不必填 不弹窗
  const newId = generateAddOpId();
  const dataReq: Record<string, any> = {
    userId: userId,
    orgId: currentSelection.selectSpace,
    nodeId: newId,
    parentId: parentId,
    tempId: viewTemplate.template_id,
    tempProp: addInitData.initProp || [],
    status: addInitData.initStatus || 0,
    statusProp: addInitData.initStatusProp || [],
    sysTag: addInitData.initSysTag || [],
  };
  if (
    viewTemplate &&
    viewTemplate.title_setting &&
    viewTemplate.title_setting.titleDefault &&
    viewTemplate.title_setting.titleDefault.length
  ) {
    dataReq.title = getTitleDefault(
      addInitData.initProp || [],
      viewTemplate,
      userId,
      userMap,
      tempMap,
      departmentMap,
    );
  }
  const req = transformAddDataToApiData(dataReq);
  const [err, res] = await addNewNode(request, req);
  if (err || res.status !== 'ok') {
    message.error('新增失败，请重试');
  } else {
    message.success('新增成功');
    patchUpdateRecordSorts(newId, prevNodeId);
  }
};

const getTitleDefault = (
  propData: any[],
  viewTemplate: ApiResponse.CurrentUser.TemplateInfo,
  userId: string,
  userMap: Record<string, ApiResponse.OrgUser.OrgUserItem>,
  tempMap: Record<string, ApiResponse.CurrentUser.TemplateInfo>,
  departmentMap: Record<string, ApiResponse.OrgGroups.OrgDepartmentNodeInfo>,
) => {
  const defaultTitle = viewTemplate?.title_setting?.titleDefault
    .map((v) => {
      if (typeof v === 'string') return v;
      if (v === -1) return userMap[userId].nick_name;
      if (v === -2) return dayjs().format('YYYY/MM/DD HH:mm');
      if (viewTemplate.prop[v]?.type && !propIsNull(propData?.[v])) {
        return tempValueDisplay({
          propConfig: viewTemplate.prop[v],
          propValue: propData[v],
          propIndex: v,
          userMap,
          tempMap,
          departmentMap,
        });
      }
    })
    .filter((t) => !!t)
    .join('-');
  return defaultTitle;
};
