import type { ApiResponse } from '@linkpi/core';
import { Avatar } from 'antd';
import dayjs from 'dayjs';
import type { FC } from 'react';

import logo from '@/assets/logo.svg';

type StatusRecordItemProp = {
  record: any;
  timeFormat?: string;
  userMap: Record<string, ApiResponse.OrgUser.OrgUserItem>;
};
export const StatusRecordItem: FC<React.PropsWithChildren<StatusRecordItemProp>> = ({
  children,
  record,
  userMap,
  timeFormat = 'YY/MM/DD HH:mm',
}) => {
  return (
    <div className={'status-record-item'}>
      <div className={'status-record-time'}>
        <div className={'status-record-time-main'}>
          {dayjs(record.time).format(timeFormat)}
          <div className={'text-omit'}>
            <Avatar
              style={{ margin: '-2px 4px 0 0' }}
              src={userMap[record.ad]?.avatar || logo}
              size={16}
            />
            {userMap[record.ad]?.nick_name || ''}
          </div>
        </div>
        <div className={'status-record-time-dot'} />
      </div>
      <div className={'status-record-item-content'}>
        <div className={'status-record-item-content-main'}>
          <div className={'status-record-item-content-main-list'}>{children}</div>
        </div>
      </div>
    </div>
  );
};
