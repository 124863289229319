import type { FormInstance, FormItemProps } from 'antd';
import { Form } from 'antd';
import { isBoolean, isFunction } from 'lodash';
import type { FunctionComponent } from 'react';

type CheckEnbleFn = (form: FormInstance) => boolean;
type Props = Pick<FormItemProps, 'dependencies' | 'shouldUpdate'>;
type ConditionFormProp = Props & {
  enble: boolean | CheckEnbleFn;
};

const ConditionForm: FunctionComponent<
  | React.PropsWithChildren<ConditionFormProp>
  | ({
      children: () => React.ReactNode;
    } & ConditionFormProp)
> = ({ enble, children, ...restProps }) => {
  const content = () => {
    if (isBoolean(enble)) return enble && children;
    //  TODO: useFormInstance in antd@4.20 is better
    else if (isFunction(enble)) return (form: FormInstance) => enble(form) && children;

    return null;
  };

  const props: Props = {};
  if (restProps.dependencies) {
    props.dependencies = restProps.dependencies;
  } else {
    props.shouldUpdate = true;
  }

  return (
    <Form.Item {...props} noStyle>
      {content()}
    </Form.Item>
  );
};

export default ConditionForm;
