import { ArrowRightOutlined } from '@ant-design/icons';
import type { ApiResponse } from '@linkpi/core';
import type { OrgUser } from '@linkpi/core';
import { propIsNull } from '@linkpi/core';
import type { GetterPiNode } from '@linkpi/core/web';
import { useRequest } from 'ahooks';
import { Avatar, Space, Tag, Typography } from 'antd';
import cls from 'classnames';
import dayjs from 'dayjs';
import { values } from 'lodash';
import { defaultTo, keys, sortBy, uniq, without } from 'ramda';
import { type FC, useMemo } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import logo from '@/assets/logo.svg';
import { RegularIcon } from '@/components/IconFont';
import { ScrollArea } from '@/components/ui/scroll-area';
import { useOrgUserMap } from '@/hook';
import { usePageThemeMode } from '@/hook/usePageLayout';
import { getStatusLog } from '@/services/home';

import { viewComponent } from '../../Workbench/NodePageModel/components';
import { StatusRecordItem } from './StatusRecordItem';

import './index.less';

const checkStatusPropType = (type: string) => {
  return type === 'user' || type === 'datetime' || type === 'text';
};

const displayLastTime: (mSeconds: number) => string = (mSeconds: number) => {
  if (mSeconds > 24 * 3600 * 1000) {
    return Math.floor(mSeconds / (24 * 60 * 60 * 1000))
      .toString()
      .concat('天', displayLastTime(mSeconds % (24 * 60 * 60 * 1000)));
  } else if (mSeconds > 3600 * 1000) {
    return Math.floor(mSeconds / (60 * 60 * 1000))
      .toString()
      .concat('小时', displayLastTime(mSeconds % (60 * 60 * 1000)));
  } else if (mSeconds > 60 * 1000) {
    return Math.floor(mSeconds / (60 * 1000))
      .toString()
      .concat('分钟', displayLastTime(mSeconds % (60 * 1000)));
  } else {
    return '';
  }
};

const propDisplay = (
  prop: ApiResponse.CurrentUser.statusProp,
  value: any,
  userMap: Record<string, OrgUser.OrgUserItem>,
) => {
  if (propIsNull(value)) return '/';
  switch (prop.type) {
    case 'user': {
      const userIds = (Array.isArray(value) ? value : [value]).filter(
        (userId: string) => userMap[userId],
      );
      return userIds.map((userId: string, index: number) => {
        return (
          <Space key={userId} align="center" size={4}>
            <Avatar src={userMap[userId].avatar || logo} size={18} />
            {userMap[userId].nick_name}
            {index < userIds.length - 1 ? ' 、' : ''}
          </Space>
        );
      });
    }
    case 'datetime':
      return dayjs(value).format(timeFormat);
    case 'text':
      return value;
    default:
      return '/';
  }
};

interface NodeHistoryStatusProp {
  node: GetterPiNode;
  orgId: string;
  template: ApiResponse.CurrentUser.TemplateInfo;
  disablePreview?: boolean;
}

interface StatusItemType {
  status: number;
  props: any[];
  modifyRecords: any[];
  voltRecords: {
    commit: string;
    time: number;
    status: ApiResponse.CurrentUser.taskStatus;
    ad: string;
  }[];
  time: number;
  ad: string;
}

const timeFormat = 'YY/MM/DD HH:mm';

const NodeHistoryStatus: FC<NodeHistoryStatusProp> = (props) => {
  const { node, orgId, template, disablePreview = false } = props;
  const userMap = useOrgUserMap();
  const { isDark: isDarkMode } = usePageThemeMode();

  const { data: historyStatus = [] } = useRequest(
    async ({
      nodeId,
      tempId,
      orgId: _orgId,
    }: {
      nodeId: string;
      tempId: string;
      orgId: string;
    }) => {
      if (!orgId || !nodeId || !tempId) return [];

      const statusItems: Array<StatusItemType> = [];
      if (Array.isArray(node.value.prop?._sys_temp) && node.value.prop._sys_temp[0]) {
        const res = await getStatusLog({ node_id: nodeId, org_id: _orgId, temp_id: tempId });
        const statusList = template.task_status;
        if (res.status === 'ok') {
          res.data.forEach((record: any) => {
            if ('s' in record) {
              statusItems.push({
                status: record.s,
                props: record.p,
                modifyRecords: [],
                voltRecords: [],
                time: record.time,
                ad: record.ad,
              });
            } else if ('v' in record) {
              statusItems[statusItems.length - 1].voltRecords.push({
                commit: record.vc,
                time: record.time,
                status: statusList[record.v],
                ad: record.ad,
              });
            } else if (statusItems.length) {
              statusItems[statusItems.length - 1].modifyRecords.push({
                propMap: record.m,
                time: record.time,
                ad: record.ad,
              });
            }
          });
        }
      }
      return statusItems.reverse();
    },
    {
      debounceWait: 200,
      defaultParams: [
        {
          nodeId: node.value?.id,
          orgId,
          tempId: node.value?.tempInfo?.id,
        },
      ],
      refreshDeps: [node.value.metadata.m],
      cacheKey: [node.value?.id, orgId, node.value?.tempInfo?.id].join('@'),
    },
  );

  const historyStatusContent = useMemo(
    () =>
      historyStatus.map((record: StatusItemType, index) => {
        const status = template.task_status[record.status];
        if (!status) return null;
        const lastTime = (index === 0 ? Date.now() : historyStatus[index - 1].time) - record.time;
        const statusProp = status.prop.filter(
          (prop) => prop?.type && checkStatusPropType(prop.type) && prop.display,
        );

        // 展示未参与会签的用户
        const didntVoteContent = (() => {
          // 判断该记录是否是当前状态
          if (index !== 0) return null;

          // 获取要参与会签的所有人
          const allUsers = uniq(
            [
              // 参与者
              ...defaultTo([])(node.value.tempInfo.statusProp[1]),
              // 负责人
              node.value.tempInfo.statusProp[0],
            ].filter(Boolean),
          );

          // 获取当前会签的状态
          const votingResults = values(node.value.prop._sys_voting_results || {})?.[0];

          if (!votingResults) return null;

          // 已参与的人
          const handledUsers = keys(votingResults);

          if (handledUsers.length === allUsers.length || allUsers.length === 0) return null;

          // 未参与的人
          const restUsers: string[] = without(handledUsers, allUsers);
          return (
            <StatusRecordItem
              key={record.time + 'commit'}
              record={{ ad: 'null', time: new Date().valueOf() }}
              userMap={userMap}
            >
              <p className={'voltName'} style={{ lineHeight: 1 }}>
                <Space align="center" wrap>
                  未处理:
                  {restUsers.map((userId) => (
                    <Space key={userId} align="center" size={2}>
                      <Avatar src={userMap[userId]?.avatar || logo} size={16} />
                      {userMap[userId].nick_name}
                    </Space>
                  ))}
                </Space>
              </p>
            </StatusRecordItem>
          );
        })();

        return (
          <div className={'status-record'} key={record.time}>
            <div className={'status-record-head'}>
              <div className={'status-record-time'}>
                {record.modifyRecords.length + record.voltRecords.length ? null : (
                  <>
                    <div className={'status-record-time-main'}>
                      <div style={{ color: isDarkMode ? 'rgba(214,224,255,0.3)' : '#242D3F' }}>
                        {dayjs(record.time).format(timeFormat)}
                      </div>
                      <div className={'text-omit'}>
                        <Avatar
                          style={{ margin: '-2px 4px 0 0' }}
                          src={userMap[record.ad].avatar || logo}
                          size={16}
                        />
                        {userMap[record.ad].nick_name}
                      </div>
                    </div>
                    <div className={'status-record-time-dot'} />
                    <div className={'status-record-time-line'} />
                  </>
                )}
              </div>
              <div className={'status-record-title'}>
                <div className={'status-record-title-main'}>
                  <div>
                    <div className={'status-record-title-main-name text-omit'}>
                      <div className={'text-omit'}>{status.name}</div>
                      {index === 0 ? '（当前）' : ''}
                    </div>
                    <div className={'status-record-title-main-time'}>
                      状态时长：
                      {lastTime > 60_000
                        ? displayLastTime(lastTime)
                        : Math.floor(lastTime / 1000)
                            .toString()
                            .concat('秒')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {didntVoteContent}
            {sortBy((r: any) => 0 - r.time)([
              ...record.modifyRecords
                .reverse()
                .map((mr) => {
                  mr.propIndexes = Object.keys(mr.propMap || {}).filter(
                    (key: string) =>
                      status.prop[Number(key)]?.type &&
                      checkStatusPropType(status.prop[Number(key)].type) &&
                      status.prop[Number(key)].display,
                  );
                  return mr;
                })
                .filter((mr: any) => mr.propIndexes.length),
              ...record.voltRecords,
            ]).map((record: any) => {
              if ('propIndexes' in record) {
                const mr = record;
                console.log(record);
                return (
                  <StatusRecordItem key={record.time + 'prop'} record={mr} userMap={userMap}>
                    {mr.propIndexes.map((key: string) => (
                      <div key={key} className={'status-record-item-content-main-list-item'}>
                        {status.prop[Number(key)].name}：
                        {propDisplay(status.prop[Number(key)], mr.propMap[Number(key)], userMap)}
                      </div>
                    ))}
                  </StatusRecordItem>
                );
              } else if ('commit' in record) {
                return (
                  <StatusRecordItem key={record.time + 'commit'} record={record} userMap={userMap}>
                    <p className={'voltName'}>
                      <Space>
                        {userMap[record.ad].nick_name}已经处理 <ArrowRightOutlined />
                        <Tag>{(record.status as ApiResponse.CurrentUser.taskStatus).name}</Tag>
                      </Space>
                    </p>
                    <Typography.Paragraph
                      ellipsis={{ rows: 1, tooltip: true }}
                      className={'commit'}
                    >
                      审批意见：{record.commit}
                    </Typography.Paragraph>
                    <RegularIcon type="iconwancheng" size={44} className={'icon'} />
                  </StatusRecordItem>
                );
              }
            })}
            <div className={'status-record-tail'}>
              <div className={'status-record-time'}>
                {record.modifyRecords.length ? (
                  <>
                    <div className={'status-record-time-main'}>
                      <div style={{ color: isDarkMode ? 'rgba(214,224,255,0.3)' : '#242D3F' }}>
                        {dayjs(record.time).format(timeFormat)}
                      </div>
                      <div className={'text-omit'}>
                        <Avatar
                          style={{ margin: '-2px 4px 0 0' }}
                          src={userMap[record.ad].avatar || logo}
                          size={16}
                        />
                        {userMap[record.ad].nick_name}
                      </div>
                    </div>
                    <div className={'status-record-time-dot'} />
                    <div className={'status-record-time-line'} />
                  </>
                ) : null}
              </div>
              <div
                className={`status-record-tail-content${
                  index === historyStatus.length - 1 ? '' : ' hasPadding'
                }`}
              >
                <div className={'status-record-tail-content-main'}>
                  {statusProp.length ? (
                    <div className={'status-record-tail-content-main-list'}>
                      {status.prop.map((prop, i) => {
                        if (prop?.type && checkStatusPropType(prop.type) && prop.display) {
                          return (
                            <div key={i} className={'status-record-tail-content-main-list-item'}>
                              {prop.name}：{propDisplay(prop, record.props[i], userMap)}
                            </div>
                          );
                        }
                      })}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        );
      }),
    [
      historyStatus,
      isDarkMode,
      node.value.prop._sys_voting_results,
      node.value.tempInfo.statusProp,
      template.task_status,
      userMap,
    ],
  );

  return (
    <ScrollArea className="h-full relative">
      <div className={cls('NodeHistoryStatus', isDarkMode && 'dark-mode')}>
        {disablePreview || (
          <div
            className={'head'}
            onClick={() => {
              viewComponent({
                width: 626,
                content: (
                  <PerfectScrollbar>
                    <div className="NodeHistoryStatus">{historyStatusContent}</div>
                  </PerfectScrollbar>
                ),
              });
            }}
          >
            <div />
            <div className={'viewall'}>
              <i className="iconfont iconneirongye-dakai2" />
            </div>
          </div>
        )}
        {historyStatusContent}
      </div>
    </ScrollArea>
  );
};

export default NodeHistoryStatus;
