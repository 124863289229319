import { Divider } from 'antd';
import type { FC, PropsWithChildren } from 'react';
import { Children } from 'react';

export const DividerList: FC<PropsWithChildren> = ({ children }) => {
  const total = Children.count(children);
  return Children.map(children, (c, index) => {
    if (index + 1 === total) return c;
    if (!c) return null;

    return [c, <Divider type="vertical" key="divider" />];
  });
};
