import { childConditionTransformToTempProps } from '@/utils/utils';

import BaseModule from './_baseModule';
type Map = Record<string, PiNode[]>;

type Cache = Record<string, Map>;

interface matchingItem {
  target: string | undefined;
  origin: string | undefined;
  key: number;
}

export default class ChildNodesLoader extends BaseModule {
  public cache: Cache = {};

  public getChildNodes(node: PiNode, config: any, index: number) {
    const { cache } = this;
    const map = cache[node.id] || {};

    if (map[index]) return map[index];

    this.loadChildNodes(node, config, index);
    return [];
  }

  public async loadChildNodes(node: PiNode, config: any, index: number) {
    const { Render, DataManager } = this.moduleInstances;
    const { matchings, theme } = config;
    const groupBy = matchings.map((matching: matchingItem) => `p${matching.target}`);

    const filterNode = await this.getFilterNode(node, config);
    const nodes: PiNode[] = filterNode.getMatchNode(node.id, groupBy);
    // 按创建时间排序
    const childOrderBy = this.config.viewInfo.childOrderBy;
    // if (!childOrderBy || childOrderBy === '_child_treeLevel')
    if (childOrderBy === '_child_createTime') nodes.sort((a, b) => a.createTime - b.createTime);
    if (childOrderBy === '_child_updateTime') nodes.sort((a, b) => b.metadata.m - a.metadata.m);

    // 子主题按照属性排序
    if (Object.prototype.toString.call(childOrderBy) === '[object Object]') {
      // 当前主题的排序
      const nowThemeOrderConfig = childOrderBy[theme];
      if (nowThemeOrderConfig) {
        const { pIndex, sort } = nowThemeOrderConfig;

        nodes.sort((a, b) => {
          const data_a = a.tempInfo.prop[pIndex];
          const data_b = b.tempInfo.prop[pIndex];

          if (sort === 'desc') {
            if (data_a === undefined || data_a === null) return 1;
            if (data_b === undefined || data_b === null) return -1;
            return data_b - data_a;
          } else {
            if (data_a === undefined || data_a === null) return -1;
            if (data_b === undefined || data_b === null) return 1;
            return data_a - data_b;
          }
        });
      }
    }
    if (this.cache[node.id]) {
      this.cache[node.id][index] = nodes;
    } else {
      this.cache[node.id] = { [`${index}`]: nodes };
    }

    DataManager.getFixVirtualList();
    Render.render();
  }

  public async getFilterNode(node: PiNode, config: any) {
    let { nodePath, theme, matchings, isRoot, filterChilds, conditions } = config;

    let tempProps = [];
    if (Array.isArray(conditions) && conditions.length > 0) {
      // 去除没有给信息的筛选条件
      conditions = conditions.filter((c) => c.key && c.value && c.value.length);
      conditions.forEach((c: any) => {
        const { key, op, value } = c;
        const index = key.split('-')[1];
        tempProps.push(value.map((v: any) => `p${index}-${v}`));
      });
    }

    // 子主题筛选
    const childCondition = this.config.viewInfo.childCondition || {};
    const nowCondition = childCondition[theme] || [];
    if (nowCondition.length) tempProps = childConditionTransformToTempProps(nowCondition);

    // target 是子节点的属性位置
    const groupBy = matchings.map((matching: matchingItem) => `p${matching.origin}`);
    const filters: any = {
      groupBy,
      temp_id: theme,
    };
    if (filterChilds) filters.parentId = node.id;
    if (tempProps.length) filters.tempProps = tempProps;

    if (!isRoot) filters.parentId = nodePath;

    //@ts-ignore
    return node.nodeManager.getTempFilterNode(filters);
  }
}
