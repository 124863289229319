import { useMemoizedFn, useRequest } from 'ahooks';

import { searchNodes } from '@/services/node';

import { useCurrentOrgId, useOrgList } from './useOrg';

export function useSearchNodes() {
  const orgList = useOrgList();
  const orgId = useCurrentOrgId();

  const {
    data = [],
    loading,
    runAsync,
    mutate,
  } = useRequest(searchNodes, {
    manual: true,
    async onSuccess(list, params) {
      if (list && list.length < 50) {
        const newList =
          (await searchNodes({
            ...params[0],
            hardlimit: 50 - list.length,
            org_id: orgList.map((o) => o.orgId).filter((id) => id !== orgId),
          })) || [];
        mutate([...list, ...newList]);
      }
    },
  });

  const fetchNodes = useMemoizedFn((searchText: string) =>
    runAsync({
      org_id: [orgId],
      keyWords: searchText.split(' ').filter(Boolean),
      hardlimit: 50,
    }),
  );

  return { data, loading, mutate, fetchNodes };
}
