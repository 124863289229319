import { SearchOutlined } from '@ant-design/icons';
import type { InputProps, InputRef } from 'antd';
import { Input } from 'antd';
import cls from 'classnames';
import type { ForwardRefRenderFunction } from 'react';
import { forwardRef } from 'react';

import styles from './style.module.less';

const _SearchBar: ForwardRefRenderFunction<InputRef, InputProps> = (props, ref) => {
  return (
    <Input
      prefix={<SearchOutlined style={{ color: '#cfd6de' }} />}
      {...props}
      ref={ref}
      className={cls(props.className, styles.wrapper)}
    />
  );
};

export const SearchBar = forwardRef(_SearchBar);
