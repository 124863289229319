import { Popover } from 'antd';
import JsBarcode from 'jsbarcode';
import QRCode from 'qrcode';
import { useState } from 'react';

import {
  exportGraphicCodes,
  getExportGraphicCodesPropValues,
  strExistSpecialCode,
} from '@/utils/utils';

import styles from './styles.less';

interface IProps {
  setGraphicCodeInfo: (v: any) => void;
  graphicCodeInfo: any;
}

export default (props: IProps) => {
  const { graphicCodeInfo, setGraphicCodeInfo } = props;
  if (!graphicCodeInfo) return null;

  const { position, text, node, prop } = graphicCodeInfo;
  const { graphicCodeFormat, exportContent } = prop.canExportGraphicCodeParams || {
    graphicCodeFormat: '二维码', // 二维码
    exportContent: 0, // 单码
  };

  const [open, setOpen] = useState<boolean>(true);

  const changeOpen = (v: boolean) => {
    if (!v) return setGraphicCodeInfo(null);
    setOpen(v);
  };
  const exportPropGraphicCode = async () => {
    const propValues = getExportGraphicCodesPropValues([node], [prop]);
    await exportGraphicCodes(propValues);
    setGraphicCodeInfo(null);
  };

  const getGraphicCodeSrc = () => {
    const graphicCodeCanvas = document.createElement('canvas');
    if (graphicCodeFormat === '二维码') QRCode.toCanvas(graphicCodeCanvas, text);
    else {
      if (!strExistSpecialCode(String(text))) {
        JsBarcode(graphicCodeCanvas, text, {
          format: graphicCodeFormat.toUpperCase(),
          displayValue: !!exportContent,
        });
      }
    }
    return graphicCodeCanvas.toDataURL('image/png');
  };

  const renderContent = () => {
    return (
      <div className={styles.container}>
        <img style={{ height: 150, width: 150 }} src={getGraphicCodeSrc()} alt="" />
        <div className={styles.text}>{text}</div>
        <div className={styles.exportGraphicCode}>
          <span onClick={exportPropGraphicCode}>导出</span>
        </div>
      </div>
    );
  };

  return (
    <Popover
      placement="top"
      visible={open}
      onVisibleChange={changeOpen}
      content={renderContent()}
      overlayClassName={styles.popover}
    >
      <div
        style={{
          position: 'absolute',
          height: position.height,
          width: position.width,
          left: position.x,
          top: position.y,
        }}
      />
    </Popover>
  );
};
