import { useRef } from 'react';

import NodeStatusModal from '@/pages/home/components/View/ListTable/NodeStatusModal';
import { showTransformStatusBtn } from '@/utils/utils';

import '../GroupForView/styles.less';
export default (props: any) => {
  const { currentUser, template, checkId, userMap, orgInfo, setCheckId, getterPiNode } = props;

  const allowTransformStatus = () => {
    if (!currentUser || !userMap) return false;
    const userid = currentUser.userid;
    const user = userMap[userid];
    if (!user) return false;
    return showTransformStatusBtn(template, user.group_ids);
  };

  if (checkId.length < 1 || !allowTransformStatus()) return null;
  const nodeStatusModalRef = useRef<any>();

  const onBatchChangeStatus = () => {
    nodeStatusModalRef.current.open(checkId, template, getterPiNode);
  };
  return (
    <>
      <div className="view-setting-block" onClick={onBatchChangeStatus}>
        <i className={`iconfont icona-View_changestatus`} />
        &nbsp;状态转换
      </div>
      <NodeStatusModal
        ref={nodeStatusModalRef}
        userMap={userMap}
        orgId={orgInfo.orgId}
        onHandle={() => setCheckId([])}
      />
    </>
  );
};
