import AMapLoader from '@amap/amap-jsapi-loader';
import type { ForwardRefRenderFunction } from 'react';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef } from 'react';

import { isChrome } from '@/utils/utils';

import type { GaodeAddrDataType } from './utils';

import styles from './styles.less';

export type MapEditorHandleType = {
  /** 设置并移动点 */
  select: ([lng, lat]: [number, number], isFixOnChange?: boolean) => void;

  /** 移动到当前位置 */
  selectCurrentPosition: () => void;

  /** 通过关键字搜索 */
  search: (text: string) => Promise<GaodeAddrDataType[]>;

  /** 通过经纬度获取附近 */
  searchNearBy: ([lng, lat]: [number, number]) => Promise<GaodeAddrDataType[]>;

  /** 加载下一页 */
  loadNextPage: () => Promise<GaodeAddrDataType[]> | [];

  /** 获取中心点 */
  getCenter: () => { lng: number; lat: number };
};

let _containerId = 0;

const MapEditor: ForwardRefRenderFunction<
  MapEditorHandleType,
  {
    onLoad: (data: any) => void;
    onChange: ([lng, lat]: [number, number]) => void;
    initCenter?: [number, number];
    markers: SysLocationItemType[];
    disableChange: boolean;
  }
> = (props, ref) => {
  useImperativeHandle(ref, () => ({
    select([lng, lat]: [number, number], isFixOnChange) {
      if (isFixOnChange) {
        fixOnChange.current = true;
      }
      currentMarkerRef.current.setPosition([lng, lat]);
      mapRef.current.setCenter([lng, lat]);
    },
    selectCurrentPosition() {
      // 判断是否是chrome chrome直接触发onchange
      if (isChrome) {
        console.log('isChrome onselect center');
        const center = mapRef.current.getCenter();
        onChange([center.lng, center.lat]);
        return;
      }
      console.log('getCurrentPosition');
      geolocationRef.current.getCurrentPosition();
    },
    loadNextPage() {
      // 最多只拿10页
      if (loadDataInfo.current.page == 10) return [];
      loadDataInfo.current.page++;
      return loadDataInfo.current.type === 'search' ? handleSearch() : handleSearchNearBy();
    },
    async searchNearBy([lng, lat]: [number, number]) {
      loadDataInfo.current = {
        page: 1,
        type: 'searchNearBy',
        searchLngLat: [lng, lat],
      };

      return handleSearchNearBy();
    },
    async search(text: string) {
      loadDataInfo.current = {
        page: 1,
        type: 'search',
        searchText: text,
      };
      return handleSearch();
    },
    getCenter() {
      return mapRef.current.getCenter();
    },
  }));

  const { onLoad, onChange, initCenter, markers, disableChange } = props;

  const AMapRef = useRef<any>();
  const mapRef = useRef<any>();
  const placeSearchRef = useRef<any>();
  const geocoderRef = useRef<any>();
  const geolocationRef = useRef<any>();
  const currentMarkerRef = useRef<any>();

  const fixOnChange = useRef(false);
  const loadDataInfo = useRef<{
    page: number;
    type: 'search' | 'searchNearBy';
    searchText?: string;
    searchLngLat?: number[];
  }>({
    page: 1,
    type: 'search',
    searchText: '',
    searchLngLat: [],
  });

  const containerId = useMemo(() => {
    return 'mapEditor_' + _containerId++;
  }, []);

  useEffect(() => {
    loadMap();
  }, []);

  const loadMap = async () => {
    const AMap = await AMapLoader.load({
      key: '4e9bb8b8e9a61a0656fe4601905cd7b7',
      version: '2.0',
      plugins: [
        'AMap.ToolBar',
        'AMap.ControlBar',
        'AMap.Geolocation',
        'AMap.PlaceSearch',
        'AMap.Geocoder',
      ],
    });
    AMapRef.current = AMap;

    // 地图
    const mapOption: any = {
      zoom: 13,
      resizeEnable: true, //初始化地图时，若center属性缺省，地图默认定位到用户所在城市的中心
    };
    if (initCenter) {
      mapOption.center = initCenter;
    }
    if (disableChange) {
      mapOption.keyboardEnable = false; //地图是否可通过键盘控制，默认为true
    }

    const map = new AMap.Map(containerId, mapOption);
    mapRef.current = map;

    // 罗盘
    const controlBar = new AMap.ControlBar({
      position: {
        bottom: '150px',
        right: '10px',
      },
    });
    map.addControl(controlBar);

    // 放大缩小
    const toolBar = new AMap.ToolBar({
      position: {
        bottom: '40px',
        right: '40px',
      },
    });
    map.addControl(toolBar);

    // 定位
    const geolocation = new AMap.Geolocation({
      position: {
        bottom: '110px',
        right: '39px',
      },
      showCircle: false, // 不显示表示精度的蓝色圆环范围
    });
    map.addControl(geolocation);
    geolocationRef.current = geolocation;

    AMap.Event.addListener(geolocation, 'complete', (e: any) => {
      if (disableChange) return;
      //返回定位信息
      console.log('geolocation complete', e);
      currentMarkerRef.current.setPosition(e.position);
      onChange([e.position.lng, e.position.lat]);
    });
    AMap.Event.addListener(geolocation, 'error', (err: any) => {
      //返回定位出错信息
      console.log('geolocation error', err);
    });

    // 当前位置maker
    const currentMarker = new AMap.Marker({
      map: map,
      icon: new AMap.Icon({
        size: new AMap.Size(32, 40),
        imageSize: new AMap.Size(32, 40),
        image: 'http://ljp-static.oss-cn-hangzhou.aliyuncs.com/locationCenter.png',
      }),
      position: map.getCenter(),
      offset: new AMap.Pixel(-16, -29),
    });
    currentMarkerRef.current = currentMarker;

    // 辅助显示 maker
    if (markers.length) {
      console.log('markers', markers);
      markers.map((x) => {
        new AMap.Marker({
          map: map,
          position: [x.lng, x.lat],
          icon: new AMap.Icon({
            size: new AMap.Size(24, 30), // 图标尺寸
            imageSize: new AMap.Size(24, 30), // 根据所设置的大小拉伸或压缩图片
            image: 'http://ljp-static.oss-cn-hangzhou.aliyuncs.com/location_marker.png', // Icon的图像
          }),
          offset: new AMap.Pixel(-12, -21),
        });
      });
      // 自适应地图 让marker都显示
      map.setFitView();
    }

    // 搜索
    const placeSearch = new AMap.PlaceSearch({
      type: '餐饮服务|购物服务|体育休闲服务|医疗保健服务|住宿服务|风景名胜|商务住宅|政府机构及社会团体|科教文化服务|交通设施服务|公司企业|道路附属设施|公共设施',
      extensions: 'all',
    });
    placeSearchRef.current = placeSearch;

    // 地址转换
    const geocoder = new AMap.Geocoder({});
    geocoderRef.current = geocoder;

    if (!disableChange) {
      map.on('click', (e: any) => {
        // 点击 移动到改位置
        const point: [number, number] = [e.lnglat.lng, e.lnglat.lat];
        currentMarkerRef.current.setPosition(point);
        map.setCenter(point);
        onChange(point);
      });
    }

    onLoad({ AMap });
  };

  const handleSearch = async (): Promise<GaodeAddrDataType[]> => {
    return new Promise((r, f) => {
      const { page, searchText } = loadDataInfo.current;
      placeSearchRef.current.setPageIndex(page);
      placeSearchRef.current.search(searchText, function (status: any, result: any) {
        console.log('handleSearch', searchText, status, result);
        if (status === 'complete') {
          r(result.poiList.pois);
        } else {
          r([]);
        }
      });
    });
  };

  const handleSearchNearBy = async (): Promise<GaodeAddrDataType[]> => {
    return new Promise((r, f) => {
      const { page, searchLngLat } = loadDataInfo.current;
      placeSearchRef.current.setPageIndex(page);
      placeSearchRef.current.searchNearBy(
        '',
        searchLngLat,
        2000,
        function (status: string, result: any) {
          console.log('handleSearchNearBy', searchLngLat, status, result);
          if (status === 'complete') {
            r(result.poiList.pois);
          } else {
            r([]);
          }
        },
      );
    });
  };

  return (
    <div className={styles.mapEditor}>
      <div id={containerId} className={styles.mapContainer} />
    </div>
  );
};

export default forwardRef(MapEditor);
