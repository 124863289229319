import type { ModalProps } from 'antd';
import { Divider, Modal } from 'antd';
import type { FC, ReactChildren, ReactElement, ReactNode } from 'react';

import './index.less';

export type LinkPiModalProps = Pick<
  ModalProps,
  | 'visible'
  | 'open'
  | 'onCancel'
  | 'onOk'
  | 'width'
  | 'title'
  | 'maskClosable'
  | 'zIndex'
  | 'centered'
  | 'okText'
  | 'footer'
  | 'className'
  | 'afterClose'
> & {
  subTitle?: string;
  modalContent?: ReactChildren | ReactElement | ReactNode;
  loading?: boolean;
  noFooter?: boolean;
  height?: string | number;
};
const LinkPiModal: FC<React.PropsWithChildren<LinkPiModalProps>> = (props) => {
  const {
    height,
    visible,
    open,
    onCancel,
    onOk,
    width = 548,
    title,
    subTitle,
    modalContent,
    className,
    maskClosable,
    loading,
    noFooter,
    zIndex,
    centered,
    children,
    okText,
    footer,
    afterClose,
  } = props;

  const modalProps: ModalProps = {
    afterClose,
    onCancel,
    open: open ?? visible,
    destroyOnClose: true,
    // @ts-ignore
    height,
    width: width || 520,
    maskStyle: { backgroundColor: 'rgba(0, 0, 0, 0.2)' },
    maskClosable: maskClosable || false,
    closable: !!title,
    closeIcon: <i className="iconfont iconButton_cancel" />,
    wrapClassName: `link-pi-modal ${className} ${title ? '' : 'no-header'}`,
    okButtonProps: { loading: loading },
    zIndex: zIndex || 1000,
    centered: centered || false,
    okText: okText || '确定',
    title: title ? (
      <div className="link-modal-title">
        <div style={{ width: 'calc(100% - 56px)' }} className="link-modal-title-main text-omit">
          {title}
        </div>
        {subTitle ? (
          <>
            <Divider type="vertical" />
            <div className="link-modal-title-sub text-omit">{subTitle}</div>
          </>
        ) : null}
      </div>
    ) : null,
  };

  if (noFooter) {
    modalProps.footer = null;
  } else if (footer) {
    modalProps.footer = footer;
  }

  return (
    <div className="footer-wrap">
      <Modal {...modalProps} onOk={onOk}>
        {modalContent || children}
      </Modal>
    </div>
  );
};

export default LinkPiModal;
