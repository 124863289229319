import type { CurrentUser } from '@linkpi/core';
import type { ViewList } from '@linkpi/core';
import type { GetterPiNode } from '@linkpi/core/web';
import { type FC, useEffect, useMemo, useState } from 'react';

import { showNodeImportModal } from '@/pages/home/components/NodeImport';

import styles from './styles.less';
export const ImportNodesForView: FC<{
  getterNode: GetterPiNode;
  templateList: CurrentUser.TemplateInfo[];
  orgInfo: CurrentUser.OrgInfo;
  curViewData: ViewList.ViewListItem<2>;
  isDark?: boolean;
}> = ({ getterNode, templateList, orgInfo, curViewData, isDark }) => {
  // 子表格主题类型
  const [childTemp, setChildTemp] = useState<CurrentUser.TemplateInfo[]>([]);
  const [curTemplate, setCurTemplate] = useState<CurrentUser.TemplateInfo | null>(null);

  // 初始化配置
  useEffect(() => {
    let temp: any = null;
    let childTemp: any[] = [];
    if (curViewData.view_info) {
      const hasTempId = curViewData.view_info.condition?.find((y) => y?.key === 'templateId');
      if (hasTempId) {
        temp = templateList.find((x) => x?.template_id === hasTempId.value);
        if (temp && curViewData.view_info.customTableId) {
          const customTable = temp.custom_view?.data?.find(
            (x) => x.id === curViewData.view_info.customTableId,
          );
          if (customTable?.secondaryAreasList && customTable.secondaryAreasList.length) {
            childTemp = customTable.secondaryAreasList
              .map((x) => templateList.find((t) => t?.template_id === x?.[0]?.theme))
              .filter((x) => !!x);
          }
        }
      }
      setCurTemplate(temp);
      setChildTemp(childTemp);
    }
  }, [curViewData.view_info, templateList]);

  const allowTemplateList = useMemo(
    () => (curTemplate ? [curTemplate, ...childTemp] : []),
    [childTemp, curTemplate],
  );

  return (
    <div
      style={{ fontSize: 12 }}
      className={'link-pi-btn btn-lg' + (isDark ? ' ' + styles.darkBlock : '')}
      onClick={() =>
        showNodeImportModal({
          parentId: getterNode.value.id,
          node: getterNode,
          templateList: allowTemplateList,
          orgInfo,
        })
      }
    >
      <i className={`iconfont iconContent_Download icon-left-4`} />
      导入表格
    </div>
  );
};
