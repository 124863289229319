import type { PositionType } from '../components/_baseComponent';
import GridBaseComponent from '../components/_baseComponent';
import type { PiGridModules } from '../core';

class QuoteItem extends GridBaseComponent {
  public data: any;
  public type: string = 'quoteItem';
  public fontSize: number = 14;
  public captrue: boolean = false;
  public colorAndBold: { color: string; bold: boolean } = { color: 'black', bold: false };

  constructor(
    position: PositionType,
    moduleInstances: PiGridModules,
    data: any,
    fontSize?: number,
  ) {
    super(position, moduleInstances);

    this.data = data;
    this.colorAndBold = data.colorAndBold || { color: 'black', bold: false };
    if (fontSize) this.fontSize = fontSize;
  }

  public click() {
    this.moduleInstances.DataManager.config.allowToOpenNode(this.data);
  }

  public drawLine() {
    const { Draw } = this.moduleInstances;
    const { x, y, height, width } = this.position;

    // underline
    const underlineStart = [x - 1, y + height + 1] as [number, number];
    const underlineEnd = [x + width + 2, y + height + 1] as [number, number];
    Draw.line([underlineStart, underlineEnd], this.colorAndBold.color);
  }

  public drawText(text: string, color: string, x: number, y: number, fontSize?: number) {
    const { Draw } = this.moduleInstances;

    Draw.attr({
      fillStyle: color,
      textBaseline: 'top',
      textAlign: 'start',
      font: `${this.colorAndBold.bold ? 'bold ' : ''}${Draw.npx(fontSize || 14)}px  sans-serif`,
    });

    Draw.fillText(text, x, y);
    return Draw.measureTextWidth(text);
  }

  public render() {
    const { Render } = this.moduleInstances;
    const { x, y } = this.position;
    const { value } = this.data;

    this.drawText(value, this.colorAndBold.color, x, y, this.fontSize);

    if (this.isHover) {
      Render.setCursor('pointer');
      this.drawLine();
    }
  }
}

export default QuoteItem;
