export const STYLE_CONFIG = {
  backgroundColor: '#fff',
  borderColor: '#f6f6f6',
  head: {
    backgroundColor: '#fff',
  },
  headLeft: {
    color: '#767C88',
  },
  rangeSelect: {
    color: '#98a2b6',
    hoverColor: '#316ef5',
    backgroundColor: '#f7f9fb',
    hoverBackgroundColor: '#f4f7fd',
  },
  dateColumn: {
    weekBackgroundColor: '#FBFBFC',
    todayLineBorderColor: '#316EF5',
    fullDateColor: '#767C88',
    dateColor: '#767C88',
    todayDateColor: '#fff',
    todayDateBackgroundColor: '#316EF5',
  },
  node: {
    hoverBackgroundColor: '#dde1ea33',
    color: '#242E41',
    groupArrowColor: '#6b7a96',
    groupNumColor: '#98a2b6',
    groupColor: '#312D2D',
    titleColor: '#242E41',
  },
  previewButton: {
    backgroundColor: '#eff1f5',
    color: '#6b7a96',
    hoverBackgroundColor: '#eff1f5',
    hoverColor: '#6b7a96',
  },
  addButton: {
    color: '#6b7a96',
  },
  pagination: {
    backgroundColor: '#e3e5ea',
    color: '#fff',
    hoverBackgroundColor: '#316ef5',
    hoverColor: '#fff',
  },
};

export const STYLE_CONFIG_DARK = {
  backgroundColor: 'rgba(30,32,38,1)',
  borderColor: 'rgba(255, 255, 255, 0.05)',
  head: {
    backgroundColor: 'rgba(255, 160, 47, 0.1)',
  },
  headLeft: {
    color: 'rgba(255, 160, 47, 1)',
  },
  rangeSelect: {
    color: 'rgba(255, 160, 47, 0.4)',
    hoverColor: 'rgba(255, 160, 47, 1)',
    backgroundColor: 'rgba(255, 160, 47, 0.1)',
    hoverBackgroundColor: 'rgba(255, 160, 47, 0.1)',
  },
  dateColumn: {
    weekBackgroundColor: 'rgba(214, 224, 255, 0.03)',
    todayLineBorderColor: '#fff',
    fullDateColor: 'rgba(255, 160, 47, 1)',
    dateColor: 'rgba(255, 160, 47, 1)',
    todayDateColor: 'rgba(36, 45, 63, 1)',
    todayDateBackgroundColor: 'rgba(255, 160, 47, 1)',
  },
  node: {
    hoverBackgroundColor: 'rgba(190, 206, 255, 0.05)',
    color: 'rgba(202, 207, 222, 1)',
    groupArrowColor: '#FFFFFF33',
    groupNumColor: 'rgba(214, 224, 255, 0.30)',
    groupColor: '#fff',
    titleColor: '#fff',
  },
  previewButton: {
    backgroundColor: 'rgba(36, 45, 63, 0.80)',
    color: 'rgba(49, 110, 245, 1)',
    hoverBackgroundColor: 'rgba(49, 110, 245, 1)',
    hoverColor: '#fff',
  },
  addButton: {
    color: 'rgba(214, 224, 255, 1)',
  },
  pagination: {
    backgroundColor: 'rgba(36, 45, 63, 0.80)',
    color: 'rgba(214, 224, 255, 0.60)',
    hoverBackgroundColor: 'rgba(36, 45, 63, 0.80)',
    hoverColor: 'rgba(49, 110, 245, 1)',
  },
};
