import { Spin } from 'antd';
import cls from 'classnames';
import { throttle } from 'lodash';
import { Fragment, useCallback, useRef } from 'react';

import type { GaodeAddrDataType } from './utils';
import { isEqualPoint } from './utils';

import styles from './styles.less';

const AddrList = (props: {
  keyword: string;
  value: SysLocationItemType | null;
  dataSource: GaodeAddrDataType[];
  loading: boolean;
  onClick: (data: GaodeAddrDataType) => void;
  onLoadNextPage: () => void;
}) => {
  const { loading, dataSource, value, keyword, onClick, onLoadNextPage } = props;

  const scrollListRef = useRef<HTMLDivElement>(null);
  const renderAddrName = (name: string) => {
    if (!keyword) return name;
    const splitText = name.split(keyword);
    return splitText.map((x, i) => {
      return i < splitText.length - 1 ? (
        <Fragment key={i}>
          <span>{x}</span>
          <span className={styles.highlight}>{keyword}</span>
        </Fragment>
      ) : (
        x
      );
    });
  };

  const throttleScroll = useCallback(
    throttle(() => {
      if (!scrollListRef.current) return;
      if (
        scrollListRef.current.clientHeight + scrollListRef.current.scrollTop >=
        scrollListRef.current.scrollHeight
      ) {
        onLoadNextPage();
      }
    }, 200),
    [],
  );

  return (
    <div className={styles.addrList} ref={scrollListRef} onScroll={throttleScroll}>
      {loading ? (
        <Spin style={{ width: '100%', marginTop: 20 }} />
      ) : (
        dataSource.map((x, i) => (
          <div
            className={cls(styles.addrListItem, isEqualPoint(value, x) ? styles.curItem : '')}
            key={i}
            onClick={() => onClick(x)}
          >
            <p className={styles.addrListItemName}>{renderAddrName(x.name)}</p>
            <p className={styles.addrListItemAddr}>{x.address}</p>
            <i className={cls('iconfont iconselected', styles.selectIcon)} />
          </div>
        ))
      )}
    </div>
  );
};

export default AddrList;
