import { clone } from 'ramda';
import { useCallback, useMemo } from 'react';
import { match, P } from 'ts-pattern';

import type { IWidgetInstanceData } from '@/components/PageModelEditor';
import { cn } from '@/utils/utils';

import GridComponent from './_Grid';

const ColumnCount = 12;
const SectionHeight = 32;
const Gap = 8;
const Padding = 4;

interface GridProps {
  style?: React.CSSProperties;
  className?: string;
  itemWrapperStyle?: React.CSSProperties;
  itemWrapperClassName?: string;
  widgetTree: IWidgetInstanceData[];
  itemRender?: (data: IWidgetInstanceData) => React.ReactNode;
  rowHeight?: number;
}

// FIXME 如果是下图情况会有问题
/**
 * A B B
 * A B B
 * A C C
 * A C C
 * A · ·
 */
const convertToGrid = (widgetList: IWidgetInstanceData[]): IWidgetInstanceData[][] => {
  const rows = new Map<number, IWidgetInstanceData[]>();

  widgetList.forEach((widget) => {
    const y = widget.rect.y;

    if (!rows.has(y)) {
      rows.set(y, []);
    }

    rows.get(y)!.push(widget);
  });

  return Array.from(rows.values()).sort((a, b) => a[0].rect.y - b[0].rect.y);
};

const convertToList = (widgetTree: IWidgetInstanceData[]): IWidgetInstanceData[] => {
  const _widgetList = clone(widgetTree);
  _widgetList.sort((w1, w2) => w1.rect.y - w2.rect.y);

  _widgetList.forEach((widget, index) => {
    match(widget).with({ widgetId: 'DynamicHeightSection' }, () => {
      const offset = widget.rect.h - 1;
      widget.rect.h = 1;
      _widgetList.slice(index + 1).forEach((_widget) => {
        _widget.rect.y -= offset;
      });
    });
  });

  return _widgetList;
};

export const Grid = (props: GridProps) => {
  const {
    style = {},
    className,
    itemWrapperStyle = {},
    itemWrapperClassName,
    widgetTree,
    itemRender,
    rowHeight = 32,
  } = props;
  const widgetGird = useMemo(() => convertToGrid(widgetTree), [widgetTree]);
  const widgetList = useMemo(() => convertToList(widgetTree), [widgetTree]);

  const getAreaName = (id: string) => `栅格-${id}`;

  const getGridAreas = useCallback(() => {
    const maxWidth = ColumnCount;
    const maxHeight = widgetList.reduce((max, widget) => {
      const { y, h } = widget.rect;
      return Math.max(max, y + h);
    }, 0);

    const areas = Array.from({ length: maxHeight }, () => []) as string[][];
    const rows = areas.map(() => `${rowHeight}px`);

    widgetList.forEach((widget) => {
      const {
        id,
        rect: { x, y, w, h },
      } = widget;
      match(widget)
        .with({ widgetId: 'DynamicHeightSection' }, () => {
          rows[y + h - 1] = 'auto';
        })
        .otherwise(() => null);
      for (let row = 0; row < h; row++) {
        for (let col = 0; col < w; col++) {
          if (!areas[y + row]) areas[y + row] = [];
          areas[y + row][x + col] = getAreaName(id);
        }
      }
    });

    areas.forEach((row) => {
      for (let col = 0; col < maxWidth; col++) {
        if (!row[col]) {
          row[col] = '.';
        }
      }
    });

    return {
      areas,
      rows,
      cols: Array.from({ length: maxWidth }, () => '1fr'),
    };
  }, [rowHeight, widgetList]);

  const gridStyle: React.CSSProperties = useMemo(() => {
    const { areas, rows } = getGridAreas();
    // const containerAreaStyle = widgetGird.reduce(
    //   (result, row) => {
    //     const { rowMaxHeight, rowTemplateArea } = row.reduce(
    //       (rowResult, widget) => {
    //         rowResult.rowTemplateArea.fill(
    //           `栅格-${widget.id}`,
    //           widget.rect.x,
    //           widget.rect.x + widget.rect.w,
    //         );
    //         match(widget)
    //           .with({ widgetId: 'DynamicHeightSection' }, () => {
    //             rowResult.rowMaxHeight = -1;
    //           })
    //           .with(
    //             { widgetId: P.string, rect: { h: P.number.gt(rowResult.rowMaxHeight) } },
    //             () => {
    //               rowResult.rowMaxHeight = widget.rect.h;
    //             },
    //           )
    //           .otherwise(() => null);

    //         return rowResult;
    //       },
    //       {
    //         rowMaxHeight: -1,
    //         rowTemplateArea: Array.from({ length: ColumnCount }).fill('.') as string[],
    //       },
    //     );

    //     const gridTemplateRow = match(rowMaxHeight)
    //       .with(-1, () => 'auto')
    //       .otherwise(() => `${Math.max(rowMaxHeight, maxRow) * SectionHeight}px`);
    //     const gridTemplateArea = rowTemplateArea.join(' ');

    //     result.gridTemplateAreas += `"${gridTemplateArea}" `;
    //     result.gridTemplateRows += `${gridTemplateRow} `;

    //     return result;
    //   },
    //   {
    //     gridTemplateAreas: '',
    //     gridTemplateRows: '',
    //   },
    // );
    return {
      display: 'grid',
      gridTemplateColumns: `repeat(${ColumnCount}, 1fr)`,
      // gap: Gap,
      gridTemplateAreas: areas.map((row) => '"' + row.join(' ') + '"').join(' '),
      gridTemplateRows: rows.join(' '),
      // ...containerAreaStyle,
    };
  }, [getGridAreas]);

  return (
    <GridComponent {...getGridAreas()} style={style} className={className}>
      {widgetList.map((widget) => {
        return (
          <div
            key={getAreaName(widget.id)}
            style={{
              padding: Padding,
              ...itemWrapperStyle,
            }}
            className={cn('flex', itemWrapperClassName)}
          >
            {itemRender?.(widget) ?? widget.id}
          </div>
        );
      })}
    </GridComponent>
  );
};
