import { CloseOutlined } from '@ant-design/icons';
import { STATUS_ICON } from '@linkpi/core';
import { defaultUserAvatar } from '@linkpi/yiti';
import { Input, Modal, Steps, Switch } from 'antd';
import dayjs from 'dayjs';
import type { FC } from 'react';
import { useEffect, useMemo, useState } from 'react';
import ReactQuill from 'react-quill';

import { previewImages, previewWebOffice } from '@/components';
import { FILE_TYPE_MAP } from '@/components/WebOfficeModal/constants';
import { useOrgUserMap } from '@/hook';
import { getStatusLog } from '@/services/home';
import request from '@/utils/request';
import { getAttachmentValue, getFileTypeIconByUrl, getUserNickName } from '@/utils/utils';

import styles from './styles.less';

interface IProps {
  node: PiNode;
  closeModal: () => void;
  orgId: string;
}

const GameInfoVersion: FC<IProps> = (props) => {
  const { TextArea } = Input;
  const { node, closeModal, orgId } = props;
  const userMap = useOrgUserMap();

  const [showStatusLogs, setShowStatusLogs] = useState(false);
  const [showCommitModal, setShowCommitModal] = useState(false);
  const [statusLogs, setStatusLogs] = useState([]);
  const [commit, setCommit] = useState('');

  const toppingDetails = [
    {
      label: '发布单位',
      index: 2,
      icon: 'iconfont iconrelease',
      linearGradient: 'linear-gradient( 135deg, #73A2FA 0%, #73D9A5 100%)',
      value: node.tempInfo.prop[2] || '未知单位',
    },
    {
      label: '发布时间',
      index: 21,
      icon: 'iconfont icona-releasetime',
      linearGradient: 'linear-gradient( 309deg, #EEB472 0%, #F38CA7 100%)',
      value: node.tempInfo.prop[21] ? dayjs(node.tempInfo.prop[21]).format('YYYY/MM/DD HH:mm') : '',
    },
    {
      label: '资询电话',
      index: 24,
      icon: 'iconfont iconphone',
      linearGradient: 'linear-gradient( 135deg, #73A2FA 0%, #73D9A5 100%)',
      value: node.tempInfo.prop[24] || '暂无',
    },
  ];

  const propNames = [
    { label: '赛事名称', index: 1 },
    { label: '发布单位', index: 2 },
    { label: '竞赛规则', index: 3 },
    { label: '赛程发布', index: 4 },
    { label: '赛事服务', index: 5 },
    { label: '组别/项目', index: 23 },
    { label: '承办方确认函', index: 6 },
    { label: '活动时间', index: [16, 17] },
    { label: '报名时间', index: [14, 15] },
    { label: '赛事标签', index: 7 },
    { label: '赛事城市', index: 9 },
    { label: '赛事场馆', index: 25 },
    { label: '活动备注', index: 18 },
    { label: '赛事成绩', index: 10 },
    { label: '活动照片', index: 12 },
  ];

  const getUserConfig = (ad: string) => {
    const userInfo = userMap[ad];
    const userName = getUserNickName(userInfo);

    return {
      userName,
      userAvatar: userInfo?.avatar || defaultUserAvatar,
    };
  };

  const getStatusName = (s: number) => {
    if (s === 3) return '驳回';
    if (s === 1) return '审核通过';
    if (s === 0) return '提交审核';
    return '';
  };

  const fetchStatusLogs = async () => {
    const res: any = await getStatusLog({
      node_id: node.id,
      org_id: orgId,
      temp_id: node.tempInfo.id,
    });

    if (res.status === 'ok') {
      setStatusLogs(
        (res.data || [])
          .filter((i: any) => i.s !== 2) // 已取消的不用展示
          .map((log: any, index: number) => {
            const { ad, s, time, p } = log;
            const { userAvatar, userName } = getUserConfig(ad);
            const statusCommit = (p || [])[4]; // 备注

            return {
              title: (
                <div style={{ color: '#767C88', fontSize: 12 }}>
                  {dayjs(time).format('YYYY/MM/DD HH:mm')}
                </div>
              ),
              description: (
                <div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        backgroundImage: `url(${userAvatar})`,
                        height: 16,
                        width: 16,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        marginRight: 4,
                        borderRadius: 8,
                      }}
                    />
                    <div style={{ color: '#242E41', fontSize: 12 }}>{userName}</div>
                    <div style={{ marginLeft: 12, color: '#242E41', fontSize: 12 }}>
                      {index === 0 ? '提交申请' : getStatusName(s)}
                    </div>
                  </div>
                  {statusCommit && <div className={styles.refuseCommit}>{statusCommit}</div>}
                </div>
              ),
            };
          }),
      );
    }
  };

  useEffect(() => {
    fetchStatusLogs();
  }, []);

  const nowStatusConfig = useMemo(() => {
    const task_status = node.template?.task_status || [];
    const statusIndex = node.tempInfo.status;
    return task_status[statusIndex] || {};
  }, [node.tempInfo.status]);

  const getRangeTime = (start: any, end: any) => {
    const startFormat = start ? dayjs(Number(start)).format('YYYY/MM/DD') : '';
    const endFormat = end ? dayjs(Number(end)).format('YYYY/MM/DD') : '';

    if (startFormat && endFormat) return `${startFormat} - ${endFormat}`;
    return '';
  };

  const getQuillValue = (v: any) => {
    if (!v) return '';
    if (typeof v !== 'string') return '';

    return JSON.parse(v);
  };

  const handleAttachmentClick = (type: string, src: string, fileName: string) => {
    if (type === 'image') {
      previewImages({
        images: [{ src, alt: '' }],
        noNavbar: true,
        changeable: false,
        attribute: false,
        scalable: false,
        noClose: true,
      });

      return;
    }

    if (!FILE_TYPE_MAP[type]) return;
    previewWebOffice({
      url: src,
      fileName,
      orgId,
    });
  };

  const renderValue = (item: any) => {
    if (['赛事成绩', '活动照片'].includes(item.label)) {
      return (
        <div className={styles.value}>
          <a
            style={{ textDecoration: 'underline' }}
            target="_blank"
            href={node.tempInfo.prop[item.index]}
            rel="noreferrer"
          >
            {node.tempInfo.prop[item.index]}
          </a>
        </div>
      );
    }
    if (item.label === '活动时间' || item.label === '报名时间')
      return (
        <div className={styles.value}>
          {getRangeTime(node.tempInfo.prop[item.index[0]], node.tempInfo.prop[item.index[1]])}
        </div>
      );

    if (item.label === '赛事标签')
      return (
        <div style={{ display: 'flex', alignItems: 'center' }} className={styles.value}>
          {(node.tempInfo.prop[item.index] || []).map((t: any) => (
            <div className={styles.tag}>{t}</div>
          ))}
        </div>
      );

    if (item.label === '承办方确认函') {
      const files = getAttachmentValue(node, item.index);
      return (
        <div style={{ display: 'flex', alignItems: 'center' }} className={styles.value}>
          {files.map((f) => {
            const { src, type: attachmentType } = getFileTypeIconByUrl(f.src);
            return (
              <div
                onClick={() => handleAttachmentClick(attachmentType, src, f.fileName)}
                style={{
                  backgroundImage: `url(${src})`,
                  height: 50,
                  width: 50,
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  marginRight: 8,
                  borderRadius: 8,
                  cursor: 'pointer',
                }}
              />
            );
          })}
        </div>
      );
    }

    if (['竞赛规则', '赛程发布', '赛事服务', '组别/项目'].includes(item.label))
      return (
        <div className={styles.value}>
          <ReactQuill
            value={getQuillValue(node.tempInfo.prop[item.index])}
            readOnly={true}
            modules={{
              toolbar: false,
            }}
          />
        </div>
      );
    return <div className={styles.value}>{node.tempInfo.prop[item.index]}</div>;
  };

  const refuse = () => {
    setShowCommitModal(true);
  };

  const pass = async () => {
    await request('/docapi/setAction', {
      method: 'POST',
      data: {
        org_id: orgId,
        node: node.id,
        temp_id: node.tempInfo.id,
        status: 1,
      },
    });

    fetchStatusLogs();
  };

  const submit = async () => {
    await request('/docapi/setAction', {
      method: 'POST',
      data: {
        org_id: orgId,
        node: node.id,
        temp_id: node.tempInfo.id,
        status: 3,
        statusCommit: commit,
      },
    });

    setShowCommitModal(false);
    fetchStatusLogs();
  };

  const renderLabel = (item: any) => {
    if (item.label === '赛事成绩')
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className={styles.label}>{item.label}</div>
          <Switch disabled checked={node.tempInfo.prop[11] === '是'} />
        </div>
      );

    if (item.label === '活动照片')
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div className={styles.label}>{item.label}</div>
          <Switch disabled checked={node.tempInfo.prop[13] === '是'} />
        </div>
      );

    return <div className={styles.label}>{item.label}</div>;
  };

  const renderNowStatusBtn = () => {
    // @ts-ignore
    const { backgroundColor, color } = STATUS_ICON[nowStatusConfig.icon] || {};
    return (
      <div style={{ backgroundColor }} className={styles.nowStatusBtn}>
        <i style={{ color }} className={`iconfont ${nowStatusConfig.icon}`} />
        <span style={{ color, marginLeft: 4 }}>{nowStatusConfig.name}</span>
      </div>
    );
  };
  return (
    <Modal
      title={null}
      width={1024}
      closable={false}
      footer={null}
      bodyStyle={{ padding: 0 }}
      open
      onCancel={closeModal}
    >
      <div className={styles.main}>
        <div style={{ flex: 1 }}>
          <div className={styles.header}>
            <div className={styles.title}>赛事发布申请</div>
            {!showStatusLogs && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div onClick={() => setShowStatusLogs(true)} className={styles.showStatusLogsBtn}>
                  处理记录
                </div>
                <CloseOutlined
                  onClick={closeModal}
                  style={{ color: '#6B7A96', cursor: 'pointer' }}
                />
              </div>
            )}
          </div>
          <div className={styles.detail}>
            <div className={styles.toppingDetail}>
              {toppingDetails.map((t) => (
                <div key={t.index} className={styles.box}>
                  <div className={styles.iconDiv}>
                    <i style={{ background: t.linearGradient }} className={t.icon} />
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', color: '#242E41' }}>
                    <span style={{ fontWeight: 600, fontSize: 16, marginBottom: 4 }}>
                      {t.label}
                    </span>
                    <span>{t.value}</span>
                  </div>
                </div>
              ))}
            </div>
            {propNames.map((i) => (
              <div className={styles.item} key={i.label}>
                {renderLabel(i)}
                {renderValue(i)}
              </div>
            ))}
          </div>
        </div>
        {showStatusLogs && (
          <div className={styles.statusLogs}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginBottom: 36,
              }}
            >
              <div className={styles.title}>处理记录</div>
              <CloseOutlined
                onClick={() => setShowStatusLogs(false)}
                style={{ color: '#6B7A96', cursor: 'pointer' }}
              />
            </div>
            <Steps progressDot items={statusLogs} direction="vertical" />
          </div>
        )}
      </div>
      <div className={styles.footer}>
        {renderNowStatusBtn()}
        {nowStatusConfig.name === '待审核' && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div onClick={refuse} className={styles.refuseBtn}>
              驳回
            </div>
            <div onClick={pass} className={styles.passBtn}>
              通过
            </div>
          </div>
        )}
      </div>
      {showCommitModal && (
        <Modal
          className={styles.commitModal}
          closable={false}
          onOk={submit}
          onCancel={() => setShowCommitModal(false)}
          title={null}
          open
          width={460}
        >
          <div style={{ color: '#767C88', fontSize: 14, marginBottom: 8 }}>驳回原因</div>
          <TextArea
            value={commit}
            onChange={(v) => setCommit(v.target.value)}
            placeholder="请输入…"
          />
        </Modal>
      )}
    </Modal>
  );
};

export default GameInfoVersion;
