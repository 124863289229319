const proSettings = {
  navTheme: 'dark',
  // 拂晓蓝
  primaryColor: '#2A3240',
  layout: 'side',
  contentWidth: 'Fluid',
  fixedHeader: false,
  fixSiderbar: true,
  colorWeak: false,
  menu: {
    locale: true,
  },
  title: '连接派主题｜点击访问',
  pwa: false,
  iconfontUrl: '',
};
export default proSettings;
