import { useFavicon } from 'ahooks';
import { Typography } from 'antd';
import type { FC } from 'react';
import { useEffect, useState } from 'react';

import { getBuildNumber } from './utils';

const favicon = require('@/assets/img/favicon.ico');
const faviconTest = require('@/assets/img/favicon_test.ico');
const faviconDev = require('@/assets/img/favicon_dev.ico');
const faviconPre = require('@/assets/img/favicon_pre.ico');

const { Text } = Typography;

type Props = {};

const VersionInfo: FC<React.PropsWithChildren<Props>> = () => {
  const [buildNumber, visible, type] = getBuildNumber();
  const versionText = [__VERSION_NUMBER__, buildNumber, type].join('@');

  if (!visible) {
    return <Text type="secondary">{__VERSION_NUMBER__}</Text>;
  }

  return (
    <Text type="secondary" copyable>
      {versionText}
    </Text>
  );
};

export const VersionInfoWrapper: FC<React.PropsWithChildren<any>> = (props) => {
  const [url, setUrl] = useState(favicon);
  const [buildNumber, visible, type] = getBuildNumber();
  useFavicon(url);
  useEffect(() => {
    if (visible) {
      if (type === 'local') {
        setUrl(faviconDev);
        return;
      }
      if (type === 'pre') {
        setUrl(faviconPre);
        return;
      }
      setUrl(faviconTest);
    } else {
      setUrl(favicon);
    }
  }, [visible]);

  return props.children;
};

export default VersionInfo;
