import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import type { ReactElement } from 'react';

import { VersionInfoWrapper } from '@/components/VersionInfo';

import { CustomWidgeUtilsProvider } from './components';
import { NodeContentModalProvider } from './hook';

import 'reflect-metadata';

const queryClient = new QueryClient();

export function rootContainer(container: ReactElement) {
  return (
    <NodeContentModalProvider>
      <CustomWidgeUtilsProvider>
        <QueryClientProvider client={queryClient}>
          <VersionInfoWrapper>{container}</VersionInfoWrapper>
        </QueryClientProvider>
      </CustomWidgeUtilsProvider>
    </NodeContentModalProvider>
  );
}
