import { useControllableValue } from 'ahooks';
import type { ModalProps } from 'antd';
import { Modal, Row, Typography } from 'antd';
import type { FC, ReactNode } from 'react';
import { isValidElement } from 'react';
import { cloneElement } from 'react';

import styles from './style.module.less';

type SelectModalProp = ModalProps & {
  pure?: boolean;
  value?: any[];
  onChange?: (v: any[]) => void;
  valueRender?: (v: any[]) => ReactNode | string | null;
  selectedCountRender?: (v: any[]) => ReactNode | string | null;
};

export const SelectModal: FC<React.PropsWithChildren<SelectModalProp>> = ({
  children,
  valueRender,
  selectedCountRender,
  pure = false,
  ...rest
}) => {
  const [value, onChange] = useControllableValue(rest);

  if (!isValidElement(children)) throw new Error('not a valid children');

  const props = { value, onChange };

  return (
    <Modal title="设置" width={860} {...rest} className={styles.modal}>
      <div className={styles.wrapper}>
        <div>{pure ? children : cloneElement(children, props)}</div>
        <div className={styles.rightPanel}>
          <Row justify="space-between" style={{ marginBottom: 16 }}>
            <Typography.Text>已选：{selectedCountRender?.(rest.value || [])}</Typography.Text>
            <Typography.Link onClick={() => onChange?.([])}>清空</Typography.Link>
          </Row>
          {valueRender?.(rest.value || [])}
        </div>
      </div>
    </Modal>
  );
};
