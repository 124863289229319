import type { PiGridModules } from '../core';
import type { PositionType } from './_baseComponent';
import GridBaseComponent from './_baseComponent';

class CollapseGroupFunctionCell extends GridBaseComponent {
  public data: any;
  public type: string = 'collapseGroupFunctionCell';
  private STYLE = {
    hoverBg: '#f0f0f0',
    darknessBg: 'rgba(214,224,255,0.03)',
  };
  constructor(position: PositionType, moduleInstances: PiGridModules, data: any, fixed: boolean) {
    super(position, moduleInstances, fixed);

    this.data = data;
  }

  public click() {
    const {
      DataManager: { config, existStatistics },
    } = this.moduleInstances;
    const {
      cell: { stat, key },
      row: { groupKey },
    } = this.data;

    if (existStatistics) return;
    config.showCalcPopover({ stat, cellType: key, groupKey }, this.position, 'bottom');
  }

  public hover() {}

  public render() {
    const {
      Draw,
      DataManager: { existStatistics },
    } = this.moduleInstances;
    const { x, y, height, width } = this.position;
    const styleMode = Draw.config.styleMode;
    const containerBgColor = Draw.config.contentPageGeneralTableConfig?.containerBgColor || '#fff';
    const cellBg = styleMode === 'darkness' ? containerBgColor : '#fff';

    if (existStatistics) {
      Draw.fillRect(x, y, width, height, cellBg);
      return;
    }

    const bg = this.isHover
      ? styleMode === 'darkness'
        ? this.STYLE.darknessBg
        : this.STYLE.hoverBg
      : cellBg;
    Draw.fillRect(x, y, width, height, bg);

    if (!this.isHover) return;
    Draw.attr({
      fillStyle: styleMode === 'darkness' ? '#316EF5' : '#97a1b5',
      textBaseline: 'middle',
      textAlign: 'start',
    });

    const startY = y + height / 2;

    // text
    Draw.ctx.font = `${Draw.npx(13)}px sans-serif`;
    Draw.fillText('计算', x + 10, startY);

    // icon
    Draw.ctx.font = `${Draw.npx(13)}px iconfont`;
    Draw.fillText(Draw.iconFont('&#xe6f3;'), x + 40, startY);
  }
}

export default CollapseGroupFunctionCell;
