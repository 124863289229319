import type { Dayjs } from 'dayjs';

import GanttCell, { DragDateSide } from '../components/ganttCell';
import BaseModule from './_baseModule';
import type { nodeRowData } from './nodeRowManager';

type dragCellDirectionType = 'left' | 'right' | 'up' | 'down';
type dragDateDirectionType = 'left' | 'right';

type dragCellType =
  | false
  | {
      dragCell: GanttCell;
      dragPointPositionInCell: { x: number; y: number };
      dragMoveStatus: { direction: dragCellDirectionType; step: number } | null;
    };

type dragDateType =
  | false
  | {
      dragDateSide: DragDateSide;
      dragMoveStatus: { direction: dragDateDirectionType; step: number } | null;
    };
export default class Event extends BaseModule {
  // 鼠标down下的坐标 用于判断拖拽
  private isMouseDown: boolean = false;
  public mosueDownPosition: { x: number; y: number } = { x: 0, y: 0 };
  public isDragCell: dragCellType = false;
  public isDragDate: dragDateType = false;

  public bootstrap() {
    const { Listeners, Render } = this.moduleInstances;

    Listeners.on(window, 'resize', Render.render);
    Listeners.on(Render.doms.wrapper, 'mousewheel', this.onWrapperMouseWheel);
    Listeners.on(Render.doms.wrapper, 'mousemove', this.onWrapperMouseMove);
    Listeners.on(Render.doms.wrapper, 'click', this.onWrapperClick);
    Listeners.on(Render.doms.wrapper, 'contextmenu', this.onWrapperContextmenu);

    Listeners.on(Render.doms.wrapper, 'mousedown', this.onWrapperMouseDown);
    Listeners.on(document, 'mouseup', this.onWrapperMouseUp);
  }

  // 鼠标滚轮
  private onWrapperMouseWheel = (e: WheelEvent) => {
    // TODO 判断有没有到底或者到顶
    let preventDefault = true;
    const { deltaY, deltaX } = e;

    const tempX = Math.abs(deltaX);
    const tempY = Math.abs(deltaY);
    const temp = Math.max(tempY, tempX);
    const isShiftKey = e.shiftKey;
    // 只操作一个方向
    if (temp === tempX) {
      this.handleMoveX(deltaX);
    }
    if (temp === tempY) {
      // shiftKey+滚动 == 滚动x轴
      if (isShiftKey) {
        this.handleMoveX(deltaY);
      } else {
        preventDefault = this.handleMoveY(deltaY);
      }
    }

    if (preventDefault) {
      e.preventDefault();
    }
  };

  private onWrapperMouseDown = (e: MouseEvent) => {
    const { x, y } = this.getPositionOfCanvas(e.pageX, e.pageY);
    this.mosueDownPosition = { x, y };
    this.isMouseDown = true;
  };
  private onWrapperMouseUp = (e: MouseEvent) => {
    // fix 多个实例重复触发mouseup
    if (!this.isMouseDown) return;

    const { Render, Listeners, DateColumnManager, NodeRowManager } = this.moduleInstances;
    this.isMouseDown = false;

    if (this.isDragCell) {
      // 有效拖拽
      // hack一下 当有效拖拽时，拦截下一次点击事件触发
      // 捕获阶段拦截，且只触发1次
      Listeners.on(Render.doms.wrapper, 'click', this.hackClick, {
        capture: true,
        once: true,
      });

      const { x, y } = this.getPositionOfCanvas(e.pageX, e.pageY);

      const dropCellX = x - this.isDragCell.dragPointPositionInCell.x;
      const dropY = y;

      // 开始时间
      const dropDay = DateColumnManager.xToDay(dropCellX);
      // 位置
      const rowInfo = NodeRowManager.getRowByPositionY(dropY);

      if (rowInfo && rowInfo.row.type === 'node') {
        if (
          (rowInfo.row as nodeRowData<'node'>).node.id !== this.isDragCell.dragCell.data.node.id
        ) {
          this.config.onMove(
            this.isDragCell.dragCell.data.node,
            (rowInfo.row as nodeRowData<'node'>).node,
            rowInfo.relation,
          );
        }

        const dragCellStartDay = this.isDragCell.dragCell.validDateScope[0] as Dayjs;
        const dragCellEndDay = this.isDragCell.dragCell.validDateScope[1] as Dayjs;
        const columnUnit = DateColumnManager.columnUnit;

        if (!dragCellStartDay.isSame(dropDay, columnUnit)) {
          // 根据columnUnit 修改day/month
          const diff = dropDay
            .startOf(columnUnit)
            .diff(dragCellStartDay.startOf(columnUnit), columnUnit);

          const start = dragCellStartDay.add(diff, columnUnit);
          const end = dragCellEndDay.add(diff, columnUnit);
          this.config.onDateChange(this.isDragCell.dragCell.data.node, start, end);

          // 本地更新时间记录
          NodeRowManager.setCellLocalDateChangeRecord(
            this.isDragCell.dragCell.data.node.id,
            start,
            end,
          );
          Render.render();
        }
      }

      this.isDragCell = false;
    }

    if (this.isDragDate) {
      //
      // hack click
      Listeners.on(Render.doms.wrapper, 'click', this.hackClick, {
        capture: true,
        once: true,
      });

      const { x } = this.getPositionOfCanvas(e.pageX, e.pageY);
      const dropX = x;
      const dropDay = DateColumnManager.xToDay(dropX);

      const ganttCell = this.isDragDate.dragDateSide.ganttCell;

      let newStartDate: Dayjs;
      let newEndDate: Dayjs;
      const oldStartDate = ganttCell.validDateScope[0] as Dayjs;
      const oldEndDate = ganttCell.validDateScope[1] as Dayjs;

      if (this.isDragDate.dragDateSide.direction === 'left') {
        // 开始时间
        newStartDate = dropDay;
        newEndDate = ganttCell.validDateScope[1] as Dayjs;
      } else {
        // 结束时间
        newStartDate = ganttCell.validDateScope[0] as Dayjs;
        newEndDate = dropDay;
      }

      const isDiffStart =
        this.isDragDate.dragDateSide.direction === 'left' &&
        !newStartDate.isSame(oldStartDate, DateColumnManager.columnUnit);
      const isDiffEnd =
        this.isDragDate.dragDateSide.direction === 'right' &&
        !newEndDate.isSame(oldEndDate, DateColumnManager.columnUnit);
      if (isDiffStart || isDiffEnd) {
        this.config.onDateChange(ganttCell.data.node, newStartDate, newEndDate);
        // 本地更新时间记录
        NodeRowManager.setCellLocalDateChangeRecord(
          ganttCell.data.node.id,
          newStartDate,
          newEndDate,
        );
      }

      this.isDragDate = false;
    }

    Render.render();
  };
  private hackClick = (e: MouseEvent) => {
    e.stopPropagation();
  };

  private onWrapperMouseMove = (e: MouseEvent) => {
    const { Render, Draw } = this.moduleInstances;
    if (Render.config.readOnlyMode) return;
    // on hover
    const { x, y } = this.getPositionOfCanvas(e.pageX, e.pageY);
    window.__PI__GANTT.mousePosition = { x, y };

    if (this.isMouseDown) {
      if (!this.isDragCell && !this.isDragDate) {
        const isDraging =
          Math.abs(x - this.mosueDownPosition.x) >= 5 ||
          Math.abs(y - this.mosueDownPosition.y) >= 5;
        if (isDraging) {
          const component = this.getComponentByPosition(
            this.mosueDownPosition.x,
            this.mosueDownPosition.y,
          );
          if (component instanceof DragDateSide) {
            this.isDragDate = {
              dragDateSide: component,
              dragMoveStatus: null,
            };
          }
          // 点击 拖拽ganttcell
          else if (component instanceof GanttCell) {
            this.isDragCell = {
              dragCell: component,
              dragPointPositionInCell: {
                x: this.mosueDownPosition.x - component.position.x,
                y: this.mosueDownPosition.y - component.position.y,
              },
              dragMoveStatus: null,
            };
          }
        }
      }
      if (this.isDragDate) {
        const activeX = {
          left: Render.leftPanelStyle.width + 80,
          right: Draw.canvasWidth - 80,
        };
        let deltaX = 0;
        let directionX: dragDateDirectionType = 'left';
        if (x < activeX.left) {
          deltaX = x - activeX.left;
          directionX = 'left';
        } else if (x > activeX.right) {
          deltaX = x - activeX.right;
          directionX = 'right';
        }

        if (Math.abs(deltaX) > 0) {
          this.isDragDate.dragMoveStatus = {
            direction: directionX,
            step: deltaX / 5,
          };
        } else {
          this.isDragDate.dragMoveStatus = null;
        }
      } else if (this.isDragCell) {
        // isDragCell
        const activeX = {
          left: Render.leftPanelStyle.width + 80,
          right: Draw.canvasWidth - 80,
        };
        const activeY = {
          up: 80 + 80,
          down: Draw.canvasHeight - 80,
        };

        let deltaX = 0;
        let directionX: dragCellDirectionType = 'left';
        if (x < activeX.left) {
          deltaX = x - activeX.left;
          directionX = 'left';
        } else if (x > activeX.right) {
          deltaX = x - activeX.right;
          directionX = 'right';
        }

        let deltaY = 0;
        let directionY: dragCellDirectionType = 'up';
        if (y < activeY.up) {
          deltaY = y - activeY.up;
          directionY = 'up';
        } else if (y > activeY.down) {
          deltaY = y - activeY.down;
          directionY = 'down';
        }

        const tempX = Math.abs(deltaX);
        const tempY = Math.abs(deltaY);
        const temp = Math.max(tempY, tempX);

        if (temp > 0) {
          if (temp === tempX) {
            this.isDragCell.dragMoveStatus = {
              direction: directionX,
              step: deltaX / 5,
            };
          } else {
            this.isDragCell.dragMoveStatus = {
              direction: directionY,
              step: deltaY / 5,
            };
          }
        } else {
          this.isDragCell.dragMoveStatus = null;
        }
      }
    }

    Render.render();
  };
  private onWrapperClick = (e: MouseEvent) => {
    const { x, y } = this.getPositionOfCanvas(e.pageX, e.pageY);
    const clickComponent = this.getComponentByPosition(x, y);
    clickComponent && clickComponent.onClick(x, y);
  };
  private onWrapperContextmenu = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  public handleMoveX = (delta: number, force?: boolean) => {
    /**
     * x轴无限滚动，
     * 每次滚动到 0 或者 maxwidth ，重置scroll到中心，继续叠加
     */
    const { Render, DateColumnManager, HorizontalScrollbar } = this.moduleInstances;
    const canvasWidth = this.moduleInstances.Draw.canvasWidth;
    const maxScrollWidth =
      DateColumnManager.scrollWidth - (canvasWidth - Render.leftPanelStyle.width);

    const moveDelta = force ? delta : Render.scrollDistance.left + delta;
    Render.scrollDistance.left = moveDelta;

    /**
     * TODO 需要底部滚动条直接拖动 下面注释的逻辑有问题
     * --鼠标滚动 -> handleMove delta 计算 -> scroll move -> onscroll render--
     * --拖拽scroll -> onscroll render--
     *
     * 方案2： 记录x轴滚动的屏数 -1 / 0 / 1 渲染的时候再计算
     */

    if (moveDelta < 0) {
      // 到滚动条左边
      const left = maxScrollWidth + (moveDelta % maxScrollWidth);
      // 重置滚动条位置到中间
      HorizontalScrollbar.move({ left: left });
    } else if (moveDelta > maxScrollWidth) {
      // 到滚动条右边
      const left = moveDelta % maxScrollWidth;
      // 重置滚动条位置到中间
      HorizontalScrollbar.move({ left: left });
    } else {
      // 在滚动条内
      HorizontalScrollbar.move({ left: moveDelta });
    }
  };

  public handleMoveY = (delta: number) => {
    const { VerticalScrollbar } = this.moduleInstances;
    let preventDefault = true;
    const scrollTop = VerticalScrollbar.scrollTop;
    const moveDelta = scrollTop + delta;
    if (moveDelta > 0) {
      // 到底了
      const isAtBottom =
        moveDelta >=
        VerticalScrollbar.doms.scrollbarInner.offsetHeight -
          VerticalScrollbar.doms.scrollbar.offsetHeight;
      if (isAtBottom) preventDefault = false;
      VerticalScrollbar.move({ top: moveDelta });
    } else {
      // 到顶了
      preventDefault = false;
      VerticalScrollbar.move({ top: 0 });
    }

    return preventDefault;
  };

  /**
   * 获取Event在canvas的坐标
   */
  public getPositionOfCanvas(pageX: number, pageY: number) {
    const { Render } = this.moduleInstances;
    const rect = Render.doms.container.getBoundingClientRect();
    return { x: pageX - rect.x, y: pageY - rect.y };
  }
  /**
   * 获取position位置的组件
   */
  public getComponentByPosition(x: number, y: number) {
    for (let i = window.__PI__GANTT.components.length - 1; i > -1; i--) {
      // 根据插入位置触发
      const item = window.__PI__GANTT.components[i];
      if (item.isPositionInside(x, y)) {
        return item;
      }
    }
  }

  public destroy() {
    const { Listeners, Render } = this.moduleInstances;
    Listeners.off(window, 'resize', Render.render);
    Listeners.off(Render.doms.wrapper, 'mousewheel', this.onWrapperMouseWheel);
    Listeners.off(Render.doms.wrapper, 'mousemove', this.onWrapperMouseMove);
    Listeners.off(Render.doms.wrapper, 'click', this.onWrapperClick);
    Listeners.off(Render.doms.wrapper, 'contextmenu', this.onWrapperContextmenu);
    Listeners.off(Render.doms.wrapper, 'mousedown', this.onWrapperMouseDown);
    Listeners.off(document, 'mouseup', this.onWrapperMouseUp);
  }
}
