import type { ApiResponse } from '@linkpi/core';
import { DEFAULT_AVATAR } from '@linkpi/core';
import { tempValueDisplay } from '@linkpi/core';
import { getQuoteOriginProp } from '@linkpi/core';
import { isArray } from 'lodash';

const getUser = (id: string, userMap: Record<string, ApiResponse.OrgUser.OrgUserItem>) => {
  return (
    userMap[id] || {
      avatar: DEFAULT_AVATAR,
      nick_name: '未知用户',
    }
  );
};

export const getUserData = (
  node: PiNode,
  config: any,
  _data: any,
  userMap: Record<string, ApiResponse.OrgUser.OrgUserItem>,
) => {
  const isMatchingStat = config.conditionMatching && config.matchingType > 0;

  if (isMatchingStat) return _data;

  _data = _data ? (Array.isArray(_data) ? _data : [_data]) : [];

  const value = _data.filter((x: any) => x).map((x: any) => getUser(x, userMap));

  return value;
};

const getUserScope = (
  config: any,
  userMap: Record<string, ApiResponse.OrgUser.OrgUserItem>,
): Record<string, ApiResponse.OrgUser.OrgUserItem> => {
  // 可选范围 -1全部 -2管理员 其他表示用户的分组id
  const scope = config.extend && config.extend.length ? config.extend : ['-1'];
  if (~scope.indexOf('-1')) {
    return userMap;
  } else {
    return Object.keys(userMap)
      .filter((userId: string) => {
        const user = userMap[userId];
        let valid = false;
        if (user.group_ids) {
          if (isArray(user.group_ids)) {
            user.group_ids.map((id: any) => {
              if (~scope.indexOf(id)) {
                valid = true;
              }
            });
          } else if (typeof user.group_ids === 'string') {
            if (~scope.indexOf(user.group_ids)) {
              valid = true;
            }
          }
        }
        return valid;
      })
      .reduce(
        (a, b) => {
          a[b] = userMap[b];
          return a;
        },
        {} as Record<string, ApiResponse.OrgUser.OrgUserItem>,
      );
  }
};

export const getQuoteData = (
  node: PiNode,
  config: any,
  _data: any,
  userMap: Record<string, ApiResponse.OrgUser.OrgUserItem>,
  tempMap: any,
  propIndex: number,
  departmentMap: any,
) => {
  const originProp: any = getQuoteOriginProp(config, tempMap);
  const res: any = {};
  const value = tempValueDisplay({
    propConfig: config,
    propValue: _data,
    propIndex: propIndex,
    userMap: userMap,
    tempMap: tempMap,
    sysCascade: node.prop._sys_cascade,
    departmentMap,
  });

  // 引用附件
  if (
    originProp !== -3 &&
    originProp &&
    originProp.type === 'attachment' &&
    originProp.matchingType === 0
  ) {
    res.data = _data;
    res.type = 'quote-attachment';
    return res;
  }
  return value;
};
