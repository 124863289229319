import { useDebounceFn } from 'ahooks';
import { useState } from 'react';

export const useDisabledEvents = (usable?: boolean) => {
  const [pointerEvents, setPointerEvents] = useState<'auto' | 'none'>('auto');

  const { run } = useDebounceFn(
    () => {
      setPointerEvents('auto');
    },
    { wait: 200 },
  );

  const onMouseDown = () => {
    if (usable) {
      setPointerEvents('none');
      run();
    }
  };

  return [pointerEvents, onMouseDown] as const;
};
