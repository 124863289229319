import { DEFAULT_AVATAR } from '@linkpi/core';
import { Avatar, Space } from 'antd';
import type { FC } from 'react';
import { useMemo } from 'react';

import type { CheckboxSelectProp } from '@/components';
import { CheckboxSelect } from '@/components';
import { useOrgUserList } from '@/hook';
import { filterUsersByPinyin, getUserNickName } from '@/utils/utils';

export const UserSelect: FC<CheckboxSelectProp> = (props) => {
  const userList = useOrgUserList();
  const options = useMemo(() => {
    return userList.map((u) => ({
      name: getUserNickName(u),
      label: (
        <Space>
          <Avatar size={24} src={u.avatar || DEFAULT_AVATAR} />
          {getUserNickName(u)}
        </Space>
      ),
      value: u.account_id,
      pinyin: u.pinyin,
    }));
  }, [userList]);

  const map = options.reduce((res, cur) => {
    res[cur.name] = cur;

    return res;
  }, {} as any);

  return (
    <div style={{ padding: '0 8px', height: '100%', overflow: 'auto' }}>
      <CheckboxSelect
        {...props}
        options={options}
        searchFilter={(v, r) => {
          return filterUsersByPinyin(v, { children: r.name }, map);
        }}
      />
    </div>
  );
};
