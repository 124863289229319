import { ProForm } from '@ant-design/pro-form';
import { getQuoteOriginPropInfo } from '@linkpi/core';
import { useDispatch, useSelector } from '@umijs/max';
import { useMemoizedFn, useSafeState as useState } from 'ahooks';
import { Button, Checkbox, Drawer, Input, Modal, Select, Space } from 'antd';
import dayjs from 'dayjs';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { concat, defaultTo, isEmpty, isNil, omit, uniqBy, without } from 'ramda';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef } from 'react';

import { RegularIcon } from '@/components';
import IndicatorMatchingSetting from '@/components/IndicatorMatchingSetting';
import { useNodeTreeData, useProvData, useQuoteOptions } from '@/hook';
import useFilterTemplateList from '@/hook/useFilterTemplateList';
import { getModifyPermission } from '@/pages/home/components/View/hooks';

import { fetchUserName } from '../../TempStatus';
import { getConditionForm } from './getConditionForm';
import { getFormOptions } from './getFormOptions';
import { getXAxiosForm } from './getXAxisForm';
import { getYAxisForm } from './getYAxisForm';
import { apiDataConvertToFormData, formDataConvertApiData } from './utils';

import './index.less';

/**
 * @param {import('@linkpi/core').CurrentUser.TemplateProp | undefined} propConfig
 * @param {Record<string, TemplateInfo>} tempMap
 */
const getRealPropInfo = (propConfig, tempMap) => {
  if (propConfig?.type === 'quote') {
    const realPropInfo = getQuoteOriginPropInfo(propConfig, tempMap);
    if (['tag', 'enum'].includes(realPropInfo.type)) {
      realPropInfo.extend = realPropInfo.originalProp.extend;
    }
    return realPropInfo;
  }

  return propConfig;
};

/**
 * @typedef {import('@/core/src/@types/ViewType').ChartsInfo['verticalAxis']} VerticalAxis
 */
const { Option } = Select;
const displayLineChartOptions = [{ value: 0, label: '折线图' }];
const ChartForm = (props, ref) => {
  useImperativeHandle(ref, () => ({
    getForm() {
      return form;
    },
    getFormData() {
      const value = formSubmit();
      return value;
    },
    initForm() {
      setInitForm();
    },
  }));

  const {
    type,
    visible,
    onCancel,
    templateList,
    onSubmit,
    auth,
    curViewData,
    currentUser,
    node,
    userMap,
    isGetChartsForm = false,
    enablePublic = true,
    enableTitleEdit = false,
    disableChartType = false,
    defaultViewType = 1,
  } = props;
  /**
   * @type {import('@linkpi/core').CurrentUser.OrgInfo}
   */
  const orgInfo = props.orgInfo;
  const { spaceTags } = useSelector((state) => state.space);

  const [form] = ProForm.useForm();
  const horizontalAxisValue = ProForm.useWatch('horizontalAxis', form);

  const onValuesChange = useMemoizedFn((newValue, value) => {
    if ('verticalAxis' in newValue) {
      form.setFieldValue(
        'verticalTermsGroup',
        value?.verticalTermsGroup?.map?.((i) => ({ groupName: i.groupName })),
      );
    }

    /**
     * @description 确保 verticalTermsGroup[number].value 不会有重复数据
     */
    if ('verticalTermsGroup' in newValue) {
      const editIndex = newValue.verticalTermsGroup?.findIndex((v) => !!v);
      if ('value' in (newValue.verticalTermsGroup[editIndex] || {})) {
        const newVerticalTermsGroup = value.verticalTermsGroup.map((item, index) =>
          index === editIndex || !Array.isArray(item.value)
            ? item
            : {
                ...item,
                value: without(newValue.verticalTermsGroup[editIndex].value)(item.value),
              },
        );
        form.setFieldValue('verticalTermsGroup', newVerticalTermsGroup);
      }
    }

    /**
     * 当 charrsInfo 修改后，通知上层
     * ^1.10 用于页面大模型
     *
     * 因为 setFieldValue 等接口不会触发 onValuesChange
     */
    requestAnimationFrame(async () => {
      if (typeof props.onChartsInfoChange === 'function') {
        const chartsInfo = await formSubmit();
        if (isNil(chartsInfo.type)) {
          chartsInfo.type = viewType;
        }
        props.onChartsInfoChange({ ...chartsInfo, matchings });
      }
    });
  });

  const dispatch = useDispatch();
  // 统计图的类型
  // 1 堆积柱状图
  // 2 堆积条形图
  // 3 折线图
  // 4 多维统计图
  // 5 饼图
  const [viewType, setViewType] = useState(defaultViewType);
  const [currentTemplate, setCurrentTemplate] = useState(null);
  // x轴选项
  const [horizontalOptions, setHorizontalOptions] = useState([]);
  // 图例类别的选项
  const [verticalAxisOptions, setVerticalAxisOptions] = useState([]);
  // y轴的值-图例类别
  const [verticalAxis, setVerticalAxis] = useState(null);
  // 筛选选项
  const [conditionOptions, setConditionOptions] = useState([]);
  // 图例项选项
  const [verticalOptions, setVerticalOptions] = useState([]);
  // 筛选条件
  const [conditions, setConditions] = useState([]);
  // 匹配条件
  const [matchings, setMatchings] = useState([]);
  // 筛选条件选项的值
  const [conditionValues, setConditionValues] = useState({});
  // 多维统计视图 y轴的筛选选项
  const [columnOptionGroup, setColumnOptionGroup] = useState({});
  // 多维统计视图 统计维度选项
  const [verticalGranularityGroup, setVerticalGranularityGroup] = useState({});

  const [parentId, setParentId] = useState({});
  const treeData = useNodeTreeData();

  // 省市区数据
  const { provData } = useProvData();

  const sysTags = useMemo(() => {
    if (orgInfo && spaceTags?.[orgInfo.orgId]) {
      return spaceTags[orgInfo.orgId];
    } else {
      return [];
    }
  }, [orgInfo, spaceTags]);

  useEffect(() => {
    dispatch({
      type: 'space/fetchSpaceTags',
      payload: { org_id: orgInfo.orgId },
    });
  }, [orgInfo]);

  const templateMap = useMemo(() => {
    return (templateList || []).reduce((res, cur) => {
      res[cur.template_id] = cur;
      return res;
    }, {});
  }, [templateList]);

  const initedRef = useRef(false);
  // 初始化表单
  useEffect(() => {
    /*
     * HACK
     * onChartsInfoChange 存在说明外部在监听内部 form 的值，
     * curViewData 不是真实的视图配置，而是 mock 数据，例如另一个 form 组件内的 value
     * 说明不需要初始化数据
     */
    if (
      // 保证第一次初始化正常执行
      !initedRef.current ||
      (curViewData && !props.onChartsInfoChange)
    ) {
      setInitForm();
      initedRef.current = true;
    }
  }, [visible, curViewData]);

  const filterTemplateList = useFilterTemplateList(orgInfo);

  // 初始化表单
  const setInitForm = () => {
    // 新增
    if (!visible || type === 'add') {
      form.resetFields();
      setViewType(defaultViewType);
      setVerticalAxis(null);
      setCurrentTemplate(null);
      setHorizontalOptions([]);
      setVerticalAxisOptions([]);
      setConditionOptions([]);
      setConditionValues({});
      setVerticalOptions([]);
      setColumnOptionGroup({});
      setVerticalGranularityGroup({});
      setConditions([]);
      setMatchings([]);
      setParentId({ [orgInfo.rootId]: 0 });
      form.setFieldsValue({
        horizontalAxis: [{}],
      });
    } else {
      // 编辑
      if (curViewData.charts_info) {
        const chartsInfo = curViewData.charts_info;
        const converData = apiDataConvertToFormData(chartsInfo);

        setViewType(chartsInfo.type || 1);
        setCurrentTemplate(curViewData.template_id);
        setMatchings(chartsInfo.matchings || []);
        setConditions(chartsInfo.conditions?.map((x) => x?.key) || []);
        const parentIdConfig = chartsInfo.conditions?.find((x) => x?.key === 'parentId');

        setParentId(
          parentIdConfig && parentIdConfig.value && Object.keys(parentIdConfig.value).length
            ? parentIdConfig.value
            : { [orgInfo.rootId]: 0 },
        );

        // 判断是否是
        /**
         * @type {VerticalAxis}
         */
        let oVerticalAxis = cloneDeep(defaultTo([])(chartsInfo.verticalAxis));

        if (chartsInfo.type === 5) 
        form.setFieldValue('legendConfig', chartsInfo.legendConfig ||'top')

        // 处理verticalAxis
        // 多维视图
        // TODO
        if (chartsInfo.type === 4) {
          // if (Array.isArray(oVerticalAxis)) {
          oVerticalAxis.map((v, i) => {
            // 兼容老数据
            if (v.verticalGranularity === 'node') {
              v.verticalGranularity = undefined;
              v.statisticsType = 'node';
            }

            if (v.verticalGranularity && isNil(v.type) && isNil(v.statisticsType)) {
              v.statisticsType = 'statisticsAttr';
            }
            if (Array.isArray(v.conditions)) {
              v.conditions.map((c) => {
                if (c.key?.endsWith('Time')) {
                  if (typeof c.value === 'number') {
                    c.value = {
                      timeType: 0,
                      timeValue: null,
                      timeRange: c.value,
                    };
                  } else if (c.value.timeType === 1) {
                    if (Array.isArray(c.value.timeValue)) {
                      const timeValueExtra = c.value.timeValue[1];
                      c.value.timeValue = [
                        moment(c.value.timeValue[0]),
                        timeValueExtra === 0 ? moment() : moment(c.value.timeValue[1]),
                      ];
                      if (!isNaN(timeValueExtra)) {
                        c.value.timeValue.push(timeValueExtra);
                      }
                    } else {
                      c.value.timeValue = [];
                    }
                  }
                }
              });
            }
            let vg = null;
            if (
              Array.isArray(chartsInfo.verticalGranularity) &&
              chartsInfo.verticalGranularity[i]
            ) {
              vg = chartsInfo.verticalGranularity[i];
            }
            v.verticalGranularity = vg;
          });
        } else {
          // 'SysTag' , 地址 和 数字 类型
          if (Array.isArray(oVerticalAxis)) {
            if (oVerticalAxis[0]?.conditions?.[0]?.key === 'SysTag') {
              oVerticalAxis = 'SysTag';
            }
          }
        }
        setVerticalAxis(oVerticalAxis);
        let tranConditions = cloneDeep(chartsInfo.conditions || []);
        if (Array.isArray(tranConditions) && tranConditions.length) {
          // 过滤parentId
          tranConditions = tranConditions.filter((x) => x.key !== 'parentId');
          tranConditions.map((c) => {
            if (c.key?.endsWith('Time')) {
              if (Array.isArray(c.value)) {
                if (c.value.length > 2) {
                  c.value = {
                    timeType: 0,
                    timeValue: null,
                    timeRange: c.value[2],
                  };
                } else {
                  c.value = {
                    timeType: 1,
                    timeValue: c.value.map((t) => moment(t)),
                    timeRange: null,
                  };
                }
              }
            }
          });
        }
        console.log('chartsInfo', chartsInfo);
        form.setFieldsValue({

          viewName: curViewData.view_name,
          template: curViewData.template_id,
          dateRange: chartsInfo.dateRange || 0,
          verticalAxis: oVerticalAxis,
          horizontalAxis: converData.horizontalAxis,
          others: chartsInfo.others,
          viewPublic: !!curViewData.public,
          displayLineChart: chartsInfo.displayLineChart ?? false,
          lineChartType: chartsInfo.lineChartType ?? 0,
          viewPercent: chartsInfo.viewPercent || false,
          verticalTerms: chartsInfo.verticalTerms || [],
          verticalTermsGroup: converData?.verticalTermsGroup || [],
          conditions: tranConditions,
          horizInterval: chartsInfo.horizInterval,
          verticalAxisDateInterval: converData.verticalAxisDateInterval,
          yAxisStatisticsType: converData.yAxisStatisticsType,
        });
      }
    }
  };

  useEffect(() => {
    if (conditions.length) {
      const cv = {};
      const template = templateMap[currentTemplate];

      conditions.map((x, index) => {
        if (!x) return;
        if (x === 'creator' || x === 'statusOwner') {
          cv[index] = Object.values(userMap).map((user) => {
            return {
              label: user.nick_name,
              value: user.account_id,
              pinyin: user.pinyin,
            };
          });
        } else if (x === 'status' || x === 'historyStatus') {
          cv[index] = [];
          template.task_status?.map((x, i) => {
            if (x && !x.delete) {
              cv[index].push({
                label: x.name,
                value: i,
              });
            }
          });
        } else if (x === 'parentId') {
          // TODO
        } else {
          const dataIndex = Number(x.substr(4));
          const prop = template.prop[dataIndex];
          cv[index] = getConditionValues(prop, dataIndex);
        }
      });
      setConditionValues(cv);
    }
  }, [conditions, currentTemplate, templateMap]);

  useEffect(() => {
    const option = getFormOptions({ templateMap, currentTemplate, viewType, userMap });

    setHorizontalOptions(option.horizontalOptions);
    setVerticalAxisOptions(option.verticalAxisOptions);
    setConditionOptions(option.conditionOptions);
    setColumnOptionGroup(option.columnOptionGroup);
    setVerticalGranularityGroup(option.verticalGranularityGroup);
  }, [currentTemplate, viewType, userMap, templateMap]);

  // 生成图例项的选项
  useEffect(() => {
    const template = templates.find((x) => x.template_id === currentTemplate);
    let options = [];
    if (template) {
      //状态
      if (verticalAxis === -1) {
        if (Array.isArray(template.task_status) && template.task_status.length > 0) {
          // TODO 历史状态
          template.task_status.map((status, i) => {
            if (!status?.name || status.delete) return;
            options.push({
              value: i,
              label: status.name,
            });
          });
        }
      }
      // 系统标签
      else if (verticalAxis === 'SysTag') {
        sysTags.map((x) => {
          options.push({
            value: x.tag,
            label: x.tag,
          });
        });
      }
      // 自定义属性
      else if (Array.isArray(template.prop) && template.prop.length > 0) {
        /**
         * @type {import('@linkpi/core').CurrentUser.TemplateProp}
         */
        const prop = template.prop[verticalAxis];
        const realPropInfo = getRealPropInfo(prop, templateMap);

        if (prop?.enumQuote || prop?.matchingType <= 0) {
          options.push(
            ...defaultTo([])(propsQuoteOption[verticalAxis]).map((x) => ({
              value: x,
              label: x,
            })),
          );
        }

        if (
          ['enum', 'tag'].includes(realPropInfo?.type) &&
          Array.isArray(realPropInfo.extend) &&
          realPropInfo.extend.length
        ) {
          realPropInfo.extend.map((x) => {
            options.push({
              value: x,
              label: x,
            });
          });
        }

        if (realPropInfo?.type === 'user') {
          options.push(
            ...Object.values(userMap).map((x) => ({
              value: x.account_id,
              label: x.nick_name,
            })),
          );
        }

        if (prop?.type === 'address') {
          options.push(...provData);
        } else {
          options = uniqBy((v) => v.value, options);
        }
      }
    }
    setVerticalOptions(options);
  }, [
    verticalAxis,
    userMap,
    provData,
    sysTags,
    templates,
    currentTemplate,
    templateMap,
    propsQuoteOption,
  ]);

  const templates = useMemo(() => {
    if (Array.isArray(templateList) && templateList.length) {
      return JSON.parse(JSON.stringify(templateList)).map((x) => {
        x.template_id = x.template_id.toUpperCase();
        return x;
      });
    }
    return [];
  }, [templateList]);

  const curTempInfo = useMemo(() => {
    return templates.find((x) => x.template_id === currentTemplate);
  }, [templates, currentTemplate]);

  // horizontalAxis === -2 则不支持选择范围
  const disableNodeScope = useMemo(() => {
    return curViewData?.charts_info?.horizontalAxis === -2;
  }, [curViewData]);

  const handleTemplateChange = (e) => {
    setCurrentTemplate(e);
    setVerticalAxis(null);
    setVerticalOptions([]);
    setConditions([]);
    setMatchings([]);
    setColumnOptionGroup({});
    setVerticalGranularityGroup({});
    setHorizontalOptions([]);
    setVerticalAxisOptions([]);
    setConditionOptions([]);
    setConditionValues({});
    form.resetFields(['verticalAxis', 'verticalTerms', 'conditions']);
    form.setFieldsValue({ horizontalAxis: [{}] });
  };

  const formSubmit = useMemoizedFn(async () => {
    const values = await form.getFieldsValue();
    if (auth || type === 'add') {
      values.type = viewType;
      values.viewType = 3;
      if (type === 'edit') {
        values.view_id = curViewData.view_id;
      }

      // QUESTION: why????
      let oVerticalAxis = JSON.parse(JSON.stringify(values.verticalAxis || {}));

      const convertData = formDataConvertApiData({ formData: values, horizontalOptions, viewType });

      // 处理verticalAxis
      // 多维统计
      if (values.type === 4) {
        const verticalGranularity = [];
        oVerticalAxis.map((v) => {
          const { statisticsType, calculateProp, type } = v;
          if (statisticsType === 'node') v.verticalGranularity = 'n';
          if (statisticsType === 'calculateAttr')
            v.verticalGranularity = `${type}${v.calculateProp}`;
          if (Array.isArray(v.conditions)) {
            v.conditions.map((c) => {
              if (
                c.key?.endsWith('Time') &&
                c.value.timeType === 1 &&
                Array.isArray(c.value.timeValue)
              ) {
                if (!isNaN(c.value.timeValue[2])) {
                  c.value.timeValue = [dayjs(c.value.timeValue[0]).valueOf(), c.value.timeValue[2]];
                } else {
                  c.value.timeValue = c.value.timeValue.map((t) => dayjs(t).valueOf());
                }
              }
            });
          }
          verticalGranularity.push(v.verticalGranularity);
        });
        values.verticalGranularity = verticalGranularity;
      }
      // 非多维统计
      else {
        // verticalAxis
        // 如果是 'SysTag' , 地址 和 数字 类型
        // 要把verticalAxis 转成 VerticalAxisObject
        if (oVerticalAxis === 'SysTag') {
          // verticalTerms
          /**
           * @type {import('@linkpi/core').VerticalAxisObject[]}
           */
          const verticalAxis = convertData.verticalTerms?.map((v) => ({
            name: v, // 这个需要 1. 在编辑时转成字符串 2. 显示的时候转成对应名字
            conditions: [
              {
                key: 'SysTag',
                value: [v],
              },
            ],
          }));
          oVerticalAxis = verticalAxis;
        } else {
          oVerticalAxis = convertData.verticalAxis;
        }
      }

      const tc = values.conditions ? JSON.parse(JSON.stringify(values.conditions)) : [];
      if (Array.isArray(tc) && tc.length) {
        tc.map((c) => {
          if (c.key?.endsWith('Time')) {
            if (c.value.timeType === 1) {
              c.value = c.value.timeValue.map((t) => dayjs(t).valueOf());
            } else {
              c.value = [null, null, c.value.timeRange];
            }
          }
        });
      }
      // 添加parentId
      tc.push({ key: 'parentId', value: parentId });

      let data = {
        ...values,
        ...convertData,
        verticalAxis: oVerticalAxis,
        conditions: tc,
      };

      if (Array.isArray(oVerticalAxis) && isEmpty(oVerticalAxis)) {
        data = omit(['verticalAxis'], data);
      }
      onSubmit && onSubmit(data);
      return data;
    }
  });

  const [propsQuoteOption] = useQuoteOptions(orgInfo.orgId, curTempInfo);

  const getConditionValues = useMemoizedFn(
    /**
     * @param {import('@linkpi/core').CurrentUser.TemplateProp} prop
     * @param {numbe} dataIndex
     * @returns
     */
    (prop, dataIndex) => {
      let options = [];
      if (isNil(prop)) return options;
      const realProp = getRealPropInfo(prop, templateMap);
      if (prop?.conditionMatching || prop?.enumQuote) {
        options = (propsQuoteOption[dataIndex] || []).map((x) => {
          return {
            label: prop.type !== 'user' ? x : fetchUserName(x, userMap),
            value: x,
          };
        });
      }
      if (Array.isArray(prop?.extend) && ['enum', 'tag'].includes(prop.type)) {
        options = concat(
          prop.extend.map((x) => {
            return {
              label: x,
              value: x,
            };
          }),
          options,
        );
      }
      if (realProp.type === 'user') {
        let users = Object.values(userMap);
        if (prop.extend?.length && !~prop.extend.indexOf('-1')) {
          users = Object.values(userMap).filter(
            (user) =>
              Array.isArray(user.group_ids) &&
              !!user.group_ids.find((id) => ~prop.extend.indexOf(id)),
          );
        }
        options = users.map((user) => {
          return {
            label: user.nick_name,
            value: user.account_id,
            pinyin: user.pinyin,
          };
        });
      }
      return options;
    },
  );

  const handleConditionKeyChange = useMemoizedFn(async (e, key) => {
    const fc = form.getFieldValue('conditions');
    if (Array.isArray(fc) && fc.length) {
      const cv = conditionValues;
      if (e) {
        if (fc[key]?.value) {
          delete fc[key].value;
        }
        const template = templates.find((x) => x.template_id === currentTemplate);
        if (e === 'creator' || e === 'statusOwner') {
          cv[key] = Object.values(userMap).map((user) => {
            return {
              label: user.nick_name,
              value: user.account_id,
            };
          });
        } else if (e === 'status' || e === 'historyStatus') {
          cv[key] = [];
          template.task_status?.map((x, i) => {
            if (x && !x.delete) {
              cv[key].push({
                label: x.name,
                value: i,
              });
            }
          });
        } else {
          const dataIndex = Number(e.substr(4));
          const prop = template.prop[dataIndex];
          cv[key] = await getConditionValues(prop, dataIndex);
        }
      } else if (cv[key]) {
        delete cv[key];
      }
      setConditionValues(cv);
      form.setFieldsValue({
        conditions: fc,
      });
      setConditions(fc.map((x) => x.key));
    } else {
      setConditions([]);
      setConditionValues({});
    }
  });

  const handleVerticalKeyChange = useMemoizedFn(async (e, key, cKey) => {
    const fc = form.getFieldValue('verticalAxis');
    if ('value' in fc[key].conditions[cKey]) {
      delete fc[key].conditions[cKey].value;
    }
    form.setFieldsValue({
      verticalAxis: fc,
    });
  });

  const handleHorizontalChange = useMemoizedFn((e) => {
    if (form.getFieldValue('verticalAxis') === e) {
      form.resetFields(['verticalAxis', 'verticalTerms']);
      setVerticalOptions([]);
    }
  });

  const handleViewType = useMemoizedFn((e) => {
    if (e === 3) {
      form.setFieldsValue({
        horizontalAxis: [{}],
      });
    }
    if (viewType === 3) {
      form.setFieldsValue({
        horizontalAxis: [{}],
      });
    }
    if (viewType === 4 || e === 4) {
      form.resetFields(['verticalAxis']);
    }
    setViewType(e);
  });

  const getColumnGroupOptions = useMemoizedFn((key, cKey) => {
    const fc = form.getFieldValue('verticalAxis');
    let options = [];
    if (fc[key]?.conditions && fc[key]?.conditions[cKey]?.key) {
      if (fc[key].conditions[cKey].key.substr(0, 4) === 'prop') {
        const dataIndex = Number(fc[key].conditions[cKey].key.substr(4));
        const template = templates.find((x) => x.template_id === currentTemplate);
        const prop = template.prop[dataIndex];
        options = getConditionValues(prop, dataIndex);
      } else {
        options =
          columnOptionGroup[fc[key].conditions[cKey].key.substr(0, 4)]?.options.find(
            (x) => x.value === fc[key].conditions[cKey].key,
          )?.options || [];
      }
    }
    return options;
  });

  const handleTimeTypeChange = useMemoizedFn((name, cName) => {
    const values = form.getFieldValue('verticalAxis');
    values[name].conditions[cName].value['timeValue'] = null;
    values[name].conditions[cName].value['timeRange'] = null;
    form.setFieldsValue({
      ['verticalAxis']: values,
    });
  });

  const legendConfigs = [
    { label: '顶部', value: 'top' },
    { label: '左侧', value: 'left' },
    { label: '右侧', value: 'right' },
    { label: '底部', value: 'bottom' },
    { label: '隐藏', value: 'hide' },
  ]
  const renderLegendSetting = () => {
    if (viewType === 5) return (
      <ProForm.Item
          name='legendConfig'
        >
          <Select
            
            placeholder='请选择'
          >
            {legendConfigs.map((i) => {
              return (
                <Option value={i.value} key={i.value}>
                  {i.label}
                </Option>
              );
            })}
          </Select>
        </ProForm.Item>
    )
  }

  const handleConditionTimeTypeChange = useMemoizedFn((name) => {
    const values = form.getFieldValue('conditions');
    values[name].value['timeValue'] = null;
    values[name].value['timeRange'] = null;
    form.setFieldsValue({
      ['conditions']: values,
    });
  });
  // 是否存在修改权限
  const hasModifyPermission = () => {
    if (type === 'add') {
      return true;
    }
    const isCreater = currentUser?.userid === curViewData?.account_id;
    const role = orgInfo?.role;
    const modify_permission = curViewData.modify_permission;
    return getModifyPermission(modify_permission, role, isCreater);
  };

  const getChartsForm = () => {
    let titleAndViewTypeForm = null;
    if (type === 'add' || enableTitleEdit) {
      titleAndViewTypeForm = (
        <>
          <ProForm.Item
            name="viewName"
            rules={[
              {
                required: true,
                message: '请输入视图名称',
              },
            ]}
          >
            <Input name={'viewName'} placeholder={'请输入视图名称'} />
          </ProForm.Item>
          {!disableChartType && (
            <>
              <div className="setting-title">图表类型</div>
              <div className="view-type-select">
                <div
                  className={`view-type ${viewType === 1 ? 'active' : ''}`}
                  onClick={() => handleViewType(1)}
                >
                  <RegularIcon
                    size={40}
                    type={viewType === 1 ? 'iconzhuzhuangtu_active' : 'iconzhuzhuangtu_default'}
                  />
                  堆积柱状图
                </div>
                <div
                  className={`view-type ${viewType === 2 ? 'active' : ''}`}
                  onClick={() => handleViewType(2)}
                >
                  <RegularIcon
                    size={40}
                    type={viewType === 2 ? 'icontiaoxingtu_active' : 'icontiaoxingtu_default'}
                  />
                  堆积条形图
                </div>
                <div
                  className={`view-type ${viewType === 3 ? 'active' : ''}`}
                  onClick={() => handleViewType(3)}
                >
                  <RegularIcon
                    size={40}
                    type={viewType === 3 ? 'iconzhexiantu_active' : 'iconzhexiantu_default'}
                  />
                  折线图
                </div>
                <div
                  className={`view-type ${viewType === 4 ? 'active' : ''}`}
                  onClick={() => handleViewType(4)}
                >
                  <RegularIcon
                    size={40}
                    type={
                      viewType === 4 ? 'iconduoweitongjitu_active' : 'iconduoweitongjitu_default'
                    }
                  />
                  多维统计图
                </div>
                <div
                  className={`view-type ${viewType === 5 ? 'active' : ''}`}
                  onClick={() => handleViewType(5)}
                >
                  <RegularIcon
                    size={40}
                    type={viewType === 5 ? 'iconbingtuxuanzhong' : 'iconbingtuweixuanzhong'}
                  />
                  饼图
                </div>
              </div>
            </>
          )}
        </>
      );
    }

    const tempTypeForm = (
      <>
        <div className={`setting-title ${type === 'edit' ? '' : 'title-space-between'}`}>
          主题类型
        </div>
        <ProForm.Item
          name={'template'}
          rules={[
            {
              required: true,
              message: '请选择主题类型',
            },
          ]}
        >
          <Select
            filterOption={(input, option) =>
              option?.children?.toLowerCase?.()?.indexOf(input.toLowerCase()) >= 0
            }
            showSearch={true}
            placeholder={'请选择主题类型'}
            onChange={(e) => handleTemplateChange(e)}
          >
            {filterTemplateList.map((template, i) => {
              return (
                <Option value={template.template_id} key={i}>
                  {template.name}
                </Option>
              );
            })}
          </Select>
        </ProForm.Item>
      </>
    );

    const renderMatchingsForm = () => {
      const mockConditions = currentTemplate ? [{ key: 'templateId', value: currentTemplate }] : [];
      return (
        <IndicatorMatchingSetting
          conditions={mockConditions}
          matchings={matchings}
          onChange={async (e) => {
            setMatchings(e);
            if (typeof props.onChartsInfoChange === 'function') {
              const chartInfo = await formSubmit();
              if (isNil(chartInfo.type)) {
                chartInfo.type = viewType;
              }
              props.onChartsInfoChange({ ...chartInfo, matchings: e });
            }
          }}
        />
      );
    };

    const conditionsForm = getConditionForm({
      viewType,
      currentTemplate,
      form,
      conditionOptions,
      setConditions,
      conditions,
      parentId,
      hasModifyPermission,
      disableNodeScope,
      setParentId,
      selectNode: node?.value?.id,
      treeData,
      orgInfo,
      handleConditionKeyChange,
      handleConditionTimeTypeChange,
      conditionValues,
    });

    const xAxisForm = getXAxiosForm({
      viewType,
      handleHorizontalChange,
      horizontalOptions,
      verticalAxis,
      form,
      horizontalAxisValue,
    });

    const yAxiosForm = getYAxisForm({
      viewType,
      currentTemplate,
      form,
      verticalGranularityGroup,
      templates,
      templateMap,
      verticalAxis,
      handleVerticalKeyChange,
      columnOptionGroup,
      getColumnGroupOptions,
      handleTimeTypeChange,
      setVerticalAxis,
      setVerticalOptions,
      verticalOptions,
      verticalAxisOptions,
    });

    return (
      <ProForm
        submitter={false}
        className="statisticsView view-add-modal-form"
        form={form}
        layout="vertical"
        name="statisticsView"
        requiredMark={false}
        colon={false}
        onFinish={formSubmit}
        scrollToFirstError={{ behavior: 'smooth' }}
        onValuesChange={onValuesChange}
      >
        {titleAndViewTypeForm}
        {tempTypeForm}
        {conditionsForm}
        {renderMatchingsForm()}

        <div className="setting-title title-space-between">图表配置</div>
        {xAxisForm}
        {yAxiosForm}
        <div className='setting-title title-space-between'>图列位置</div>
        {renderLegendSetting()}

        {!!enablePublic && !!(viewType <= 3) && (
          <div className="setting-title title-space-between">显示</div>
        )}
        {!!enablePublic && (
          <ProForm.Item
            name={'viewPublic'}
            style={{ margin: 0 }}
            valuePropName={'checked'}
            required={false}
            colon={false}
          >
            <Checkbox>公开</Checkbox>
          </ProForm.Item>
        )}

        {viewType === 4 && (
          <ProForm.Item style={{ margin: 0 }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                whiteSpace: 'nowrap',
              }}
            >
              <ProForm.Item
                name={'displayLineChart'}
                valuePropName="checked"
                style={{ marginRight: '8px', marginBottom: 0 }}
              >
                <Checkbox />
              </ProForm.Item>
              <span style={{ marginRight: '16px' }}>显示统计图</span>
              <ProForm.Item name={'lineChartType'} noStyle style={{ flex: 1 }}>
                <Select
                  showSearch={true}
                  style={{ width: '100%' }}
                  onChange={(e) => handleTemplateChange(e)}
                >
                  {displayLineChartOptions.map((item, i) => (
                    <Option value={item.value} key={i}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </ProForm.Item>
            </div>
          </ProForm.Item>
        )}
        <ProForm.Item
          name={'viewPercent'}
          style={{ margin: 0 }}
          hidden={viewType > 3}
          valuePropName={'checked'}
          required={false}
          colon={false}
        >
          <Checkbox>统计百分比</Checkbox>
        </ProForm.Item>
      </ProForm>
    );
  };

  if (isGetChartsForm) {
    return getChartsForm();
  }

  return type === 'edit' ? (
    <Drawer
      className="view-add-modal"
      title={<span className="set-title">设置</span>}
      placement="right"
      closable={true}
      onClose={onCancel}
      open={visible}
      width={456}
      style={{ position: 'absolute', height: 'calc(100% - 79px)', marginTop: 79 }}
      mask={true}
      maskStyle={{ opacity: 0, animation: 'none', height: 'calc(100% + 79px)', marginTop: -79 }}
      headerStyle={{ borderBottom: 'none' }}
    >
      {getChartsForm()}
      {auth && (
        <div className="view-set-btns">
          <Space>
            <Button type={'primary'} onClick={() => form.submit()}>
              确认
            </Button>
            <Button type={'default'} onClick={onCancel}>
              取消
            </Button>
          </Space>
        </div>
      )}
    </Drawer>
  ) : (
    <Modal
      title={'新增统计视图'}
      width={620}
      bodyStyle={{ padding: '24px 40px 0 24px', height: 600, overflowY: 'auto' }}
      destroyOnClose
      wrapClassName="view-add-modal"
      open={visible}
      onCancel={onCancel}
      maskClosable={false}
      centered
      onOk={(_) => form.submit()}
    >
      {getChartsForm()}
    </Modal>
  );
};

export default forwardRef(ChartForm);
