import type { ApiResponse } from '@linkpi/core';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { v4 as uuid4 } from 'uuid';

/**
 * 生成随机uuid
 */
export const generateId = () => uuid4().replace(/-/g, '');

export const hexToRgba = (hex: string, opacity: number) => {
  const RGBA =
    'rgba(' +
    parseInt('0x' + hex.slice(1, 3)) +
    ',' +
    parseInt('0x' + hex.slice(3, 5)) +
    ',' +
    parseInt('0x' + hex.slice(5, 7)) +
    ',' +
    opacity +
    ')';
  return {
    red: parseInt('0x' + hex.slice(1, 3)),
    green: parseInt('0x' + hex.slice(3, 5)),
    blue: parseInt('0x' + hex.slice(5, 7)),
    rgba: RGBA,
  };
};

/**
 * 获取坐标的网格定位
 */
export const getGridLocationOfPosition = (
  x: number,
  y: number,
  width: number,
  height: number,
  particleSize: 100,
) => {
  const xStart = x;
  const xEnd = x + width;
  const yStart = y;
  const yEnd = y + height;

  // TODO
};

export const dayjsMinMax = (o: any, c: any, d: any) => {
  const sortBy = (method: any, dates: any) => {
    if (!dates || !dates.length || !dates[0] || (dates.length === 1 && !dates[0].length)) {
      return null;
    }
    if (dates.length === 1 && dates[0].length > 0) {
      [dates] = dates;
    }
    let result;
    [result] = dates;
    for (let i = 1; i < dates.length; i += 1) {
      if (!dates[i].isValid() || dates[i][method](result)) {
        result = dates[i];
      }
    }
    return result;
  };

  d.max = function () {
    const args = [].slice.call(arguments, 0); // eslint-disable-line prefer-rest-params
    return sortBy('isAfter', args);
  };
  d.min = function () {
    const args = [].slice.call(arguments, 0); // eslint-disable-line prefer-rest-params
    return sortBy('isBefore', args);
  };
};

export function isEnableProp(config: any) {
  return config && config.type && config.display;
}

const STATUS_START_INDEX = 2;
const STATUS_END_INDEX = 3;
export function getValidDateScope(node: PiNode, tempConfig: ApiResponse.CurrentUser.TemplateInfo) {
  let validScope: [Dayjs, Dayjs] | [] = [];

  if (!node || !tempConfig) return validScope;

  const { status } = node.tempInfo;
  const statusConfig = tempConfig && tempConfig.task_status && tempConfig.task_status[status];
  if (!statusConfig) return validScope;

  let startValue: number | null = null;
  let endValue: number | null = null;

  const { statusProp } = node.tempInfo;
  const enableStart = isEnableProp(statusConfig.prop[STATUS_START_INDEX]);
  const enableEnd = isEnableProp(statusConfig.prop[STATUS_END_INDEX]);

  if (enableStart) {
    startValue = statusProp[STATUS_START_INDEX] as number;
  }
  if (enableEnd) {
    endValue = statusProp[STATUS_END_INDEX] as number;
  }

  // 如果2个值都没有 ，跳过
  if (!startValue && !endValue) return validScope;

  // 如果只有1个值 显示为1天
  if (startValue && endValue) {
    validScope = [dayjs(startValue), dayjs(endValue)];
  } else if (startValue) {
    const d = dayjs(startValue);
    // 有开始时间 结束时间为18点或者endOf day
    const end = d.isAfter(d.set('hour', 18).startOf('hour'))
      ? d.endOf('day')
      : d.set('hour', 18).startOf('hour');
    validScope = [d, end];
  } else if (endValue) {
    const d = dayjs(endValue);
    // 有结束时间 开始时间为8点或startOf day
    const start = d.isBefore(d.set('hour', 8).startOf('hour'))
      ? d.startOf('day')
      : d.set('hour', 8).startOf('hour');
    validScope = [start, d];
  }

  // 1. 如果配置只有开始时间或者结束时间 就显示为当天 不允许区间拖拽;如果都没有 就不显示cell
  // 2. 如果配置有s,e 值只有1个 那么显示为1天；
  // 3. 单位精确到小时

  return validScope;
}
