import { useControllableValue } from 'ahooks';
import type { TreeProps } from 'antd';
import { Avatar, Checkbox, Space, Tree, Typography } from 'antd';
import { intersection, isEmpty, uniq } from 'ramda';
import type { FC } from 'react';
import { useCallback, useMemo, useState } from 'react';

import { CheckboxSelect, SearchBar } from '@/components';
import { RegularIcon } from '@/components';
import type { NewPiNode } from '@/hook/useOrgStructure';
import { useOrgDepartmentNodes, useOrgStructureDepartments } from '@/hook/useOrgStructure';
import { traverseTreeCreator } from '@/utils/tree';
import { toArray } from '@/utils/utils';

import styles from './Department.module.less';

export const DepartmentSelect: FC<
  React.PropsWithChildren<
    Omit<TreeProps, 'checkedKeys' | 'onCheck'> & {
      allowSelectAll?: boolean;
      allowSearch?: boolean;
      value: string[];
      mode?: 'multiple' | 'single';
      onChange: (v: string[]) => void;
      // 筛选符合条件的部门
      filterIds?: string[];
      checkStrictly?: boolean;
    }
  >
> = ({
  mode = 'multiple',
  allowSelectAll = false,
  allowSearch = false,
  filterIds,
  checkStrictly,
  ...props
}) => {
  const departmentNodes = useOrgDepartmentNodes();

  const _treeData = useOrgStructureDepartments({});

  const treeData = useMemo(() => {
    if (!filterIds?.length) return _treeData;

    function filterTree(node: NewPiNode) {
      if (filterIds?.includes(node.groupId)) {
        // 如果节点的符合条件
        return node;
      }

      if (node.departmentChildren) {
        // 否则，对子节点进行过滤
        const filteredChildren: NewPiNode[] = node.departmentChildren
          .map(filterTree)
          .filter((child) => child !== null);

        if (filteredChildren.length > 0) {
          // 如果有满足条件的子节点，保留此节点并更新其子节点
          return {
            ...node,
            disabled: true,
            groupId: node.groupId,
            title: node.title,
            departmentChildren: filteredChildren,
          };
        }
      }

      // 否则，不保留此节点
      return null;
    }
    return _treeData.map(filterTree).filter(Boolean);
  }, [_treeData, filterIds]);

  const [value, _onChange] = useControllableValue({
    ...props,
    value:
      toArray(props.value)
        ?.filter(Boolean)
        ?.map((i) => i?.replace('group_', '')) || [],
  });

  const onChange = useCallback(
    (v: string[] = []) => {
      _onChange(v.map((i) => `group_${i}`));
    },
    [_onChange],
  );

  const options = useMemo(() => {
    return departmentNodes?.map((d) => ({
      value: d.id,
      name: d.name,
      label: (
        <Space align="center">
          <Avatar
            src={<RegularIcon type="iconOrg_Nav_Department" color="#fff" size={16} />}
            size={24}
            style={{ background: '#7869E6' }}
          />
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography.Text>{d.name}</Typography.Text>
            <Typography.Text type="secondary" style={{ fontSize: 12 }}>
              {d.pathName}
            </Typography.Text>
          </div>
        </Space>
      ),
    }));
  }, [departmentNodes]);

  const allValue = useMemo(() => {
    const result: string[] = [];
    traverseTreeCreator(
      (n) => {
        if (n.disabled) return;
        result.push(n.groupId);
      },
      {
        childrenKeyName: 'departmentChildren',
      },
    )(
      // @ts-ignore
      treeData,
    );
    return result;
  }, [treeData]);

  const selectedAll = useMemo(() => {
    if (!(value?.length > 0)) return false;

    return intersection(allValue, value)?.length === allValue?.length;
  }, [allValue, value]);

  // @ts-ignore
  const onCheck: TreeProps['onCheck'] = (checkedKeys, { checked, node }) => {
    if (Array.isArray(checkedKeys)) return;
    if (mode === 'single') {
      if (!checked) onChange([]);
      // @ts-ignore
      else onChange([node.groupId]);
      return;
    }

    if (!checked) {
      onChange(checkedKeys.checked as string[]);
      return;
    }
    const appendChecked: string[] = [];
    if (!checkStrictly)
      traverseTreeCreator(
        (n) => {
          appendChecked.push(n.groupId);
        },
        {
          childrenKeyName: 'departmentChildren',
        },
      )(
        // @ts-ignore
        node.departmentChildren || [],
      );
    const newCheckedKeys = uniq([...checkedKeys.checked, ...appendChecked]);
    onChange(newCheckedKeys as string[]);
  };

  const [searchValue, setSearchValue] = useState('');

  return (
    <div style={{ padding: '0px 4px', height: '100%', overflow: 'auto' }}>
      {allowSearch && (
        <div style={{ padding: '0 8px', paddingBottom: 8 }}>
          <SearchBar
            placeholder="搜索组织架构"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>
      )}
      {mode === 'multiple' && allowSelectAll && !searchValue && (
        <div style={{ padding: '0 8px', paddingBottom: 4 }}>
          <Checkbox
            checked={selectedAll}
            onChange={(e) => {
              if (e.target.checked) {
                onChange(allValue);
              } else {
                onChange([]);
              }
            }}
          >
            全选
          </Checkbox>
        </div>
      )}
      {isEmpty(searchValue) && (
        <Tree
          className={styles.wrapper}
          showIcon
          icon={
            <Avatar
              src={<RegularIcon type="iconOrg_Nav_Department" color="#fff" size={14} />}
              size={20}
              style={{ background: '#7869E6' }}
            />
          }
          {...props}
          blockNode
          checkable
          checkStrictly
          checkedKeys={value}
          onCheck={onCheck}
          treeData={treeData}
          fieldNames={{ children: 'departmentChildren', key: 'groupId' }}
        />
      )}
      {searchValue && (
        <CheckboxSelect
          options={options}
          value={value}
          onChange={onChange}
          searchValue={searchValue}
          onSearchChange={setSearchValue}
          searchFilter={(v, r) => {
            if (typeof r.name === 'string') {
              return r.name.toLocaleLowerCase().indexOf(v.toLocaleLowerCase()) >= 0;
            }
            return true;
          }}
        />
      )}
    </div>
  );
};
