import sha512 from 'sha512';

import request from '@/utils/request';

export async function query() {
  return request('/api/users');
}

export async function updatePassword(params) {
  if (params.new_password !== params.confirm_password) {
    return { status: 'error', message: 'Two password entries are inconsistent' };
  }
  if (params.phone.startsWith('+86-')) {
    params.phone = params.phone.substr(4);
  }
  const old_password = params.old_password;
  params.old_password = sha512(`${old_password}${params.phone}LinkPi20200903!`).toString('hex');
  params.old_mail_password = sha512(`${old_password}${params.email}LinkPi20200903!`).toString(
    'hex',
  );
  const password = params.new_password;
  params.new_password = sha512(`${password}${params.email}LinkPi20200903!`).toString('hex');
  params.confirm_password = sha512(`${password}${params.phone}LinkPi20200903!`).toString('hex');
  return request('/api/updatePassword', {
    method: 'POST',
    data: params,
  });
}

export async function resetPassword(params) {
  if (!params.new_password || !params.confirm_password) {
    return { status: 'error', message: 'lack password or confirm' };
  }
  if (params.new_password !== params.confirm_password) {
    return { status: 'error', message: 'Two password entries are inconsistent' };
  }
  if (params.phone.startsWith('+86-')) {
    params.phone = params.phone.substr(4);
  }
  const password = params.new_password;
  if (params.mail || params.email) {
    params.mail = params.mail || params.email;
  } else {
    // 首页重置密码时需要调用接口获取用户email
    const emailParams = {
      captcha: params.captcha,
      phone: params.phone,
    };
    const mailResponse = await request('/api/getUserMail', {
      method: 'POST',
      data: emailParams,
    });
    if (mailResponse.status === 'ok') {
      params.mail = mailResponse.data.mail;
    } else {
      return { status: 'error', message: 'get user mail fail' };
    }
  }
  if (params.mail) {
    params.new_password = sha512(`${password}${params.mail}LinkPi20200903!`).toString('hex');
  } else {
    delete params.new_password;
  }
  params.confirm_password = sha512(`${password}${params.phone}LinkPi20200903!`).toString('hex');
  delete params.password;
  delete params.confirm;
  return request('/api/resetPassword', {
    method: 'POST',
    data: params,
  });
}

export async function bindPhone(params) {
  if (!params.new_password || !params.confirm_password) {
    return { status: 'error', message: 'lack password or confirm' };
  }
  if (!params.phone) {
    return { status: 'error', message: 'lack parameter phone' };
  }
  if (params.new_password !== params.confirm_password) {
    return { status: 'error', message: 'Two password entries are inconsistent' };
  }
  if (params.phone.startsWith('+86-')) {
    params.phone = params.phone.substr(4);
  }
  const password = params.new_password;
  params.new_password = sha512(`${password}${params.mail}LinkPi20200903!`).toString('hex');
  params.confirm_password = sha512(`${password}${params.phone}LinkPi20200903!`).toString('hex');
  delete params.password;
  delete params.confirm;
  return request('/api/bindPhone', {
    method: 'POST',
    data: params,
  });
}
