import { FullscreenOutlined, LoadingOutlined } from '@ant-design/icons';
import {
  antdModalV5,
  create as ModalCreate,
  show,
  useModal,
} from '@ebay/nice-modal-react';
import type { ApiResponse, CustomButtonCopyConfig } from '@linkpi/core';
import {
  DEFAULT_STATUS_PROP,
  STATUS_ICON,
  tempValueDisplay,
} from '@linkpi/core';
import { useWindowSize } from '@react-hookz/web';
import { useMemoizedFn, useRequest, useToggle } from 'ahooks';
import {
  Checkbox,
  Col,
  Input,
  Modal,
  notification,
  Row,
  Space,
  Spin,
  Tooltip,
  Typography,
} from 'antd';
import cls from 'classnames';
import { filter, isEmpty, isNil, pipe, sortBy } from 'ramda';
import type { FC } from 'react';
import { useMemo, useRef, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import type { ListRowRenderer } from 'react-virtualized';
import { AutoSizer, List } from 'react-virtualized';

import { useOrgUserMap } from '@/hook';
import { useOrgDepartmentNodesMap } from '@/hook/useOrgStructure';
import { useOrgTempMap } from '@/hook/useTemplate';
import { propDisplay } from '@/pages/home/components/TempStatus';
import type { ICopyNodes } from '@/services/node';
import { copyNodes } from '@/services/node';
import { getAttachmentValue, toRecord } from '@/utils/utils';

import { PiButton } from '../Button';
import { ConditionFilterButton } from '../ConditionFilter';
import { getPropsValue } from './CopyNodePropSetupModal';
import { filterChecker } from './utils';

import '@/pages/home/components/QuoteSelector/index.less';
import styles from './CopyNodeModal.less';

const { Text } = Typography;
const defaultWidth = 160;

const allowKey = ['prop', 'templateId', 'status'];
const allowPropTypes: ApiResponse.CurrentUser.propType[] = [
  'enum',
  'tag',
  'text',
  'number',
  'formula',
];

const toNodeRecord = toRecord((node: PiNode) => ({
  [node.id]: node,
}));

async function getFilterNode(node: PiNode, config: CustomButtonCopyConfig) {
  const { copyTemplate: template, matchCondition, filterCondition } = config;

  // 指定被匹配数据的属性
  const groupBy = matchCondition?.map((item) => item.target) || [];
  // 筛选条件
  const tempProps =
    filterCondition?.map((item) =>
      item.value.map((v) => {
        if (item.prop === 'status') return `si-${v}`;
        else return [item.prop, v].join('-');
      }),
    ) || [];

  const filters = {
    groupBy,
    tempProps,
    temp_id: template,
    draft: node.metadata.f === -2,
  };
  return node.nodeManager.getTempFilterNode(filters);
}

interface TableHeader {
  name: string;
  width: number;
  type: string;
  index: number;
  sort: number;
  sticky?: 'left' | 'right';
  key: string;
}

type CopyNodeModalProps = {
  config: ApiResponse.CurrentUser.CustomButton;
  orgId: string;
  node: PiNode;
};

const CopyNode: FC<CopyNodeModalProps> = ({ orgId, config, node }) => {
  const modal = useModal();
  const nodeId = node.id;

  const headerScrollRef = useRef<any>(null);
  const bodyScrollRef = useRef<any>(null);
  const [fullScreen, { toggle: toggleFullScreen }] = useToggle();

  const { height: windowHeight } = useWindowSize();

  const bodyHeight = windowHeight - (fullScreen ? 247 : 328);

  const [search, setSearch] = useState('');

  const { data: allNodeList = [] } = useRequest(async () => {
    const filterNode = await getFilterNode(node, config.copyConfig!);

    // 根据当前节点，匹配数据
    const groupBy =
      config.copyConfig!.matchCondition?.map((item) => item.source) || [];
    const nodes = filterNode.getMatchNode(node.id, groupBy, [], false);

    return sortBy((i: PiNode) => i.createTime * -1)(nodes);
  });

  const [filterValue, setFilterValue] =
    useState<ApiResponse.ViewList.ViewconditionV2>([
      {
        op: 'beIncluded',
        key: 'templateId',
        input: [config.copyConfig!.copyTemplate],
      },
    ]);

  const nodeList = useMemo(() => {
    if (filterValue.length > 1)
      return pipe(
        filter(filterChecker(filterValue)),
        toNodeRecord,
      )(allNodeList);

    return toNodeRecord(allNodeList);
  }, [allNodeList, filterValue]);

  const { loading, runAsync: batchCopyNodes } = useRequest(
    async (args: Parameters<typeof copyNodes>[0]) => {
      const requestKey = ['copyNode', args.orgId, args.parentId].join('@');
      notification.open({
        icon: <Spin indicator={<LoadingOutlined />} />,
        message: '正在复制节点',
        key: requestKey,
        duration: 0,
      });
      try {
        await copyNodes({
          ...args,
          copyMode: 'NoChild',
        });
        notification.success({
          message: '复制完成！',
          key: requestKey,
          duration: 3,
        });
        modal.hide();
      } catch (error) {
        notification.error({
          message: '复制失败。',
          key: requestKey,
          duration: 3,
        });
      }
    },
    {
      manual: true,
    },
  );

  const onOk = useMemoizedFn(async () => {
    const _config = config.copyConfig!;

    const parentId =
      _config.position === 'current'
        ? nodeId!
        : (_config.positionPath as string);

    const copyParams: any = {};

    // 初始状态
    if (_config!.copyTempType === 'same' && !isNil(_config!.initalStatus)) {
      copyParams.set_task_status = {
        [_config.copyTemplate]: { index: Number(_config!.initalStatus) },
      };
    }

    const copyId = propValue.map((p) => {
      return {
        ...copyParams,
        org_id: orgId,
        node_id: p,
      };
    });

    const params: ICopyNodes = {
      orgId,
      parentId,
      copyId,
      copyMode: 'NoChild',
    };

    if (_config.copyTempType === 'others') {
      params.temp_map = { [_config.copyTemplate]: _config.switchTemplate };

      if (_config.enableEdit && !isEmpty(_config.enableEdit)) {
        const setProp = (await getPropsValue({
          orgId,
          targetTempId: _config.switchTemplate,
          sourceTempId: _config.copyTemplate,
        })) as Record<string | number, unknown>;

        params.set_prop = {
          [_config.switchTemplate]: setProp,
        };
      }
    }

    const result = await batchCopyNodes(params);

    modal.resolve(result);

    return result;
  });

  const [propValue, setPropValue] = useState<string[]>([]);

  const clickRow = useMemoizedFn((o: string) => {
    const index = propValue.indexOf(o);
    const newValue = [...propValue];
    if (~index) {
      newValue.splice(index, 1);
    } else {
      newValue.push(o);
    }
    setPropValue(newValue);
  });

  const selectAll = useMemoizedFn((e: boolean) => {
    if (!search) {
      setPropValue(e ? Object.keys(nodeList) : []);
    } else {
      const searchKeys = Object.keys(nodeList).filter(
        (o) => ~nodeList[o].title.toLowerCase().indexOf(search.toLowerCase()),
      );
      if (searchKeys.length) {
        setPropValue(
          e
            ? [
                ...propValue,
                ...searchKeys.filter((s) => !propValue.includes(s)),
              ]
            : [...propValue.filter((x) => !searchKeys.includes(x))],
        );
      }
    }
  });

  const userMap = useOrgUserMap();
  const tempMap = useOrgTempMap(orgId);
  const departmentNodeMap = useOrgDepartmentNodesMap();

  const matchingTemplate = useMemo(() => {
    return tempMap[config.copyConfig!.copyTemplate];
  }, [config]);

  const modalContent = useMemo(() => {
    const defaultHeaders: TableHeader[] = [
      {
        name: '状态',
        width: 120,
        type: 'status',
        index: -2,
        sort: -2,
        key: 'status',
      },
      {
        name: '标题',
        width: 200,
        type: 'text',
        index: -1,
        sort: -1,
        sticky: 'left',
        key: 'title',
      },
    ];
    if (Array.isArray(matchingTemplate?.prop)) {
      matchingTemplate.prop.forEach((p, i) => {
        if (p?.type && !p.hide) {
          defaultHeaders.push({
            name: p.name,
            width: p.type === 'text' ? 200 : defaultWidth,
            type: p.type,
            index: i,
            sort: 'sort' in p ? p.sort : i,
            key: `prop-${i}`,
          });
        }
      });
    }
    defaultHeaders.sort((b, a) => b.sort - a.sort);
    defaultHeaders.push.apply(
      defaultHeaders,
      DEFAULT_STATUS_PROP.map((s, i) => ({
        name: s.name,
        width: defaultWidth,
        type: s.type,
        index: i - 7,
        sort: i - 7,
        key: 'status-' + i,
      })),
    );

    const headers = config.copyConfig?.visibleProps ? [] : defaultHeaders;

    if (config.copyConfig?.visibleProps?.length) {
      config.copyConfig?.visibleProps.forEach((e) => {
        const header = defaultHeaders.find((h) => h.key === e.key);
        if (header && e.checked) {
          headers.push(header);
        }
      });
    }

    let tableWidth = 32;
    headers.forEach((h) => {
      tableWidth += h.width;
    });
    const searchKeys = Object.keys(nodeList).filter(
      (o) => ~nodeList[o].title.toLowerCase().indexOf(search.toLowerCase()),
    );

    const rowRenderer: ListRowRenderer = ({ index: i, key, style }) => {
      const o = searchKeys[i];
      const n = nodeList[o];

      return (
        <div key={key} style={style}>
          <div
            onClick={() => clickRow(o)}
            className={cls(
              `quote-option-body-column`,
              propValue.includes(o) && 'active',
              styles.column,
            )}
          >
            <Checkbox
              checked={propValue.includes(o)}
              style={{ marginLeft: 6, marginTop: n ? -2 : -4 }}
            />
            <div className={'quote-option-body-column-item'}>
              {n ? (
                <div className={'quote-option-body-column-item-main'}>
                  {headers.map((h) => {
                    if (h.index < 0) {
                      if (h.index === -1) {
                        return (
                          <div
                            className={
                              'quote-option-body-column-item-main-cell text-omit'
                            }
                            style={{ width: h.width }}
                            key={h.key}
                          >
                            {n.title || '无标题'}
                          </div>
                        );
                      } else {
                        const curStatus =
                          matchingTemplate?.task_status[n.tempInfo.status];
                        if (h.index === -2) {
                          // 状态
                          return curStatus && !curStatus.delete ? (
                            <div
                              className={`quote-option-body-column-item-main-cell text-omit status`}
                              style={{ width: h.width }}
                              key={h.key}
                            >
                              <i
                                className={`iconfont ${curStatus.icon}`}
                                // @ts-ignore
                                style={{
                                  color: STATUS_ICON[curStatus.icon]?.color,
                                }}
                              />
                              {curStatus.name}
                            </div>
                          ) : (
                            <div
                              className={
                                'quote-option-body-column-item-main-cell'
                              }
                              style={{ width: h.width }}
                              key={h.key}
                            />
                          );
                        } else {
                          const si = h.index + 7;
                          return (
                            <div
                              className={`quote-option-body-column-item-main-cell text-omit`}
                              style={{ width: h.width }}
                              key={h.key}
                            >
                              {curStatus && curStatus.prop?.[si]?.display
                                ? propDisplay(
                                    curStatus.prop[si],
                                    n.tempInfo.statusProp[si],
                                    userMap,
                                    tempMap,
                                    n.prop._sys_cascade,
                                    h.index,
                                    n,
                                    departmentNodeMap,
                                  )
                                : null}
                            </div>
                          );
                        }
                      }
                    } else {
                      let res: any = {};
                      if (matchingTemplate?.prop[h.index].type === 'quote') {
                        res = propDisplay(
                          matchingTemplate?.prop[h.index],
                          n.tempInfo.prop[h.index],
                          userMap,
                          tempMap,
                          n.prop._sys_cascade,
                          h.index,
                          n,
                          departmentNodeMap,
                        );
                      } else if (
                        matchingTemplate?.prop[h.index].type === 'attachment'
                      ) {
                        res.value = getAttachmentValue(n, h.index);
                        res.type = 'quote-attachment';
                      } else {
                        res = tempValueDisplay({
                          propConfig: matchingTemplate?.prop[h.index],
                          propValue: n.tempInfo.prop[h.index],
                          propIndex: h.index,
                          sysCascade: n.prop._sys_cascade,
                          userMap: userMap,
                          tempMap,
                          departmentMap: departmentNodeMap,
                        });
                      }

                      return (
                        <div
                          className={`quote-option-body-column-item-main-cell text-omit`}
                          style={{ width: h.width }}
                          key={h.key}
                        >
                          {res?.type === 'quote-attachment'
                            ? res.value.map((f: any, index: number) => (
                                <div
                                  key={index}
                                  style={{
                                    backgroundImage: `url(${f.src})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    height: 32,
                                    width: 32,
                                    marginRight: 6,
                                  }}
                                />
                              ))
                            : res}
                        </div>
                      );
                    }
                  })}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      );
    };

    return (
      <div id={'QuoteSelector'}>
        <div className={'quote-option'}>
          <Row gutter={8} align="middle">
            <Col flex="auto">
              <Input
                prefix={
                  <i
                    className={'iconfont iconsearch'}
                    style={{ color: '#EBEDF0', width: 'auto' }}
                  />
                }
                placeholder={'请输入关键字搜索'}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Col>
            <Col flex="none">
              <ConditionFilterButton
                orgId={orgId}
                value={filterValue}
                onChange={setFilterValue}
                allowKey={allowKey}
                allowPropTypes={allowPropTypes}
                displayParentId={false}
                displayTemplate={false}
              />
            </Col>
          </Row>
          <PerfectScrollbar
            ref={headerScrollRef}
            onScrollX={(e) =>
              (bodyScrollRef.current._container.scrollLeft = e.scrollLeft)
            }
          >
            <div
              className={'quote-option-header'}
              style={{ width: tableWidth }}
            >
              <Checkbox
                checked={
                  !!searchKeys.length &&
                  !~searchKeys.findIndex((o) => !propValue.includes(o))
                }
                disabled={!searchKeys.length}
                style={{ marginLeft: 6 }}
                onChange={(e) => selectAll(e.target.checked)}
              />
              {headers.map((h, i) => (
                <div
                  key={i}
                  className={`quote-option-header-cell text-omit`}
                  style={{
                    width: h.width,
                  }}
                >
                  <span>{h.name}</span>
                </div>
              ))}
            </div>
          </PerfectScrollbar>
          <PerfectScrollbar
            ref={bodyScrollRef}
            onScrollX={(e) =>
              (headerScrollRef.current._container.scrollLeft = e.scrollLeft)
            }
            style={{ height: bodyHeight }}
          >
            <div
              className={'quote-option-body'}
              style={{ width: tableWidth, height: bodyHeight }}
            >
              <AutoSizer disableHeight>
                {({ width }) => (
                  <List
                    height={bodyHeight}
                    rowCount={searchKeys.length}
                    rowHeight={58}
                    overscanRowCount={10}
                    rowRenderer={rowRenderer}
                    width={width}
                  />
                )}
              </AutoSizer>
            </div>
          </PerfectScrollbar>
        </div>
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    matchingTemplate.prop,
    matchingTemplate?.task_status,
    nodeList,
    search,
    orgId,
    filterValue,
    propValue,
    clickRow,
    userMap,
    tempMap,
    departmentNodeMap,
    selectAll,
    bodyHeight,
  ]);

  const modalSizeProps = fullScreen
    ? {
        width: '100vw',
        height: '100%',
      }
    : {
        width: 'min(1200px, 80vw)',
      };

  const modalProps = antdModalV5(modal);

  return (
    <Modal
      title={
        <Row justify="space-between">
          <Space>
            选择主题
            <Text type="secondary">{`共 ${Object.keys(nodeList)?.length} 条记录/已选 ${
              propValue.length
            } 条`}</Text>
          </Space>
          <div>
            <Tooltip title="最大化">
              <PiButton
                type="secondary"
                shape="circle"
                style={{ position: 'absolute', right: 54, top: 10 }}
                icon={
                  <FullscreenOutlined
                    type="iconContent_Download"
                    style={{ color: '#7b8aa3' }}
                  />
                }
                onClick={toggleFullScreen}
              />
            </Tooltip>
          </div>
        </Row>
      }
      destroyOnClose
      centered
      {...modalSizeProps}
      {...modalProps}
      confirmLoading={loading}
      onOk={onOk}
      onCancel={() => {
        modalProps.onCancel();
        modal.resolve();
      }}
      okButtonProps={{ disabled: !propValue.length }}
    >
      {modalContent}
    </Modal>
  );
};

const CopyNodeModal = ModalCreate(CopyNode);

export const showCopyNodeModal = (props: CopyNodeModalProps) =>
  show(CopyNodeModal, props);

export const triggerCopyNodeButton = async (props: CopyNodeModalProps) => {
  const config = props.config.copyConfig;
  const copyParams: any = {};

  // 初始状态
  if (config!.copyTempType === 'same' && !isNil(config!.initalStatus)) {
    copyParams.set_task_status = {
      [props.node.tempInfo.id]: { index: Number(config!.initalStatus) },
    };
  }

  if (config?.copyTarget === 'others') {
    return showCopyNodeModal(props);
  }

  if (config?.copyTarget === 'current') {
    const parentId =
      config.position === 'current' ? props.node.id : config!.positionPath;
    const copyNode: Record<string, any> = {
      org_id: props.orgId,
      node_id: props.node.id,
    };

    if (config.copyTempType === 'others') {
      copyParams.temp_map = { [props.node.tempInfo.id]: config.switchTemplate };
    }
    if (config.enableEdit && !isEmpty(config.enableEdit)) {
      const setProp = (await getPropsValue({
        orgId: props.orgId,
        targetTempId: config.switchTemplate,
        sourceTempId: props.node.tempInfo.id,
      })) as Record<string | number, unknown>;

      copyParams.set_prop = {
        [config.switchTemplate]: setProp,
      };
    }

    const requestKey = [props.orgId, parentId, 'copynode'].join('@');

    notification.open({
      icon: <Spin indicator={<LoadingOutlined />} />,
      message: '正在复制节点',
      key: requestKey,
      duration: 0,
    });

    try {
      await copyNodes({
        ...copyParams,
        orgId: props.orgId,
        parentId: parentId!,
        copyId: [copyNode],
        copyMode: 'NoChild',
      });
      notification.success({
        message: '复制完成！',
        key: requestKey,
        duration: 3,
      });
    } catch (error) {
      notification.error({
        message: '复制失败。',
        key: requestKey,
        duration: 3,
      });
    }
  }
};
