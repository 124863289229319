import { LoadingOutlined } from '@ant-design/icons';
import type { FC } from 'react';

import { hexToRgba } from '@/utils/utils';

interface CustomButtonProp {
  config: any;
  forbiddenClick?: boolean;
  loading?: boolean;
}

const CustomButton: FC<CustomButtonProp> = ({
  config,
  forbiddenClick,
  loading,
}) => {
  const {
    text, // 按钮 文案
    size, // 按钮大小
    pictureUrl, // 按钮icon 是否是图片
    color, // 按钮背景色
    icon, // icon
    hideWebBackground,
    hideWebText,
  } = config;

  const rectangleConfig: any = {
    large: { height: 40, padding: 16 },
    small: { height: 20, padding: 4 },
    middle: { height: 28, padding: 8 },
  };

  // 是否存在自定义的icon
  const showIcon = icon || pictureUrl;

  const renderIcon = () => {
    if (icon)
      return (
        <i
          className={`iconfont ${icon}`}
          style={{
            marginRight: 4,
            color: forbiddenClick
              ? '#BFBFBF'
              : hideWebBackground
                ? color
                : 'white',
          }}
        />
      );
    return (
      <div
        style={{
          marginRight: 4,
          height: 20,
          width: 20,
          backgroundImage: `url(${pictureUrl})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
    );
  };

  return (
    <div
      style={{
        backgroundColor: forbiddenClick
          ? '#F5F5F5'
          : hideWebBackground
            ? 'transparent'
            : loading
              ? hexToRgba(color, 0.6).rgba
              : color,
        height: rectangleConfig[size].height,
        padding: rectangleConfig[size].padding,
        width: 'fit-content',
        display: 'flex',
        alignItems: 'center',
        borderRadius: 4,
        border: forbiddenClick && !loading ? '1px solid #D9D9D9' : 'none',
        cursor: forbiddenClick || loading ? 'not-allowed' : 'pointer',
      }}
    >
      {loading && (
        <LoadingOutlined style={{ color: 'white', marginRight: 4 }} />
      )}
      {showIcon && renderIcon()}
      {!hideWebText && (
        <span
          style={{
            color: forbiddenClick
              ? '#BFBFBF'
              : hideWebBackground
                ? color
                : 'white',
          }}
        >
          {text}
        </span>
      )}
    </div>
  );
};

export default CustomButton;
