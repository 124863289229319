import type { ApiResponse } from '@linkpi/core';
import { useDebounceFn } from 'ahooks';
import { Checkbox, Divider, InputNumber, message } from 'antd';
import classnames from 'classnames';
import React, { Fragment, useMemo, useState } from 'react';

import LinkPiModal from '@/components/LinkPiModal';
import request from '@/utils/request';

import './index.less';

enum SplitType {
  Copies = 'copies',
  Value = 'value',
}

const SplitTypeList = [
  {
    label: '按份数拆分',
    key: SplitType.Copies,
  },
  {
    label: '按每份数值拆分',
    key: SplitType.Value,
  },
];

const SplitTypeTextMap = {
  [SplitType.Copies]: '拆分份数',
  [SplitType.Value]: '每份数值',
};

// 拆分最大不超过 200
const MAX_COPIES = 200;

const SplitNumber = (props: {
  index: number;
  node: any;
  onCancel: (e?: React.MouseEvent<HTMLElement>) => void;
  template: ApiResponse.CurrentUser.TemplateInfo;
  orgId: string;
}) => {
  const { index, node, onCancel, template, orgId } = props;

  const {
    name,
    number: { numericalFormat, precision, unit, unitPosition } = {
      numericalFormat: 0,
      precision: 0,
      unit: '',
      unitPosition: 0,
    },
  } = useMemo(() => template?.prop?.[index] ?? {}, [template]);

  const initValue = useMemo(() => node?.prop?._sys_temp?.[1]?.[index], [node, index]);

  const [splitType, setSplitType] = useState(SplitType.Copies);
  const [customNumber, setCustomNumber] = useState<(number | null)[]>([null]);
  const [surplus, setSurplus] = useState(0);
  const [isPrecision, togglePrecision] = useState(true);

  const [perValue, setPerValue] = useState(1);

  const [total, absoluteValue] = useMemo(() => {
    let t = 0;
    if (Number.isFinite(initValue)) {
      t = Number((numericalFormat ? initValue * 100 : initValue).toFixed(precision || 0));
    }
    setSurplus(t);
    // 当数值超过最大限制后将绝对值修改为最大限制份数
    return [t, Math.abs(t) > MAX_COPIES ? MAX_COPIES : Math.abs(t)];
  }, [initValue, numericalFormat, precision]);

  // useEffect(() => {
  //   // 初始化处理一次
  //   quickSplit(absoluteValue);
  // }, [absoluteValue]);

  const precise = (num: number | null, rank = 14) => {
    if (!num) return 0;
    const sign = num / Math.abs(num);
    const number = num * sign;
    const temp = rank - 1 - Math.floor(Math.log10(number));
    let ans;
    if (temp > 0) {
      ans = parseFloat(number.toFixed(temp));
    } else if (temp < 0) {
      ans = Math.round(number / Math.pow(10, temp)) * temp;
    } else {
      ans = Math.round(number);
    }
    return ans * sign;
  };

  const currentTotal = useMemo(
    () => precise(customNumber.reduce((p, c) => (p || 0) + (c || 0), surplus)),
    [customNumber, surplus],
  );

  const onSubmit = async () => {
    const numberList = [surplus];
    numberList.push.apply(
      numberList,
      customNumber.map((c) => c || 0),
    );
    const res = await request('/docapi/node/splitNumber', {
      method: 'POST',
      data: {
        org_id: orgId,
        node_id: node.id,
        index: index,
        numberList: numericalFormat ? numberList.map((n) => n / 100) : numberList,
      },
    });
    if (res?.status === 'ok') {
      message.success('拆分成功');
    } else {
      message.error('拆分失败');
    }
    onCancel();
  };

  const quickSplit = (e: number | null, f: boolean = false) => {
    if (e === customNumber.length + 1 && !f) {
      return;
    }
    if (!e || e <= 1) {
      message.warn('至少拆分一份主题');
      return;
    }
    if (isPrecision && absoluteValue !== MAX_COPIES && e > absoluteValue) {
      message.warn(`暂不支持快捷拆分出${absoluteValue}份以上的主题`);
      return;
    }
    if (e > MAX_COPIES) {
      message.warn(`拆分份数已达上限（${MAX_COPIES}份），请重新输入`);
      e = MAX_COPIES;
      // return;
    }
    const average = parseInt((total / e).toString());
    setCustomNumber(Array.from({ length: e - 1 }, (v, i) => average));
    setSurplus(total - average * (e - 1));
  };

  const { run: quickSplitByValue } = useDebounceFn(
    (e: number) => {
      if (total / e > MAX_COPIES) {
        message.warn(`拆分份数已达上限（${MAX_COPIES}份），请重新输入`);
        e = Math.ceil((total / MAX_COPIES) * Math.pow(10, precision)) / Math.pow(10, precision);
        setPerValue(e);
      }
      if (e >= total) {
        setCustomNumber([e]);
        setSurplus(total - e);
      } else {
        const remainder = total % e;
        const length = Math.floor(total / e);
        setCustomNumber(Array.from({ length: remainder ? length : length - 1 }, () => e));
        setSurplus(remainder || e);
      }
    },
    { wait: 500 },
  );

  const handleCustomNumber = (e: number | null, i: number, del: boolean = false) => {
    const numbers = [...customNumber];
    if (del) {
      if (numbers.length <= 1) return message.warn('至少拆分一份主题');
      numbers.splice(i, 1);
    } else {
      numbers.splice(i, 1, e);
    }
    let s = total;
    if (isPrecision) {
      numbers.forEach((n) => (s -= n || 0));
      setSurplus(s);
    }

    setCustomNumber(numbers);
  };

  const handleCheckboxChange = (_checked: boolean, _type?: SplitType) => {
    togglePrecision(_checked);
    if (_type && _checked) {
      if (_type === SplitType.Value) {
        quickSplitByValue(perValue);
      } else {
        quickSplit(
          customNumber.length + 1 < absoluteValue ? customNumber.length + 1 : absoluteValue,
          true,
        );
      }
    }
  };

  const handleSplitTypeClick = (type: SplitType) => {
    setSplitType(type);

    handleCheckboxChange(true);

    if (type === SplitType.Value) {
      handlePerValueChange(total);
    } else {
      quickSplit(2, true);
    }
  };

  const { run: handlePerValueInput } = useDebounceFn(
    (e: string) => {
      if (
        e.length &&
        +e < Math.ceil((total / MAX_COPIES) * Math.pow(10, precision)) / Math.pow(10, precision)
      ) {
        message.warn(`拆分份数已达上限（${MAX_COPIES}份），请重新输入`);
      }
    },
    { wait: 500 },
  );

  const handlePerValueChange = (e: number | null) => {
    if (typeof e !== 'number') {
      return;
    }
    setPerValue(e);
    quickSplitByValue(e);
  };

  const splitNumberInputRender = () => {
    const inputNumberProps =
      splitType === SplitType.Copies
        ? {
            placeholder: absoluteValue > 1 ? `请输入1~${absoluteValue}的整数` : '',
            onChange: quickSplit,
            value: customNumber.length + 1,
            // max: absoluteValue,
          }
        : {
            placeholder:
              absoluteValue > 1
                ? `请输入${
                    Math.ceil((total / MAX_COPIES) * Math.pow(10, precision)) /
                    Math.pow(10, precision)
                  }~${total}的整数`
                : '',
            onChange: handlePerValueChange,
            onInput: handlePerValueInput,
            value: perValue,
            min:
              Math.ceil((total / MAX_COPIES) * Math.pow(10, precision)) / Math.pow(10, precision),
            addonAfter: numericalFormat ? '%' : null,
            precision,
          };
    return (
      <InputNumber
        min={1}
        style={{ width: '100%', marginBottom: 24 }}
        disabled={absoluteValue <= 1}
        precision={0}
        {...inputNumberProps}
      />
    );
  };

  const handleAddClick = () => {
    // MAX_COPIES - 1 去除最后一个不可删除 input
    if (customNumber.length + 1 > MAX_COPIES - 1) {
      message.warn(`拆分份数已达上限（${MAX_COPIES}份）`);
    } else {
      const defaultValue = splitType === SplitType.Copies ? null : perValue;
      setCustomNumber([...customNumber, isPrecision ? null : defaultValue]);
    }
  };

  return (
    <LinkPiModal
      width={776}
      className={'split-number-modal'}
      visible={!!~index}
      onCancel={onCancel}
      title="拆分设置"
      onOk={onSubmit}
    >
      <div className="split-number">
        <div className="split-number-tab">
          {SplitTypeList.map(({ label, key }) => (
            <div
              key={key}
              className={classnames([
                'split-number-tab-item',
                {
                  active: splitType === key,
                },
              ])}
              onClick={() => handleSplitTypeClick(key)}
            >
              {label}
            </div>
          ))}
        </div>
        <div className="split-number-label">{SplitTypeTextMap[splitType]}</div>
        {splitNumberInputRender()}
        <div className={'split-number-label'}>
          拆分后，每个主题{name}的值分别为
          {/*<div className={'split-number-label-des'}>将根据拆分项对主题进行拆分</div>*/}
        </div>
        <div className="split-number-total">
          <Checkbox
            checked={isPrecision}
            onChange={(e) => handleCheckboxChange(e.target.checked, splitType)}
          >
            拆分项总和等于被拆分项
          </Checkbox>
          <Divider type="vertical" />
          <span className="split-number-total-text">
            总计：{isPrecision ? total : currentTotal}
          </span>
        </div>
        <div className="split-number-input">
          {customNumber.map((number, i) => (
            <InputNumber
              key={i}
              value={number}
              onChange={(e) => handleCustomNumber(e, i)}
              addonAfter={
                numericalFormat || customNumber.length > 1 ? (
                  <Fragment>
                    {numericalFormat ? '%' : null}
                    {customNumber.length > 1 ? (
                      <i
                        className="iconfont iconshanchu1"
                        onClick={() => handleCustomNumber(null, i, true)}
                      />
                    ) : null}
                  </Fragment>
                ) : null
              }
              style={{ width: 220, margin: '0 8px 8px 0' }}
              precision={precision}
            />
          ))}
          <InputNumber
            key={customNumber.length}
            value={surplus}
            disabled={isPrecision}
            style={{ width: 220, margin: '0 8px 8px 0' }}
            addonAfter={numericalFormat ? '%' : null}
            precision={precision}
            onChange={(e) => (isPrecision ? null : setSurplus(e || 0))}
          />
          <i className={'iconfont iconButton_add'} onClick={handleAddClick} />
        </div>
      </div>
    </LinkPiModal>
  );
};

export default SplitNumber;
