/**
 * 日期列
 */

import type { Dayjs, ManipulateType } from 'dayjs';
import dayjs from 'dayjs';

import type { PiGanttModules } from '../core';
import type Draw from '../modules/draw';
import type { positionType } from './_baseComponent';
import BaseComponent from './_baseComponent';

export default class dateColumn extends BaseComponent {
  private offsetIndex: number;
  public dayInstance: Dayjs;

  constructor(position: positionType, moduleInstances: PiGanttModules, offsetIndex: number) {
    super(position, moduleInstances);
    this.offsetIndex = offsetIndex;

    const toDay = dayjs();
    this.dayInstance = toDay.add(
      offsetIndex,
      this.moduleInstances.DateColumnManager.columnUnit as ManipulateType,
    );
  }

  private getIsFirstDateUtil() {
    let status = false;
    if (
      this.moduleInstances.DateColumnManager.columnUnit === 'day' &&
      this.dayInstance.format('DD') === '01'
    ) {
      status = true;
    }
    if (
      this.moduleInstances.DateColumnManager.columnUnit === 'month' &&
      this.dayInstance.format('MM') === '01'
    ) {
      status = true;
    }
    return status;
  }

  public renderHead(Draw: Draw) {
    Draw.save();

    const isFirstDateUnit = this.getIsFirstDateUtil();

    // 1月和1日，显示年月
    if (isFirstDateUnit) {
      this.renderFullDate(Draw);
    }

    let textColor = Draw.style.dateColumn.dateColor;
    // 当日rect width 32
    if (this.offsetIndex === 0) {
      Draw.fillRoundRect(
        {
          x: this.position.x + (this.position.width - 32) / 2,
          y: this.position.y + 4,
          width: 32,
          height: 32,
        },
        16,
        Draw.style.dateColumn.todayDateBackgroundColor,
      );
      textColor = Draw.style.dateColumn.todayDateColor;
    }

    Draw.attr({ fillStyle: textColor });
    Draw.ctx.textBaseline = 'middle';
    Draw.ctx.textAlign = 'center';
    Draw.ctx.font = `400 ${Draw.npx(14)}px  Arial`;

    let title;
    if (this.moduleInstances.DateColumnManager.columnUnit === 'day') {
      title = this.dayInstance.format('DD');
    } else {
      title = this.dayInstance.format('MM') + '月';
    }
    Draw.fillText(title, this.position.x + this.position.width / 2, this.position.y + 40 / 2);

    // border
    Draw.line(
      [
        [this.position.x, this.position.y],
        [this.position.x, this.position.y + 40],
      ],
      Draw.style.borderColor,
    );
    Draw.line(
      [
        [this.position.x + this.position.width, this.position.y],
        [this.position.x + this.position.width, this.position.y + 40],
      ],
      Draw.style.borderColor,
    );

    Draw.restore();
  }
  public renderBox() {
    const { Draw, DateColumnManager } = this.moduleInstances;
    Draw.save();
    // 周末背景
    if (DateColumnManager.columnUnit === 'day') {
      const weekDay = this.dayInstance.day();
      if (weekDay === 0 || weekDay === 6) {
        Draw.fillRect(
          this.position.x,
          80,
          this.position.width,
          Draw.canvasHeight - 80,
          Draw.style.dateColumn.weekBackgroundColor,
        );
      }
    }
    // 左右边框

    // right
    Draw.line(
      [
        [this.position.x, 80],
        [this.position.x, Draw.canvasHeight],
      ],
      Draw.style.borderColor,
    );
    // left
    Draw.line(
      [
        [this.position.x + this.position.width, 80],
        [this.position.x + this.position.width, Draw.canvasHeight],
      ],
      Draw.style.borderColor,
    );

    // 今日线
    if (this.offsetIndex === 0) {
      Draw.ctx.setLineDash([Draw.npx(4), Draw.npx(3)]);
      Draw.line(
        [
          [this.position.x + this.position.width / 2, 80],
          [this.position.x + this.position.width / 2, Draw.canvasHeight],
        ],
        Draw.style.dateColumn.todayLineBorderColor,
      );
    }

    Draw.restore();
  }

  private renderFullDate(Draw: Draw) {
    Draw.save();

    // 1月和1日，右边画到顶
    Draw.line(
      [
        [this.position.x, 0],
        [this.position.x, 80],
      ],
      Draw.style.borderColor,
    );

    Draw.attr({ fillStyle: Draw.style.dateColumn.fullDateColor });
    Draw.ctx.textBaseline = 'middle';
    Draw.ctx.textAlign = 'left';
    Draw.ctx.font = `600 ${Draw.npx(14)}px  Arial`;
    const fullFormat =
      this.moduleInstances.DateColumnManager.columnUnit === 'day' ? 'YYYY年MM月' : 'YYYY年';
    Draw.fillText(this.dayInstance.format(fullFormat), this.position.x + 18, 40 / 2);

    Draw.restore();
  }
}
