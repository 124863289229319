import { message } from 'antd';

export const reminderSetupSucccess = (isFromTree) => {
  message.success(isFromTree ? '提醒设置成功, 快去时间轴看看吧' : '提醒设置成功');
};

export const reminderFinishSucccess = (isFromTree) => {
  message.success('又完成了一个小目标');
};

export const reminderGenralSuccess = () => {
  message.success('操作成功');
};

export const addNewSuccess = () => {
  message.success('添加成功');
};

export const deleteSuccess = () => {
  message.success('节点已删除');
};

export const addTaskSuccess = () => {
  message.success('任务已添加');
};

export const reminderError = () => {
  message.warning('提醒设置失败，快去锤开发吧😭');
};

export const addTaskError = () => {
  message.warning('添加任务失败，快去锤开发吧😭');
};

export const copySuccess = () => {
  message.success('节点已复制');
};

export const pasteNoSourceError = () => {
  message.warning('请先复制节点');
};

export const pasteSelfError = () => {
  message.warning('粘自己身上不大好吧');
};

export const generalError = () => {
  message.warn('出错了，快去锤开发吧');
};

export const warningDeveloping = () => {
  message.warning('正在开发中，敬请期待');
};

export const addPropSuccess = () => {
  message.success('添加成功');
};

export const updatePropSuccess = () => {
  message.success('修改成功');
};

export const updatePropFail = () => {
  message.error('修改失败');
};

export const propRequired = () => {
  message.error('必填属性不得为空');
};

export const deletePropSuccess = () => {
  message.success('已删除');
};
