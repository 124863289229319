import StatusFlowButton from '../commonComponents/statusFlowButton';
import type { PositionType } from '../components/_baseComponent';
import GridBaseComponent from '../components/_baseComponent';
import type { PiGridModules } from '../core';

class FixStatus extends GridBaseComponent {
  public data: any;
  public type: string = 'fixStatus';
  public node: PiNode;
  public captrue: boolean = false;

  constructor(position: PositionType, moduleInstances: PiGridModules, data: any, node: PiNode) {
    super(position, moduleInstances);

    this.data = data;
    this.node = node;
  }

  public drawStatusIcon(x: number, y: number, color: string, unicode: string, fontSize?: number) {
    const { Draw } = this.moduleInstances;
    const { ctx } = Draw;

    ctx.save();
    Draw.attr({
      fillStyle: color,
      textBaseline: 'top',
      textAlign: 'start',
      font: `${Draw.npx(fontSize || 14)}px iconfont`,
    });

    Draw.fillText(Draw.iconFont(unicode), x, y);
    ctx.restore();
  }

  public drawText(text: string, color: string, x: number, y: number, fontSize?: number) {
    const { Draw } = this.moduleInstances;

    Draw.attr({
      fillStyle: color,
      textBaseline: 'top',
      textAlign: 'start',
      font: `${Draw.npx(fontSize || 14)}px  sans-serif`,
    });

    Draw.fillText(text, x, y);
    return Draw.measureTextWidth(text);
  }

  public async click() {
    this.moduleInstances.DataManager.config.onStatusClick(
      this.node,
      this.position,
      this.data.status.button,
    );
  }

  public render() {
    const { Render, Draw } = this.moduleInstances;
    const { x, y } = this.position;
    const { statusName, statusIcon, button, buttons } = this.data.status;

    this.drawStatusIcon(x, y, statusIcon.color, statusIcon.unicode, this.data.fontSize);

    this.drawText(statusName, this.data.color, x + this.data.fontSize + 2, y, this.data.fontSize);

    if (button) {
      let advance = x + this.data.fontSize + 6 + Draw.measureTextWidth(statusName);
      // 按钮样式，开始画按钮
      buttons.forEach((btn: any) => {
        if (advance - x > this.data.colWidth) return; // 超出colwidth 就别绘制了
        const statusFlowButton = new StatusFlowButton(
          {
            x: advance,
            y: y - 6,
            width: Draw.measureTextWidth(btn.name) + 12,
            height: 12 + this.data.fontSize,
          },
          this.moduleInstances,
          btn,
          this.node,
          this.data.fontSize,
        );

        advance = advance + Draw.measureTextWidth(btn.name) + 12 + 6;
        statusFlowButton.render();
      });
    }

    if (this.isHover) {
      Render.setCursor('pointer');
    }
  }
}

export default FixStatus;
