import { MinusCircleOutlined } from '@ant-design/icons';
import type { ProFormListProps } from '@ant-design/pro-form';
import { ProFormList } from '@ant-design/pro-form';
import cls from 'classnames';
import { isNil } from 'ramda';
import { useMemo } from 'react';

import styles from './styles.less';

type PiProFormListProps<T> = ProFormListProps<T> & {
  creatorButtonText?: string;
};
export function PiProFormList<T>(props: PiProFormListProps<T>) {
  const formProps = useMemo(() => {
    const result = { ...props };
    if (isNil(result.copyIconProps)) {
      result.copyIconProps = false;
    }
    if (isNil(result.deleteIconProps)) {
      result.deleteIconProps = {
        Icon: MinusCircleOutlined,
      };
    }

    return result;
  }, [props]);

  const creatorButtonProps = useMemo(() => {
    if (!isNil(props.creatorButtonProps) && !props.creatorButtonProps) {
      return false;
    }

    const p = { ...props.creatorButtonProps };
    p.type = 'dashed';

    if (!isNil(props.creatorButtonText)) {
      p.creatorButtonText = props.creatorButtonText;
    }

    return p;
  }, [props.creatorButtonProps, props.creatorButtonText]);

  return (
    <ProFormList
      {...formProps}
      creatorButtonProps={creatorButtonProps}
      // @ts-ignore
      className={cls(formProps.containerClassName, styles.wrapper)}
    />
  );
}
