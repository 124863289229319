import type { ApiResponse } from '@linkpi/core';

const numberTypeOptionRule: MATCHONG_PROP_OPTION_RULE_TYPE = {
  supportType: 'all',
  supportFormulaFormat: [0, 1],
  supportQuoteMatchingType: [0, 1, 2, 3, 4, 5],
  supportQuoteMatchingRealDataType: 'all',
  supportQuoteMatchingRealDataFormulaFormat: [0, 1],
};

type MATCHONG_PROP_OPTION_RULE_TYPE = {
  /** 是否支持引用标题 */
  // enableTitle: boolean;
  /** 支持引用的类型 */
  supportType: 'all' | ('-3' | ApiResponse.CurrentUser.propType)[];
  /** 支持引用的公式类型 */
  supportFormulaFormat: (0 | 1)[];
  /** 支持引用引用属性的引用方式 */
  supportQuoteMatchingType: (-1 | 0 | 1 | 2 | 3 | 4 | 5)[];
  /** 支持引用引用属性引用原值的的属性类型 */
  supportQuoteMatchingRealDataType: 'all' | (ApiResponse.CurrentUser.propType | '-3')[];
  /** 支持引用引用属性引用原值的公式类型 */
  supportQuoteMatchingRealDataFormulaFormat: (0 | 1)[];
};
export const MATCHING_PROP_OPTION_RULE: Record<
  ApiResponse.CurrentUser.propType,
  MATCHONG_PROP_OPTION_RULE_TYPE
> = {
  text: {
    supportType: ['-3', 'text', 'tag', 'enum', 'number', 'currency', 'formula', 'quote'],
    supportFormulaFormat: [0, 1],
    supportQuoteMatchingType: [0, 1, 2, 3, 4, 5],
    supportQuoteMatchingRealDataType: [
      '-3',
      'text',
      'tag',
      'enum',
      'date',
      'datetime',
      'positioning',
      'user',
      'number',
      'currency',
      'formula',
    ],
    supportQuoteMatchingRealDataFormulaFormat: [0, 1],
  },
  tag: {
    supportType: ['-3', 'text', 'tag', 'enum', 'formula', 'number', 'currency', 'quote'],
    supportFormulaFormat: [0, 1],
    supportQuoteMatchingType: [0, 1, 2, 3, 4, 5],
    supportQuoteMatchingRealDataType: [
      '-3',
      'text',
      'tag',
      'enum',
      'number',
      'currency',
      'formula',
    ],
    supportQuoteMatchingRealDataFormulaFormat: [0, 1],
  },
  enum: {
    supportType: ['-3', 'text', 'tag', 'enum', 'formula', 'number', 'currency', 'quote'],
    supportFormulaFormat: [0, 1],
    supportQuoteMatchingType: [0, 1, 2, 3, 4, 5],
    supportQuoteMatchingRealDataType: [
      '-3',
      'text',
      'tag',
      'enum',
      'number',
      'currency',
      'formula',
    ],
    supportQuoteMatchingRealDataFormulaFormat: [0, 1],
  },
  user: {
    supportType: ['user', 'text', 'quote'],
    supportFormulaFormat: [],
    supportQuoteMatchingType: [0],
    supportQuoteMatchingRealDataType: ['text', 'user'],
    supportQuoteMatchingRealDataFormulaFormat: [],
  },
  number: numberTypeOptionRule,
  currency: numberTypeOptionRule,
  date: {
    supportType: [],
    supportFormulaFormat: [],
    supportQuoteMatchingType: [],
    supportQuoteMatchingRealDataType: [],
    supportQuoteMatchingRealDataFormulaFormat: [],
  },
  datetime: {
    supportType: [],
    supportFormulaFormat: [],
    supportQuoteMatchingType: [],
    supportQuoteMatchingRealDataType: [],
    supportQuoteMatchingRealDataFormulaFormat: [],
  },
  cascade: {
    supportType: ['cascade', 'quote'],
    supportFormulaFormat: [],
    supportQuoteMatchingType: [0],
    supportQuoteMatchingRealDataType: ['cascade'],
    supportQuoteMatchingRealDataFormulaFormat: [],
  },
  positioning: {
    supportType: [],
    supportFormulaFormat: [],
    supportQuoteMatchingType: [],
    supportQuoteMatchingRealDataType: [],
    supportQuoteMatchingRealDataFormulaFormat: [],
  },
  attachment: {
    supportType: ['attachment', 'quote'],
    supportFormulaFormat: [],
    supportQuoteMatchingType: [0],
    supportQuoteMatchingRealDataType: ['attachment'],
    supportQuoteMatchingRealDataFormulaFormat: [],
  },
  quote: {
    supportType: 'all',
    supportFormulaFormat: [0, 1],
    supportQuoteMatchingType: [0, 1, 2, 3, 4, 5],
    supportQuoteMatchingRealDataType: 'all',
    supportQuoteMatchingRealDataFormulaFormat: [0, 1],
  },
  formula: {
    supportType: [],
    supportFormulaFormat: [],
    supportQuoteMatchingType: [],
    supportQuoteMatchingRealDataType: [],
    supportQuoteMatchingRealDataFormulaFormat: [],
  },
  address: {
    supportType: ['address', 'quote'],
    supportFormulaFormat: [],
    supportQuoteMatchingType: [0],
    supportQuoteMatchingRealDataType: ['address'],
    supportQuoteMatchingRealDataFormulaFormat: [],
  },
  str: {
    supportType: [],
    supportFormulaFormat: [],
    supportQuoteMatchingType: [],
    supportQuoteMatchingRealDataType: [],
    supportQuoteMatchingRealDataFormulaFormat: [],
  },
  auto_inc: {
    supportType: [],
    supportFormulaFormat: [],
    supportQuoteMatchingType: numberTypeOptionRule.supportQuoteMatchingType,
    supportQuoteMatchingRealDataType: [],
    supportQuoteMatchingRealDataFormulaFormat: [],
  },
};

type MATCHING_PROP_TYPE_RULE_type = Record<
  string,
  {
    supportType:
      | 'all'
      | (
          | ApiResponse.CurrentUser.propType
          | '-1' // 创建者
          | '-2' // 创建时间
          | '-3' // 标题
          | '-5' // 负责人
          | '-6' // 参与者
          | '-7' // 开始时间
          | '-8' // 结束时间
          | '-9' // 备注
          | '-1000'
        )[]; // 状态
    supportFormulaFormat: number[];
    supportQuoteMatchingType: 'all' | (-1 | 0 | 1 | 2 | 3 | 4 | 5)[];
    supportQuoteMatchingRealDataType: ('-3' | ApiResponse.CurrentUser.propType)[] | 'all';
    supportQuoteMatchingRealDataFormulaFormat: number[];
  }
>;

const tagTypeRule: MATCHING_PROP_TYPE_RULE_type = {
  '-1': {
    supportType: [
      '-3',
      'text',
      'tag',
      'enum',
      'number',
      'currency',
      'cascade',
      'positioning',
      'formula',
      'quote',
    ],
    supportFormulaFormat: [0, 1],
    supportQuoteMatchingType: [0, 1, 2, 3, 4, 5],
    supportQuoteMatchingRealDataType: [
      '-3',
      'text',
      'tag',
      'enum',
      'number',
      'currency',
      'formula',
    ],
    supportQuoteMatchingRealDataFormulaFormat: [0, 1],
  },
  '0': {
    supportType: [
      '-3',
      '-1000',
      'text',
      'tag',
      'enum',
      'number',
      'currency',
      'positioning',
      'formula',
      'quote',
    ],
    supportFormulaFormat: [0, 1],
    supportQuoteMatchingType: [0, 1, 2, 3, 4, 5],
    supportQuoteMatchingRealDataType: [
      '-3',
      'text',
      'tag',
      'enum',
      'number',
      'currency',
      'formula',
    ],
    supportQuoteMatchingRealDataFormulaFormat: [0, 1],
  },
};

const numberTypeRule: MATCHING_PROP_TYPE_RULE_type = {
  '0': {
    supportType: ['number', 'currency', 'formula', 'quote'],
    supportFormulaFormat: [0],
    supportQuoteMatchingType: [0, 1, 2, 3, 4, 5],
    supportQuoteMatchingRealDataType: ['number', 'currency', 'formula'],
    supportQuoteMatchingRealDataFormulaFormat: [0],
  },
  '5': {
    supportType: 'all',
    supportFormulaFormat: [0, 1],
    supportQuoteMatchingType: [0, 1, 2, 3, 4, 5],
    supportQuoteMatchingRealDataType: 'all',
    supportQuoteMatchingRealDataFormulaFormat: [0, 1],
  },
  '1': {
    supportType: ['number', 'currency', 'formula', 'quote'],
    supportFormulaFormat: [0],
    supportQuoteMatchingType: [0, 1, 2, 3, 4, 5],
    supportQuoteMatchingRealDataType: ['number', 'currency', 'formula'],
    supportQuoteMatchingRealDataFormulaFormat: [0],
  },
  '2': {
    supportType: ['number', 'currency', 'formula', 'quote'],
    supportFormulaFormat: [0],
    supportQuoteMatchingType: [0, 1, 2, 3, 4, 5],
    supportQuoteMatchingRealDataType: ['number', 'currency', 'formula'],
    supportQuoteMatchingRealDataFormulaFormat: [0],
  },
  '3': {
    supportType: ['number', 'currency', 'formula', 'quote'],
    supportFormulaFormat: [0],
    supportQuoteMatchingType: [0, 1, 2, 3, 4, 5],
    supportQuoteMatchingRealDataType: ['number', 'currency', 'formula'],
    supportQuoteMatchingRealDataFormulaFormat: [0],
  },
  '4': {
    supportType: ['number', 'currency', 'formula', 'quote'],
    supportFormulaFormat: [0],
    supportQuoteMatchingType: [0, 1, 2, 3, 4, 5],
    supportQuoteMatchingRealDataType: ['number', 'currency', 'formula'],
    supportQuoteMatchingRealDataFormulaFormat: [0],
  },
};

export const MATCHING_PROP_TYPE_RULE: Record<
  ApiResponse.CurrentUser.propType,
  MATCHING_PROP_TYPE_RULE_type
> = {
  text: {
    '0': {
      supportType: [
        '-3',
        '-1000',
        'text',
        'tag',
        'enum',
        'user',
        'number',
        'currency',
        'date',
        'datetime',
        'cascade',
        'positioning',
        'formula',
        'quote',
      ],
      supportFormulaFormat: [0, 1],
      supportQuoteMatchingType: [0, 1, 2, 3, 4, 5],
      supportQuoteMatchingRealDataType: 'all',
      supportQuoteMatchingRealDataFormulaFormat: [],
    },
    '5': {
      supportType: [
        '-3',
        'text',
        'tag',
        'enum',
        'user',
        'number',
        'currency',
        'date',
        'datetime',
        'cascade',
        'positioning',
        'formula',
      ],
      supportFormulaFormat: [0, 1],
      supportQuoteMatchingType: [],
      supportQuoteMatchingRealDataType: [],
      supportQuoteMatchingRealDataFormulaFormat: [],
    },
    '1': {
      supportType: ['number', 'currency', 'formula', 'quote'],
      supportFormulaFormat: [0],
      supportQuoteMatchingType: [0, 1, 2, 3, 4, 5],
      supportQuoteMatchingRealDataType: ['number', 'currency', 'formula'],
      supportQuoteMatchingRealDataFormulaFormat: [0],
    },
    '2': {
      supportType: ['number', 'currency', 'formula', 'quote'],
      supportFormulaFormat: [0],
      supportQuoteMatchingType: [0, 1, 2, 3, 4, 5],
      supportQuoteMatchingRealDataType: ['number', 'currency', 'formula'],
      supportQuoteMatchingRealDataFormulaFormat: [0],
    },
    '3': {
      supportType: ['number', 'currency', 'formula', 'quote'],
      supportFormulaFormat: [0],
      supportQuoteMatchingType: [0, 1, 2, 3, 4, 5],
      supportQuoteMatchingRealDataType: ['number', 'currency', 'formula'],
      supportQuoteMatchingRealDataFormulaFormat: [0],
    },
    '4': {
      supportType: ['number', 'currency', 'formula', 'quote'],
      supportFormulaFormat: [0],
      supportQuoteMatchingType: [0, 1, 2, 3, 4, 5],
      supportQuoteMatchingRealDataType: ['number', 'currency', 'formula'],
      supportQuoteMatchingRealDataFormulaFormat: [0],
    },
  },
  tag: tagTypeRule,
  enum: tagTypeRule,
  user: {
    '0': {
      supportType: ['user', 'text', 'quote', '-1', '-5', '-6'],
      supportFormulaFormat: [],
      supportQuoteMatchingType: [0],
      supportQuoteMatchingRealDataType: ['user', 'text'],
      supportQuoteMatchingRealDataFormulaFormat: [],
    },
    '-1': {
      supportType: ['user', 'text', 'quote'],
      supportFormulaFormat: [],
      supportQuoteMatchingType: [0],
      supportQuoteMatchingRealDataType: ['user', 'text'],
      supportQuoteMatchingRealDataFormulaFormat: [],
    },
  },
  number: numberTypeRule,
  currency: numberTypeRule,
  date: {},
  datetime: {},
  cascade: {
    '0': {
      supportType: ['cascade', 'quote'],
      supportFormulaFormat: [],
      supportQuoteMatchingType: [0],
      supportQuoteMatchingRealDataType: ['cascade'],
      supportQuoteMatchingRealDataFormulaFormat: [],
    },
  },
  positioning: {},
  auto_inc: {},
  address: {
    '0': {
      supportType: ['address', 'quote'],
      supportFormulaFormat: [],
      supportQuoteMatchingType: [0],
      supportQuoteMatchingRealDataType: ['address'],
      supportQuoteMatchingRealDataFormulaFormat: [],
    },
    '-1': {
      supportType: ['address', 'quote'],
      supportFormulaFormat: [],
      supportQuoteMatchingType: [0],
      supportQuoteMatchingRealDataType: ['address'],
      supportQuoteMatchingRealDataFormulaFormat: [],
    },
  },
  formula: {},
  attachment: {
    '0': {
      supportType: ['attachment', 'quote'],
      supportFormulaFormat: [],
      supportQuoteMatchingType: [0],
      supportQuoteMatchingRealDataType: ['attachment'],
      supportQuoteMatchingRealDataFormulaFormat: [],
    },
  },
  str: {},
  quote: {
    '0': {
      supportType: [
        'text',
        'tag',
        'enum',
        'user',
        'number',
        'currency',
        'date',
        'datetime',
        'cascade',
        'positioning',
        'address',
        'formula',
        '-1',
        '-2',
        '-3',
        '-1000',
        '-5',
        '-6',
        '-7',
        '-8',
        'attachment',
        'quote',
        'auto_inc',
      ],
      supportFormulaFormat: [0, 1],
      supportQuoteMatchingType: 'all',
      supportQuoteMatchingRealDataType: 'all',
      supportQuoteMatchingRealDataFormulaFormat: [0, 1],
    },
    '5': {
      supportType: [
        'text',
        'tag',
        'enum',
        'user',
        'number',
        'currency',
        'date',
        'datetime',
        'cascade',
        'positioning',
        'address',
        'formula',
        '-3',
        '-1000',
        '-5',
        '-6',
        '-7',
        '-8',
        'quote',
        'auto_inc',
      ],
      supportFormulaFormat: [0, 1],
      supportQuoteMatchingType: 'all',
      supportQuoteMatchingRealDataType: 'all',
      supportQuoteMatchingRealDataFormulaFormat: [0, 1],
    },
    '1': {
      supportType: ['number', 'currency', 'formula', 'auto_inc', 'quote'],
      supportFormulaFormat: [0],
      supportQuoteMatchingType: 'all',
      supportQuoteMatchingRealDataType: ['number', 'currency', 'formula'],
      supportQuoteMatchingRealDataFormulaFormat: [0],
    },
    '2': {
      supportType: ['number', 'currency', 'formula', 'auto_inc', 'quote'],
      supportFormulaFormat: [0],
      supportQuoteMatchingType: 'all',
      supportQuoteMatchingRealDataType: ['number', 'currency', 'formula'],
      supportQuoteMatchingRealDataFormulaFormat: [0],
    },
    '3': {
      supportType: ['number', 'currency', 'formula', 'auto_inc', 'quote', 'date', 'datetime'],
      supportFormulaFormat: [0],
      supportQuoteMatchingType: 'all',
      supportQuoteMatchingRealDataType: ['number', 'currency', 'formula'],
      supportQuoteMatchingRealDataFormulaFormat: [0],
    },
    '4': {
      supportType: ['number', 'currency', 'formula', 'auto_inc', 'quote', 'date', 'datetime'],
      supportFormulaFormat: [0],
      supportQuoteMatchingType: 'all',
      supportQuoteMatchingRealDataType: ['number', 'currency', 'formula'],
      supportQuoteMatchingRealDataFormulaFormat: [0],
    },
  },
};
