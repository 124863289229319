import type { PiGanttModules } from '../core';
import type { positionType } from './_baseComponent';
import BaseComponent from './_baseComponent';

export default class RangeSelect extends BaseComponent {
  public config: any;

  constructor(position: positionType, moduleInstances: PiGanttModules, config: any) {
    super(position, moduleInstances);
    this.config = config;
  }

  public onClick(x: number, y: number) {
    this.moduleInstances.DateColumnManager.setRange(this.config.key);
  }

  public render() {
    const { Draw, Render } = this.moduleInstances;

    Draw.save();

    let fillColor = Draw.style.rangeSelect.backgroundColor;
    let textColor = Draw.style.rangeSelect.color;

    if (this.isHover) {
      Render.setCursor('pointer');
    }

    if (this.config.key === this.moduleInstances.DateColumnManager.range || this.isHover) {
      fillColor = Draw.style.rangeSelect.hoverBackgroundColor;
      textColor = Draw.style.rangeSelect.hoverColor;
    }

    Draw.fillRoundRect(this.position, 5, fillColor);

    Draw.ctx.textBaseline = 'middle';
    Draw.ctx.textAlign = 'center';
    Draw.ctx.font = `${Draw.npx(13)}px  Arial`;
    Draw.attr({ fillStyle: textColor });

    Draw.fillText(
      this.config.name,
      this.position.x + this.position.width / 2,
      this.position.y + this.position.height / 2 + 1,
    );

    Draw.restore();
  }
}
