import cls from 'classnames';
import CodeMirror from 'codemirror';
import { debounce } from 'lodash';
import type { ForwardRefRenderFunction } from 'react';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';

import 'codemirror/mode/javascript/javascript';

import 'codemirror/lib/codemirror.css';
import styles from './styles.less';

type FormulaEditorPropsType = {
  onEditorChange: (editor: CodeMirror.Editor) => void;
  onTextChange: (str: string, editor: CodeMirror.Editor) => void;
};

export type FormulaEditorHandlerType = {
  getEditor: () => CodeMirror.Editor;
};

const FormulaEditor: ForwardRefRenderFunction<FormulaEditorHandlerType, FormulaEditorPropsType> = (
  props,
  ref,
) => {
  useImperativeHandle(ref, () => ({
    // @ts-ignore
    getEditor() {
      return cmEditorRef.current;
    },
  }));

  const { onEditorChange, onTextChange } = props;
  const cmContainerRef = useRef<HTMLDivElement | null>(null);
  const cmEditorRef = useRef<CodeMirror.Editor>();
  const [isFocus, setIsFocus] = useState(false);

  useEffect(() => {
    // @ts-ignore
    cmEditorRef.current = CodeMirror(cmContainerRef.current, {
      value: '',
      mode: 'javascript',
      lineWrapping: true,
    });
    cmEditorRef.current.focus();

    cmEditorRef.current.on('focus', onFocus);
    cmEditorRef.current.on('blur', onBlur);
    cmEditorRef.current.on('change', onChange);
    // 光标变化时触发 通知检查当前光标处token
    cmEditorRef.current.on('cursorActivity', onCursorActivity);

    return () => {
      cmEditorRef.current?.off('focus', onFocus);
      cmEditorRef.current?.off('blur', onBlur);
      cmEditorRef.current?.off('change', onChange);
      cmEditorRef.current?.off('cursorActivity', onCursorActivity);
    };
  }, []);

  const onFocus = () => {
    setIsFocus(true);
  };
  const onBlur = () => {
    setIsFocus(false);
  };
  const onCursorActivity = (e: CodeMirror.Editor) => {
    onEditorChange(e);
  };

  const onChange = useCallback(
    debounce((e) => {
      const value = e.getValue();
      onTextChange(value, e);
    }, 300),
    [],
  );

  return (
    <div className={cls(styles.formulaEditor)}>
      <div className={cls(styles.code, isFocus ? styles.active : '')} ref={cmContainerRef} />
    </div>
  );
};

export default forwardRef(FormulaEditor);
