import { diffSymbol, textSort } from '@linkpi/core';
import { Col, DatePicker, Input, InputNumber, Radio, Row, Select, Space } from 'antd';
import { isArray } from 'lodash';
import moment from 'moment';
import { memo, useEffect, useState } from 'react';

import ChooseTagSelect from '@/components/ChooseTagSelect';
import CustomDate from '@/components/CustomDate';
import { uniqueArr } from '@/utils/utils';

import './index.less';

const { Option } = Select;

let inputChangeT = null;

const EditProps = (props) => {
  const {
    config,
    onChange,
    value,
    userMap,
    enumOption,
    diffValue,
    tagExtends = [],
    newEnumOption,
  } = props;
  const requireClassName = config.required ? 'required' : '';
  const [diffDateRadio, setDiffDateRadio] = useState(false);

  useEffect(() => {
    if (config.type === 'date' || config.type === 'datetime') {
      setDiffDateRadio(value === diffSymbol);
    }
  }, [config, value]);

  if (!config || !config.type || config.delete || config.hide || config.type === 'quote') {
    return '';
  }

  const onDateChange = (e) => {
    if (e === 'diff') {
      onChange(diffSymbol);
    } else {
      onChange(!e ? null : e.valueOf());
    }
  };

  const onTextChange = (e) => {
    const text = e.target.value;
    clearTimeout(inputChangeT);
    inputChangeT = setTimeout(() => {
      onChange(text);
    }, 500);
  };

  const onUserChange = (e) => {
    onChange(e === 'diff' ? diffSymbol : e);
  };

  const onCurrencyChange = (v) => {
    clearTimeout(inputChangeT);
    inputChangeT = setTimeout(() => {
      // 精度
      const precision = config?.extend?.precision || config?.number?.precision || 0;
      let precisionValue = v.toFixed(precision);

      // 百分比的话需要除以100
      if (config.number?.numericalFormat) {
        precisionValue = precisionValue / 100;
      }

      onChange(precisionValue);
    }, 500);
  };

  if (config.type === 'date' || config.type === 'datetime') {
    let picker;
    const diffSelectValue = value === diffSymbol ? 'diff' : 'custom';
    const showValue = value === diffSymbol ? null : value ? moment(value) : null;

    if (diffValue) {
      picker = (
        <Radio.Group
          onChange={(e) => {
            if (e.target.value === 'diff') {
              onDateChange('diff');
            } else {
              setDiffDateRadio(false);
            }
          }}
          value={diffSelectValue}
        >
          <Space direction="vertical">
            <Radio value="diff">{diffValue.diffName}</Radio>
            <Radio value="custom">
              自定义：
              <DatePicker
                disabled={diffDateRadio}
                value={showValue}
                onChange={onDateChange}
                format={config.type === 'datetime' ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD'}
                showTime={config.type === 'datetime'}
                showToday
                placeholder="请选择"
              />
            </Radio>
          </Space>
        </Radio.Group>
      );
    } else {
      picker = (
        <CustomDate
          type={config.name === '开始时间' ? 'start' : 'end'}
          isDate={config.type !== 'datetime'}
          value={showValue}
          onChange={onDateChange}
          style={{ width: '100%' }}
          showToday
          placeholder="请选择"
        />
      );
    }

    return (
      <Row
        align={diffValue ? 'top' : 'middle'}
        justify="space-between"
        style={{ marginBottom: 15 }}
      >
        <Col className={requireClassName} span={5} style={{ textAlign: 'right' }}>
          {config.name}
        </Col>
        <Col span={18}>{picker}</Col>
      </Row>
    );
  }

  if (config.type === 'user') {
    // 过滤选择范围
    const scope = config.extend && config.extend.length ? config.extend : ['-1'];
    let userList;
    if (~scope.indexOf('-1')) {
      userList = Object.values(userMap);
    } else {
      userList = Object.values(userMap).filter((user) => {
        let valid = false;
        if (user.group_ids) {
          if (isArray(user.group_ids)) {
            user.group_ids.map((id) => {
              if (~scope.indexOf(id)) {
                valid = true;
              }
            });
          } else if (typeof user.group_ids === 'string') {
            if (~scope.indexOf(user.group_ids)) {
              valid = true;
            }
          }
        }
        return valid;
      });
    }

    if (diffValue) {
      userList.unshift({
        account_id: 'diff',
        nick_name: diffValue.diffName,
      });
    }
    let showValue;
    if (value === diffSymbol) {
      showValue = 'diff';
    } else {
      if (config.multiple) {
        showValue = value instanceof Array ? value.filter((x) => !!x) : [];
      } else {
        showValue = value;
      }
    }

    // TODO fix 默认值不在选值范围内
    // if (!userMap[showValue]) {
    //   showValue = null;
    // }

    return (
      <Row align="middle" justify="space-between" style={{ marginBottom: 15 }}>
        <Col className={requireClassName} span={5} style={{ textAlign: 'right' }}>
          {config.name}
        </Col>
        <Col span={18}>
          <Select
            value={showValue}
            placeholder="请选择"
            style={{ width: '100%' }}
            mode={config.multiple ? 'multiple' : ''}
            onChange={onUserChange}
            filterOption={(input, option) =>
              option?.children?.toLowerCase?.()?.indexOf(input.toLowerCase()) >= 0
            }
          >
            {userList.map((x, i) => (
              <Option key={i} value={x.account_id}>
                {x.nick_name}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
    );
  }

  if (config.type === 'text' || config.type === 'str') {
    return (
      <Row align="middle" justify="space-between" style={{ marginBottom: 15 }}>
        <Col className={requireClassName} span={5} style={{ textAlign: 'right' }}>
          {config.name}
        </Col>
        <Col span={18}>
          <Input
            defaultValue={value || ''}
            onChange={onTextChange}
            placeholder={`请输入${config.name}`}
          />
        </Col>
      </Row>
    );
  }

  if (config.type === 'currency' || config.type === 'number') {
    let v = value || 0;
    if (config.number?.numericalFormat) v = v * 100;
    return (
      <Row align="middle" justify="space-between" style={{ marginBottom: 15 }}>
        <Col className={requireClassName} span={5} style={{ textAlign: 'right' }}>
          {config.name}
        </Col>
        <Col span={18}>
          <InputNumber
            defaultValue={v}
            onChange={onCurrencyChange}
            placeholder={`请输入${config.name}`}
            style={{ width: '100%' }}
            addonAfter={config.number?.numericalFormat ? '%' : ''}
          />
        </Col>
      </Row>
    );
  }

  if (config.type === 'enum' || config.type === 'tag') {
    // enum 选值，不允许新增
    // tag 选值，允许新增
    let arr = [];
    if ((config.conditionMatching || config.enumQuote) && enumOption) {
      arr = (enumOption || []).concat(config.extend || []);
    } else {
      arr = config.extend || [];
    }
    if (config.type === 'tag') {
      arr = uniqueArr([...arr, ...tagExtends]).filter((to) => !!to);
    } else {
      arr = arr.map((i) => ({ value: i, label: i }));
      if (Array.isArray(newEnumOption)) {
        newEnumOption.forEach((i) => {
          if (!config.extend?.includes(i.label)) arr.push(i);
        });
      }
      arr = uniqueArr([...arr]).filter((i) => i);
    }
    arr = textSort(arr);
    let showValue;
    if (config.multiple) {
      if (value instanceof Array) {
        showValue = value.filter((x) => !!x);
      } else {
        showValue = value === null ? [] : [value];
      }
    } else {
      if (value instanceof Array) {
        showValue = value.filter((x) => !!x)[0] || '';
      } else {
        showValue = value || '';
      }
    }

    return (
      <Row align="middle" justify="space-between" style={{ marginBottom: 15 }}>
        <Col className={requireClassName} span={5} style={{ textAlign: 'right' }}>
          {config.name}
        </Col>
        <Col span={18}>
          {config.type === 'tag' ? (
            <ChooseTagSelect
              data={arr}
              domId="editprop-tag-select"
              defaultValue={showValue}
              allowClear={!config.required}
              onChange={onChange}
              mode={config.multiple ? 'multiple' : ''}
            />
          ) : (
            <Select
              value={showValue}
              placeholder="请选择"
              allowClear={!config.required}
              style={{ width: '100%' }}
              mode={config.multiple ? 'multiple' : ''}
              onChange={onChange}
              showSearch={true}
            >
              {arr.map((i) => (
                <Option key={i.value} value={i.value}>
                  {i.aux ? (
                    i.aux.position === 0 ? (
                      <>
                        <p>{i.label}</p>
                        <p style={{ color: 'rgb(185, 187, 188)' }}>{i.aux.label}</p>
                      </>
                    ) : (
                      <>
                        <p style={{ color: 'rgb(185, 187, 188)' }}>{i.aux.label}</p>
                        <p>{i.label}</p>
                      </>
                    )
                  ) : (
                    i.label
                  )}
                </Option>
              ))}
            </Select>
          )}
        </Col>
      </Row>
    );
  }

  return (
    <Row align="middle" justify="space-between" style={{ marginBottom: 15 }}>
      暂不支持：{config.type}
    </Row>
  );
};

export default memo(EditProps);
