import type { PiGridModules } from '../core';
import type { PositionType } from './_baseComponent';
import GridBaseComponent from './_baseComponent';

class HeaderCellMore extends GridBaseComponent {
  public data: any;
  public type: string = 'headerCellMore';
  constructor(position: PositionType, moduleInstances: PiGridModules, data: any, fixed: boolean) {
    super(position, moduleInstances, fixed);

    this.data = data;
  }

  public click() {}
  public render() {}
}

export default HeaderCellMore;
