import { message } from 'antd';

import request from '@/utils/request';

export function deleteTempStatus(params: {
  org_id: string;
  origin_index: number;
  target_index: number;
  temp_id: string;
}) {
  return request('/docapi/switchTaskStatusIndex', {
    method: 'POST',
    data: params,
  })
    .then(() => message.success('删除成功'))
    .catch((err) => {
      message.error('删除失败');
      return Promise.reject(err);
    });
}

export function queryTableHeader(org_id: string) {
  return request('/api/tableHeader/query', {
    method: 'POST',
    data: { org_id },
  });
}
export function queryTableHeaderMap(org_id: string, th_id: string) {
  return request('/api/tableHeaderMap/query', {
    method: 'POST',
    data: { org_id, th_id },
  });
}
export function outerTriggerUrl(
  org_id: string,
  db_id: string,
  table_id: string,
  column_id: string,
) {
  return request('/api/outerTriggerUrl', {
    method: 'POST',
    data: { org_id, db_id, table_id, column_id },
  });
}
export function queryDbConfig(org_id: string) {
  return request('/docapi/db/config', {
    method: 'POST',
    data: {
      org_id,
      option: 'select',
      getTable: true,
      getColumn: false,
      config: {
        name: '',
      },
    },
  });
}
