import type Core from '../core';
import $ from '../helper/dom';
import BaseModule from './_baseModule';

import '../style/scroll.less';

type directionType = 'vertical' | 'horizontal';

export default class Scrollbar extends BaseModule {
  private direction: directionType;
  //   private scrollEl: any;

  public static CSS = {
    vertical: 'pi-gantt-scroll-vertical',
    horizontal: 'pi-gantt-scroll-horizontal',
  };

  private get scrollbarClassname(): string {
    return Scrollbar.CSS[this.direction];
  }

  public get scrollTop() {
    return this.doms.scrollbar.scrollTop;
  }

  public get scrollLeft() {
    return this.doms.scrollbar.scrollLeft;
  }

  //   public get scrollContainerWidth() {
  //     return this.scrollEl.containerWidth;
  //   }

  constructor(core: Core, direction: directionType) {
    super(core);
    this.direction = direction;
  }

  public prepare() {
    const { Render, Listeners, DateColumnManager } = this.moduleInstances;

    this.doms.scrollbar = $.make('div', this.scrollbarClassname);
    Render.doms.wrapper.append(this.doms.scrollbar);

    // mock
    this.doms.scrollbarInner = $.make('div', this.scrollbarClassname + '-inner');
    this.doms.scrollbar.append(this.doms.scrollbarInner);

    if (this.direction === 'vertical') {
      this.doms.scrollbarInner.style.height = '0px';
    } else {
      this.doms.scrollbarInner.style.width = DateColumnManager.scrollWidth + 'px';
    }

    // 在Render代理了鼠标滚动事件，这里跳过这个事件
    // @ts-ignore
    this.doms.scrollbar.onmousewheel = (e) => {
      e.stopPropagation();
    };
    // this.move被调用时触发
    this.doms.scrollbar.onscroll = (e) => {
      e.stopPropagation();
      this.moduleInstances.Render.render();
    };
  }
  public move(opt: ScrollToOptions) {
    this.doms.scrollbar.scroll(opt);
  }

  public setInner(length: number) {
    if (this.direction === 'vertical') {
      this.doms.scrollbarInner.style.height = length + 'px';
    } else {
      this.doms.scrollbarInner.style.width = length + 'px';
    }
  }
}
