import { propIsNull } from '@linkpi/core';

import { displayGroupName } from '@/utils/utils';

export const groupingNodes = (
  nodes: any[],
  groupByKey: string,
  spaceUsers: any,
  groups: any,
  targetTheme: string,
  templateList: any,
) => {
  const spaceUserMap = spaceUsers.reduce((a: any, b: any) => {
    a[b.account_id] = b;
    return a;
  }, {});

  let res: any = nodes;
  let groupMap: any;

  function genUserGroupMap(groupType: any) {
    const gm: any = {};
    if (groupType === '0') {
      spaceUsers?.map((user: any) => {
        gm[user.account_id] = {
          name: user.account_id,
          list: [],
          nameType: 'user',
        };
      });
    } else {
      groups?.map((g: any) => {
        gm[g.group_id] = {
          name: g.group_id,
          list: [],
          nameType: 'group',
        };
      });
    }
    gm.default = {
      name: '未定义',
      list: [],
      nameType: groupType === '0' ? 'user' : 'group',
    };
    return gm;
  }

  function genUserGroups(users: any) {
    return users.reduce((a: any, b: any) => {
      const user = spaceUserMap[b];
      if (Array.isArray(user?.group_ids)) {
        user.group_ids.map((g: any) => {
          if (!a.includes(g)) a.push(g);
        });
      }
      return a;
    }, []);
  }

  if (!groupByKey) {
    res = {
      default: { list: res, name: '', info: {} },
    };
  } else if (groupByKey.startsWith('templateStatusOwnerAndParticipant')) {
    // 负责人或参与者
    const groupType = groupByKey.split('_')[1] || '0';
    const propType = groupType === '0' ? 'user' : '1';
    groupMap = genUserGroupMap(groupType);
    res.map((node: any) => {
      let all;
      let joiner = node.tempInfo.statusProp[1] || [];
      const owner = node.tempInfo.statusProp[0];
      joiner = Array.isArray(joiner) ? joiner : [joiner];
      all = joiner.includes(owner) || !owner ? joiner : [...joiner, owner];
      if (groupType !== '0') {
        all = genUserGroups(all);
      }
      if (Array.isArray(all) && all.length) {
        all.forEach((x) => {
          if (groupMap[x]) {
            groupMap[x].list.push(node);
          } else if (x) {
            groupMap[x] = {
              name: x,
              list: [node],
              nameType: propType,
            };
          }
        });
      } else {
        if (groupMap.default) {
          groupMap.default.list.push(node);
        } else {
          groupMap.default = {
            name: 'default',
            list: [node],
            nameType: propType,
          };
        }
      }
    });
  } else if (groupByKey === 'templateStatus') {
    //状态
    groupMap = {
      default: {
        name: '未分组',
        list: [],
        sort: -1,
      },
    };
    const tempData = templateList?.find((t: any) => t.template_id === targetTheme);
    if (Array.isArray(tempData?.task_status)) {
      tempData.task_status.forEach((task: any, taskIndex: number) => {
        if (!task?.name || task.delete) return;
        const key = `${targetTheme}_${taskIndex}`;
        groupMap[key] = {
          name: task.name,
          list: [],
          info: {
            tempId: targetTheme,
            status: taskIndex,
          },
          sort: task.sort ?? taskIndex,
        };
      });
    }
    res.map((node: any) => {
      const key = `${node.tempInfo.id}_${node.tempInfo.status}`;
      if (!groupMap[key]) {
        groupMap.default.list.push(node);
      } else {
        groupMap[key].list.push(node);
      }
    });
  } else if (groupByKey === 'sysCreator') {
    const propType = 'user';
    groupMap = genUserGroupMap('0');
    res.map((node: any) => {
      let key = node.prop?._sys_creator;
      if (!key || !spaceUsers.find((x: any) => x.account_id === key)) {
        key = 'default';
      }
      if (groupMap[key]) {
        groupMap[key].list.push(node);
      } else {
        groupMap[key] = {
          name: key,
          list: [node],
          nameType: propType,
        };
      }
    });
  } else if (groupByKey === '_sys_tag') {
    const propType = 'tag';
    groupMap = {};
    groupMap.default = {
      name: '未定义',
      list: [],
      nameType: propType,
    };
    res.map((node: any) => {
      const tags =
        node.prop?._sys_tag && Object.keys(node.prop._sys_tag)?.length
          ? Object.keys(node.prop._sys_tag)
          : ['default'];
      tags.map((key) => {
        if (groupMap[key]) {
          groupMap[key].list.push(node);
        } else {
          groupMap[key] = {
            name: key,
            list: [node],
            nameType: propType,
          };
        }
      });
    });
  } else if (groupByKey.startsWith('templateStatusPropBase_')) {
    //状态任务属性（负责人、参与者、开始时间、结束时间）
    let [mark, statusPropIndex, type] = groupByKey.split('_');
    statusPropIndex = Number(statusPropIndex);
    if (!type) type = '0';
    // base只有4个 可分组的只有user类型
    const propType = type === '0' ? 'user' : 'group';
    groupMap = genUserGroupMap(type);
    res.map((node: any) => {
      let key = node.tempInfo.statusProp[statusPropIndex];
      if (propIsNull(key)) {
        key = ['default'];
      } else {
        if (!Array.isArray(key)) key = [key];
        if (type !== '0') {
          key = genUserGroups(key);
        }
      }
      key.forEach((x: any) => {
        if (groupMap[x]) {
          groupMap[x].list.push(node);
        } else {
          groupMap[x] = {
            name: x,
            list: [node],
            nameType: propType,
          };
        }
      });
    });
  } else {
    let propInfo: any = null,
      propIndex: any = null,
      propType = '0';
    groupMap = {};
    if (groupByKey.startsWith('templateProp_')) {
      //模板属性
      const [mark, tempId, index, type] = groupByKey.split('_');
      propIndex = Number(index);
      propType = type || '0';
      const tempData = templateList?.find((x: any) => x.template_id === tempId);
      if (tempData && tempData.prop[propIndex]) {
        propInfo = tempData.prop[propIndex];
      }
      res = res.filter((x: any) => x.tempInfo.id === tempId);
    }

    if (!propInfo) return groupMap;
    switch (propInfo.type) {
      case 'user':
        groupMap = genUserGroupMap(propType);
        break;
      case 'enum':
      case 'tag':
        propInfo.extend?.map((x: any) => {
          groupMap[x] = {
            name: x,
            list: [],
            nameType: propInfo.type,
          };
        });
        break;
    }
    propType = propType === '0' ? propInfo.type : 'group';
    groupMap.default = {
      name: '未定义',
      list: [],
      nameType: propType,
    };
    res.map((node: any) => {
      let key = groupByKey.startsWith('templateProp_')
        ? node.tempInfo.prop[propIndex]
        : node.tempInfo.statusProp[propIndex];
      // 数字 分组时未填写等于0
      if (propIsNull(key)) {
        key = propType === 'number' ? ['0'] : ['default'];
      }
      if (!Array.isArray(key)) key = [key];
      if (propType === 'group') key = genUserGroups(key);
      let unknowKey = false;
      key.map((x: any) => {
        if (groupMap[x]) {
          groupMap[x].list.push(node);
        } else if (x) {
          groupMap[x] = {
            name: x,
            list: [node],
            nameType: propType,
          };
        } else {
          unknowKey = true;
        }
      });
      if (unknowKey) {
        groupMap.default.list.push(node);
      }
    });
  }
  if (groupMap) {
    Object.keys(groupMap).map((key) => {
      if (!groupMap[key].list.length) {
        delete groupMap[key];
      }
    });
    res = groupMap;
  }

  return Object.keys(res)
    .sort((a, b) => {
      if ('sort' in res[a]) return res[a].sort - res[b].sort;
      const groupNameA = String(displayGroupName(res[a], spaceUserMap, groups)),
        groupNameB = String(displayGroupName(res[b], spaceUserMap, groups));
      if (groupNameA === '未定义') return -1;
      if (groupNameB === '未定义') return 1;
      if (groupNameA === '未知成员') return -1;
      if (groupNameB === '未知成员') return 1;
      if (res[a].nameType === 'number') return Number(res[a].name) - Number(res[b].name);
      return groupNameA.localeCompare(groupNameB);
    })
    .reduce((map, groupKey, index) => {
      map[groupKey] = {
        ...res[groupKey],
        sort: index,
      };
      return map;
    }, {} as any);
};
