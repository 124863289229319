import { message, Popover } from 'antd';
import { pick } from 'ramda';
import { useState } from 'react';

import { makeProgressIterator, showProgressModal } from '@/components';
import ExportGraphicCodesModal from '@/components/ExportGraphicCodesModal';
import ExportFormCodeModal from '@/pages/home/components/View/ViewSetting/ExportFormCodeModal';
import request from '@/utils/request';
import {
  downLoadFile,
  exportGraphicCodes,
  getExportGraphicCodesPropValues,
  getPropsSupportGraphicCode,
} from '@/utils/utils';

import '../GroupForView/styles.less';

export default (p: any) => {
  const { getterPiNode, orgInfo, checkId, viewName, template } = p;

  if (checkId.length < 1) return null;

  const [exportVisible, setExportVisible] = useState(false);

  const [showExportGraphicCodesModal, setShowExportGraphicCodesModal] = useState(false);
  const [checkedNodes, setCheckedNodes] = useState([]);
  const [graphicCodeProps, setGraphicCodeProps] = useState<any[]>([]);

  const [exportCustomFormVisible, setExportCustomFormVisible] = useState(false);
  const handleExportNodeCode = async () => {
    try {
      await showProgressModal({
        loadingText: '正在导出...',
        finishText: '导出完成',
        progressIterator: makeProgressIterator({
          startFn: () =>
            request('/docapi/share/batch', {
              method: 'POST',
              data: {
                org_id: orgInfo.orgId,
                node_id: checkId,
              },
            }).then((res) =>
              res.status === 'ok' ? { status: 'ok', data: pick(['total', 'async_id'], res) } : res,
            ) as any,
          checkFn: (data) =>
            request('/docapi/share/progress', {
              method: 'POST',
              data,
            }),
        }),
        onFinish(modal, finishRes: any) {
          if (finishRes.status !== 'ok') {
            return message.error('导出失败，请重试');
          }
          message.success('导出成功');
          downLoadFile(finishRes.data.url, viewName + '_主题码');
        },
      });
    } catch (error) {
      console.error('🤖 导出失败', error);
      message.error('操作失败，请重试');
    }
  };

  const exportPropsGraphicCodes = async () => {
    const props = getPropsSupportGraphicCode(template);
    if (props.length === 0) return message.warning('不存在可以导出图形码的属性～');
    if (props.length < 2) {
      // 直接导出 ，不需要打开属性选择modal
      const propValues = getExportGraphicCodesPropValues(
        checkId.map((id: string) => getterPiNode.value.nodeManager.findChildren(id)),
        props,
      );
      await exportGraphicCodes(propValues);
      return message.success(`${props[0].name}的图形码已导出`);
    }

    setGraphicCodeProps(props);
    setCheckedNodes(checkId.map((id: string) => getterPiNode.value.nodeManager.findChildren(id)));
    setShowExportGraphicCodesModal(true);
  };

  return (
    <>
      <Popover
        trigger={['click']}
        overlayClassName="view-setting-export"
        placement={'bottomRight'}
        visible={exportVisible}
        onVisibleChange={() => setExportVisible(!exportVisible)}
        content={
          <div className="view-setting-list">
            <li
              className="view-setting-item"
              onClick={() => {
                handleExportNodeCode();
                setExportVisible(false);
              }}
            >
              导出主题二维码
            </li>
            <li
              className="view-setting-item"
              onClick={() => {
                exportPropsGraphicCodes();
                setExportVisible(false);
              }}
            >
              导出属性图形码
            </li>
            {Object.keys(template?.temp_option?.custom_form || {}).length ? (
              <li
                className="view-setting-item"
                onClick={() => {
                  setExportCustomFormVisible(true);
                  setExportVisible(false);
                }}
              >
                导出表单二维码
              </li>
            ) : null}
          </div>
        }
      >
        <div className="view-setting-block">导出图形码</div>
      </Popover>
      {showExportGraphicCodesModal && (
        <ExportGraphicCodesModal
          closeModal={() => setShowExportGraphicCodesModal(false)}
          nodes={checkedNodes}
          graphicCodeProps={graphicCodeProps}
        />
      )}
      <ExportFormCodeModal
        open={exportCustomFormVisible}
        onCancel={() => setExportCustomFormVisible(false)}
        nodeIds={checkId}
        orgId={orgInfo.orgId}
        template={template}
      />
    </>
  );
};
