import type { CurrentUser } from '@linkpi/core';
import { createContext, useContext } from 'react';

import type { ViewConditionFormPropsType } from '../types';

export const ConditionFilterConfigContext = createContext<
  Pick<
    ViewConditionFormPropsType,
    | 'maxTagCount'
    | 'experimental_disableHistoryStatus'
    | 'experimental_disableOpList'
    | 'experimental_userLiteMode'
    | 'experimental_enableUserProperty'
  > & {
    curTemplate?: CurrentUser.TemplateInfo | null;
    curTemplateList?: CurrentUser.TemplateInfo[];
  }
>({});

export const useConditionFilterConfigContext = () => {
  return useContext(ConditionFilterConfigContext);
};
