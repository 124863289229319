import type { ApiResponse } from '@linkpi/core';
import cls from 'classnames';
import { random } from 'lodash';
import { isNil } from 'ramda';
import type { FC } from 'react';
import { memo, useMemo, useRef } from 'react';

import type { IStatisticFormValue } from '@/components/PageModelEditor/src/components/StatisticComponents/Form';
import { usePageThemeMode } from '@/hook';
import { cn, dashboardCardContentStatNumColors, displayGroupName, toFixed } from '@/utils/utils';

import type { statDataType } from '../ListTable/useStat';
import type { DashBoardCardType } from '.';
import { DEFAULT_IMG, DEFAULT_IMG_KEY } from './ComponentForm/AddForm';

import styles from './CardContentStat.less';

const toFixButRemovePrecision = (n: number, fractionDigits: number) =>
  Number(toFixed(n, fractionDigits)).toLocaleString('zh', { notation: 'compact' });

const getColor = () => {
  const index = random(dashboardCardContentStatNumColors.length - 1, false);
  return dashboardCardContentStatNumColors[index];
};

const lightBgColors = [
  'linear-gradient(135deg, #00CEAC 0%, #00C6DC 100%)',
  'linear-gradient(135deg, #A25BFF 0%, #D15DFF 100%)',
  'linear-gradient(135deg, #14BDFF 0%, #24DDF1 100%)',
  'linear-gradient(135deg, #FF8E1D 0%, #FFB92E 100%)',
  'linear-gradient(135deg, #FF6897 0%, #FF8DE1 100%)',
  'linear-gradient(135deg, #4370FF 0%, #43BDFF 100%)',
];

// const getRandomBgColors = (isDark: boolean) => {
//   const shuffleArray = (array: any[]) => {
//     for (let i = array.length - 1; i > 0; i--) {
//       const j = Math.floor(Math.random() * (i + 1));
//       [array[i], array[j]] = [array[j], array[i]];
//     }
//     return array;
//   };
//   return isDark ? shuffleArray(dashboardCardContentStatNumColors) : shuffleArray(lightBgColors);
// };

type CardContentStatPropsType = {
  cardViewInfo: DashBoardCardType;
  statData: number | Record<string, number>;
  templateName: string;
  propConfigList: ApiResponse.CurrentUser.TemplateProp[];
  statUnitConfig: null | ApiResponse.CurrentUser.numberExtend;
  onPreview: () => void;
  config?: IStatisticFormValue<'more'>;
};
/**
 *
 * 显示单个统计
 *
 */
export const _CardContentStat: FC<CardContentStatPropsType> = (props) => {
  const {
    cardViewInfo,
    statData,
    propConfigList,
    templateName,
    statUnitConfig,
    onPreview,
    config = {} as Partial<IStatisticFormValue<'more'>>,
  } = props;
  const allCount = useMemo(() => {
    if (!statData) return 0;
    if (typeof statData === 'number') return statData;
    return Object.keys(statData).reduce((a, b) => a + statData[b], 0);
  }, [statData]);

  const randColor = useMemo(() => {
    return getColor();
  }, []);

  const statKeyIndex = Number(cardViewInfo.stat?.key.split('_')[1] || 0);

  let displayNum: any = allCount;

  // 精度计算
  if (!isNil(statUnitConfig?.precision) && typeof displayNum === 'number') {
    displayNum = toFixButRemovePrecision(displayNum, statUnitConfig!.precision);
  }

  let displayNumStr = displayNum?.toLocaleString('zh', { notation: 'compact' }) || '-- ';

  if (statUnitConfig?.unit) {
    if (statUnitConfig.unitPosition === 1) displayNumStr = statUnitConfig.unit + displayNumStr;
    else displayNumStr += statUnitConfig.unit;
  }

  let displayDesc;
  const propName = propConfigList?.[statKeyIndex]?.name || '';
  if (cardViewInfo.stat === null) {
    displayDesc = `${templateName} 总数`;
  } else if (cardViewInfo.stat.value === 'count') {
    displayDesc = `${templateName} 总数`;
  } else if (cardViewInfo.stat.value === 'sum') {
    displayDesc = `${propName} 总计`;
  } else if (cardViewInfo.stat.value === 'ave') {
    displayDesc = `${propName} 平均值`;
  } else if (cardViewInfo.stat.value === 'max') {
    displayDesc = `${propName} 最大值`;
  } else if (cardViewInfo.stat.value === 'min') {
    displayDesc = `${propName} 最小值`;
  }

  const IMG = config.groupImgList?.find((x) => x.key === DEFAULT_IMG_KEY)?.url || DEFAULT_IMG;

  if (config.layout === 'photo-col') {
    return (
      <div className="w-full flex justify-center">
        <div
          onClick={onPreview}
          // width: boxWidth, height: boxWidth,
          // style={{ background: color }}
          className={cn(
            'flex flex-row w-[200px] h-20 bg-[#F7F7F9] rounded-[16px] items-center pl-[34px] mb-4 cursor-pointer mr-2',
          )}
        >
          <img className="w-14 h-14 rounded-full mr-3 object-cover" src={IMG} />
          <div className="overflow-hidden flex flex-col ml-2">
            <span className="text-[16px] text-[#3D3D3D] truncate">{displayDesc}</span>
            <span className="text-[14px] font-bold text-[#3D3D3D] truncate">{displayNumStr}</span>
          </div>
        </div>
      </div>
    );
  }
  if (config.layout === 'photo-row') {
    return (
      <div className="w-full flex justify-center">
        <div
          onClick={onPreview}
          // width: boxWidth, height: boxWidth,
          // style={{ background: color }}
          className={cn(
            'overflow-hidden flex flex-row w-[200px] h-20 bg-[#F7F7F9] rounded-[16px] items-center pl-6 mb-4 cursor-pointer mr-2',
          )}
        >
          <img className="w-10 h-10 rounded-full mr-3 object-cover" src={IMG} />
          <span className="text-[16px] text-[#3D3D3D] mr-2 truncate">{displayDesc}</span>
          <span className="text-[24px] font-bold text-[#3D3D3D] truncate">{displayNumStr}</span>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.CardContentStat}>
      <div
        onClick={onPreview}
        className={styles.CardContentStatNum}
        style={{
          backgroundImage: cardViewInfo.config?.view_info.numColor || randColor,
          ['--webkit-background-clip']: 'text',
          ['--moz-background-clip']: 'text',
        }}
      >
        {displayNumStr}
      </div>
      <div className={styles.CardContentStatDesc}>{displayDesc}</div>
    </div>
  );
};
export const CardContentStat = memo(_CardContentStat);

type CardContentStatByGroupPropsType = {
  cardViewInfo: DashBoardCardType;
  width: number;
  height: number;
  statData: Record<string, number>;
  propConfigList: ApiResponse.CurrentUser.TemplateProp[];
  userMap: Record<string, ApiResponse.OrgUser.OrgUserItem>;
  statUnitConfig: null | ApiResponse.CurrentUser.numberExtend;
  onPreview: () => void;
  config?: IStatisticFormValue<'more'>;
};
/**
 *
 * 显示分组统计
 *
 */
export const _CardContentStatByGroup: FC<CardContentStatByGroupPropsType> = (props) => {
  const {
    width,
    height,
    statData,
    cardViewInfo,
    userMap,
    statUnitConfig,
    onPreview,
    config = {} as Partial<IStatisticFormValue<'more'>>,
  } = props;

  const containerRef = useRef<HTMLDivElement>(null);
  const { isDark } = usePageThemeMode();

  const [boxsWrapper, showNavButton] = useMemo(() => {
    if (!statData || !Object.keys(statData).length) return [null, false];

    const wrapperStyles: Record<string, string | number> = {};
    let showNavButton = false;

    /*
     * QUESTION
     * boxWidth 计算逻辑？ @yuan7
     */
    // old: 100 = 最小宽度为88 + margin 12
    let boxWidth = 80;
    const groupDataArr = Object.keys(statData).map((x) => ({ value: statData[x], groupKey: x }));
    const boxCount = groupDataArr.length;

    const boxArr: { groupKey: string; value: number }[][] = [];

    const heightOmitPadding = height - 24;
    const widthOmitPadding = width - 48;

    // 1. 按100的宽度，计算一行的个数没如果可以显示的下，那么就显示1行
    const maxFullRowCount = Math.floor(widthOmitPadding / boxWidth);
    if (boxCount <= maxFullRowCount) {
      // 只有1行 判断最大宽度和最大高度 取最小值
      const maxHeight = heightOmitPadding;
      const maxWidth = Math.floor(widthOmitPadding / boxCount);

      boxWidth = Math.min(maxWidth, maxHeight);
      boxArr.push(groupDataArr);
    } else {
      // 2. 先通过高度 计算行数
      const lineCount = Math.floor(heightOmitPadding / boxWidth);
      // 3. 再通过分组数和行数，计算每行的个数
      const rowCount = Math.ceil(boxCount / lineCount);
      // 4. 判断 如果每行个数小于最大可显示的个数，那么就放大撑满
      if (rowCount <= maxFullRowCount) {
        // 多行 放大撑满
        // 判断最大宽度和最大高度 取最小值
        const maxWidth = Math.floor(widthOmitPadding / rowCount);
        const maxHeight = Math.floor(heightOmitPadding / lineCount);
        boxWidth = Math.min(maxWidth, maxHeight);
        new Array(lineCount).fill('').map((line, lineIndex) => {
          const start = lineIndex * rowCount;
          const end = start + rowCount;
          boxArr.push(groupDataArr.slice(start, end));
        });
      } else {
        // 按行列数渲染，左右滚动

        wrapperStyles.alignItems = 'flex-start';
        showNavButton = true;

        new Array(lineCount).fill('').map((line, lineIndex) => {
          const start = lineIndex * rowCount;
          const end = start + rowCount;
          boxArr.push(groupDataArr.slice(start, end));
        });
      }
    }

    const boxs = boxArr.map((line, i) => {
      const row = line.map((group, j) => {
        const statKey = cardViewInfo?.stat?.key;

        /**
         * 显示单位
         * 1. 小组件的统计指定属性（统计的属性为以下两种）
         * 数字属性/数字公式
         * 引用数字属性/数字公式，方式：原值、求和、求平均、最大值、最小值
         * 2. 统计方式为：求和、求平均、最大值、最小值
         */
        let statValue: string | number = 0;
        if (statKey) {
          let statValueNumber: any = group.value;
          if (typeof statValueNumber === 'number' && !isNil(statUnitConfig?.precision)) {
            statValueNumber = toFixButRemovePrecision(statValueNumber, statUnitConfig!.precision);
          }

          const unit = statUnitConfig?.unit || '';
          // 0:后置；1：前置
          const unitPosition = statUnitConfig?.unitPosition || 0;
          if (unitPosition === 0) {
            statValue = statValueNumber + unit;
          } else {
            statValue = unit + statValueNumber;
          }
        } else {
          // 没有启用 statKey  就显示grouplist.length 计数
          statValue = group.value;
        }
        // const groupName = displayGroupName(group, userMap, []);
        const groupName = isUUID(group.groupKey)
          ? displayGroupName({ nameType: 'user', name: group.groupKey }, userMap, [])
          : group.groupKey;

        const color = lightBgColors[j % lightBgColors.length];

        const IMG = config.groupImgList?.find((x) => x.key === DEFAULT_IMG_KEY)?.url || DEFAULT_IMG;
        const imgUrl = config.groupImgList?.find((x) => `${x.key}` === groupName)?.url || IMG;

        if (config.layout === 'photo-col') {
          return (
            <div
              key={j}
              onClick={onPreview}
              // width: boxWidth, height: boxWidth,
              // style={{ background: color }}
              className={cn(
                'flex flex-row w-[200px] h-20 bg-[#F7F7F9] rounded-[16px] items-center pl-[34px] mb-4 cursor-pointer mr-2',
              )}
            >
              <img className="w-14 h-14 rounded-full mr-3 object-cover" src={imgUrl} />
              <div className="overflow-hidden flex flex-col ml-2">
                <span className="text-[16px] text-[#3D3D3D] truncate">{groupName}</span>
                <span className="text-[14px] font-bold text-[#3D3D3D] truncate">{statValue}</span>
              </div>
            </div>
          );
        }
        if (config.layout === 'photo-row') {
          return (
            <div
              key={j}
              onClick={onPreview}
              // width: boxWidth, height: boxWidth,
              // style={{ background: color }}
              className={cn(
                'overflow-hidden flex flex-row w-[200px] h-20 bg-[#F7F7F9] rounded-[16px] items-center pl-6 mb-4 cursor-pointer mr-2',
              )}
            >
              <img className="w-10 h-10 rounded-full mr-3 object-cover" src={imgUrl} />
              <span className="text-[16px] text-[#3D3D3D] mr-2 truncate">{groupName}</span>
              <span className="text-[24px] font-bold text-[#3D3D3D] truncate">{statValue}</span>
            </div>
          );
        }
        return (
          <div key={j} className={styles.Box} style={{ width: boxWidth, height: boxWidth }}>
            <div onClick={onPreview} className={styles.BoxInner} style={{ background: color }}>
              <div className={styles.BoxNum} style={{ color: '#fff' }}>
                {statValue}
              </div>
              <div className={cls(styles.BoxDesc, 'text-omit')} style={{ color: '#fff' }}>
                {groupName}
              </div>
            </div>
          </div>
        );
      });
      return (
        <div key={i} className={styles.BoxLine}>
          {row}
        </div>
      );
    });

    const _boxsWrapper = (
      <div className={styles.BoxWrapper} style={wrapperStyles}>
        {boxs}
      </div>
    );

    return [_boxsWrapper, showNavButton];
  }, [statData, cardViewInfo, userMap, width, height, statUnitConfig]);

  const handleScroll = (direction: 'left' | 'right') => {
    if (!containerRef.current) return;
    if (direction === 'left') {
      containerRef.current.scrollLeft -= 100;
    } else {
      containerRef.current.scrollLeft += 100;
    }
  };

  let prevBtn = null;
  let nextBtn = null;
  if (showNavButton) {
    prevBtn = <div className={styles.PrevBtn} onClick={() => handleScroll('left')} />;
    nextBtn = <div className={styles.NextBtn} onClick={() => handleScroll('right')} />;
  }

  return (
    <div className={styles.CardContentStatByGroup}>
      {prevBtn}
      <div className={styles.CardContentStatByGroupInner} ref={containerRef}>
        {boxsWrapper}
      </div>
      {nextBtn}
    </div>
  );
};

export const CardContentStatByGroup = memo(_CardContentStatByGroup);

export function isUUID(str: string) {
  const uuidRegex =
    /^[0-9A-Fa-f]{8}-?[0-9A-Fa-f]{4}-?[0-9A-Fa-f]{4}-?[0-9A-Fa-f]{4}-?[0-9A-Fa-f]{12}$/;
  return uuidRegex.test(str);
}
