import type { InputProps } from 'antd';
import { Input } from 'antd';
import type { FC } from 'react';

interface ITextProps extends InputProps {
  id: string;
}

/**
 * 暂时是普通组件，未封装 FormItem
 */
export const TemplateTextPropInput: FC<React.PropsWithChildren<ITextProps>> = (props) => {
  return <Input {...props} />;
};
