import type { ApiResponse } from '@linkpi/core';
import type { CurrentUser } from '@linkpi/core';
import { propIsNull } from '@linkpi/core';
import type { FormInstance } from 'antd';
import { Button, DatePicker, Form, message, Select, TreeSelect } from 'antd';

import { filterUsersByPinyin } from '@/utils/utils';
const { OptGroup, Option } = Select;
const { RangePicker } = DatePicker;

export const getConditionForm = (props: {
  viewType: ApiResponse.ViewList.ViewChartsInfo['type'];
  currentTemplate: any;
  form: FormInstance;
  conditionOptions: any;
  setConditions: any;
  conditions: any;
  parentId: any;
  hasModifyPermission: () => boolean;
  disableNodeScope: boolean;
  setParentId: any;
  selectNode?: string;
  treeData: any;
  orgInfo: CurrentUser.OrgInfo;
  handleConditionKeyChange: any;
  handleConditionTimeTypeChange: any;
  conditionValues: Record<string, { value: number | string; label: unknown }[]>;
}) => {
  const {
    viewType,
    currentTemplate,
    form,
    conditionOptions,
    setConditions,
    conditions,
    parentId,
    hasModifyPermission,
    disableNodeScope,
    setParentId,
    selectNode,
    treeData,
    orgInfo,
    handleConditionKeyChange,
    handleConditionTimeTypeChange,
    conditionValues,
  } = props;

  return (
    <Form.Item hidden={[4].includes(viewType)}>
      <Form.List name="conditions">
        {(fields, { add, remove }) => (
          <>
            <Form.Item style={{ marginBottom: 8 }}>
              <div className="form-label line-label">
                <div className="setting-title">筛选条件</div>
                <Button
                  className={'line-add-btn'}
                  size={'small'}
                  onClick={() => {
                    if (!currentTemplate) {
                      form.validateFields(['template']);
                      message.warn('请选择主题类型');
                      return;
                    }
                    const fc = form.getFieldValue('conditions') || [];
                    if (
                      !~fc.findIndex((x: any) => !x?.key || propIsNull(x?.value)) &&
                      fc.length < conditionOptions.length
                    ) {
                      add();
                      setConditions([...conditions, undefined]);
                    }
                  }}
                  icon={<i className={'iconfont iconadd'} style={{ fontSize: 12 }} />}
                >
                  添加
                </Button>
              </div>
            </Form.Item>
            <div className="form-list-item form-scope" key={'parentId'}>
              <div className="form-scope-label">范围</div>
              <div className="form-scope-value">
                {Object.keys(parentId).map((x, i) => {
                  return (
                    <div className={'form-item-parent-select'} key={i}>
                      <TreeSelect
                        disabled={!hasModifyPermission() || disableNodeScope}
                        popupClassName="scope-treeSelect"
                        dropdownRender={(originNode) => {
                          return (
                            <>
                              {originNode}
                              {/* @todo 大模型-节点例如 */}
                              {selectNode && (
                                <div className="scope-treeSelect-extra">
                                  <div
                                    className="scope-extra-btn"
                                    onClick={(_) => {
                                      const pid = { ...parentId };
                                      delete pid[x];
                                      pid[selectNode] = 0;
                                      setParentId(pid);
                                    }}
                                  >
                                    当前主题
                                  </div>
                                </div>
                              )}
                            </>
                          );
                        }}
                        treeData={treeData}
                        value={x}
                        onChange={(e) => {
                          const pid = { ...parentId };
                          if (!(e in parentId)) {
                            delete pid[x];
                            pid[e] = 0;
                            setParentId(pid);
                          }
                        }}
                        treeDefaultExpandedKeys={[orgInfo.rootId]}
                        showSearch={true}
                        filterTreeNode={(e, treeNode) => {
                          return !!~(treeNode?.title as unknown as string)
                            .toLowerCase()
                            .indexOf(e.toLowerCase());
                        }}
                        virtual={false}
                      />
                      {i === 0 ? (
                        <div
                          className="cancel-btn"
                          onClick={() => {
                            setParentId({ ...parentId, ['']: 0 });
                          }}
                          style={{ display: hasModifyPermission() ? 'block' : 'none' }}
                        >
                          <i className={'iconfont iconadd'} />
                        </div>
                      ) : (
                        <div
                          className="cancel-btn"
                          style={{ display: hasModifyPermission() ? 'block' : 'none' }}
                          onClick={() => {
                            const pid = { ...parentId };
                            delete pid[x];
                            if (!x) {
                              setParentId(pid);
                            } else {
                              setParentId(pid);
                            }
                          }}
                        >
                          <i className={'iconfont iconButton_cancel'} />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <div key={fieldKey} className={'form-list-item'}>
                <Form.Item
                  {...restField}
                  fieldKey={[fieldKey as any, 'key']}
                  name={[name, 'key']}
                  rules={[{ required: true, message: '请选择筛选项' }]}
                  className={'group-key'}
                >
                  <Select
                    filterOption={(input, option) =>
                      (option?.children as unknown as string)
                        ?.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch={true}
                    placeholder={'请选择筛选项'}
                    onChange={(e) => handleConditionKeyChange(e, name)}
                  >
                    <OptGroup label="属性">
                      {conditionOptions.map((option: any, i: number) => {
                        if (option.value.startsWith('prop')) {
                          return (
                            <Option
                              value={option.value}
                              key={i}
                              disabled={~conditions.indexOf(option.value)}
                            >
                              {option.label}
                            </Option>
                          );
                        }
                      })}
                    </OptGroup>
                    <OptGroup label="状态相关">
                      <Option
                        value={'status'}
                        key={'status'}
                        disabled={!!~conditions.indexOf('status')}
                      >
                        当前状态
                      </Option>
                      <Option
                        value={'historyStatus'}
                        key={'historyStatus'}
                        disabled={!!~conditions.indexOf('historyStatus')}
                      >
                        历史状态
                      </Option>
                    </OptGroup>
                    <OptGroup label="状态属性">
                      <Option
                        value={'statusOwner'}
                        key={'statusOwner'}
                        disabled={!!~conditions.indexOf('statusOwner')}
                      >
                        负责人
                      </Option>
                      <Option
                        value={'startTime'}
                        key={'startTime'}
                        disabled={!!~conditions.indexOf('startTime')}
                      >
                        开始时间
                      </Option>
                      <Option
                        value={'endTime'}
                        key={'endTime'}
                        disabled={!!~conditions.indexOf('endTime')}
                      >
                        结束时间
                      </Option>
                    </OptGroup>
                    <OptGroup label="系统属性">
                      <Option
                        value={'creator'}
                        key={'creator'}
                        disabled={!!~conditions.indexOf('creator')}
                      >
                        创建者
                      </Option>
                    </OptGroup>
                  </Select>
                </Form.Item>
                <Form.Item
                  {...restField}
                  fieldKey={[fieldKey as any, 'value']}
                  name={[name, 'value']}
                  rules={[{ required: true, message: '请选择条件' }]}
                  className={'group-value'}
                >
                  {form.getFieldValue('conditions')[name]?.key?.endsWith('Time') ? (
                    <>
                      <Form.Item
                        fieldKey={[fieldKey as any, 'value', 'timeType']}
                        name={[name, 'value', 'timeType']}
                        rules={[{ required: true, message: '请选择时间类型' }]}
                        style={{ marginBottom: 12 }}
                        initialValue={0}
                      >
                        <Select
                          placeholder={'请选择时间类型'}
                          onChange={() => handleConditionTimeTypeChange(name)}
                        >
                          <Option value={0}>范围</Option>
                          <Option value={1}>日期</Option>
                        </Select>
                      </Form.Item>

                      {form.getFieldValue('conditions')[name]?.value?.timeType === 1 ? (
                        <Form.Item
                          fieldKey={[fieldKey as any, 'value', 'timeValue']}
                          name={[name, 'value', 'timeValue']}
                          rules={[{ required: true, message: '请选择时间范围' }]}
                          style={{ marginBottom: 12 }}
                        >
                          <RangePicker />
                        </Form.Item>
                      ) : (
                        <Form.Item
                          fieldKey={[fieldKey as any, 'value', 'timeRange']}
                          name={[name, 'value', 'timeRange']}
                          rules={[{ required: true, message: '请选择时间范围' }]}
                          style={{ marginBottom: 12 }}
                        >
                          <Select
                            placeholder={'请选择时间范围'}
                            options={[
                              {
                                label: '今日',
                                value: 1,
                              },
                              {
                                label: '近7天',
                                value: 7,
                              },
                              {
                                label: '近30天',
                                value: 30,
                              },
                            ]}
                          />
                        </Form.Item>
                      )}
                    </>
                  ) : (
                    <Select
                      filterOption={(input, option) => {
                        const t = orgInfo.templateList.find(
                          (i) => i.template_id === currentTemplate,
                        );
                        const itemKey = form.getFieldValue('conditions')[name]?.key;
                        let isUserType = false;
                        if (itemKey === 'statusOwner' || itemKey === 'creator') isUserType = true;
                        if (itemKey.slice(0, 4) === 'prop') {
                          const propType = (t?.prop || [])[Number(itemKey.slice(4))]?.type;
                          if (propType === 'user') isUserType = true;
                        }

                        if (isUserType) {
                          const map = conditionValues[name].reduce((res, cur: any) => {
                            res[cur.label] = cur;

                            return res;
                          }, {} as any);
                          return filterUsersByPinyin(input, option, map);
                        }

                        return (
                          (option?.children as unknown as string)
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        );
                      }}
                      showSearch={true}
                      placeholder={'请选择条件'}
                      mode={
                        form.getFieldValue('conditions')[name]?.key === 'historyStatus' ||
                        form.getFieldValue('conditions')[name]?.key === 'status'
                          ? 'multiple'
                          : undefined
                      }
                    >
                      {conditionValues[name]?.map((option: any, i: number) => {
                        return (
                          <Option value={option.value} key={i}>
                            {option.label}
                          </Option>
                        );
                      })}
                    </Select>
                  )}
                </Form.Item>
                <div
                  className="cancel-btn"
                  onClick={() => {
                    remove(name);
                    handleConditionKeyChange(null, name);
                  }}
                >
                  <i className="iconfont iconButton_cancel" />
                </div>
              </div>
            ))}
          </>
        )}
      </Form.List>
    </Form.Item>
  );
};
