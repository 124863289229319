import { Checkbox } from 'antd';
import type { FC } from 'react';

import { useTempPropConfig } from '../hooks';
import { CanExportGraphicCodeParams } from './CanExportGraphicCodeParams';

export const QrBarcodeParams: FC = () => {
  const { propParams, setPropParams } = useTempPropConfig();
  return (
    <>
      {(['enum', 'tag'].includes(propParams.type ?? '') && propParams.type === 'tag') ||
      ['text'].includes(propParams.type ?? '') ||
      propParams.formulaFormat === 1 ? (
        <div className={'prop-item no-padding'} key={'allowScanCode'}>
          <Checkbox
            checked={propParams.allowScanCode}
            onChange={(e) =>
              setPropParams({
                ...propParams,
                allowScanCode: e.target.checked,
              })
            }
          >
            允许扫码填值
          </Checkbox>
        </div>
      ) : null}
      {(['enum', 'tag'].includes(propParams.type ?? '') && !propParams.multiple) ||
      ['text'].includes(propParams.type ?? '') ||
      propParams.formulaFormat === 1 ? (
        <div>
          <div className={'prop-item no-padding'} key={'canExportGraphicCode'}>
            <Checkbox
              checked={propParams.canExportGraphicCode}
              onChange={(e) =>
                setPropParams({
                  ...propParams,
                  canExportGraphicCode: e.target.checked,
                })
              }
            >
              可导出图形码
            </Checkbox>
          </div>
          {propParams?.canExportGraphicCode ? <CanExportGraphicCodeParams /> : null}
        </div>
      ) : null}
    </>
  );
};
