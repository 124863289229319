import { mergeDeepRight } from 'ramda';

import { STYLE_CONFIG_DARK } from '../helper/contants';
import { STYLE_CONFIG } from '../helper/contants';
import Log from '../helper/log';
import BaseModule from './_baseModule';

interface rectType {
  x: number;
  y: number;
  width: number;
  height: number;
}

type pointType = [number, number];

export default class Draw extends BaseModule {
  public dpr() {
    return window.devicePixelRatio || 1;
  }

  public npx(px: number) {
    return px * this.dpr();
  }

  public npxLine(px: number) {
    const n = this.npx(px);
    return n > 0 ? n - 0.5 : 0.5;
  }

  // @ts-ignore
  private el: HTMLCanvasElement;
  // @ts-ignore
  public ctx: CanvasRenderingContext2D;
  public style: typeof STYLE_CONFIG & typeof STYLE_CONFIG_DARK = STYLE_CONFIG;

  public get drawWidth() {
    return this.el.width;
  }
  public get drawHeight() {
    return this.el.height;
  }
  public get canvasWidth() {
    return Number(this.el.style.width.split('px')[0]);
  }
  public get canvasHeight() {
    return Number(this.el.style.height.split('px')[0]);
  }

  public genStyle() {
    if (!this.config.isDark) {
      this.style = { ...STYLE_CONFIG };
    } else {
      // 合并
      this.style = mergeDeepRight(STYLE_CONFIG, {
        ...STYLE_CONFIG_DARK,
        backgroundColor: this.config.backgroundColor,
      });
    }
  }

  public prepare() {
    const { Render } = this.moduleInstances;

    this.el = Render.doms.canvas as HTMLCanvasElement;
    this.ctx = this.el.getContext('2d') as CanvasRenderingContext2D;
  }

  public resize(width: number, height: number) {
    const floorWidth = Math.floor(width);
    const floorHeight = Math.floor(height);

    this.el.style.width = `${floorWidth}px`;
    this.el.style.height = `${floorHeight}px`;

    this.el.width = this.npx(floorWidth);
    this.el.height = this.npx(floorHeight);

    return this;
  }

  public clear() {
    const { width, height } = this.el;
    this.ctx.clearRect(0, 0, width, height);
    return this;
  }

  public attr(options: any) {
    Object.assign(this.ctx, options);
    return this;
  }

  public save() {
    this.ctx.save();
    this.ctx.beginPath();
    return this;
  }

  public restore() {
    this.ctx.restore();
    return this;
  }

  public iconFont(_unicode: string): string {
    let unicode = _unicode;
    if (unicode.indexOf('&amp;') !== -1) {
      unicode = unicode.replace('&amp;', '&');
    }
    if (unicode.indexOf('&amp') !== -1) {
      unicode = unicode.replace('&amp', '&');
    }
    const icon = eval('("' + `${unicode}`.replace('&#x', '\\u').replace(';', '') + '")');
    return icon;
  }

  public fillRect(x: number, y: number, w: number, h: number, fillColor: string = '#fff') {
    this.ctx.fillStyle = fillColor;
    this.ctx.fillRect(this.npx(x) - 0.5, this.npx(y) - 0.5, this.npx(w), this.npx(h));
    return this;
  }

  public fillText(text: string, x: number, y: number, maxWidth?: number) {
    this.ctx.fillText(text, this.npx(x), this.npx(y), maxWidth ? this.npx(maxWidth) : undefined);
    return this;
  }

  public line(points: pointType[], strokeColor: string = '#f6f6f6', lineWidth: number = 1) {
    if (points.length < 2) {
      Log.warn('line: length < 2');
      return;
    }

    this.attr({ lineWidth: this.npx(lineWidth), strokeStyle: strokeColor });

    this.ctx.beginPath();
    const [sx, sy] = points[0];
    this.ctx.moveTo(this.npxLine(sx), this.npxLine(sy));
    for (let i = 1; i < points.length; i += 1) {
      const [x1, y1] = points[i];
      this.ctx.lineTo(this.npxLine(x1), this.npxLine(y1));
    }
    this.ctx.stroke();
    return this;
  }

  /**
   * 圆角边框矩形
   */
  public strokeRoundRect(rect: rectType, radius: number, lineWidth: number, strokeColor: string) {
    if (2 * radius > rect.width || 2 * radius > rect.height) {
      return false;
    }

    this.ctx.save();
    this.ctx.translate(this.npx(rect.x), this.npx(rect.y));
    //绘制圆角矩形的各个边
    drawRoundRectPath(this.ctx, this.npx(rect.width), this.npx(rect.height), this.npx(radius));
    this.ctx.lineWidth = this.npx(lineWidth || 2);
    this.ctx.strokeStyle = strokeColor || '#000';
    this.ctx.stroke();
    this.ctx.restore();
  }

  // 画进度条的填充区域
  public fillProgressArea(
    x: number,
    y: number,
    width: number,
    height: number,
    radius: number,
    fillColor: string,
  ) {
    this.ctx.save();
    this.ctx.fillStyle = fillColor;

    this.ctx.beginPath();
    this.ctx.moveTo(this.npx(x), this.npx(y + radius));
    this.ctx.lineTo(this.npx(x), this.npx(y + height - radius));
    this.ctx.quadraticCurveTo(
      this.npx(x),
      this.npx(y + height),
      this.npx(x + radius),
      this.npx(y + height),
    );
    this.ctx.lineTo(this.npx(x + width), this.npx(y + height));
    this.ctx.lineTo(this.npx(x + width), this.npx(y));
    this.ctx.lineTo(this.npx(x + radius), this.npx(y));
    this.ctx.quadraticCurveTo(this.npx(x), this.npx(y), this.npx(x), this.npx(y + radius));

    this.ctx.fill();
    this.ctx.restore();
  }

  /**
   * 圆角填充矩形
   */
  public fillRoundRect(rect: rectType, radius: number | number[], fillColor: string = '#000') {
    //圆的直径必然要小于矩形的宽高
    const minRadius = radius instanceof Array ? Math.min(...radius) : radius;
    if (2 * minRadius > rect.width || 2 * minRadius > rect.height) {
      Log.error('fillRoundRect', '2 * radius > rect.width || 2 * radius > rect.height');
      return false;
    }
    this.ctx.save();
    this.ctx.translate(this.npx(rect.x), this.npx(rect.y));
    //绘制圆角矩形的各个边
    drawRoundRectPath(
      this.ctx,
      this.npx(rect.width),
      this.npx(rect.height),
      radius instanceof Array ? radius.map((x) => this.npx(x)) : this.npx(radius),
    );
    this.ctx.fillStyle = fillColor; //若是给定了值就用给定的值否则给予默认值
    this.ctx.fill();
    this.ctx.restore();
  }

  public measureTextWidth(str: string) {
    return this.ctx.measureText(str).width / this.dpr();
  }

  /**
   * 文字超出省略
   */
  public fittingString(str: string, maxWidth: number) {
    const maxWidthNpx = this.npx(maxWidth);
    let strWidth = this.ctx.measureText(str).width;
    const ellipsis = '...';
    const ellipsisWidth = this.ctx.measureText(ellipsis).width;
    if (strWidth <= maxWidthNpx || maxWidthNpx <= ellipsisWidth) {
      return str;
    } else {
      let len = str.length;
      while (strWidth >= maxWidthNpx - ellipsisWidth && len-- > 0) {
        str = str.slice(0, len);
        strWidth = this.ctx.measureText(str).width;
      }
      return str + ellipsis;
    }
  }

  /**
   * 三角形
   */
  public fillTriangle(rect: rectType, fillColor: string, direction: 'top' | 'bottom') {
    const width = this.npx(rect.width);
    const height = this.npx(rect.height);
    const x = this.npx(rect.x);
    const y = this.npx(rect.y);

    this.ctx.beginPath();

    if (direction === 'top') {
      this.ctx.moveTo(x + width / 2, y);
      this.ctx.lineTo(x, y + height);
      this.ctx.lineTo(x + width, y + height);
    } else {
      this.ctx.moveTo(x, y);
      this.ctx.lineTo(x + width, y);
      this.ctx.lineTo(x + width / 2, y + height);
    }
    this.ctx.fillStyle = fillColor;
    this.ctx.fill(); //闭合
  }
}

function drawRoundRectPath(
  ctx: CanvasRenderingContext2D,
  width: number,
  height: number,
  radius: number | number[],
) {
  const radiusTopLeft = radius instanceof Array ? radius[0] : radius;
  const radiusTopRight = radius instanceof Array ? radius[1] : radius;
  const radiusBottomRight = radius instanceof Array ? radius[2] : radius;
  const radiusBottomLeft = radius instanceof Array ? radius[3] : radius;

  ctx.beginPath();
  //从右下角顺时针绘制，弧度从0到1/2PI
  ctx.arc(width - radiusBottomRight, height - radiusBottomRight, radiusBottomRight, 0, Math.PI / 2);

  //矩形下边线
  ctx.lineTo(radiusBottomLeft, height);

  //左下角圆弧，弧度从1/2PI到PI
  ctx.arc(radiusBottomLeft, height - radiusBottomLeft, radiusBottomLeft, Math.PI / 2, Math.PI);

  //矩形左边线
  ctx.lineTo(0, radiusTopLeft);

  //左上角圆弧，弧度从PI到3/2PI
  ctx.arc(radiusTopLeft, radiusTopLeft, radiusTopLeft, Math.PI, (Math.PI * 3) / 2);

  //上边线
  ctx.lineTo(width - radiusTopRight, 0);

  //右上角圆弧
  ctx.arc(width - radiusTopRight, radiusTopRight, radiusTopRight, (Math.PI * 3) / 2, Math.PI * 2);

  //右边线
  ctx.lineTo(width, height - radiusBottomRight);
  ctx.closePath();
}
