import ProForm from '@ant-design/pro-form';
import type { CurrentUser } from '@linkpi/core';
import { CREATOR, DEPARTMENT, PASS_ARRAY, PROP } from '@linkpi/core';
import { useMemoizedFn } from 'ahooks';
import type { FC } from 'react';

import { StatusUserPropConfigSelect } from '@/pages/space/components/TaskStatus/components/StatusUserPropConfigSelect';
import { toArray } from '@/utils/utils';

import { useTempPropConfig } from '../hooks';

const DEFAULT_CONFIG = {
  range: undefined,
  defaultValue: undefined,
};

export const DepartmentType: FC = () => {
  const { savePropParams, propParams } = useTempPropConfig();

  const onChange = useMemoizedFn((changedValues: Partial<CurrentUser.DepartmentConfig>, values) => {
    savePropParams(values, 'departmentConfig');
    console.log(values);

    if ('defaultValue' in changedValues) {
      let needFormula = false;
      const valueFormulas = toArray(changedValues.defaultValue || [])
        ?.map((v) => {
          if (v.startsWith('group_')) return v.replace('group_', '');
          if (v.startsWith('dynamicDepartment_')) {
            const [, propStr, levelStr] = v.split('_');
            const level = Number(levelStr);
            const propFormula = (() => {
              if (propStr === 'creator') return CREATOR();
              if (propStr.startsWith('prop')) {
                const propIndex = Number(propStr.replace('prop', ''));
                return PROP(propIndex);
              }
            })();
            if (propFormula) {
              needFormula = true;
              return DEPARTMENT(propFormula, level);
            }
          }
        })
        .filter(Boolean);

      if (needFormula) {
        const defaultFormula = PASS_ARRAY(
          ...(valueFormulas || []),
        ) as CurrentUser.propDefaultFormula;
        console.log(defaultFormula);

        savePropParams(defaultFormula, 'defaultFormula');
      } else {
        savePropParams(null, 'defaultFormula');
      }
    }
  });

  const initialValues = { ...DEFAULT_CONFIG, ...propParams?.departmentConfig };

  return (
    <ProForm
      layout="vertical"
      submitter={false}
      onValuesChange={onChange}
      initialValues={initialValues}
    >
      <ProForm.Item label="选值范围" name={['range']}>
        <StatusUserPropConfigSelect
          maxTagCount={'responsive'}
          mode="multiple"
          allowTypes={['department', 'dynamicDepartment']}
          placeholder="全部"
        />
      </ProForm.Item>
      <ProForm.Item label="默认值" name={['defaultValue']}>
        <StatusUserPropConfigSelect
          departmentCheckStrictly
          maxTagCount={'responsive'}
          mode={propParams.multiple ? 'multiple' : undefined}
          allowTypes={['department', 'dynamicDepartment']}
          placeholder="请设置默认值"
        />
      </ProForm.Item>
    </ProForm>
  );
};
