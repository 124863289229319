import {
  getNodesDiffStatusProp,
  setNodeAction,
  transformBatchStatusDataToApiData,
} from '@linkpi/core';
import { useSelector } from '@umijs/max';
import { Divider, message, Modal, Select } from 'antd';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { forwardRef, memo, useImperativeHandle, useReducer } from 'react';

import EditProps from '@/components/EditProps';
import { useOrgUserMap } from '@/hook';
import request from '@/utils/request';

const { Option } = Select;

const initState = {
  visible: false,
  nodeIds: [],
  nodes: [],

  template: { task_status: [] },
  statusConfig: { prop: [] },

  status: 0,
  statusProp: [],
  statusPropDiff: [],

  confirmLoading: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'loading':
      return { ...state, confirmLoading: action.payload };
    case 'init':
      const { nodeIds, template, node, initStatus } = action.payload;
      const nodes = nodeIds.map((x) => node.value.nodeManager.findChildren(x));
      // 初始状态 第一个节点的状态和状态属性
      const initNode = nodes[0];
      const status = initStatus !== null ? initStatus : Number(initNode.tempInfo.status);
      return {
        ...state,
        template,
        nodeIds,
        nodes,
        status,
        statusProp: initNode.tempInfo.statusProp,
        statusConfig: template.task_status[status] || { prop: [] },
        visible: true,
      };
    case 'changeStatus':
      const { index, userId, userMap } = action.payload;
      const { defaultStatusProp, defaultStatusPropDiff } = getNodesDiffStatusProp(
        state.nodes.map((x) => ({
          tempProp: x.tempInfo.prop,
          // TODO: @yq 节点的状态属性值
          tempStatusProp: [],
          creator: x.prop._sys_creator,
          createTime: x.prop._sys_createTime,
        })),
        index,
        state.template,
        userId,
        userMap,
      );

      return {
        ...state,
        status: index,
        statusProp: defaultStatusProp,
        statusConfig: state.template.task_status[index] || { prop: [] },
        statusPropDiff: defaultStatusPropDiff,
      };
    case 'fieldChange':
      const { propIndex, value } = action.payload;
      const newProps = cloneDeep(state.statusProp);
      newProps[propIndex] = value;

      return { ...state, statusProp: newProps };
    case 'cancel':
      return { ...state, visible: false };
    default:
      return state;
  }
};

const NodeStatusModal = (props, ref) => {
  useImperativeHandle(ref, () => ({
    open: (nodeIds, template, node, initStatus = null) => {
      dispatch({
        type: 'init',
        payload: { nodeIds, template, node, initStatus },
      });
    },
  }));

  const currentUser = useSelector((rootState) => rootState.user.currentUser);
  const { userMap, orgId, onHandle } = props;
  const orgUserMap = useOrgUserMap();

  const [state, dispatch] = useReducer(reducer, initState);

  const onStatusChange = (index) => {
    if (index === state.status) {
      return;
    }
    dispatch({
      type: 'changeStatus',
      payload: { index, userId: currentUser.userid, userMap: orgUserMap },
    });
  };

  const handleUnusualTime = (index, value) => {
    if (index !== 2 && index !== 3) return;

    const _i = index === 2 ? 3 : 2;

    const v = state.statusProp[_i];

    const err = index === 2 ? v <= value : value <= v;

    if (err) {
      // 存在倒挂
      dispatch({
        type: 'fieldChange',
        payload: {
          propIndex: _i,
          value: index === 2 ? moment(value).add(1, 'hours') : moment(value).subtract(1, 'hours'),
        },
      });
    }
  };

  const onFieldChange = (propIndex, value) => {
    handleUnusualTime(propIndex, value);

    dispatch({ type: 'fieldChange', payload: { propIndex, value } });
  };

  // 检查必填属性是否填写
  const checkRequiredProps = () => {
    const statusProps = state.statusConfig.prop; // 所有状态属性
    const requiredIndexs = [];
    statusProps.forEach((p, i) => {
      if (p.required && p.display && p.type !== 'quote') requiredIndexs.push(i);
    }); // 需要必填的属性
    let flag = false;
    requiredIndexs.forEach((index) => {
      if (!state.statusProp[index]) flag = true;
    });

    return flag;
  };

  const handleSave = async () => {
    if (checkRequiredProps()) {
      message.warning('请填写必填属性');
      return;
    }
    const req = transformBatchStatusDataToApiData(
      orgId,
      state.nodeIds,
      state.template.template_id,
      state.status,
      state.statusProp,
      state.statusPropDiff,
    );
    const fails = [];
    dispatch({ type: 'loading', payload: true });
    await Promise.all(
      state.nodeIds.map(async (id) => {
        const [err, res] = await setNodeAction(request, { ...req, node: [id] });

        if (err || res.status !== 'ok') fails.push(id);
      }),
    );
    dispatch({ type: 'loading', payload: false });

    if (fails.length !== 0) {
      return message.error('操作失败');
    }
    message.success('操作成功');
    onHandle();
    onCancel();
  };

  const onCancel = () => {
    dispatch({ type: 'cancel' });
  };
  return (
    <Modal
      open={state.visible}
      destroyOnClose={true}
      title="批量转换状态"
      onOk={handleSave}
      onCancel={onCancel}
      confirmLoading={state.confirmLoading}
    >
      <Select value={state.status} style={{ width: '100%' }} onChange={onStatusChange}>
        {state.template.task_status
          .map((x, i) =>
            x.delete
              ? ''
              : {
                  sort: 'sort' in x ? x.sort : i,
                  dom: (
                    <Option value={i} key={i}>
                      {x.name}
                    </Option>
                  ),
                },
          )
          .sort((a, b) => a.sort - b.sort)
          .map((x) => x.dom)}
      </Select>
      <Divider />
      {state.statusConfig.prop.map((x, i) =>
        x.display ? (
          <EditProps
            key={i}
            config={x}
            value={state.statusProp[i]}
            userMap={userMap}
            onChange={(e) => onFieldChange(i, e)}
            diffValue={state.statusPropDiff[i]}
          />
        ) : (
          ''
        ),
      )}
    </Modal>
  );
};

export default memo(forwardRef(NodeStatusModal));
