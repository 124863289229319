import type { NiceModalHandler } from '@ebay/nice-modal-react';
import { antdModalV5, create, show, useModal } from '@ebay/nice-modal-react';
import { Modal, Progress } from 'antd';
import type { FC } from 'react';
import { useEffect, useState } from 'react';

import type { IProgressIterator } from './utils';

type ShowConfigType = {
  progressIterator: IProgressIterator;
  autoclose?: boolean;
  onFinish?: (modal: NiceModalHandler<Record<string, unknown>>, result: unknown) => void;
  loadingText?: string;
  finishText?: string;
};

/**
 *
 * 配合后端的进度逻辑
 *
 */
const _ProgressModal: FC<ShowConfigType> = ({
  loadingText = '加载中',
  finishText = '加载完成',
  progressIterator,
  autoclose = true,
  onFinish,
}) => {
  const modal = useModal();
  const [progress, setProgress] = useState(0);

  const isOver = progress >= 1;

  useEffect(() => {
    async function run() {
      while (true) {
        const { value, done } = await progressIterator.next();
        if (typeof value.progress === 'number') {
          setProgress(value.progress);
        }
        if (done) {
          if (value.status === 'error') {
            if (autoclose) modal.hide();
            modal.reject();
            return;
          }
          if (autoclose) modal.hide();
          modal.resolve();
          if (done && value.progress === 1) {
            onFinish?.(modal, value.result);
          }
          return;
        }
      }
    }
    if (modal.visible) {
      run();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal.visible]);

  return (
    <Modal {...antdModalV5(modal)} footer={null} maskClosable={false}>
      <div style={{ padding: '24px 0 12px 0' }}>
        <div>{isOver ? finishText : loadingText}</div>
        <Progress percent={Math.ceil(progress * 100)} />
      </div>
    </Modal>
  );
};

const ProgressModal = create(_ProgressModal);

export const showProgressModal = (props: ShowConfigType) => {
  return show(ProgressModal, props);
};

export * from './utils';
