export default class Log {
  public static info(message: any, ...more: any) {
    console.log('%c[PI-grid info]', 'color:blue', message, more);
  }
  public static warn(message: any, ...more: any) {
    console.log('%c[PI-grid warn]', 'color:saddlebrown', message, more);
  }
  public static error(message: any, ...more: any) {
    console.log('%c[PI-grid error]', 'color:red', message, more);
  }
}
