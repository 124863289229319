import { useOrgDepartmentNodesMap } from '@/hook/useOrgStructure';
import RowDisplay from '@/pages/home/components/View/ListTable/toolbar/RowDisplay';
import { genGridAttrs } from '@/pages/pi/grid/react/useGridAttrs';

export default (props: any) => {
  const departmentMap = useOrgDepartmentNodesMap();

  const { templateList, userMap, curViewData } = props;
  const { view_info } = curViewData;

  const tempId =
    view_info?.conditionV2?.find((i: any) => i.key === 'templateId')?.input[0] ||
    view_info?.condition?.find((i: any) => i.key === 'templateId')?.value;

  const tempMap = templateList.reduce((res: any, cur: any) => {
    res[cur.template_id] = cur;
    return res;
  }, {});

  const template = tempMap[tempId];

  const getDefaultTShowFieldsIndex = () => {
    const tShowFieldsIndex = [];

    // 主题类型是否存在状态
    const templateStatus = (template?.task_status || []).filter((s: any) => !s.delete);
    // false 显示
    tShowFieldsIndex.push({ key: 'order', disable: false });
    tShowFieldsIndex.push({ key: 'status', disable: templateStatus.length < 1 });
    tShowFieldsIndex.push({ key: 'index', disable: true });
    tShowFieldsIndex.push({ key: 'treeLevelId', disable: true });
    tShowFieldsIndex.push({ key: 'title', disable: true });
    tShowFieldsIndex.push({ key: 'sysTag', disable: true });
    tShowFieldsIndex.push({ key: 'mainbody', disable: true });

    // 属性排序
    const templateProps = (template?.prop || [])
      .map((p: any, index: number) => ({ ...p, index }))
      .filter((p: any) => p.type);
    templateProps.sort((p1: any, p2: any) => {
      if ('sort' in p1) return p1.sort - p2.sort;
      return 1;
    });
    templateProps.forEach((p: any) => {
      tShowFieldsIndex.push({ disable: !!p.hide, key: `prop_${p.index}` });
    });

    tShowFieldsIndex.push({ key: 'statusProp_common_0', disable: true });
    tShowFieldsIndex.push({ key: 'statusProp_common_1', disable: true });
    tShowFieldsIndex.push({ key: 'statusProp_common_2', disable: true });
    tShowFieldsIndex.push({ key: 'statusProp_common_3', disable: true });
    tShowFieldsIndex.push({ key: 'statusProp_common_4', disable: true });
    tShowFieldsIndex.push({ key: 'repeatTask', disable: true });

    return tShowFieldsIndex;
  };

  const tShowFieldsIndex = view_info.tShowFieldsIndex || getDefaultTShowFieldsIndex();

  // 显示列
  const tableProps = genGridAttrs(template, tShowFieldsIndex, userMap, tempMap, departmentMap);

  return (
    <RowDisplay
      tableProps={tableProps}
      template={template}
      curViewData={curViewData}
      forceUpdateView
      notLimitTemplate={false}
      needRefetch
    />
  );
};
