import type { PiGanttModules } from '../core';
import type { nodeRowData } from '../modules/nodeRowManager';
import type { positionType } from './_baseComponent';
import BaseComponent from './_baseComponent';

export default class AddRow extends BaseComponent {
  public data: nodeRowData<'addRow'>;

  constructor(
    position: positionType,
    moduleInstances: PiGanttModules,
    data: nodeRowData<'addRow'>,
  ) {
    super(position, moduleInstances);
    this.data = data;
  }

  public onClick() {
    console.log('onclick');
    this.moduleInstances.NodeRowManager.config.onAdd(this.data);
  }

  public render() {
    const { Draw, Render } = this.moduleInstances;
    Draw.save();

    const x = this.position.x + 30;

    Draw.attr({ fillStyle: Draw.style.addButton.color });
    Draw.ctx.textBaseline = 'middle';
    Draw.ctx.textAlign = 'left';
    Draw.ctx.font = `400 ${Draw.npx(14)}px  iconfont`;

    // add icon
    Draw.fillText(Draw.iconFont('&#xe6dc;'), x, this.position.y + this.position.height / 2);

    if (this.isHover) {
      Render.setCursor('pointer');
      Draw.ctx.font = `400 ${Draw.npx(14)}px   sans-serif`;
      Draw.fillText('新增一行', x + 20, this.position.y + this.position.height / 2);
    }

    // bottom line
    Draw.line(
      [
        [0, this.position.y + this.position.height],
        [Render.leftPanelStyle.width, this.position.y + this.position.height],
      ],
      Draw.style.borderColor,
    );

    Draw.restore();
  }
}
