import type { IFrameConfig } from '@/components/PageModelEditor';
// import type { GetterPiNode } from '@linkpi/core/web';

interface FrameProps {
  data: IFrameConfig;
  // node: GetterPiNode;
}

export const Frame = (props: FrameProps) => {
  const { data } = props;

  return (
    <div className="w-full h-full">
      {data.link ? (
        <iframe width="100%" height="100%" src={data.link} />
      ) : (
        <div className="w-full h-full flex items-center justify-center text-[#BFC6D2] text-[14px]">
          未设置视图
        </div>
      )}
    </div>
  );
};
