import type { ApiResponse } from '@linkpi/core';
import { getQuoteOriginProp, tempValueDisplay } from '@linkpi/core';
import { useThrottleEffect } from 'ahooks';
import { isArray } from 'lodash';
import { useState } from 'react';

import type { UseStatType } from '@/pages/home/components/View/ListTable/useStat';
import { getQuoteOrMatchingInfo, isQuotePropCanCount } from '@/utils/utils';

export type GridAttrSorter = null | {
  asc: (a: PiNode, b: PiNode) => number;
  desc: (a: PiNode, b: PiNode) => number;
};

export type GridAttrItem = {
  key: string; // key
  type: GridAttrTypeKeys; // 类型
  name: string; // 列名
  disable: boolean; // 是否显示
  width: number;
  config:
    | ApiResponse.CurrentUser.TemplateProp
    | ApiResponse.CurrentUser.statusProp
    | null
    | ((
        node: PiNode,
      ) => null | ApiResponse.CurrentUser.TemplateProp | ApiResponse.CurrentUser.statusProp);
  getData: (node: PiNode) => any;
  propIndex?: number;
  sorter?: GridAttrSorter;
  stat?: UseStatType[] | null;
  propIsCanCount?: boolean;
  isMatchingStat?: boolean;
  ellipsis?: boolean | ((node: PiNode) => boolean); // 省略字段 显示 -
};

const useGridAttrs = (
  templateList: ApiResponse.CurrentUser.TemplateInfo[],
  curViewData: ApiResponse.ViewList.ViewListItem<2>,
  localTableConfig: ApiResponse.ViewList.ViewTableShowFieldItem[],
  userMap: Record<string, ApiResponse.OrgUser.OrgUserItem>,
  departmentMap: any,
) => {
  const [gridAttrs, setGridAttrs] = useState<GridAttrItem[]>([]);

  useThrottleEffect(
    () => {
      const { view_info } = curViewData;
      const { condition = [] } = view_info || {};

      const tempMap = templateList.reduce((a, b) => {
        a[b.template_id] = b;
        return a;
      }, {} as any);

      const viewTempId = condition.find((c: any) => c.key === 'templateId')?.value;
      const template = (templateList || []).find((t) => t.template_id === viewTempId);
      const newAttrs = genGridAttrs(template!, localTableConfig, userMap, tempMap, departmentMap);

      if (view_info.showActions) {
        newAttrs.push({
          key: 'actions',
          disable: false,
          type: 'actions',
          name: '操作',
          config: null,
          width: 200,
          sorter: null,
          stat: ['none'],
          getData: (node: PiNode) => node,
        });
      }

      setGridAttrs(newAttrs);
    },
    [templateList, curViewData, localTableConfig],
    { wait: 0 },
  );

  return [gridAttrs];
};

export const genGridAttrs = (
  template: ApiResponse.CurrentUser.TemplateInfo,
  showFields: ApiResponse.ViewList.ViewTableShowFieldItem[],
  userMap: Record<string, ApiResponse.OrgUser.OrgUserItem>,
  tempMap: Record<string, ApiResponse.CurrentUser.TemplateInfo>,
  departmentMap: any,
): GridAttrItem[] => {
  if (!template) {
    const fieldsMap = (showFields || []).reduce((a, b, index: number) => {
      a[b.key] = {
        ...b,
        localIndex: index,
      };
      return a;
    }, {} as any);
    const res: any = [
      {
        key: 'status',
        type: 'status',
        name: '状态',
        disable: fieldsMap.status?.disable,
        config: null,
        width: getGridAttrWidth('status'),
        sorter: {
          asc: (a, b) => 1,
          desc: (a, b) => 1,
        },
        getData: (node: PiNode) => node.tempInfo.status,
        stat: ['count'],
      },
      {
        key: 'sender',
        type: 'user',
        name: '发送人',
        width: getGridAttrWidth('user'),
        disable: fieldsMap.sender?.disable,
        config: null,
        getData: (node: any) => ({ data: node.prop._sys_task_status?.sender }),
        sorter: getSorterByPropType('user', (node) => node.prop._sys_task_status?.sender),
        stat: ['count'],
      },
      {
        key: 'title',
        type: 'text',
        name: '标题',
        disable: fieldsMap.title?.disable,
        config: null,
        width: getGridAttrWidth('text'),
        sorter: {
          asc: (a, b) => String(a.title).localeCompare(String(b.title), 'zh-CN'),
          desc: (a, b) => String(b.title).localeCompare(String(a.title), 'zh-CN'),
        },
        getData: (node: PiNode) => node.title,
        stat: ['count'],
      },

      {
        key: 'statusProp_common_0',
        type: 'user',
        name: '负责人',
        width: getGridAttrWidth('user'),
        disable: fieldsMap.statusProp_common_0?.disable,
        config: (node) => getCommonStatusPropConfig(tempMap[node.tempInfo.id], node, 0),
        getData: (node) => getCommonStatusPropValue(tempMap[node.tempInfo.id], node, 0),
        ellipsis: (node) => !getCommonStatusPropConfig(tempMap[node.tempInfo.id], node, 0),
        sorter: getSorterByPropType('user', (node) => node.tempInfo.statusProp[0]),
        stat: ['count'],
      },
      {
        key: 'sendTime',
        name: '发送时间',
        type: 'datetime',
        disable: fieldsMap.sendTime?.disable,
        width: getGridAttrWidth('datetime'),
        config: null,
        getData: (node: any) => ({ data: node.prop._sys_task_status?.sendTime }),
        ellipsis: null,
        sorter: getSorterByPropType('datetime', (node) => ({
          data: node.prop._sys_task_status?.sendTime,
        })),
        stat: ['count'],
      },
    ];
    return res.sort((a: any, b: any) => {
      const indexOfA = fieldsMap[a.key]?.localIndex;
      const indexOfB = fieldsMap[b.key]?.localIndex;

      // 如果a不存在 a排在b后面 return > 0
      if (!~indexOfA) {
        return 1;
      }

      // // 如果b不存在 b排到a后面 return < 0
      if (!~indexOfB) {
        return -1;
      }

      // // 如果都存在，升序
      return indexOfA - indexOfB;
    });
  }

  const localPropsConfigKeyMap = (showFields || []).reduce(
    (a, b, index: number) => {
      a[b.key] = {
        ...b,
        localIndex: index,
      };
      return a;
    },
    {} as Record<string, ApiResponse.ViewList.ViewTableShowFieldItem & { localIndex: number }>,
  );

  const setDefaultDisable = (key: string, defaultValue: boolean) => {
    return localPropsConfigKeyMap[key] ? localPropsConfigKeyMap[key].disable : defaultValue;
  };

  /**
   * 标题，状态，前两个模板属性，四个共有状态属性，创建时间
   */
  const titleAlias = template.title_setting?.titleAlias || '标题';

  const res: GridAttrItem[] = [
    {
      type: 'order',
      key: 'order',
      name: '序号',
      config: null,
      disable: setDefaultDisable('order', true),
      width: getGridAttrWidth('order'),
      sorter: null,
      stat: ['count'],
      getData: (node: PiNode) => 1,
    },
    {
      type: 'index',
      key: 'index',
      name: '编号',
      config: null,
      disable: setDefaultDisable('index', true),
      width: getGridAttrWidth('index'),
      sorter: {
        asc: (a, b) => a.prop._sys_node_seq - b.prop._sys_node_seq,
        desc: (a, b) => b.prop._sys_node_seq - a.prop._sys_node_seq,
      },
      stat: ['count'],
      getData: (node: PiNode) => node.prop._sys_node_seq,
    },
    {
      key: 'status',
      type: 'status',
      name: '状态',
      disable: setDefaultDisable('status', true),
      config: null,
      width: getGridAttrWidth('status'),
      sorter: {
        asc: (a, b) => {
          const task_status = template.task_status || [];
          const aSort = task_status[a.tempInfo.status].sort;
          const bSort = task_status[b.tempInfo.status].sort;
          return aSort - bSort;
        },
        desc: (a, b) => {
          const task_status = template.task_status || [];
          const aSort = task_status[a.tempInfo.status].sort;
          const bSort = task_status[b.tempInfo.status].sort;
          return bSort - aSort;
        },
      },
      getData: (node: PiNode) => node.tempInfo.status,
      stat: ['count'],
    },
    {
      type: 'treeLevelId',
      key: 'treeLevelId',
      name: '树层ID',
      config: null,
      disable: setDefaultDisable('treeLevelId', true),
      width: getGridAttrWidth('treeLevelId'),
      sorter: null,
      stat: ['count'],
      getData: (node: PiNode) => node,
    },
    {
      key: 'title',
      type: 'text',
      name: titleAlias,
      disable: setDefaultDisable('title', true),
      config: null,
      width: getGridAttrWidth('text'),
      sorter: {
        asc: (a, b) => String(a.title).localeCompare(String(b.title), 'zh-CN'),
        desc: (a, b) => String(b.title).localeCompare(String(a.title), 'zh-CN'),
      },
      getData: (node: PiNode) => node.title,
      stat: ['count'],
    },
    {
      key: 'sysTag',
      type: 'sysTag',
      name: '系统标签',
      disable: setDefaultDisable('sysTag', true),
      config: null,
      width: getGridAttrWidth('sysTag'),
      sorter: {
        asc: (a, b) => String(getSysTagValue(a)).localeCompare(String(getSysTagValue(b)), 'zh-CN'),
        desc: (a, b) => String(getSysTagValue(b)).localeCompare(String(getSysTagValue(a)), 'zh-CN'),
      },
      getData: (node: PiNode) => getSysTagValue(node),
      stat: ['count'],
    },

    {
      key: 'mainbody',
      type: 'mainbody',
      name: '正文',
      disable: setDefaultDisable('mainbody', true),
      config: null,
      width: getGridAttrWidth('mainbody'),
      sorter: null,
      getData: (node: PiNode) => node,
      stat: ['count'],
    },
  ];

  // 模板属性
  const props: (GridAttrItem & { config: ApiResponse.CurrentUser.TemplateProp })[] = [];

  // 属性排序
  const templateProps = template.prop
    .map((p: any, index: number) => ({ ...p, index }))
    .filter((p: any) => p.name);
  templateProps.sort((p1, p2) => {
    if ('sort' in p1) return p1.sort - p2.sort;
    return 1;
  });

  templateProps.map((x, i) => {
    if (!x.type) {
      return;
    }

    i = x.index;
    const item: GridAttrItem & { config: ApiResponse.CurrentUser.TemplateProp } = {
      key: `prop_${i}`,
      type: x.type,
      name: x.name,
      config: x,
      propIndex: i,
      disable: setDefaultDisable(`prop_${i}`, true),
      width: getGridAttrWidth(x.type),
      stat: getStatByPropType(x.type),
      isMatchingStat: x.conditionMatching,
      // 下面处理差异
      getData: (node: PiNode) => {
        const v = getQuoteOrMatchingInfo(x);
        if (v === 3) {
          // 说明这个属性是条件匹配且是引用计数
          return {
            data: node.prop._sys_temp[1][i] || 0,
            propIndex: i,
            type: 'conditionMatch_count',
          };
        }
        if (v === 1 || v === 2) {
          // 引用标题
          return {
            data: node.prop._sys_temp[1][i],
            propIndex: i,
            type: 'conditionMatch_title',
          };
        }
        return { data: node.prop._sys_temp[1][i], propConfig: x };
      },
      sorter: null,
    };

    const getAttachmentValue = (node: PiNode) => {
      const tempInfo = node.prop._sys_temp[1];
      const fileNames = tempInfo[i] || [];
      const _sys_attach = (node.prop || {})._sys_attach || {};
      return (Array.isArray(_sys_attach[i]) ? _sys_attach[i] : []).map(
        (src: string, j: number) => ({
          src,
          fileName: fileNames[j] || '未知文件名',
          compressSrc: `${src?.split('?')[0]}?x-oss-process=image/resize,h_32`,
        }),
      );
    };

    if (x.type === 'quote' && !x.table_id) {
      const originProp: any = getQuoteOriginProp(x, tempMap);
      if (!originProp) return;

      // 引用的公式属性是数字公式
      const numberFormula = originProp.type === 'formula' && originProp.formulaFormat === 0;
      // 引用类型是否是数字或货币
      const quoteTempPropIsCanCount =
        originProp &&
        originProp !== '-3' &&
        (originProp.type === 'number' || originProp.type === 'currency');
      const propIsCanCount = x.matchingType > 0;
      let quoteStatMenus: any[] = ['count', 'uniqueCount', 'notEmptyUniqueCount'];
      let numPrecision = 0;
      if (quoteTempPropIsCanCount || propIsCanCount || numberFormula) {
        quoteStatMenus = [
          'count',
          'uniqueCount',
          'notEmptyUniqueCount',
          'sum',
          'ave',
          'max',
          'min',
        ];
        // @ts-ignore
        numPrecision = originProp?.number?.precision || originProp?.extend?.precision || 0;
      }

      // 引用选值
      if (originProp && originProp.type === 'enum')
        quoteStatMenus = ['count', 'uniqueCount', 'notEmptyUniqueCount', 'enumUniqueCount'];
      item.propIsCanCount = propIsCanCount;
      item.stat = quoteStatMenus;
      item.getData = (node: PiNode) => {
        const res: any = { data: '' };
        // 是不是数字相关的属性
        const isNumberProp = isQuotePropCanCount(x, originProp);
        const quoteData = tempValueDisplay({
          propConfig: x,
          propValue: node.prop._sys_temp[1][i],
          tempMap,
          userMap,
          sysCascade: node.prop._sys_cascade,
          propIndex: i,
          departmentMap,
        });
        // 引用
        const v = getQuoteOrMatchingInfo(x);
        if (v === 3) {
          return {
            data: quoteData || 0,
            propIndex: i,
            type: 'conditionMatch_count',
            isNumberProp,
            originData: node.prop._sys_temp[1][i],
          };
        }
        if (v === 1 || v === 2) {
          return {
            data: quoteData || '',
            propIndex: i,
            type: 'conditionMatch_title',
            isNumberProp,
            originData: node.prop._sys_temp[1][i],
          };
        }
        // 引用附件
        if (
          originProp !== -3 &&
          originProp &&
          originProp.type === 'attachment' &&
          originProp.matchingType === 0
        ) {
          res.data = getAttachmentValue(node);
          res.type = 'quote-attachment';
          return res;
        }

        // 普通引用
        return { data: quoteData, isNumberProp, originData: node.prop._sys_temp[1][i] };
      };
    }

    if (x.type === 'attachment') {
      item.getData = (node: PiNode) => getAttachmentValue(node);
    }

    if (x.type === 'cascade') {
      item.getData = (node: PiNode) => {
        const _sys_cascade = (node.prop || {})._sys_cascade || {};
        const data = _sys_cascade[i] || [];
        return data
          .map((i: any) => {
            if (Array.isArray(i)) {
              if (item.config.hideRoutes) return i[i.length - 1];
              return i.filter((j: any) => j).join('/');
            }
            return i;
          })
          .filter((j: any) => j)
          .join('、');
      };
    }

    if (x.type === 'positioning') {
      item.getData = (node: PiNode) => {
        const data = node.prop._sys_temp[1][i];
        return Array.isArray(data) ? data[0] : data;
      };
    }

    if (x.type === 'quote' && x.table_id) {
      item.getData = (node: PiNode) => node.prop._sys_temp[1][i];
    }

    item.sorter = getSorterByPropType(x.type, item.getData);

    props.push(item);
  });

  props.sort((a, b) => {
    return a.config.sort - b.config.sort;
  });
  res.push(...props);

  if (template.task_status.length) {
    // 4个共有状态属性
    res.push({
      key: 'statusProp_common_0',
      type: 'user',
      name: '负责人',
      width: getGridAttrWidth('user'),
      disable: setDefaultDisable('statusProp_common_0', true),
      config: (node) => getCommonStatusPropConfig(template, node, 0),
      getData: (node) => getCommonStatusPropValue(template, node, 0),
      ellipsis: (node) => !getCommonStatusPropConfig(template, node, 0),
      sorter: getSorterByPropType('user', (node) => node.tempInfo.statusProp[0]),
      stat: ['count'],
    });
    res.push({
      key: 'statusProp_common_1',
      type: 'user',
      name: '参与者',
      disable: setDefaultDisable('statusProp_common_1', true),
      width: getGridAttrWidth('user'),
      config: (node) => getCommonStatusPropConfig(template, node, 1),
      getData: (node) => getCommonStatusPropValue(template, node, 1),
      ellipsis: (node) => !getCommonStatusPropConfig(template, node, 1),
      sorter: getSorterByPropType('user', (node) => node.tempInfo.statusProp[1]),
      stat: ['count'],
    });
    res.push({
      key: 'statusProp_common_2',
      name: '开始时间',
      type: 'datetime',
      disable: setDefaultDisable('statusProp_common_2', true),
      width: getGridAttrWidth('datetime'),
      config: (node) => getCommonStatusPropConfig(template, node, 2),
      getData: (node) => getCommonStatusPropValue(template, node, 2),
      ellipsis: (node) => !getCommonStatusPropConfig(template, node, 2),
      sorter: getSorterByPropType('datetime', (node) => ({ data: node.tempInfo.statusProp[2] })),
      stat: ['count'],
    });
    res.push({
      key: 'statusProp_common_3',
      name: '结束时间',
      type: 'datetime',
      disable: setDefaultDisable('statusProp_common_3', true),
      width: getGridAttrWidth('datetime'),
      config: (node) => getCommonStatusPropConfig(template, node, 3),
      getData: (node) => getCommonStatusPropValue(template, node, 3),
      ellipsis: (node) => !getCommonStatusPropConfig(template, node, 3),
      sorter: getSorterByPropType('datetime', (node) => ({ data: node.tempInfo.statusProp[3] })),
      stat: ['count'],
    });
    res.push({
      key: 'statusProp_common_4',
      name: '备注',
      type: 'text',
      disable: setDefaultDisable('statusProp_common_4', true),
      width: getGridAttrWidth('text'),
      config: (node) => getCommonStatusPropConfig(template, node, 4),
      getData: (node) => getCommonStatusPropValue(template, node, 4),
      ellipsis: (node) => !getCommonStatusPropConfig(template, node, 4),
      sorter: getSorterByPropType('text', (node) => node.tempInfo.statusProp[4]),
      stat: ['count'],
    });
  }
  res.push({
    key: 'creator',
    type: 'user',
    name: '创建人',
    width: getGridAttrWidth('user'),
    disable: setDefaultDisable('creator', false),
    config: null,
    getData: (node: any) => ({ data: node.creator }),
    sorter: getSorterByPropType('user', (node: any) => node.creator),
    stat: ['count'],
  });
  res.push({
    key: 'createTime',
    type: 'datetime',
    name: '创建时间',
    disable: setDefaultDisable('createTime', true),
    config: null,
    width: getGridAttrWidth('datetime'),
    getData: (node) => node.createTime,
    sorter: getSorterByPropType('datetime', (node) => ({ data: node.createTime })),
    stat: ['count'],
  });
  // 重复
  res.push({
    key: 'repeatTask',
    type: 'repeatTask',
    name: '重复',
    disable: setDefaultDisable('repeatTask', true),
    config: null,
    width: getGridAttrWidth('repeatTask'),
    getData: (node) => {
      const status = template.task_status?.[node.tempInfo.status];
      const config: any = status ? status.prop[2] : {};
      if (!(config && config.display && config.type !== 'quote')) return '-';

      const repeatData = node.prop._sys_periodic;
      return !repeatData ? '未设置重复' : displayRepeatTask(repeatData.repeat[0]);
    },
    stat: ['count'],
  });

  return res.sort((a, b) => {
    const indexOfA = localPropsConfigKeyMap[a.key]?.localIndex;
    const indexOfB = localPropsConfigKeyMap[b.key]?.localIndex;

    // 如果a不存在 a排在b后面 return > 0
    if (!~indexOfA) {
      return 1;
    }

    // // 如果b不存在 b排到a后面 return < 0
    if (!~indexOfB) {
      return -1;
    }

    // // 如果都存在，升序
    return indexOfA - indexOfB;
  });
};

export type GridAttrTypeKeys =
  | ApiResponse.CurrentUser.propType
  | 'nodeSeq'
  | 'status'
  | 'text'
  | 'str'
  | 'datetime'
  | 'date'
  | 'sysTag'
  | 'enum'
  | 'tag'
  | 'user'
  | 'currency'
  | 'number'
  | 'repeatTask'
  | 'formula'
  | 'attachment'
  | 'quote'
  | 'index'
  | 'mainbody'
  | 'order'
  | 'treeLevelId'
  | 'actions';

export type GridAttrWidthMap = {
  [key in GridAttrTypeKeys]: number;
};

export const widthMap: GridAttrWidthMap = {
  nodeSeq: 100,
  str: 220,
  text: 220,

  status: 150,
  date: 150,
  datetime: 150,

  sysTag: 140,

  enum: 180,
  tag: 250,

  user: 140,

  currency: 240,
  number: 160,

  repeatTask: 150,
  formula: 240,

  attachment: 200,

  quote: 240,
  index: 120,

  mainbody: 200,

  treeLevelId: 120,

  order: 100,

  address: 160,

  // TODO
  cascade: 200,
  positioning: 120,
  auto_inc: 120,
};

export const getGridAttrWidth = (key: GridAttrTypeKeys) => {
  return widthMap[key] || 100;
};

const getSysTagValue = (node: PiNode) => {
  return node.prop._sys_tag && typeof node.prop._sys_tag === 'object'
    ? Object.keys(node.prop._sys_tag)
    : [];
};

const displayRepeatTask = (repeatInfo: any) => {
  let label = '';
  if (repeatInfo.countWeek && repeatInfo.countWeek.length) {
    label = label.concat(repeatInfo.month ? '每年' : '每月', '重复');
  } else {
    label = label.concat(
      '每',
      repeatInfo.month ? '年' : repeatInfo.day ? '月' : repeatInfo.week ? '周' : '天',
      '重复',
    );
  }
  return label;
};

export const getSorterByPropType = (
  type: ApiResponse.CurrentUser.TemplateProp['type'],
  getData: (node: PiNode) => any,
): GridAttrSorter => {
  if (type === 'str' || type === 'text') {
    return {
      asc: (a, b) => String(getData(a).data).localeCompare(String(getData(b).data), 'zh-CN'),
      desc: (a, b) => String(getData(b).data).localeCompare(String(getData(a).data), 'zh-CN'),
    };
  }
  if (type === 'tag' || type === 'enum') {
    return {
      asc: (a, b) => String(getData(a).data).localeCompare(String(getData(b).data), 'zh-CN'),
      desc: (a, b) => String(getData(b).data).localeCompare(String(getData(a).data), 'zh-CN'),
    };
  }
  if (type === 'date' || type === 'datetime') {
    return {
      asc: (a, b) => {
        const { data: data_a, propConfig } = getData(a);
        const { data: data_b } = getData(b);
        if (data_a === undefined || data_a === null) return -1;
        if (data_b === undefined || data_b === null) return 1;
        // if (propConfig && propConfig.dateFormat) {
        //   data_a = moment(data_a, propConfig.dateFormat).valueOf();
        //   data_b = moment(data_b, propConfig.dateFormat).valueOf();
        // }
        return data_a - data_b;
      },
      desc: (a, b) => {
        const { data: data_a, propConfig } = getData(a);
        const { data: data_b } = getData(b);
        if (data_a === undefined || data_a === null) return 1;
        if (data_b === undefined || data_b === null) return -1;
        // if (propConfig && propConfig.dateFormat) {
        //   data_a = moment(data_a, propConfig.dateFormat).valueOf();
        //   data_b = moment(data_b, propConfig.dateFormat).valueOf();
        // }
        return data_b - data_a;
      },
    };
  }
  if (type === 'user') {
    return {
      asc: (a, b) => String(getData(a)).charCodeAt(0) - String(getData(b)).charCodeAt(0),
      desc: (a, b) => String(getData(b)).charCodeAt(0) - String(getData(a)).charCodeAt(0),
    };
  }
  if (type === 'currency' || type === 'number' || type === 'formula' || type === 'auto_inc') {
    return {
      asc: (a, b) => {
        const { data: value_a, propConfig } = getData(a);
        const value_b = getData(b).data;
        // 去除 NaN 的情况
        if (value_a === undefined || value_a === null) return -1;
        if (value_b === undefined || value_b === null) return 1;

        // 如果公式属性是文本公式按照文本的逻辑排序
        if (type === 'formula' && propConfig && isNaN(Number(value_a))) {
          return String(value_a).localeCompare(String(value_b), 'zh-CN');
        }
        return Number(value_a) - Number(value_b);
      },
      desc: (a, b) => {
        const { data: value_a, propConfig } = getData(a);
        const value_b = getData(b).data;
        if (value_a === undefined || value_a === null) return 1;
        if (value_b === undefined || value_b === null) return -1;
        // 如果公式属性是文本公式按照文本的逻辑排序
        if (type === 'formula' && propConfig && isNaN(Number(value_a))) {
          return String(value_b).localeCompare(String(value_a), 'zh-CN');
        }
        return Number(value_b) - Number(value_a);
      },
    };
  }
  if (type === 'cascade') {
    return {
      asc: (a, b) => {
        const value_a = getData(a);
        const value_b = getData(b);
        // 去除 NaN 的情况
        if (value_a === undefined || value_a === null) return -1;
        if (value_b === undefined || value_b === null) return 1;
        return String(value_a).localeCompare(String(value_b), 'zh-CN');
      },
      desc: (a, b) => {
        const value_a = getData(a);
        const value_b = getData(b);
        if (value_a === undefined || value_a === null) return 1;
        if (value_b === undefined || value_b === null) return -1;
        return String(value_b).localeCompare(String(value_a), 'zh-CN');
      },
    };
  }

  if (type === 'quote') {
    return {
      asc: (a, b) => {
        const { isNumberProp, originData: originData_a, data: data_a } = getData(a);
        const { originData: originData_b, data: data_b } = getData(b);
        if (originData_a === undefined || originData_a === null) return -1;
        if (originData_b === undefined || originData_b === null) return 1;

        if (isNumberProp) {
          return Number(originData_a) - Number(originData_b);
        }

        // 不是数字相关的属性
        return String(data_a).localeCompare(String(data_b), 'zh-CN');
      },
      desc: (a, b) => {
        const { isNumberProp, originData: originData_a, data: data_a } = getData(a);
        const { originData: originData_b, data: data_b } = getData(b);
        if (originData_a === undefined || originData_a === null) return 1;
        if (originData_b === undefined || originData_b === null) return -1;
        if (isNumberProp) {
          return Number(originData_b) - Number(originData_a);
        }

        // 不是数字相关的属性
        return String(data_b).localeCompare(String(data_a), 'zh-CN');
      },
    };
  }

  return null;
};

export const getStatByPropType = (type: ApiResponse.CurrentUser.TemplateProp['type']): any[] => {
  // enum
  if (type === 'enum') {
    return ['count', 'uniqueCount', 'notEmptyUniqueCount', 'enumUniqueCount'];
  }

  // number
  if (type === 'number' || type === 'currency' || type === 'formula') {
    return ['count', 'uniqueCount', 'notEmptyUniqueCount', 'sum', 'ave', 'max', 'min'];
  }

  // TODO
  // if(type === 'quote'){
  //   return ['count', 'sum', 'ave', 'max', 'min'];
  // }

  return ['count', 'uniqueCount', 'notEmptyUniqueCount'];
};

const getUserScope = (
  config: any,
  userMap: Record<string, ApiResponse.OrgUser.OrgUserItem>,
): Record<string, ApiResponse.OrgUser.OrgUserItem> => {
  // 可选范围 -1全部 -2管理员 其他表示用户的分组id
  const scope = config.extend && config.extend.length ? config.extend : ['-1'];
  if (~scope.indexOf('-1')) {
    return userMap;
  } else {
    return Object.keys(userMap)
      .filter((userId: string) => {
        const user = userMap[userId];
        let valid = false;
        if (user.group_ids) {
          if (isArray(user.group_ids)) {
            user.group_ids.map((id: any) => {
              if (~scope.indexOf(id)) {
                valid = true;
              }
            });
          } else if (typeof user.group_ids === 'string') {
            if (~scope.indexOf(user.group_ids)) {
              valid = true;
            }
          }
        }
        return valid;
      })
      .reduce(
        (a, b) => {
          a[b] = userMap[b];
          return a;
        },
        {} as Record<string, ApiResponse.OrgUser.OrgUserItem>,
      );
  }
};

const getCommonStatusPropConfig = (
  template: ApiResponse.CurrentUser.TemplateInfo,
  node: PiNode,
  index: number,
) => {
  const status = template.task_status?.[node.tempInfo.status];
  const config = status ? status.prop[index] : null;
  return config && config.display && config.type !== 'quote' ? config : null;
};
const getCommonStatusPropValue = (
  template: ApiResponse.CurrentUser.TemplateInfo,
  node: PiNode,
  index: number,
) => {
  const status = template.task_status?.[node.tempInfo.status];
  const config = status ? status.prop[index] : null;
  return config && config.display && config.type !== 'quote'
    ? { data: node.tempInfo.statusProp[index] }
    : {};
};

export default useGridAttrs;
