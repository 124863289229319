import type { PiGridModules } from '../core';
import type { RowType } from '../modules/dataManager';
import type { PositionType } from './_baseComponent';
import GridBaseComponent from './_baseComponent';

class AddRow extends GridBaseComponent {
  public data: RowType<'addRow'>;
  public type: string = 'addRow';
  public STYLE = {
    hoverBg: '#f0f0f0',
  };
  constructor(position: PositionType, moduleInstances: PiGridModules, data: any, fixed: boolean) {
    super(position, moduleInstances, fixed);

    this.data = data;
  }

  public click() {
    const { DataManager } = this.moduleInstances;
    // 没有数据新建
    if (this.data.groupKey === 'noData') {
      DataManager.config.onAdd(null, 'noData', null);
      return;
    }
    const groupItem = this.moduleInstances.DataManager.config.groupData[this.data.groupKey];
    const prevNode = groupItem.list[groupItem.list.length - 1];
    DataManager.config.onAdd(groupItem, this.data.groupKey, prevNode.id);
  }
  public hover() {}
  public render() {
    const { Draw, Render } = this.moduleInstances;

    const styleMode = Draw.config.styleMode;
    const cellBg = styleMode === 'darkness' ? 'rgba(0,0,0,0)' : '#fff';
    const lineColor = styleMode === 'darkness' ? 'rgba(255,255,255,0.05)' : '#E4E4E4';

    const bg = this.isHover
      ? styleMode === 'darkness'
        ? 'rgba(190,206,255,0.05)'
        : this.STYLE.hoverBg
      : cellBg;
    Draw.fillRect(this.position.x, this.position.y, this.position.width, this.position.height, bg);
    Draw.drawComponentBorder(this.position, ['top', 'bottom'], lineColor);

    Draw.attr({
      fillStyle: styleMode === 'darkness' ? '#D6E0FF' : '#97a1b5',
      textBaseline: 'middle',
      textAlign: 'start',
    });

    const startY = this.position.y + this.position.height / 2;

    // icon
    Draw.ctx.font = `${Draw.npx(14)}px iconfont`;
    const iconX = Render.collapseRowTextGap;
    Draw.fillText(Draw.iconFont('&#xe728;'), iconX, startY);

    // text
    Draw.ctx.font = `${Draw.npx(14)}px sans-serif`;
    const textX = iconX + 20;
    Draw.fillText('新建', textX, startY);

    if (this.isHover) {
      Render.setCursor('pointer');
    }
  }
}

export default AddRow;
