import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons';
import { PlusOutlined } from '@ant-design/icons';
import { assertExists } from '@linkpi/utils';
import type { UploadFile, UploadProps } from 'antd';
import { Input, InputNumber, message, Select, Spin, Upload } from 'antd';
import React, { cloneElement, useRef, useState } from 'react';

import { TemplateTreeNodeSelect } from '@/components/LinkPiForm';
import { useOrgInfo } from '@/hook';
import { uploadFileToOss } from '@/utils/utils';

import Styles from './index.less';

export const Categories = [
  {
    label: '个人群组',
    value: 'personal',
  },
  {
    label: '企业通用',
    value: 'corp',
  },
  {
    label: '行业定制',
    value: 'customized',
  },
];

export enum RMode {
  New = 'new',
  Cover = 'cover',
}

export const ReleaseMode = [
  {
    label: '发布新模板',
    value: RMode.New,
  },
  {
    label: '覆盖已有模板',
    value: RMode.Cover,
  },
];

interface CustomFormItem<T = any> {
  value?: T;
  onChange?: (value: T) => void;
}

export const NodeSelect = (
  prop: CustomFormItem & {
    disabledChildren?: string[];
    orgId: string;
  },
) => {
  const { value, onChange, disabledChildren, orgId } = prop;

  return (
    <TemplateTreeNodeSelect
      noStyle
      orgId={orgId}
      rootTitle="主题树"
      disabledChildren={disabledChildren?.filter((child) => child !== value)}
      fieldProps={{
        value,
        placeholder: '请选择相关主题',
        style: { minWidth: 660 },
        onChange,
      }}
    />
  );
};

export const PriceSelect = (prop: CustomFormItem) => {
  const { value, onChange } = prop;
  const { sale, person, year } = value || {};

  const handleSaleChange = (e: number | null) => {
    onChange?.({
      ...value,
      sale: e,
    });
  };

  const handlePersonChange = (e: number | null) => {
    onChange?.({
      ...value,
      person: e,
    });
  };

  const handleYearChange = (e: number | null) => {
    onChange?.({
      ...value,
      year: e,
    });
  };

  return (
    <div className={Styles['form-item-price']}>
      <div className={Styles['form-item-price-item']}>
        <InputNumber
          min={0}
          value={sale}
          className={Styles['price-item']}
          placeholder="请输入（单位：元）"
          onChange={handleSaleChange}
        />
      </div>
      <span className={Styles['form-item-divider']}>/</span>
      <div className={Styles['form-item-price-item']}>
        <InputNumber
          min={1}
          value={person}
          className={Styles['price-item']}
          placeholder="请输入（单位：人）"
          formatter={(v) => (v ? `${v}人` : '')}
          parser={(v) => v!.replace('人', '') as unknown as number}
          onChange={handlePersonChange}
        />
      </div>
      <span className={Styles['form-item-divider']}>/</span>
      <div className={Styles['form-item-price-item']}>
        <InputNumber
          min={1}
          value={year}
          className={Styles['price-item']}
          placeholder="请输入（单位：年）"
          formatter={(v) => (v ? `${v}年` : '')}
          parser={(v) => v!.replace('年', '') as unknown as number}
          onChange={handleYearChange}
        />
      </div>
    </div>
  );
};

export const CustomUpload = (
  prop: CustomFormItem<UploadFile<string>[]> & {
    orgId?: string;
    maxSize?: number;
    type?: string;
    maxCount?: number;
    children?: React.ReactNode;
    className?: string;
    showUploadList?: boolean;
    onSuccess?: (fileUrl: string) => void;
    listType?: UploadProps['listType'];
  },
) => {
  const {
    value,
    onChange,
    orgId,
    maxSize,
    type = 'image',
    maxCount = 1,
    children,
    className,
    showUploadList = true,
    onSuccess,
    listType = 'picture-card',
  } = prop;

  const [loading, setLoading] = useState(false);

  const [currentOrg] = useOrgInfo(orgId);

  assertExists(currentOrg);

  const uploadCustomRequest: UploadProps['customRequest'] = async (requestOption) => {
    const fileUrl = await uploadFileToOss(requestOption.file, currentOrg.orgId);
    setLoading(false);
    if (fileUrl) {
      requestOption.onSuccess?.(fileUrl);
      onSuccess?.(fileUrl);
    } else {
      message.error('上传失败请重新上传!');
      requestOption.onError?.(new Error('上传失败'));
    }
  };

  const accept = type === 'image' ? 'image/png, image/jpeg' : undefined;

  const beforeUpload: UploadProps['beforeUpload'] = (file, fileList) => {
    if ((value?.length ?? 0) + fileList.length > maxCount) {
      message.error({
        content: `最多支持上传${maxCount}${type === 'image' ? '张' : '份'}!`,
        key: 'exceed',
      });
      return Upload.LIST_IGNORE;
    }

    if (type === 'image') {
      const isImage = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isImage) {
        console.log('file -> ', file);
        message.error({
          content: '仅允许上传 JPG/PNG 格式文件!',
          key: 'error type',
        });
        return Upload.LIST_IGNORE;
      }
    }

    if (maxSize && file.size > maxSize) {
      message.error({
        content: `文件不超过 ${maxSize / 1024 / 1024}M !`,
        key: 'oversize',
      });
      return Upload.LIST_IGNORE;
    }

    setLoading(true);
    return file;
  };

  const handleChange: UploadProps['onChange'] = (info) => {
    console.log('onChange ----', info);
    onChange?.(info.fileList);
    // const fileList = info.fileList.filter((f) => f.status === 'done').map((f) => f.response);
    // if (info.file.status === 'done') {
    //   onChange?.(info.fileList);
    // } else if (info.file.status === 'error') {
    //   message.error(info.file.response);
    // }
  };

  const content = () => {
    if (children) {
      return <Spin spinning={loading}>{children}</Spin>;
    }

    if (value?.length === maxCount) {
      return null;
    }

    return (
      <div className={Styles['custom-upload-add']}>
        <PlusOutlined />
      </div>
    );
  };

  return (
    <Upload<string>
      // showUploadList={false}
      accept={accept}
      showUploadList={
        showUploadList
          ? {
              showDownloadIcon: false,
              showRemoveIcon: true,
              removeIcon: <DeleteOutlined />,
              showPreviewIcon: false,
            }
          : false
      }
      customRequest={uploadCustomRequest}
      beforeUpload={beforeUpload}
      onChange={handleChange}
      maxCount={maxCount}
      multiple={maxCount > 1}
      fileList={value}
      listType={listType}
      className={className ?? Styles['custom-upload-wrapper']}
      itemRender={(originNode) => originNode}
    >
      {content()}
    </Upload>
  );
};
