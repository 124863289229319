import { antdModalV5, create as ModalCreate, show, useModal } from '@ebay/nice-modal-react';
import type { FC } from 'react';

import type { NodeImportProp } from './Import';
import { NodeImport } from './Import';

const _NodeImportModal: FC<NodeImportProp> = (props) => {
  const modal = useModal();
  console.log('🛠', props, antdModalV5(modal));

  return <NodeImport {...antdModalV5(modal)} zIndex={1001} {...props} />;
};

const NodeImportModal = ModalCreate(_NodeImportModal);

export const showNodeImportModal = (props: NodeImportProp) => {
  return show(NodeImportModal, props);
};
