import { Space } from 'antd';
import type { FC } from 'react';
import { match } from 'ts-pattern';

export const ShareButton: FC<{
  type?: 'icon' | 'default';
  shareView: () => void;
}> = ({ shareView, type = 'default' }) => {
  return match(type)
    .with('icon', () => (
      <div className={'link-pi-btn btn-lg'} onClick={shareView}>
        <i className="iconfont iconfenxiang" />
      </div>
    ))
    .with('default', () => (
      <Space onClick={shareView} align="center">
        <i className="iconfont iconfenxiang" />
        分享
      </Space>
    ))
    .exhaustive();
};
