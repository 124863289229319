const LPChatModel = {
  namespace: 'lpchat',
  state: {
    initializedApp: false,
    currentChatId: undefined,
    homePageList: [],
    allPrivateChats: new Map(),
    allGroupChats: new Map(),

    relatedCurrentChat: true,
    shareData: null,
  },

  reducers: {
    setInit(state, { payload }) {
      return { ...state, initializedApp: payload.initializedApp };
    },

    setHomePageList(state, { payload }) {
      return { ...state, homePageList: payload.homePageList };
    },

    setAllGroupChats(state, { payload }) {
      return { ...state, allGroupChats: payload.allGroupChats };
    },

    addGroupMessageAndInfo(state, { payload }) {},

    addGroupInfo(state, { payload }) {
      const { member, members, groupId, groupInfo } = payload;
      const membersArg = members || [member];
      const { allGroupChats } = state;
      const allGroupChatsCopy = new Map(allGroupChats);
      const goalGroupChat = allGroupChatsCopy.get(groupId);
      const originGroupInfo = (goalGroupChat && goalGroupChat.groupInfo) || {};
      const originMembers = (originGroupInfo && originGroupInfo.members) || [];
      const newGroupMembers =
        originMembers.filter((m) => m.user_id === (member && member.user_id)).length === 0
          ? [...originMembers, ...membersArg]
          : originMembers;
      const newGroupInfo = groupInfo || { ...originGroupInfo, members: newGroupMembers };
      if (goalGroupChat) {
        allGroupChatsCopy.get(groupId).groupInfo = newGroupInfo;
      } else {
        allGroupChatsCopy.set(groupId, { groupInfo: newGroupInfo });
      }
      return { ...state, allGroupChats: allGroupChatsCopy };
    },

    addGroupMessages(state, { payload }) {
      const { messages, message, groupId, inLazyLoading = false } = payload;
      const { allGroupChats } = state;
      const allGroupChatsCopy = new Map(allGroupChats);
      const goalGroupChat = allGroupChatsCopy.get(groupId);
      const originMessages = (goalGroupChat && goalGroupChat.messages) || [];
      const newMessages = messages || [message];
      if (goalGroupChat) {
        const finalMessages = inLazyLoading
          ? [...newMessages, ...originMessages]
          : [...originMessages, ...newMessages];
        allGroupChatsCopy.get(groupId).messages = finalMessages;
      } else {
        allGroupChatsCopy.set(groupId, { messages: newMessages });
      }
      return { ...state, allGroupChats: allGroupChatsCopy };
    },
  },
};

export default LPChatModel;
