import type { CurrentUser } from '@linkpi/core';
import type { ViewList } from '@linkpi/core';
import type { GetterPiNode } from '@linkpi/core/web';
import type { FC } from 'react';

import type { ViewConditionFormPropsType } from '@/components/ConditionFilter';
import ConditionFilter from '@/components/ConditionFilter';
import {
  useNodeTreeData,
  useOrgInfo,
  useOrgTempMap,
  useOrgUserGroup,
  useOrgUserMap,
  useProvData,
} from '@/hook';
import { getCustomTableChildTemplateIds } from '@/utils/utils';

export const ChildrenConditionFilter: FC<
  {
    curViewData: ViewList.ViewListItem<2>;
    template: CurrentUser.TemplateInfo;
    editable: boolean;
    getterPiNode: GetterPiNode;
    onChildConditionChange: (tempId: string, conditionV2: ViewList.ViewconditionV2) => void;
  } & Pick<ViewConditionFormPropsType, 'allowTempIds' | 'experimental_disableOpList'>
> = ({ curViewData, template, editable, getterPiNode, allowTempIds, onChildConditionChange }) => {
  const tempMap = useOrgTempMap();
  const userMap = useOrgUserMap();
  const orgInfo = useOrgInfo()[0]!;
  const groups = useOrgUserGroup();
  const { provData } = useProvData();
  const treeData = useNodeTreeData({ enableRoot: true });

  if (!curViewData.view_info?.customTableId) return null;

  const childTempIds = getCustomTableChildTemplateIds(
    curViewData.view_info?.customTableId,
    template,
  );

  // 配置在 curViewData.view_info.childCondition
  // Record<tempId, conditionV2>
  if (!childTempIds.length) return null;
  return (
    <div>
      <p style={{ lineHeight: '40px', marginBottom: 8, color: '#b6b8b9', fontSize: 14 }}>
        子主题筛选
      </p>
      {childTempIds.map((tempId, index) => {
        const itemCondition = curViewData.view_info.childCondition?.[tempId] || [
          {
            key: 'templateId',
            op: 'intersect',
            input: [tempId],
          },
        ];
        const temp = tempMap[tempId];
        // 支持人员和选值
        const allowProp: string[] = [];
        temp?.prop.map((x, i) => {
          if (!x || x.hide) return;
          if (x.type === 'user' || x.type === 'enum' || x.type === 'tag') {
            allowProp.push('prop_' + i);
          }
        });
        const key = [tempId, index].join('@');

        return (
          <ConditionFilter
            key={key}
            defaultConditions={itemCondition}
            userMap={userMap}
            tempMap={tempMap}
            editable={editable}
            disableTemplate={true}
            orgInfo={orgInfo}
            getTreeData={() => treeData}
            userGroups={groups}
            onChange={(e) => {
              onChildConditionChange(tempId, e.conditionsV2);
            }}
            provData={provData}
            allowTempIds={allowTempIds}
            nodeSelectExtraCurrent={getterPiNode.value.id}
            displayTemplate
            allowKey={[...allowProp, 'status', 'statusProp_0', 'statusProp_1']}
          />
        );
      })}
    </div>
  );
};
