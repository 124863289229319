import type { PiGanttModules } from '../core';

export interface positionType {
  x: number;
  y: number;
  width: number;
  height: number;
}

export default class BaseComponent {
  protected moduleInstances: PiGanttModules;
  public position = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  };
  public isHover: boolean = false;

  /**
   * 实时获取isHover(用于动态设置的Position)
   */
  public getCurrentIsHover() {
    const { x, y } = window.__PI__GANTT.mousePosition;
    return this.isPositionInside(x, y);
  }

  public isPositionInside(x: number, y: number) {
    return (
      x > this.position.x &&
      x < this.position.x + this.position.width &&
      y > this.position.y &&
      y < this.position.y + this.position.height
    );
  }

  public onHover(x: number, y: number) {}
  public onClick(x: number, y: number) {}

  constructor(position: positionType, moduleInstances: PiGanttModules) {
    this.moduleInstances = moduleInstances;
    this.position = position;

    // 初始化后把实例丢到全局的[]，让程序可以通过[]的添加顺序，处理Hover,click
    window.__PI__GANTT.components.push(this);

    this.isHover = this.getCurrentIsHover();
  }

  public setPosition(key: keyof positionType, value: number) {
    this.position[key] = value;
  }
}
