import type { ApiResponse } from '@linkpi/core';
import type { HorizontalAxisObject, IntervalType, VerticalGranularityObject } from '@linkpi/core';
import { cloneDeep } from 'lodash';
import { isNil, omit, uniq } from 'ramda';

import type { HorizontalAxisOption } from './getFormOptions';
import { DATE_INTERVAL_OPTION } from './getXAxisForm';

type NormalHorizontalAxisType = HorizontalAxisObject | { prop: number; type?: string; parm?: any };
export type ChartFormDataType = Omit<ApiResponse.ViewList.ViewChartsInfo, 'horizontalAxis'> & {
  horizontalAxis: NormalHorizontalAxisType[];
  verticalAxisDateInterval?: IntervalType;
  verticalTermsGroup?: { groupName: string; value: unknown[] }[];
  viewName?: string;
  template?: string;
  viewPublic?: boolean;
};

const handleConvertApiDataHorizontalAxisItem = (
  item: number | HorizontalAxisObject,
  itemIndex: number,
  apiData: ApiResponse.ViewList.ViewChartsInfo,
): NormalHorizontalAxisType => {
  if (typeof item === 'number') {
    // dateRange horizInterval
    const res: any = { prop: item };
    if (item === -2) {
      res.parm = apiData.dateRange;
    }
    if (apiData.horizInterval !== undefined) {
      if (Array.isArray(apiData.horizInterval) && apiData.horizInterval[itemIndex]) {
        res.parm = apiData.horizInterval[itemIndex];
      } else {
        res.parm = apiData.horizInterval;
      }
    }

    return res;
  } else {
    return item;
  }
};

export const apiDataConvertToFormData = (apiData: ApiResponse.ViewList.ViewChartsInfo) => {
  const converDatas: Partial<ChartFormDataType> = {};

  // 1. x轴 horizontalAxis
  // number | number[] | HorizontalAxisObject | HorizontalAxisObject[]
  // to HorizontalAxisObject[]
  const horizontalAxis = cloneDeep(apiData.horizontalAxis);
  let horizontalAxisList;
  // number[] | HorizontalAxisObject[]
  if (Array.isArray(horizontalAxis)) {
    horizontalAxisList = horizontalAxis.map((x, index) =>
      handleConvertApiDataHorizontalAxisItem(x, index, apiData),
    );
  }
  // number | HorizontalAxisObject
  else {
    horizontalAxisList = [handleConvertApiDataHorizontalAxisItem(horizontalAxis, 0, apiData)];
  }

  // 2. x轴 prop === -2时，horizInterval 放到 parm
  converDatas.horizontalAxis = horizontalAxisList.map(
    (x: NormalHorizontalAxisType, index: number) => {
      if (x.prop === -2) {
        x.parm = apiData.dateRange;
      }
      return x;
    },
  );

  // 3. 如果视图不是多维视图
  // verticalAxisDateInterval 图例项为日期时的时间间隔 除了多维视图外
  // 图例类别为时间时，要把 verticalAxisDateInterval 拼到 verticalGranularity
  // VerticalGranularityObject
  if (apiData.type !== 4 && !isNil(apiData.verticalParm)) {
    if (DATE_INTERVAL_OPTION.map((i) => i.value).includes(apiData.verticalParm as string)) {
      converDatas.verticalAxisDateInterval = apiData.verticalParm as IntervalType;
    }
  }

  if (apiData.verticalTermsGroupMap) {
    converDatas.verticalTermsGroup = Object.keys(apiData.verticalTermsGroupMap).map((key) => ({
      value: apiData.verticalTermsGroupMap[key],
      groupName: key,
    }));
  }
  if (apiData.type !== 4) {
    if (!(!isNil(apiData.verticalGranularity) && !Array.isArray(apiData.verticalGranularity))) {
      converDatas.yAxisStatisticsType = {
        statisticsType: 'node',
      } as ChartFormDataType['yAxisStatisticsType'];
    }
  }

  return { ...apiData, ...converDatas } as ChartFormDataType;
};

const handleConvertFormDataHorizontalAxis = (
  item: NormalHorizontalAxisType,
  horizontalOptions: any,
) => {
  if (item.prop === -2) {
    // 折线图的真实时间
    return {
      horizontalAxis: -2,
      dateRange: item.parm,
    };
  }

  if (item.prop === -5) {
    // 开始时间
    return {
      horizontalAxis: -5,
      horizInterval: item.parm || 'day',
    };
  }

  const option = horizontalOptions.find((x: any) => x.value === item.prop);
  if (!option) {
    return {
      horizontalAxis: item.prop,
    };
  }

  // 日期属性 输出prop
  // 添加parm到 horizInterval
  if (
    option.propType === 'date' ||
    option.propType === 'datetime' ||
    option.originType === 'date' ||
    option.originType === 'datetime'
  ) {
    return {
      horizontalAxis: item.prop,
      horizInterval: item.parm,
    };
  }

  // 地址属性和多级选值
  // HorizontalAxisObject
  if (option.propType === 'address' || option.originType === 'address') {
    return {
      horizontalAxis: {
        prop: item.prop,
        type: 'address',
        parm: item.parm,
      },
    };
  }

  if (option.propType === 'cascade' || option.originType === 'cascade') {
    return {
      horizontalAxis: {
        prop: item.prop,
        type: 'cascade',
        parm: item.parm,
      },
    };
  }

  return {
    horizontalAxis: item.prop,
  };
};

export const formDataConvertApiData = (props: {
  formData: ChartFormDataType;
  horizontalOptions: HorizontalAxisOption[];
  viewType: number;
}): ApiResponse.ViewList.ViewChartsInfo => {
  const { formData, horizontalOptions, viewType } = props;
  // 1. x轴 转成
  // NormalHorizontalAxisType to number | HorizontalAxisObject | Array<number | HorizontalAxisObject>;
  // 可能转换出多个字段
  let convertHorizontalAxis = JSON.parse(JSON.stringify(formData.horizontalAxis)).map(
    (item: NormalHorizontalAxisType) =>
      handleConvertFormDataHorizontalAxis(item, horizontalOptions),
  );

  const convertHorizontalAxisData: {
    horizontalAxis?: ApiResponse.ViewList.ViewChartsInfo['horizontalAxis'];
    dateRange?: ApiResponse.ViewList.ViewChartsInfo['dateRange'];
    horizInterval?: ApiResponse.ViewList.ViewChartsInfo['horizInterval'];
    verticalGranularity?: VerticalGranularityObject;
    verticalAxis?: ApiResponse.ViewList.ViewChartsInfo['verticalAxis'];
    verticalType?: ApiResponse.ViewList.ViewChartsInfo['verticalType'];
    verticalParm?: ApiResponse.ViewList.ViewChartsInfo['verticalParm'];
    verticalTerms?: ApiResponse.ViewList.ViewChartsInfo['verticalTerms'];
    verticalTermsGroupMap?: ApiResponse.ViewList.ViewChartsInfo['verticalTermsGroupMap'];
  } = {};

  // 折线图 只有1个
  if (viewType === 3) {
    convertHorizontalAxis = convertHorizontalAxis[0];
    convertHorizontalAxisData.horizontalAxis = convertHorizontalAxis.horizontalAxis;
    if (convertHorizontalAxis.dateRange !== undefined) {
      convertHorizontalAxisData.dateRange = convertHorizontalAxis.dateRange;
    }
    if (convertHorizontalAxis.horizInterval !== undefined) {
      convertHorizontalAxisData.horizInterval = convertHorizontalAxis.horizInterval;
    }
  } else {
    const horizontalAxis: any = [];
    const horizInterval: any = [];
    convertHorizontalAxis.map((x: any, index: number) => {
      horizontalAxis.push(x.horizontalAxis);
      if (x.horizInterval !== undefined) {
        horizInterval[index] = x.horizInterval;
      }
    });
    convertHorizontalAxisData.horizontalAxis = horizontalAxis;
    if (horizInterval.length) {
      convertHorizontalAxisData.horizInterval = horizInterval;
    }
  }

  // 2. verticalAxisDateInterval 图例项为日期时的时间间隔 除了多维视图外
  // 图例类别为时间时，要把verticalAxisDateInterval 拼到 verticalType, verticalParm
  if (viewType !== 4) {
    if (typeof formData.verticalAxis === 'number') {
      const optionConfig = horizontalOptions.find((o) => o.value === formData.verticalAxis);
      if (formData.verticalAxisDateInterval !== undefined) {
        convertHorizontalAxisData.verticalType = 'datetime';
        convertHorizontalAxisData.verticalParm = formData.verticalAxisDateInterval;
      } else if (optionConfig?.propType === 'cascade') {
        convertHorizontalAxisData.verticalType = 'cascade';
      } else if (optionConfig?.propType === 'address') {
        convertHorizontalAxisData.verticalType = 'address';
      }
      convertHorizontalAxisData.verticalAxis = formData.verticalAxis;
    }
    if (formData.yAxisStatisticsType?.statisticsType === 'calculateAttr') {
      convertHorizontalAxisData.verticalGranularity = `${formData.yAxisStatisticsType.type}${formData.yAxisStatisticsType.calculateProp}`;
    } else if (formData.yAxisStatisticsType?.statisticsType === 'statisticsAttr') {
      convertHorizontalAxisData.verticalGranularity =
        formData.yAxisStatisticsType.verticalGranularity;
    }
  }

  if (formData.verticalTermsGroup) {
    convertHorizontalAxisData.verticalTerms = uniq(
      formData.verticalTermsGroup
        .map((i) => i.value)
        .flat()
        .filter((v) => !isNil(v) && v !== ''),
    );
    convertHorizontalAxisData.verticalTermsGroupMap = formData.verticalTermsGroup.reduce(
      (r, item) => ({
        ...r,
        [item.groupName]: item.value,
      }),
      {},
    );
  }

  return {
    ...omit(['verticalTermsGroup', 'horizontalAxis', 'verticalTermsGroup'], formData),
    ...convertHorizontalAxisData,
    horizontalAxis: convertHorizontalAxisData.horizontalAxis!,
  };
};
