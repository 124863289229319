import type Core from '../core';
import type { PiGridModules } from '../core';

export default class BaseModule {
  public Core: Core;

  // @ts-ignore
  public moduleInstances: PiGridModules;

  public doms: domMapType = {};

  constructor(Core: Core) {
    this.Core = Core;
  }

  public get config() {
    return this.Core.config;
  }

  public inject(moduleInstances: any) {
    this.moduleInstances = moduleInstances;
  }
}
