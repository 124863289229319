import type { PositionType } from '../components/_baseComponent';
import GridBaseComponent from '../components/_baseComponent';
import type { PiGridModules } from '../core';

class ExportGraphicCodeIcon extends GridBaseComponent {
  public data: any;
  public type: string = 'exportGraphicCodeIcon';
  public fontSize: number = 14;
  public captrue: boolean = false;

  constructor(position: PositionType, moduleInstances: PiGridModules, data: any) {
    super(position, moduleInstances);

    this.data = data;
  }

  public drawMaIcon(x: number, y: number, unicode: string) {
    const { Draw } = this.moduleInstances;
    const { ctx } = Draw;
    const styleMode = Draw.config.styleMode;

    ctx.save();
    Draw.attr({
      fillStyle: styleMode === 'darkness' ? 'rgba(62,112,223,0.5)' : '#316EF5',
      textBaseline: 'top',
      textAlign: 'left',
      font: `${Draw.npx(this.fontSize)}px iconfont`,
    });

    Draw.fillText(Draw.iconFont(unicode), x, y);
    ctx.restore();
  }

  public click() {
    this.moduleInstances.DataManager.config.setGraphicCodeInfo({
      position: this.position,
      node: this.data.node,
      text: this.data.text,
      prop: this.data.prop,
    });
  }

  public render() {
    const { Render } = this.moduleInstances;

    this.drawMaIcon(this.position.x, this.position.y, '&#xe7e8;');
    if (this.isHover) {
      Render.setCursor('pointer');
    }
  }
}

export default ExportGraphicCodeIcon;
