/* eslint-disable react-refresh/only-export-components */
import { antdModalV5, create, show, useModal } from '@ebay/nice-modal-react';
import type { GetterPiNode } from '@linkpi/core/web';
import { Modal } from 'antd';
import { type FC } from 'react';

import { useCurrentUser } from '@/hook';

import NewNodeProp from '../NewNodeProp';

interface CustomButtonEditPropsModalProps {
  editProps: string[];
  title: string;
  node: GetterPiNode;
}

const CustomButtonEditPropsModal: FC<CustomButtonEditPropsModalProps> = ({
  editProps,
  title,
  node,
}) => {
  const modal = useModal();
  const modalProps = antdModalV5(modal);
  const currentUser = useCurrentUser();

  return (
    <Modal
      width={1000}
      title={title}
      footer={null}
      {...modalProps}
      onOk={() => {
        modal.resolve();
        modalProps.onOk();
      }}
      onCancel={() => {
        modal.resolve();
        modalProps.onCancel();
      }}
      centered
    >
      <NewNodeProp
        node={node}
        isSimpleThemeTree={false}
        currentUser={currentUser}
        simpleThemeTreeWidth={0}
        setEditedProps={() => {}}
        mode="edit"
        isDrafts={false}
        nodePropConfig={{
          showGroup: false,
          style: 'default',
          propGrid: false,
        }}
        editPartPropsInNode={editProps.map((p) => Number(p.slice(5)))}
      />
    </Modal>
  );
};

const CustomButtonEditPropsNiceModal = create(CustomButtonEditPropsModal);

export const openCustomButtonEditPropsModal = (
  props: CustomButtonEditPropsModalProps,
) => {
  return show(CustomButtonEditPropsNiceModal, props);
};
