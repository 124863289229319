import type { SpaceProps } from 'antd';
import cls from 'classnames';
import { isBoolean } from 'lodash';
import type { FunctionComponent, PropsWithChildren } from 'react';

import VerticalSpace from './VerticalSpace';

import styles from './RulePanel.less';

const RulePanelCard: FunctionComponent<
  PropsWithChildren<{
    color?: string;
    showDelete?: boolean;
    onDelete?: () => void;
    spaceProps?: SpaceProps | false;
  }>
> = (props) => {
  return (
    <div className={styles['rule-card-wrapper']}>
      <div className={styles['rule-card']}>
        {props.color && (
          <div className={styles['rule-card-bg']} style={{ backgroundColor: props.color }} />
        )}
        {isBoolean(props.spaceProps) && !props.spaceProps ? (
          props.children
        ) : (
          <VerticalSpace {...props.spaceProps}>{props.children}</VerticalSpace>
        )}
      </div>
      {props.showDelete && (
        <div className={styles.delete} onClick={() => props.onDelete?.()}>
          <i className="iconfont iconshanchu2" />
        </div>
      )}
    </div>
  );
};

type RulePanelProps = {
  mode: 'condition' | 'result';
};
const RulePanel: FunctionComponent<PropsWithChildren<RulePanelProps>> & {
  Card: typeof RulePanelCard;
} = (props) => {
  return (
    <div className={styles['rule-panel']}>
      <div
        className={cls(
          styles['rule-panel-header'],
          props.mode === 'condition' && styles.left,
          props.mode === 'result' && styles.right,
        )}
      >
        {/* 条件 */}
        {props.mode === 'condition' && (
          <>
            <img src={'https://www.mylinkpi.com/icon/automation_then.png'} width={60} />
            <div className={styles['rule-panel-des']}>
              <div className={styles['rule-panel-des-title']}>当</div>
              <div className={styles['rule-panel-des-sub']}>满足以下条件时</div>
            </div>
          </>
        )}
        {props.mode === 'result' && (
          <>
            <img src={'https://www.mylinkpi.com/icon/automation_when.png'} width={60} />
            <div className={styles['rule-panel-des']}>
              <div className={styles['rule-panel-des-title']}>就</div>
              <div className={styles['rule-panel-des-sub']}>执行以下操作</div>
            </div>
          </>
        )}
      </div>
      {props.children}
    </div>
  );
};

RulePanel.Card = RulePanelCard;

export default RulePanel;
