import { CaretDownOutlined } from '@ant-design/icons';
import { PERMISSION_OPTION } from '@linkpi/core';
import { Input, Select, Tree } from 'antd';
import { useState } from 'react';

import { useOrgDepartmentNodes, useOrgStructureDepartments } from '@/hook/useOrgStructure';

import styles from './styles.less';
interface IProps {
  setNodeGroupAclData: any;
  setChangedGroupAclData: any;
  nodeGroupAclData: any;
  changedGroupAclData: any;
}
export default (props: IProps) => {
  const { setChangedGroupAclData, setNodeGroupAclData, nodeGroupAclData, changedGroupAclData } =
    props;

  const departmentNodes = useOrgDepartmentNodes();
  const treeData = useOrgStructureDepartments({});
  const [searchKey, setSearchKey] = useState('');

  const changeStructureAcl = (node: any, v: any) => {
    setNodeGroupAclData({ ...nodeGroupAclData, [node.groupId]: v });
    setChangedGroupAclData({ ...changedGroupAclData, [node.groupId]: v });
  };

  const titleRender = (node: any) => {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: 400,
        }}
      >
        <div style={{ paddingLeft: 8 }}>{node.title}</div>
        <Select
          style={{ width: 95 }}
          bordered={false}
          value={nodeGroupAclData[node.groupId]}
          allowClear={true}
          onChange={(v) => changeStructureAcl(node, v)}
          placeholder="请选择"
        >
          {PERMISSION_OPTION.map((x) => (
            <Select.Option key={x.key}>{x.value}</Select.Option>
          ))}
        </Select>
      </div>
    );
  };
  return (
    <div className={`editForm ${styles.structureAcl}`}>
      <div style={{ padding: 0, marginBottom: 16 }} className="search">
        <Input
          style={{ width: 400 }}
          bordered={false}
          suffix={<i className="iconfont iconsearch" />}
          value={searchKey}
          onChange={(e) => setSearchKey(e.target.value)}
          placeholder="请输入组织名称"
        />
      </div>
      {!searchKey.trim() ? (
        <Tree
          titleRender={titleRender}
          treeData={treeData}
          fieldNames={{ children: 'departmentChildren', key: 'groupId' }}
          switcherIcon={<CaretDownOutlined />}
        />
      ) : (
        departmentNodes
          .filter((d) => d.name.indexOf(searchKey) !== -1)
          .map((d) => (
            <div className={styles.departmentNode} key={d.id}>
              <div>{d.name}</div>
              <Select
                style={{ width: 95 }}
                bordered={false}
                value={nodeGroupAclData[d.id]}
                allowClear={true}
                onChange={(v) => changeStructureAcl({ groupId: d.id }, v)}
                placeholder="请选择"
              >
                {PERMISSION_OPTION.map((x) => (
                  <Select.Option key={x.key}>{x.value}</Select.Option>
                ))}
              </Select>
            </div>
          ))
      )}
    </div>
  );
};
