import $ from '../helper/dom';
import BaseModule from './_baseModule';

export default class Overlayer extends BaseModule {
  public static CSS = {
    overlayer: 'pi-gantt-overlayer',
  };

  public prepare() {
    const { Render, Listeners } = this.moduleInstances;
    this.doms.overlayer = $.make('div', Overlayer.CSS.overlayer);
    this.moduleInstances.Render.doms.wrapper.append(this.doms.overlayer);
  }
}
