import type { ApiResponse } from '@linkpi/core';
import { getTempStatusIconColor, STATUS_ICON, tempValueDisplay } from '@linkpi/core';
import type { Dayjs } from 'dayjs';

import type { PiGanttModules } from '../core';
import { hexToRgba } from '../helper/utils';
import type DateColumnManager from '../modules/dateColumnManager';
import type { nodeRowData } from '../modules/nodeRowManager';
import type { positionType } from './_baseComponent';
import BaseComponent from './_baseComponent';
import type NodeRow from './nodeRow';

const CELL_HEIGHT = 32;

function genPosition(
  validDateScope: [Dayjs, Dayjs] | [],
  DateColumnManager: DateColumnManager,
  nodeRow: NodeRow,
) {
  const position = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  };

  if (!validDateScope.length) return position;

  const startOffset = DateColumnManager.getDateOffset(validDateScope[0]);
  const startX = DateColumnManager.offsetToX(startOffset);
  const dayCount = Math.max(
    1,
    validDateScope[1]
      .startOf('day')
      .diff(validDateScope[0].startOf('day'), DateColumnManager.columnUnit) + 1,
  );

  position.x = startX - DateColumnManager.columnWidth / 2;
  position.y = nodeRow.position.y + (nodeRow.position.height - CELL_HEIGHT) / 2;
  position.width = dayCount * DateColumnManager.columnWidth;
  position.height = CELL_HEIGHT;

  return position;
}

export default class GanttCell extends BaseComponent {
  public data: nodeRowData<'node'>;
  private nodeRow: NodeRow;
  public validDateScope: [Dayjs, Dayjs] | [] = [];
  public statusConfig: ApiResponse.CurrentUser.taskStatus;

  constructor(
    moduleInstances: PiGanttModules,
    data: nodeRowData<'node'>,
    statusConfig: any,
    nodeRow: NodeRow,
  ) {
    const localDateScope = moduleInstances.NodeRowManager.getCellLocalDateChangeRecord(
      data.node.id,
      data.validDateScope,
    );
    const validDateScope = localDateScope ? localDateScope : data.validDateScope;

    const position = genPosition(validDateScope, moduleInstances.DateColumnManager, nodeRow);

    super(position, moduleInstances);
    this.data = data;
    this.nodeRow = nodeRow;
    this.statusConfig = statusConfig;
    this.validDateScope = validDateScope;
  }

  public onClick() {
    this.moduleInstances.NodeRowManager.config.onPreview(
      this.nodeRow.data.node.id,
      this.moduleInstances.NodeRowManager.virtualNodeIdList,
    );
  }

  public render() {
    if (!this.validDateScope.length) return;

    const { Draw, Render, DateColumnManager, Event } = this.moduleInstances;
    const { columnUnit, columnList } = DateColumnManager;
    const iconColor = getTempStatusIconColor(this.statusConfig.icon);
    const bgColorAlpha = Draw.config.isDark ? 0.8 : 0.15;
    const bgColor = hexToRgba(iconColor, bgColorAlpha).rgba;
    const mousePosition = window.__PI__GANTT.mousePosition;
    let dragDateBg = 'transparent';

    // 如果存在显示百分比的属性，就展示百分比
    const { curViewData, tempConfig } = DateColumnManager.config;
    const showProgressByProp = curViewData?.view_info?.showProgressByProp;

    // is drag cell
    if (Event.isDragCell && Event.isDragCell.dragCell.data.node.id === this.data.node.id) {
      this.position.x = mousePosition.x - Event.isDragCell.dragPointPositionInCell.x;
      this.position.y = mousePosition.y - Event.isDragCell.dragPointPositionInCell.y;
    }

    // is drag date
    const isDragCurDate =
      Event.isDragDate &&
      Event.isDragDate.dragDateSide.ganttCell.data.node.id === this.data.node.id;
    if (this.isHover) {
      // hover bg color
      dragDateBg = iconColor;
    }
    if (isDragCurDate) {
      // hover bg color
      dragDateBg = iconColor;
      // dragDateSide
      const isDragLeft =
        isDragCurDate && Event.isDragDate && Event.isDragDate.dragDateSide.direction === 'left';
      const isDragRight =
        isDragCurDate && Event.isDragDate && Event.isDragDate.dragDateSide.direction === 'right';

      if (isDragLeft) {
        let offsetLeftX = this.position.x - mousePosition.x + 4;
        // 最小一格不允许拖动
        if (offsetLeftX + this.position.width < DateColumnManager.columnWidth) {
          offsetLeftX = -(this.position.width - DateColumnManager.columnWidth);
        }

        this.position.x = this.position.x - offsetLeftX;
        this.position.width = this.position.width + offsetLeftX;
      }

      if (isDragRight) {
        let offsetRightX = mousePosition.x - this.position.x - this.position.width + 4;
        if (offsetRightX + this.position.width < DateColumnManager.columnWidth) {
          offsetRightX = -(this.position.width - DateColumnManager.columnWidth);
        }
        this.position.width = this.position.width + offsetRightX;
      }
    }

    // 6/17 cell不管在不在可视区域 都渲染
    // cell
    Draw.fillRoundRect(this.position, 8, bgColor);

    // 渲染属性百分比进度条
    if (showProgressByProp) {
      const propIndex = showProgressByProp.split('-')[1];
      const progress = tempValueDisplay({
        propConfig: tempConfig?.prop[propIndex],
        propValue: this.data.node.tempInfo.prop[propIndex],
        userMap: {},
        tempMap: {},
        departmentMap: {},
      });
      const percent = Number(String(progress).slice(0, -1));
      const progressBgColorAlpha = Draw.config.isDark ? 0.8 : 0.3;
      const progressBgColor = hexToRgba(iconColor, progressBgColorAlpha).rgba;
      if (percent > 0) {
        if (percent >= 100) Draw.fillRoundRect(this.position, 8, progressBgColor);
        else {
          const { x, y, height, width } = this.position;
          const progressWidth = (width * percent) / 100;
          Draw.fillProgressArea(
            x,
            y,
            progressWidth <= 8 ? 8 : progressWidth > width - 8 ? width - 8 : progressWidth,
            height,
            8,
            progressBgColor,
          );
        }
      }
    }

    // icon
    // 比设计图多4px 的padding 做hover阴影
    const paddingLeft = 10;
    const iconDiameter = 18;
    const iconX = this.position.x + paddingLeft;
    const iconY = this.position.y + 7 - 4;
    const iconWidth = iconDiameter + 4 * 2;
    const iconHeight = iconDiameter + 4 * 2;
    const iconUnicode = STATUS_ICON[this.statusConfig.icon].unicode;

    const statusIcon = new StatusIcon(
      {
        x: iconX,
        y: iconY,
        width: iconWidth,
        height: iconHeight,
      },
      this.moduleInstances,
      this,
      iconColor,
      iconUnicode,
      iconDiameter,
    );
    statusIcon.render();

    // title
    Draw.attr({ fillStyle: Draw.style.node.titleColor });
    Draw.ctx.textBaseline = 'middle';
    Draw.ctx.textAlign = 'left';
    Draw.ctx.font = `${Draw.npx(14)}px  sans-serif`;
    Draw.fillText(
      this.data.node.title || '无标题',
      this.position.x + paddingLeft + iconWidth + 4,
      this.position.y + this.position.height / 2,
    );

    if (showProgressByProp) {
      const propIndex = showProgressByProp.split('-')[1];
      const progress = tempValueDisplay({
        propConfig: tempConfig?.prop[propIndex],
        propValue: this.data.node.tempInfo.prop[propIndex],
        userMap: {},
        tempMap: {},
        departmentMap: {},
      });
      Draw.ctx.font = `bold ${Draw.npx(14)}px  sans-serif`;
      Draw.fillText(
        String(progress),
        this.position.x +
          paddingLeft +
          iconWidth +
          8 +
          Draw.measureTextWidth(this.data.node.title || '无标题'),
        this.position.y + this.position.height / 2,
      );
    }

    // hover popup
    if (this.isHover && !Event.isDragCell) {
      Render.setCursor('pointer');
      Render.setRenderAfterAction(() => {
        if (!this.validDateScope.length) return;

        // title
        Draw.save();
        Draw.attr({ fillStyle: '#fff' });
        Draw.ctx.textBaseline = 'middle';
        Draw.ctx.textAlign = 'left';
        Draw.ctx.font = `${Draw.npx(13)}px  sans-serif`;
        // 显示周期
        // 最小取1天
        let displayText = '';
        if (statusIcon.isHover) {
          displayText = '点击切换状态';
        } else {
          const count = Math.max(
            1,
            this.validDateScope[1]
              .startOf('day')
              .diff(this.validDateScope[0].startOf('day'), 'day') + 1,
          );
          displayText = `${this.validDateScope[0].format(
            'YYYY/MM/DD',
          )} - ${this.validDateScope[1].format('YYYY/MM/DD')}`;
          displayText += `，共${count}天`;
        }

        const displayInfo = Draw.ctx.measureText(displayText);

        const padding = 4 * window.devicePixelRatio;
        const width = displayInfo.width / window.devicePixelRatio + padding * 2;
        const height =
          (displayInfo.actualBoundingBoxAscent + displayInfo.actualBoundingBoxDescent) /
            window.devicePixelRatio +
          padding * 2;
        let x = window.__PI__GANTT.mousePosition.x - width / 2;
        let y = this.position.y + this.position.height + padding;

        // TODO: use Draw
        const canvas = this.moduleInstances.Render.doms.container;
        // Check for overflow
        if (x + width > canvas.clientWidth) {
          x = canvas.clientWidth - width;
        }

        if (y + height > canvas.clientHeight) {
          y = canvas.clientHeight - height;
        }

        // preview popup
        Draw.fillRoundRect(
          {
            x,
            y,
            width,
            height,
          },
          5,
          '#4a4b4f',
        );

        Draw.attr({ fillStyle: '#fff' });
        Draw.ctx.textBaseline = 'top';
        Draw.ctx.textAlign = 'left';
        Draw.ctx.font = `${Draw.npx(13)}px  sans-serif`;

        Draw.fillText(displayText, x + padding, y + padding);
      });
    }

    let getUnit = 'YYYYMM';
    if (columnUnit === 'day') {
      getUnit = 'YYYYMMDD';
    }
    if (columnUnit === 'month') {
      getUnit = 'YYYYMM';
    }

    const startDateColDateNum = Number(columnList[0].dayInstance.format(getUnit));
    const endDateColDateNum = Number(columnList[columnList.length - 1].dayInstance.format(getUnit));
    const renderPaginationStart =
      startDateColDateNum >= Number(this.validDateScope[0].format(getUnit));
    const renderPaginationEnd = endDateColDateNum <= Number(this.validDateScope[1].format(getUnit));

    // render pagination
    // 如果没显示完整，则显示pagination
    //   margin 10
    const paginationWidth = 20;
    if (renderPaginationStart) {
      const startPagination = new Pagination(
        {
          width: paginationWidth,
          height: paginationWidth,
          x: Render.leftPanelStyle.width + 10,
          y: this.nodeRow.position.y + (this.nodeRow.position.height - paginationWidth) / 2,
        },
        this.moduleInstances,
        'start',
        this,
      );
      startPagination.render();
    }

    if (renderPaginationEnd) {
      const endPagination = new Pagination(
        {
          width: paginationWidth,
          height: paginationWidth,
          x: Draw.canvasWidth - 10 - paginationWidth,
          y: this.nodeRow.position.y + (this.nodeRow.position.height - paginationWidth) / 2,
        },
        this.moduleInstances,
        'end',
        this,
      );

      endPagination.render();
    }

    const leftDragDateSide = new DragDateSide(
      {
        x: this.position.x,
        y: this.position.y,
        width: 8,
        height: this.position.height,
      },
      this.moduleInstances,
      'left',
      dragDateBg,
      this,
    );
    leftDragDateSide.render();

    const rightDragDateSide = new DragDateSide(
      {
        x: this.position.x + this.position.width - 8,
        y: this.position.y,
        width: 8,
        height: this.position.height,
      },
      this.moduleInstances,
      'right',
      dragDateBg,
      this,
    );
    rightDragDateSide.render();
  }
}

class Pagination extends BaseComponent {
  private type: 'start' | 'end';
  private cell: GanttCell;
  constructor(
    position: positionType,
    moduleInstances: PiGanttModules,
    type: 'start' | 'end',
    cell: GanttCell,
  ) {
    super(position, moduleInstances);
    this.type = type;
    this.cell = cell;
  }

  public onClick() {
    const { DateColumnManager, Event } = this.moduleInstances;
    let offset;
    if (this.type === 'start') {
      // 滚动到start
      offset = DateColumnManager.getDateOffset(this.cell.validDateScope[0] as Dayjs);
    } else {
      offset = DateColumnManager.getDateOffset(this.cell.validDateScope[1] as Dayjs);
    }
    Event.handleMoveX(offset, true);
  }

  public render() {
    const { Draw, Render } = this.moduleInstances;

    let unicode;
    let boxFillColor = Draw.style.pagination.backgroundColor;
    let fontColor = Draw.style.pagination.color;

    if (this.type === 'start') {
      unicode = '&#xe70b;';
    } else {
      unicode = '&#xe6cc;';
    }

    if (this.isHover) {
      Render.setCursor('pointer');
      boxFillColor = Draw.style.pagination.hoverBackgroundColor;
      fontColor = Draw.style.pagination.hoverColor;
    }
    if (this.isHover) {
      Render.setCursor('pointer');
      Render.setRenderAfterAction(() => {
        Draw.save();
        Draw.attr({ fillStyle: '#fff' });
        Draw.ctx.textBaseline = 'top';
        Draw.ctx.textAlign = 'left';
        Draw.ctx.font = `${Draw.npx(13)}px  sans-serif`;

        const text = `shift+滚轮 可横向滚动视图`;

        const displayInfo = Draw.ctx.measureText(text);

        const padding = 4 * window.devicePixelRatio;
        const width = displayInfo.width / window.devicePixelRatio + padding * 2;
        const height =
          (displayInfo.actualBoundingBoxAscent + displayInfo.actualBoundingBoxDescent) /
            window.devicePixelRatio +
          padding * 2;
        let x = window.__PI__GANTT.mousePosition.x - width / 2;
        let y = this.position.y + this.position.height + padding;

        // TODO: use Draw
        const canvas = this.moduleInstances.Render.doms.container;
        // Check for overflow
        if (x + width > canvas.clientWidth) {
          x = canvas.clientWidth - width;
        }

        if (y + height > canvas.clientHeight) {
          y = canvas.clientHeight - height * 2;
        }

        Draw.fillRoundRect(
          {
            x,
            y,
            width,
            height,
          },
          5,
          '#4a4b4f',
        );
        Draw.fillText(text, x + padding, y + padding + displayInfo.actualBoundingBoxAscent);

        Draw.restore();
      });
    }

    Draw.fillRoundRect(
      {
        width: this.position.width,
        height: this.position.height,
        x: this.position.x,
        y: this.position.y,
      },
      2,
      boxFillColor,
    );

    Draw.attr({ fillStyle: fontColor });
    Draw.ctx.textBaseline = 'middle';
    Draw.ctx.textAlign = 'center';
    Draw.ctx.font = `${Draw.npx(13)}px  iconfont`;
    Draw.fillText(
      Draw.iconFont(unicode),
      this.position.x + this.position.width / 2,
      this.position.y + this.position.height / 2,
    );
  }
}

class StatusIcon extends BaseComponent {
  private ganttCell: GanttCell;
  private iconDiameter: number;
  private iconColor: string;
  private iconUnicode: string;

  constructor(
    position: positionType,
    moduleInstances: PiGanttModules,
    ganttCell: GanttCell,
    iconColor: string,
    iconUnicode: string,
    iconDiameter: number,
  ) {
    super(position, moduleInstances);
    this.ganttCell = ganttCell;
    this.iconColor = iconColor;
    this.iconUnicode = iconUnicode;
    this.iconDiameter = iconDiameter;
  }

  public onClick() {
    this.moduleInstances.NodeRowManager.config.onStatusIconClick(
      this.ganttCell.data.node,
      this.position,
    );
  }

  public render() {
    const { Draw } = this.moduleInstances;

    if (this.isHover) {
      const bgColor = hexToRgba(this.iconColor, 0.3).rgba;
      Draw.fillRoundRect(
        {
          x: this.position.x,
          y: this.position.y,
          width: this.position.width,
          height: this.position.height,
        },
        8,
        bgColor,
      );
    }

    const renderIconColor = Draw.config.isDark ? '#fff' : this.iconColor;
    Draw.attr({ fillStyle: renderIconColor });
    Draw.ctx.textBaseline = 'middle';
    Draw.ctx.textAlign = 'center';
    Draw.ctx.font = `${Draw.npx(this.iconDiameter)}px  iconfont`;
    Draw.fillText(
      Draw.iconFont(this.iconUnicode),
      this.position.x + this.position.width / 2,
      this.position.y + this.position.height / 2,
    );
  }
}

export class DragDateSide extends BaseComponent {
  public direction: 'left' | 'right';
  private bgColor: string;
  public ganttCell: GanttCell;

  constructor(
    position: positionType,
    moduleInstances: PiGanttModules,
    direction: 'left' | 'right',
    bgColor: string,
    ganttCell: GanttCell,
  ) {
    super(position, moduleInstances);
    this.direction = direction;
    this.bgColor = bgColor;
    this.ganttCell = ganttCell;
  }

  public render() {
    const { Draw, Render } = this.moduleInstances;
    Draw.fillRoundRect(
      {
        x: this.position.x,
        y: this.position.y,
        width: this.position.width,
        height: this.position.height,
      },
      4,
      this.bgColor,
    );
    if (Draw.config.isDark && this.bgColor !== 'transparent') {
      Draw.strokeRoundRect(
        {
          x: this.position.x,
          y: this.position.y,
          width: this.position.width,
          height: this.position.height,
        },
        4,
        1,
        '#fff',
      );
    }

    if (this.isHover) {
      Render.setCursor('ew-resize');
    }
  }
}
