import type { ApiResponse } from '@linkpi/core';
import type { FormInstance } from 'antd';
import { Button, Form, message, Select } from 'antd';
const { OptGroup, Option } = Select;

const NUMBER_DAY_OPTION = [
  { value: 0, label: '全部' },
  { value: 7, label: '7天' },
  { value: 30, label: '30天' },
  { value: 90, label: '90天' },
];

export const DATE_INTERVAL_OPTION = [
  { value: 'hour', label: '按小时统计' },
  { value: 'day', label: '按日统计' },
  { value: 'mon', label: '按月统计' },
  { value: 'year', label: '按年统计' },
];

export const DATE_INTERVAL_OPTION_WITHOUT_HOUR = DATE_INTERVAL_OPTION.filter(
  (i) => i.value !== 'hour',
);

const ADDRESS_OPTION = [
  { value: 'prov', label: '按省统计' },
  { value: 'city', label: '按市统计' },
  { value: 'dist', label: '按区县统计' },
];

const getCascadeOption = (propConfig: ApiResponse.CurrentUser.TemplateProp) => {
  const titles = propConfig?.cascade?.titles || [];
  return [{ value: 0, label: '全部' }, ...titles.map((x, i) => ({ value: i + 1, label: x }))];
};

export const getXAxiosForm = (props: {
  viewType: number;
  handleHorizontalChange: any;
  horizontalOptions: any;
  verticalAxis: any;
  horizontalAxisValue: any;
  form: FormInstance;
}) => {
  const {
    handleHorizontalChange,
    horizontalAxisValue,
    viewType,
    horizontalOptions,
    form,
    verticalAxis,
  } = props;

  const getIsType = (itemOption: any, type: ApiResponse.CurrentUser.propType) => {
    if (!itemOption) return false;

    if (itemOption.propType === type) return true;
    if (itemOption.originType === type) return true;

    return false;
  };

  // 有多选时 不允许添加
  const hasMultiple: boolean = !!(horizontalAxisValue || []).find((x: any) => {
    const prop = x?.prop;
    const item = horizontalOptions.find((x: any) => x.value === Number(prop));
    return item?.multiple;
  });

  const getAddBtn = (add: any) => {
    // 仅柱状图和条形图支持多 x 轴
    if ([3, 4].includes(viewType)) return null;

    // 有多选时 不允许添加
    if (hasMultiple) return null;

    return (
      <Button
        className={'line-add-btn'}
        size={'small'}
        onClick={() => {
          const xAxis = form.getFieldValue('horizontalAxis') || [];
          if (
            !~xAxis?.findIndex((x: any) => x === null) &&
            xAxis.length < horizontalOptions.length + 1
          ) {
            add();
            handleHorizontalChange(null);
          } else if (!horizontalOptions.length) {
            form.validateFields(['template']);
            message.warn('请选择主题类型');
          }
        }}
        icon={<i className={'iconfont iconadd'} style={{ fontSize: 12 }} />}
      >
        添加
      </Button>
    );
  };

  return (
    <Form.Item>
      <Form.List name="horizontalAxis">
        {(fields, { add, remove }) => (
          <>
            <Form.Item style={{ marginBottom: 8 }}>
              <div className="form-label line-label">
                X轴
                {getAddBtn(add)}
              </div>
            </Form.Item>
            {fields.map((horizontalAxisItem) => {
              const selectItemValue = form.getFieldValue([
                'horizontalAxis',
                horizontalAxisItem.key,
              ]);
              const itemOption = horizontalOptions.find(
                (x: any) => x.value === selectItemValue?.prop,
              );

              return (
                <div
                  key={horizontalAxisItem.key}
                  className={'form-list-item'}
                  style={{ display: 'flex' }}
                >
                  <Form.Item
                    name={[horizontalAxisItem.name, 'prop']}
                    rules={[{ required: true, message: '请选择X轴显示属性' }]}
                    className={'list-item'}
                    style={{ flex: 1 }}
                  >
                    <Select
                      filterOption={(input, option) =>
                        (option?.children as unknown as string)
                          ?.toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      showSearch={true}
                      placeholder={'请选择X轴属性'}
                      onChange={(e) => {
                        const item = horizontalOptions.find((x: any) => x.value === e);

                        // 多选时 清空所有的
                        if (item?.multiple) {
                          form.setFieldValue('horizontalAxis', [
                            {
                              prop: e,
                            },
                          ]);
                          form.resetFields([['horizontalAxis', horizontalAxisItem.name, 'parm']]);
                        } else {
                          if (e === -5 || getIsType(item, 'date') || getIsType(item, 'datetime'))
                            form.setFieldValue(
                              ['horizontalAxis', horizontalAxisItem.name, 'parm'],
                              DATE_INTERVAL_OPTION_WITHOUT_HOUR[0].value,
                            );
                          else if (e === -2)
                            form.setFieldValue(
                              ['horizontalAxis', horizontalAxisItem.name, 'parm'],
                              NUMBER_DAY_OPTION[0].value,
                            );
                          else if (getIsType(item, 'address'))
                            form.setFieldValue(
                              ['horizontalAxis', horizontalAxisItem.name, 'parm'],
                              ADDRESS_OPTION[0].value,
                            );
                          else
                            form.resetFields([['horizontalAxis', horizontalAxisItem.name, 'parm']]);
                        }

                        handleHorizontalChange(e);
                      }}
                    >
                      <OptGroup label="属性">
                        {horizontalOptions.map((option: any, i: number) => {
                          // if (option.value >= 0) {
                          return (
                            <Option
                              value={option.value}
                              key={i}
                              disabled={verticalAxis === option.value}
                            >
                              {option.label}
                            </Option>
                          );
                          // }
                        })}
                      </OptGroup>
                      <OptGroup label="状态属性">
                        <Option value={-1} key={-1}>
                          状态
                        </Option>
                        <Option value={-5} key={-5}>
                          开始时间
                        </Option>
                      </OptGroup>
                    </Select>
                  </Form.Item>

                  {/* 折线图 x轴选择 时间(-2) 时 选择统计的天数 */}
                  <Form.Item
                    name={[horizontalAxisItem.name, 'parm']}
                    colon={false}
                    required={false}
                    hidden={!(viewType === 3 && selectItemValue?.prop === -2)}
                    style={{ flex: 1, marginLeft: 10, marginBottom: 0 }}
                  >
                    <Select options={NUMBER_DAY_OPTION} />
                  </Form.Item>

                  {/* 开始时间 时 选择统计的时间间隔 */}
                  <Form.Item
                    name={[horizontalAxisItem.name, 'parm']}
                    colon={false}
                    required={false}
                    hidden={selectItemValue?.prop !== -5}
                    style={{ flex: 1, marginLeft: 10, marginBottom: 0 }}
                  >
                    <Select options={DATE_INTERVAL_OPTION_WITHOUT_HOUR} />
                  </Form.Item>

                  {/* 日期属性 时 选择统计的时间间隔 */}
                  <Form.Item
                    name={[horizontalAxisItem.name, 'parm']}
                    colon={false}
                    required={false}
                    hidden={!(getIsType(itemOption, 'date') || getIsType(itemOption, 'datetime'))}
                    style={{ flex: 1, marginLeft: 10, marginBottom: 0 }}
                  >
                    <Select options={DATE_INTERVAL_OPTION} />
                  </Form.Item>

                  {/* 地址属性 统计的粒度 */}
                  <Form.Item
                    name={[horizontalAxisItem.name, 'parm']}
                    colon={false}
                    required={false}
                    hidden={!getIsType(itemOption, 'address')}
                    style={{ flex: 1, marginLeft: 10, marginBottom: 0 }}
                  >
                    <Select options={ADDRESS_OPTION} />
                  </Form.Item>

                  {/* 多级选值 统计粒度 */}
                  <Form.Item
                    name={[horizontalAxisItem.name, 'parm']}
                    colon={false}
                    required={false}
                    hidden={!getIsType(itemOption, 'cascade')}
                    style={{ flex: 1, marginLeft: 10, marginBottom: 0 }}
                  >
                    <Select options={getCascadeOption(itemOption?.option)} />
                  </Form.Item>

                  {fields.length > 1 ? (
                    <div
                      className="cancel-btn"
                      style={{ marginLeft: 10 }}
                      onClick={() => {
                        remove(horizontalAxisItem.name);
                        handleHorizontalChange(null);
                      }}
                    >
                      <i className="iconfont iconButton_cancel" />
                    </div>
                  ) : null}
                </div>
              );
            })}
          </>
        )}
      </Form.List>
    </Form.Item>
  );
};
