import type { DragTableProps } from '@ant-design/pro-table';
import type { ConditionRuleConfigV2 } from '@linkpi/core';
import { buildRecord } from '@linkpi/utils';
import { useMemoizedFn } from 'ahooks';
import { useMemo, useState } from 'react';

export const useSortedRuleList = <T extends ConditionRuleConfigV2.ConditionRuleConfigV2>({
  immediateSorting,
  setList,
  list,
}: {
  list: T[];
  setList: (i: T[]) => any;
  immediateSorting: boolean;
}) => {
  const [sorted, setSorted] = useState(false);
  const [sortedIdList, setSortedIdList] = useState(() => list?.map((i) => i.ruleId) || []);

  const setSortedList: DragTableProps<T, any>['onDragSortEnd'] = useMemoizedFn(
    (_before, _after, _list: T[]) => {
      if (immediateSorting) {
        setList(_list);
        return;
      }
      setSorted(true);
      setSortedIdList(_list.map((i) => i.ruleId));
    },
  );

  const sortedList = useMemo(() => {
    if (immediateSorting) {
      return list;
    }

    if (!sorted) return list;

    const ruleMap = buildRecord(list, 'ruleId');

    return sortedIdList.map((id) => ruleMap[id]);
  }, [sortedIdList, list, sorted]);

  return { sorted, setSorted, sortedList, setSortedList };
};
