import { checkGroup, updateProp } from '@linkpi/core';
import { Popover } from 'antd';
import { useMemo, useState } from 'react';

import IconPark from '@/components/IconPark';
import { WEB_OFFICE_TYPES } from '@/components/WebOfficeModal/constants';
import { useCurrentUserInfo } from '@/hook';
import request from '@/utils/request';
import { getFileTypeIconByUrl } from '@/utils/utils';

import styles from './styles.less';

interface IProps {
  showAttachmentActions: null | {
    file: any;
    propIndex: number;
    node: PiNode;
    position: any;
    propConfig: any;
  };
  setShowAttachmentActions: (payload: any) => void;
  showBigImg: (imgs: string[], index: number) => void;
  orgInfo: any;
  handleViewDoc: (v: any) => void;
}

export default (props: IProps) => {
  const { setShowAttachmentActions, showAttachmentActions, showBigImg, orgInfo, handleViewDoc } =
    props;
  if (!showAttachmentActions) return null;

  const { position, file, node, propIndex, propConfig } = showAttachmentActions;
  const [open, setOpen] = useState<boolean>(true);
  const userInfo = useCurrentUserInfo();

  const changeOpen = (v: boolean) => {
    if (!v) return setShowAttachmentActions(null);
    setOpen(v);
  };

  const { src: attachmentUrl, type: attachmentType } = useMemo(
    () => getFileTypeIconByUrl(file.src),
    [file],
  );

  const download = (src: string) => {
    fetch(src)
      .then((res) => res.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('download', file.fileName);
        a.setAttribute('href', url);

        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      });
  };

  const delAttachment = async () => {
    setShowAttachmentActions(null);
    // 节点附件name
    const tempInfo = node.tempInfo;
    const fileNames = tempInfo.prop[propIndex] || [];
    const newFileNames = fileNames.filter((n: string) => n !== file.fileName);

    // 节点附件链接
    const _sys_attach = (node.prop || {})._sys_attach || {};
    const fileUrls = _sys_attach[propIndex] || [];
    const newFileUrls = fileUrls.filter((u) => u !== file.src);

    const req = {
      org_id: orgInfo.orgId,
      temp_id: node.tempInfo.id,
      node_id: node.id,
      index: [Number(propIndex)],
      value: [newFileNames],
      attachment: {
        [propIndex]: newFileUrls,
      },
    };

    await updateProp(request, req);
  };

  const renderContent = () => {
    return (
      <div className={styles.container}>
        <div
          style={{
            backgroundImage: `url(${attachmentUrl})`,
          }}
          className={styles.fileImg}
        >
          <div className={styles.actions}>
            {attachmentType === 'image' || WEB_OFFICE_TYPES.includes(attachmentType) ? (
              <IconPark
                type="chakandatu1"
                style={{ fontSize: 14, color: '#fff', cursor: 'pointer' }}
                onClick={() => {
                  setShowAttachmentActions(null);
                  if (WEB_OFFICE_TYPES.includes(attachmentType)) {
                    return handleViewDoc({ src: file.src, name: file.fileName });
                  }

                  const _sys_attach = (node.prop || {})._sys_attach || {};
                  const urls = _sys_attach[propIndex];

                  const imgs = urls
                    .map((i) => getFileTypeIconByUrl(i))
                    .filter((i) => i.type === 'image')
                    .map((i) => i.src);

                  showBigImg(
                    imgs,
                    imgs.findIndex((i) => i === attachmentUrl),
                  );
                }}
              />
            ) : (
              <div />
            )}

            <div>
              {checkGroup(propConfig.attachmentConfig?.allowDownloadGroups || ['-1'], userInfo) && (
                <IconPark
                  onClick={() => {
                    setShowAttachmentActions(null);
                    download(file.src);
                  }}
                  type="xiazai1"
                  style={{ fontSize: 14, color: '#fff', cursor: 'pointer' }}
                />
              )}
              <IconPark
                type="shanchu1"
                style={{ fontSize: 14, color: '#fff', marginLeft: 12, cursor: 'pointer' }}
                onClick={delAttachment}
              />
            </div>
          </div>
        </div>
        <div className={styles.fileName}>{file.fileName}</div>
      </div>
    );
  };

  return (
    <Popover
      placement="top"
      visible={open}
      onVisibleChange={changeOpen}
      content={renderContent()}
      overlayClassName={styles.popover}
    >
      <div
        style={{
          position: 'absolute',
          height: position.height,
          width: position.width,
          left: position.x,
          top: position.y,
        }}
      />
    </Popover>
  );
};
