import type { ViewList } from '@linkpi/core';

export const getViewIcon = (viewType: ViewList.ViewType) => {
  switch (viewType) {
    case 1: // 看板
      return {
        icon: 'iconfont iconView_kanban',
        color: '#877ae9',
      };
    case 2: // 表格
      return {
        icon: 'iconfont iconView_table',
        color: '#67C2B7',
      };
    case 3: // 统计
      return {
        icon: 'iconfont iconView_Statistic',
        color: '#fda84a',
      };
    case 4: // gantt
      return {
        icon: 'iconfont icongantt',
        color: '#70bff3',
      };
    default:
      return {
        icon: 'iconfont iconView_kanban',
        color: '#877ae9',
      };
  }
};
