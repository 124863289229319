import { RollbackOutlined } from '@ant-design/icons';
import type { ApiResponse } from '@linkpi/core';
import { Button, Col, Input, Row } from 'antd';
import cls from 'classnames';
import { isNil } from 'ramda';
import { useMemo, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import type { ComponentTypeInfo } from '.';

import styles from './styles.less';

type ViewListPropsType = {
  viewList: ApiResponse.ViewList;
  componentType: ComponentTypeInfo['type'];
  chartType?: ApiResponse.ViewList.ViewChartsType;
  onViewClick: (viewInfo: ApiResponse.ViewList.ViewListItem<any>) => void;
  getViewPath: (nodeId: string) => string;
  getViewIcon: (viewType: ApiResponse.ViewList.ViewType) => { color: string; icon: string };
  containerHeight?: string | number;
  showBack?: boolean;
  onBack?: () => void;
  className?: string;
};

const ViewList = (props: ViewListPropsType) => {
  const {
    viewList,
    componentType,
    onViewClick,
    getViewPath,
    getViewIcon,
    containerHeight,
    chartType,
    showBack = false,
    onBack,
    className,
  } = props;

  const [search, setSearch] = useState('');

  const showViewList = useMemo(() => {
    const lowSearch = search.toLowerCase();
    return viewList.filter((item) => {
      if (!item.node_id) return false;
      if (item.view_type === 5) return false;
      if (item.view_type === 7) return false;
      if (item.dash_view_id) return false;
      if (componentType === 'chart') {
        if (item.view_type !== 3) {
          return false;
        }
      } else {
        if (item.view_type === 3) {
          return false;
        }
      }
      if (lowSearch) {
        return item.view_name.toLowerCase().includes(lowSearch);
      }
      if (!isNil(chartType) && item.charts_info?.type !== chartType) {
        return false;
      }
      return true;
    });
  }, [viewList, search, componentType]);

  return (
    <div
      className={cls(styles.container, styles.step3, className)}
      style={{ height: containerHeight }}
    >
      <Row align="middle" gutter={2}>
        {showBack && (
          <Col style={{ width: 40 }}>
            <Button
              type="text"
              icon={<RollbackOutlined />}
              onClick={onBack}
              style={{ marginLeft: 'auto', display: 'block' }}
            />
          </Col>
        )}
        <Col flex={'auto'}>
          <Input
            style={{ backgroundColor: '#F8F9FB', flex: 'auto' }}
            size="large"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="搜索视图"
            bordered={false}
            prefix={
              <i className="iconfont iconsearch" style={{ fontSize: 16, color: '#BFC6D2' }} />
            }
          />
        </Col>
      </Row>
      <PerfectScrollbar className={styles.viewList}>
        {showViewList.length ? (
          showViewList.map((x, i) => (
            <div className={styles.viewItem} key={i} onClick={() => onViewClick(x)}>
              <div
                className={styles.viewItemIcon}
                style={{ backgroundColor: getViewIcon(x.view_type).color + '30' }}
              >
                <i
                  className={getViewIcon(x.view_type).icon}
                  style={{
                    fontSize: 12,
                    color: getViewIcon(x.view_type).color,
                  }}
                />
              </div>
              <div className={cls(styles.viewItemName, 'text-omit')}>{x.view_name}</div>
              <div className={cls(styles.viewItemPath, 'text-omit')}>{getViewPath(x.node_id)}</div>
            </div>
          ))
        ) : (
          <div className={styles.viewListPlaceholder}>暂无可选视图</div>
        )}
      </PerfectScrollbar>
    </div>
  );
};

export default ViewList;
