import type { ViewList } from '@linkpi/core';
import { Space } from 'antd';
import cls from 'classnames';
import type { FC } from 'react';
import { match } from 'ts-pattern';

export const ViewTypeSwitch: FC<{
  type?: 'icon' | 'default';
  viewType: ViewList.ViewType;
  onChangeViewType: (type: 1 | 2) => void;
}> = ({ viewType: _viewType, onChangeViewType, type = 'default' }) => {
  return match(_viewType)
    .with(1, 2, (viewType) =>
      match(type)
        .with('icon', () => (
          <div className="switch">
            <div
              onClick={() => onChangeViewType(1)}
              className={cls('switch-item', viewType === 1 ? 'active' : '')}
            >
              <i className="iconfont iconView_kanban" />
            </div>
            <div
              onClick={() => onChangeViewType(2)}
              className={cls('switch-item', viewType === 2 ? 'active' : '')}
            >
              <i className="iconfont iconView_table" />
            </div>
          </div>
        ))
        .with('default', () => (
          <Space onClick={() => onChangeViewType(([2, 1] as const)[viewType - 1])}>
            {match(viewType)
              .with(1, () => <i className="iconfont iconView_table" />)
              .with(2, () => <i className="iconfont iconView_kanban" />)
              .exhaustive()}
            <span>切换至{['表格', '看板'][viewType - 1]}</span>
          </Space>
        ))
        .exhaustive(),
    )
    .otherwise(() => null);
};
