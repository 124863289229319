import { Popover } from 'antd';
import type { FC } from 'react';
import React from 'react';

type IProps = {
  showPopover: boolean;
  content: string;
};

const SimplePopover: FC<React.PropsWithChildren<IProps>> = (props) => {
  const { children, showPopover, content } = props;
  if (!showPopover) return children;

  return (
    <Popover content={content} title="" placement="top">
      {children}
    </Popover>
  );
};
export default SimplePopover;
