import type { CurrentUser } from '@linkpi/core';
import type { OrgGroups } from '@linkpi/core';
import type { OrgUser } from '@linkpi/core';
import { CURRENCIES, DEFAULT_AVATAR, EXTERNAL_OWNER } from '@linkpi/core';
import { displayCascadeValue, getTempPropEnumOption, propIsNull, textSort } from '@linkpi/core';
import { tempValueDisplay } from '@linkpi/core';
import { getDefaultTempProp, getQuoteOriginProp } from '@linkpi/core';
import { generateGroupMap, handleStatusUserProp } from '@linkpi/core';
import { toRecord } from '@linkpi/utils';
import type { FormInstance, FormItemProps, InputNumberProps, SelectProps } from 'antd';
import { Avatar, DatePicker, Form, Input, InputNumber, Select, Tag } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import { isNil } from 'ramda';
import type { ReactElement } from 'react';

import logo from '@/assets/logo.svg';
import { RegularIcon } from '@/components';
import AddressInput from '@/components/AddressInput';
import CustomDate from '@/components/CustomDate';
import { SapceUserSelect } from '@/components/EnhanceForm';
import { findUser, getAttachmentValue, getFileTypeIconByUrl, uniqueArr } from '@/utils/utils';

const { Option } = Select;
const locale = 'en-us';
// 获取用户头像
export const getUserAvatar = (
  userId: string,
  orgId: string,
  spaceUserList: OrgUser.OrgUserItem[],
  size = 16,
) => {
  const user = findUser(userId, orgId, spaceUserList);
  if (!user)
    return userId === EXTERNAL_OWNER ? (
      <Avatar
        size={16}
        style={{ color: '#666D7C', background: 'none' }}
        icon={<i className={'iconfont iconconfirming'} style={{ fontSize: 16 }} />}
        alt={'待认领'}
      />
    ) : (
      ''
    );
  return <Avatar size={size} key={0} src={user.avatar ? user.avatar : DEFAULT_AVATAR} />;
};
// 获取模板任务参与者头像
export const getTemplateParticipant = (
  participant: string | string[],
  orgId: string,
  spaceUserList: OrgUser.OrgUserItem[],
  size = 16,
) => {
  return Array.isArray(participant) && participant.length > 0 ? (
    <Avatar.Group size={size} maxCount={3} style={{ verticalAlign: 'middle' }}>
      {participant.map((userId) => {
        return getUserAvatar(userId, orgId, spaceUserList, size);
      })}
    </Avatar.Group>
  ) : typeof participant === 'string' ? (
    getUserAvatar(participant, orgId, spaceUserList, size)
  ) : (
    <></>
  );
};
// 获取用户的名称
export const fetchUserName = (userId: string, userMap: Record<string, OrgUser.OrgUserItem>) => {
  if (!userMap) return '';
  const user = userMap[userId];
  if (!user) return userId === 'CC4123F2258411EB9A4A7CD30AB8A430' ? '连接派' : '未知用户';
  return user?.nick_name;
};

export const currencyFormatter = (extend?: { type: string }) => (value: number) => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: extend?.type || 'CNY',
  }).format(value);
};
export const currencyParser = (extend: { type: string; precision: number }) => (val: string) => {
  try {
    // for when the input gets clears
    if (typeof val === 'string' && !val.length) {
      val = '0.0';
    }
    // detecting and parsing between comma and dot
    const group = new Intl.NumberFormat(locale).format(1111).replace(/1/g, '');
    const decimal = new Intl.NumberFormat(locale).format(1.1).replace(/1/g, '');
    let reversedVal = val.replace(new RegExp('\\' + group, 'g'), '');
    reversedVal = reversedVal.replace(new RegExp('\\' + decimal, 'g'), '.');
    //  => 1232.21 €
    // removing everything except the digits and dot
    reversedVal = reversedVal.replace(/[^0-9.]/g, '');
    //  => 1232.21
    // appending digits properly
    const digitsAfterDecimalCount = (reversedVal.split('.')[1] || []).length;
    const needsDigitsAppended = digitsAfterDecimalCount > extend?.precision;
    if (needsDigitsAppended) {
      reversedVal =
        reversedVal.split('.')[0] +
        (extend?.precision === 0
          ? ''
          : '.' + reversedVal.split('.')[1].substring(0, extend?.precision));
    }
    return Number.isNaN(reversedVal) ? 0 : reversedVal;
  } catch (error) {
    console.error(error);
  }
};

export const validateUsers = (
  _value: string | string[],
  prop: { multiple: boolean },
  userOptions: OrgUser.OrgUserItem[],
) => {
  let value = _value;
  if (prop.multiple) {
    if (Array.isArray(value)) {
      value = value?.filter((id) => !!userOptions?.find((user) => user.account_id === id));
    } else {
      value = userOptions?.find((user) => user.account_id === value) ? [value] : [];
    }
  } else {
    if (Array.isArray(value)) value = value[0];

    if (!userOptions?.find((user) => user.account_id === value)) {
      return null;
    }
  }
  return value;
};

//根据index获得模板普通属性
export const getTempPropItemByIndex = (getTempPropItemByIndexOptions: {
  prop: CurrentUser.TemplateProp;
  index: number;
  currentUser: CurrentUser;
  spaceUsers: OrgUser.OrgUserItem[];
  propValue: any;
  node: PiNode | null;
  quoteStatusProp: any[];
  form: FormInstance | null;
  required: boolean;
  enumOptions: any[];
}) => {
  const {
    prop,
    index,
    currentUser,
    spaceUsers,
    propValue = null,
    node = null,
    quoteStatusProp = [],
    form = null,
    required = false,
    enumOptions = [],
  } = getTempPropItemByIndexOptions;
  //处理条件匹配
  const handleTempPropChange = (e: any, index: number) => {
    if (!form || !quoteStatusProp.length) return;
    if (!node) return;

    const propData = node.prop._sys_temp[1],
      changedFields = [index];
    propData[index] = e;

    const fields: Record<string, any> = {};
    quoteStatusProp.map((x) => {
      if (~changedFields.indexOf(x.propIndex)) {
        fields[x.fieldName] = Array.isArray(propData[x.propIndex])
          ? x.multiple
            ? propData[x.propIndex]
            : propData[x.propIndex][0]
          : x.multiple
            ? [propData[x.propIndex]]
            : propData[x.propIndex];
      }
    });
    if (Object.keys(fields).length) {
      form.setFieldsValue(fields);
    }
  };

  if (!prop?.type) return null;

  let item;

  const itemName = 'tempProp' + index;
  const option: SelectProps = {
      placeholder: required ? prop.name + '（必填）' : prop.name,
      bordered: true,
    },
    formOption: FormItemProps = { label: prop.name };
  if (propValue)
    formOption.initialValue = prop.type.startsWith('date') ? moment(propValue) : propValue;
  // @ts-ignore
  formOption.key = itemName;

  if (prop.multiple) option.mode = 'multiple';

  switch (prop.type) {
    case 'tag':
    case 'enum':
      const extend: {
        label: string;
        value: string;
        aux?: {
          label: string;
          position: 0 | 1;
        };
      }[] = (prop.extend || []).map((x: string) => ({
        label: x,
        value: x,
      }));
      if (prop.conditionMatching && Array.isArray(enumOptions) && enumOptions.length) {
        enumOptions.forEach((x) => {
          if (!prop.extend?.includes(x.label) && !~extend.findIndex((y) => y.label === x.label)) {
            extend.push(x);
          }
        });
      }
      if (prop.type === 'tag') option.mode = 'tags';
      if (!!prop.defaultValue && !formOption.initialValue) {
        formOption.initialValue = prop.multiple ? [prop.defaultValue] : prop.defaultValue;
      }
      item = (
        <Form.Item
          {...formOption}
          name={itemName}
          colon={false}
          rules={[
            {
              required: required && extend.length > 0,
              message: '请设置' + prop.name,
            },
          ]}
        >
          <Select
            {...option}
            allowClear={true}
            showSearch={true}
            optionFilterProp={'label'}
            onChange={(e) => handleTempPropChange(e, index)}
            optionLabelProp="name"
          >
            {extend.map((x, index) => (
              <Option
                key={index}
                value={x.value}
                name={x.value}
                label={x.label + (x.aux?.label ? '-' + x.aux.label : '')}
                children={
                  x.aux ? (
                    x.aux.position ? (
                      <>
                        <p style={{ color: 'rgb(185, 187, 188)' }}>{x.aux.label}</p>
                        <p>{x.label}</p>
                      </>
                    ) : (
                      <>
                        <p>{x.label}</p>
                        <p style={{ color: 'rgb(185, 187, 188)' }}>{x.aux.label}</p>
                      </>
                    )
                  ) : (
                    x.label
                  )
                }
              />
            ))}
          </Select>
        </Form.Item>
      );
      break;
    case 'user':
      let users = spaceUsers;
      if (prop.extend?.length && !~prop.extend.indexOf('-1')) {
        users = spaceUsers.filter(
          (user) =>
            Array.isArray(user.group_ids) &&
            !!user.group_ids.find((id) => ~prop.extend.indexOf(id)),
        );
      }
      if (!!prop.defaultValue && !formOption.initialValue) {
        let defaultUserId: any;
        if (prop.defaultValue === 'creator') {
          defaultUserId = node?.prop?._sys_creator || currentUser.userid;
        } else if (prop.defaultValue === 'updater') {
          defaultUserId = currentUser.userid;
        } else {
          defaultUserId = prop.defaultValue;
        }
        if (!!defaultUserId && !!users.find((user) => user.account_id === defaultUserId)) {
          formOption.initialValue = prop.multiple ? [defaultUserId] : defaultUserId;
        }
      }
      item = (
        <Form.Item
          name={itemName}
          rules={[{ required: required && users.length > 0, message: '请设置' + prop.name }]}
          {...formOption}
        >
          <Select
            {...option}
            allowClear={true}
            showSearch={true}
            filterOption={(input, optionItem) => {
              return (optionItem?.label as string).toLowerCase().indexOf(input.toLowerCase()) >= 0;
            }}
            onChange={(e) => handleTempPropChange(e, index)}
            options={users.map((x) => ({
              label: x.nick_name,
              value: x.account_id,
            }))}
          />
        </Form.Item>
      );
      break;
    case 'datetime':
    case 'date':
      // TODO: 支持日期属性 defaultFormula
      if (!!prop.defaultValue && !formOption.initialValue) {
        formOption.initialValue = moment();
        if (prop.defaultValue === 'createTime' && !!node?.prop?._sys_createTime) {
          formOption.initialValue = moment(node.prop?._sys_createTime * 1000);
        }
      }
      item = (
        <Form.Item
          name={itemName}
          rules={[{ required: required, message: '请设置' + prop.name }]}
          {...formOption}
        >
          <DatePicker
            format={prop.type === 'date' ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm'}
            showTime
            showToday
            placeholder={required ? prop.name + '（必填）' : prop.name}
            onChange={(e) => handleTempPropChange(e?.valueOf(), index)}
          />
        </Form.Item>
      );
      break;
    case 'currency':
    case 'number': {
      // TODO: 支持日期属性 defaultFormula
      const numberInputOption = option as InputNumberProps;
      if (prop.number?.upperLimit !== null && prop.number?.upperLimit !== undefined)
        numberInputOption.max = prop.number?.upperLimit;
      if (prop.number?.lowerLimit !== null && prop.number?.lowerLimit !== undefined)
        numberInputOption.min = prop.number?.lowerLimit;
      if (prop.number?.unit) {
        numberInputOption.addonAfter = <div>{prop.number.unit}</div>;
      }
      item = (
        <Form.Item
          name={itemName}
          {...formOption}
          rules={[{ required: required, message: '请输入' + prop.name }]}
        >
          <InputNumber
            {...numberInputOption}
            precision={prop.number?.precision}
            onChange={(e) => handleTempPropChange(e, index)}
          />
        </Form.Item>
      );
      break;
    }
    case 'attachment':
      item = {
        name: itemName,
        formOption,
        rules: [{ required: required, message: '请输入' + prop.name }],
        attachment: true,
        node,
        index,
      };
      break;
    case 'cascade':
      item = {
        name: itemName,
        formOption,
        rules: [{ required: required, message: '请输入' + prop.name }],
        cascade: true,
        node,
        index,
      };
      break;

    case 'auto_inc':
    case 'formula':
    case 'quote':
      break;

    default:
      item = (
        <Form.Item
          name={itemName}
          {...formOption}
          rules={[{ required: required, message: '请输入' + prop.name }]}
        >
          <Input
            placeholder={required ? prop.name + '（必填）' : prop.name}
            onChange={(e) => handleTempPropChange(e.target.value, index)}
          />
        </Form.Item>
      );
  }
  return item;
};

//模板属性Form.Item
/**
 * @define GetTempPropItemsProps
 * @property {*} currentUser
 *
 * @param {GetTempPropItemsProps} param0
 * @returns
 */
export const getTempPropItems = async (getTempPropItemsOption: {
  form: FormInstance;
  template: CurrentUser.TemplateInfo; //当前模板
  currentUser: CurrentUser;
  spaceUsers: OrgUser.OrgUserItem[];
  spaceUsersMap: Record<string, OrgUser.OrgUserItem>;
  node: PiNode | null;
  hasLabel: boolean;
  viewCondition: any[]; //将视图过滤条件设为默认值
  status: number; //当前状态
  tagExtends: any[];
  departmentNodes: OrgGroups.OrgDepartmentNodeInfo[];
}) => {
  const {
    form,
    template, //当前模板
    currentUser,
    spaceUsers,
    spaceUsersMap,
    node = null,
    hasLabel = true,
    viewCondition = null, //将视图过滤条件设为默认值
    status = 0, //当前状态
    tagExtends = [],
    departmentNodes = [],
  } = getTempPropItemsOption;

  let propItems: {
      index: number;
      item: ReactElement<
        FormItemProps & {
          children: ReactElement<{ disabled: boolean }>;
        }
      >;
      required: boolean;
    }[] = [],
    itemCount = 0,
    itemIndex = 0;

  let matchingFields: Record<string, any> = {};
  const tagRender = (props: any) => {
    const { label, onClose } = props;
    return (
      <Tag
        closable={false}
        onClose={onClose}
        style={{ marginRight: 2, border: 'none', fontSize: 14, lineHeight: '26px' }}
      >
        {label};
      </Tag>
    );
  };

  const statusProps =
    Array.isArray(template.task_status) && Array.isArray(template.task_status[status]?.prop)
      ? template.task_status[status].prop
      : [];
  const handleMatchingFields = (
    matchingProps: unknown,
    tempValue: any,
    index: number,
    formValues: Record<string, any> = {},
    changedFields: number[] = [],
  ) => {
    if (!changedFields.length) {
      changedFields.push(index);
    }
    const propData = template.prop.map((x, i) => {
      const fieldName = 'tempProp' + i;
      if (fieldName in formValues) {
        return formValues[fieldName];
      }
      return null;
    });
    if (~index) propData[index] = tempValue;
    /**
     * 22/11/23 前端不再处理条件匹配相关
     */
    /*[propData, changedFields] = conditionMatchPropsData({
      handleDataIndex: index, // 当前操作的属性index
      propData, // 当前的属性数据
      userMap: spaceUsersMap, // 用户信息map { userid:{ nick_name:xxxx } }
      template, // 当前模板
      templateList: [], // 空间模板列表
      matchConfig: matchingProps, // 可选，已经通过getConditionMatchProps匹配的数据
      creator: currentUser.userid, //创建者
      title: node?.title || '',
      changedFields,
      currentUserId: currentUser.userid,
    });*/
    changedFields.shift();
    if (changedFields.length) {
      for (let i = 0; i < changedFields.length; i++) {
        const fieldName = 'tempProp' + changedFields[i];
        formValues[fieldName] = propData[changedFields[i]];
      }
    }
    return [formValues, changedFields];
  };

  //处理条件匹配
  const handleTempPropChange = (matchingProps: any[], e: any, index: number) => {
    if (!matchingProps.find((x) => ~x.matchingIndex.indexOf(index))) return;
    const [fields, changedFields] = handleMatchingFields(
      matchingProps,
      e,
      index,
      form.getFieldsValue(),
    );
    if (Object.keys(fields)?.length) {
      form.setFieldsValue(fields);
    }
  };

  const matchingProps: any[] = [],
    defaultProp: number[] = [];

  if (Array.isArray(template.prop) && template.prop) {
    const defaultTempProp = getDefaultTempProp(
      {},
      template,
      currentUser,
      null,
      spaceUsersMap,
      departmentNodes,
    );

    const tempProp = Array.isArray(defaultTempProp?._sys_temp?.[1])
      ? defaultTempProp._sys_temp[1]
      : [];

    // 非必填项 也要把默认值填进去
    tempProp.map((x, index) => {
      if (x !== null) {
        const itemName = 'tempProp' + index;
        matchingFields[itemName] = x;
      }
    });
    template.prop.map((prop, index) => {
      if (prop?.type) {
        let item;
        const itemName = 'tempProp' + index;
        const statusIndex = statusProps.findIndex(
          (sp) => sp.type === 'quote' && sp.quoteProp === 'prop' + index,
        );
        if (prop.required || ~statusIndex) itemCount++;
        const option: SelectProps = {
            placeholder: prop.required ? prop.name + '（必填）' : prop.name,
            bordered: hasLabel,
          },
          formOption: FormItemProps = hasLabel
            ? { label: prop.name, hidden: !prop.required && !~statusIndex }
            : { hidden: (!prop.required && !~statusIndex) || prop.type === 'quote' };
        // @ts-ignore
        formOption.key = itemName;
        if (prop.multiple) option.mode = 'multiple';
        //从视图创建模板，设定默认值
        const condition = viewCondition?.find(
          (c) => c.key === 'templateProp_' + template.template_id + '_' + index,
        );
        if (condition) {
          formOption.initialValue = prop.multiple
            ? Array.isArray(condition.value)
              ? condition.value
              : [condition.value]
            : Array.isArray(condition.value)
              ? condition.value[0]
              : condition.value;
          if (!matchingFields[itemName]) {
            matchingFields[itemName] = formOption.initialValue;
          }
        }
        switch (prop.type) {
          case 'tag':
          case 'enum': {
            let enumOptions: any[] = prop.extend || [];
            if (prop.conditionMatching || prop.enumQuote) {
              // enumOptions = [];
              const matchingProp = matchingProps.find((x) => x.index === index);
              if (matchingProp) {
                enumOptions.push.apply(
                  enumOptions,
                  getTempPropEnumOption({
                    propData: Array.isArray(node?.prop?._sys_temp?.[1])
                      ? node?.prop._sys_temp[1]
                      : tempProp,
                    dataIndex: index,
                    dataType: prop.type,
                    matchConfig: matchingProps,
                    creator: currentUser.userid,
                    title: node?.title || '',
                  }),
                );
              }
            }
            const tagExtend = Array.isArray(tagExtends[index]) ? tagExtends[index] : [];
            enumOptions = textSort(uniqueArr([...enumOptions, ...tagExtend]).filter((to) => !!to));
            if (prop.type === 'tag') option.mode = 'tags';
            if (!hasLabel) {
              option.suffixIcon = <RegularIcon size={14} type="iconmultiplechoice" />;
              if (prop.multiple) {
                option.tagRender = tagRender;
              }
            }
            if (!!prop.defaultValue && !formOption.initialValue && !matchingFields[itemName]) {
              formOption.initialValue = prop.multiple ? [prop.defaultValue] : prop.defaultValue;
              defaultProp.push(index);
            }

            item = (
              <Form.Item
                {...formOption}
                name={itemName}
                colon={false}
                rules={[
                  {
                    required: prop.required && enumOptions.length > 0,
                    message: '请设置' + prop.name,
                  },
                ]}
              >
                <Select
                  {...option}
                  allowClear={true}
                  showSearch={true}
                  filterOption={(input, option) =>
                    option?.props?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(e) => handleTempPropChange(matchingProps, e, index)}
                >
                  {enumOptions.map((x, index) => {
                    return (
                      <Option key={index} value={x}>
                        {x}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            );
            break;
          }
          case 'user': {
            let users = spaceUsers;
            if (prop.extend?.length && !~prop.extend.indexOf('-1')) {
              users = spaceUsers.filter(
                (user) =>
                  Array.isArray(user.group_ids) &&
                  !!user.group_ids.find((id) => ~prop.extend.indexOf(id)),
              );
            }
            if (!!prop.defaultValue && !formOption.initialValue && !matchingFields[itemName]) {
              let defaultUserId: string | number;
              if (prop.defaultValue === 'creator') {
                defaultUserId = node?.prop?._sys_creator || currentUser.userid;
              } else if (prop.defaultValue === 'updater') {
                defaultUserId = currentUser.userid;
              } else {
                defaultUserId = prop.defaultValue;
              }
              if (!!defaultUserId && !!users.find((user) => user.account_id === defaultUserId)) {
                formOption.initialValue = prop.multiple ? [defaultUserId] : defaultUserId;
                defaultProp.push(index);
                // if (!matchingFields[itemName]) {
                //   matchingFields[itemName] = formOption.initialValue;
                // }
              }
            }
            if (!hasLabel) {
              option.suffixIcon = <RegularIcon size={14} type="iconstaff" />;
              if (prop.multiple) {
                option.tagRender = tagRender;
              }
            }
            item = (
              <Form.Item
                name={itemName}
                rules={[
                  { required: prop.required && users.length > 0, message: '请设置' + prop.name },
                ]}
                {...formOption}
              >
                <SapceUserSelect
                  {...option}
                  allowClear={true}
                  showSearch={true}
                  onChange={(e) => handleTempPropChange(matchingProps, e, index)}
                />
              </Form.Item>
            );
            break;
          }
          case 'datetime':
          case 'date': {
            if (prop.defaultValue) {
              formOption.initialValue = moment();
              if (prop.defaultValue === 'createTime' && !!node?.prop?._sys_createTime) {
                formOption.initialValue = moment(node.prop?._sys_createTime * 1000);
              }
              defaultProp.push(index);
            }
            const dateOptions = hasLabel
              ? {}
              : { suffixIcon: <RegularIcon size={14} type="iconTime" /> };
            item = (
              <Form.Item
                name={itemName}
                rules={[{ required: prop.required, message: '请设置' + prop.name }]}
                {...formOption}
              >
                <DatePicker
                  format={prop.type === 'date' ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm'}
                  showTime
                  showToday
                  placeholder={prop.required ? prop.name + '（必填）' : prop.name}
                  bordered={hasLabel}
                  {...dateOptions}
                  onChange={(e) => handleTempPropChange(matchingProps, e?.valueOf(), index)}
                />
              </Form.Item>
            );
            break;
          }
          case 'currency':
          case 'number': {
            const inputNumberOption = option as InputNumberProps;
            if (prop.number?.upperLimit !== null && prop.number?.upperLimit !== undefined)
              inputNumberOption.max = prop.number?.upperLimit;
            if (prop.number?.lowerLimit !== null && prop.number?.lowerLimit !== undefined)
              inputNumberOption.min = prop.number?.lowerLimit;
            if (prop.number?.unit) {
              inputNumberOption.addonAfter = <div>{prop.number.unit}</div>;
            }
            item = (
              <Form.Item
                name={itemName}
                {...formOption}
                rules={[{ required: prop.required, message: '请输入' + prop.name }]}
              >
                <InputNumber
                  {...inputNumberOption}
                  precision={prop.number?.precision}
                  onChange={(e) => handleTempPropChange(matchingProps, e, index)}
                />
              </Form.Item>
            );
            break;
          }
          case 'address':
            item = (
              <Form.Item
                name={itemName}
                {...formOption}
                rules={[{ required: prop.required, message: '请输入' + prop.name }]}
                valuePropName="address"
                trigger="onConfirm"
                normalize={(_address) => [_address]}
                // initialValue={[]}
              >
                <AddressInput
                  getPopupContainer={false}
                  prop={prop}
                  onConfirm={(e, cb) => {
                    handleTempPropChange(matchingProps, e, index);
                    cb?.();
                  }}
                  onCancel={(cb) => {
                    cb?.();
                  }}
                  autoOpen={false}
                  style={{
                    lineHeight: '30px',
                    border: '1px solid #EBEDF0',
                    borderRadius: 8,
                  }}
                />
              </Form.Item>
            );
            break;
          default:
            item = (
              <Form.Item
                name={itemName}
                {...formOption}
                rules={[{ required: prop.required, message: '请输入' + prop.name }]}
              >
                <Input
                  placeholder={prop.required ? prop.name + '（必填）' : prop.name}
                  bordered={hasLabel}
                  onChange={(e) => handleTempPropChange(matchingProps, e.target.value, index)}
                />
              </Form.Item>
            );
        }

        if (formOption.initialValue?.length || Number.isFinite(formOption.initialValue)) {
          if (!matchingFields[itemName]) {
            matchingFields[itemName] = formOption.initialValue;
          }
          if (
            !!matchingProps?.find((x) => ~x.matchingIndex.indexOf(index)) &&
            (prop.type === 'user' || prop.type === 'enum' || prop.type === 'text')
          ) {
            //目前支持人员属性、枚举属性、文本属性、引用属性的匹配
            let changedFields;
            [matchingFields, changedFields] = handleMatchingFields(
              matchingProps,
              formOption.initialValue,
              index,
              matchingFields,
            );
            if (changedFields.length && defaultProp.length) {
              changedFields.map((x: unknown) => {
                const changeIndex = defaultProp.findIndex((y) => y === x);
                if (~changeIndex) {
                  defaultProp.splice(changeIndex, 1);
                }
              });
            }
          }
        }
        propItems.push({ index: index, item: item, required: prop.required || !!~statusIndex });
      }
    });
  }

  if (propItems.length > 0) {
    [matchingFields] = handleMatchingFields(matchingProps, currentUser.userid, -1, matchingFields);

    propItems = propItems.sort((a, b) => a.index - b.index);
    if (itemCount) {
      itemIndex = propItems.findIndex((x) => x.required) || 0;
    }
  }

  return [propItems.map((x) => x.item), matchingFields, itemCount, itemIndex, defaultProp] as const;
};

//模板初始状态任务属性Form.Item
export const getTaskPropItems = (
  template: CurrentUser.TemplateInfo,
  currentUser: CurrentUser,
  spaceUsers: OrgUser.OrgUserItem[],
  spaceUsersMap: Record<string, OrgUser.OrgUserItem>,
  startTime: number | null | undefined,
  endTime: number | null | undefined,
  node: PiNode | null = null,
  hasLabel = true,
  statusIndex = 0,
  viewCondition = null,
  matchingFields = {},
) => {
  let statusItems: ReturnType<typeof getStatusItems> = [];
  if (
    !isNil(node) &&
    Array.isArray(template.task_status) &&
    !template.task_status[statusIndex]?.delete &&
    Array.isArray(template.task_status[statusIndex]?.prop) &&
    template.task_status[statusIndex].prop.length
  ) {
    statusItems = getStatusItems(
      template.org_id,
      template.task_status[statusIndex],
      spaceUsers,
      null,
      null,
      null,
      node,
      currentUser,
      hasLabel,
      viewCondition,
      template.template_id,
      null,
      false,
      undefined,
      undefined,
      undefined,
      undefined,
      template,
    );
  }
  return statusItems;
};
//模板状态任务属性Form.Item
/**
 *
 * @param {string} spaceId
 * @param {import('@linkpi/core').CurrentUser.TemplateInfo['task_status'][number]} status
 * @param {import('@linkpi/core').OrgUser[]} spaceUsers
 * @param {*} spaceUsersMap
 * @param {*} startTime
 * @param {*} endTime
 * @param {PiNode} node
 * @param {import('@linkpi/core').CurrentUser} currentUser
 * @param {*} hasLabel
 * @param {*} viewCondition
 * @param {string} template_id
 * @param {*} matchingFields
 * @param {boolean} isModify
 * @param {*} tempProp
 * @param {*} matchConfig
 * @param {*} tagExtends
 * @param {*} form
 * @param {*} template
 * @param {*} historyStatus
 * @param {*} statusIndex
 * @param {*} enumOptions
 * @returns
 */
export const getStatusItems = (
  spaceId: undefined | string,
  status: CurrentUser.taskStatus,
  spaceUsers: OrgUser.OrgUserItem[],
  spaceUsersMap = null,
  startTime = null,
  endTime = null,
  node: PiNode,
  currentUser: CurrentUser,
  hasLabel = true,
  viewCondition: null | any[] = null,
  template_id: string | null = null,
  matchingFields = null,
  isModify = false,
  tempProp: CurrentUser.TemplateProp[] = [], // 模板属性
  matchConfig = null,
  tagExtends = null,
  form: FormInstance | null = null,
  template: CurrentUser.TemplateInfo | any = {},
  historyStatus: {
    status: number;
    task_status: (CurrentUser.taskStatus & {
      value: unknown;
    })[];
  }[] = [],
  statusIndex = 0,
  enumOptions: Record<string, unknown[]> = {},
  departmentNodes: any = [],
) => {
  if (!status || status?.delete) return [];
  const quoteProp: { multiple: boolean; fieldName: string; propIndex: number }[] = [];
  status.prop.map((x, index) => {
    if (x?.defaultValue?.startsWith?.('prop')) {
      quoteProp.push({
        propIndex: Number(x.defaultValue.slice(4)),
        fieldName: 'prop' + index,
        multiple: x.multiple,
      });
    }
  });
  const tagRender = (props: any) => {
    const { label, onClose } = props;
    return (
      <Tag
        closable={false}
        onClose={onClose}
        style={{ marginRight: 2, border: 'none', fontSize: 14, lineHeight: '26px' }}
      >
        {label};
      </Tag>
    );
  };
  const lastStatus = historyStatus.find((x) => x.status === statusIndex);

  const statusItems = status.prop?.map((_prop, index) => {
    const prop = { ..._prop };

    // QUESTION index 为什么会没有
    // @ts-ignore
    if (!('index' in prop)) prop.index = index;

    if (prop.display) {
      let item:
        | ReactElement<
            FormItemProps & {
              children: ReactElement<{ disabled: boolean }>;
            }
          >
        | undefined = undefined;
      const itemName = 'prop' + index;
      const option: SelectProps = {
          placeholder: prop.required ? prop.name + '（必填）' : prop.name,
          bordered: hasLabel,
          disabled: !!prop.readOnly,
        },
        formOption: FormItemProps = hasLabel ? { label: prop.name } : {};
      if (prop.multiple) option.mode = 'multiple';
      if (viewCondition && template_id) {
        const conditionKey =
          index < 4
            ? 'templateStatusPropBase_' + index
            : 'templateStatusPropCustom_' + template_id + '_' + status + '_' + index;
        const condition = viewCondition?.find((condition) => condition.key === conditionKey);
        if (condition) {
          formOption.initialValue = prop.multiple
            ? Array.isArray(condition.value)
              ? condition.value
              : [condition.value]
            : Array.isArray(condition.value)
              ? condition.value[0]
              : condition.value;
        }
      }
      if (isModify) {
        // 修改状态属性
        if (
          Array.isArray(node?.prop?._sys_task_status?.prop) &&
          node.prop._sys_task_status.prop[index] !== null
        ) {
          formOption.initialValue = prop.type.startsWith('date')
            ? moment(node.prop._sys_task_status.prop[index])
            : node.prop._sys_task_status.prop[index];
        }
      } else if (
        lastStatus &&
        'value' in (lastStatus.task_status?.[index] || {}) &&
        !propIsNull(lastStatus.task_status[index].value) &&
        prop.type === 'user'
      ) {
        const lastValue: any = Array.isArray(lastStatus!.task_status[index].value!)
          ? lastStatus!.task_status[index].value!
          : [lastStatus!.task_status[index].value!];
        formOption.initialValue = prop.multiple ? lastValue : lastValue[0];
      }
      switch (prop.type) {
        case 'user': {
          let users = spaceUsers;
          // @ts-ignore
          option.groups = prop.extend;

          if (Array.isArray(prop.extend) && prop.extend.length && !~prop.extend.indexOf('-1')) {
            users = spaceUsers.filter(
              (user) =>
                Array.isArray(user.group_ids) &&
                !!user.group_ids.find((id) => ~prop.extend!.indexOf(id)),
            );
          }
          const lastVotingProp = node.prop._sys_voting_prop?.[statusIndex];
          if (lastVotingProp) {
            if (prop.index === 0) {
              formOption.initialValue = lastVotingProp.owner;
            }
            if (prop.index === 1) {
              formOption.initialValue = lastVotingProp.user;
            }
          }

          if (prop.defaultValue) {
            if (!formOption.initialValue && template) {
              const userMap = toRecord((user: OrgUser.OrgUserItem) => ({
                [user.account_id]: user,
              }))(spaceUsers);

              const groupMap = generateGroupMap(userMap);

              //最近一次的该状态的参与者/负责人 优先级高于 除状态负责人外的其他默认值
              formOption.initialValue = handleStatusUserProp(
                template,
                {
                  ...prop,
                  historyStatusInfo: historyStatus.map((status) => ({
                    s: status.status,
                    p: status.task_status?.map((i) => i.value) || [],
                  })),
                },
                node.prop._sys_temp[1],
                node.prop._sys_creator,
                node.prop._sys_task_status?.prop[1] || [],
                currentUser.userid,
                groupMap,
                userMap,
                departmentNodes,
              );
            }
          }

          if (formOption.initialValue) {
            formOption.initialValue = validateUsers(formOption.initialValue, prop, users);
          }
          if (!hasLabel) {
            option.suffixIcon = <RegularIcon size={14} type="iconstaff" />;
            if (prop.multiple) {
              option.tagRender = tagRender;
            }
          }
          item = (
            <Form.Item
              key={index}
              name={itemName}
              rules={[
                {
                  // 如果是只读状态属性，强制取消必填设置
                  required: prop.required && !prop.readOnly && users.length > 0,
                  message: '请设置' + prop.name,
                },
              ]}
              {...formOption}
            >
              <SapceUserSelect {...option} allowClear={true} showSearch={true} />
            </Form.Item>
          );
          break;
        }
        case 'datetime': {
          const lastVotingProp = node.prop._sys_voting_prop?.[statusIndex];
          if (lastVotingProp) {
            if (prop.index === 2) {
              formOption.initialValue = moment(lastVotingProp.startTime);
            }
            if (prop.index === 3) {
              formOption.initialValue = moment(lastVotingProp.endTime);
            }
          }
          if (!isModify) {
            if (!formOption.initialValue && prop.defaultValue) {
              formOption.initialValue = moment();
              switch (prop.defaultValue) {
                case 'createTime':
                  if (node?.prop?._sys_createTime)
                    formOption.initialValue = moment(node.prop._sys_createTime * 1000);
                  break;
                case 'updateTime':
                  break;
                case 'previousStart':
                  if (node?.prop._sys_task_status?.prop?.[2])
                    formOption.initialValue = moment(node.prop._sys_task_status.prop[2]);
                  break;
                case 'previousEnd':
                  if (node?.prop._sys_task_status?.prop?.[3])
                    formOption.initialValue = moment(node.prop._sys_task_status.prop[3]);
                  break;
              }
              if (prop.defaultValue?.startsWith?.('prop')) {
                const propIndex = Number(prop.defaultValue.replace('prop', ''));
                formOption.initialValue = moment(node.prop._sys_temp[1][propIndex]);
              }
            }
          }
          const dateOptions: any = hasLabel
            ? {}
            : { suffixIcon: <RegularIcon size={14} type="iconTime" /> };

          item = (
            <Form.Item
              key={index}
              name={itemName}
              rules={[{ required: prop.required && !prop.readOnly, message: '请设置' + prop.name }]}
              {...formOption}
            >
              <CustomDate
                type={index === 3 ? 'end' : 'start'}
                isDate={false}
                inputReadOnly={true}
                allowClear={index === 2 ? false : !prop.required}
                showNow={false}
                placeholder={prop.required ? prop.name + '（必填）' : prop.name}
                bordered={hasLabel}
                disabled={option.disabled}
                {...dateOptions}
              />
            </Form.Item>
          );
          break;
        }
        case 'quote':
          if (
            Array.isArray(tempProp) &&
            tempProp.length &&
            typeof prop.quoteProp === 'string' &&
            prop.quoteProp.startsWith('prop')
          ) {
            let quoteIndex = prop.quoteProp.slice(4) as unknown as number;
            if (quoteIndex) {
              quoteIndex = Number(quoteIndex);
              if (Number.isFinite(quoteIndex) && tempProp[quoteIndex]?.type) {
                // @ts-ignore
                item = getTempPropItemByIndex({
                  prop: tempProp[quoteIndex],
                  index: quoteIndex,
                  currentUser,
                  spaceUsers,
                  propValue:
                    Array.isArray(node?.prop?._sys_temp) && Array.isArray(node.prop._sys_temp[1])
                      ? node.prop._sys_temp[1][quoteIndex]
                      : null,
                  node,
                  quoteStatusProp: quoteProp,
                  form,
                  required: prop.required && !prop.readOnly,
                  enumOptions: enumOptions?.[quoteIndex] || [],
                });
                index = index - status.prop.length;
              }
            }
          }
          break;
        default:
          const lastVotingProp = node.prop._sys_voting_prop?.[statusIndex];
          if (lastVotingProp) {
            if (prop.index === 4) {
              formOption.initialValue = lastVotingProp.statusCommit;
            }
          }
          item = (
            <Form.Item
              key={index}
              name={itemName}
              {...formOption}
              rules={[{ required: prop.required && !prop.readOnly, message: '请输入' + prop.name }]}
            >
              <Input
                placeholder={prop.required ? prop.name + '（必填）' : prop.name}
                bordered={hasLabel}
              />
            </Form.Item>
          );
      }
      if (!isNil(item)) {
        return { index: index, item: item, itemName };
      }
      return null;
    }
  });
  if (statusItems.length) {
    return [...statusItems]
      .filter((i) => !isNil(i))
      .sort((a, b) => a!.index - b!.index)
      .map((x) => {
        return x!.item;
      });
  }
  return [];
};
export type StatusItemType = ReturnType<typeof getStatusItems>[number];

//应用模板
export const handleApplyTemplate = (
  showTemplate: CurrentUser.TemplateInfo,
  currentUser: CurrentUser,
  node: PiNode,
  selectStatus: number,
  values: Record<string, any>,
) => {
  let _sys_temp = [],
    temp_prop: any[] = [],
    task_status = null,
    status_prop: any[] = [];
  _sys_temp.push(showTemplate.template_id);
  showTemplate.prop.map((prop) => {
    let defaultValue = null;
    if (prop.defaultValue) {
      if (prop.type === 'user') {
        defaultValue = prop.multiple ? [currentUser.userid] : currentUser.userid;
        if (prop.defaultValue === 'creator' && !!node?.prop?._sys_creator) {
          defaultValue = prop.multiple ? [node.prop._sys_creator] : node.prop._sys_creator;
        }
        if (prop.defaultValue !== 'creator' && prop.defaultValue !== 'updater') {
          defaultValue = prop.multiple ? [prop.defaultValue] : prop.defaultValue;
        }
      }
      if (prop.type.startsWith('date')) {
        defaultValue = new Date().getTime();
        if (prop.defaultValue === 'createTime' && !!node?.prop?._sys_createTime) {
          defaultValue = node.prop._sys_createTime * 1000;
        }
      }
    }
    temp_prop.push(defaultValue);
  });
  if (Array.isArray(showTemplate.task_status[selectStatus]?.prop)) {
    showTemplate.task_status[selectStatus].prop.map(() => {
      status_prop.push(null);
    });
  }
  Object.keys(values).map((x) => {
    if (values[x]) {
      if (x.startsWith('prop')) {
        const index: number = Number(x.split('prop')[1]);
        status_prop[index] = showTemplate.task_status[selectStatus]?.prop[index]?.type.startsWith(
          'date',
        )
          ? values[x].valueOf()
          : values[x];
      }
      if (x.startsWith('tempProp')) {
        const index: number = Number(x.split('tempProp')[1]);
        switch (showTemplate.prop[index]?.type) {
          case 'address':
            const _address = values[x]?.[0] ?? {};
            temp_prop[index] = _address.prov
              ? `${_address.prov}${_address.city ?? ''}${_address.dist ?? ''}${
                  _address.street ?? ''
                }${_address.add ?? ''}`
              : null;
            break;
          case 'date':
          case 'datetime':
            temp_prop[index] = values[x].valueOf();
            break;
          default:
            temp_prop[index] = values[x];
            break;
        }
      }
    }
  });
  _sys_temp.push(temp_prop);
  if (Array.isArray(showTemplate.task_status) && showTemplate.task_status.length > 0) {
    task_status = {
      index: selectStatus,
      prop: status_prop,
      sender: currentUser.userid,
      sendTime: new Date().getTime(),
    };
  }
  return [_sys_temp, task_status];
};
// 处理默认值变化
export const handleDefaultChange = (changes = {}, defaultProp = []) => {
  const keys = Object.keys(changes);
  if (keys.length) {
    for (let i = 0; i < keys.length; i++) {
      if (keys[i].startsWith('tempProp')) {
        const index = parseInt(keys[i].split('tempProp')[1]);
        const changeIndex = defaultProp.findIndex((y) => y === index);
        if (~changeIndex) {
          defaultProp.splice(changeIndex, 1);
        }
      }
    }
  }
};
export const checkNameDuplicate = (
  name: string,
  propIndex: number,
  currentTemplate: CurrentUser.TemplateInfo,
) => {
  if (name === '负责人' || name === '参与者' || name === '开始时间' || name === '结束时间')
    return true;
  if (
    Array.isArray(currentTemplate.prop) &&
    currentTemplate.prop.find((prop, index) => index !== propIndex && prop?.name === name)
  )
    return true;
  if (currentTemplate.task_status.length) {
    let task_prop_duplicate = false;
    currentTemplate.task_status.some((status) => {
      if (Array.isArray(status.prop) && status.prop.length > 0) {
        status.prop.some((prop) => {
          if (prop.name === name) {
            task_prop_duplicate = true;
            return true;
          }
        });
        return task_prop_duplicate;
      }
    });
    return task_prop_duplicate;
  }
  return false;
};

//获取模板属性展示值
export const propDisplay = (
  prop: CurrentUser.TemplateProp,
  value: any | any[],
  userMap: Record<string, OrgUser.OrgUserItem> = {},
  tempMap: Record<string, TemplateInfo> = {},
  sysCascade: any,
  propIndex: number,
  node: PiNode,
  departmentMap: Record<string, OrgGroups.OrgDepartmentNodeInfo> = {},
) => {
  if (!prop || propIsNull(value)) return '';
  let display: any = '';
  switch (prop?.type) {
    case 'date':
      return value ? dayjs(Array.isArray(value) ? value[0] : value).format('YYYY/MM/DD') : '';
    case 'datetime':
      return value ? dayjs(Array.isArray(value) ? value[0] : value).format('YYYY/MM/DD HH:mm') : '';
    case 'tag':
    case 'enum':
      if (Array.isArray(value)) {
        return value.join(';');
      } else {
        return value;
      }
    case 'user':
      display = [];
      if (Array.isArray(value)) {
        // if (!avatar) return value.map((x) => userMap[x]?.nick_name || '未知用户').join(';');
        value.map((x) => {
          if (userMap[x]) {
            display.push(userMap[x]);
          }
        });

        if (!display.length) return null;

        if (display.length === 1) {
          return (
            <>
              <Avatar style={{ marginRight: 4 }} src={display[0].avatar || logo} size={24} />
              {display[0].nick_name}
            </>
          );
        } else {
          return (
            <>
              {display.slice(0, 3).map((user: OrgUser.OrgUserItem, i: number) => (
                <Avatar key={i} style={{ marginRight: 4 }} src={user.avatar || logo} size={24} />
              ))}
              {display.length > 3 ? (
                <span style={{ color: '#767C88' }}>+{display.length - 3}</span>
              ) : (
                ''
              )}
            </>
          );
        }
      } else {
        // if (!avatar)
        // return value === EXTERNAL_OWNER ? '待认领' : userMap[value]?.nick_name || '未知用户';
        return value === EXTERNAL_OWNER ? (
          <>
            <Avatar
              size={16}
              style={{ color: '#666D7C', background: 'none', marginRight: 4 }}
              icon={<i className={'iconfont iconconfirming'} style={{ fontSize: 16 }} />}
              alt={'待认领'}
            />
            待认领
          </>
        ) : userMap[value as string] ? (
          <>
            <Avatar
              style={{ marginRight: 4 }}
              src={userMap[value as string].avatar || logo}
              size={24}
            />
            {userMap[value as string].nick_name}
          </>
        ) : null;
      }
    case 'currency':
      let precision = prop.extend?.precision || 2;
      if (prop.number && 'matchingPrecision' in prop.number) {
        precision = prop.number.matchingPrecision;
      }
      const symbol = CURRENCIES.find((x) => x.type === prop.extend?.type)?.symbol || 'CN¥';
      return symbol.concat(Number(value).toFixed(precision));
    case 'number':
      return Number(value)
        .toFixed(prop.number?.precision || 0)
        .concat(prop.number?.unit || '');
    case 'formula':
      return typeof value === 'number'
        ? Number(value)
            .toFixed(prop.number?.precision || 0)
            .concat(prop.number?.unit || '')
        : value;
    case 'quote':
      const originProp = getQuoteOriginProp(prop, tempMap);
      if (
        typeof originProp !== 'string' &&
        originProp &&
        originProp.type === 'attachment' &&
        originProp.matchingType === 0
      ) {
        const res = getAttachmentValue(node, propIndex);
        return {
          type: 'quote-attachment',
          value: res,
        };
      }
      return tempValueDisplay({
        propConfig: prop,
        propValue: value,
        userMap,
        tempMap: tempMap,
        sysCascade,
        propIndex,
        departmentMap,
      });
    case 'attachment':
      if (Array.isArray(value)) {
        // if (avatar) {
        display = [];
        value.forEach((x) => {
          if (x?.src) {
            display.push({ ...getFileTypeIconByUrl(x.src), name: x.name });
          }
        });
        return (
          <>
            {display.slice(0, 2).map((x: any, i: number) => (
              <img
                key={i}
                style={{
                  display: 'inline-block',
                  width: 50,
                  height: 50,
                  backgroundImage: `url(${x.src})`,
                  backgroundSize: 'cover',
                  marginRight: 4,
                }}
                src={x.src}
                title={x.name}
              />
            ))}
            {display.length > 2 ? (
              <span style={{ color: '#767C88' }}>+{display.length - 2}</span>
            ) : (
              ''
            )}
          </>
        );
      }
      return display;
    case 'cascade':
      return displayCascadeValue({
        value: value,
        cascadeNodes: prop.cascade.nodes,
        multiple: prop.multiple,
        hideRoutes: prop.hideRoutes,
      });
    case 'department':
      return tempValueDisplay({
        propConfig: prop,
        propValue: value,
        userMap,
        tempMap: tempMap,
        sysCascade,
        propIndex,
        departmentMap,
      });
    default:
      return value.toString();
  }
};
