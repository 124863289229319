import type { ViewList } from '@linkpi/core';
import { Popover } from 'antd';
import type { FC, PropsWithChildren, ReactNode } from 'react';

import { ConditionFilterPanel } from './ConditionFilterPanel';
import type { ViewConditionFormPropsType } from './types';

export type ConditionFilterPreviewProp = {
  className?: string;
  value: ViewList.ViewconditionV2;
  type?: string;
  editable?: boolean;
  enableRoot?: boolean;
  extraPopcontent?: ReactNode;
} & Pick<
  ViewConditionFormPropsType,
  | 'addText'
  | 'allowTempIds'
  | 'allowKey'
  | 'allowPropTypes'
  | 'displayTemplate'
  | 'displayParentId'
  | 'displayHeader'
  | 'entry'
  | 'extraActionsRender'
  | 'experimental_disableHistoryStatus'
  | 'experimental_disableOpList'
  | 'experimental_userLiteMode'
>;

/**
 * 使用 Popover 预览
 */
export const ConditionFilterPreview: FC<PropsWithChildren<ConditionFilterPreviewProp>> = ({
  className,
  value,
  type,
  enableRoot,
  children,
  ...restProps
}) => {
  return (
    <Popover
      content={
        <div style={{ minWidth: 400 }}>
          <ConditionFilterPanel editable={false} {...restProps} value={value} />
        </div>
      }
    >
      {children}
    </Popover>
  );
};
