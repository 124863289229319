import qs from 'query-string';

import request from '@/utils/request';

export async function updateCurrentSelection(params: any) {
  return request('/api/user/update_status', {
    method: 'POST',
    data: params,
  });
}

export async function fetchNodeUpdateLog(params: any) {
  return request('/api/node/log', {
    method: 'POST',
    data: params,
  });
}

export async function fetchNodeLatestUpdateInfo(params: any) {
  return request('/api/node/latestInfo', {
    method: 'POST',
    data: params,
  });
}
export async function insertVisited(params: any) {
  return request('/api/insert/record', {
    method: 'POST',
    data: params,
  });
}
export async function searchNodeByFilter(params: any) {
  return request('/docapi/getNodeByFilter', {
    method: 'POST',
    data: params,
  });
}

interface IHistoryData {
  s: number;
  ad: string;
  p: any[];
  m?: Record<number, any[]>;
  time: number;
}
export async function getStatusLog(params: { node_id: string; org_id: string; temp_id: string }) {
  return request<IHistoryData[]>('/docapi/getNodeStatusLog', {
    method: 'POST',
    data: params,
  });
}
