import { queryMyPosts, queryMyReplies } from '@/services/community';

const CommunityModel = {
  namespace: 'community',
  state: {
    myPosts: {},
  },
  effects: {
    *fetchMyPosts({ _ }, { call, put }) {
      const response = yield call(queryMyPosts);
      yield put({
        type: 'saveMyPosts',
        payload: response,
      });
      return response;
    },
    *fetchMyReplies({ _ }, { call, put }) {
      return yield call(queryMyReplies);
    },
  },
  reducers: {
    saveMyPosts(state, action) {
      return {
        ...state,
        myPosts: {
          ...action.payload,
        },
      };
    },
  },
};
export default CommunityModel;
