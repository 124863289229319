import { exportNodeToPDF } from '@/components';

import '../GroupForView/styles.less';

const ExportPDFForView = (props: any) => {
  const { getterPiNode, checkId } = props;

  if (checkId.length !== 1) return null;

  return (
    <div
      onClick={() =>
        exportNodeToPDF({
          node: getterPiNode.value.nodeManager.findChildren(checkId[0]),
        })
      }
      className="view-setting-block"
    >
      <i className="iconfont iconfuzhizhuti" />
      &nbsp;导出主题
    </div>
  );
};

export default ExportPDFForView;
