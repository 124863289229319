import type { PositionType } from '../components/_baseComponent';
import GridBaseComponent from '../components/_baseComponent';
import type { PiGridModules } from '../core';

class FixLink extends GridBaseComponent {
  public data: any;
  public node: PiNode;
  public parentNode: any;
  public type: string = 'fixLink';
  public captrue: boolean = false;
  constructor(
    position: PositionType,
    moduleInstances: PiGridModules,
    data: any,
    node: PiNode,
    parentNode: any,
  ) {
    super(position, moduleInstances);

    this.data = data;
    this.node = node;
    this.parentNode = parentNode;
  }

  public async click() {
    const { theme, matchings, nodePath, linkType, staticOpenType } = this.data.config;
    const {
      ChildNodesLoader,
      DataManager: { templateMap },
    } = this.moduleInstances;

    // openNowNode
    if (linkType === 'openNowNode') {
      const t = templateMap[this.node.tempInfo.id];
      if (t.temp_option?.viewThemeMode === 'content')
        return ChildNodesLoader.config.toNode(this.node.id, true);
      this.moduleInstances.DataManager.config.onPreviewNodeForFix(this.node.id, this.parentNode);
      return;
    }
    if (theme) {
      // 动态链接
      const groupBy = matchings.map((matching: any) => `p${matching.target}`);
      const filterNode = await ChildNodesLoader.getFilterNode(this.node, this.data.config);
      const nodes: PiNode[] = filterNode.getMatchNode(this.node.id, groupBy);
      if (nodes.length) ChildNodesLoader.config.showLinkNodes(nodes, templateMap[theme]);
    } else {
      // 静态链接
      if (staticOpenType === 'jump') {
        return this.moduleInstances.ChildNodesLoader.config.toNode(nodePath);
      }
      this.moduleInstances.ChildNodesLoader.config.onPreviewNodeForFix(nodePath, false);
    }
  }

  public render() {
    const { Draw, Render } = this.moduleInstances;
    const { x, y, height } = this.position;
    const { text, color } = this.data;

    if (this.isHover) {
      Render.setCursor('pointer');
    }

    Draw.attr({
      fillStyle: color || '#316EF5',
      textBaseline: 'top',
      textAlign: 'start',
      font: `${Draw.npx(height)}px sans-serif`,
    });

    Draw.fillText(text, x, y);
  }
}

export default FixLink;
