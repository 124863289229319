export default class Dom {
  public static getContainer(element: string | HTMLElement): HTMLElement | null {
    if (typeof element === 'string') {
      return document.querySelector(element);
    } else {
      return element;
    }
  }

  public static make(
    tagName: string,
    classNames: string | string[] = '',
    attributes: object = {},
  ): HTMLElement {
    const el = document.createElement(tagName);
    if (Array.isArray(classNames)) {
      el.classList.add(...classNames);
    } else if (classNames) {
      el.classList.add(classNames);
    }

    for (const attrName in attributes) {
      if (Object.prototype.hasOwnProperty.call(attributes, attrName)) {
        // @ts-ignore
        el[attrName] = attributes[attrName];
      }
    }
    return el;
  }
}
