import { useUnmountEffect } from '@react-hookz/web';
import { history } from '@umijs/max';
import { useMemoizedFn } from 'ahooks';
import type { ModalFuncProps } from 'antd';
import { Modal } from 'antd';
import { type FC, useRef } from 'react';

import { Prompt } from '../RouterIntercept';

let open = false;

export const RouteLeavingGuard: FC<
  {
    when: boolean;
    shouldBlockNavigation?: (l: Location) => boolean;
    shouldNavigation?: (l: Location) => boolean;
    onLeave?: () => void;
  } & ModalFuncProps
> = ({ when, shouldBlockNavigation, shouldNavigation, onLeave, ...rest }) => {
  const jumpRef = useRef(false);

  const handleBlockedNavigation = useMemoizedFn((nextLocation: Location) => {
    if (open) {
      return false;
    }

    open = true;

    if (
      shouldBlockNavigation &&
      typeof shouldBlockNavigation === 'function' &&
      shouldBlockNavigation(nextLocation)
    ) {
      open = false;
      return false;
    }

    if (
      shouldNavigation &&
      typeof shouldNavigation === 'function' &&
      shouldNavigation(nextLocation)
    ) {
      open = false;
      onLeave?.();
      return true;
    }

    const jump = jumpRef.current;

    if (jump) {
      jumpRef.current = false;
      open = false;
      return true;
    }

    Modal.confirm({
      ...rest,
      onOk() {
        jumpRef.current = true;
        open = false;
        onLeave?.();
        history.push(nextLocation.pathname + nextLocation.search);
      },
      onCancel() {
        open = false;
      },
    });
    return false;
  });

  useUnmountEffect(() => {
    jumpRef.current = false;
  });

  return <Prompt when={when} message={handleBlockedNavigation as any} />;
};

export default RouteLeavingGuard;
