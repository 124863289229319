/* eslint-disable react-refresh/only-export-components */
import { antdModalV5, create, show, useModal } from '@ebay/nice-modal-react';
import { assertExists } from '@linkpi/utils';
import { useMemoizedFn } from 'ahooks';
import type { FC } from 'react';
import { useMemo, useState } from 'react';

import { useOrgInfo, useOrgUserMap } from '@/hook';
import { exportPDF, genPDF, getDefaultExportPDFDirection } from '@/utils/utils';

import type { ExportPDFLegacyModal } from './LegacyModal';
import { default as LegacyModal } from './LegacyModal';

type ExportPDFModalProps = Pick<ExportPDFLegacyModal, 'node'>;

const ExportPDFModal: FC<ExportPDFModalProps> = ({ node }) => {
  const [loading, setLoading] = useState(false);
  const modal = useModal();
  const modalProps = antdModalV5(modal);
  const [orgInfo] = useOrgInfo();
  const userMap = useOrgUserMap();

  const defaultDirection = useMemo(() => {
    assertExists(orgInfo);
    return getDefaultExportPDFDirection(node, orgInfo.templateList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node, orgInfo?.templateList]);

  const onOk = useMemoizedFn(async (direction: string, sheetId: string) => {
    setLoading(true);
    try {
      modalProps.onOk();

      assertExists(orgInfo);

      const html = await exportPDF(
        node,
        orgInfo.templateList,
        orgInfo,
        userMap,
        sheetId,
      );

      // html 导出方向 文件名
      await genPDF(html, direction === 'horizontal', node.title || '无标题');

      modal.resolve();
    } catch (error) {
      modal.reject(error);
    } finally {
      setLoading(false);
    }
  });

  return (
    <LegacyModal
      loading={loading}
      node={node}
      defaultDirection={defaultDirection}
      open={modalProps.open}
      ok={onOk}
      closeModal={() => {
        modal.resolve();
        modalProps.onCancel();
      }}
      afterClose={modalProps.afterClose}
    />
  );
};

const ExportPDFNiceModal = create(ExportPDFModal);

export const exportNodeToPDF = (props: ExportPDFModalProps) =>
  show(ExportPDFNiceModal, props);
