import { Input, Space, Tooltip } from 'antd';
import type { FC } from 'react';
import { match } from 'ts-pattern';

import { useTempPropConfig } from '../hooks';

export const NumberType: FC = () => {
  const { propParams, setPropParams } = useTempPropConfig();

  const saveNumber = (
    value: any,
    key: 'unit' | 'unitPosition' | 'precision' | 'numericalFormat' | 'locale',
  ) => {
    setPropParams({
      ...propParams,
      // @ts-ignore
      number: {
        ...propParams.number,
        [key]: value,
      },
    });
  };

  return (
    <>
      {match(propParams.type)
        .when(
          () => {
            if (propParams.type === 'number') return true;
            if (propParams.type === 'formula' && propParams.formulaFormat === 0) return true;
            return false;
          },
          () => [
            <div className={'prop-item'} key={'numericalFormat'}>
              <Space size={80}>
                <div>
                  <div className={'prop-item-label'}>格式</div>
                  <div className={'prop-item-input'}>
                    <div
                      className={`prop-item-input-btn${
                        !propParams.number?.numericalFormat ? ' active' : ''
                      }`}
                      onClick={() => saveNumber(0, 'numericalFormat')}
                    >
                      数值
                    </div>
                    <div
                      className={`prop-item-input-btn${
                        propParams.number?.numericalFormat === 1 ? ' active' : ''
                      }`}
                      onClick={() => saveNumber(1, 'numericalFormat')}
                    >
                      百分比
                    </div>
                  </div>
                </div>
                {!propParams.number?.numericalFormat && (
                  <div>
                    <div className={'prop-item-label'}>千分位</div>
                    <div className={'prop-item-input'}>
                      <div
                        className={`prop-item-input-btn${
                          !propParams.number?.locale ? ' active' : ''
                        }`}
                        onClick={() => saveNumber(false, 'locale')}
                      >
                        不显示
                      </div>
                      <div
                        className={`prop-item-input-btn${
                          propParams.number?.locale ? ' active' : ''
                        }`}
                        onClick={() => saveNumber(true, 'locale')}
                      >
                        显示
                      </div>
                    </div>
                  </div>
                )}
              </Space>
            </div>,
            !propParams.number?.numericalFormat && (
              <div className={'prop-item'} key={'unit'}>
                <div className={'prop-item-label'}>单位</div>
                <div className={'prop-item-input inline-2'}>
                  <Input
                    value={propParams.number?.unit}
                    placeholder="请输入符号或单位，最多10字符"
                    autoComplete={'off'}
                    maxLength={10}
                    onChange={(e) => saveNumber(e.target.value, 'unit')}
                  />
                  <div
                    className={`prop-item-input-btn${
                      !propParams.number?.unitPosition ? ' active' : ''
                    }`}
                    onClick={() => saveNumber(0, 'unitPosition')}
                  >
                    显示在数字后
                  </div>
                  <div
                    className={`prop-item-input-btn${
                      propParams.number?.unitPosition ? ' active' : ''
                    }`}
                    onClick={() => saveNumber(1, 'unitPosition')}
                  >
                    显示在数字前
                  </div>
                </div>
              </div>
            ),
          ],
        )
        .with('currencyV2', () => [
          <div className={'prop-item'} key="symbol">
            <div className={'prop-item-label'}>货币符号</div>
            <div className={'prop-item-input inline-2'}>
              <Input
                value={propParams.number?.unit}
                placeholder="请输入符号，最多10字符"
                autoComplete={'off'}
                maxLength={10}
                onChange={(e) => saveNumber(e.target.value, 'unit')}
              />
              <div
                className={`prop-item-input-btn${' active'}`}
                onClick={() => saveNumber(1, 'unitPosition')}
              >
                显示在数字前
              </div>
            </div>
          </div>,
          <div className={'prop-item'} key={'unit'}>
            <div className={'prop-item-label'}>单位</div>
            <div className={'prop-item-input inline-2'}>
              <Input
                value={propParams.number?.unit}
                placeholder="请输入单位，最多10字符"
                autoComplete={'off'}
                maxLength={10}
                onChange={(e) => saveNumber(e.target.value, 'unit')}
              />
              <div
                className={`prop-item-input-btn${' active'}`}
                onClick={() => saveNumber(0, 'unitPosition')}
              >
                显示在数字后
              </div>
            </div>
          </div>,
        ])
        .otherwise(() => null)}
      <div className={'prop-item'} key={'precision'}>
        <div className={'prop-item-label'}>精度</div>
        <Space className={'prop-item-input'} size={[0, 8]}>
          {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((precision) => (
            <Tooltip title={precision && `固定 ${precision} 位小数`} key={precision}>
              <div
                className={`prop-item-input-btn${
                  propParams.number?.precision === precision ? ' active' : ''
                }`}
                onClick={() => saveNumber(precision, 'precision')}
              >
                {Number(1).toFixed(precision)}
              </div>
            </Tooltip>
          ))}
        </Space>
      </div>
    </>
  );
};
