import type { ProFormSelectProps } from '@ant-design/pro-form';
import { ProForm, ProFormSelect } from '@ant-design/pro-form';
import type { ApiResponse } from '@linkpi/core';
import type { CurrentUser } from '@linkpi/core';
import { useCreation } from 'ahooks';
import type { SelectProps } from 'antd';
import { isNil, prop, sortBy } from 'ramda';
import { memo } from 'react';

import { useOrgTemplatesSettingInfo } from '@/hook/useOrgSetting';

import { selectSearchFn } from '../utils';

const defaultEnums: [] = [];
type TemplateStatusSelectProps = {
  templateName?: ProFormSelectProps['name'];
  templateId?: string;
  placeholder?: SelectProps['placeholder'];
  excludeStatus?: number[];
  /**
   * 自定义状态列表
   */
  statusOptions?: CurrentUser.taskStatus[];
};
function _TemplateStatusSelect<T>(props: ProFormSelectProps<T> & TemplateStatusSelectProps) {
  // @ts-ignore
  const formTempId = ProForm.useWatch(props.templateName);
  const tempId = props.templateId || formTempId;

  const [templates] = useOrgTemplatesSettingInfo();

  const statusOptions = useCreation(() => {
    const tempMap = templates.reduce<Record<string, ApiResponse.CurrentUser.TemplateInfo>>(
      (r, t) => ({ ...r, [t.template_id]: t }),
      {},
    );
    const temp = tempMap[tempId];

    if (!props.statusOptions) {
      if (isNil(temp)) return defaultEnums;
      if (!Array.isArray(temp.task_status)) return defaultEnums;
    }

    const task_status = props.statusOptions || temp.task_status;

    const tempProps = task_status
      .map((s, i) => ({ ...s, statusIndex: s.index ?? i }))
      .filter((s) => {
        if (Array.isArray(props.excludeStatus) && props.excludeStatus.includes(s.statusIndex)) {
          return false;
        }
        return s.name && !s.delete;
      });

    return sortBy(prop('sort'))(tempProps).map((s) => ({
      label: s.name,
      value: s.statusIndex,
    }));
  }, [tempId, templates, props.templateId, props.templateName]);

  return (
    <ProFormSelect
      {...props}
      fieldProps={{
        ...props.fieldProps,
        placeholder: props.placeholder || '请选择状态',
        filterOption: selectSearchFn,
        showSearch: true,
      }}
      options={statusOptions}
    />
  );
}

export const TemplateStatusSelect = memo(_TemplateStatusSelect);
