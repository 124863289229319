import { Select } from 'antd';
import type { FC } from 'react';

import { ACCURACY_OPTIONS } from '@/pages/space/components/TemplateProp/constants';

import { useTempPropConfig } from '../hooks';

export const AddressType: FC = () => {
  const { isLock, propParams, setPropParams } = useTempPropConfig();

  return (
    <div className={'prop-item'} key={'accuracy'}>
      <div className={'prop-item-label'}>地址精度</div>
      <div className={'prop-item-input'}>
        <Select
          disabled={isLock}
          placeholder={'请选择'}
          value={propParams.accuracy}
          options={ACCURACY_OPTIONS}
          onChange={(e) => setPropParams({ ...propParams, accuracy: e })}
        />
      </div>
    </div>
  );
};
