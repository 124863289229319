import type { ApiResponse } from '@linkpi/core';
import { useControllableValue, useDebounceFn } from 'ahooks';
import { useMemo } from 'react';
import { match } from 'ts-pattern';

import { conditionV2ConvertCondition } from '../utils';

export const useViewConditionCount = (
  viewConditions: ApiResponse.ViewList.ViewconditionV2,
  filterPathSettings: any[],
) => {
  const conditionCount = useMemo(() => {
    let count = String((viewConditions.length || 0) + 1);
    const filterPathSettingCount = filterPathSettings?.length || 0;
    if (filterPathSettingCount) {
      count += '+' + filterPathSettingCount;
    }
    return count;
  }, [viewConditions, filterPathSettings]);

  return conditionCount;
};

export const useViewConditionData = (props: {
  defaultConditions?: ApiResponse.ViewList.ViewconditionV2; // | propSet
  conditions?: ApiResponse.ViewList.ViewconditionV2; // | propSet
  onConditionsChange?: (c: ApiResponse.ViewList.ViewconditionV2) => void;
  entry: 'view' | 'propSet';
  orgInfo: ApiResponse.CurrentUser.OrgInfo;
  onChange?: (changeData: {
    conditions: ApiResponse.ViewList.Viewcondition[];
    parentId: ApiResponse.ViewList.ViewInfo['parentId'];
    changedTemplateId?: boolean;
    conditionsV2: ApiResponse.ViewList.ViewconditionV2;
  }) => void;
  /**
   * onChange 触发时机
   *
   * 默认
   */
  onChangeMode?: 'debounce' | 'immediate';
}) => {
  const {
    defaultConditions,
    conditions: outerConditions,
    onConditionsChange,
    onChange,
    onChangeMode = 'debounce',
  } = props;

  const controllableValueOptions = useMemo(() => {
    const options: Parameters<typeof useControllableValue>[0] = {};
    if (defaultConditions) {
      options.defaultValue = internalV2Data(defaultConditions);
    }

    if (outerConditions) {
      options.value = internalV2Data(outerConditions);
    }

    if (onConditionsChange) {
      options.onChange = (v: any) => {
        onConditionsChange(normalV2Data(v));
      };
    }

    return options;
  }, [outerConditions, defaultConditions, onConditionsChange]);

  const [conditions, setConditions] =
    useControllableValue<InternalConditionV2>(controllableValueOptions);

  const handleChangeActions = useDebounceFn(
    (newConditions: InternalConditionV2, changedTemplateId: boolean = false) => {
      // 转v1 并且 把 Prop_1 变成 key:prop index:1 等等
      const conditionV2 = normalV2Data(newConditions);
      const v1 = conditionV2ConvertCondition(conditionV2);
      console.log('💡💡💡 筛选参数: ', { conditionV2, internalV2Data: newConditions, v1 });
      onChange?.({
        changedTemplateId,
        conditions: v1.conditions,
        parentId: v1.parentId,
        conditionsV2: conditionV2,
      });
    },
    { wait: 300 },
  );

  const debounceHandleChange = match(onChangeMode)
    .with('debounce', () => handleChangeActions.run)
    .with('immediate', () => (...args: Parameters<typeof handleChangeActions.run>) => {
      handleChangeActions.run(...args);
      handleChangeActions.flush();
    })
    .exhaustive();

  const setConditionByIndex = (
    index: number,
    newCondition: ApiResponse.ViewList.ViewconditionV2Item,
  ) => {
    let newConditions = [...conditions];
    newConditions[index] = newCondition;

    // 切换主题类型 重置主题类型和 范围外 的所有
    // 还需要重置 分组 和 排序
    // 还需重置所有和属性相关的配置
    let changedTemplateId = false;
    if (newCondition.key === 'templateId') {
      newConditions = newConditions.filter((x) => x.key === 'templateId' || x.key === 'ancestor');
      changedTemplateId = true;
    }
    // 设置当前表单
    setConditions(newConditions);
    debounceHandleChange(newConditions, changedTemplateId);
  };

  const addCondition = () => {
    setConditions((data: any) => {
      return [...data, { key: null, value: null }];
    });
  };
  const removeCondition = (index: number) => {
    const newConditions = [...conditions];
    newConditions.splice(index, 1);
    setConditions(newConditions);
    debounceHandleChange(newConditions);
  };
  const clearCondition = () => {
    // 清空除了主题类型和 范围 之外的
    const newConditions = conditions.filter((x) => x.key === 'templateId' || x.key === 'ancestor');
    setConditions(newConditions);
    debounceHandleChange(newConditions);
  };

  return {
    conditions,
    setConditionByIndex,
    addCondition,
    removeCondition,
    clearCondition,
    setConditions,
  };
};

// 把外部的key prop 转成 prop_1
export const internalV2Data = (v2Data: ApiResponse.ViewList.ViewconditionV2) => {
  const convertItem = (_item: ApiResponse.ViewList.ViewconditionV2Item) => {
    const item = { ..._item }!;
    if (item.key === 'prop') {
      item.key = `prop_${item.index}` as const;
    }
    if (item.key === 'statusProp') {
      item.key = `statusProp_${item.index}` as const;
    }
    if (item.key === 'rangeEqualCascade') {
      item.key = `rangeEqualCascade_${item.index}` as const;
    }

    if (item.key === 'or' || item.key === 'and') {
      if (item.input) {
        item.input = item.input.map((x: ApiResponse.ViewList.ViewconditionV2Item) =>
          convertItem(x),
        );
      }
    }

    return item;
  };
  return v2Data.map((x) => convertItem(x));
};

export type InternalConditionV2 = ReturnType<typeof internalV2Data>;

// 把内部的key prop_1 转成 prop index1
export const normalV2Data = (v2Data: InternalConditionV2) => {
  const convertItem = (
    _item: InternalConditionV2[number],
  ): ApiResponse.ViewList.ViewconditionV2Item => {
    const item = { ..._item }!;
    if (item.key?.startsWith('prop_')) {
      const [mark, index] = item.key.split('prop_');
      item.key = 'prop';
      item.index = Number(index);
    }
    if (item.key?.startsWith('statusProp_')) {
      const [mark, index] = item.key.split('statusProp_');
      item.key = 'statusProp';
      item.index = Number(index);
    }
    if (item.key?.startsWith('rangeEqualCascade_')) {
      const [mark, index] = item.key.split('rangeEqualCascade_');
      item.key = 'rangeEqualCascade';
      item.level = Number(index);
    }

    if (item.key === 'or' || item.key === 'and') {
      if (item.input) {
        item.input = item.input.map((x: ApiResponse.ViewList.ViewconditionV2Item) =>
          convertItem(x),
        );
      }
    }

    return item;
  };
  return v2Data.map((x) => convertItem(x));
};
