import { useMemoizedFn, usePrevious } from 'ahooks';
import { Space } from 'antd';
import { max, not } from 'ramda';
import type { FC } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useMemo } from 'react';

import type { CheckboxSelectProp } from '@/components';
import { CheckboxSelect } from '@/components';
import { TemplatePropSelect } from '@/components/LinkPiForm';
import { useCurrentTemplateSetting } from '@/hook/useOrgSetting';
import { useOrgDepartmentNodes } from '@/hook/useOrgStructure';
import { toArray } from '@/utils/utils';

export const getSupervisorLabel = (n: number) => {
  if (n > 1) return `第 ${n} 级部门主管`;

  return ['直接', '上级'][n] + '部门主管';
};

export const SupervisorSelect: FC<
  Omit<CheckboxSelectProp, 'value'> & {
    value?: `supervisor_${`prop${number}` | 'creator'}_${number}`[];
  }
> = ({ value: outerValue, onChange, ...props }) => {
  const [templateInfo] = useCurrentTemplateSetting();
  const departmentNodes = useOrgDepartmentNodes();

  const level = useMemo(() => {
    if (!departmentNodes) return 0;
    if (!departmentNodes.length) return 0;

    const result = Math.max(
      ...(departmentNodes.map((i) => i.pathName.split('/').length) as number[]),
    );
    if (Number.isInteger(result)) return result;
    return 0;
  }, [departmentNodes]);

  // 缓存之前的 value
  const preOuterValue = usePrevious(outerValue);
  const editedRef = useRef(false);

  const options = useMemo(() => {
    const list = new Array(level).fill(null).map((_, index) => ({
      value: index.toString(),
      label: getSupervisorLabel(index),
    }));

    return list;
  }, [level]);

  const outerValueMap = useMemo(() => {
    if (!outerValue?.length)
      return {
        prop: 'creator',
        levels: [],
      };

    let prop: string = 'creator';
    const levels: string[] = [];

    toArray(outerValue || []).forEach((item) => {
      if (!item.startsWith('supervisor_')) return;

      const [propV, levelV] = item.replace('supervisor_', '').split('_');

      prop = propV;
      levels.push(levelV);
    });

    const newValueMap = {
      prop: prop as string,
      levels,
    };

    return newValueMap;
  }, [outerValue]);

  // 没有设置参考的人员属性时, 本地临时选择的部门列表
  const [valueMap, onInnerChange] = useState<{
    prop: string;
    levels?: string[];
  }>({ prop: 'creator' });

  useEffect(() => {
    // 用来判断是否要清空组件内的值
    if (!outerValue?.length) {
      let isOuterClear = false;
      if (preOuterValue?.length) {
        // outeValue 如果从有值到没值，并且不是组件内部触发的
        // 则会清空组件内状态
        if (!editedRef.current) {
          isOuterClear = true;
        }
      }
      editedRef.current = false;

      if (not(isOuterClear)) return;
    }
    editedRef.current = false;

    // 如果 outerValue 有值，和组件内状态同步
    onInnerChange(outerValueMap);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onInnerChange, outerValue?.length, outerValueMap]);

  const onLevelsChange = useMemoizedFn((value: any) => {
    const newValueMap = {
      ...valueMap,
      levels: value,
    };

    setValue(newValueMap);
  });

  const onPropChange = useMemoizedFn((value: any) => {
    const newValueMap = {
      ...valueMap,
      prop: value,
    };

    setValue(newValueMap);
  });

  const setValue = (newValueMap: any = {}) => {
    const newValue =
      toArray(newValueMap.levels)?.map((i) => ['supervisor', newValueMap.prop, i].join('_')) || [];

    onInnerChange(newValueMap);
    if (!newValueMap.levels?.length) {
      onChange?.([], []);
      editedRef.current = true;
    } else if (!newValueMap.prop) {
      onChange?.([], []);
      editedRef.current = true;
    } else {
      onChange?.(newValue, []);
    }
    return;
  };

  return (
    <div style={{ padding: '0 8px', height: '100%', overflow: 'auto' }}>
      <div style={{ padding: '6px 12px', paddingTop: 0 }}>
        <Space className="full-w">
          <TemplatePropSelect
            noStyle
            allowCreator
            templateId={templateInfo.template_id}
            allowPropTypes={['user']}
            genValue={(_, id) => `prop${id}`}
            fieldProps={{
              style: {
                width: 240,
              },
              value: valueMap.prop,
              onChange: onPropChange,
            }}
          />
          的
        </Space>
      </div>
      <CheckboxSelect
        {...props}
        options={options}
        value={valueMap.levels}
        onChange={onLevelsChange}
      />
    </div>
  );
};
