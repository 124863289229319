import type { ApiResponse } from '@linkpi/core';
import { message, Modal, Radio } from 'antd';
import { pick } from 'ramda';
import { useEffect, useMemo, useRef, useState } from 'react';

import { makeProgressIterator, showProgressModal } from '@/components';
import request from '@/utils/request';
import { downLoadFile } from '@/utils/utils';

interface IProps {
  nodeIds: any[];
  onCancel: any;
  open: boolean;
  orgId: string;
  template: ApiResponse.CurrentUser.TemplateInfo | null;
}

export default (props: IProps) => {
  const { nodeIds, onCancel, open, orgId, template } = props;

  const [checkFormId, setCheckFormId] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const [formIds, customFormConfig]: [string[], ApiResponse.CurrentUser.TempOption['custom_form']] =
    useMemo(() => {
      if (!template) return [[], {}];
      const _customFormConfig = template.temp_option?.custom_form || {};
      return [Object.keys(_customFormConfig), _customFormConfig];
    }, [template]);
  const formIdsRef = useRef(formIds);
  formIdsRef.current = formIds;

  useEffect(() => {
    if (open && formIdsRef.current.length) {
      setCheckFormId(formIdsRef.current[0]);
    }
  }, [open, formIds]);

  const ok = async () => {
    setLoading(true);

    try {
      await showProgressModal({
        loadingText: '正在导出...',
        finishText: '导出完成',
        progressIterator: makeProgressIterator({
          startFn: () =>
            request<any, any>('/docapi/batchFormAcode', {
              method: 'POST',
              data: {
                org_id: orgId,
                node_id: nodeIds,
                temp_id: template?.template_id,
                form_id: checkFormId,
              },
            }).then((res) =>
              res.status === 'ok' ? { status: 'ok', data: pick(['total', 'async_id'], res) } : res,
            ),
          checkFn: (data) =>
            request<{ finished: number; url: string }>('/docapi/getExportACodeProgress', {
              method: 'POST',
              data,
            }),
        }),
        onFinish(modal, result: any) {
          if (!checkFormId || result.status !== 'ok' || !result.data.url) {
            message.error('导出失败，请重试');
            return;
          }
          message.success('导出成功');
          downLoadFile(result.data.url, customFormConfig?.[checkFormId].name);
        },
      });
    } catch (error) {
      console.error('🤖 导出失败', error);
      message.error('操作失败，请重试');
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal
      confirmLoading={loading}
      open={open}
      title="选择导出的表单"
      onOk={ok}
      onCancel={onCancel}
    >
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <Radio.Group onChange={(e) => setCheckFormId(e.target.value)} value={checkFormId}>
          {formIds.map((id) => (
            <Radio value={id} key={id}>
              {customFormConfig?.[id].name}
            </Radio>
          ))}
        </Radio.Group>
      </div>
    </Modal>
  );
};
