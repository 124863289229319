import type { ApiResponse } from '@linkpi/core';
import { getQuoteOriginProp } from '@linkpi/core';
import Decimal from 'decimal.js';
import { cloneDeep } from 'lodash';
import { useEffect, useRef, useState } from 'react';

// 求和，平均值，最大值，最小值
export type UseStatType = 'none' | 'count' | 'sum' | 'ave' | 'max' | 'min' | undefined | null;

export type statConfigType = Record<string, UseStatType>;
export type statDataType = Record<
  string,
  {
    [groupKey: string]: number;
    all: number;
  }
>;

const uniqueAry = (ary: any[]) => {
  let _ary = [...ary];
  _ary = _ary.map((v) => {
    if (Array.isArray(v)) return v.length ? v : ['$void$'];
    return v === undefined || v === null ? '$void$' : v;
  });

  const map: any = {};

  _ary.forEach((i) => {
    const key = Array.isArray(i) ? i.join('') : i;
    map[key] = true;
  });

  return Object.keys(map).length;
};

const notEmptyUniqueAry = (ary: any[]) => {
  let _ary = [...ary];
  _ary = _ary.filter((v) => {
    if (Array.isArray(v)) return v.length > 0;
    return v !== undefined && v !== null;
  });

  const map: any = {};

  _ary.forEach((i) => {
    const key = Array.isArray(i) ? i.join('') : i;
    map[key] = true;
  });

  return Object.keys(map).length;
};

const enumUniqueAry = (ary: any[]) => {
  const _ary = [...ary];

  const map: any = {};

  _ary.forEach((i) => {
    if (Array.isArray(i))
      i.forEach((j) => {
        map[j] = true;
      });
    else map[i] = true;
  });

  return Object.keys(map).length;
};

export default (
  groupData: ViewGroupDataType | null,
  viewTemplate: any,
  tempMap: any,
): [
  statDataType,
  statConfigType,
  (itemKey: string, value: UseStatType) => void,
  (newConfig: statConfigType) => void,
] => {
  const [statConfig, setStatConfig] = useState<statConfigType>({});
  const statConfigRef = useRef(statConfig);
  statConfigRef.current = statConfig;

  const [statData, setStatData] = useState<statDataType>({});
  const statDataRef = useRef(statData);
  statDataRef.current = statData;

  // 数据变化和配置变化时 重新获取统计
  useEffect(() => {
    const newStatData = getStat(groupData, statConfigRef.current, viewTemplate, tempMap);
    setStatData(newStatData);
  }, [groupData, statConfig]);

  // 修改
  const handleSetStatConfig = (itemKey: string, value: UseStatType) => {
    const newStatConfig = cloneDeep(statConfigRef.current);
    if (value && value !== 'none') {
      newStatConfig[itemKey] = value;
    } else {
      delete newStatConfig[itemKey];
    }
    setStatConfig(newStatConfig);

    return newStatConfig;
  };
  // 批量修改
  const handleBatchSetStatConfig = (newConfig: statConfigType) => {
    setStatConfig(newConfig);
  };

  return [statData, statConfig, handleSetStatConfig, handleBatchSetStatConfig];
};

export const getStat = (
  groupData: ViewGroupDataType | null,
  statConfig: statConfigType,
  viewTemplate: any,
  tempMap: any,
): statDataType => {
  if (!groupData || !Object.keys(statConfig).length) {
    return {};
  }
  const countConfig = Object.keys(statConfig).reduce(
    (res, itemKey) => {
      // prop_x
      const [_, index] = itemKey.split('prop_');
      res[itemKey] = {
        type: statConfig[itemKey], // count ave
        propIndex: Number(index),
        groupNodeDatas: {},
        groupPropValue: {},
        isProp: itemKey.slice(0, 4) === 'prop',
      };
      return res;
    },
    {} as Record<
      string,
      {
        isProp: boolean;
        groupPropValue: any;
        type: UseStatType;
        propIndex: number;
        groupNodeDatas: Record<string, number[]>;
      }
    >,
  );

  Object.keys(groupData).map((groupKey) => {
    groupData[groupKey].list.map((node) => {
      Object.keys(countConfig).map((y) => {
        const propIndex = countConfig[y].propIndex;
        let data = cloneDeep(node.tempInfo.prop[propIndex]);
        // 兼容引用属性的值 值有prop属性
        if (data?.props && data?.props.length === 1) {
          // 有可能是数组 有可能是字符 强行转number
          data =
            data?.props[0]?.value instanceof Array
              ? data?.props[0]?.value?.[0]
              : data?.props[0]?.value;
        }
        // number
        data = Number(data);
        if (isNaN(data)) {
          data = 0;
        }

        if (!countConfig[y].groupPropValue[groupKey]) {
          countConfig[y].groupPropValue[groupKey] = [];
        }
        countConfig[y].groupPropValue[groupKey].push(
          countConfig[y].isProp ? cloneDeep(node.tempInfo.prop[propIndex]) : undefined,
        );

        if (!countConfig[y].groupNodeDatas[groupKey]) {
          countConfig[y].groupNodeDatas[groupKey] = [];
        }
        countConfig[y].groupNodeDatas[groupKey].push(data);
      });
    });
  });

  const newStatData = Object.keys(countConfig).reduce((res, itemKey) => {
    let precision = 0;
    if (itemKey.slice(0, 4) === 'prop' && viewTemplate) {
      const propIndex = itemKey.split('_')[1];
      let prop = viewTemplate.prop[propIndex];

      let isNumber = prop && prop.type === 'number' && prop.number;
      const isFormulaNumber =
        prop && prop.type === 'formula' && prop.formulaFormat === 0 && prop.number;

      if (prop.type === 'quote') {
        const originProp = getQuoteOriginProp(prop, tempMap, [], false);
        isNumber =
          originProp &&
          typeof originProp !== 'string' &&
          originProp.type === 'number' &&
          originProp.number;
        prop = originProp;
      }

      if (isFormulaNumber || isNumber) {
        precision = prop.number.precision || 0;
      }
    }

    let allValue = 0;
    const allNodeDatas = [];
    const groupValue: Record<string, number> = {};
    const { type, groupNodeDatas, groupPropValue } = countConfig[itemKey];

    //
    if (type === 'uniqueCount') {
      const all = [];
      for (const i in groupPropValue) {
        const values = groupPropValue[i];
        groupValue[i] = uniqueAry(values);
        all.push(...groupPropValue[i]);
      }
      allValue = uniqueAry(all);
    }

    if (type === 'notEmptyUniqueCount') {
      const all = [];
      for (const i in groupPropValue) {
        const values = groupPropValue[i];
        groupValue[i] = notEmptyUniqueAry(values);
        all.push(...groupPropValue[i]);
      }
      allValue = notEmptyUniqueAry(all);
    }

    if (type === 'enumUniqueCount') {
      const all = [];
      for (const i in groupPropValue) {
        const values = groupPropValue[i];
        groupValue[i] = enumUniqueAry(values);
        all.push(...groupPropValue[i]);
      }
      allValue = enumUniqueAry(all);
    }

    if (type === 'count') {
      for (const i in groupNodeDatas) {
        groupValue[i] = groupNodeDatas[i].length;
        allValue = Decimal.add(allValue, groupValue[i]).toNumber();
      }
    } else if (type === 'sum') {
      for (const i in groupNodeDatas) {
        groupValue[i] = groupNodeDatas[i]
          .reduce((a, b) => Decimal.add(a, b.toFixed(precision)).toNumber(), 0)
          .toFixed(precision);
        allValue = Decimal.add(allValue, groupValue[i]).toNumber().toFixed(precision);
      }
    } else if (type === 'ave') {
      for (const i in groupNodeDatas) {
        const sum = groupNodeDatas[i].reduce(
          (a, b) => Decimal.add(a, b.toFixed(precision)).toNumber(),
          0,
        );
        groupValue[i] = Decimal.div(sum, groupNodeDatas[i].length).toNumber().toFixed(precision);
        allNodeDatas.push(...groupNodeDatas[i]);
      }
      const sum = allNodeDatas.reduce((a, b) => Decimal.add(a, b.toFixed(precision)).toNumber(), 0);
      allValue = Decimal.div(sum, allNodeDatas.length).toNumber().toFixed(precision);
    } else if (type === 'max') {
      for (const i in groupNodeDatas) {
        groupValue[i] = Math.max(...groupNodeDatas[i]).toFixed(precision);
        allNodeDatas.push(...groupNodeDatas[i]);
      }
      allValue = Math.max(...allNodeDatas).toFixed(precision);
    } else if (type === 'min') {
      for (const i in groupNodeDatas) {
        groupValue[i] = Math.min(...groupNodeDatas[i]).toFixed(precision);
        allNodeDatas.push(...groupNodeDatas[i]);
      }
      allValue = Math.min(...allNodeDatas).toFixed(precision);
    }

    res[itemKey] = {
      all: allValue,
      ...groupValue,
    };

    return res;
  }, {} as statDataType);

  return newStatData;
};

export const getStatUnitConfig = (
  key: string,
  type: UseStatType,
  tempId: string,
  tempMap: Record<string, ApiResponse.CurrentUser.TemplateInfo>,
): null | ApiResponse.CurrentUser.numberExtend => {
  if (!key.startsWith('prop_')) {
    return null;
  }

  const enableStatType = ['sum', 'ave', 'max', 'min'];
  if (!enableStatType.includes(type || '')) {
    return null;
  }

  const temp = tempMap[tempId];
  if (!temp) {
    return null;
  }
  const [_, propIndex] = key.split('prop_');
  const prop = temp.prop[Number(propIndex)];
  if (!prop) {
    return null;
  }

  const originalProp = getQuoteOriginProp(prop, tempMap, [], false);
  if (!originalProp) {
    return null;
  }
  // 在QUOTE_SYS_PROP里设置的特殊标识
  else if (typeof originalProp === 'string') {
    return null;
  }
  // 引用统计
  else if (originalProp.conditionMatching && originalProp.matchingType > 0) {
    return null;
  }
  // 引用公式
  else if (originalProp.type === 'formula') {
    // 数字公式 配置
    if (originalProp.formulaFormat === 0) {
      return originalProp.number;
    }
  }
  // 其他属性
  else {
    if (originalProp.type === 'number') {
      return originalProp.number;
    }
  }
  return null;
};
