/* eslint-disable react-refresh/only-export-components */
import { create, show, useModal } from '@ebay/nice-modal-react';
import type { CurrentUser } from '@linkpi/core';
import { assertExists } from '@linkpi/utils';
import { useMemoizedFn } from 'ahooks';
import { message, Modal } from 'antd';
import { type FC, useEffect } from 'react';
import { match } from 'ts-pattern';

import { useCurrentOrgId, useJumpNode } from '@/hook';
import { useCloseCurrentNodeTab } from '@/pages/home/Workbench/NodeTabs/hook';
import { runAutomation } from '@/services/automation';

type ICustomAction = {
  config: CurrentUser.CustomButton;
  nodeId: string;
};

const CustomAction: FC<ICustomAction> = ({ config, nodeId }) => {
  const modal = useModal();
  const { jump } = useJumpNode();
  const { close: closeCurrentNodeTab } = useCloseCurrentNodeTab();
  const orgId = useCurrentOrgId();

  const run = useMemoizedFn(async () => {
    if (config.opType !== 'customMadeAction') throw Error('按钮逻辑错误');

    assertExists(config.customMadeAction);

    match(config.customMadeAction)
      /**
       * NOTE
       *
       * START 仅仅在电瓦特环境生效
       */
      .with({ id: '参与平台交易' }, async () => {
        Modal.confirm({
          content: '前往“发布申报”进行方案定制?',
          okText: '确定',
          cancelText: '取消',
          onOk() {
            runAutomation({
              orgId,
              nodeId,
              autoId: '48102B5D4A94464AA70B8E8177A91647',
            });
            jump(
              'c6f5da0504564b37b139433b919fa3e4',
              'BFD1515E9F40213059BC03DB33D5F6BD',
            );
          },
        });
      })
      .with({ id: '用户侧参与' }, () => {
        // TODO 关闭当前标签页
        closeCurrentNodeTab();
        runAutomation({
          orgId,
          nodeId,
          autoId: '45AE260D2151623C4CAAA46D5F0D6442',
        });
        jump(
          '99c047804fe3474db6cd99ef63c13d13',
          '972A3C6D0C2B6548D0A0FAACAA066179',
        );
      })
      .with({ id: '用户侧不参与' }, () => {
        // TODO 关闭当前标签页
        runAutomation({
          orgId,
          nodeId,
          autoId: '2D20578400DA0BDE1D038BB67C4D8483',
        });
        jump(
          '99c047804fe3474db6cd99ef63c13d13',
          '972A3C6D0C2B6548D0A0FAACAA066179',
        );
      })
      .with({ id: '平台侧申报' }, () => {
        // TODO 关闭当前标签页
        runAutomation({
          orgId,
          nodeId,
          autoId: 'BA4A637CF1160D8F4290670C5795B241',
        });
        jump(
          'b6fa695a41bf4a92bd73e70418d9a64d',
          'A7D37D9A5E6AB449A9BCAC7AF6A20C60',
        );
      })
      .with({ id: '用户侧确认效果' }, () => {
        // TODO 关闭当前标签页
        runAutomation({
          orgId,
          nodeId,
          autoId: '16CD36DE57A38933B526B2484C29A653',
        });
        jump(
          '0d456154c03f479195d9b2010931a1c3',
          'DFD7BD77F73D403AEAB0841B5D0553AF',
        );
      })
      .with({ id: '平台侧确认披露效果' }, () => {
        // TODO 关闭当前标签页
        runAutomation({
          orgId,
          nodeId,
          autoId: 'F22B17A6683B36E250813377DCC76075',
        });
        jump(
          '33414f1fd3254a868d7e794e4a4436a0',
          '368BD139536E2865566AD9B85E51E6D7',
        );
      })
      .otherwise(() => {
        message.warn('未找到对应操作，请检查按钮配置');
      });

    modal.hide();
    modal.resolve();
    modal.resolveHide();
  });

  useEffect(() => {
    if (!modal.visible) return;
    run();
  }, [modal.visible, run]);

  return null;
};

const CustomActionWrapper = create(CustomAction);

export const triggerCustomButtonCustomAction = (options: ICustomAction) => {
  return show(CustomActionWrapper, options);
};
