import type { OrgGroups } from '@linkpi/core';
import { generateId, getDepartmentNodes } from '@linkpi/core';
import { usePiNode } from '@linkpi/core/web';
import { useDispatch } from '@umijs/max';
import { useMemoizedFn, useRequest } from 'ahooks';
import { atom, useAtom } from 'jotai';
import { defaultTo, values } from 'ramda';
import type { BasicDataNode } from 'rc-tree';
import { useEffect, useMemo, useState } from 'react';

import request from '@/utils/request';
import { delay, toRecord } from '@/utils/utils';

import { useCurrentOrgId, useOrgConnection } from './useOrg';
import { useOrgUserMap } from './useOrgUser';
import { useOrgDepartment } from './useOrgUserGroup';

interface IOrgStructureHook {
  orgId?: string;
}

export function useOrgStructureNode(options?: IOrgStructureHook) {
  const currentOrgId = useCurrentOrgId();
  const orgId = options?.orgId || currentOrgId;
  const orgConnection = useOrgConnection(orgId);
  const rootNode = orgConnection?.nodeManager.getRoot();
  const {
    node: orgStructureNodeId,
    temp: [departmentTemplateId, departmentUserTemplateId] = [],
  } = defaultTo({ node: undefined, temp: undefined })(
    rootNode?.prop._sys_org_struct,
  );

  const [orgStructureNode] = usePiNode(orgConnection, orgStructureNodeId);

  return {
    orgId,
    orgConnection,
    rootNode,
    orgStructureNode: orgStructureNode?.value,
    orgStructureNodeId,
    departmentTemplateId,
    departmentUserTemplateId,
    openedOrgStructure: !!rootNode?.prop._sys_org_struct,
  };
}

const getGroupIdNodeIdMap = (orgStructureNode?: PiNode) => {
  const groupIdNodeIdMap: Record<string, string> = {};

  const recurseOrgStructureNode = (nodeChildren: PiNode[]) => {
    nodeChildren.forEach((child) => {
      // 貌似小连没有，被过滤掉了
      if (child.prop._sys_group_id) {
        groupIdNodeIdMap[child.prop._sys_group_id] = child.key;
      }

      if (child.children?.length) {
        recurseOrgStructureNode(child.children);
      }
    });
  };

  if (orgStructureNode) {
    recurseOrgStructureNode(orgStructureNode?.children);
    return groupIdNodeIdMap;
  } else {
    return {};
  }
};

export function useOrgStructure(options: IOrgStructureHook) {
  const {
    orgConnection,
    departmentTemplateId: departmentTemplate,
    orgStructureNodeId,
    orgStructureNode,
    orgId,
  } = useOrgStructureNode(options);
  const { changeUserDepartment } = useOrgStructureUsers(options);

  const dispatch = useDispatch();
  const updateUserList = useMemoizedFn(async () => {
    const orgUser = dispatch({
      type: 'workspace/updateSpaceUsers',
      payload: { org_id: orgId },
    });

    const fetchGroup = dispatch({
      type: 'space/fetchGroups',
      payload: { org_id: orgId },
    });

    // const updateUser = window.__updateOrgUser()

    return await Promise.all([orgUser, fetchGroup]);
  });

  const groupIdNodeIdMap = getGroupIdNodeIdMap(orgStructureNode);

  const checkGroupId = async (node: PiNode): Promise<string> => {
    if (node.prop._sys_group_id) {
      return node.prop._sys_group_id;
    } else {
      await delay(50);
      return checkGroupId(node);
    }
  };

  const addDepartment = async ({
    parentId,
    name,
    supervisor,
  }: {
    parentId?: string;
    name: string;
    supervisor?: string[];
  }) => {
    const targetNode = orgConnection?.nodeManager.getNode(
      (parentId && groupIdNodeIdMap[parentId]) || orgStructureNodeId!,
    );

    if (!targetNode) {
      console.log('Error!! 没有找到 structure node!');
      return;
    }

    const newDepartmentNode = await targetNode?.insertNode(
      Math.max(targetNode.children.length, 0),
      {
        title: name,
        id: generateId(),
        prop: {
          _sys_temp: [departmentTemplate, [name, null, supervisor ?? null]],
        },
      },
    );

    const newGroupId = await checkGroupId(newDepartmentNode);

    await dispatch({
      type: 'space/fetchGroups',
      payload: { org_id: orgId },
    });

    if (supervisor) {
      await changeUserDepartment({
        userIds: supervisor,
        departmentIds: [newGroupId],
      });
    }

    return newGroupId;
  };

  const addDepartments = async ({
    parentId,
    names,
    supervisor,
  }: {
    parentId?: string;
    names: string[];
    supervisor?: string[];
  }) => {
    const targetNode = orgConnection?.nodeManager.getNode(
      (parentId && groupIdNodeIdMap[parentId]) || orgStructureNodeId!,
    );

    if (!targetNode) {
      console.log('Error!! 没有找到 structure node!');
      return;
    }

    const newDepartmentNodes = names.map((name, index) => {
      return targetNode?.insertNode(Math.max(targetNode.children.length, 0), {
        title: name,
        id: generateId(),
        prop: {
          _sys_temp: [departmentTemplate, [name, null, supervisor ?? null]],
        },
      });
    });
    // const newDepartmentNode = await targetNode?.insertNode(
    //   Math.max(targetNode.children.length, 0),
    //   {
    //     title: name,
    //     id: generateId(),
    //     prop: {
    //       _sys_temp: [departmentTemplate, [name, null, supervisor ?? null]],
    //     },
    //   },
    // );
    await Promise.all(
      newDepartmentNodes.map(async (newDepartmentNode) => {
        const newGroupId = await checkGroupId(newDepartmentNode);

        if (supervisor) {
          await changeUserDepartment({
            userIds: supervisor,
            departmentIds: [newGroupId],
          });
        }
      }),
    );

    await dispatch({
      type: 'space/fetchGroups',
      payload: { org_id: orgId },
    });
    // return newGroupId;
  };

  const updateDepartment = async ({
    id,
    name,
    supervisor,
    parentId,
  }: {
    id: string;
    name?: string;
    supervisor?: string[];
    parentId?: string;
  }) => {
    const node = orgConnection?.nodeManager.getNode(groupIdNodeIdMap[id]);
    const propValues: any[] = [];
    const propValuesIndex: number[] = [];

    if (name) {
      propValues.push(name);
      propValuesIndex.push(0);
    }
    if (supervisor) {
      propValues.push(supervisor);
      propValuesIndex.push(2);
      await changeUserDepartment({
        userIds: supervisor,
        departmentIds: [id],
      });
    }
    node?.updateProp({
      index: propValuesIndex,
      value: propValues,
      temp_id: departmentTemplate!,
    });

    if (parentId) {
      node?.moveNode(parentId, 0);
    }

    await delay(2000);
    await updateUserList();
  };
  const deleteDepartment = (id: string) => {
    orgConnection?.nodeManager.delete(groupIdNodeIdMap[id], false);
  };

  const deleteDepartments = (ids: string[]) => {
    ids.forEach((id) => {
      orgConnection?.nodeManager.delete(groupIdNodeIdMap[id], false);
    });
  };

  const moveDepartment = async (
    id: string,
    parentId: string | undefined,
    index: number,
  ) => {
    const node = orgConnection?.nodeManager.getNode(groupIdNodeIdMap[id]);

    if (parentId) {
      await node?.moveNode(parentId, index);
    }
  };

  return {
    addDepartment,
    addDepartments,
    updateDepartment,
    deleteDepartment,
    deleteDepartments,
    moveDepartment,
  };
}

interface IOrgStructureUserHook extends IOrgStructureHook {
  departmentId?: string;
}

export function useOrgStructureUsers(options: IOrgStructureHook) {
  const {
    orgConnection,
    departmentUserTemplateId,
    orgStructureNodeId,
    orgStructureNode,
    orgId,
  } = useOrgStructureNode(options);

  const dispatch = useDispatch();
  const updateUserList = useMemoizedFn(async () => {
    const orgUser = dispatch({
      type: 'workspace/updateSpaceUsers',
      payload: { org_id: orgId },
    });

    const fetchGroup = dispatch({
      type: 'space/fetchGroups',
      payload: { org_id: orgId },
    });

    // const updateUser = window.__updateOrgUser()

    return await Promise.all([orgUser, fetchGroup]);
  });

  const groupIdNodeIdMap = getGroupIdNodeIdMap(orgStructureNode);

  const deleteCurrentDepartmentUser = async ({
    userIds,
    departmentIds,
  }: {
    userIds: string[];
    departmentIds: string[];
  }) => {
    if (!userIds.length) return;
    if (departmentIds.length) {
      await departmentIds.forEach((departmentId) => {
        const node = orgConnection?.nodeManager.getNode(
          groupIdNodeIdMap[departmentId],
        );
        userIds.forEach((userId) => {
          node?.children.forEach((_node) => {
            if (_node.tempInfo.prop[0] === userId) {
              node?.nodeManager.delete(_node.key, true);
            }
          });
        });
      });
    } else {
      console.log('没有 departmentId，可能无法正常删除！');
      await userIds.forEach((userId) => {
        orgConnection?.nodeManager.delete(userId, true);
      });
    }
  };

  const changeUserDepartment = async ({
    userIds,
    departmentIds,
    needDelete = false,
    oldDepartmentIds,
  }: {
    userIds: string[];
    departmentIds: string[];
    needDelete?: boolean;
    oldDepartmentIds?: string[];
  }) => {
    const _groupIdNodeIdMap = getGroupIdNodeIdMap(orgStructureNode);
    if (!userIds.length) return;
    if (needDelete)
      await deleteCurrentDepartmentUser({
        userIds,
        departmentIds: oldDepartmentIds ?? [],
      });
    if (departmentIds.length) {
      await Promise.all(
        departmentIds.map(async (departmentId) => {
          let dId = departmentId;
          if (departmentId.startsWith('group_')) {
            [, dId] = departmentId.split('group_');
          }
          const targetNode = orgConnection?.nodeManager.getNode(
            _groupIdNodeIdMap[dId] || orgStructureNodeId!,
          );
          return userIds.map((userId) => {
            return targetNode?.insertNode(
              Math.max(targetNode.children.length, 0),
              {
                id: generateId(),
                prop: {
                  _sys_temp: [departmentUserTemplateId, [userId, null, null]],
                },
              },
            );
          });
        }),
      );
    } else {
      const targetNode = orgConnection?.nodeManager.getNode(
        orgStructureNodeId!,
      );
      await Promise.all(
        userIds.map((userId) => {
          return targetNode?.insertNode(
            Math.max(targetNode.children.length, 0),
            {
              id: generateId(),
              prop: {
                _sys_temp: [departmentUserTemplateId, [userId, null, null]],
              },
            },
          );
        }),
      );
    }
    await delay(2000);
    await updateUserList();
  };

  const updateUserInfo = async ({
    id,
    phoneNumber,
  }: {
    id: string;
    phoneNumber?: string;
  }) => {
    const node = orgConnection?.nodeManager.getNode(id);
    const propValues: any[] = [];
    const propValuesIndex: number[] = [];

    if (phoneNumber) {
      propValues.push(phoneNumber);
      propValuesIndex.push(1);
    }

    await node?.updateProp({
      index: propValuesIndex,
      value: propValues,
      temp_id: departmentUserTemplateId!,
    });

    await delay(2000);
    await updateUserList();
  };

  return {
    changeUserDepartment,
    deleteCurrentDepartmentUser,
    updateUserInfo,
    updateUserList,
  };
}

async function getFilterNode(node: PiNode, templateId: string) {
  const filters = {
    groupBy: [],
    tempProps: [],
    parentId: node.prop._sys_group_id,
    temp_id: templateId,
    draft: node.metadata.f === -2,
  };
  const filterNode = await node.nodeManager.getTempFilterNode(filters);
  return filterNode.getMatchNode(node.id, []);
}

export function useOrgDepartmentNodes(options?: IOrgStructureHook) {
  const groups = useOrgDepartment(options);
  const useMap = useOrgUserMap();

  const departmentNodes: ReturnType<typeof getDepartmentNodes> = useMemo(() => {
    return getDepartmentNodes(groups, useMap);
  }, [groups, useMap]);

  return departmentNodes;
}

type ExtraPiNode = { departmentChildren?: PiNode[]; userChildren?: PiNode[] };

export interface NewPiNode extends ExtraPiNode, BasicDataNode {
  groupId: string;
  title: string;
  index: number;
  nodeType: number;
  acl: number;
  parentId?: string;
}

export function useOrgStructureDepartments({
  departmentId,
  ...rest
}: IOrgStructureUserHook) {
  const {
    rootNode,
    orgStructureNode,
    departmentUserTemplateId,
    departmentTemplateId,
  } = useOrgStructureNode(rest) ?? {};

  let node = orgStructureNode;
  if (node && departmentId) {
    node =
      node.prop._sys_group_id === departmentId
        ? node
        : node.all_children.find(
            (childNode) => childNode.prop._sys_group_id === departmentId,
          );
  }

  const filterChildren = (children: PiNode[]): ExtraPiNode => {
    return children
      .filter((n) => n.prop._sys_group_id)
      .reduce<ExtraPiNode>(
        (prev, child) => ({
          departmentChildren:
            child.template?.template_id === departmentTemplateId
              ? prev.departmentChildren?.concat(child)
              : prev.departmentChildren,
          userChildren:
            child.template?.template_id === departmentUserTemplateId
              ? prev.userChildren?.concat(child)
              : prev.userChildren,
        }),
        {
          departmentChildren: [],
          userChildren: [],
        },
      );
  };

  if (node) {
    const departmentNodes = node.children
      .filter((n) => n.template?.template_id === departmentTemplateId)
      .filter((n) => n.prop._sys_group_id);

    const copyTree = (originalTree: PiNode[]): NewPiNode[] =>
      originalTree.map<NewPiNode>((n: PiNode) => {
        const { departmentChildren, userChildren } = filterChildren(
          n.children ?? [],
        );
        return {
          departmentChildren: copyTree(
            departmentChildren || [],
          ) as unknown as PiNode[],
          userChildren,
          groupId: n.prop._sys_group_id!,
          title: n.title,
          index: n.index,
          nodeType: n.nodeType,
          acl: n.acl,
          parentId: n.parent?.node_id,
        };
      });
    return copyTree(departmentNodes);
  }

  return [];
}

export const getDingActivatedList = async (params: {
  org_id: string;
  activated: 0 | 1;
}) => {
  const res = await request<string[]>('/api/dingtalk/activated', {
    method: 'POST',
    data: params,
  });
  return res.status === 'ok' ? res.data : [];
};

export interface PositionRequest {
  /**
   * 空间ID
   */
  org_id: string;
  /**
   * 职位信息
   */
  post: Post[];
  /**
   * 部门ID
   */
  struct_id: string;
}

export interface Post {
  /**
   * 部门ID
   */
  group_id?: string;
  /**
   * 职位ID
   */
  post_id?: string;
  /**
   * 职位名称
   */
  post_name: string;
  /**
   * 角色ID
   */
  role_id: string;
  create_time?: string;
}

export const setPosition = async (params: PositionRequest) =>
  await request('/api/org/setPost', {
    method: 'POST',
    data: params,
  });

export type PositionMap = Record<string, Post[]>;

export const getPosition = async (params: { org_id: string }) =>
  await request<PositionMap>('/api/org/getPost', {
    method: 'POST',
    data: params,
  });

interface IOrgStructureUserListHook extends IOrgStructureHook {
  update?: number;
}
export function useOrgStructureUserList(options?: IOrgStructureUserListHook) {
  const userMap = useOrgUserMap();
  const { departmentUserTemplateId, rootNode } = useOrgStructureNode(options);
  const currentOrgId = useCurrentOrgId();
  const orgId = options?.orgId || currentOrgId;
  const [dingOnce, setDingOnce] = useState(false);
  const { data: userList } = useRequest(
    // @ts-ignore
    async () => {
      if (!rootNode || !departmentUserTemplateId) return [];
      const list = await getFilterNode(rootNode, departmentUserTemplateId);
      let dingNonactivatedList: string[] = [];
      if (!dingOnce) {
        dingNonactivatedList = await getDingActivatedList({
          org_id: orgId,
          activated: 0,
        });
        setDingOnce(true);
      }

      const infosMap: Record<
        string,
        {
          phoneNumber: string;
          nodeId: string;
        }
      > = {};
      list.forEach((node) => {
        const userId = node.tempInfo.prop[0];
        const phoneNumber = node.tempInfo.prop[1];

        infosMap[userId] = {
          phoneNumber,
          nodeId: node.node_id,
        };
      });

      return values(userMap).map((u) => ({
        ...u,
        ...(infosMap[u.account_id] ? infosMap[u.account_id] : {}),
        dingNonactivated:
          u.dingtalk === 1 && dingNonactivatedList.includes(u.account_id),
      }));
    },
    { refreshDeps: [rootNode, departmentUserTemplateId, userMap] },
  );

  return userList || [];
}

export function useOrgDepartmentNodesMap(options?: IOrgStructureHook) {
  const departmentNodes = useOrgDepartmentNodes(options);

  const departmentNodeMap = useMemo(() => {
    return toRecord<OrgGroups.OrgDepartmentNodeInfo>((o) => ({ [o.id]: o }))(
      departmentNodes,
    );
  }, [departmentNodes]);

  return departmentNodeMap;
}

const PositionAtom = atom<PositionMap>({});

export const usePositionMap = (orgId: string) => {
  const [positionMap, setPositionMap] = useAtom(PositionAtom);
  const [updateKey, setUpdateKey] = useState(0);

  useEffect(() => {
    (async () => {
      const res = await getPosition({
        org_id: orgId,
      });
      if (res.status === 'ok') {
        setPositionMap(res.data);
      }
    })();
  }, [updateKey]);

  const updatePositionMap = () => {
    setUpdateKey((v) => v + 1);
  };

  return [positionMap, updatePositionMap] as const;
};
