import { sendError } from '@linkpi/core';

import { ENV_CONFIG } from '@/consts';

import request from './request';

const sendWebError = ({ title, desc }) => {
  if (process.env.NODE_ENV === 'production') {
    const path = window.location.href;
    const sys = window.navigator.userAgent;

    if (typeof desc === 'string' && ~desc.indexOf('/api/notice/sum')) return;
    sendError({
      title: title,
      desc: desc + `  \n${path}  \n${sys}`,
      pl: 'web',
      request,
      url: ENV_CONFIG.DING_TALK_WARNING,
    });
  }
};

export default sendWebError;
