import useUrlState from '@ahooksjs/use-url-state';
import { useMemoizedFn } from 'ahooks';

export const useUrlQueryState = <T extends string>(key: string, value: T) => {
  const [state, _setState] = useUrlState({ [key]: value });
  const setState = useMemoizedFn((_value: T) => {
    _setState((v) => ({
      ...v,
      [key]: _value,
    }));
  });

  return [state[key] as T, setState] as const;
};
