import type { PiGridModules } from '../core';
import type { PositionType } from './_baseComponent';
import GridBaseComponent from './_baseComponent';

export default class ShowLevelInFirstCol extends GridBaseComponent {
  public data: any;

  constructor(position: PositionType, moduleInstances: PiGridModules, data: any) {
    super(position, moduleInstances);
    this.data = data;
  }

  public click() {
    const {
      moduleInstances: {
        DataManager: { foldNodesPool },
      },
      data: { node },
    } = this;

    const has = foldNodesPool.find((n) => n.id === node.id);

    const newFoldNodesPool = has
      ? foldNodesPool.filter((n) => n.id !== node.id)
      : [...foldNodesPool, node];

    this.moduleInstances.DataManager.foldNodesPool = newFoldNodesPool;

    const hideNodesMap: any = {};

    newFoldNodesPool.forEach((n) => {
      (n.all_children || []).forEach((i: any) => {
        hideNodesMap[i.id] = true;
      });
    });
    this.moduleInstances.DataManager.hideNodesMap = hideNodesMap;
    this.moduleInstances.DataManager.getVirtualList();
    this.moduleInstances.Render.render();
  }

  public render() {
    const {
      Render,
      Draw,
      DataManager: { foldNodesPool },
    } = this.moduleInstances;

    const { x, y, width, height } = this.position;
    const triangleHeight = 7;
    const triangleWidth = 7;

    const isFold = foldNodesPool.find((n) => n.id === this.data.node.id);

    Draw.fillTriangle(
      {
        x: x + (width - triangleWidth) / 2,
        y: y + (height - triangleHeight) / 2,
        height: triangleHeight,
        width: triangleWidth,
      },
      '#6b7a96',
      isFold ? 'right' : 'bottom',
    );

    if (this.isHover) {
      Render.setCursor('pointer');

      // Draw.fillRect(x, y, width, height, 'red');
    }
  }
}
