import type { FC } from 'react';

import IconPark from '@/components/IconPark';

import styles from './styles.less';

export const ToggleCollapse: FC<{
  tableCollapse: boolean;
  onToggle: (v: boolean) => void;
  isDark?: boolean;
}> = (props) => {
  const { onToggle, tableCollapse, isDark } = props;

  return (
    <div
      className={styles.setCollapse + (isDark ? ' ' + styles.darkBlock : '')}
      onClick={() => {
        onToggle(!tableCollapse);
      }}
    >
      <IconPark type={tableCollapse ? 'fold' : 'open'} style={{ marginRight: 4 }} />
      {tableCollapse ? '折叠' : '展开'}
    </div>
  );
};
