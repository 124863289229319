import { NODE_PERMISSION } from '@linkpi/core';
import { getSubString32 } from '@linkpi/core';
import { useSelector } from '@umijs/max';
import { Checkbox, Input, message, Popconfirm } from 'antd';
import { useEffect, useMemo, useRef, useState } from 'react';

import LinkPiPop from '@/components/LinkPiPop';

import './index.less';

const SysTag = (props) => {
  const {
    node,
    insideDom,
    tagAdd,
    tags,
    tagDel,
    tagEdit,
    defaultVisible,
    placement = 'bottomLeft',
    onCancel,
  } = props;
  const searchRef = useRef();
  const tagInputRef = useRef();
  const { currentUser } = useSelector((state) => state.user);
  const { currentSelection } = useSelector((state) => state.workspace);
  const [tagFilter, setTagFilter] = useState('');
  const [tagOwn, setTagOwn] = useState(false);
  const [showTags, setShowTags] = useState([]);
  const [nodeTags, setNodeTags] = useState([]);
  const [editingTag, setEditingTag] = useState(null);

  const isAdmin = useMemo(() => {
    const role = currentUser?.organization?.find(
      (x) => x.orgId === currentSelection.selectSpace,
    )?.role;
    return role === 1 || role === 3;
  }, [currentUser, currentSelection]);

  const hasPermission = isAdmin || (node && node.value.acl < NODE_PERMISSION.只读);

  useEffect(() => {
    const nTags =
      node && typeof node.value.prop._sys_tag === 'object'
        ? Object.keys(node.value.prop._sys_tag)
        : [];
    setNodeTags(nTags);
    setShowTags([
      ...tags.filter(
        (x) =>
          (!tagOwn || x.create_account === currentUser.userid) &&
          !~nTags.indexOf(x.tag) &&
          ~x.tag.toLowerCase().indexOf(tagFilter.toLowerCase()),
      ),
    ]);
  }, [tagFilter, tagOwn, tags, node]);

  /*const searchChange = useDebounce(() => {
    const nodeTags =
      node.value.prop._sys_tag && typeof node.value.prop._sys_tag === 'object'
        ? Object.keys(node.value.prop._sys_tag)
        : [];
    setShowTags([
      ...Object.keys(tags).filter(
        (x) =>
          (!tagOwn || tags[x] === currentUser.userid) &&
          !~nodeTags.indexOf(x) &&
          ~x.toLowerCase().indexOf(tagFilter.toLowerCase()),
      ),
    ]);
  }, 500);*/

  const handleAddTag = (value, isOld = true) => {
    let tag = value;
    const newTags = node?.value.prop._sys_tag || {};
    const filterTag = tags.find((x) => ~x.tag.toLowerCase().indexOf(value.toLowerCase()));
    if (filterTag) tag = filterTag.tag;
    if (!newTags[tag]) {
      setTagFilter('');
      tagAdd(tag, false, isOld).then((res) => {
        if (res?.status === 'ok') {
          message.success('添加成功');
        }
      });
    } else {
      message.warn('此标签已存在');
    }
  };

  const handleEditTag = (value, tag) => {
    setEditingTag(null);
    tagEdit &&
      tagEdit(value, tag).then((res) => {
        if (res?.status === 'ok') {
          message.success('修改成功');
        }
      });
  };

  const handleDelTag = (tag) => {
    tagDel &&
      tagDel(tag).then((res) => {
        if (res?.status === 'ok') {
          message.success('删除成功');
        }
      });
  };

  const onVisibleChange = (e) => {
    if (!e && onCancel) onCancel();
    setTagFilter('');
    setTagOwn(false);
    setEditingTag(null);
  };

  const content = useMemo(() => {
    return (
      <div className="sys-tag-select">
        <Input
          ref={searchRef}
          onChange={(e) => setTagFilter(getSubString32(e.target.value))}
          prefix={<i className="iconfont iconsearch" />}
          bordered={false}
          value={tagFilter}
          placeholder={hasPermission ? '搜索/添加标签' : '搜索标签'}
          onPressEnter={(e) => hasPermission && handleAddTag(e.target.value, false)}
        />
        <ul className="tag-list">
          {showTags.map((x) => {
            return (
              <li key={x.tag} className="tag-list-item">
                {editingTag === x.tag ? (
                  <Input
                    ref={tagInputRef}
                    // bordered={false}
                    size={'small'}
                    defaultValue={x.tag}
                    onPressEnter={(e) => handleEditTag(e.target.value, x.tag)}
                  />
                ) : (
                  <div
                    className={`tag-list-item-tag text-omit ${hasPermission ? '' : 'no-btn'}`}
                    onClick={() => handleAddTag(x.tag)}
                  >
                    {x.tag}
                  </div>
                )}
                {isAdmin ? (
                  <div className="tag-list-item-btns">
                    {editingTag === x.tag ? (
                      <i
                        className="iconfont iconzhongmingming"
                        onClick={() => handleEditTag(tagInputRef.current.state.value, x.tag)}
                      />
                    ) : (
                      <i
                        className="iconfont iconzhongmingming"
                        onClick={() => setEditingTag(x.tag)}
                      />
                    )}
                    <Popconfirm
                      title="确定删除吗？"
                      onConfirm={() => handleDelTag(x.tag)}
                      // onCancel={cancel}
                      okText="确定"
                      cancelText="取消"
                    >
                      <i className="iconfont iconshanchu" />
                    </Popconfirm>
                  </div>
                ) : (
                  <></>
                )}
              </li>
            );
          })}
        </ul>
        {hasPermission ? (
          <div className="tag-tip">
            {tagFilter ? (
              '按回车创建标签'
            ) : nodeTags.length < tags.length ? (
              <>
                <Checkbox
                  checked={tagOwn}
                  onChange={(e) => {
                    setTagOwn(e.target.checked);
                  }}
                />
                仅显示我创建的标签
              </>
            ) : (
              '在搜索框输入文字，按回车创建标签'
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }, [node, showTags, editingTag]);

  const moreProps = {};
  if (defaultVisible !== undefined) {
    moreProps.visible = defaultVisible;
  }

  return (
    <LinkPiPop
      getPopupContainer={false}
      trigger={'click'}
      content={content}
      placement={placement}
      insideDom={insideDom}
      onVisibleChange={onVisibleChange}
      noPadding={true}
      {...moreProps}
    />
  );
};

export default SysTag;
