import { useState } from 'react';

import PermissionModal from '@/pages/home/components/ThemeTree/PermissionModal';
import { isAdmin } from '@/utils/utils';

import '../GroupForView/styles.less';
export default (props: any) => {
  const { getterPiNode, orgInfo, checkId } = props;

  if (checkId.length < 1 || !isAdmin(orgInfo)) return null;

  // 节点编辑权限
  const [showPermissionModal, setShowPermissionModal] = useState(false);
  // 选择中的节点
  const [checkedNodes, setCheckedNodes] = useState([]);

  const editNodesAcl = () => {
    setCheckedNodes(checkId.map((id: string) => getterPiNode.value.nodeManager.findChildren(id)));
    setShowPermissionModal(true);
  };

  return (
    <>
      <div onClick={editNodesAcl} className="view-setting-block">
        <i className="iconfont iconView_Edit" />
        &nbsp;编辑权限
      </div>
      {showPermissionModal && (
        <PermissionModal
          visible
          data={null}
          onCancel={() => setShowPermissionModal(false)}
          batchNodes={checkedNodes}
        />
      )}
    </>
  );
};
