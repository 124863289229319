function getOS() {
  const userAgent = window.navigator.userAgent;

  if (/Win/i.test(userAgent)) {
    return 'win';
  } else if (/Mac/i.test(userAgent)) {
    return 'mac';
  } else if (/Linux/i.test(userAgent)) {
    return 'linux';
  } else if (/Android/i.test(userAgent)) {
    return 'android';
  } else if (/iOS/i.test(userAgent)) {
    return 'ios';
  } else {
    return 'unknown';
  }
}

const gen = (key: string) => {
  if (['ios', 'mac'].includes(getOS())) {
    return key.replace('ctrl', 'meta');
  }
  return key;
};

/**
 * 当 mac 平台上，ctrl 使用 cmd 键
 */
export const genModifyKeys = (keys: string | string[]) => {
  if (Array.isArray(keys)) return keys.map(gen);
  return gen(keys);
};
