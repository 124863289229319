import type { CurrentUser } from '@linkpi/core';
import { DEFAULT_TEMPLATE } from '@linkpi/core';
import { toRecord } from '@linkpi/utils';
import { memoize } from 'lodash';
import { useMemo } from 'react';
import { match } from 'ts-pattern';

import { useOrgInfo } from './useOrg';

const getTemlateList = memoize((orgInfo: CurrentUser.OrgInfo | null) => {
  if (!orgInfo || !Array.isArray(orgInfo.templateList)) return [];
  return match(orgInfo)
    .with({ newDefault: 1 }, ({ templateList }) =>
      templateList?.filter((x) => x.template_id !== DEFAULT_TEMPLATE),
    )
    .otherwise(({ templateList }) => templateList);
});

export const useTemplateList = (orgId?: string) => {
  if (location.pathname.includes('/space')) {
    console.warn('获取了应用测的主题类型配置，应该使用配置测的主题类型配置');
  }

  const [orgInfo] = useOrgInfo(orgId);

  const list = useMemo(() => getTemlateList(orgInfo), [orgInfo]);

  return list;
};

const buildTempMap = memoize(
  toRecord((t: CurrentUser.TemplateInfo) => ({ [t.template_id]: t })),
);

export const useTemplateMap = (orgId?: string) => {
  const list = useTemplateList(orgId);

  const map = useMemo(() => buildTempMap(list), [list]);

  return map;
};

export const useOrgTempMap = useTemplateMap;

export const useTemplateInfo = (templateId: string) => {
  const templateMap = useTemplateMap();

  const templateInfo = useMemo(
    () => templateMap[templateId],
    [templateMap[templateId]],
  );

  return templateInfo;
};
