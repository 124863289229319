import styles from './styles.less';

interface IProps {
  colors: string[];
  gap?: number;
  colorSize?: number;
  rowNum?: number;
  onChange: (color: string) => void;
  value: string;
}

export default (props: IProps) => {
  const { gap = 8, colors, colorSize = 20, rowNum = 3, onChange, value } = props;

  const getSize = (n: number) => (n + 1) * gap + n * colorSize;

  return (
    <div
      className={styles.container}
      style={{
        padding: gap / 2,
        height: colors.length === 6 ? getSize(2) : getSize(colors.length / 3),
        width: getSize(3),
      }}
    >
      {colors.map((color, index) => (
        <div
          style={{
            height: colorSize + gap,
            width: colorSize + gap,
            borderRadius: 4,
            cursor: 'pointer',
            padding: gap / 2,
            position: 'relative',
            border: `2px solid ${value === color ? '#316EF5' : 'white'}`,
          }}
          onClick={() => onChange(color)}
          key={color}
        >
          {index === 0 && color !== '#242D3F' ? (
            <i
              style={{
                fontSize: colorSize,
                color: '#BFC6D2',
                position: 'absolute',
                transform: 'translate(-50%, -50%)',
                top: '50%',
                left: '50%',
              }}
              className="icona-zu1535 iconfont"
            />
          ) : (
            <div
              style={{
                height: colorSize,
                width: colorSize,
                backgroundColor: color,
                borderRadius: 4,
                position: 'absolute',
                transform: 'translate(-50%, -50%)',
                top: '50%',
                left: '50%',
              }}
            />
          )}
        </div>
      ))}
    </div>
  );
};
