import { create, show, useModal } from '@ebay/nice-modal-react';
import type { FC } from 'react';
import Viewer from 'react-viewer';

import type { GetReactClassComponentProps } from '@/utils/typeUtils';

type IImageViewer = Omit<
  GetReactClassComponentProps<typeof Viewer>,
  'onClose' | 'onMaskClick' | 'visible'
>;

/**
 * 预览图片
 */
const _ImageViewer: FC<IImageViewer> = ({ images = [], ...rest }) => {
  const modal = useModal();

  const close = () => {
    modal.hide();
    modal.resolve();
  };

  return (
    <Viewer
      visible={modal.visible}
      onClose={close}
      images={images}
      onMaskClick={(e) => {
        // 鼠标右键不关闭mask
        if (e.button !== 2) {
          close();
        }
      }}
      noNavbar={images.length <= 1}
      changeable={images.length > 1}
      attribute={false}
      scalable={false}
      noClose
      {...rest}
    />
  );
};

const ImageViewer = create(_ImageViewer);

export const previewImages = (o: IImageViewer) => {
  return show(ImageViewer, o);
};
