import { Date_Format_Options } from '@linkpi/core';
import type { DatePickerProps } from 'antd';
import { Button, message } from 'antd';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import { pick } from 'ramda';
import type { FC } from 'react';

import DatePicker from '@/components/DatePicker';
import TimePicker from '@/components/TimePikcer';

import './index.less';

export type PropType = DatePickerProps & {
  dateFormat: string;
  onChange?: (v?: Dayjs) => void;
  onMeridiemChange?: (v?: Dayjs) => void;
  onConfirm?: (v?: string) => void;
  defaultValue?: any;
  domId?: string;
  placeholder?: string;
  dateValue?: any;
};

type DateType = Omit<DatePickerProps, 'value' | 'defaultValue'> & {
  defaultValue?: Dayjs | null;
  value?: Dayjs | null;
  id?: string;
};

const LinkPiDate: FC<PropType> = (props) => {
  const {
    dateFormat,
    onChange,
    onConfirm,
    defaultValue = undefined,
    placeholder,
    domId,
    dateValue,
    allowClear = true,
    disabledDate,
    onMeridiemChange,
    bordered = true,
    disabled = false,
  } = props;

  const dateModule = Date_Format_Options.find((x) => x.value === dateFormat)?.module || 'day';
  const picker =
    (Date_Format_Options.find((x) => x.value === dateFormat)?.range as DatePickerProps['picker']) ||
    'date';
  const dateProps: DateType = {
    bordered,
    allowClear,
    getPopupContainer: (triggerNode) => triggerNode?.parentNode?.parentNode as HTMLElement,
    format: dateFormat,
    // @ts-ignore
    showToday: true,
    placeholder: placeholder || '请选择',
    onBlur: (e: any) => onConfirm && onConfirm(e),
    onOk: (e: any) => onConfirm && onConfirm(e),
    onChange: (e: any) => onChange?.(e),
    showTime: dateModule === 'min',
    disabledDate: (c: any) => !!disabledDate && disabledDate(c),
    onOpenChange: (o: boolean) => {
      props.onOpenChange?.(o);
      if (!o) {
        onConfirm?.();
      }
    },
    disabled,
    picker,
    ...pick(['style', 'autoFocus', 'open', 'defaultOpen'], props),
  };

  if (defaultValue !== undefined) {
    dateProps.defaultValue = defaultValue ? dayjs(defaultValue) : null;
  } else {
    dateProps.value = dateValue ? dayjs(dateValue) : null;
  }
  if (domId) dateProps.id = domId;

  if (dateFormat.endsWith(' A')) {
    dateProps.popupClassName = 'meridiem-picker';
    dateProps.showToday = false;
    dateProps.renderExtraFooter = () => (
      <>
        <div className={'meridiem-picker-extra'}>
          <div
            className={`meridiem-picker-extra-item${
              dateValue && dayjs(dateValue).hour() < 12 ? ' active' : ''
            }`}
            onClick={() => {
              if (!dateValue) return message.warn('请先选择日期');
              onMeridiemChange
                ? onMeridiemChange(dayjs(dateValue).hour(8))
                : onChange?.(dayjs(dateValue).hour(8));
              onConfirm && onConfirm();
            }}
          >
            上午
          </div>
          <div
            className={`meridiem-picker-extra-item${
              dateValue && dayjs(dateValue).hour() > 12 ? ' active' : ''
            }`}
            onClick={() => {
              if (!dateValue) return message.warn('请先选择日期');
              onMeridiemChange
                ? onMeridiemChange(dayjs(dateValue).hour(14))
                : onChange?.(dayjs(dateValue).hour(14));
              onConfirm && onConfirm();
            }}
          >
            下午
          </div>
        </div>
        {onConfirm ? (
          <Button size="small" type={'primary'} onClick={() => onConfirm()}>
            确定
          </Button>
        ) : null}
      </>
    );
  }

  // @ts-ignore
  return dateModule === 'time' ? <TimePicker {...dateProps} /> : <DatePicker {...dateProps} />;
};

export default LinkPiDate;
