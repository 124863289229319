import type { PiGridModules } from '../core';
import { sortTriangleConfig } from '../helper/config';
import type { HeaderCellType } from '../modules/dataManager';
import type { PositionType } from './_baseComponent';
import GridBaseComponent from './_baseComponent';

export default class SortTriangle extends GridBaseComponent {
  private data: HeaderCellType;
  constructor(position: PositionType, moduleInstances: PiGridModules, data: HeaderCellType) {
    super(position, moduleInstances);
    this.data = data;
  }

  public click() {}

  public render() {
    const { Draw, DataManager } = this.moduleInstances;
    const { x, y } = this.position;
    const styleMode = DataManager.config.styleMode;

    const { height, width } = sortTriangleConfig;
    let { selectBg, bg } = sortTriangleConfig;
    selectBg = styleMode === 'darkness' ? 'white' : selectBg;
    bg = styleMode === 'darkness' ? 'rgba(214,224,255,0.3)' : bg;

    const topBg = DataManager.sortInfo[this.data.key]?.sort === 'asc' ? selectBg : bg;
    const bottomBg = DataManager.sortInfo[this.data.key]?.sort === 'desc' ? selectBg : bg;
    // top
    Draw.fillTriangle(
      {
        x,
        y,
        height,
        width,
      },
      topBg,
      'top',
    );

    // bottom
    Draw.fillTriangle(
      {
        x,
        y: y + this.position.height - height,
        height,
        width,
      },
      bottomBg,
      'bottom',
    );
  }
}
