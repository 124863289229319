import type { ApiResponse } from '@linkpi/core';
import type { CurrentUser } from '@linkpi/core';
import { PROP_TYPE } from '@linkpi/core';
import { convertTimeByModule } from '@linkpi/core';
import { getMatchingOriginProp } from '@linkpi/core';
import { Date_Format_Options } from '@linkpi/core';
import { InputNumber, message } from 'antd';
import dayjs from 'dayjs';
import type { FC } from 'react';
import { useState } from 'react';
import { match } from 'ts-pattern';

import LinkPiDate from '@/components/LinkPiDate';
import LinkPiPop from '@/components/LinkPiPop';
import type { PropOption } from '@/pages/space/components/TemplateProp/components/TempPropModal/types';

interface INumberLimit {
  propType: ApiResponse.CurrentUser.propType;
  tempProps: PropOption[];
  tempMap: Record<string, ApiResponse.CurrentUser.TemplateInfo>;
  numberLimit: ApiResponse.CurrentUser.propNumberLimit;
  setNumberLimit: (v: CurrentUser.propNumberLimit) => void;
  precision: number;
  numericalFormat: number;
  dateFormat?: string;
}

interface IMenuOption {
  label: string;
  index: number;
  handler: () => void;
  icon: string;
  type: string;
}

export const NumberLimit: FC<INumberLimit> = (props) => {
  const {
    propType,
    tempProps,
    tempMap,
    numberLimit,
    setNumberLimit,
    precision,
    numericalFormat,
    dateFormat,
  } = props;

  const [upperOpen, setUpperOpen] = useState(false);
  const [lowerOpen, setLowerOpen] = useState(false);

  const defaultOptions = {
    placeholder: `请${propType === 'date' || propType === 'datetime' ? '选择' : '输入'}，或引用`,
    bordered: false,
  };

  const filterMenu = (p: PropOption) => {
    switch (propType) {
      case 'text':
        return p.type !== 'attachment';
      case 'number':
        let type = p.type;
        if (type === 'quote') {
          // @ts-ignore
          const originProp: PropOption = getMatchingOriginProp(p, tempMap);
          if (originProp)
            type =
              originProp.type === 'formula' && originProp.formulaFormat === 0
                ? 'number'
                : originProp.type;
        }
        return type === 'number' || (p.type === 'formula' && p.formulaFormat === 0);
      case 'date':
      case 'datetime':
        return p.type === 'date' || p.type === 'datetime';
      default:
        return p.type === propType;
    }
  };

  const menus: IMenuOption[] = tempProps
    .filter((p) => filterMenu(p))
    .map((p) => {
      return {
        label: p.name,
        index: p.index,
        handler: () => {},
        // @ts-ignore
        icon: PROP_TYPE[p.type]?.icon || 'iconattribute_selected_value',
        type: p.type,
      };
    });

  //时间类型属性值修改事件
  const handleDateChange = (e: any, type: 'upper' | 'lower', module = 'day', hour = -1) => {
    const newDate = e ? convertTimeByModule(e, module, hour) : undefined;
    if (newDate) {
      if (
        type === 'upper' &&
        numberLimit.lower.type === 'const' &&
        numberLimit.lower.const &&
        newDate < numberLimit.lower.const
      )
        return message.warn('最晚时间不得早于最早时间');
      if (
        type === 'lower' &&
        numberLimit.upper.type === 'const' &&
        numberLimit.upper.const &&
        newDate > numberLimit.upper.const
      )
        return message.warn('最早时间不得晚于最晚时间');
    }
    setNumberLimit({
      ...numberLimit,
      [type]: {
        ...numberLimit[type],
        const: newDate,
      },
    });
  };

  const genLimitInputDom = (type: 'upper' | 'lower') =>
    match(propType)
      .with('number', 'currencyV2', () => (
        <InputNumber
          precision={precision || 0}
          value={numberLimit[type].const}
          {...defaultOptions}
          onChange={(e) => {
            if (e !== null) {
              if (
                type === 'upper' &&
                numberLimit.lower.type === 'const' &&
                numberLimit.lower.const !== undefined &&
                numberLimit.lower.const !== null &&
                e < numberLimit.lower.const
              )
                return message.warn('上限不得小于下限');
              if (
                type === 'lower' &&
                numberLimit.upper.type === 'const' &&
                numberLimit.upper.const !== undefined &&
                numberLimit.upper.const !== null &&
                e > numberLimit.upper.const
              )
                return message.warn('下限不得大于上限');
            }
            setNumberLimit({
              ...numberLimit,
              [type]: {
                ...numberLimit[type],
                const: e,
              },
            });
          }}
        />
      ))
      .otherwise(() => {
        const propDateFormat =
          dateFormat || (propType === 'date' ? 'YYYY/MM/DD' : 'YYYY/MM/DD HH:mm');
        const dateModule =
          Date_Format_Options.find((x) => x.value === propDateFormat)?.module || 'day';
        return (
          <LinkPiDate
            dateFormat={propDateFormat}
            onChange={(e: any) =>
              handleDateChange(
                e,
                type,
                dateModule,
                dayjs(numberLimit[type].const || e || Date.now()).hour(),
              )
            }
            onMeridiemChange={(e: any) => handleDateChange(e, type, dateModule, dayjs(e).hour())}
            dateValue={numberLimit[type].const}
            bordered={false}
          />
        );
      });

  const genLimitInput = (type: 'upper' | 'lower') => {
    const setOpen = (e: boolean) => (type === 'upper' ? setUpperOpen(e) : setLowerOpen(e));
    return (
      <LinkPiPop
        menus={menus.map((m) => {
          return {
            ...m,
            active: numberLimit[type].prop === m.index,
            handler: () => {
              setNumberLimit({
                ...numberLimit,
                [type]: {
                  ...numberLimit[type],
                  type: 'prop',
                  prop: m.index,
                },
              });
              setOpen(false);
            },
          };
        })}
        menuWidth={333}
        menuHeight={menus.length > 6 ? 220 : menus.length * 40}
        visible={type === 'upper' ? upperOpen : lowerOpen}
        onVisibleChange={(e: boolean) => (numberLimit[type].type !== 'const' || !e) && setOpen(e)}
        trigger={'click'}
        insideDom={
          <div className={`form-inline-input${numberLimit[type].type !== 'const' ? ' quote' : ''}`}>
            {numberLimit[type].type === 'prop' ? (
              <div className={'text-omit'}>
                {tempProps.find((p) => p.index === numberLimit[type].prop)?.name}
              </div>
            ) : (
              genLimitInputDom(type)
            )}
            <div className={'form-item-addon'}>
              <i
                className={`iconfont ${
                  numberLimit[type].type !== 'const' ? 'iconformula-subtract' : 'iconformula-add'
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  if (numberLimit[type].type !== 'const') {
                    setOpen(false);
                    setNumberLimit({
                      ...numberLimit,
                      [type]: {
                        ...numberLimit[type],
                        type: 'const',
                      },
                    });
                  } else {
                    if (!menus.length) return message.warn('当前主题类型没有可引用的属性');
                    setOpen(true);
                  }
                }}
              />
              {numericalFormat ? <div className={'form-item-symbol'}>%</div> : null}
            </div>
          </div>
        }
      />
    );
  };

  return (
    <>
      <div className={`form-item${numericalFormat ? ' numerical' : ''}`}>
        <div className={'form-label'}>
          {match(propType)
            .with('number', 'currencyV2', () => '数字')
            .otherwise(() => '日期')}
          范围
        </div>
        <div className={'form-inline'}>
          {genLimitInput('lower')}
          <div className={'form-inline-divider'}>~</div>
          {genLimitInput('upper')}
        </div>
      </div>
    </>
  );
};
