import { history } from '@umijs/max';
import React from 'react';

type Blocker = Parameters<typeof history.block>[0];

function useBlocker(blocker: Blocker, when = true): void {
  React.useEffect(() => {
    if (!when) return;

    const unblock = history.block((tx) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          // Automatically unblock the transition so it can play all the way
          // through before retrying it. TODO: Figure out how to re-enable
          // this block if the transition is cancelled for some reason.
          unblock();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return unblock;
  }, [navigator, blocker, when]);
}

type Message =
  | ((
      location: Parameters<Blocker>[0]['location'],
      action: Parameters<Blocker>[0]['action'],
    ) => string | boolean)
  | string;

export function usePrompt(message: Message, when = true) {
  const blocker: Blocker = React.useCallback(
    (tx) => {
      let response;
      if (typeof message === 'function') {
        response = message(tx?.location, tx?.action);
        if (typeof response === 'string') {
          response = window.confirm(response);
        }
      } else if (typeof message === 'string') {
        response = window.confirm(message);
      }
      if (response) {
        tx.retry();
      }
    },
    [message],
  );

  useBlocker(blocker, when);
}

export interface PromptProps {
  message: Message;
  when?: boolean;
}

export function Prompt({ message, when }: PromptProps) {
  usePrompt(message, when);
  return null;
}
