import type { ApiResponse } from '@linkpi/core';
import type { GetterPiNode } from '@linkpi/core/web';

import { conditionConvertConditionV2 } from '@/components/ConditionFilter';
import { convertGetTempFilterNodeConditionV2, displayGroupName, toRecord } from '@/utils/utils';

import {
  convertGroupByToGetTempFilterNodeGroupBy,
  convertTempFilterNodesToGroupData,
} from './utils';

const countGroupData = async (
  curViewData: ApiResponse.ViewList.ViewListItem<any>,
  filterPathSettings: any[],
  orgInfo: ApiResponse.CurrentUser.OrgInfo,
  node: GetterPiNode,
  currentUser: ApiResponse.CurrentUser,
  userList: ApiResponse.OrgUser,
  userMap: Record<string, ApiResponse.OrgUser.OrgUserItem>,
  flowData: any[],
  historyData: any[],
  groups: any[],
) => {
  // 多主题类型和日历，不走countGroupDataNew
  const allTemplates = curViewData.view_info?.allTemplates;
  const useOldFn = allTemplates || curViewData.view_type === 6;
  if (!useOldFn) {
    return countGroupDataNew(
      curViewData,
      filterPathSettings,
      orgInfo,
      node,
      currentUser,
      userList,
      userMap,
      flowData,
      historyData,
      groups,
    );
  }

  const f = curViewData.view_info?.condition?.find((x) => x && x.key === 'statusFlow');
  const t = curViewData.view_info?.condition?.find((x) => x && x.key === 'templateId');
  const h = curViewData.view_info?.condition?.find((x) => x && x.key === 'historyStatus');
  // 合并 curViewData.view_info.condition 和 过滤器节点缩小范围的条件
  let allConditions = (curViewData.view_info?.condition || []).concat(filterPathSettings);
  if (h?.value) {
    allConditions = allConditions.filter(
      (x) => x.key !== 'templateStatus' && x.key !== 'templateStatusPropBase_0',
    );
  }
  const orderBy = curViewData.view_info?.orderBy;
  const groupBy = curViewData.view_info?.group;
  const groupOrderDesc = curViewData.view_info.groupOrderDesc ?? false;
  const orderDesc =
    'orderDesc' in (curViewData.view_info || {}) ? curViewData.view_info?.orderDesc : true;
  const pid =
    'parentId' in (curViewData.view_info || {})
      ? curViewData.view_info?.parentId
        ? Object.keys(curViewData.view_info.parentId)
        : [orgInfo?.rootId]
      : [orgInfo?.rootId];

  // 看板 和 表格不限制主题类型
  const noLimitTemplateDetail = {
    allTemplates: curViewData.view_info?.allTemplates,
  };
  /**
   * 通过worker获取数据 存在数据量太大的问题，连接派的metadatamap有近50Mb 传输要800ms
   * 先改回原来的方式 1.6优先处理
   */
  const groupData = node.value.nodeManager
    .getRoot()
    .getNodeListByCondition(
      orgInfo.templateList,
      allConditions,
      orderBy,
      groupBy,
      userList,
      orderDesc,
      '',
      pid,
      currentUser,
      groups,
      noLimitTemplateDetail,
    );

  // const { getNodeListByConditionWorker } = window.__PI__WORKERS;

  // const groupDataMetadata: ViewGroupDataTypeWithMetadata = await getNodeListByConditionWorker.run({
  //   rootId: node.value.nodeManager.getRoot().id,
  //   metadataMap: node.value.nodeManager._data,
  //   templateList: orgInfo.templateList,
  //   conditions: allConditions,
  //   spaceUsers: userList,
  //   orderByKey: orderBy,
  //   groupByKey: groupBy,
  //   orderDesc: orderDesc,
  //   searchTitle: searchTitle,
  //   parentId: pid,
  //   userId: currentUser.userid,
  //   orgInfo: orgInfo,
  //   groups: groups,
  //   parentChildrenMap: node.value.nodeManager._parentIndex,
  //   wildNode: node.value.nodeManager._wildNode,
  // });

  // const groupData = converViewGroupDataTypeWithMetadataToViewGroupDataType(
  //   groupDataMetadata,
  //   node.value.nodeManager,
  // );

  const filterGroupKeys: string[] = [];
  if (t?.value && (h?.value || f?.value)) {
    Object.keys(groupData).map((groupKey) => {
      if (Array.isArray(groupData[groupKey]?.list)) {
        groupData[groupKey].list = groupData[groupKey].list.filter(
          (x) =>
            (!f?.value || flowData.includes(x.node_id)) &&
            (!h?.value || historyData.includes(x.node_id)),
        );
        if (!groupData[groupKey].list.length) {
          filterGroupKeys.push(groupKey);
        }
      }
    });
  }

  const resData = Object.keys(groupData)
    .filter((x) => !filterGroupKeys.includes(x))
    .sort((a, b) => {
      if ('sort' in groupData[a]) return groupData[a].sort - groupData[b].sort;
      const groupNameA = String(displayGroupName(groupData[a], userMap, groups)),
        groupNameB = String(displayGroupName(groupData[b], userMap, groups));

      if (groupNameA === '未定义') return -1;
      if (groupNameB === '未定义') return 1;
      if (groupNameA === '未知成员') return -1;
      if (groupNameB === '未知成员') return 1;

      if (groupData[a].nameType === 'number')
        return (
          (Number(groupData[a].name) - Number(groupData[b].name)) *
          // 根据分组配置排序
          (groupOrderDesc ? -1 : 1)
        );

      return (
        groupNameA.localeCompare(groupNameB) *
        // 根据分组配置排序
        (groupOrderDesc ? -1 : 1)
      );
    })
    .reduce((res, groupKey, index) => {
      res[groupKey] = {
        ...groupData[groupKey],
        sort: index,
      };
      return res;
    }, {} as ViewGroupDataType);

  console.log({ resData });
  return resData;
};

const countGroupDataNew = async (
  curViewData: ApiResponse.ViewList.ViewListItem<1 | 2 | 4 | 6>,
  filterPathSettings: any[],
  orgInfo: ApiResponse.CurrentUser.OrgInfo,
  node: GetterPiNode,
  currentUser: ApiResponse.CurrentUser,
  userList: ApiResponse.OrgUser,
  userMap: Record<string, ApiResponse.OrgUser.OrgUserItem>,
  flowData: any[],
  historyData: any[],
  groups: any[],
) => {
  const groupBy = curViewData.view_info?.group;
  const orderBy = curViewData.view_info?.orderBy;
  const orderDesc = curViewData.view_info?.orderDesc;
  const templateMap = toRecord((temp: ApiResponse.CurrentUser.TemplateInfo) => ({
    [temp.template_id]: temp,
  }))(orgInfo.templateList);

  // @ts-ignore
  const conditionV2 =
    curViewData.view_info?.conditionV2 ||
    conditionConvertConditionV2(
      curViewData.view_info?.condition,
      curViewData.view_info?.parentId,
      orgInfo.rootId,
      templateMap,
    );
  const internalGroupBy = convertGroupByToGetTempFilterNodeGroupBy(groupBy || '');
  const tempCondition = conditionV2.find((x: any) => x.key === 'templateId');
  const tempId = tempCondition?.input?.[0] || '';
  const template = templateMap[tempId];

  // 需要前端再处理conditionV2给getTempFilterNode
  const getTempFilterNodeConditionV2 = convertGetTempFilterNodeConditionV2({
    conditionV2,
    currentUserId: currentUser.userid,
  });

  const filterNode = await node.value.nodeManager.getTempFilterNode({
    conditionV2: getTempFilterNodeConditionV2,
    groupBy: internalGroupBy ? [internalGroupBy] : [], // nodeManager getNodeGroup
  });

  // 没有分组的时候 取filterNode.metadata.e._sys_filter_node;
  // 有分组的时候 children是分组 children的.metadata.e._sys_filter_node
  let nodes: any;
  if (!internalGroupBy) {
    nodes = { default: Array.from(filterNode.metadata.e._sys_filter_node || []) };
  } else {
    nodes = filterNode.children.reduce((a, b) => {
      a[b.title] = Array.from(b.metadata.e._sys_filter_node || []);
      return a;
    }, {} as any);
  }

  const newGroupData = convertTempFilterNodesToGroupData({
    filterNodes: nodes,
    userMap,
    node,
    groupBy,
    orderBy,
    orderDesc,
    groupOrderDesc: curViewData.view_info.groupOrderDesc,
    groups,
    template,
    templateMap,
  });

  console.log({
    curViewData,
    userMap,
    filterNode,
    conditionV2,
    getTempFilterNodeConditionV2,
    nodes,
    groupBy,
    orderBy,
    orderDesc,
    internalGroupBy,
    newGroupData,
  });

  return newGroupData;
};

export default countGroupData;
