import type { PositionType } from '../components/_baseComponent';
import GridBaseComponent from '../components/_baseComponent';
import type { PiGridModules } from '../core';

class ActionMask extends GridBaseComponent {
  public src: string;
  public fileName: string = 'name';
  public type: string = 'actionMask';
  public captrue: boolean = false;

  constructor(position: PositionType, moduleInstances: PiGridModules, file: any) {
    super(position, moduleInstances);
    this.src = file.src;
    this.fileName = file.fileName;
  }

  public drawDownloadIcon(x: number, y: number, color: string, unicode: string, size: number) {
    const { Draw } = this.moduleInstances;
    const { ctx } = Draw;

    ctx.save();
    Draw.attr({
      fillStyle: color,
      textBaseline: 'top',
      textAlign: 'center',
      font: `${Draw.npx(size)}px iconfont`,
    });

    Draw.fillText(Draw.iconFont(unicode), x, y);
    ctx.restore();
  }

  public click() {
    fetch(this.src)
      .then((res) => res.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('download', this.fileName);
        a.setAttribute('href', url);

        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      });
  }

  public render() {
    const { x, y, height, width } = this.position;
    const { Draw } = this.moduleInstances;

    const iconSize = width === 128 ? 22 : width === 64 ? 16 : 14;

    Draw.fillRect(x, y, width, height, 'rgba(36, 45, 63, 0.6)');
    this.drawDownloadIcon(
      x + width / 2,
      y + (height - iconSize) / 2,
      'white',
      '&#xe6ca;',
      iconSize,
    );
  }
}

export default ActionMask;
