import type { FormItemProps } from 'antd';

type Rules = FormItemProps['rules'];

const _default: Rules = [{ required: true }];
const array: Rules = [
  {
    type: 'array',
    required: true,
  },
];

export const Required = {
  default: _default,
  array,
};
