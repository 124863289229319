import type { PositionType } from '../components/_baseComponent';
import GridBaseComponent from '../components/_baseComponent';
import type { PiGridModules } from '../core';

class ShowUnitMoreDetail extends GridBaseComponent {
  public data: {
    node: PiNode;
    unit: any;
    unitPosition: PositionType;
  };
  public type: string = 'showUnitMoreDetail';
  public captrue: boolean = false;
  constructor(
    position: PositionType,
    moduleInstances: PiGridModules,
    data: {
      node: PiNode;
      unit: any;
      unitPosition: PositionType;
    },
  ) {
    super(position, moduleInstances);

    this.data = data;
  }

  public click() {
    this.moduleInstances.DataManager.config.showUnitMoreDetail(this.data);
  }

  public drawIcon(x: number, y: number, unicode: string, fontSize: number) {
    const { Draw } = this.moduleInstances;
    const { ctx } = Draw;

    ctx.save();
    Draw.attr({
      fillStyle: '#BFC6D2',
      textBaseline: 'top',
      textAlign: 'start',
      font: `${Draw.npx(fontSize)}px iconfont`,
    });

    Draw.fillText(Draw.iconFont(unicode), x, y);
    ctx.restore();
  }

  public render() {
    const { Render } = this.moduleInstances;
    const { x, y } = this.position;

    if (this.isHover) {
      Render.setCursor('pointer');
    }

    this.drawIcon(x, y, '&#xe7d8;', 14);
  }
}

export default ShowUnitMoreDetail;
