import styles from './styles.less';

interface IProps {
  tip: string;
  img: string;
}

export default (props: IProps) => {
  const { tip, img } = props;

  const map: any = {
    stop: 'https://www.mylinkpi.com/theme_square/stopUse/stop.png',
  };

  return (
    <div className={styles.mask}>
      <div
        style={{
          background: `url(${map[img]})`,
          backgroundSize: 'cover',
          height: 200,
          width: 200,
          marginBottom: 12,
        }}
      />
      <span children={tip} />
    </div>
  );
};
