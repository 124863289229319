import { throttle } from 'lodash';

import $ from '../helper/dom';
import Log from '../helper/log';
import BaseModule from './_baseModule';

import '../style/ui.less';

interface afterActionType {
  priorityWeight: number;
  action: () => void;
}
export default class Render extends BaseModule {
  public static CSS = {
    wrapper: 'pi-gantt-wrapper',
    canvasWrapper: 'pi-gantt-canvas-wrapper',
    canvas: 'pi-gantt-canvas',
  };

  public leftPanelStyle = {
    width: 270,
  };

  /**
   * x,y 滚动位置
   * x轴时间无限滚动
   */
  public scrollDistance = {
    left: 0,
  };

  /**
   * 如果是第1次渲染，渲染后滚动到今日位置
   */
  private isFirstRender = true;

  // 需要设置的光标样式 设置时取最后一个
  private cursorList: string[] = [];
  // 强制光标样式
  private forceCursors: string[] = [];

  public setCursor(cursor: string, force: boolean = false) {
    if (force) {
      this.forceCursors.push(cursor);
    } else {
      this.cursorList.push(cursor);
    }
  }

  private renderCursor() {
    const forceCursor = this.forceCursors.length
      ? this.forceCursors[this.forceCursors.length - 1]
      : null;
    const lastCursor = this.cursorList.length ? this.cursorList[this.cursorList.length - 1] : null;
    this.doms.wrapper.style.cursor = forceCursor || lastCursor || 'default';
  }

  /**
   * 需要在render流程结束后触发的动作
   * 比如渲染更高层级的层级，按顺序，可设置优先级
   */
  private renderAfterActionList: afterActionType[] = [];
  public setRenderAfterAction(action: () => void, priorityWeight: number = 0) {
    this.renderAfterActionList.push({
      action,
      priorityWeight,
    });
  }
  private runRenderAfterActionList() {
    const order = this.renderAfterActionList.sort((a, b) => b.priorityWeight - a.priorityWeight);
    order.map((x) => x.action());
  }

  public prepare() {
    if (!this.config.container) {
      Log.error('config.container 不能为空');
      return;
    }

    // make wrapper
    this.doms.container = $.getContainer(this.config.container) as HTMLElement;
    this.doms.wrapper = $.make('div', [Render.CSS.wrapper]);
    this.doms.container.append(this.doms.wrapper);

    // canvas wrapper
    this.doms.canvasWrapper = $.make('div', [Render.CSS.canvasWrapper]);
    this.doms.wrapper.append(this.doms.canvasWrapper);

    // canvas
    this.doms.canvas = $.make('canvas', [Render.CSS.canvas]);
    this.doms.canvasWrapper.append(this.doms.canvas);

    this.moduleInstances.Event.bootstrap();
  }

  public render = throttle(() => {
    requestAnimationFrame(() => {
      const { DateColumnManager, Draw, NodeRowManager, Event } = this.moduleInstances;
      Draw.genStyle();

      // 清空components
      window.__PI__GANTT.components = [];
      // 清空afteraction
      this.renderAfterActionList = [];

      // 清空光标列表
      this.cursorList = [];
      this.forceCursors = [];

      // resize canvas
      const rect = this.doms.container.getBoundingClientRect();
      Draw.resize(rect.width, rect.height);
      Draw.clear();

      // bg
      Draw.fillRect(0, 0, Draw.canvasWidth, Draw.canvasHeight, Draw.style.backgroundColor);

      // 1. render DateColumnManager column
      DateColumnManager.renderColumns();

      // 2. render left box
      this.renderLeftBox();

      // 3. render NodeRowManager nodeRow
      NodeRowManager.render();

      // 4. render DateColumnManager head
      DateColumnManager.renderHead();

      // 5. render Left head
      this.renderLeftHead();

      if (this.isFirstRender) {
        this.isFirstRender = false;
        Event.handleMoveX(DateColumnManager.getTodayLineScrollOffSet(), true);
      }

      // 执行afterAction
      this.runRenderAfterActionList();

      // 设置光标样式
      this.renderCursor();

      if (Event.isDragCell && Event.isDragCell.dragMoveStatus) {
        if (
          Event.isDragCell.dragMoveStatus.direction === 'left' ||
          Event.isDragCell.dragMoveStatus.direction === 'right'
        ) {
          Event.handleMoveX(Event.isDragCell.dragMoveStatus.step);
        } else {
          Event.handleMoveY(Event.isDragCell.dragMoveStatus.step);
        }
      }

      if (Event.isDragDate && Event.isDragDate.dragMoveStatus) {
        Event.handleMoveX(Event.isDragDate.dragMoveStatus.step);
      }
    });
  }, 16);

  public renderLeftBox() {
    const { Draw } = this.moduleInstances;
    Draw.save();

    // left fill
    Draw.fillRect(0, 0, this.leftPanelStyle.width, Draw.canvasHeight, Draw.style.backgroundColor);
    // left line
    Draw.line(
      [
        [this.leftPanelStyle.width, 0],
        [this.leftPanelStyle.width, Draw.drawHeight],
      ],
      Draw.style.borderColor,
    );
    Draw.restore();
  }

  public renderLeftHead() {
    const { Draw } = this.moduleInstances;

    Draw.save();

    // left head fill
    Draw.fillRect(0, 0, this.leftPanelStyle.width - 1, 80, Draw.style.backgroundColor);
    Draw.fillRect(0, 0, this.leftPanelStyle.width - 1, 80, Draw.style.head.backgroundColor);

    // title line
    Draw.line(
      [
        [0, 80],
        [this.leftPanelStyle.width, 80],
      ],
      Draw.style.borderColor,
    );

    // render 标题
    Draw.attr({ fillStyle: Draw.style.headLeft.color });
    Draw.ctx.textBaseline = 'middle';
    Draw.ctx.textAlign = 'left';
    Draw.ctx.font = `600 ${Draw.npx(14)}px  Arial`;

    const paddingLeft = 30;

    Draw.fillText('序号', paddingLeft, 80 / 2);

    Draw.fillText('标题', paddingLeft + 62, 80 / 2);

    Draw.restore();
  }
}
