import type { ApiResponse } from '@linkpi/core';
import { genDefaultFlowSetting } from '@linkpi/core';
import { defaultTo } from 'ramda';
import type { FC } from 'react';
import { useEffect, useRef } from 'react';

export interface IStatusFlowGraph {
  statusConfig: ApiResponse.CurrentUser.taskStatus[];
}

export const StatusFlowGraph: FC<IStatusFlowGraph> = ({ statusConfig }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    (async function () {
      const G6 = await import('@antv/g6');

      if (!wrapperRef.current) return;
      const container = wrapperRef.current;

      const nodes = statusConfig
        .map((s, i) => ({
          id: i.toString(),
          name: s.name,
          dataType: 'alps',
        }))
        .filter((_, index) => !statusConfig[index].delete);

      const edges = statusConfig
        .map((s, statusIndex) => {
          if (s.delete) return [];

          const flowSetting = defaultTo(
            genDefaultFlowSetting(statusConfig, statusIndex, undefined, undefined),
          )(s.flowSetting);
          return flowSetting.map((targetStatus) => ({
            source: statusIndex.toString(),
            type: 'polyline',
            target: targetStatus.index.toString(),
          }));
        })
        .flat();

      const data = { nodes, edges };

      G6.registerNode(
        'status',
        {
          drawShape(cfg, group) {
            if (!group || !cfg) return;
            const shape = {
              width: 96,
              height: 30,
            };
            const rect = group.addShape('rect', {
              attrs: {
                x: (-1 * shape.width) / 2,
                y: (-1 * shape.height) / 2,
                ...shape,
                radius: 8,
                stroke: '#5B8FF9',
                fill: '#C6E5FF',
                lineWidth: 3,
              },
              name: 'rect-shape',
            });
            if (cfg.name) {
              group.addShape('text', {
                attrs: {
                  text: cfg.name,
                  x: 0,
                  y: 0,
                  fill: '#00287E',
                  fontSize: 12,
                  textAlign: 'center',
                  textBaseline: 'middle',
                  fontWeight: 'bold',
                },
                name: 'text-shape',
              });
            }
            return rect;
          },
        },
        'single-node',
      );

      const width = container.scrollWidth;
      const height = container.scrollHeight || 640;
      const graph = new G6.Graph({
        container: container,
        width,
        height,
        fitCenter: true,
        layout: {
          type: 'dagre',
          controlPoints: false,
          nodesep: 32,
          ranksep: 32,
        },
        defaultNode: {
          type: 'status',
        },
        defaultEdge: {
          type: 'polyline',
          /* configure the bending radius and min distance to the end nodes */
          style: {
            lineWidth: 2,
            radius: 12,
            offset: 24,
            endArrow: true,
            stroke: '#F6BD16',
          },
        },
        nodeStateStyles: {
          selected: {
            stroke: '#d9d9d9',
            fill: '#5394ef',
          },
        },
        modes: {
          default: ['drag-canvas', 'zoom-canvas', 'click-select'],
        },
        fitView: true,
      });
      graph.data(data);
      graph.render();
    })();
  });

  return <div ref={wrapperRef} />;
};
