import { antdModalV5, create as ModalCreate, show, useModal } from '@ebay/nice-modal-react';
import { Modal, Typography } from 'antd';
import type { FC } from 'react';

import type { IStatusFlowGraph } from '@/components/StatusFlowGraph';
import { StatusFlowGraph } from '@/components/StatusFlowGraph';

const _StatusFlowModal: FC<IStatusFlowGraph> = (props) => {
  const modal = useModal();
  return (
    <Modal
      {...antdModalV5(modal)}
      width={1200}
      footer={null}
      centered
      title={
        <Typography.Text type="secondary">
          根据经典{' '}
          <Typography.Link href={'https://github.com/dagrejs/dagre'} target="_blank">
            DAGRE
          </Typography.Link>{' '}
          算法自动布局，未必符合真实场景，仅供参考
        </Typography.Text>
      }
    >
      <StatusFlowGraph {...props} />
    </Modal>
  );
};

const StatusFlowModal = ModalCreate(_StatusFlowModal);

export const showStatusFlowModal = (props: IStatusFlowGraph) => {
  show(StatusFlowModal, props);
};
