import CodeMirror from 'codemirror';
import type { ForwardRefRenderFunction } from 'react';
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';

import 'codemirror/mode/javascript/javascript';

import 'codemirror/lib/codemirror.css';

type PropsType = {};
export type ConvertCodeToHtmlRefHandleType = {
  getHtml: (str: string) => string;
};

const ConvertCodeToHtml: ForwardRefRenderFunction<ConvertCodeToHtmlRefHandleType, PropsType> = (
  props,
  ref,
) => {
  useImperativeHandle(ref, () => ({
    getHtml(str: string) {
      return getHtml(str);
    },
  }));

  const cmContainerRef = useRef<HTMLDivElement | null>(null);
  const cmEditorRef = useRef<any>(null);

  // 初始化编辑器 用于转换example code
  useEffect(() => {
    // @ts-ignore
    cmEditorRef.current = CodeMirror(cmContainerRef.current, {
      value: '',
      mode: 'javascript',
    });
  }, []);

  const getHtml = (str: string) => {
    cmEditorRef.current?.setValue(str);
    const domHtml = document.querySelector('#convertCmContainer .CodeMirror-code')?.outerHTML || '';
    return domHtml;
  };

  return (
    <div
      ref={cmContainerRef}
      id="convertCmContainer"
      style={{
        position: 'fixed',
        left: -500,
        width: 300,
        visibility: 'hidden',
      }}
    />
  );
};

export default forwardRef(ConvertCodeToHtml);
