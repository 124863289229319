import { Spin } from 'antd';
import classNames from 'classnames';
import { useState } from 'react';

import Styles from './index.less';

interface ImageProps {
  src: string;
  style?: React.CSSProperties;
  className?: string;
  errImg?: string;
  alt?: string;
}

const LImage = (props: ImageProps) => {
  const { src, style, className, errImg, alt = '图像' } = props;

  // 小尺寸预览图 替换 ''
  const [targetSrc, setTargetSrc] = useState('');
  const [loading, toggleLoading] = useState(true);

  const handleImgLoad = () => {
    const imgEle = new Image();
    imgEle.src = src;
    imgEle.onload = () => {
      setTargetSrc(src);
      toggleLoading(false);
    };
    imgEle.onerror = () => {
      // 默认 error image
      setTargetSrc(errImg ?? '');
      toggleLoading(false);
    };
  };

  return (
    <div className={classNames(Styles['image-wrapper'], className)} style={style}>
      <Spin spinning={loading}>
        <img className={Styles['image-ele']} src={targetSrc} onLoad={handleImgLoad} alt={alt} />
      </Spin>
    </div>
  );
};

export default LImage;

export * from './Image';
