import type { GetterPiNode } from '@linkpi/core/web';
import { getShareDBConnectionInstance, ShareDBConnectionOfChatAndTable } from '@linkpi/core/web';
import { message } from 'antd';
import dayjs from 'dayjs';
import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import type sharedb from 'sharedb';

import { useCurrentOrgId, useCurrentSelection, useCurrentUser, useOrgUserMap } from '@/hook';
import request from '@/utils/request';

import EditArea from './EditArea';
import MessagesArea from './MessagesArea';

interface IProps {
  node: GetterPiNode;
  closeChat?: any;
}

const Chat: FC<IProps> = (props) => {
  const { node } = props;
  const currentSelection = useCurrentSelection();

  const currentUser = useCurrentUser();
  const userMap = useOrgUserMap();
  const orgId = useCurrentOrgId();

  // 长连接
  const ad = window.localStorage.getItem('ad') as string;
  const token = window.localStorage.getItem('token') as string;
  const shareDBConnectionInstance = getShareDBConnectionInstance();
  const sdk = useRef(
    new ShareDBConnectionOfChatAndTable(request, shareDBConnectionInstance, { ad, token }),
  );
  const docRef = useRef<sharedb.Doc | null>(null);

  const nodeRef = useRef(node);
  nodeRef.current = node;

  // state
  const [messages, setMessages] = useState<any[]>([]);
  const [groupUsersMap, setGroupUsersMap] = useState<any>({});

  // 当前群聊的人员
  const getGroupUsers = (usr: any[]) => {
    const map: any = {};

    usr.forEach((u: any) => {
      map[u.ad] = userMap[u.ad];
    });

    setGroupUsersMap(map);
  };

  // 发送消息
  const sendMessage = async (delta: any) => {
    const message = {
      s: currentUser.userid,
      c: delta,
      t: 1,
      b: null,
      r: null,
      m: null,
      n: null,
      i: nodeRef.current.value.id,
      d: dayjs().valueOf(),
      q: 0,
    };

    const res = await sdk.current.Chat.sendMessage(orgId, nodeRef.current.value.id, message);
  };

  const cb = () => {
    if (!docRef.current) return;

    if (docRef.current.type === null || !docRef.current.data) {
      setMessages([]);
      return;
    }

    const { msg, usr } = docRef.current.data;

    getGroupUsers(usr);
    setMessages(msg);
  };

  // 3. 连接
  useEffect(() => {
    if (!node.value.id) return;

    // 关闭连接
    if (docRef.current) {
      docRef.current.destroy();
      docRef.current = null;
    }

    // 连接
    docRef.current = sdk.current.Chat.getChatDoc(node.value.id);
    // 监听事件
    docRef.current.subscribe((err) => {
      if (err) {
        message.warning('sharedb err');
        return;
      }

      cb();
    });

    // 数据更新
    docRef.current.on('op', () => {
      cb();
    });

    docRef.current.on('create', () => {
      cb();
    });

    // initChat addChat 获取老数据
    sdk.current.Chat.initChat(orgId, node.value.id).then(() => {
      sdk.current.Chat.addChat(orgId, node.value.id);
    });

    return () => {
      // if (docRef.current) docRef.current.destroy();
    };
  }, [node.value.id]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        overflow: 'hidden',
        width: '100%',
      }}
    >
      <MessagesArea
        messages={messages}
        currentUser={currentUser}
        userMap={userMap}
        node={node}
        currentSelection={currentSelection}
      />
      <EditArea
        userMap={userMap}
        currentUser={currentUser}
        currentSelection={currentSelection}
        node={node}
        sendMessage={sendMessage}
      />
    </div>
  );
};

export default Chat;
