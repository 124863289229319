import type { CurrentUser } from '@linkpi/core';
import { getTempPropEnumOption } from '@linkpi/core';
import { useDispatch } from '@umijs/max';
import { useCreation, useRequest } from 'ahooks';
import type { SelectProps } from 'antd';
import { Select } from 'antd';
import type { FunctionComponent } from 'react';

type Props = {
  // 主题类型配置
  templatePropertyConfig: CurrentUser.TemplateProp;
  templatePropertyKey: number;
  // 具体节点信息
  templateNodeData?: PiNode;
  templateId: string;
  orgId: string;
} & SelectProps;

const getEnumOptions = (keys: string[] = []) => keys.map((i: string) => ({ label: i, value: i }));

const TemplatePropEnumSelect: FunctionComponent<React.PropsWithChildren<Props>> = ({
  orgId,
  templateId,
  templatePropertyConfig,
  templatePropertyKey,
  templateNodeData,
  ...props
}) => {
  const dispatch = useDispatch();
  const { data: matchConfig, loading } = useRequest(
    () =>
      dispatch<any>({
        type: 'workspace/fetchMatchProps',
        payload: {
          org_id: orgId,
          temp_id: templateId,
          reFetch: true,
        },
      }),
    {
      cacheKey: [orgId, templateId, 'matchPropsConfig'].join('@'),
    },
  );

  const options = useCreation(() => {
    if (!matchConfig) return [];

    const baseKeys = templatePropertyConfig.conditionMatching
      ? getTempPropEnumOption({
          propData: templateNodeData?.prop || [],
          matchConfig,
          creator: templateNodeData?.metadata?.e?._sys_creator || '',
          title: templateNodeData?.title || '',
          dataIndex: templatePropertyKey,
          dataType: templatePropertyConfig.type,
        })
      : templatePropertyConfig?.extend;
    return getEnumOptions(baseKeys);
  }, [templatePropertyConfig?.extend, matchConfig]);

  return <Select {...props} loading={loading} options={options} />;
};

export default TemplatePropEnumSelect;
