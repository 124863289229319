import type { ApiResponse } from '@linkpi/core';
import { getMatchingOriginProp } from '@linkpi/core';
import { Select } from 'antd';
import type { FC } from 'react';

import type { PropOption } from '@/pages/space/components/TemplateProp/components/TempPropModal/types';
import { AUXILIARY_PROP_TYPE } from '@/pages/space/components/TemplateProp/constants';

import { useTempPropConfig } from '../hooks';

interface ICommonParams {
  tempProps: PropOption[];
  tempMap: Record<string, ApiResponse.CurrentUser.TemplateInfo>;
}

export const CommonParams: FC<ICommonParams> = (props) => {
  const { tempProps, tempMap } = props;
  const { isLock, propParams, setPropParams } = useTempPropConfig();

  const saveAuxiliary = (value: any, key: 'index' | 'position') => {
    setPropParams({
      ...propParams,
      defAuxProp: {
        ...(propParams.defAuxProp || { index: null, position: 0 }),
        [key]: value,
      },
    });
  };

  return (
    <div className={'prop-item'} key={'defAuxProp'}>
      <div className={'prop-item-label'}>辅助显示</div>
      <div className={'prop-item-input inline-2'}>
        <Select
          placeholder={'请选择'}
          value={propParams.defAuxProp?.index}
          disabled={isLock}
          options={tempProps
            .filter(
              (p) =>
                AUXILIARY_PROP_TYPE.includes(p.type) ||
                (p.type === 'quote' &&
                  // @ts-ignore
                  AUXILIARY_PROP_TYPE.includes(getMatchingOriginProp(p, tempMap)?.type)),
            )
            .map((p) => {
              return {
                label: p.name,
                value: p.index,
              };
            })}
          onChange={(e) => saveAuxiliary(e, 'index')}
          filterOption={(input, option) =>
            !!option?.label && !!~option.label.toLowerCase().indexOf(input.toLowerCase())
          }
          allowClear={true}
          showSearch
        />
        <div
          className={`prop-item-input-btn${propParams.defAuxProp?.position ? ' active' : ''}`}
          onClick={() => !isLock && saveAuxiliary(1, 'position')}
        >
          显示在前
        </div>
        <div
          className={`prop-item-input-btn${!propParams.defAuxProp?.position ? ' active' : ''}`}
          onClick={() => !isLock && saveAuxiliary(0, 'position')}
        >
          显示在后
        </div>
      </div>
    </div>
  );
};
