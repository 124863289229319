import type { Dayjs, ManipulateType } from 'dayjs';
import dayjs from 'dayjs';

import CurDateSelect from '../components/curDateSelect';
import DateColumn from '../components/dateColumn';
import RangeSelect from '../components/rangeSelect';
import BaseModule from './_baseModule';

type DateRangeType = 'week' | 'month' | 'year';
type rangeConfigType = {
  key: DateRangeType;
  name: string;
  unit: ManipulateType;
  scrollWidth: number;
  columnWidth: number;
};
export default class DateColumnManager extends BaseModule {
  public columnList: DateColumn[] = [];

  public range: DateRangeType = 'week';
  public rangeConfig: Record<DateRangeType, rangeConfigType> = {
    week: {
      key: 'week',
      name: '周',
      unit: 'day',
      scrollWidth: 12000,
      columnWidth: 100,
    },
    month: {
      key: 'month',
      name: '月',
      unit: 'day',
      scrollWidth: 5760,
      columnWidth: 38,
    },
    year: {
      key: 'year',
      name: '年',
      unit: 'month',
      scrollWidth: 7680,
      columnWidth: 50,
    },
  };
  public get columnUnit(): ManipulateType {
    return this.rangeConfig[this.range].unit;
  }

  public get columnWidth() {
    return this.rangeConfig[this.range].columnWidth;
  }

  public get scrollWidth() {
    return this.rangeConfig[this.range].scrollWidth;
  }

  public setRange(key: DateRangeType) {
    this.range = key;
    this.moduleInstances.Event.handleMoveX(this.getTodayLineScrollOffSet(), true);
  }

  /**
   * 获取今日的scroll偏移
   */
  public getTodayLineScrollOffSet() {
    const { Render, Draw } = this.moduleInstances;
    return (
      Render.leftPanelStyle.width +
      this.scrollWidth / 2 -
      (Draw.canvasWidth - Render.leftPanelStyle.width) / 2
    );
  }

  public getDateOffset(day: Dayjs) {
    const todayOffset = this.getTodayLineScrollOffSet();
    const today = dayjs();
    const diff = today.startOf(this.columnUnit).diff(day.startOf(this.columnUnit), this.columnUnit);
    return todayOffset + diff * this.columnWidth * -1;
  }

  /**
   * 偏移量转换成x坐标
   */
  public offsetToX(offset: number) {
    const { Render, Draw } = this.moduleInstances;
    const toDayLineX =
      Render.leftPanelStyle.width + (Draw.canvasWidth - Render.leftPanelStyle.width) / 2;
    const toDayLineOffset = this.getTodayLineScrollOffSet();
    // 今日线与中心点的偏移量
    const toDayLineOffsetByCenter = toDayLineOffset - Render.scrollDistance.left;
    // 与今日线的偏移量
    const diffOffset = offset + toDayLineOffsetByCenter - toDayLineOffset;
    return toDayLineX + diffOffset;
  }

  /**
   * x坐标转换成偏移量
   */
  public xToOffset(x: number) {
    const toDayLineOffset = this.getTodayLineScrollOffSet();
    const toDayLineX = this.offsetToX(toDayLineOffset);
    const diffX = toDayLineX - x;
    return toDayLineOffset - diffX;
  }

  /**
   * x坐标转换成dayjs
   */
  public xToDay(x: number) {
    const todayLineX = this.offsetToX(this.getTodayLineScrollOffSet());
    const diff = x - todayLineX;
    const diffColumnCount = Math.round(diff / this.columnWidth);
    return dayjs().add(diffColumnCount, this.columnUnit);
  }

  public renderHead() {
    this.offsetToX(this.getTodayLineScrollOffSet());
    const { Draw, Render } = this.moduleInstances;
    Draw.save();
    // rect
    Draw.fillRect(
      Render.leftPanelStyle.width + 1,
      0,
      Draw.canvasWidth - Render.leftPanelStyle.width,
      80,
      Draw.style.backgroundColor,
    );
    Draw.fillRect(
      Render.leftPanelStyle.width + 1,
      0,
      Draw.canvasWidth - Render.leftPanelStyle.width,
      80,
      Draw.style.head.backgroundColor,
    );

    // line
    Draw.line(
      [
        [Render.leftPanelStyle.width, 40],
        [Draw.canvasWidth, 40],
      ],
      Draw.style.borderColor,
    );
    Draw.line(
      [
        [Render.leftPanelStyle.width, 80],
        [Draw.canvasWidth, 80],
      ],
      Draw.style.borderColor,
    );

    this.columnList.map((x) => x.renderHead(Draw));

    // tool box
    const toolBoxPosition = {
      width: 230,
      height: 40 - 1,
      y: 0,
      x: Draw.canvasWidth - 230,
    };
    // 下层先画个背景
    Draw.fillRect(
      toolBoxPosition.x,
      toolBoxPosition.y,
      toolBoxPosition.width,
      toolBoxPosition.height,
      Draw.style.backgroundColor,
    );
    Draw.fillRect(
      toolBoxPosition.x,
      toolBoxPosition.y,
      toolBoxPosition.width,
      toolBoxPosition.height,
      Draw.style.head.backgroundColor,
    );

    Draw.line(
      [
        [Draw.canvasWidth - 80, 10],
        [Draw.canvasWidth - 80, 30],
      ],
      Draw.style.borderColor,
    );

    // range切换 w 150
    // 周月年 box 25*25
    const rangePosition = {
      width: 25,
      height: 25,
      y: (toolBoxPosition.height - 25) / 2,
    };

    let startX = toolBoxPosition.x + 15;
    Object.values(this.rangeConfig).map((x) => {
      const rangeSelect = new RangeSelect({ ...rangePosition, x: startX }, this.moduleInstances, x);
      rangeSelect.render();
      startX += 45;
    });

    // 回到今日
    // 40*25 box
    const toDayBoxPosition = {
      width: 40,
      height: 25,
      y: (toolBoxPosition.height - 25) / 2,
      x: Draw.canvasWidth - 80 + 40 / 2,
    };
    const curDateSelect = new CurDateSelect(toDayBoxPosition, this.moduleInstances);
    curDateSelect.render();
  }
  public renderColumns() {
    const { Draw, Render } = this.moduleInstances;

    // 通过scrollDistance.left 计算columns
    const scrollAreaWidth = this.scrollWidth;

    // scroll的中心点的偏移量
    const currentColumnOffsetX =
      Render.leftPanelStyle.width + scrollAreaWidth / 2 - this.columnWidth / 2;
    // 计算当前显示位置的偏移量index
    const scrollLeft = Render.scrollDistance.left;
    const offset = currentColumnOffsetX - scrollLeft;
    // 计算scrollLeft位置的index
    const startIndex = Math.floor(offset / this.columnWidth) * -1 - 1;
    // 显示的个数
    const columnCount =
      Math.ceil((Draw.canvasWidth - Render.leftPanelStyle.width) / this.columnWidth) + 1;

    let index = 0;
    const columns = [];

    while (index < columnCount) {
      const curIndex = startIndex + index;
      const position = {
        width: this.columnWidth,
        height: Draw.canvasHeight - 40,
        y: 40,
        x:
          currentColumnOffsetX +
          this.columnWidth * curIndex -
          scrollLeft +
          Render.leftPanelStyle.width,
      };

      const column = new DateColumn(position, this.moduleInstances, curIndex);
      columns.push(column);
      index++;
    }

    this.columnList = columns;

    columns.map((x) => {
      x.renderBox();
    });
  }
}
