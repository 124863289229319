import { MinusCircleOutlined } from '@ant-design/icons';
import { generateAddOpId } from '@linkpi/core';
import { Select } from 'antd';
import { cloneDeep } from 'lodash';

import { selectSearchFn } from '@/components/LinkPiForm/utils';
import { useCurrentOrgId, useOrgTempMap } from '@/hook';
import { useCurrentTemplate } from '@/hook/useCurrentTemplate';

export default (props: any) => {
  const { Option } = Select;
  const { onChange, matchings, conditions } = props;

  const currentTemplate = useCurrentTemplate();

  const orgId = useCurrentOrgId();
  const tempMap = useOrgTempMap(orgId);

  const matchingTemplate =
    tempMap[conditions.find((i: any) => i.key === 'templateId')?.value] || {};
  const changeTargetThemeProp = (e: any, key: any) => {
    const newMatchings = cloneDeep(matchings);

    newMatchings.forEach((m: any) => {
      if (m.key === key) m.origin = e;
    });
    onChange(newMatchings);
  };
  const changeNowThemeProp = (e: any, key: any) => {
    const newMatchings = cloneDeep(matchings);

    newMatchings.forEach((m: any) => {
      if (m.key === key) m.target = e;
    });
    onChange(newMatchings);
  };
  const del = (key: any) => {
    onChange(matchings.filter((i: any) => i.key !== key));
  };
  const addMatchingItem = () => {
    const v = {
      origin: undefined,
      target: undefined,
      key: generateAddOpId(),
    };
    onChange([...matchings, v]);
  };

  const targetThemeProps = (matchingTemplate.prop || [])
    .map((p: any, index: number) => ({ ...p, index }))
    .filter((p: any) => p.name);
  const nowThemeProps = (currentTemplate?.prop || [])
    .map((p: any, index: number) => ({ ...p, index }))
    .filter((p: any) => p.name);
  return (
    <div style={{ marginTop: 12, borderRadius: 8 }}>
      {matchings.map((matching: any) => (
        <div style={{ display: 'flex', alignItems: 'center' }} key={matching.key}>
          <Select
            placeholder="请选择属性"
            showSearch
            onChange={(e) => changeTargetThemeProp(e, matching.key)}
            filterOption={selectSearchFn}
            style={{ marginTop: 12, flex: 1 }}
            value={matching.origin}
          >
            {targetThemeProps.map((p: any) => (
              <Option key={p.index} value={p.index}>
                {p.name}
              </Option>
            ))}
          </Select>
          <div
            style={{
              marginTop: 12,
              padding: '0 8px',
              color: '#242d3f',
              fontWeight: 'bold',
              fontSize: 18,
            }}
          >
            =
          </div>
          <Select
            placeholder="当前主题类型下的属性"
            showSearch
            onChange={(e) => changeNowThemeProp(e, matching.key)}
            filterOption={(input, option) =>
              // @ts-ignore
              option?.children?.toLowerCase?.()?.indexOf(input?.toLowerCase()) !== -1
            }
            style={{ marginTop: 12, flex: 1 }}
            value={matching.target}
          >
            {nowThemeProps.map((p: any) => (
              <Option key={p.index} value={p.index}>
                {p.name}
              </Option>
            ))}
          </Select>
          <MinusCircleOutlined
            onClick={() => del(matching.key)}
            style={{ color: '#B9BBBC', marginTop: 12, marginLeft: 8, cursor: 'pointer' }}
          />
        </div>
      ))}
      <div
        onClick={addMatchingItem}
        style={{ display: 'flex', alignItems: 'center', padding: '12px 0px', cursor: 'pointer' }}
      >
        <i style={{ color: '#316ef5', fontSize: 16 }} className="iconfont iconadd" />
        <div style={{ color: '#316ef5' }}>添加匹配条件</div>
      </div>
    </div>
  );
};
