import { DATE_INTERVAL_OPTIONS } from '@linkpi/core';
import { Checkbox } from 'antd';
import { isNil } from 'ramda';
import type { FC } from 'react';

import type { BasicProp } from '@/pages/space/components/TemplateProp/components/TempPropModal/types';
import { checkPropType as _checkPropType } from '@/utils/utils';

import { useTempPropConfig } from '../hooks';

export const CheckableParams: FC = () => {
  const { isLock, savePropParams, isDebugMode, propParams, setPropParams } = useTempPropConfig();

  const onMuitipleProp = (checked: boolean, params: BasicProp) => {
    if (params.type === 'user' && isNil(checked) && !isDebugMode) return;
    savePropParams(checked, 'multiple');
  };

  const checkPropType = _checkPropType(propParams.type);

  return (
    <>
      <div className={'prop-item no-padding'} key={'required-1'}>
        <Checkbox
          checked={!propParams.hide}
          disabled={!propParams.editGroups?.length}
          onChange={(e) => savePropParams(!e.target.checked, 'hide')}
        >
          是否显示属性
        </Checkbox>
      </div>
      {checkPropType(['date', 'datetime']) ? (
        <div className={'prop-item no-padding'} key={'showInterval'}>
          <div className={'prop-item-input'}>
            <Checkbox
              disabled={isLock}
              checked={propParams.showInterval}
              onChange={(e) =>
                setPropParams({
                  ...propParams,
                  showInterval: e.target.checked,
                  intervalType: propParams.intervalType || 'hour',
                })
              }
            >
              显示距离当前时间
            </Checkbox>
            {propParams.showInterval
              ? DATE_INTERVAL_OPTIONS.map((d) => (
                  <div
                    key={d.value}
                    className={`prop-item-input-btn${
                      propParams.intervalType === d.value ? ' active' : ''
                    }`}
                    // @ts-ignore
                    onClick={() => setPropParams({ ...propParams, intervalType: d.value })}
                  >
                    {d.label}
                  </div>
                ))
              : null}
          </div>
        </div>
      ) : null}
      <div className={'prop-item no-padding'} key={'required'}>
        <Checkbox
          checked={propParams.required}
          disabled={!propParams.editGroups?.length}
          onChange={(e) => savePropParams(e.target.checked, 'required')}
        >
          这是必填项
        </Checkbox>
      </div>
      {checkPropType(['enum', 'tag', 'user', 'cascade', 'department']) ? (
        <div className={'prop-item no-padding'} key={'multiple'}>
          <Checkbox
            checked={propParams.multiple}
            onChange={(e) => onMuitipleProp(e.target.checked, propParams)}
          >
            可多选
          </Checkbox>
        </div>
      ) : null}
      {checkPropType('cascade') && propParams.cascade ? (
        <>
          {propParams.cascade.type === 0 ? (
            <div className={'prop-item no-padding'} key={'addOnSelect'}>
              <Checkbox
                checked={propParams.addOnSelect}
                onChange={(e) => savePropParams(e.target.checked, 'addOnSelect')}
              >
                编辑时可新增选项
              </Checkbox>
            </div>
          ) : null}
          <div className={'prop-item no-padding'} key={'changeOnSelect'}>
            <Checkbox
              checked={!propParams.changeOnSelect}
              onChange={(e) => savePropParams(!e.target.checked, 'changeOnSelect')}
            >
              必须选择到最后一级
            </Checkbox>
          </div>
          <div className={'prop-item no-padding'} key={'hideRoutes'}>
            <Checkbox
              checked={!propParams.hideRoutes}
              onChange={(e) => savePropParams(!e.target.checked, 'hideRoutes')}
            >
              选择结果显示层级路径
            </Checkbox>
          </div>
        </>
      ) : null}
      {checkPropType(['enum', 'tag']) ? (
        <>
          <div className={'prop-item no-padding'} key={'enumType'}>
            <Checkbox
              checked={propParams.type === 'tag'}
              onChange={(e) =>
                setPropParams({ ...propParams, type: e.target.checked ? 'tag' : 'enum' })
              }
            >
              编辑时可新增选项
            </Checkbox>
          </div>
          {checkPropType('tag') ? (
            <div className={'prop-item no-padding'} key={'enumNotSave'}>
              <Checkbox
                checked={!propParams.enumNotSave}
                onChange={(e) => setPropParams({ ...propParams, enumNotSave: !e.target.checked })}
              >
                保留新增选项
              </Checkbox>
            </div>
          ) : null}
        </>
      ) : null}
      {checkPropType('positioning') ? (
        <div className={'prop-item no-padding'} key={'editable'}>
          <Checkbox
            checked={propParams.editable}
            onChange={(e) =>
              setPropParams({
                ...propParams,
                editable: e.target.checked,
              })
            }
          >
            可选择任意地点
          </Checkbox>
        </div>
      ) : null}
      {propParams.type !== 'formula' ? (
        <div className={'prop-item no-padding'} key={'recordModification'}>
          <Checkbox
            checked={propParams.recordModification}
            onChange={(e) =>
              setPropParams({
                ...propParams,
                recordModification: e.target.checked,
                requireModificationRemark: e.target.checked && propParams.requireModificationRemark,
              })
            }
          >
            保留属性值修改记录
          </Checkbox>
        </div>
      ) : null}
      {propParams.recordModification ? (
        <div className={'prop-item no-padding'} key={'requireModificationRemark'}>
          <Checkbox
            checked={propParams.requireModificationRemark}
            onChange={(e) => savePropParams(e.target.checked, 'requireModificationRemark')}
          >
            修改时必须填写备注
          </Checkbox>
        </div>
      ) : null}
    </>
  );
};
