import { SearchOutlined } from '@ant-design/icons';
import { useDebounceFn } from 'ahooks';
import { Input } from 'antd';

import request from '@/utils/request';

import styles from './styles.less';
export default (props: any) => {
  const { orgInfo, changeSearchData, isDark } = props;
  const { run: search } = useDebounceFn(
    async (v: string) => {
      if (!v.trim()) return changeSearchData(undefined);

      const res = await request('/docapi/getNodeByFilter', {
        method: 'POST',
        data: {
          org_id: orgInfo.orgId,
          keyWords: v.trim().split(' '),
          onlyId: true,
        },
      });

      if (res?.status === 'ok' && Array.isArray(res.data)) {
        changeSearchData(res.data);
      } else {
        changeSearchData(undefined);
      }
    },
    { wait: 500 },
  );
  return (
    <Input
      className={styles.search + (isDark ? ' ' + styles.darkSearch : '')}
      bordered={false}
      allowClear
      prefix={<SearchOutlined />}
      placeholder="搜索"
      onChange={(e) => search(e.target.value)}
    />
  );
};
