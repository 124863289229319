import { Select } from 'antd';
import { isString } from 'lodash';
import { Fragment, useEffect, useMemo, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { uniqueArr } from '@/utils/utils';

interface IProps {
  data: string[];
  onChange: any;
  onBlur: any;
  defaultValue: any[];
  domId?: any;
  allowClear: boolean;
  bordered?: boolean;
  open?: boolean;
  autoFocus?: boolean;
  mode?: string;
  notSave?: boolean;
  addQuoteTheme: any;
  getPopupContainer?: Function;
  quoteOptions?: any[];
}

const { Option } = Select;

const ChooseTagSelect = (props: IProps) => {
  const {
    data = [], // 下拉的数据
    onChange, // 选择项目
    onBlur = () => {}, // 将标签存在节点上
    defaultValue = [], // 默认值
    domId = '',
    allowClear,
    autoFocus = false,
    bordered = true,
    open = false,
    mode = '',
    notSave = false,
    addQuoteTheme,
    getPopupContainer,
    quoteOptions,
  } = props;

  const opt: any = {};
  if (open) opt.open = open;
  if (getPopupContainer) opt.getPopupContainer = getPopupContainer;

  // input str
  const [searchKey, setSearchKey] = useState('');
  // input str
  const [searchKeys, setSearchKeys] = useState<Array<string>>([]);
  // 组件内 下拉可以增加
  const [compData, setCompData] = useState<string[]>(() => data.filter(isString));
  // 选中的值
  const [selecteds, setSelecteds] = useState<string | Array<string>>(defaultValue);

  useEffect(() => {
    setSelecteds(defaultValue);
  }, [defaultValue.length ? defaultValue : 0]);

  useEffect(() => {
    setCompData(data.filter(isString));
  }, [data]);

  // 是否存在项目（输入框中输入的）
  const hasItem = useMemo(() => {
    const arr = compData.filter((i) => i === searchKey);
    return arr.length > 0;
  }, [searchKey, compData, searchKeys]);

  // 是否存在项目，不用全等
  const exist = useMemo(() => {
    const arr = compData.filter((i) => i.toLowerCase().indexOf(searchKey.toLowerCase()) >= 0);
    return arr.length > 0;
  }, [searchKey, compData]);

  // 渲染 下拉面板
  const render = (_: any) => (
    <Fragment>
      <PerfectScrollbar>
        {exist || searchKey === '' ? <div key="menu" children={_} /> : null}
      </PerfectScrollbar>
      {searchKey !== '' &&
      (!hasItem || ~searchKey.indexOf(',') || ~searchKey.indexOf(';') || compData.length === 0) ? (
        <div
          key="createTip"
          style={{ padding: '5px 12px', color: '#B6B8B9' }}
          children={`输入“，；”可分开选项，按回车创建 ${searchKeys
            .filter((x) => !compData.includes(x))
            .map((x) => `"${x}"`)}`}
        />
      ) : null}
      {searchKey === '' && (
        <div style={{ padding: '5px 12px', color: '#B6B8B9' }}>
          <div>在搜索框输入文字，按回车创建。输入“，；”可分开选项。</div>
          {/* <div>{addQuoteTheme}</div> */}
        </div>
      )}
      {addQuoteTheme}
    </Fragment>
  );

  const search = (v: string) => {
    setSearchKey(v);
    setSearchKeys(
      v
        .split(';')
        .reduce((a, b) => a.concat(b.split(',')), [] as Array<string>)
        .reduce((a, b) => a.concat(b.split('，')), [] as Array<string>)
        .reduce((a, b) => a.concat(b.split('；')), [] as Array<string>)
        .filter((x) => x),
    );
  };

  const handleValueChange = (selects: any) => {
    const newTags = selects
      ? (Array.isArray(selects) ? selects : [selects]).filter((x: string) => !~data.indexOf(x))
      : [];
    onChange(selects, newTags);
  };

  // 按回车创建
  const handleInputKeyDown = (v: any) => {
    const { nativeEvent } = v;
    const { isComposing } = nativeEvent;

    if (isComposing) return;
    if (v.key !== 'Enter') return;
    if (searchKey === '') return;

    if (compData.includes(searchKey)) {
      setSearchKey('');
      setSearchKeys([]);
      return;
    }
    const selects = uniqueArr(mode === 'multiple' ? [...searchKeys, ...selecteds] : searchKeys);
    // 增加选项
    if (!notSave) setCompData(uniqueArr([...searchKeys, ...compData]));
    // 选中
    handleValueChange(mode !== 'multiple' && selects.length === 1 ? selects[0] : selects);
    setSelecteds(selects);
    setTimeout(() => {
      setSearchKey('');
      setSearchKeys([]);
    }, 50);
  };

  return (
    <Select
      showAction={['focus', 'click']} // from rc-select
      id={domId}
      className="edit-select"
      allowClear={allowClear}
      placeholder="搜索或添加"
      value={selecteds}
      showSearch
      onChange={(e) => {
        setSelecteds(e);
        handleValueChange(e);
        setSearchKey('');
        setSearchKeys([]);
      }}
      style={{ width: '100%' }}
      {...opt}
      bordered={bordered}
      autoFocus={autoFocus}
      onSearch={search}
      mode={mode}
      onBlur={onBlur}
      defaultActiveFirstOption={false}
      searchValue={searchKey}
      dropdownRender={(_) =>
        compData.length === 0 && searchKey === '' ? (
          <div style={{ padding: '5px 12px' }}>
            <div>在搜索框输入文字，按回车创建。输入“，；”可分开选项。</div>
            <div>{addQuoteTheme}</div>
          </div>
        ) : (
          render(_)
        )
      }
      onInputKeyDown={handleInputKeyDown}
      virtual={true}
      optionFilterProp={'label'}
      optionLabelProp={'value'}
    >
      {compData
        .filter((i) => i)
        .map((x, index) => {
          const quote = quoteOptions?.find((o) => o.label === x);
          return (
            <Option
              key={index}
              value={x}
              label={quote?.aux?.label ? x + '-' + quote.aux.label : x}
              children={
                quote?.aux ? (
                  quote.aux.position ? (
                    <>
                      <p style={{ color: 'rgb(185, 187, 188)' }}>{quote.aux.label}</p>
                      <p>{quote.label}</p>
                    </>
                  ) : (
                    <>
                      <p>{quote.label}</p>
                      <p style={{ color: 'rgb(185, 187, 188)' }}>{quote.aux.label}</p>
                    </>
                  )
                ) : (
                  x
                )
              }
            />
          );
        })}
    </Select>
  );
};

export default ChooseTagSelect;
