import { atom, useAtom } from 'jotai';
import { useCallback } from 'react';

const spaceSideBarOpenAtom = atom(true);

export const useSpaceSideBarState = () => {
  const [spaceSideBarOpen, setSpaceSideBarOpen] = useAtom(spaceSideBarOpenAtom);

  const toggleSpaceSideBarOpen = useCallback(() => {
    setSpaceSideBarOpen((v) => !v);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { spaceSideBarOpen, toggleSpaceSideBarOpen };
};
