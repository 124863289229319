export const getAttrNameWidth = (attrNameWidth: any, style: string) => {
  if (['style_1', 'style_2', 'style_3'].includes(style)) return { width: attrNameWidth || 120 };
  return {};
};

export const getAttrNameClassName = (style: string) => {
  const original = 'prop-item-name text-omit';
  let newName = ' defaultPropName';
  if (style === 'style_1') newName = ' style1PropName';
  if (style === 'style_2') newName = ' style2PropName';
  if (style === 'style_3') newName = ' style3PropName';
  return original + newName;
};

export const getPropValueClassName = (disable: boolean, style: string, nodePropConfig: any) => {
  const { valueWrap } = nodePropConfig || {};
  let n = 'prop-item-value';
  if (!valueWrap) n += ' text-omit';
  if (!disable) n += ' enable-prop';
  if (disable && !['style_1', 'default'].includes(style)) n += ' disable-prop';
  if (style === 'style_1') n += ' style1PropValue';
  if (style === 'style_2') n += ' style2PropValue';
  if (style === 'style_3') n += ' style3PropValue';
  if (!['style_1', 'style_2', 'style_3'].includes(style)) n += ' defaultPropValue';
  return n;
};

export const getPropValueLabelClassName = (style: string) => {
  let n = 'prop-item-value-label';
  if (style === 'style_1') n = 'prop-item-value-label-style1';
  if (style === 'style_2') n = 'prop-item-value-label-style2';
  if (style === 'style_3') n = 'prop-item-value-label-style3';
  return n + ' propValueLabel';
};

export const getPropValueInputClassName = (style: string) => {
  let n = 'prop-item-value-input';
  if (style === 'style_1') n += ' style1PropValueSelected';
  if (style === 'style_2') n += ' style2PropValueSelected';
  if (style === 'style_3') n += ' style3PropValueSelected';
  return n;
};
