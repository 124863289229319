import type { CurrentUser } from '@linkpi/core';
import { EQUAL, GREAT, GREAT_EQUAL, LESS, LESS_EQUAL, NOT_EQUAL } from '@linkpi/core';

export const ACCURACY_OPTIONS = [
  {
    label: '省份/城市/区县/街道 详细地址',
    value: 'address',
  },
  {
    label: '省份/城市/区县/街道',
    value: 'street',
  },
  {
    label: '省份/城市/区县',
    value: 'district',
  },
  {
    label: '省份/城市',
    value: 'city',
  },
  {
    label: '省份',
    value: 'province',
  },
];

export const TEMP_PROP_OPTIONS: {
  label: string;
  value: CurrentUser.propType;
}[] = [
  {
    label: '人员',
    value: 'user',
  },
  {
    label: '时间',
    value: 'datetime',
  },
  {
    label: '时间段',
    value: 'datetimeRange',
  },
  {
    label: '日期',
    value: 'date',
  },
  {
    label: '选值',
    value: 'enum',
  },
  {
    label: '多级选值',
    value: 'cascade',
  },
  {
    label: '数字',
    value: 'number',
  },
  {
    label: '货币',
    value: 'currencyV2',
  },
  {
    label: '文本',
    value: 'text',
  },
  {
    label: '附件',
    value: 'attachment',
  },
  {
    label: '定位',
    value: 'positioning',
  },
  {
    label: '地址',
    value: 'address',
  },
  {
    label: '自增ID',
    value: 'auto_inc',
  },
  {
    label: '公式',
    value: 'formula',
  },
  {
    label: '引用',
    value: 'quote',
  },
  {
    label: '部门',
    value: 'department',
  },
];

export const SYS_QUOTE_PROPS = [
  {
    index: '-1',
    type: 'user',
    name: '创建者',
  },
  {
    index: '-2',
    type: 'datetime',
    name: '创建时间',
  },
];

export const STATUS_QUOTE_PROPS = [
  {
    index: '-5',
    type: 'user',
    name: '负责人',
  },
  {
    index: '-6',
    type: 'user',
    name: '参与者',
  },
  {
    index: '-7',
    type: 'datetime',
    name: '开始时间',
  },
  {
    index: '-8',
    type: 'datetime',
    name: '结束时间',
  },
];

export const PROP_COLUMNS = [
  {
    title: '',
    dataIndex: 'index',
    width: 50,
  },
  {
    title: '',
    dataIndex: 'sort',
    width: 50,
    className: 'drag-visible',
  },
  {
    title: '是否显示',
    dataIndex: 'hide',
    key: 'hide',
    ellipsis: true,
    width: 90,
  },
  {
    title: '属性类型',
    dataIndex: 'type',
    key: 'type',
    width: 90,
  },
  {
    title: '属性名称',
    key: 'name',
    dataIndex: 'name',
    width: 'calc(50% - 330px)',
    ellipsis: true,
    className: 'drag-visible',
  },
  {
    title: '是否多选',
    dataIndex: 'multiple',
    key: 'multiple',
    width: 90,
  },
  {
    title: '是否必填',
    dataIndex: 'required',
    key: 'required',
    width: 90,
  },
  {
    title: '默认值/引用',
    dataIndex: 'defaultValue',
    key: 'defaultValue',
    ellipsis: true,
    width: 'calc(50% - 330px)',
  },
  {
    title: '操作',
    dataIndex: 'template_id',
    key: 'template_id',
    width: 200,
  },
];

export const FORMULA_SYMBOL = {
  GREAT: { formula: (a: any, b: any) => GREAT(a, b) },
  GREAT_EQUAL: { formula: (a: any, b: any) => GREAT_EQUAL(a, b) },
  LESS_EQUAL: { formula: (a: any, b: any) => LESS_EQUAL(a, b) },
  LESS: { formula: (a: any, b: any) => LESS(a, b) },
  EQUAL: { formula: (a: any, b: any) => EQUAL(a, b) },
  NOT_EQUAL: { formula: (a: any, b: any) => NOT_EQUAL(a, b) },
};

export const AUXILIARY_PROP_TYPE = ['text', 'enum', 'tag', 'formula'];
export const CONDITION_PROP_TYPE: CurrentUser.propType[] = [
  'user',
  'number',
  'cascade',
  'text',
  'enum',
  'tag',
  'quote',
  'address',
  'attachment',
];
