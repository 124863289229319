import { message, Modal, Table } from 'antd';
import React, { useEffect, useState } from 'react';

import { previewWebOffice } from '@/components';
import request from '@/utils/request';

interface IProps {
  org_id: string;
  node_id: string;
  closeModal: () => void;
  templateList: any[];
  spaceUserList: any;
  zIndex?: number;
}

export default (props: IProps) => {
  const { closeModal, node_id, org_id, templateList, spaceUserList, zIndex } = props;
  const [list, setList] = useState<any[]>([]);

  const init = async () => {
    const res: any = await request('/api/import/query', {
      method: 'POST',
      data: { node_id, org_id },
    });
    if (res.status === 'ok') setList(res.data);
    else message.error('请求导入历史失败，请关闭重试');
  };

  useEffect(() => {
    init();
  }, []);

  const showImportErrorDetail = (url: string, file_name: string) => {
    previewWebOffice({
      url,
      fileName: file_name,
      orgId: org_id,
    });
  };

  const columns: any[] = [
    {
      title: '文件名称',
      dataIndex: 'file_name',
      key: 'file_name',
    },
    {
      title: '导入主题类型',
      dataIndex: 'temp_id',
      key: 'temp_id',
      render: (v: any) => {
        const t = templateList.find((i) => i.template_id === v);
        return t ? t.name : '未知主题类型';
      },
    },
    {
      title: '导入总数',
      dataIndex: 'total',
      key: 'total',
    },
    {
      title: '成功数',
      dataIndex: 'success',
      key: 'success',
      render: (v: any, record: any) => {
        const s = record.total - record.failed_total;
        return <span style={{ color: '#63C662' }}>{s}</span>;
      },
    },
    {
      title: '失败数',
      dataIndex: 'failed_total',
      key: 'failed_total',
      render: (v: any) => <span style={{ color: '#F0665E' }}>{v}</span>,
    },
    {
      title: '操作人',
      dataIndex: 'account_id',
      key: 'account_id',
      render: (v: any) => spaceUserList[org_id][v].nick_name,
    },
    {
      title: '操作',
      dataIndex: 'detail_url',
      key: 'detail_url',
      render: (v: any, record: any) => {
        if (!v) return '-';
        return (
          <span
            onClick={() => showImportErrorDetail(v, record.file_name)}
            style={{ color: '#1779F9', cursor: 'pointer' }}
          >
            查看失败详情
          </span>
        );
      },
    },
  ];
  return (
    <Modal title="导入历史" open onCancel={closeModal} footer={null} width={1000} zIndex={zIndex}>
      <Table scroll={{ y: 500 }} pagination={false} dataSource={list} columns={columns} />
    </Modal>
  );
};
