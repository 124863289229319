console.log('welcome~');
console.log(
  decodeURI(`   ,--,
,---.'|                    ,--.       ,--.,-.----.
|   | :      ,---,       ,--.'|   ,--/  /|\\    /  \\     ,---,
:   : |   ,%60--.' |   ,--,:  : |,---,': / '|   :    \\ ,%60--.' |
|   ' :   |   :  :,%60--.'%60|  ' ::   : '/ / |   |  .\\ :|   :  :
;   ; '   :   |  '|   :  :  | ||   '   ,  .   :  |: |:   |  '
'   | |__ |   :  |:   |   \\ | :'   |  /   |   |   \\ :|   :  |
|   | :.'|'   '  ;|   : '  '; ||   ;  ;   |   : .   /'   '  ;
'   :    ;|   |  |'   ' ;.    ;:   '   \\  ;   | |%60-' |   |  |
|   |  ./ '   :  ;|   | | \\   ||   |    ' |   | ;    '   :  ;
;   : ;   |   |  ''   : |  ; .''   : |.  \\:   ' |    |   |  '
|   ,/    '   :  ||   | '%60--'  |   | '_\\.':   : :    '   :  |
'---'     ;   |.' '   : |      '   : |    |   | :    ;   |.'
          '---'   ;   |.'      ;   |,'    %60---'.|    '---'
                  '---'        '---'        %60---%60
                                                              `),
);
