import { Button, Popover } from 'antd';
import { useState } from 'react';

import styles from '../ToggleCollapse/styles.less';
export default (props: any) => {
  const { exportView, isDark } = props;
  const [exportVisible, setExportVisible] = useState(false);
  return (
    <Popover
      trigger={['click']}
      overlayClassName="view-setting-export"
      placement={'bottomRight'}
      open={exportVisible}
      onOpenChange={() => setExportVisible(!exportVisible)}
      content={
        <div className="view-setting-list">
          <li
            className="view-setting-item"
            onClick={() => {
              exportView('excel');
              setExportVisible(false);
            }}
          >
            导出Excel
          </li>
          <li
            className="view-setting-item"
            onClick={() => {
              exportView('pdf');
              setExportVisible(false);
            }}
          >
            导出PDF
          </li>
        </div>
      }
    >
      <Button
        style={{ transition: 'none' }}
        type={'text'}
        className={styles.setCollapse + (isDark ? ' ' + styles.darkBlock : '')}
      >
        <i className="iconfont iconshaixuanqu-daochu" />
        &nbsp;导出
      </Button>
    </Popover>
  );
};
