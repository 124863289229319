import type { WidgetInstanceData } from '@linkpi/core';
import type { GetterPiNode } from '@linkpi/core/web';
import { Empty } from 'antd';
import { type FC, type PropsWithChildren } from 'react';

import type { ISharedStateNodeSetting } from '@/components/PageModelEditor/src/components';

import { useShareStatePiNode } from './hook';

type SharedStateNodeConnectProps<T> = T & {
  getterPiNode: GetterPiNode;
  data: WidgetInstanceData<ISharedStateNodeSetting>;
};

export function sharedStateNodeConnect<T, P extends SharedStateNodeConnectProps<T>>(
  Comp: FC<PropsWithChildren<P>>,
) {
  const Wrapper: FC<PropsWithChildren<P>> = (props) => {
    const getterPiNode = useShareStatePiNode({
      currentGetterPinode: props.getterPiNode,
      nodeSource: props.data.config.nodeSource,
    });

    if (!getterPiNode)
      return (
        <div className="flex h-full items-center justify-center">
          <Empty description="暂无数据" />
        </div>
      );

    return <Comp {...props} getterPiNode={getterPiNode} />;
  };

  return Wrapper;
}
