import type { PiGridModules } from '../core';

export interface PositionType {
  x: number;
  y: number;
  width: number;
  height: number;
}

export default class GridBaseComponent {
  public type: string = 'component';
  public moduleInstances: PiGridModules;

  public fixed: boolean;

  // 是否允许点击事件捕获
  public captrue = true;

  public isHover: boolean = false;

  public position: PositionType = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  };
  public getCurrentIsHover() {
    const { x, y } = window.__PI__GRID.mousePosition;
    return this.isPositionInside(x, y);
  }
  public isPositionInside(x: number, y: number) {
    return (
      x > this.position.x &&
      x < this.position.x + this.position.width &&
      y > this.position.y &&
      y < this.position.y + this.position.height
    );
  }
  public click() {}
  public mousedown() {}
  public hover() {}

  constructor(position: PositionType, moduleInstances: PiGridModules, fixed: boolean = false) {
    this.position = position;
    this.moduleInstances = moduleInstances;
    this.fixed = fixed;

    this.isHover = this.getCurrentIsHover();
    if (this.isHover) {
      window.__PI__GRID.mouseComponents.unshift(this);
    }
  }
}
