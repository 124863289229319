import { updateNodeSysProp } from '@linkpi/core';
import type { GetterPiNode } from '@linkpi/core/web';
import { useDispatch, useSelector } from '@umijs/max';
import { Col, Modal, Row, Select, Tag } from 'antd';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import React, { memo, useEffect, useMemo, useRef, useState } from 'react';

import request from '@/utils/request';
const { Option } = Select;
type nodeId = string;
type propsType = {
  visible: boolean;
  nodeId: nodeId | nodeId[];
  node: GetterPiNode;
  orgId: string;
  onOk: () => void;
  onCancel: () => void;
  userId: string;
};

type SysTag = Record<string, { c: string }>;

const EditSysTag = (props: propsType) => {
  const { visible, nodeId, orgId, onOk, onCancel, node, userId } = props;
  const dispatch = useDispatch();
  const { spaceTags } = useSelector((state: any) => state.space);

  const tagOptions: { tag: string }[] = useMemo(() => {
    return spaceTags[orgId] || [];
  }, [spaceTags, orgId]);

  const [saveLoading, setSaveLoading] = useState(false);
  const [tags, setTags] = useState<string[]>([]);

  const nodeRef = useRef(node);
  nodeRef.current = node;

  useEffect(() => {
    if (visible && !tagOptions.length) {
      dispatch({
        type: 'space/fetchSpaceTags',
        payload: { org_id: orgId },
      });
    }
  }, [visible, tagOptions]);

  useEffect(() => {
    if (visible) {
      // 获取第一节点的标签为默认值
      const editNode = node.value.nodeManager.findChildren(
        nodeId instanceof Array ? nodeId[0] : nodeId,
      );
      const defaultTag = Object.keys(editNode.prop._sys_tag || []);
      setTags(defaultTag);
    }
  }, [visible, nodeId]);

  const handleOk = async () => {
    setSaveLoading(true);
    const newSysTag = tags.reduce((a, b) => {
      a[b] = { c: userId };
      return a;
    }, {} as SysTag);

    // 判断有多少个是新增的
    const addTags = tags.filter((tag) => {
      return tagOptions.find((item) => item.tag === tag) === undefined;
    });

    if (addTags.length) {
      await Promise.all(
        addTags.map(async (tag) => {
          dispatch({
            type: 'space/addSpaceTag',
            payload: {
              org_id: orgId,
              node_id: nodeId instanceof Array ? nodeId[0] : nodeId,
              tag: tag,
              delete: false,
              isOld: false,
            },
          });
        }),
      );
    }

    if (nodeId instanceof Array) {
      await Promise.all(
        nodeId.map((id) => {
          updateNodeSysProp(request, {
            org_id: orgId,
            node_id: id,
            key: ['_sys_tag'],
            value: [newSysTag],
          });
        }),
      );

      setSaveLoading(false);
      onOk();
    } else {
      // 只编辑1个时
      await updateNodeSysProp(request, {
        org_id: orgId,
        node_id: nodeId,
        key: ['_sys_tag'],
        value: [newSysTag],
      });
      setSaveLoading(false);
      onOk();
    }
  };

  return (
    <Modal
      title={nodeId instanceof Array ? '批量编辑 标签' : '编辑标签'}
      open={visible}
      destroyOnClose={true}
      onCancel={onCancel}
      onOk={handleOk}
      confirmLoading={saveLoading}
    >
      <Row align="middle" justify="space-between" style={{ marginBottom: 15 }}>
        <Col span={5} style={{ textAlign: 'right' }}>
          标签
        </Col>
        <Col span={18}>
          <Select
            mode="tags"
            showArrow
            tagRender={tagRender}
            defaultValue={tags}
            onChange={setTags}
            style={{ width: '100%' }}
          >
            {tagOptions.map((x, i) => (
              <Option key={`tagOptions` + i} value={x.tag}>
                {x.tag}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
    </Modal>
  );
};

const tagRender = (props: CustomTagProps) => {
  const { label, closable, onClose } = props;
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };
  return (
    <Tag
      closable={closable}
      onClose={onClose}
      style={{ marginRight: 3 }}
      onMouseDown={onPreventMouseDown}
    >
      {label}
    </Tag>
  );
};

export default memo(EditSysTag);
