import type { ApiResponse } from '@linkpi/core';
import type { ViewList } from '@linkpi/core';
import { useDebounceFn, useMemoizedFn } from 'ahooks';
import { Button, Checkbox, Input, Popover, Radio, Switch } from 'antd';
import cls from 'classnames';
import { defaultTo } from 'ramda';
import type { FC } from 'react';
import { memo, useEffect, useMemo, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { match, P } from 'ts-pattern';

import { useViewConfig } from '@/hook/useOrgViewListRequest';
import { updateViewConfig } from '@/services/view';
import request from '@/utils/request';
import { DefaultSysButtons } from '@/utils/utils';

import styles from './styles.less';
type TableDisplayConfigType = {
  icon: boolean;
  requiredMark: boolean;
  openIcon: boolean;
};
// 目前表格的逻辑
// 新建表格视图 - tShowFieldsIndex 顺序写死
// 表格渲染 属性顺序根据 tShowFieldsIndex 排
// 显示列就是表格的属性实际上就是tShowFieldsIndex的顺序
// 排序更改表格属性顺序 - tShowFieldsIndex
const baseMap = {
  order: '序号',
  index: '编号',
  title: '标题',
  sysTag: '系统标签',
  status: '状态',
  mainbody: '正文',
  statusProp_common_0: '负责人',
  statusProp_common_1: '参与者',
  statusProp_common_2: '开始时间',
  statusProp_common_3: '结束时间',
  statusProp_common_4: '备注',
  createTime: '创建时间',
  repeatTask: '重复',
  treeLevelId: '树层ID',
  sendTime: '发送时间',
  sender: '发送人',
  creator: '创建人',
};

const RowDisplay: FC<{
  tableProps: ViewList.ViewTableShowFieldItem[];
  curViewData: ApiResponse.ViewList.ViewListItem<1 | 2 | 4 | 6>;
  getViewList?: () => void;
  template?: ApiResponse.CurrentUser.TemplateInfo;
  // 当 onChange 存在时，不会自动更新视图配置
  onChange?: (a: {
    tShowFieldsIndex: ViewList.ViewTableShowFieldItem[];
    fixedAttrPool?: string[];
  }) => void;
  // 强制更新视图配置
  forceUpdateView?: boolean;
  notLimitTemplate?: boolean;
  isDark?: boolean;
  needRefetch?: boolean;
}> = (props) => {
  const {
    curViewData,
    getViewList,
    template,
    onChange,
    forceUpdateView: forceUpdate = false,
    notLimitTemplate = false,
    isDark,
    needRefetch,
  } = props;
  const __tableProps = props.tableProps;

  const tableProps: ViewList.ViewTableShowFieldItem[] = useMemo(() => {
    return notLimitTemplate
      ? curViewData.view_info.tShowFieldsIndex.map((p) => ({
          ...p,
          name: baseMap[p.key as keyof typeof baseMap],
        }))
      : __tableProps;
  }, [__tableProps, curViewData.view_info.tShowFieldsIndex, notLimitTemplate]);

  const propsNameMap = useMemo(() => {
    const propsMap = (template?.prop || [])
      .map((p, index) => ({ ...p, index }))
      .filter((p) => p.type)
      .reduce(
        (res, cur) => {
          res[`prop_${cur.index}`] = cur.name;
          return res;
        },
        {} as Record<string, string>,
      );
    return { ...propsMap, ...baseMap } as Record<string, string>;
  }, [template?.prop]);

  const [search, setSearch] = useState('');
  const [_tableProps, _setTableProps] = useState<ViewList.ViewTableShowFieldItem[]>([]);

  // 固定的列数组
  const [fixedAttrPool, setFixedAttrPool] = useState(['order']);

  // 重排index
  useEffect(() => {
    _setTableProps(tableProps.map((p, index) => ({ ...p, index })));
  }, [tableProps]);

  // 固定列属性
  useEffect(() => {
    setFixedAttrPool(curViewData?.view_info?.fixedAttrPool || ['order']);
  }, [curViewData]);

  // 开关显示或者隐藏属性
  const onSwitchChange = (index: number, e: boolean) => {
    const _newTableProps = [..._tableProps];
    _newTableProps[index].disable = !e;

    _setTableProps(_newTableProps);
    saveViewInfo({
      tShowFieldsIndex: _newTableProps.map((p) => ({ key: p.key, disable: p.disable })),
    });
  };

  const { refetch } = useViewConfig(curViewData.view_id);

  // 保存视图的info
  const { run: saveViewInfo } = useDebounceFn(
    async (data: Partial<ViewList.ViewInfo>) => {
      if (onChange) {
        onChange({
          ...data,
          tShowFieldsIndex: data.tShowFieldsIndex!.map((p) => ({
            ...p,
            name: propsNameMap[p.key],
          })),
        });
        if (!forceUpdate) return;
      }
      const payload = { ...curViewData.view_info };

      if (data) {
        Object.keys(data).forEach((k) => {
          // @ts-ignore
          payload[k] = data[k];
        });
      }

      const req = {
        view_id: curViewData.view_id,
        view_info: payload,
      };

      await updateViewConfig(req);
      if (needRefetch) refetch();
      getViewList?.();
    },
    { wait: 1000 },
  );

  // 属性固定 和 非固定的分组
  const attrGroups = [
    {
      label: '固定',
      key: 'fixed',
      getAttrs: (attrs: ViewList.ViewTableShowFieldItem[]) =>
        attrs.filter(
          (attr) =>
            fixedAttrPool.includes(attr.key) && propsNameMap?.[attr.key]?.indexOf?.(search) !== -1,
        ),
    },
    {
      label: '非固定',
      key: 'notFixed',
      getAttrs: (attrs: ViewList.ViewTableShowFieldItem[]) =>
        attrs.filter(
          (attr) =>
            !fixedAttrPool.includes(attr.key) && propsNameMap?.[attr.key]?.indexOf(search) !== -1,
        ),
    },
  ];

  const onDragEnd = (res) => {
    const { destination, source } = res;
    if (!destination) return;

    let { droppableId: destinationType, index: destinationIndex } = destination;
    const { droppableId: sourceType, index: sourceIndex } = source;

    // 不知道为啥需要减1 后面再看
    if (sourceType === 'fixed' && destinationType === 'notFixed') destinationIndex--;

    // 交换 tShowFieldsIndex
    const _newTableProps = _tableProps.slice();
    const [removed] = _newTableProps.splice(sourceIndex, 1);
    _newTableProps.splice(destinationIndex, 0, removed);
    _setTableProps(_newTableProps.map((p, index) => ({ ...p, index })));

    // 如果都是非固定列中的交换位置
    if (sourceType === 'notFixed' && destinationType === 'notFixed') {
      return saveViewInfo({
        tShowFieldsIndex: _newTableProps.map((p) => ({ key: p.key, disable: p.disable })),
      });
    }

    // 如果都是固定的交换位置
    if (sourceType === 'fixed' && destinationType === 'fixed') {
      const newFixedAttrPool = fixedAttrPool.slice();
      const [removed] = newFixedAttrPool.splice(sourceIndex, 1);
      newFixedAttrPool.splice(destinationIndex, 0, removed);
      setFixedAttrPool(newFixedAttrPool);
      return saveViewInfo({
        tShowFieldsIndex: _newTableProps.map((p) => ({ key: p.key, disable: p.disable })),
        fixedAttrPool: newFixedAttrPool,
      });
    }

    // 固定 -> 非固定
    if (sourceType === 'fixed' && destinationType === 'notFixed') {
      const newFixedAttrPool = fixedAttrPool.slice();
      newFixedAttrPool.splice(sourceIndex, 1);
      setFixedAttrPool(newFixedAttrPool);
      return saveViewInfo({
        tShowFieldsIndex: _newTableProps.map((p) => ({ key: p.key, disable: p.disable })),
        fixedAttrPool: newFixedAttrPool,
      });
    }

    // 非固定 -> 固定
    if (sourceType === 'notFixed' && destinationType === 'fixed') {
      const newFixedAttrPool = fixedAttrPool.slice();
      newFixedAttrPool.splice(destinationIndex, 0, removed.key);
      setFixedAttrPool(newFixedAttrPool);
      return saveViewInfo({
        tShowFieldsIndex: _newTableProps.map((p) => ({ key: p.key, disable: p.disable })),
        fixedAttrPool: newFixedAttrPool,
      });
    }
  };

  const changeCustomButton = useMemoizedFn(
    (btn: ApiResponse.CurrentUser.SysButton | ApiResponse.CurrentUser.CustomButton) => {
      let newBtns = curViewData.view_info.actionButtons
        ? [...curViewData.view_info.actionButtons]
        : [];

      const actionButtons: string[] = [];
      const _actionButtons = buttons.map((b) => {
        let checked = !!curViewData.view_info.actionButtons?.includes(b.id);
        if (b.id === btn.id) {
          checked = !checked;
        }

        if (checked) {
          actionButtons.push(b.id);
        }

        return {
          checked,
          id: b.id,
        };
      });

      if (newBtns.includes(btn.id)) {
        newBtns = newBtns.filter((i) => i !== btn.id);
      } else {
        newBtns.push(btn.id);
      }
      saveViewInfo({ ...curViewData.view_info, actionButtons });
    },
  );

  const buttons = useMemo(() => {
    return [
      ...defaultTo(DefaultSysButtons, template?.custom_view?.sys_button),
      ...defaultTo([], template?.custom_button),
    ];
  }, [template?.custom_button, template?.custom_view?.sys_button]);

  const [showActions, actionButtons = []] = useMemo(
    () => [curViewData.view_info.showActions, curViewData.view_info.actionButtons],
    [curViewData.view_info.actionButtons, curViewData.view_info.showActions],
  );

  const rowContent = (
    <div className={styles.rowContentInner}>
      <p className={styles.mainRow}>显示列</p>
      <div className={styles.search}>
        <Input
          placeholder="搜索"
          bordered={false}
          prefix={<i className="iconfont iconsearch" />}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      <div className={styles.list}>
        <PerfectScrollbar className={styles.listInner}>
          <DragDropContext onDragEnd={onDragEnd}>
            {attrGroups.map((g) => (
              <Droppable key={g.key} droppableId={g.key} type="attr">
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    <div className="text-[#B6B8B9] py-2">{g.label}</div>
                    {g.getAttrs(_tableProps).map((p) => (
                      <Draggable key={p.index} index={p.index} draggableId={String(p.index)}>
                        {(draggableProvided) => (
                          <div
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                          >
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                padding: '6px 0',
                                justifyContent: 'space-between',
                              }}
                            >
                              <div
                                style={{ display: 'flex', alignItems: 'center', flex: 1, width: 0 }}
                              >
                                <i
                                  {...draggableProvided.dragHandleProps}
                                  className={`iconfont icondrag`}
                                  style={{ fontSize: 18 }}
                                />
                                <span
                                  style={{ paddingLeft: 6, color: '#242D3F', flex: 1 }}
                                  className={'text-omit'}
                                >
                                  {propsNameMap[p.key]}
                                </span>
                              </div>

                              <Switch
                                size="small"
                                key={p.key}
                                checked={!p.disable}
                                onChange={(e) => onSwitchChange(p.index, e)}
                              />
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            ))}
          </DragDropContext>
          <div>
            <div className="text-[#B6B8B9] py-2">操作</div>
            <div className="flex justify-between items-center mb-3">
              自定义按钮
              <Switch
                size="small"
                checked={showActions}
                onChange={(e) => {
                  saveViewInfo({
                    ...curViewData.view_info,
                    showActions: e,
                  });
                }}
              />
            </div>
            {showActions &&
              buttons?.map((b) => (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginBottom: 12,
                    cursor: 'pointer',
                  }}
                  key={b.id}
                >
                  <div onClick={() => changeCustomButton(b)} style={{ flex: 1 }}>
                    {match(b)
                      .with({ id: P.string.startsWith('system_button_') }, (v) => v.text)
                      .otherwise((v) => v.name)}
                  </div>
                  <Checkbox
                    checked={!!actionButtons.find((i: any) => i === b.id)}
                    onChange={() => changeCustomButton(b)}
                  />
                </div>
              ))}
          </div>
        </PerfectScrollbar>
      </div>
    </div>
  );

  return (
    <Popover
      content={rowContent}
      placement="bottomLeft"
      trigger="click"
      overlayClassName={styles.rowContent}
    >
      <Button
        type={'text'}
        className={cls(`view-setting-block`, isDark && styles.darkBlock, styles.button)}
      >
        <i className="iconfont iconView_column" />
        显示列
      </Button>
    </Popover>
  );
};

export default memo(RowDisplay);
