import Emittery from 'emittery';
import { useEffect } from 'react';

type TemplateEvent = {
  setCurrentTemplate: string;
};
export const TemplateEmitter = new Emittery<TemplateEvent>();

export const useTemplateSubscribe = ({
  onCurrentTemplateChange: onTemplateChange,
}: {
  onCurrentTemplateChange: (templateId: string) => void;
}) => {
  useEffect(() => {
    TemplateEmitter.on('setCurrentTemplate', onTemplateChange);

    return () => {
      TemplateEmitter.off('setCurrentTemplate', onTemplateChange);
    };
  }, [onTemplateChange]);
};

export const getTemplateEmitter = () => {
  return TemplateEmitter;
};
