import { Checkbox, Modal } from 'antd';
import { useMemo, useState } from 'react';

import { exportGraphicCodes, getExportGraphicCodesPropValues } from '@/utils/utils';

interface IProps {
  closeModal: () => void;
  nodes: any[];
  graphicCodeProps: any[];
}

export default (props: IProps) => {
  const { closeModal, nodes, graphicCodeProps } = props;

  const [checkedProps, setCheckedProps] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const graphicCodePropsMap = useMemo(() => {
    return graphicCodeProps.reduce((res, p) => {
      res[p.index] = p;
      return res;
    }, {} as any);
  }, []);

  const changeCheckedProps = (pIndex: any) => {
    const checked = checkedProps.includes(pIndex);
    if (checked) setCheckedProps(checkedProps.filter((p) => p !== pIndex));
    else setCheckedProps([...checkedProps, pIndex]);
  };

  const ok = async () => {
    setLoading(true);
    const propValues: any[] = getExportGraphicCodesPropValues(
      nodes,
      checkedProps.map((pIndex) => graphicCodePropsMap[pIndex]),
    );

    await exportGraphicCodes(propValues);
    setLoading(false);
    closeModal();
  };
  return (
    <Modal confirmLoading={loading} visible title="选择导出的属性" onOk={ok} onCancel={closeModal}>
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {graphicCodeProps.map((p) => (
          <Checkbox
            key={p.index}
            onChange={() => changeCheckedProps(p.index)}
            checked={checkedProps.includes(p.index)}
            children={p.name}
          />
        ))}
      </div>
    </Modal>
  );
};
