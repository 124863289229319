import { PlusOutlined } from '@ant-design/icons';
import type { FormListActionType } from '@ant-design/pro-form';
import ProForm, { ProFormText } from '@ant-design/pro-form';
import { generateId } from '@linkpi/core';
import { Row, Typography } from 'antd';
import type { FC, ReactNode } from 'react';
import { useRef } from 'react';

import { PiButton } from '@/components';
import { PiProFormList } from '@/components/EnhanceForm';

import styles from './ProFormVerticalTermsGroup.less';

const { Text } = Typography;

const itemRender = (dom: any) => {
  return (
    <div className={styles.group}>
      {dom.listDom}
      {dom.action}
    </div>
  );
};

type ProFormVerticalTermsGroupProp = {
  itemField: ReactNode;
};
export const ProFormVerticalTermsGroup: FC<ProFormVerticalTermsGroupProp> = ({ itemField }) => {
  const listRef = useRef<FormListActionType>();

  return (
    <>
      <Row justify="space-between" align="middle" style={{ marginBottom: 12 }}>
        <Text style={{ color: '#b6b8b9' }}>图例项</Text>
        <PiButton type="secondary" size="tiny" onClick={() => listRef.current?.add({})}>
          <PlusOutlined />
          添加
        </PiButton>
      </Row>
      <PiProFormList
        name="verticalTermsGroup"
        creatorButtonProps={false}
        copyIconProps={false}
        actionRef={listRef}
        itemRender={itemRender}
        initialValue={[]}
      >
        {(_, index) => (
          <div className="full-w">
            <ProFormText
              name="groupName"
              placeholder="请输入图例项目名称"
              hidden
              initialValue={generateId() + '@' + index.toString()}
            />
            <ProForm.Item name="value">{itemField}</ProForm.Item>
          </div>
        )}
      </PiProFormList>
    </>
  );
};
