import { Modal } from 'antd';
interface DataType {
  q: string; //模板ID
  t: string; //操作类型
  ov: any; //历史值
  v: any; //修改值
  time: number; //操作时间
  ad: string[]; //操作人ID
  auto: string; //自动化ID
  i: number; // propIndex
  qn: string[];
}
interface IProp {
  closeModal: () => void;
  record: DataType;
  orgConnection: any;
  orgId: string;
}
export default (prop: IProp) => {
  const { orgConnection, orgId, record } = prop;

  const goNodeContent = (n: PiNode) => {
    window.open(`${window.location.origin}/home/${orgId}/${n.id}/${n.id}`, '', 'noopener');
  };
  return (
    <Modal
      open
      title="主题详情"
      onCancel={prop.closeModal}
      footer={null}
      bodyStyle={{ padding: 0 }}
      width={548}
    >
      <div style={{ padding: '20px 20px' }}>
        {(record.qn ||[]).map((i: string) => {
          const n: PiNode = orgConnection.nodeManager.findChildren(i);
          if (!n) return null;
          return (
            <div
              key={i}
              style={{
                height: 52,
                display: 'flex',
                alignItems: 'center',
                borderRadius: 8,
                cursor: 'pointer',
                backgroundColor: '#F9FAFC',
                color: '#242E41',
                paddingLeft: 28,
                marginBottom: 4,
              }}
              onClick={() => goNodeContent(n)}
            >
              {n.title}
            </div>
          );
        })}
      </div>
    </Modal>
  );
};
