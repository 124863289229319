import { QuestionCircleOutlined } from '@ant-design/icons';
import type { ApiResponse, CurrentUser } from '@linkpi/core';
import { propIsNull } from '@linkpi/core';
import { getQuoteOriginProp } from '@linkpi/core';
import type { FormInstance, SelectProps } from 'antd';
import {
  Button,
  Cascader,
  Checkbox,
  DatePicker,
  Form,
  Input,
  message,
  Segmented,
  Select,
  Tooltip,
} from 'antd';
import type { DefaultOptionType } from 'antd/lib/select';
import moment from 'moment';
import { isNil } from 'ramda';

import { isQuotePropCanCount } from '@/utils/utils';

import { ProFormVerticalTermsGroup } from './components/ProFormVerticalTermsGroup';
import type { VerticalAxisOption } from './getFormOptions';
import { DATE_INTERVAL_OPTION } from './getXAxisForm';

const { OptGroup, Option } = Select;

const getIsType = (itemOption: any, type: ApiResponse.CurrentUser.propType) => {
  if (!itemOption) return false;

  if (itemOption.propType === type) return true;
  if (itemOption.originType === type) return true;

  return false;
};

export const getYAxisForm = (props: {
  viewType: number;
  currentTemplate: any;
  form: FormInstance;
  verticalGranularityGroup: any;
  templates: any;
  templateMap: any;
  handleVerticalKeyChange: any;
  columnOptionGroup: any;
  getColumnGroupOptions: any;
  handleTimeTypeChange: any;
  setVerticalAxis: any;
  setVerticalOptions: any;
  verticalAxisOptions: VerticalAxisOption[];
  verticalOptions: any;
  verticalAxis: any;
}) => {
  const {
    viewType,
    currentTemplate,
    form,
    verticalGranularityGroup,
    templates,
    templateMap,
    handleVerticalKeyChange,
    columnOptionGroup,
    getColumnGroupOptions,
    handleTimeTypeChange,
    setVerticalAxis,
    setVerticalOptions,
    verticalAxisOptions,
    verticalOptions,
    verticalAxis,
  } = props;

  const isMultiple = (key: any, cKey: any) => {
    return (
      form.getFieldValue('verticalAxis')[key] &&
      !(
        form.getFieldValue('verticalAxis')[key].conditions[cKey]?.key?.endsWith('Time') ||
        form.getFieldValue('verticalAxis')[key].conditions[cKey]?.key === 'statusFlow'
      )
    );
  };
  const getNumberProps = () => {
    const t = templates.find((x: any) => x.template_id === currentTemplate);

    // 兼容没有选择主题类型
    if (!Array.isArray(t?.prop)) return [];

    return t.prop
      .map((p: any, index: number) => ({ ...p, index }))
      .filter((p: any) => {
        if (p.type === 'formula' && p.formulaFormat === 0) return true;
        if (p.type === 'number' || p.type === 'currency') return true;
        if (p.type === 'quote') return isQuotePropCanCount(p, getQuoteOriginProp(p, templateMap));
        return false;
      });
  };

  // 视图y轴
  const renderYAxis = (_name?: string | number, customName?: string) => {
    const name = isNil(_name) ? customName! : _name;
    const fullName = customName || 'verticalAxis';
    return (
      <>
        <Form.Item
          name={[name, 'statisticsType']}
          style={{ marginBottom: 8 }}
          initialValue={'node'}
        >
          <Segmented
            block
            options={
              [
                { label: '统计主题总数', value: 'node' },
                viewType === 4 && { label: '统计指定属性', value: 'statisticsAttr' },
                { label: '统计属性的计算', value: 'calculateAttr' },
              ].filter(Boolean) as any[]
            }
          />
        </Form.Item>

        <Form.Item noStyle shouldUpdate>
          {() => {
            const verticalAxisList = form.getFieldValue(fullName) || [{}];

            const statisticsType = (!isNil(_name) ? verticalAxisList[name] : verticalAxisList)
              .statisticsType;

            // 统计主题总数
            if (statisticsType === 'node') return null;

            // 统计指定属性
            if (statisticsType === 'statisticsAttr')
              return (
                <Form.Item
                  name={[name, 'verticalGranularity']}
                  style={{ marginBottom: 8 }}
                  rules={[
                    {
                      required: true,
                      message: '请选择统计维度',
                    },
                  ]}
                >
                  <Select
                    filterOption={(input, option) =>
                      ((option?.children as unknown as string) || (option?.label as string))
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    showSearch={true}
                    placeholder={'请选择统计维度'}
                  >
                    {Object.keys(verticalGranularityGroup).map((group, i) => {
                      return (
                        <OptGroup label={verticalGranularityGroup[group].label} key={i}>
                          {verticalGranularityGroup[group].options?.map(
                            (option: any, index: number) => {
                              return (
                                <Option value={option.value} key={group.concat(String(index))}>
                                  {option.label}
                                </Option>
                              );
                            },
                          )}
                        </OptGroup>
                      );
                    })}
                  </Select>
                </Form.Item>
              );

            // 数字属性计数
            if (statisticsType === 'calculateAttr') {
              return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Form.Item
                    name={[name, 'calculateProp']}
                    style={{ marginBottom: 8, width: '70%' }}
                  >
                    <Select
                      filterOption={(input, option) =>
                        ((option?.children as unknown as string) || (option?.label as string))
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      showSearch={true}
                      placeholder={'请选择属性'}
                    >
                      {getNumberProps().map((p: any) => (
                        <Option key={p.index} value={p.index}>
                          {p.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <div style={{ width: '2%' }} />
                  <Form.Item
                    name={[name, 'type']}
                    style={{ marginBottom: 8, width: '28%' }}
                    initialValue="p"
                  >
                    <Select
                      filterOption={(input, option) =>
                        (option?.label || (option?.label as string))
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      showSearch={true}
                      placeholder={'请选择统计类型'}
                      options={[
                        { value: 'p', label: '计数' },
                        { value: 'm', label: '求和' },
                        { value: 'a', label: '求平均' },
                        { value: 'x', label: '求最大' },
                        { value: 'i', label: '求最小' },
                      ].filter((i) => {
                        // 饼图仅支持计数与求和
                        if (viewType === 5) return ['m', 'p'].includes(i.value);
                        return true;
                      })}
                    />
                  </Form.Item>
                </div>
              );
            }
          }}
        </Form.Item>
      </>
    );
  };

  // 饼图
  if (viewType === 5) {
    return <Form.Item label="Y轴">{renderYAxis(undefined, 'yAxisStatisticsType')}</Form.Item>;
  }

  // 多维视图
  if (viewType === 4) {
    return (
      <Form.Item>
        <Form.List name="verticalAxis">
          {(fields, { add, remove }) => (
            <>
              <Form.Item style={{ marginBottom: 8 }}>
                <div className="form-label line-label">
                  Y轴（表格列）
                  <Button
                    className={'line-add-btn'}
                    size={'small'}
                    onClick={() => {
                      if (currentTemplate) {
                        add({ statisticsType: 'node' });
                      } else {
                        form.validateFields(['template']);
                        message.warn('请选择主题类型');
                      }
                    }}
                    icon={<i className={'iconfont iconadd'} style={{ fontSize: 12 }} />}
                  >
                    添加
                  </Button>
                </div>
              </Form.Item>
              {fields.map(({ key, name }) => (
                <div className="vertical-items" key={key}>
                  <div className={'form-list-item'}>
                    <Form.Item
                      name={[name, 'name']}
                      style={{ marginBottom: 8 }}
                      rules={[
                        {
                          required: true,
                          message: '请输入列名称',
                        },
                      ]}
                      className={'list-item'}
                    >
                      <Input placeholder={'请输入列名称'} />
                    </Form.Item>
                    <div
                      className="cancel-btn"
                      onClick={() => {
                        remove(name);
                      }}
                    >
                      <i className="iconfont iconButton_cancel" />
                    </div>
                  </div>
                  {renderYAxis(name)}

                  {/* 多维统计视图 y轴的每项 支持添加筛选 */}
                  <Form.List name={[name, 'conditions']}>
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key: cKey, name: cName, ...restField }) => (
                          <div key={cKey} className={'form-list-item'}>
                            <Form.Item
                              {...restField}
                              name={[cName, 'key']}
                              rules={[{ required: true, message: '请选择筛选条件' }]}
                              className={'group-key'}
                            >
                              <Select
                                filterOption={(input, option) =>
                                  (
                                    (option?.children as unknown as string) ||
                                    (option?.label as string)
                                  )
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                showSearch={true}
                                placeholder={'筛选条件'}
                                onChange={(e) => handleVerticalKeyChange(e, name, cName)}
                              >
                                {Object.keys(columnOptionGroup).map((group, i) => {
                                  return (
                                    <OptGroup label={columnOptionGroup[group].label} key={i}>
                                      {columnOptionGroup[group].options?.map(
                                        (option: any, index: number) => {
                                          return (
                                            <Option
                                              value={option.value}
                                              key={group.concat(String(index))}
                                              disabled={form
                                                .getFieldValue('verticalAxis')
                                                [
                                                  key
                                                ]?.conditions?.find((x: any) => x?.key === option.value)}
                                            >
                                              {option.label}
                                            </Option>
                                          );
                                        },
                                      )}
                                    </OptGroup>
                                  );
                                })}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[cName, 'value']}
                              className={'group-value'}
                            >
                              {form.getFieldValue('verticalAxis')[name].conditions[cName]?.key ===
                              'statusFlow' ? (
                                <>
                                  <Form.Item
                                    name={[cName, 'value', 'targetStatus']}
                                    rules={[{ required: true, message: '请选择流转状态' }]}
                                    style={{ marginBottom: 12 }}
                                  >
                                    <Select
                                      filterOption={(input, option) =>
                                        (
                                          (option?.children as unknown as string) ||
                                          (option?.label as string)
                                        )
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                      showSearch={true}
                                      placeholder={'请选择流转状态'}
                                      options={getColumnGroupOptions(name, cName)}
                                    />
                                  </Form.Item>
                                  <Form.Item
                                    name={[cName, 'value', 'flowTime']}
                                    rules={[{ required: true, message: '请选择流转时间' }]}
                                    style={{ marginBottom: 12 }}
                                  >
                                    <Select
                                      filterOption={(input, option) =>
                                        (option as any)?.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                      showSearch={true}
                                      placeholder={'请选择流转时间'}
                                      options={[
                                        {
                                          label: '今日',
                                          value: 1,
                                        },
                                        {
                                          label: '三天',
                                          value: 3,
                                        },
                                        {
                                          label: '七天',
                                          value: 7,
                                        },
                                      ]}
                                    />
                                  </Form.Item>
                                </>
                              ) : form
                                  .getFieldValue('verticalAxis')
                                  [name].conditions[cName]?.key?.endsWith('Time') ? (
                                <>
                                  <Form.Item
                                    name={[cName, 'value', 'timeType']}
                                    rules={[{ required: true, message: '请选择时间类型' }]}
                                    style={{ marginBottom: 12 }}
                                    initialValue={0}
                                  >
                                    <Select
                                      placeholder={'请选择时间类型'}
                                      onChange={() => handleTimeTypeChange(name, cName)}
                                    >
                                      <Option value={0}>范围</Option>
                                      <Option value={1}>日期</Option>
                                    </Select>
                                  </Form.Item>

                                  {form.getFieldValue('verticalAxis')[name].conditions[cName].value
                                    ?.timeType === 1 ? (
                                    <Form.Item
                                    // fieldKey={[cFieldKey, 'value', 'timeValue']}
                                    // name={[cName, 'value', 'timeValue']}
                                    // rules={[
                                    //   { required: true, message: '请选择时间范围' },
                                    // ]}
                                    // style={{ marginBottom: 12 }}
                                    >
                                      <Form.Item
                                        name={[cName, 'value', 'timeValue', '0']}
                                        rules={[{ required: true, message: '请选择开始时间' }]}
                                        style={{ marginBottom: 12 }}
                                      >
                                        <DatePicker
                                          placeholder="请选择开始时间"
                                          showToday={false}
                                        />
                                      </Form.Item>
                                      <Form.Item
                                        name={[cName, 'value', 'timeValue', '1']}
                                        rules={[{ required: true, message: '请选择结束时间' }]}
                                        style={{ marginBottom: 12 }}
                                      >
                                        <DatePicker
                                          placeholder="请选择结束时间"
                                          showToday={false}
                                          popupClassName="chartform-date-picker"
                                          onChange={(value) => {
                                            // onchange时去掉timeValue[]第三位
                                            const curValue = form.getFieldsValue();
                                            const conditionsValue =
                                              curValue.verticalAxis[name].conditions[cName].value;
                                            conditionsValue.timeValue = [
                                              conditionsValue.timeValue[0],
                                              value,
                                            ];
                                            form.setFieldsValue(curValue);
                                          }}
                                          // 通过timeValue[]第三位判断是否今日
                                          format={(date) => {
                                            const dateExtra = form.getFieldValue([
                                              'verticalAxis',
                                              name,
                                              'conditions',
                                              cName,
                                              'value',
                                              'timeValue',
                                              2,
                                            ]);

                                            if (dateExtra === 0) {
                                              return '今日';
                                            } else {
                                              return date ? date.format('YYYY-MM-DD') : '';
                                            }
                                          }}
                                          // 点击设置为今日
                                          renderExtraFooter={(mode) => {
                                            return (
                                              <div>
                                                <Button
                                                  type="primary"
                                                  size="small"
                                                  onClick={() => {
                                                    const curValue = form.getFieldsValue();
                                                    const conditionsValue =
                                                      curValue.verticalAxis[name].conditions[cName]
                                                        .value;
                                                    conditionsValue.timeValue = [
                                                      conditionsValue.timeValue[0],
                                                      moment(),
                                                      0,
                                                    ];
                                                    form.setFieldsValue(curValue);
                                                  }}
                                                >
                                                  今日
                                                </Button>
                                                <span style={{ fontSize: 14, color: '#B7BAC0' }}>
                                                  （日期将每日自动更新）
                                                </span>
                                              </div>
                                            );
                                          }}
                                        />
                                      </Form.Item>
                                      {/* <RangePicker /> */}
                                      {/* TODO */}
                                    </Form.Item>
                                  ) : (
                                    <Form.Item
                                      name={[cName, 'value', 'timeRange']}
                                      rules={[{ required: true, message: '请选择时间范围' }]}
                                      style={{ marginBottom: 12 }}
                                    >
                                      <Select
                                        placeholder={'请选择时间范围'}
                                        options={[
                                          {
                                            label: '今日',
                                            value: 1,
                                          },
                                          {
                                            label: '三天',
                                            value: 3,
                                          },
                                          {
                                            label: '七天',
                                            value: 7,
                                          },
                                          {
                                            label: '月度',
                                            value: 30,
                                          },
                                          {
                                            label: '季度',
                                            value: 90,
                                          },
                                          {
                                            label: '年度',
                                            value: 365,
                                          },
                                        ]}
                                      />
                                    </Form.Item>
                                  )}
                                </>
                              ) : (
                                <Select
                                  filterOption={(input, option) =>
                                    (option?.children as any)
                                      .toLowerCase()
                                      .indexOf(input.toLowerCase()) >= 0
                                  }
                                  showSearch={true}
                                  placeholder={'筛选值'}
                                  mode={isMultiple(key, cKey) ? 'multiple' : undefined}
                                  options={getColumnGroupOptions(name, cName)}
                                />
                              )}
                            </Form.Item>
                            <div
                              className="cancel-btn"
                              onClick={() => {
                                remove(cName);
                                // handleVerticalKeyChange(null, name);
                              }}
                            >
                              <i className="iconfont iconButton_cancel" />
                            </div>
                          </div>
                        ))}
                        <Form.Item>
                          <div className="form-label line-label">
                            <Button
                              className={'line-add-btn-primary'}
                              size={'small'}
                              onClick={async () => {
                                const fc =
                                  form.getFieldValue('verticalAxis')[key]?.conditions || [];
                                if (!~fc?.findIndex((x: any) => !x?.key || propIsNull(x?.value))) {
                                  add();
                                }
                              }}
                              icon={<i className={'iconfont iconadd'} style={{ fontSize: 12 }} />}
                            >
                              新增筛选条件
                            </Button>
                          </div>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                  <Form.Item noStyle shouldUpdate>
                    {() => {
                      const verticalAxisList = form.getFieldValue('verticalAxis') || [];
                      const statisticsType = verticalAxisList[name]?.statisticsType;
                      if (statisticsType === 'calculateAttr')
                        return <Form.Item style={{ margin: '-56px 0 0 50%' }} />;
                      return (
                        <Form.Item
                          name={[name, 'percent']}
                          style={{ margin: '-56px 0 0 50%' }}
                          valuePropName={'checked'}
                          required={false}
                          colon={false}
                        >
                          <Checkbox>统计百分比</Checkbox>
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </div>
              ))}
            </>
          )}
        </Form.List>
      </Form.Item>
    );
  }

  const verticalConfig = verticalAxisOptions.find((x: any) => x.value === verticalAxis);

  // 图例项
  const getVerticalTerms = () => {
    if (!verticalConfig) return null;
    if (
      getIsType(verticalConfig, 'text') ||
      getIsType(verticalConfig, 'auto_inc') ||
      (getIsType(verticalConfig, 'formula') && verticalConfig?.option.formulaFormat === 1) ||
      getIsType(verticalConfig, 'date') ||
      getIsType(verticalConfig, 'datetime') ||
      getIsType(verticalConfig, 'number')
    ) {
      return null;
    }

    let enumSelectProps = {};
    if (
      verticalConfig.propType === 'enum' &&
      (verticalConfig.option as CurrentUser.TemplateProp).extendColorMode
    ) {
      const { extendColor, extend } = verticalConfig.option as CurrentUser.TemplateProp;
      enumSelectProps = {
        tagRender: (tagProps) => (
          <span
            className="mr-1 px-2 text-white rounded-full"
            style={{
              backgroundColor: extendColor![extend.findIndex((e: string) => e === tagProps.value)],
            }}
          >
            {tagProps.value}
          </span>
        ),
        options: verticalOptions.map((o: DefaultOptionType, index: number) => ({
          ...o,
          label: (
            <span
              className="px-2 text-white rounded-full"
              style={{ backgroundColor: extendColor![index] }}
            >
              {o.label}
            </span>
          ),
        })),
      } as {
        tagRender: SelectProps['tagRender'];
        options: SelectProps['options'];
      };
    }

    let ItemField = (
      <Select
        mode={'multiple'}
        options={verticalOptions}
        filterOption={(input, option) =>
          ((option?.children as any) || option?.label)
            ?.toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        }
        placeholder="请选择图例项"
        {...enumSelectProps}
      />
    );

    if (getIsType(verticalConfig, 'cascade')) {
      ItemField = (
        <Cascader
          placeholder="请选择图例项"
          multiple
          options={verticalConfig.option?.cascade?.nodes}
          fieldNames={{
            label: 'title',
            value: 'key',
            children: 'children',
          }}
        />
      );
    }

    if (getIsType(verticalConfig, 'address')) {
      ItemField = (
        <Cascader
          placeholder="请选择图例项"
          multiple
          options={verticalOptions}
          fieldNames={{
            label: 'name',
            value: 'adcode',
            children: 'districts',
          }}
        />
      );
    }

    if (
      viewType !== 1 ||
      // 仅有选值属性会显示分组
      getIsType(verticalConfig, 'cascade') ||
      getIsType(verticalConfig, 'address')
    )
      return (
        <Form.Item name={'verticalTerms'} required={false} colon={false} label="图例项">
          {ItemField}
        </Form.Item>
      );

    return <ProFormVerticalTermsGroup itemField={ItemField} />;
  };

  return (
    <>
      {viewType !== 4 && (
        <Form.Item label="Y轴">{renderYAxis(undefined, 'yAxisStatisticsType')}</Form.Item>
      )}
      <div className="form-label">图例类别</div>
      <div style={{ display: 'flex' }}>
        <Form.Item
          style={{ flex: 1 }}
          name={'verticalAxis'}
          rules={[
            {
              required: false,
              message: '请选择图例类别',
            },
          ]}
        >
          <Select
            placeholder={'请选择图例类别'}
            filterOption={(input, option) =>
              (option?.children as any).toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            allowClear={true}
            showSearch={true}
            onChange={(e) => {
              setVerticalAxis(e);
              form.resetFields(['verticalTerms']);
              if (getIsType(verticalConfig, 'date') || getIsType(verticalConfig, 'datetime')) {
                form.setFieldValue(['verticalAxisDateInterval'], DATE_INTERVAL_OPTION[0].value);
              }
              setVerticalOptions([]);
            }}
          >
            {verticalAxisOptions
              .filter(
                (o) =>
                  // 暂不支持
                  !['address', 'cascade'].includes(o.propType!),
              )
              .map((option: any, i: number) => {
                return (
                  <Option
                    value={option.value}
                    key={i}
                    disabled={
                      option.value === -2
                      // ||
                      // (Array.isArray(horizontalAxis)
                      //   ? ~horizontalAxis.indexOf(option.value)
                      //   : option.value === horizontalAxis)
                    }
                  >
                    {option.label}
                  </Option>
                );
              })}
          </Select>
        </Form.Item>
        {getIsType(verticalConfig, 'date') || getIsType(verticalConfig, 'datetime') ? (
          <Form.Item
            name={'verticalAxisDateInterval'}
            required={false}
            colon={false}
            style={{ marginLeft: 8, flex: 1 }}
            initialValue={DATE_INTERVAL_OPTION[0].value}
          >
            <Select options={DATE_INTERVAL_OPTION} />
          </Form.Item>
        ) : null}
      </div>

      {getVerticalTerms()}

      <div className={'view-terms-set'}>
        <Form.Item
          name={'others'}
          valuePropName={'checked'}
          required={false}
          colon={false}
          style={{ margin: '0 8px 0 0' }}
        >
          <Checkbox />
        </Form.Item>
        <div className="form-label" style={{ margin: '0 8px 0 0' }}>
          包含其他
        </div>
        <Tooltip title={'显示未勾选的图例项总和'} placement={'bottom'}>
          <QuestionCircleOutlined style={{ margin: '0 8px 0 0', color: '#6B7A96' }} />
        </Tooltip>
      </div>
    </>
  );
};
