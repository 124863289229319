import { convertPropDefaultValueToPropDefaultFormula } from '@linkpi/core';
import { Avatar, Select } from 'antd';
import { isNil } from 'ramda';
import type { FC } from 'react';
import { useMemo } from 'react';

import { extractInput } from '@/utils/formulaExtract';

import { useTempPropConfig } from '../hooks';

export const UserType: FC = () => {
  const { propParams, setPropParams, savePropParams, spaceUserList, groups } = useTempPropConfig();

  const defaultOptions = useMemo(() => {
    const userOptions = [
      {
        children: <div className="user-option-label">创建者</div>,
        value: 'creator',
        label: '创建者',
      },
    ];
    spaceUserList.forEach((user) => {
      if (
        propParams.extend?.includes('-1') ||
        (Array.isArray(user.group_ids) &&
          user.group_ids.find((id: string) => ~propParams.extend?.indexOf(id)))
      ) {
        userOptions.push({
          children: (
            <div className="user-option-label">
              <Avatar size={16} src={user.avatar} />
              {user.nick_name}
            </div>
          ),
          value: user.account_id,
          label: user.nick_name,
        });
      }
    });
    return userOptions;
  }, [propParams.extend, spaceUserList]);

  const defaultValue = useMemo(() => {
    if (isNil(propParams.defaultFormula)) {
      if (propParams.defaultValue) return propParams.defaultValue;
      return undefined;
    }

    const { defaultFormula } = propParams;
    if (defaultFormula.type === 'formula') {
      const input = extractInput(defaultFormula);

      if (input?.type === 'updater') return 'updater';
      else if (input?.type === 'creator') return 'creator';
      // else if (input?.type === 's_prop') return `status-${input.prop}`;
    } else if (defaultFormula.type === 'prop') {
      return `prop-${defaultFormula.prop}`;
    } else if (defaultFormula.type === 'const') {
      return defaultFormula.const;
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propParams.defaultFormula]);

  return (
    <>
      <div className={'prop-item'} key={'groups'}>
        <div className={'prop-item-label'}>可用值</div>
        <div className={'prop-item-input'}>
          <Select
            placeholder="请选择可用值"
            value={propParams.extend}
            filterOption={(input, option) =>
              option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            showSearch
            options={groups
              .filter((g) => g.group_id !== '-8')
              .map((x: any) => {
                return {
                  label: x.group_name,
                  value: x.group_id,
                };
              })}
            mode="multiple"
            onChange={(e) => setPropParams({ ...propParams, extend: e })}
          />
        </div>
      </div>
      <div className={'prop-item'} key={'defaultValue'}>
        <div className={'prop-item-label'}>默认值</div>
        <div className={'prop-item-input'}>
          <Select
            placeholder={'请选择'}
            value={defaultValue}
            options={defaultOptions}
            onChange={(e) => {
              if (isNil(e)) {
                savePropParams(null, 'defaultFormula');
                savePropParams(null, 'defaultValue');
                return;
              }
              const value = convertPropDefaultValueToPropDefaultFormula(e);
              savePropParams(value, 'defaultFormula');
            }}
            filterOption={(input, option) =>
              !!option?.label && !!~option.label.toLowerCase().indexOf(input.toLowerCase())
            }
            allowClear={true}
            showSearch
          />
        </div>
      </div>
    </>
  );
};
