import type { ApiResponse } from '@linkpi/core';
import { DEFAULT_AVATAR } from '@linkpi/core';
import { displayCascadeValue } from '@linkpi/core';
import { tempValueDisplay } from '@linkpi/core';
import { Modal, Spin, Tooltip } from 'antd';
import cls from 'classnames';
import dayjs from 'dayjs';
import moment from 'moment';
import type { CSSProperties, ForwardRefRenderFunction } from 'react';
import { forwardRef, memo, useImperativeHandle, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { useOrgDepartmentNodesMap } from '@/hook/useOrgStructure';
import NodeHistoryModal from '@/pages/home/components/NodeHistoryModal';
import request from '@/utils/request';
import { getFileTypeIconByUrl } from '@/utils/utils';

import styles from './index.less';

type ShowFuncArgType = {
  nodeId: string;
  orgId: string;
  propConfig: ApiResponse.CurrentUser.TemplateProp;
  propIndex: number;
};

type RecordType = {
  /** 账号id */
  ad: string;
  /** 备注 */
  c: string;
  /** 属性index */
  i: number;
  /** 修改时间戳 */
  time: number;
  /** 修改前的值 */
  ov: any;
  /** 修改后的值 */
  v: any;
  /** 修改前的附件地址 */
  oa?: string[];
  /** 修改后的附件地址 */
  a?: string[];
};

type PropsType = {
  userMap: Record<string, ApiResponse.OrgUser.OrgUserItem>;
  tempMap?: Record<string, ApiResponse.CurrentUser.TemplateInfo>;
  orgId: string;
  nodeId: string;
};

export type PropRecordModalRefType = {
  show: ({ nodeId, orgId }: ShowFuncArgType) => void;
};

/**
 * TODO 多级， 1.6
 */
const PropRecordModal: ForwardRefRenderFunction<PropRecordModalRefType, PropsType> = (
  props,
  ref,
) => {
  useImperativeHandle(ref, () => ({
    show: show,
  }));

  const { tempMap, userMap } = props;
  const departmentMap = useOrgDepartmentNodesMap();

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recordList, setRecordList] = useState<RecordType[]>([]);
  const [title, setTitle] = useState('');

  const [showNodeHistoryModal, setShowNodeHistoryModal] = useState(false);
  const [timeRange, setTimeRange] = useState<Array<number>>([]);
  const [propConfig, setPropConfig] = useState<ApiResponse.CurrentUser.TemplateProp | null>(null);

  const show = async ({ nodeId, orgId, propConfig, propIndex }: ShowFuncArgType) => {
    setPropConfig(propConfig);
    setTitle(`${propConfig.name} 修改历史`);
    setRecordList([]);
    setVisible(true);
    setLoading(true);

    const res = await request('/docapi/getPropModifyLog', {
      method: 'POST',
      data: {
        org_id: orgId,
        node_id: nodeId,
        index: propIndex,
      },
    });
    setLoading(false);

    console.log(res);
    if (res.status === 'ok') {
      setRecordList((res.data as RecordType[]).sort((a, b) => b.time - a.time));
    }
  };

  const getContentDisplay = (record: RecordType) => {
    if (propConfig?.type === 'attachment') {
      // 附件个数 超过x flex:1 并开启滚动
      const scrollStyle: CSSProperties = {
        flex: 1,
        overflowX: 'auto',
        display: 'flex',
      };
      let scrollAfter = false;
      let scrollNow = false;
      if (record.ov?.length > 9) {
        scrollAfter = true;
      }
      if (record.v?.length > 9) {
        scrollNow = true;
      }

      return (
        <div className={styles.contentRow}>
          <div className={cls(styles.attachmentList)} style={scrollAfter ? scrollStyle : undefined}>
            {(record.ov || []).map((x: string, index: number) => (
              <img
                key={index}
                className={styles.attachment}
                src={getFileTypeIconByUrl((record.oa || [])[index]).src}
              />
            ))}
          </div>
          <div className={styles.arrow}>
            <i className="iconfont iconBack" />
          </div>
          <div className={cls(styles.attachmentList)} style={scrollNow ? scrollStyle : undefined}>
            {(record.v || []).map((x: string, index: number) => (
              <img
                key={index}
                className={styles.attachment}
                src={getFileTypeIconByUrl((record.a || [])[index]).src}
              />
            ))}
          </div>
        </div>
      );
    }

    const before = displayValue(record.ov, record.i);
    const after = displayValue(record.v, record.i);

    return (
      <div className={styles.contentRow}>
        <Tooltip title={before}>
          <div className={cls(styles.value, 'text-omit')}>{before}</div>
        </Tooltip>
        <div className={styles.arrow}>
          <i className="iconfont iconBack" />
        </div>
        <Tooltip title={after}>
          <div className={cls(styles.value, 'text-omit')}>{after}</div>
        </Tooltip>
      </div>
    );
  };
  const displayValue = (value: any, index: number) => {
    if (propConfig?.type === 'cascade') {
      // 多级选值 存的是 特殊数据结构
      return displayCascadeValue({
        value: value,
        cascadeNodes: propConfig.cascade.nodes,
        multiple: propConfig.multiple,
        hideRoutes: propConfig.hideRoutes,
      });
    }
    return tempValueDisplay({
      propConfig,
      propValue: value,
      propIndex: index,
      userMap: userMap,
      tempMap: tempMap || {},
      departmentMap,
    });
  };
  const displayUser = (userId: string) => {
    const user = props.userMap[userId];
    const avatar = user ? user.avatar : DEFAULT_AVATAR;
    const name = user ? user.nick_name : '未知用户';
    return {
      avatar,
      name,
    };
  };

  const showNodeHistoryDetail = (item: RecordType) => {
    const time = item.time;
    const before = moment(time).subtract(5, 'm');
    const after = moment(time).add(5, 'm');
    setTimeRange([before.valueOf(), after.valueOf()]);
    setShowNodeHistoryModal(true);
  };

  return (
    <Modal
      open={visible}
      onCancel={() => setVisible(false)}
      title={title}
      footer={null}
      width={726}
    >
      <Spin spinning={loading}>
        {!loading && !recordList.length ? (
          <p style={{ textAlign: 'center', fontSize: 12, color: '#767C88', margin: '28px 0' }}>
            暂无修改历史
          </p>
        ) : null}
        <PerfectScrollbar options={{ suppressScrollX: true }} className={styles.list}>
          <div className={styles.listInner}>
            {recordList.map((item, index) => (
              <div key={index} className={styles.listItem}>
                <div className={styles.listItemDate}>
                  {dayjs(item.time).format('YY/MM/DD HH:mm')}
                </div>
                <div className={styles.listItemContent}>
                  {getContentDisplay(item)}

                  <div style={{ justifyContent: 'space-between' }} className={styles.contentRow}>
                    <div>
                      <div className={cls(styles.editor, 'text-omit')}>
                        修改者：
                        <img src={displayUser(item.ad).avatar} alt="" />
                        {displayUser(item.ad).name}
                      </div>
                      {item.c ? (
                        <div className={cls(styles.commit, 'text-omit')}>
                          备注：
                          <Tooltip title={item.c}>
                            <span>{item.c}</span>
                          </Tooltip>
                        </div>
                      ) : null}
                    </div>

                    <div
                      onClick={() => showNodeHistoryDetail(item)}
                      style={{
                        color: '#316EF5',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <span style={{ fontSize: 12 }}>查看更多</span>
                      <i
                        style={{ color: '#6B7A96', marginLeft: 4 }}
                        className="iconfont iconroute"
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </PerfectScrollbar>
      </Spin>

      {showNodeHistoryModal && (
        <NodeHistoryModal
          timeRange={timeRange}
          id={props.nodeId}
          orgId={props.orgId}
          closeModal={() => setShowNodeHistoryModal(false)}
        />
      )}
    </Modal>
  );
};

export default memo(forwardRef(PropRecordModal));
