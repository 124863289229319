import { history } from '@umijs/max';

import { setCurrentUserId } from '@/hook';
import { parseOrgIdFromLocation } from '@/utils/parseOrgIdFromLocation';
import request, { cloudFnRequest } from '@/utils/request';

export async function query() {
  return request('/api/users');
}

type QueryCurrentParams = {
  /**
   * @description 指定获取空间的主题类型配置
   */
  template?: string[];
};
export function queryCurrent(params: QueryCurrentParams) {
  const data = { ...params };

  if (!data.template) {
    const orgId = parseOrgIdFromLocation(history.location);
    if (!orgId) {
      const pathRegex = new RegExp(/^\/?(\w+)\/(\w+)\/\w+/g);
      const result = pathRegex.exec(location.pathname);

      // 分享界面外部成员不获取主题配置
      if (result?.[1] === 'share') {
        data.template = [];
      }
    }

    data.template = [orgId];
  }

  return cloudFnRequest('getCurrentUser', {
    method: 'POST',
    data,
  }).then((res) => {
    if ('userid' in res && typeof res.userid === 'string') {
      setCurrentUserId((res as any).userid);
    }
    return res;
  });
}
export async function queryNotices() {
  return request('/api/notices');
}
export async function getChatList() {
  return request('/api/chat/list', {
    method: 'POST',
  });
}
export async function addSpace(params: unknown) {
  return request('/api/organization/create', {
    method: 'POST',
    data: params,
  });
}
export async function updateInfo(params: unknown) {
  return request('/api/updateUserInfo', {
    method: 'POST',
    data: params,
  });
}
export async function updateAvatar(params: unknown) {
  return request('/api/updateAvatar', {
    method: 'POST',
    data: params,
  });
}
export async function checkInvitationCode(params: unknown) {
  return request('/api/checkInvitationCode', {
    method: 'POST',
    data: params,
  });
}
export async function chatRead(params: unknown) {
  return request('/api/chatRead', {
    method: 'POST',
    data: params,
  });
}
