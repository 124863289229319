import { intersection } from 'ramda';

import { useOrgList } from './useOrg';

const ORG_ID_WHITELIST = ['4FE935A56A97D32ECA9BB76F31C24F6F'];
const LINKPI_ORG_ID = ['B6835ADFF64711EA9F1D7085C2D76860'];

/**
 * isOuterDev 外部开发
 * isDev 内部开发
 * isPlatformDev 开发（无论内部外部）
 */
export const useDev = () => {
  const orgList = useOrgList();
  const orgIds = orgList.map((o) => o.orgId);
  const isOuterDev = !!intersection(orgIds, ORG_ID_WHITELIST).length;
  const isDev = !!intersection(orgIds, LINKPI_ORG_ID).length;

  return { isOuterDev, isDev, isPlatformDev: isDev || isOuterDev };
};
