import { useDispatch } from '@umijs/max';
import { Dropdown, message, Modal, Popover } from 'antd';
import { produce } from 'immer';
import { cloneDeep } from 'lodash';
import { useState } from 'react';

import ChooseColor from '@/pages/space/components/SetTemplateExportPDF/ChooseColors';
import { showTemplatePropModal } from '@/pages/space/components/TemplateERDiagram/components/showTemplatePropModal';
import { changeAttrsOrder } from '@/utils/utils';

import styles from './styles.less';
interface IProps {
  setShowHeaderMoreActions: any;
  showHeaderMoreActions: any;
  propDisplayConfig: any;
  setPropDisplayConfig: any;
  saveTableConfig: any;
  template: any;
  selectSpace: string;
  onPropUpdate: () => void;
  attrContentAlign: any;
  setAttrContentAlign: (align: string) => void;
  attrsStyle: any;
  setAttrsStyle: any;
  fixedAttrPool: any;
  setFixedAttrPool: (v: any) => void;
}

export default (props: IProps) => {
  const {
    setShowHeaderMoreActions,
    showHeaderMoreActions,
    propDisplayConfig,
    setPropDisplayConfig,
    saveTableConfig,
    selectSpace,
    template,
    attrContentAlign,
    setAttrContentAlign,
    attrsStyle,
    setAttrsStyle,
    fixedAttrPool,
    setFixedAttrPool,
    onPropUpdate,
  } = props;
  if (!showHeaderMoreActions) return null;

  const attrStyleMenus = [
    {
      name: '加粗',
      icon: (color: string) => (
        <i
          style={{ fontWeight: 600, paddingLeft: 2, marginRight: 2 }}
          className="icona-rongqi5 iconfont"
        />
      ),
    },
    {
      name: '字色',
      icon: (color: string) => (
        <div style={{ height: 20, width: 20, position: 'relative' }}>
          <i
            style={{
              fontSize: 16,
              position: 'absolute',
              top: '-4px',
              left: '50%',
              transform: 'translateX(-50%)',
              fontWeight: 600,
            }}
            className="iconwenzi iconfont"
          />
          <div
            style={{
              backgroundColor: color,
              height: 6,
              width: 20,
              borderRadius: 20,
              position: 'absolute',
              bottom: '2px',
              border: '1px solid #EBEDF0',
            }}
          />
        </div>
      ),
    },
    {
      name: '底色',
      icon: (color: string) => (
        <div style={{ height: 20, width: 20, position: 'relative' }}>
          <div
            style={{
              height: 15,
              width: 15,
              backgroundColor: '#242D3F',
              transform: 'translateX(-50%)',
              position: 'absolute',
              left: '50%',
              border: '1px solid #EBEDF0',
            }}
          />
          <div
            style={{
              backgroundColor: color,
              height: 6,
              width: 20,
              borderRadius: 20,
              position: 'absolute',
              bottom: '2px',
              border: '1px solid #EBEDF0',
            }}
          />
        </div>
      ),
    },
  ];

  const dispatch = useDispatch();

  const { position, index, key } = showHeaderMoreActions;
  const isTemplateProp = key.slice(0, 4) === 'prop';
  const [open, setOpen] = useState<boolean>(true);

  const changeOpen = (v: boolean) => {
    if (!v) return setShowHeaderMoreActions(null);
    setOpen(v);
  };

  const isAttrFixed = () => fixedAttrPool.includes(key);

  const del = async (cb: any) => {
    const propIndex = Number(key.split('_')[1]);
    const newProps = template.prop.map((p: any, index: number) => {
      return propIndex === index ? {} : p;
    });

    const res: any = await dispatch({
      type: 'space/updateTemplate',
      payload: {
        org_id: selectSpace,
        template_id: template.template_id,
        prop: JSON.stringify(newProps),
        fetchUser: true,
      },
    });

    cb();
    if (res.status === 'ok') message.success('删除成功');
    else message.error('删除失败');
  };
  const delProp = () => {
    setShowHeaderMoreActions(null);
    Modal.confirm({
      title: '确定删除该属性吗？',
      content: '',
      onOk: (cb) => del(cb),
    });
  };

  const handleEditProp = () => {
    const propIndex = Number(key.split('_')[1]);
    showTemplatePropModal({
      type: 'edit',
      propIndex,
      isDebugMode: false,
      isLock: false,
      needAddOneBtn: false,
      templateId: template.template_id,
      allowSwitchProps: false,
      autoClose: true,
    }).then(onPropUpdate);
    setShowHeaderMoreActions(null);
  };

  const handleHideRow = () => {
    const newPropDisplayConfig = produce(propDisplayConfig, (draft) => {
      const p = draft.find((i) => {
        return i.key === key;
      });
      p.disable = true;
    });

    setPropDisplayConfig(newPropDisplayConfig);
    saveTableConfig(newPropDisplayConfig, 'tShowFieldsIndex');
    setShowHeaderMoreActions(null);
  };

  const changeAttrAlign = (align: string) => {
    const newAttrContentAlign = {
      ...attrContentAlign,
      [key]: align,
    };
    setAttrContentAlign(newAttrContentAlign);
    saveTableConfig(newAttrContentAlign, 'attrContentAlign');
    setShowHeaderMoreActions(null);
  };

  const renderChooseColor = (i: { name: string; icon: any }, t: string) => {
    const { name, icon } = i;
    if (name === '加粗') return null;

    let colors: any = [];
    if (name === '字色')
      colors = [
        'white',
        '#242D3F',
        '#CFCED3',
        '#AAABB2',
        '#767C88',
        '#9288C5',
        '#82AE9D',
        '#B66C61',
        '#6777C2',
        '#FF0000',
        '#FFFF00',
        '#0000FF',
      ];
    if (name === '底色')
      colors = [
        t === '表头' ? '#f0f0f0' : 'white',
        '#FFF2E6',
        '#FCFCE3',
        '#EDF7E3',
        '#E1F8F8',
        '#E6F2FE',
        '#EDEDFF',
        '#F6EEFF',
        '#FEEEEE',
        '#FF0000',
        '#FFFF00',
        '#0000FF',
      ];
    return (
      <ChooseColor
        colors={colors}
        onChange={(c) => handleChangeColor(c, i, t)}
        value={getColorValue(i, t)}
      />
    );
  };

  const getColorValue = (i: any, t: string) => {
    const type = t === '表头' ? 'header' : 'content';
    const k = i.name === '字色' ? 'color' : 'backgroundColor';
    if (!attrsStyle[key]) return k === 'color' ? '#242D3F' : t === '表头' ? '#f0f0f0' : 'white';

    return attrsStyle[key][type][k];
  };

  const handleChangeColor = (c: string, i: any, t: string) => {
    const type = t === '表头' ? 'header' : 'content';
    const k = i.name === '字色' ? 'color' : 'backgroundColor';

    const payload = cloneDeep(attrsStyle);

    // 之前没有配置过
    if (!payload[key]) {
      payload[key] = {
        header: { bold: false, color: '#242D3F', backgroundColor: '#f0f0f0' },
        content: { color: '#242D3F', backgroundColor: 'white', bold: false },
      };
      payload[key][type][k] = c;
    }

    // 存在配置 直接改即可
    if (payload[key]) {
      payload[key][type][k] = c;
    }
    setAttrsStyle(payload);
    saveTableConfig(payload, 'attrsStyle');
  };

  const handleClickStyleItem = (i: { name: string }, t: string) => {
    if (i.name !== '加粗') return;
    const type = t === '表头' ? 'header' : 'content';
    const payload = { ...attrsStyle };

    // 之前没有配置过
    if (!payload[key])
      payload[key] = {
        header: { bold: type === 'header', color: '#242D3F', backgroundColor: '#f0f0f0' },
        content: { color: '#242D3F', backgroundColor: 'white', bold: type === 'content' },
      };

    // 存在配置 直接改即可
    if (payload[key]) {
      payload[key][type].bold = !payload[key][type].bold;
    }

    setAttrsStyle(payload);
    saveTableConfig(payload, 'attrsStyle');
  };

  // 得到属性的样式
  const getAttrStyle = (k: string, type: string) => {
    if (!attrsStyle[key]) return '';

    return attrsStyle[key][type][k];
  };

  const renderSelected = (i: any, t: string) => {
    if (i.name !== '加粗') return null;
    const res = getAttrStyle('bold', t);
    if (res)
      return <i style={{ color: '#316EF5', marginRight: 4 }} className="iconfont iconselected" />;
  };

  const renderStyleMenus = () => {
    return ['表头', '内容'].map((t) => (
      <div key={t}>
        <div style={{ padding: '6px 12px 2px 12px', color: '#C9D0D9' }}>{t}</div>
        {attrStyleMenus.map((i) => (
          <Popover
            placement="right"
            content={renderChooseColor(i, t)}
            trigger="hover"
            title={null}
            overlayClassName={styles.chooseColorPop}
            key={i.name}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '4px 10px',
              }}
              key={i.name}
              className={`${styles.styleItem}`}
              onClick={() => handleClickStyleItem(i, t)}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {i.icon(getColorValue(i, t))}
                <div style={{ paddingLeft: 8, color: '#242D3F' }}>{i.name}</div>
              </div>
              <div>
                {renderSelected(i, t === '表头' ? 'header' : 'content')}
                <i
                  style={{ color: '#6B7A96' }}
                  className={`iconfont iconyangshi ${styles.useAll}`}
                />
              </div>
            </div>
          </Popover>
        ))}
      </div>
    ));
  };

  const changeAttrFixed = () => {
    const newFixedAttrPool = isAttrFixed()
      ? fixedAttrPool.filter((f: any) => f !== key)
      : [...fixedAttrPool, key];

    // 调整显示列顺序
    const newTShowFieldsIndex = changeAttrsOrder(newFixedAttrPool, propDisplayConfig);

    setFixedAttrPool(newFixedAttrPool);
    saveTableConfig(newFixedAttrPool, 'fixedAttrPool', { tShowFieldsIndex: newTShowFieldsIndex });
    setShowHeaderMoreActions(null);
  };

  const renderOverlay = () => {
    const attrAligns = [
      { key: 'left', icon: 'iconzuoduiqi iconfont' },
      { key: 'center', icon: 'iconjuzhongduiqi iconfont' },
      { key: 'right', icon: 'iconyouduiqi iconfont' },
    ];
    return (
      <div className={styles.overlayContainer}>
        {/* 主题属性才具有这个能力 */}
        <div
          style={{ display: isTemplateProp ? 'flex' : 'none' }}
          className={styles.action}
          onClick={handleEditProp}
        >
          <i style={{ color: '#C9D0D9' }} className="iconfont iconzhongmingming" />
          <span style={{ color: '#242D3F', paddingLeft: 12 }}>编辑属性</span>
        </div>
        <div className={styles.action} onClick={handleHideRow}>
          <i style={{ color: '#C9D0D9' }} className="iconfont icona-Hide3x" />
          <span style={{ color: '#242D3F', paddingLeft: 12 }}>隐藏属性</span>
        </div>
        <div className={styles.action} onClick={changeAttrFixed}>
          <i
            style={{ color: '#C9D0D9' }}
            className={`iconfont ${isAttrFixed() ? 'iconquxiaoguding' : 'iconguding'}`}
          />
          <span style={{ color: '#242D3F', paddingLeft: 12 }}>
            {isAttrFixed() ? '取消固定' : '固定'}
          </span>
        </div>
        <div children={<div />} className={styles.divider} />
        <div className={styles.alignsContainer}>
          {attrAligns.map((a) => {
            const align = attrContentAlign[key] || 'left';
            return (
              <i
                key={a.key}
                className={`${a.icon} ${align === a.key ? styles.active : ''}`}
                style={{
                  color: '#242D3F',
                  cursor: 'pointer',
                  fontSize: 16,
                  padding: '0px 4px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: 4,
                }}
                onClick={() => changeAttrAlign(a.key)}
              />
            );
          })}
        </div>
        <div children={<div />} className={styles.divider} />
        {renderStyleMenus()}
      </div>
    );
  };

  return (
    <Dropdown
      trigger={['click']}
      overlay={renderOverlay()}
      placement="bottom"
      visible={open}
      onVisibleChange={changeOpen}
    >
      <div
        style={{
          position: 'absolute',
          height: position.height,
          width: position.width,
          left: position.x,
          top: position.y,
        }}
      />
    </Dropdown>
  );
};
