import { message } from 'antd';
import { isNil } from 'ramda';
import SparkMD5 from 'spark-md5';
import { v4 as uuid4 } from 'uuid';

import { checkUpload } from '@/services/spaceV2';
import request from '@/utils/request';

const generateId = () => uuid4().replace(/-/g, '');
/**
 *
 * @param id // node_id
 * @param buffer // buffer
 * @param fileExt // 文件后缀
 * @param currentSelection
 * @returns
 */
export const onUpload = async (id: string, buffer: any, fileExt: string, currentSelection: any) => {
  const strLength: number = buffer.length;
  // @ts-ignore
  const fileLength = parseInt(strLength - (strLength / 8) * 2);
  // 由字节转换为KB
  const size = (fileLength / 1024).toFixed(2);
  const path = generateId() + '.' + fileExt;
  const fileMd5 = SparkMD5.ArrayBuffer.hash(buffer);
  //验证是否可上传
  const res = await checkUpload({
    org_id: currentSelection.selectSpace,
    node_id: id,
    file_length: size,
    md5: fileMd5,
  });
  if (res.status === 'error') {
    message.error('上传失败');
    return;
  }

  const Oss = await import('ali-oss').then((r) => r.default);

  let fileUrl = '';
  if (!res.data) {
    const client = Oss({
      secure: true,
      bucket: res.bucket,
      region: res.region,
      accessKeyId: res.token.AccessKeyId,
      accessKeySecret: res.token.AccessKeySecret,
      stsToken: res.token.SecurityToken,
      authorizationV4: true,
    });
    const oss_res = await client.put('upload/' + path, buffer);
    if (!isNil(oss_res)) {
      const store_res = await request('/api/file/store', {
        method: 'POST',
        data: {
          org_id: currentSelection.selectSpace,
          path,
          node_id: id,
        },
      });
      if (store_res.status === 'error') {
        message.error('上传失败');
        return;
      }
      fileUrl = store_res.data;
    }
  } else {
    fileUrl = res.data;
  }
  return fileUrl;
};

// 根据文件名获取文件后缀
export const getFileExt = (fileName: string) => {
  const ary = fileName.split('.');
  const lastIndex = ary.length - 1;
  return ary[lastIndex];
};
