import type { ButtonProps } from 'antd';
import { Button } from 'antd';
import cls from 'classnames';
import { omit } from 'ramda';
import type { FC } from 'react';

import styles from './styles.less';

export type PiButtonProps = Omit<ButtonProps, 'type' | 'size'> & {
  type?: 'secondary' | ButtonProps['type'];
  size?: 'tiny' | ButtonProps['size'];
};

export const PiButton: FC<React.PropsWithChildren<PiButtonProps>> = (props) => {
  const btnProps: ButtonProps = omit(['type', 'size', 'entrance'], props);
  const btnType = props.type === 'secondary' ? undefined : props.type;
  const btnSize = props.size === 'tiny' ? undefined : props.size;
  if (btnType) {
    btnProps.type = btnType;
  }
  if (btnSize) {
    btnProps.size = btnSize;
  }

  return (
    <Button
      {...btnProps}
      className={cls(
        props.type === 'secondary' && styles.secondary,
        props.size === 'tiny' && styles.tiny,
        props.className,
      )}
    />
  );
};
