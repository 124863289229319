import { DEFAULT_AVATAR } from '@linkpi/core';

import type { PositionType } from '../components/_baseComponent';
import GridBaseComponent from '../components/_baseComponent';
import type { PiGridModules } from '../core';

class FixUser extends GridBaseComponent {
  public data: any;
  public node: PiNode;
  public type: string = 'fixUser';
  public captrue: boolean = false;
  constructor(position: PositionType, moduleInstances: PiGridModules, data: any, node: PiNode) {
    super(position, moduleInstances);

    this.data = data;
    this.node = node;
  }

  public async click() {
    if (!this.data.config) return;
    const { theme, matchings, nodePath, linkType } = this.data.config;
    const {
      ChildNodesLoader,
      DataManager: { templateMap },
    } = this.moduleInstances;

    // openNowNode
    if (linkType === 'openNowNode') {
      this.moduleInstances.DataManager.config.onPreviewNodeForFix(this.node.id);
      return;
    }
    if (theme) {
      // 动态链接
      const groupBy = matchings.map((matching: any) => `p${matching.target}`);
      const filterNode = await ChildNodesLoader.getFilterNode(this.node, this.data.config);
      const nodes: PiNode[] = filterNode.getMatchNode(this.node.id, groupBy);
      if (nodes.length) ChildNodesLoader.config.showLinkNodes(nodes, templateMap[theme]);
    } else {
      // 静态链接
      this.moduleInstances.ChildNodesLoader.config.onPreviewNodeForFix(nodePath);
    }
  }

  public drawImg(src: string, x: number, y: number) {
    const { Draw, ImageLoader } = this.moduleInstances;

    const img = ImageLoader.loadImage(src || DEFAULT_AVATAR, 'avatar');
    Draw.drawRoundImg(30, x, y, 30, 30, img);
  }

  public drawText(text: string, color: string, x: number, y: number, fontSize?: number) {
    const { Draw } = this.moduleInstances;

    Draw.attr({
      fillStyle: color,
      textBaseline: 'top',
      textAlign: 'start',
      font: `${Draw.npx(fontSize || 14)}px  sans-serif`,
    });

    Draw.fillText(text, x, y);
    return Draw.measureTextWidth(text);
  }

  public render() {
    const { Render, Draw } = this.moduleInstances;
    if (this.isHover) {
      Render.setCursor('pointer');
    }

    const { users, color, fontSize } = this.data;
    const { x, y } = this.position;
    // only 1
    if (users.length && users.length < 2) {
      this.drawText(users[0].nick_name, color, x + 35, y + 15 - fontSize / 2, fontSize);
      this.drawImg(users[0].avatar, x, y);
      return;
    }

    // many
    let g = 0;
    for (let i = 0; i < users.length; i++) {
      this.drawImg(users[i].avatar, x + g, y);
      this.drawText(users[i].nick_name, color, x + g + 35, y + 15 - fontSize / 2, fontSize);
      g = g + 40 + Draw.measureTextWidth(users[i].nick_name);
    }
  }
}

export default FixUser;
