import type { ApiResponse } from '@linkpi/core';
import {
  checkGroup,
  propIsNull,
  updateProp,
  vaildExt,
  vaildMaxCount,
  vaildMaxSize,
} from '@linkpi/core';
import type { UploadProps } from 'antd';
import { Form, Input, message, Modal, Popover, Spin, Upload } from 'antd';
import { defaultTo } from 'ramda';
import type { FC } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { previewImages, previewWebOffice } from '@/components';
import { getFileExt } from '@/components/Chat2/utils';
import IconPark from '@/components/IconPark';
import { WEB_OFFICE_TYPES } from '@/components/WebOfficeModal/constants';
import { useCurrentUserInfo } from '@/hook';
import request from '@/utils/request';
import type {
  FileIconTypes,
  UploadFileInOrgNodeResFailType,
  UploadFileInOrgNodeResSuccessType,
} from '@/utils/utils';
import { downLoadFile, getFileTypeIconByUrl, uploadFileInOrgNode } from '@/utils/utils';

import type { PropRecordModalRefType } from './PropRecordModal';
import PropRecordModal from './PropRecordModal';

type AttachmentValue = {
  name: string;
  src: string;
  type: FileIconTypes;
  preview: string;
};
type FileName = string;
interface PropType {
  config: ApiResponse.CurrentUser.TemplateProp;
  value: FileName[];
  fileSrcArr: string[];
  orgId: string;
  nodeId: string;
  propIndex: number;
  tempId: string;
  canEdit: boolean;
  showRecordIcon?: boolean;
  userMap: Record<string, ApiResponse.OrgUser.OrgUserItem>;
  customStyle: string;
  showSourceIcon?: any;
}

const formatToAttachmentList = (
  fileValue: PropType['value'],
  fileSrcArr: PropType['fileSrcArr'],
): AttachmentValue[] => {
  if (propIsNull(fileValue)) {
    return [];
  }

  if (fileValue instanceof Array) {
    return fileValue
      .filter((x, i) => !!x && !!fileSrcArr[i])
      .map((x, i) => {
        const fileSrc = fileSrcArr[i];
        const fileType = getFileTypeIconByUrl(fileSrc);
        return {
          name: x,
          src: fileSrc,
          type: fileType.type,
          preview: fileType.src,
        };
      });
  } else {
    return [];
  }
};

const AttachmentCell: FC<PropType> = (prop) => {
  const {
    config,
    value,
    fileSrcArr,
    orgId,
    nodeId,
    propIndex,
    tempId,
    canEdit,
    userMap,
    showRecordIcon = false,
    showSourceIcon = null,
    customStyle,
  } = prop;
  const userInfo = useCurrentUserInfo();

  const canReadonly = useMemo(() => {
    let result = true;
    if (result) {
      // 判断下载分组
      if (!checkGroup(config.attachmentConfig?.allowDownloadGroups || ['-1'], userInfo)) {
        result = false;
      }
    }

    return result;
  }, [userInfo, config.attachmentConfig?.allowDownloadGroups]);

  const attachmentList: AttachmentValue[] = useMemo(() => {
    if (value.length && typeof value[0] === 'object') {
      return [];
    } else {
      return formatToAttachmentList(value, fileSrcArr);
    }
  }, [value, fileSrcArr]);

  const [uploading, setUploading] = useState<boolean>(false);
  const uploadFileList = useRef<File[]>([]);
  const uploadingTimer = useRef<ReturnType<typeof setTimeout>>(0 as any);

  const scrollRef = useRef<HTMLElement>();
  const propRecordModalRef = useRef<PropRecordModalRefType>(null);

  useEffect(() => {
    return () => {
      clearTimeout(uploadingTimer.current);
    };
  }, []);

  const beforeUpload: UploadProps['beforeUpload'] = (file, fileList) => {
    const vaildMaxSizeResult = vaildMaxSize(file, config);
    if (vaildMaxSizeResult.status === 'error') {
      message.error(`文件大小不能超过${vaildMaxSizeResult.maxSize}MB! 请重新选择：${file.name}`);
      return false;
    }

    const fileIndex = fileList.findIndex((f) => f.uid === file.uid) + 1;
    const validCountResult = vaildMaxCount((value?.length || 0) + fileIndex, config);
    if (validCountResult.status === 'error') {
      message.error(`文件数量不能多于${validCountResult.maxCount}个! `);
      return false;
    }

    const vaildExtResult = vaildExt(file, config);
    if (vaildExtResult.status === 'error') {
      message.error(`该文件类型不支持上传：${vaildExtResult.ext}! `);
      return false;
    }

    return true;
  };
  const onUploadChange = (options: any) => {
    setUploading(true);
    clearTimeout(uploadingTimer.current);

    uploadFileList.current.push(options.file);
    uploadingTimer.current = setTimeout(() => {
      handleUploadFile();
    }, 200);
  };

  const handleUploadFile = async () => {
    clearTimeout(uploadingTimer.current);

    let commit: any = false;
    // 是否需要备注
    if (config.recordModification) {
      commit = { [propIndex]: '' };
      if (config.requireModificationRemark) {
        const [err, newCommit] = await recordCommit();
        if (err) return;
        commit = { [propIndex]: newCommit };
      }
    }

    const uploadReq = uploadFileList.current.map((x) => {
      return uploadFileInOrgNode(x, orgId, nodeId);
    });

    const uploadRes = await Promise.all(uploadReq);
    uploadFileList.current = [];

    const failList: UploadFileInOrgNodeResFailType[] = [];
    const successList: UploadFileInOrgNodeResSuccessType[] = [];
    uploadRes.map((x) => {
      if (x.status === 'success') {
        successList.push(x);
      } else {
        failList.push(x);
      }
    });
    if (failList.length) {
      message.error(`${failList.length}个文件上传失败`);
    } else {
      message.success(`${successList.length}个文件上传成功`);
    }

    // 设置属性
    if (successList.length) {
      const newValue = successList.map((x) => x.fileName).concat(value);
      const newAttachment = successList.map((x) => x.fileUrl).concat(fileSrcArr);
      const req: any = {
        org_id: orgId,
        temp_id: tempId,
        node_id: nodeId,
        index: [propIndex],
        value: [newValue],
        attachment: {
          [propIndex]: newAttachment,
        },
      };

      if (commit) {
        req.commit = commit;
      }

      await updateProp(request, req);

      if (scrollRef.current) {
        scrollRef.current.scrollLeft = 0;
      }
    }
    setUploading(false);
  };

  const [commitForm] = Form.useForm();
  const recordCommit = (): Promise<[boolean, any]> => {
    return new Promise((r) => {
      const commitModal = Modal.confirm({
        title: '提交修改',
        content: (
          <Form form={commitForm} layout="vertical" autoComplete="off">
            <Form.Item
              rules={[{ required: true, message: '请输入备注' }]}
              name="commit"
              label="请填写备注，否则修改的属性值将无法保存"
            >
              <Input />
            </Form.Item>
          </Form>
        ),
        okText: '提交',
        onOk: (e) => {
          commitForm.validateFields().then(() => {
            commitModal.destroy();
            r([false, commitForm.getFieldValue('commit')]);
            commitForm.resetFields();
          });
        },
        onCancel: () => {
          commitModal.destroy();
          r([true, null]);
        },
      });
    });
  };

  const onOfficeView = (x: AttachmentValue) => {
    previewWebOffice({
      url: x.src,
      fileName: x.name,
      orgId,
    });
  };

  const onPreview = (index: number) => {
    previewImages({
      images: attachmentList.map((x) => ({ src: x.preview })),
      activeIndex: index,
    });
  };

  const onDownload = (index: number) => {
    if (!canReadonly) {
      message.warn('无权下载该文件');
      return;
    }
    const item = attachmentList[index];
    downLoadFile(item.src, item.name);
  };

  const onDelete = async (index: number) => {
    if (!canEdit) {
      message.warn('无权删除该文件');
      return;
    }
    const newValue = [...value];
    newValue.splice(index, 1);
    const newAttachment = [...fileSrcArr];
    newAttachment.splice(index, 1);
    const req: any = {
      org_id: orgId,
      temp_id: tempId,
      node_id: nodeId,
      index: [propIndex],
      value: [newValue],
      attachment: {
        [propIndex]: newAttachment,
      },
    };

    // 是否需要备注
    if (config.recordModification) {
      req.commit = { [propIndex]: '' };
      if (config.requireModificationRemark) {
        const [err, commit] = await recordCommit();
        if (err) return;
        req.commit = { [propIndex]: commit };
      }
    }

    updateProp(request, req);
  };

  const recordIconNode =
    showRecordIcon && config.recordModification && !['style_2', 'style_3'].includes(customStyle) ? (
      <i
        className="iconfont iconhistorical_state"
        style={{ color: '#316EF5', fontSize: 12, padding: '0 5px' }}
        onClick={(e) => {
          e.stopPropagation();
          propRecordModalRef.current?.show({
            orgId: orgId,
            nodeId: nodeId,
            propConfig: config,
            propIndex: propIndex,
          });
        }}
      />
    ) : null;
  const getAttachmentTipStyle = () => {
    const res: any = { color: '#B9BBBC', fontSize: 14 };
    if (customStyle === 'style_3') {
      res.color = '#BFC6D2';
      res.paddingLeft = 6;
    }

    return res;
  };

  if (!attachmentList.length) {
    if (!canEdit) {
      return <div className="prop-item-value-label attachment-cell">-</div>;
    } else {
      return (
        <Spin className="attachment-spin" spinning={uploading} indicator={<></>} tip="正在上传...">
          <Upload
            showUploadList={false}
            customRequest={onUploadChange}
            beforeUpload={beforeUpload}
            multiple={true}
          >
            <div className="prop-item-value-label attachment-cell upload">
              <span style={getAttachmentTipStyle()}>请点击添加附件</span>
            </div>
          </Upload>
          {recordIconNode}
        </Spin>
      );
    }
  }

  return (
    <Spin
      wrapperClassName="attachment-spin-wrapper"
      className="attachment-spin"
      spinning={uploading}
      indicator={<></>}
      tip="正在上传..."
    >
      <div className="prop-item-value-label attachment-cell">
        {canEdit ? (
          <div className="attachment-cell-handle">
            <Upload
              showUploadList={false}
              customRequest={onUploadChange}
              beforeUpload={beforeUpload}
              multiple={true}
            >
              <div className="attachment-cell-handle-item">
                <IconPark type="tianjia" style={{ fontSize: 14, color: '#6a717c' }} />
              </div>
            </Upload>
          </div>
        ) : null}

        <PerfectScrollbar
          containerRef={(e) => (scrollRef.current = e)}
          options={{ suppressScrollX: false, suppressScrollY: true }}
          style={{ flex: 1, width: 0 }}
        >
          <div className="attachment-cell-files">
            {attachmentList.map((x, i) => (
              <AttachmentItem
                value={x}
                key={i}
                onOfficeView={() => onOfficeView(x)}
                onPreview={() => onPreview(i)}
                onDownload={() => onDownload(i)}
                onDelete={() => onDelete(i)}
                canReadonly={canReadonly}
                canEdit={canEdit}
              />
            ))}
            {recordIconNode}
            {showSourceIcon}
          </div>
        </PerfectScrollbar>
      </div>
      <PropRecordModal orgId={orgId} nodeId={nodeId} ref={propRecordModalRef} userMap={userMap} />
    </Spin>
  );
};

type AttachmentItemPropsType = {
  value: AttachmentValue;
  onPreview: () => void;
  onDelete: () => void;
  onDownload: () => void;
  onOfficeView: () => void;
  canEdit: boolean;
  canReadonly?: boolean;
};

const AttachmentItem: FC<AttachmentItemPropsType> = (props) => {
  const { value, onPreview, onDelete, onDownload, onOfficeView, canEdit } = props;
  const canReadonly = defaultTo(true)(props.canReadonly);
  const [visiblePopover, setVisiblePopover] = useState<boolean>(false);

  const handlePreview = () => {
    setVisiblePopover(false);
    onPreview();
  };

  const handleOfficeView = () => {
    setVisiblePopover(false);
    onOfficeView();
  };

  const handleDownload = () => {
    setVisiblePopover(false);
    onDownload();
  };

  const handleDelete = () => {
    setVisiblePopover(false);
    onDelete();
  };

  let IconPreview;
  let PopoverPreview;
  let FullPreviewButton;
  if (value.type === 'image') {
    const getSrc = () => {
      if (getFileExt(value.name).toLowerCase() === 'heic') {
        return value.src + '&x-oss-process=image/format,png';
      }
      return value.src;
    };
    IconPreview = PopoverPreview = (
      <div
        style={{
          backgroundImage: `url(${getSrc()})`,
          width: '100%',
          height: '100%',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
    );
    FullPreviewButton = (
      <div className="AttachmentItem-popover-handle-item preview-button" onClick={handlePreview}>
        <IconPark type="chakandatu1" style={{ fontSize: 14, color: '#fff' }} />
      </div>
    );
  } else {
    if (WEB_OFFICE_TYPES.includes(value.type))
      FullPreviewButton = (
        <div
          className="AttachmentItem-popover-handle-item preview-button"
          onClick={handleOfficeView}
        >
          <i className={'iconfont iconsearch'} style={{ fontSize: 14, color: '#fff' }} />
        </div>
      );
    IconPreview = <img src={value.preview} style={{ width: 20 }} />;
    PopoverPreview = <img src={value.preview} style={{ width: 47 }} />;
  }

  const displayName = typeof value.name === 'string' ? value.name : '';

  return (
    <Popover
      open={visiblePopover}
      onOpenChange={setVisiblePopover}
      overlayClassName="AttachmentItem-popover-overlay"
      content={
        <div className="AttachmentItem-popover">
          <div className="AttachmentItem-popover-preview">{PopoverPreview}</div>
          <div className="AttachmentItem-popover-handle">
            {FullPreviewButton}
            {canReadonly && (
              <div className="AttachmentItem-popover-handle-item" onClick={handleDownload}>
                <IconPark type="xiazai1" style={{ fontSize: 14, color: '#fff' }} />
              </div>
            )}
            {canEdit ? (
              <div className="AttachmentItem-popover-handle-item" onClick={handleDelete}>
                <IconPark type="shanchu1" style={{ fontSize: 14, color: '#fff' }} />
              </div>
            ) : null}
          </div>
          <div className="AttachmentItem-popover-info">{displayName}</div>
        </div>
      }
    >
      <div className="attachment-item">{IconPreview}</div>
    </Popover>
  );
};

export default AttachmentCell;
