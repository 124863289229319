import { TreeSelect } from 'antd';
import cls from 'classnames';
import type { FC } from 'react';

import styles from '../styles.less';

const NodeSelect: FC<{
  disabled?: boolean;
  treeData: any[];
  value: string[];
  rootId: string;
  extraCurrent?: string;
  onChange: (newValue: string[]) => void;
}> = (props) => {
  const { disabled, treeData, value, onChange, rootId, extraCurrent } = props;

  const getBtn = (index: number) => {
    if (disabled) return null;

    if (index === 0) {
      // 新增
      return (
        <div
          className={cls('condition-filter-value-item-btn', styles.btn)}
          style={{ transform: 'rotate(45deg)' }}
          onClick={() => {
            if (~value.indexOf('')) return;
            onChange([...value, '']);
          }}
        >
          <i className="iconfont iconButton_cancel" />
        </div>
      );
    } else {
      // 删除
      return (
        <div
          className={styles.btn}
          onClick={() => {
            const newValue = [...value];
            newValue.splice(index, 1);
            onChange(newValue);
          }}
        >
          <i className="iconfont iconButton_cancel" />
        </div>
      );
    }
  };

  return (
    <div className={styles.nodeSelect}>
      {value.map((x: string, index: number) => (
        <div className={styles.nodeSelectRow} key={index}>
          <TreeSelect
            disabled={disabled}
            popupClassName="scope-treeSelect"
            dropdownRender={
              extraCurrent
                ? (originNode) => {
                    return (
                      <>
                        {originNode}
                        <div className="scope-treeSelect-extra">
                          <div
                            className="scope-extra-btn"
                            onClick={(_) => {
                              if (extraCurrent) {
                                const newValue = [...value];
                                newValue[index] = extraCurrent;
                                onChange(newValue);
                              }
                            }}
                          >
                            当前主题
                          </div>
                        </div>
                      </>
                    );
                  }
                : undefined
            }
            dropdownMatchSelectWidth={400}
            treeData={treeData}
            style={{ flex: 1, maxWidth: 'calc(100% - 24px)' }}
            value={x}
            onChange={(e: string) => {
              const newValue = [...value];
              newValue[index] = e;
              onChange(newValue);
            }}
            treeDefaultExpandedKeys={[rootId]}
            showSearch={true}
            filterTreeNode={(e, treeNode) => {
              return !!~(treeNode?.title as string)?.toLowerCase().indexOf(e.toLowerCase());
            }}
          />
          {getBtn(index)}
        </div>
      ))}
    </div>
  );
};

export default NodeSelect;
