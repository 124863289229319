import { EditOutlined } from '@ant-design/icons';
import { create, show, useModal } from '@ebay/nice-modal-react';
import { useFullscreen, useMemoizedFn, useSize } from 'ahooks';
import { Button, message, Space, Spin, Tooltip } from 'antd';
import dayjs from 'dayjs';
import type { FC, ReactNode } from 'react';
import { Suspense } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';

import type { LinkPiModalProps } from '@/components/LinkPiModal';
import LinkPiModal from '@/components/LinkPiModal';
import { useOrgInfo } from '@/hook';
import request from '@/utils/request';
import { lazyImport } from '@/utils/utils';

import { RegularIcon } from '../IconFont';
import { FILE_TYPE_MAP } from './constants';

import styles from './index.less';

const { PDFViewer } = lazyImport(() => import('./PDFViewer'), 'PDFViewer');

interface IAliTokenType {
  AccessToken: string;
  RefreshToken: string;
  AccessTokenExpiredTime: number;
  WebofficeURL: string;
}
let lastTokenInfo: Partial<IAliTokenType> = {
  AccessToken: '',
  RefreshToken: '',
};

type WebOfficeModalProp = {
  url: string;
  fileName?: string;
  orgId?: string;
  visible: boolean;
} & Pick<LinkPiModalProps, 'onCancel' | 'zIndex'>;

export const WebOfficeModal: FC<WebOfficeModalProp> = (props) => {
  const { url, visible, onCancel, fileName = '', orgId = '', ...rest } = props;

  const aliWebOfficeInstance = useRef<any>(null);
  const immFilePath = url.split('.com/')[1].split('?')[0];

  const [orgInfo] = useOrgInfo(orgId);

  const fileType = useMemo(() => {
    const pathArr = url?.split('?')?.[0]?.split('/');
    const [, _fileType] = pathArr?.[pathArr.length - 1]?.split('.') || [];
    return FILE_TYPE_MAP[_fileType];
  }, [url]);

  const previewSrc = useMemo(() => {
    const origin = 'https://view.officeapps.live.com/op/embed.aspx';
    // const previewSrc = `https://www.xdocin.com/xdoc?_func=to&_format=html&_cache=1&_xdoc=${encodeURIComponent(
    //   url,
    // )}`;
    const tSymbol = ~url.indexOf('?') ? '&' : '?';
    return `${origin}?src=${encodeURIComponent(url + tSymbol + `t=${Date.now()}`)}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileType]);

  // vip空间编辑
  // 预览都用aliyun
  // 支持编辑doc excel ppt
  const isVIP = orgInfo && orgInfo.org_option && orgInfo.org_option.allowEditAttachment;

  const enableEdit = useMemo(() => {
    if (!isVIP) {
      return false;
    }

    // 只支持 word 和 excel 编辑
    if (fileType === 'f') {
      return false;
    }

    return true;
  }, [isVIP, fileType]);

  const contentRef = useRef(null);
  const [isFullscreen, { enterFullscreen }] = useFullscreen(contentRef);

  const contentSize = useSize(contentRef) || { width: 0, height: 0 };

  const refreshToken = async (readonly = false) => {
    const param: any = {
      org_id: orgId,
      filepath: immFilePath,
      woToken: lastTokenInfo.AccessToken,
      reToken: lastTokenInfo.RefreshToken,
    };
    if (readonly) {
      param.readonly = readonly;
    }
    const res = await request<IAliTokenType>('/api/weboffice/refreshToken', {
      method: 'POST',
      data: param,
    });

    if (!('data' in res)) {
      throw Error('刷新token失败');
    }

    lastTokenInfo = res.data;
    return {
      token: res.data.AccessToken,
      timeout: 25 * 60 * 1000,
    };
  };

  const renderAliWeboffice = async (readonly = false) => {
    if (aliWebOfficeInstance.current) {
      aliWebOfficeInstance.current.destroy();
    }
    // loading
    // get token
    const param: any = {
      org_id: orgId,
      filepath: immFilePath,
    };
    if (readonly) {
      param.readonly = true;
    }
    const res = await request<IAliTokenType>('/api/weboffice/genToken', {
      method: 'POST',
      data: param,
    });

    if (res.status !== 'ok') {
      if (isVIP) {
        message.error(res.err || res.message || '无法编辑');
      }
      return 'error';
    }

    lastTokenInfo = {
      AccessToken: res.data.AccessToken,
      RefreshToken: res.data.RefreshToken,
    };

    // 如果AccessToken过期 通过refreshToken拿最新的
    const nowTime = dayjs().valueOf();
    const expiredTime = dayjs(res.data.AccessTokenExpiredTime).valueOf();
    if (nowTime > expiredTime) {
      await refreshToken(readonly);
    }

    // load document
    const mount = document.querySelector('#WebOfficeContent') as HTMLElement;
    mount.innerHTML = '';
    aliWebOfficeInstance.current = aliyun.config({
      mount,
      url: res.data.WebofficeURL,
      mode: 'normal',
      refreshToken,
    });
    aliWebOfficeInstance.current.setToken({
      token: lastTokenInfo.AccessToken,
      timeout: 25 * 60 * 1000,
    });
    aliWebOfficeInstance.current.on('fileOpen', function (data: any) {
      console.log('fileOpen', data);
    });
    aliWebOfficeInstance.current.on('error', (err: any) => {
      console.log('发生错误：', err);
    });

    console.log(aliWebOfficeInstance);
  };

  const WebOfficeContentStyles = useMemo(() => {
    const minHeight = 720;
    let height = '';
    if (isFullscreen) {
      height = fileType !== 'f' ? '100vh' : 'calc(100vh - 36px)';
    } else {
      height = fileType !== 'f' ? '80vh' : 'calc(80vh - 36px)';
    }

    return {
      height,
      minHeight,
    };
  }, [isFullscreen, fileType]);

  const [previewContent, _setPreviewContent] = useState<ReactNode | null>(null);

  const setPreviewContent = useMemoizedFn(() => {
    let newPreviewContent: null | ReactNode = null;
    if (fileType === 'f') {
      newPreviewContent = (
        <Suspense fallback={<Spin />}>
          <PDFViewer url={url} width={contentSize.width} />;
        </Suspense>
      );
    } else {
      newPreviewContent = <iframe src={previewSrc} width={'100%'} height={'100%'} />;
    }
    _setPreviewContent(newPreviewContent);
  });

  useEffect(() => {
    // 其他预览
    if (!isVIP) {
      setPreviewContent();
    }
  }, [isVIP, setPreviewContent]);

  useEffect(() => {
    // vip 用阿里云预览
    renderAliWeboffice(true).then((res) => {
      if (res === 'error') {
        setPreviewContent();
      }
    });
  }, []);

  return (
    <LinkPiModal
      centered
      key={url}
      title={
        <Space>
          {fileName || '文档预览'}
          {enableEdit ? (
            <Tooltip title="编辑">
              <Button onClick={() => renderAliWeboffice(false)} type="text" shape="circle">
                <EditOutlined style={{ color: '#316ef5' }} />
              </Button>
            </Tooltip>
          ) : null}

          <Tooltip title="全屏">
            <Button onClick={enterFullscreen} type="text" shape="circle">
              <RegularIcon type="iconchangyong-quanping" color="#316ef5" />
            </Button>
          </Tooltip>
        </Space>
      }
      noFooter={true}
      width={'100%'}
      open={visible}
      onCancel={onCancel}
      className={styles['web-office-modal']}
      {...rest}
      zIndex={1001}
    >
      <div ref={contentRef} style={{ background: 'white' }}>
        <div id={'WebOfficeContent'} style={WebOfficeContentStyles}>
          {previewContent}
        </div>
      </div>
    </LinkPiModal>
  );
};

type WebOfficeNiceModalProps = Omit<WebOfficeModalProp, 'visible' | 'onCancel'>;
const _WebOfficeNiceModal: FC<WebOfficeNiceModalProps> = (props) => {
  const modal = useModal();

  const onCancel = useMemoizedFn(() => {
    modal.hide();
    modal.resolve();
  });

  return <WebOfficeModal {...props} key={props.url} visible={modal.visible} onCancel={onCancel} />;
};

const WebOfficeNiceModal = create(_WebOfficeNiceModal);

export const previewWebOffice = (o: WebOfficeNiceModalProps) => show(WebOfficeNiceModal, o);
