import type { ApiResponse } from '@linkpi/core';

import type { configType, statConfigType, statDataType } from './core';
import Core from './core';
import type { GridAttrItem } from './react/useGridAttrs';

export default class PiGrid {
  public editor: Core | null;
  public destroy: () => void;
  public onGroupDataChange: (
    groupData: ViewGroupDataType,
    viewTemplate: ApiResponse.CurrentUser.TemplateInfo,
    viewInfo: ApiResponse.ViewList.ViewInfo,
  ) => void;
  public onTableAttrsChange: (tableAttrs: GridAttrItem[]) => void;
  public onNodeHeightChange: (nodeHeight: number) => void;
  public onCollapse: (collapse: boolean) => void;
  public onCheckIdChange: (checkId: string[]) => void;
  public getNodeIds: () => string[];
  public getChildIdMap: () => any;
  public onStatDataChange: (statData: statDataType, statConfig: statConfigType) => void;
  public changeSelectNodeAttr: (direction: 'up' | 'left' | 'down' | 'right') => void;
  public edittingProp: () => void;
  public onLocalTableConfigChange: (
    localTableConfig: ApiResponse.ViewList.ViewTableShowFieldItem[],
  ) => void;
  public onAttrContentAlignChange: (attrContentAlign: any) => void;
  public updateViewInfo: (v: any) => void;
  public updateStyleMode: (v: any) => void;
  public refreshChildNodes: () => void;
  public onGroupsChange: (groups: ApiResponse.CurrentUser.orgGroup[]) => void;
  public splitPaneChange: () => void;
  public handleFooterHidden: (hidden: boolean) => void;

  // 需要暴露出去的方法
  // TODO @zenan
  // 全部折叠/展开
  // public toggleAll: (state:boolean) => void;
  // 获取当前视图节点的排序
  // public getCurrentIds: () => string[];

  constructor(config: configType) {
    window.__PI__GRID = {
      mouseComponents: [],
      mousePosition: { x: 0, y: 0 },
    };
    this.editor = new Core(config);

    this.destroy = () => {
      this.editor?.destroy();
      this.editor = null;
      window.__PI__GRID = {
        mouseComponents: [],
        mousePosition: { x: 0, y: 0 },
      };
    };

    this.getNodeIds = this.editor.getNodeIds.bind(this.editor);
    this.getChildIdMap = this.editor.getChildIdMap.bind(this.editor);
    this.onGroupDataChange = this.editor.onGroupDataChange.bind(this.editor);
    this.onTableAttrsChange = this.editor.onTableAttrsChange.bind(this.editor);
    this.onNodeHeightChange = this.editor.onNodeHeightChange.bind(this.editor);
    this.onCollapse = this.editor.onCollapse.bind(this.editor);
    this.onCheckIdChange = this.editor.onCheckIdChange.bind(this.editor);
    this.onStatDataChange = this.editor.onStatDataChange.bind(this.editor);
    this.changeSelectNodeAttr = this.editor.changeSelectNodeAttr.bind(this.editor);
    this.edittingProp = this.editor.edittingProp.bind(this.editor);
    this.onLocalTableConfigChange = this.editor.onLocalTableConfigChange.bind(this.editor);
    this.onAttrContentAlignChange = this.editor.onAttrContentAlignChange.bind(this.editor);
    this.updateViewInfo = this.editor.updateViewInfo.bind(this.editor);
    this.updateStyleMode = this.editor.updateStyleMode.bind(this.editor);
    this.refreshChildNodes = this.editor.refreshChildNodes.bind(this.editor);
    this.onGroupsChange = this.editor.onGroupsChange.bind(this.editor);
    this.splitPaneChange = this.editor.splitPaneChange.bind(this.editor);
    this.handleFooterHidden = this.editor.handleFooterHidden.bind(this.editor);
  }
}
