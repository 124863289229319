import { defaultTo, pick } from 'ramda';
import type { FC } from 'react';

import { showFormulaSettingModal } from '@/components';
import { cn } from '@/utils/utils';

import { useTempPropConfig } from '../hooks';

const UPDATE_INTERVAL = [
  { label: '不刷新', value: null },
  { label: '每天刷新', value: 'd' },
  { label: '每小时刷新', value: 'h' },
] as const;

export const FormulaType: FC = () => {
  const { isLock, propParams, setPropParams } = useTempPropConfig();

  return (
    <>
      <div className={'prop-item'}>
        <div className={'prop-item-label'}>运算公式</div>
        <div
          className={'prop-item-input bordered text-omit'}
          onClick={() => {
            if (isLock) return;
            showFormulaSettingModal(pick(['formula', 'formulaText'], propParams))
              .then((res) => {
                setPropParams({ ...propParams, ...res });
              })
              .then(console.warn);
          }}
          style={{ color: '#0099e1' }}
        >
          {propParams.formulaText ? (
            propParams.formulaText
          ) : (
            <div className={'prop-item-input-des'}>请配置运算公式</div>
          )}
        </div>
      </div>
      <div className="flex" style={{ justifyContent: 'space-between' }}>
        <div className={'prop-item'}>
          <div className={'prop-item-label'}>公式格式</div>
          <div className={'prop-item-input'}>
            <div
              className={`prop-item-input-btn${!propParams.formulaFormat ? ' active' : ''}`}
              onClick={() => setPropParams({ ...propParams, formulaFormat: 0 })}
            >
              数字公式
            </div>
            <div
              className={`prop-item-input-btn${propParams.formulaFormat === 1 ? ' active' : ''}`}
              onClick={() => setPropParams({ ...propParams, formulaFormat: 1 })}
            >
              文本公式
            </div>
          </div>
        </div>
        <div className={'prop-item'}>
          <div className={'prop-item-label'}>刷新频率</div>
          <div className={'prop-item-input'}>
            {UPDATE_INTERVAL.map((item) => (
              <div
                key={item.value}
                className={cn(
                  `prop-item-input-btn`,
                  defaultTo(null)(propParams.updateInterval) === item.value && ' active',
                )}
                onClick={() => setPropParams({ ...propParams, updateInterval: item.value })}
              >
                {item.label}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
