import type { PiGridModules } from '../core';
import type { HeaderCellType } from '../modules/dataManager';
import type { PositionType } from './_baseComponent';
import GridBaseComponent from './_baseComponent';
import FooterFunctionCell from './footerFunctionCell';

export default class Footer extends GridBaseComponent {
  constructor(position: PositionType, moduleInstances: PiGridModules) {
    super(position, moduleInstances);
  }
  captrue: boolean = false;

  public click() {}

  public renderFunctionCell(cell: HeaderCellType, fixed: boolean) {
    const currentLeft = fixed ? 0 : this.moduleInstances.HorizontalScrollbar.getScrollLeft();

    const functionCell = new FooterFunctionCell(
      {
        x: cell.x - currentLeft,
        y: this.position.y,
        width: cell.width,
        height: this.position.height,
      },
      this.moduleInstances,
      cell,
      false,
    );

    functionCell.render();
  }

  public render() {
    const { DataManager, Draw, Render } = this.moduleInstances;
    const styleMode = Draw.config.styleMode;
    const containerBgColor =
      Draw.config.contentPageGeneralTableConfig?.containerBgColor || '#f8f9fb';
    const footerBg = styleMode === 'darkness' ? containerBgColor : '#f8f9fb';

    const fixedCells = DataManager.headerCells.slice(2, DataManager.config.freeze + 1);
    const notFixedCells = DataManager.headerCells.slice(DataManager.config.freeze + 1);

    notFixedCells.forEach((cell: HeaderCellType) => {
      this.renderFunctionCell(cell, false);
    });

    fixedCells.forEach((cell: HeaderCellType) => {
      this.renderFunctionCell(cell, true);
    });

    Draw.fillRect(
      this.position.x,
      this.position.y,
      this.position.width,
      this.position.height,
      footerBg,
    );

    // 填充字
    Draw.attr({
      fillStyle: styleMode === 'darkness' ? '#919AB5' : '#767c88',
      textBaseline: 'middle',
      textAlign: 'start',
      font: `${Draw.npx(14)}px sans-serif`,
    });

    const textX = Render.collapseRowTextGap;
    const textY = this.position.y + this.position.height / 2;
    Draw.fillText(`合计 ${DataManager.nodeSum} 条`, textX, textY);
    if (DataManager.config.checkId.length) {
      Draw.fillText(`（已勾选 ${DataManager.config.checkId.length} 条）`, textX + 70, textY);
    }
  }
}
