import type { ProFormSelectProps } from '@ant-design/pro-form';
import { ProFormDependency, ProFormSelect } from '@ant-design/pro-form';
import type { SelectProps } from 'antd';
import { isNil } from 'ramda';
import type { ReactElement } from 'react';
import { useMemo } from 'react';

import { useOrgUserGroup } from '@/hook';

import { selectSearchFn } from '../utils';

type UserGroupSelectProps = {
  className?: string;
  mode?: 'single' | SelectProps['mode'];
  labelRender?: (label: string) => string | ReactElement;
};

export function UserGroupSelect<T>(props: ProFormSelectProps<T> & UserGroupSelectProps) {
  const groups = useOrgUserGroup();

  const groupOptions = useMemo(() => {
    return groups.map((i) => ({
      label: i.group_name,
      value: i.group_id,
    }));
  }, [groups]);

  const mode = isNil(props.mode) ? 'multiple' : props.mode === 'single' ? undefined : props.mode;
  return props.name ? (
    <ProFormDependency name={[props.name]}>
      {(values) => {
        return (
          <ProFormSelect
            initialValue={['-1']}
            {...props}
            fieldProps={{
              ...props.fieldProps,
              placeholder: '请选择分组',
              filterOption: selectSearchFn,
              showSearch: true,
              mode: mode,
            }}
            options={groupOptions}
          />
        );
      }}
    </ProFormDependency>
  ) : (
    <ProFormSelect
      initialValue={['-1']}
      {...props}
      fieldProps={{
        ...props.fieldProps,
        placeholder: '请选择分组',
        filterOption: selectSearchFn,
        showSearch: true,
        mode,
      }}
      options={groupOptions}
    />
  );
}
