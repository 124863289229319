import type { ForwardRefRenderFunction } from 'react';
import { forwardRef, useImperativeHandle, useMemo, useState } from 'react';

import type NodeRow from '../components/nodeRow';

import styles from './styles.less';

interface PropsType {
  onAddBtnClick: (node: NodeRow) => void;
}

export interface OverlayerRefType {
  onDisplayAddBtn: (node: NodeRow | false) => void;
}

const Overlayer: ForwardRefRenderFunction<OverlayerRefType, PropsType> = (props, ref) => {
  useImperativeHandle(ref, () => ({
    onDisplayAddBtn(node) {
      setDisplayAddBtn(node);
    },
  }));

  const { onAddBtnClick } = props;

  const [displayAddBtn, setDisplayAddBtn] = useState<false | NodeRow>(false);

  const displayAddBtnWrap = useMemo(() => {
    if (!displayAddBtn) return null;

    return (
      <div className={styles.quickAddWrap} style={{ top: displayAddBtn.position.y }}>
        <div className={styles.btnWrap} onClick={(_) => onAddBtnClick(displayAddBtn)}>
          <i className="iconfont iconButton_add" />
        </div>
        <div className={styles.hoverBorder} />
      </div>
    );
  }, [displayAddBtn]);

  return <div className={styles.piGanttOverlayer}>{displayAddBtnWrap}</div>;
};

export default forwardRef(Overlayer);
