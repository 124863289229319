import { Button, message, Upload } from 'antd';
import type { FC } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import ReactQuill from 'react-quill';

import IconPark from '@/components/IconPark';

import { getFileExt, onUpload } from '../utils';

import styles from './styles.less';

interface IProps {
  userMap: any;
  currentUser: any;
  currentSelection: any;
  node: any;
  sendMessage: (delta: any) => void;
}

const EditArea: FC<IProps> = (props) => {
  const { userMap, currentUser, currentSelection, node, sendMessage } = props;

  const [files, setFiles] = useState<any[]>([]);

  // mentionUsers
  const mentionUsers = useMemo(() => {
    const users: any[] = [];
    Object.values(userMap)
      .filter((i) => i)
      .forEach((u: any) => {
        if (u.account_id !== currentUser.userid) {
          users.push({
            id: u.account_id,
            value: u.nick_name,
          });
        }
      });

    return users;
  }, [userMap]);

  // react-quill 实例
  const quillRef = useRef<any>(null);
  // quill 实例
  const editor = useRef<any>(null);

  const modules = {
    toolbar: false,
    mention: {
      allowedChars: /^[^x00-xff_A-Za-z0-9\s]*$/,
      mentionDenotationChars: ['@'],
      source: (searchTerm: string, renderList: any) => {
        const matches = mentionUsers.filter(
          (u: any) => ~u.value.indexOf(searchTerm),
        );
        renderList(matches, searchTerm);
      },
      onSelect: (item: any, insertItem: any) => {
        insertItem(item, true);
        item.key = item.id;
        // 删除多余的字符
        let index = quillRef.current.getSelection().index - 3;
        let len = 1;
        while (quillRef.current.getText(index, 1) !== '@') {
          index--;
          len++;
        }
        quillRef.current.deleteText(index, len, 'user');
      },
    },
    // 键盘事件
    keyboard: {
      bindings: {
        enter: {
          key: 'enter',
          handler: () => {
            sendMsg();
          },
        },
        ctrlEnter: {
          key: 'enter',
          ctrlKey: true,
          handler: () => enter(),
        },
      },
    },
  };

  const formats = ['mention', 'image', 'link', 'fileBlot'];

  const getFiles = (items: any[]) => {
    const newItems = items.map((i: any) => {
      if (i.kind === 'file') {
        return i.getAsFile();
      }

      return null;
    });

    return newItems.filter((i) => i);
  };

  // 编辑器 cv
  const handlePaste = (e: any) => {
    if (
      e.clipboardData &&
      e.clipboardData.items &&
      e.clipboardData.items.length
    ) {
      const files = getFiles([...e.clipboardData.items]);

      if (files.length === 0) return;

      e.preventDefault();
      serialRequest(files, 'quill');
    }
  };

  // 复制文字到quill 主要处理链接
  const quillPaste = (data: string, delta: any) => {
    const { ops } = delta;

    const newOps = ops.map((op: any) => {
      const insert = op.insert;
      // 是链接
      if (
        insert &&
        typeof insert === 'string' &&
        (insert.startsWith('http://') || insert.startsWith('https://'))
      ) {
        return {
          insert,
          attributes: { link: insert },
        };
      }

      return { insert };
    });

    delta.ops = newOps;
    return delta;
  };

  // 事件代理 点击链接
  const handleClick = (e: any) => {
    let { target } = e;
    let url = '';

    while (target && target.className !== 'ql-editor' && !url) {
      if (
        target.href &&
        (target.href.startsWith('http://') ||
          target.href.startsWith('https://'))
      ) {
        url = target.href;
      }

      target = target.parentNode;
    }

    if (url) window.open(url);
  };

  // 换行
  const enter = () => {
    editor.current.insertText(getQuillSelection(), '\n');
  };

  useEffect(() => {
    const editorClass = quillRef.current.getEditor();
    quillRef.current.focus();

    // 文字复制
    editorClass.clipboard.addMatcher(Node.TEXT_NODE, quillPaste);

    editorClass.root.addEventListener('paste', handlePaste, false);
    editorClass.root.addEventListener('click', handleClick, false);
    editor.current = editorClass;

    return () => {
      editorClass.root.removeEventListener('paste', handlePaste, false);
      editorClass.root.removeEventListener('click', handleClick, false);
    };
  }, []);

  // 上传文件
  useEffect(() => {
    if (files.length === 0) return;

    serialRequest(
      files.map((i: any) => i.originFileObj),
      'userSelect',
    ).then(() => setFiles([]));
  }, [files.length]);

  // 获得当前 quill 的光标位置
  const getQuillSelection = () => {
    return editor.current.getSelection().index;
  };
  // 将文件插入到 quill 中
  const insertEmbed = (file: any, fileUrl: any) => {
    const { type, name, size } = file;
    let quillFileType = 'fileBlot';
    if (
      type.match(/^image\/(gif|jpe?g|a?png|svg|webp|bmp|vnd\.microsoft\.icon)/i)
    )
      quillFileType = 'image';

    if (quillFileType === 'fileBlot') {
      editor.current.insertEmbed(getQuillSelection(), 'fileBlot', {
        href: fileUrl,
        fileName: name,
        fileSize: size,
        fileType: getFileExt(name),
      });
    } else {
      editor.current.insertEmbed(getQuillSelection(), 'image', fileUrl);
    }
  };
  // 清空所有消息
  const clear = () => {
    const len = editor.current.getLength();
    editor.current.deleteText(0, len);
  };

  const selectFilesSend = (file: any, fileUrl: string) => {
    let quillFileType = 'fileBlot';

    let payload;
    const { type, name, size } = file;

    if (
      type.match(/^image\/(gif|jpe?g|a?png|svg|webp|bmp|vnd\.microsoft\.icon)/i)
    )
      quillFileType = 'image';

    if (quillFileType === 'image') {
      payload = {
        insert: {
          image: fileUrl,
        },
      };
    }

    if (quillFileType === 'fileBlot') {
      payload = {
        attributes: { size: '' },
        insert: {
          fileBlot: {
            fileName: name,
            fileSize: size,
            href: fileUrl,
            fileType: getFileExt(name),
          },
        },
      };
    }
    const delta = {
      ops: [payload],
    };

    sendMessage(delta);
  };

  // 串行发送消息
  const serialRequest = (items: any[], mode: string) => {
    return new Promise((rel: any) => {
      const dispatch = async (index: number) => {
        if (index === items.length) {
          rel();
          return;
        }

        const file = items[index];

        if (file.size > 1000 * 1024 * 2) {
          message.warning('发送的文件不能超过2MB');
          dispatch(index + 1);
          return;
        }

        // 发送
        const reader = new FileReader();

        reader.onload = async (e: any) => {
          const fileUrl = await onUpload(
            node.value.id,
            Buffer.from(e.target.result.split(',')[1], 'base64'),
            getFileExt(file.name),
            currentSelection,
          );

          if (fileUrl) {
            if (mode === 'quill') {
              // 插入到 quill 中
              insertEmbed(file, fileUrl);
              // 处理下一张
              dispatch(index + 1);
            }

            if (mode === 'userSelect') {
              await selectFilesSend(file, fileUrl);
              dispatch(index + 1);
            }
          }
        };

        // 解析文件
        if (file instanceof Blob) {
          reader.readAsDataURL(file);
        }
      };

      dispatch(0);
    });
  };

  // 发送消息
  const sendMsg = () => {
    const content = editor.current.getContents();
    const ops = content.ops;
    const f = ops[0];

    let realityContent = '';

    // 只有空格 回车 不需要发送
    if (f.insert && typeof f.insert === 'string') {
      realityContent = f.insert.replace(/[\r\n]/g, '');
    }

    if (ops.length < 2 && !realityContent.trim()) {
      console.log('消息为空～～');
      clear();
      return;
    }

    sendMessage(content);
    clear();
  };
  // 直接发送文件
  const uploadFiles = (e: any) => {
    const { fileList } = e;
    setFiles(fileList);
  };

  return (
    <div className={styles.editArea}>
      <div className={styles.top}>
        <Upload
          multiple
          beforeUpload={() => {}}
          onChange={uploadFiles}
          showUploadList={false}
          children={
            <IconPark
              style={{ fontSize: 18, cursor: 'pointer' }}
              type="tupian1"
            />
          }
          fileList={files}
        />
      </div>
      {useMemo(
        () => (
          <ReactQuill
            ref={quillRef}
            theme="snow"
            placeholder="我来说几句..."
            modules={modules}
            formats={formats}
          />
        ),
        [],
      )}
      <div className={styles.footer}>
        <div className={styles.tip}>Enter发送</div>
        <Button
          icon={<IconPark type="fasong" />}
          onClick={sendMsg}
          type="primary"
        >
          发送
        </Button>
      </div>
    </div>
  );
};

export default EditArea;
