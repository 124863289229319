import { nanoid } from 'nanoid';
import { useRef } from 'react';

/**
 * 用于生成 react 组件实例的唯一 id
 *
 * NOTE: 不支持 RSC，SSR, 仅限客户端使用
 */
export const useComponentId = () => {
  const idRef = useRef<string | null>(null);

  if (!idRef.current) {
    idRef.current = nanoid();
  }

  return idRef.current as string;
};
