import { generateId } from '../helper/utils';
import BaseModule from './_baseModule';

export interface listenerType {
  id: string;
  element: EventTarget;
  eventType: string;
  handler: (event: any) => void;
  options?: boolean | AddEventListenerOptions;
}

export default class Listeners extends BaseModule {
  private allListeners: listenerType[] = [];

  public on(
    element: EventTarget,
    eventType: string,
    handler: (event: any) => void,
    options?: boolean | AddEventListenerOptions,
  ): string {
    const id = generateId();

    const eventData = {
      id,
      element,
      eventType,
      handler,
      options,
    };

    this.allListeners.push(eventData);
    element.addEventListener(eventType, handler, options);

    return id;
  }

  public off(element: EventTarget, eventType: string, handler: (event: any) => void) {
    const index = this.allListeners.findIndex((x) => {
      return x.element === element && x.eventType === eventType && x.handler === handler;
    });

    if (!~index) {
      return;
    }

    const listener = this.allListeners.splice(index, 1)[0];
    listener.element.removeEventListener(listener.eventType, listener.handler, listener?.options);
  }

  public offById(id: string) {
    const listener = this.allListeners.find((x) => x.id === id);

    if (!listener) {
      return;
    }

    listener.element.removeEventListener(listener.eventType, listener.handler, listener?.options);
  }

  private removeAll() {
    this.allListeners.map((listener) => {
      listener.element.removeEventListener(listener.eventType, listener.handler, listener?.options);
    });

    this.allListeners = [];
  }

  public destroy() {
    this.removeAll();
  }
}
