import { elecwattConfig } from '@/utils/utils';

import type { PiGridModules } from '../core';
import { checkboxConfig, openIconConfig } from '../helper/config';
import { splitArray } from '../helper/utils';
import type { HeaderCellType, RowType } from '../modules/dataManager';
import type { PositionType } from './_baseComponent';
import GridBaseComponent from './_baseComponent';
import CheckboxCell from './checkboxCell';
import DragNodeIcon from './dragNodeIcon';
import NodeCol from './nodeCol';
import OpenIcon from './openIcon';
import ShowLevelInFirstCol from './showLevelInFirstCol';

class NodeRow extends GridBaseComponent {
  public data: RowType<'node'>;
  public type: string = 'nodeRow';
  public STYLE = {
    hoverBg: '#ffffff',
    selectBg: '#f0f6ff',
  };
  constructor(position: PositionType, moduleInstances: PiGridModules, data: any, fixed: boolean) {
    super(position, moduleInstances, fixed);
    this.data = data;
  }

  public click() {}

  public hover() {
    const { groupKey, index } = this.data;
    this.moduleInstances.DataManager.hoverRow = `${groupKey}/${index}`;
  }

  public render() {
    const { DataManager, Draw, Render } = this.moduleInstances;
    const { x, y, height, width } = this.position;
    const { groupKey, index } = this.data;

    const styleMode = Draw.config.styleMode;
    const containerBgColor = Draw.config.contentPageGeneralTableConfig?.containerBgColor || '#fff';
    const cellBg = styleMode === 'darkness' ? containerBgColor : '#fff';

    let bg = this.isHover
      ? styleMode === 'darkness'
        ? 'rgba(0,0,0,0)'
        : this.STYLE.hoverBg
      : cellBg;
    bg =
      this.moduleInstances.DataManager.selectRow === `${groupKey}/${index}`
        ? styleMode === 'darkness'
          ? containerBgColor
          : this.STYLE.selectBg
        : bg;

    if (this.isHover && Draw.config.navMode === 'elecwatt') bg = elecwattConfig.tableRowHoverBg;
    Draw.fillRect(0, y, width, height, bg);

    // node cell
    const [fixedCells, notFixedCells] = splitArray(
      DataManager.headerCells,
      DataManager.config.freeze + 1,
    );

    notFixedCells.forEach((cell: HeaderCellType) => {
      this.renderNodeCol(cell, false);
    });

    Draw.fillRect(0, y, Render.getFixedWidth(), height, bg);

    fixedCells.forEach((cell: HeaderCellType, index: number) => {
      index === 0 ? this.renderCheckbox(cell) : this.renderNodeCol(cell, true);
    });

    // const actionButtons = Draw.config.viewInfo.actionButtons || []
    // @ts-ignore
    const showDefaultOpenIcon = Draw.config?.viewInfo?.tableDisplayConfig?.openIcon !== false;
    // hover的时候画icon
    if (this.isHover && showDefaultOpenIcon) {
      // 拖动row功能
      // const dragNodeIcon = new DragNodeIcon(
      //   {
      //     height: 20,
      //     width: 18,
      //     x: 2,
      //     y: y + (height - 20) / 2,
      //   },
      //   this.moduleInstances,
      //   this.data,
      // );

      const openIcon = new OpenIcon(
        {
          height: openIconConfig.height,
          width: openIconConfig.width,
          x: 34,
          y: y + (height - openIconConfig.height) / 2,
        },
        this.moduleInstances,
        this.data,
      );

      openIcon.render();
      // dragNodeIcon.render();
    }
  }

  public renderCheckbox(cell: HeaderCellType) {
    if (cell.disable) return;
    const { y, height } = this.position;
    const { size } = checkboxConfig;
    const startX = 10;
    const startY = (height - size) / 2 + y;

    const checkBoxCell = new CheckboxCell(
      {
        x: startX,
        y: startY,
        width: size,
        height: size,
      },
      this.moduleInstances,
      { type: 'nodeCheckbox', node: this.data },
      () => {
        const id = this.data.node.id;
        this.moduleInstances.DataManager.config.onCheck(id);
      },
    );

    checkBoxCell.render(this.isHover);
  }

  public renderNodeCol(cell: HeaderCellType, fixed: boolean) {
    const currentLeft = fixed ? 0 : this.moduleInstances.HorizontalScrollbar.getScrollLeft();
    const { Draw, DataManager } = this.moduleInstances;
    const lineColor = Draw.config.styleMode === 'darkness' ? 'rgba(255,255,255,0.05)' : '#E4E4E4';
    const showLevelInFirstCol = Draw.config.viewInfo.showLevelInFirstCol;
    const cellIndex = DataManager.headerCells.findIndex((i) => i.key === cell.key);
    const isLeaf = (this.data.node.children || []).length === 0;
    const treeLevelId = this.data.node.treeLevelId || '1';
    const level = treeLevelId.split('.').length;

    // 操作列固定在右侧
    const colStartX =
      cell.type === 'actions' ? Draw.canvasWidth - cell.width : cell.x - currentLeft;
    const colStartY = this.position.y;

    // 格子边框
    if (Draw.config.navMode !== 'elecwatt')
      Draw.line(
        [
          [colStartX, colStartY],
          [colStartX, colStartY + this.position.height],
        ],
        lineColor,
      );

    const levelArrow = new ShowLevelInFirstCol(
      {
        x: colStartX + (level - 1) * 30,
        y: colStartY,
        width: 30,
        height: this.position.height,
      },
      this.moduleInstances,
      this.data,
    );
    if (!isLeaf && showLevelInFirstCol && cellIndex === 1) levelArrow.render();

    const gap = showLevelInFirstCol && cellIndex === 1 ? level * 30 : 0;

    const nodeCol = new NodeCol(
      {
        x: colStartX + gap,
        y: this.position.y,
        width: cell.width - gap,
        height: this.position.height,
      },
      this,
      { ...cell, propStyleMap: this.data.propStyleMap } as any,
      this.isHover,
    );

    this.moduleInstances.DataManager.nodeColMap[
      `${this.data.groupKey}&${this.data.index}&${cell.key}`
    ] = nodeCol;

    nodeCol.render();
  }
}

export default NodeRow;
