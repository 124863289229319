import type { ApiResponse, ViewList } from '@linkpi/core';
import { NODE_TYPE, ORG_ROLE } from '@linkpi/core';
import type { GetterPiNode } from '@linkpi/core/web';
import { getVirtualNodeOriginId } from '@linkpi/core/web';
import { useDeepCompareEffect, useUpdateEffect } from '@react-hookz/web';
import { useMemoizedFn, useWhyDidYouUpdate } from 'ahooks';
import { message } from 'antd';
import dayjs from 'dayjs';
import type { Draft } from 'immer';
import { current, produce } from 'immer';
import { atom, useAtom, useSetAtom } from 'jotai';
import { atomWithReset, useResetAtom } from 'jotai/utils';
import { isEqual } from 'lodash';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';

import request from '@/utils/request';

import { useOrgViewListRequest } from './useOrgViewListRequest';

export type ViewAuthType = false | true | 'admin' | 'creator';

const EMPTY_ARRAY: any[] = [];
const EMPTY_OBJ: Record<string, number> = {};

export const WorkbenchViewListContext = createContext({
  mutateViewInfo: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    viewId: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updater: (
      viewInfoDraft: Draft<ApiResponse.ViewList.ViewListItem<ApiResponse.ViewList.ViewType>>,
    ) => void,
  ) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  refreshViewList: (...args: any[]): any => {},
});

export const useWorkbenchViewListContext = () => {
  return useContext(WorkbenchViewListContext);
};

const serverViewInfoMapAtom = atomWithReset<Record<string, ViewList.ViewListItem | null>>({});

const setServerViewInfoAtom = atom(
  null,
  (get, set, viewId: string, value: ViewList.ViewListItem | null) => {
    const serverViewInfoMap = get(serverViewInfoMapAtom);
    // 如果已经存了，说明是临时筛选
    if (serverViewInfoMap[viewId]) return;

    set(serverViewInfoMapAtom, (infoMap) => ({ ...infoMap, [viewId]: value }));
  },
);

export const useLocalViewCondition = (viewId: string) => {
  const { mutateViewInfo } = useWorkbenchViewListContext();
  const [serverViewInfoMap, setServerViewInfoMap] = useAtom(serverViewInfoMapAtom);

  const serverViewInfo = serverViewInfoMap[viewId];
  const isLocalMode = !!serverViewInfo;

  const setLocalViewInfo = useCallback(
    (args: Parameters<typeof mutateViewInfo>[1]) => {
      mutateViewInfo(viewId, args);
    },
    [viewId],
  );

  const resetLocalViewInfo = useMemoizedFn(() => {
    if (!isLocalMode) return;
    mutateViewInfo(viewId, (draft) => {
      draft.view_info = serverViewInfo!.view_info;
    });

    setServerViewInfoMap({
      ...serverViewInfoMap,
      [viewId]: null,
    });
  });

  return { isLocalMode, setLocalViewInfo, resetLocalViewInfo };
};

export const useWorkbenchViewList = (
  node: GetterPiNode,
  orgId: string,
  orgRole: ApiResponse.CurrentUser.OrgInfo['role'],
  userId: string,
  onViewListResponse?: (data: ApiResponse.ViewList) => void,
  filterViewType: ApiResponse.ViewList.ViewType[] = EMPTY_ARRAY as any,
) => {
  const [_originNodeId] = getVirtualNodeOriginId(node.value.nodeManager, node.value.id);
  const originNode = node.value.nodeManager.findChildren(_originNodeId);

  /**
   * 记录了节点上的视图排序
   *
   */
  const [originNodeSysToppings = EMPTY_OBJ, setOriginNodeSysToppings] = useState(
    originNode.prop._sys_topping_views,
  );

  useDeepCompareEffect(() => {
    if (isEqual(originNode.prop._sys_topping_views, originNodeSysToppings)) return;

    setOriginNodeSysToppings(originNode.prop._sys_topping_views);
  }, [originNode.prop._sys_topping_views]);

  const [originNodeId, originNodeSysHideChildren] = useMemo(() => {
    return [
      _originNodeId,
      originNode.prop._sys_hideChildren || originNode.prop._sys_hideWorkAreaChildren,
    ];
  }, [_originNodeId, originNode.prop._sys_hideChildren, originNode.prop._sys_hideWorkAreaChildren]);

  const resetServerViewInfoMapAtom = useResetAtom(serverViewInfoMapAtom);

  // 获取视图列表 通过源节点获取
  const {
    data: viewList = EMPTY_ARRAY as ApiResponse.ViewList,
    mutate: setViewList,
    refreshAsync: fetchData,
  } = useOrgViewListRequest(
    { node_id: originNodeId },
    {
      onSuccess(data) {
        resetServerViewInfoMapAtom();

        if (onViewListResponse) {
          onViewListResponse(
            getDisplayViewList(
              data,
              node.value.nodeType,
              !!originNodeSysHideChildren,
              originNodeSysToppings,
              filterViewType || [],
            ),
          );
        }
      },
    },
  );

  const setServerViewInfoMap = useSetAtom(setServerViewInfoAtom);

  const mutateViewInfo = useMemoizedFn(
    (
      viewId: string,
      updater: (
        viewInfoDraft: Draft<ApiResponse.ViewList.ViewListItem<ApiResponse.ViewList.ViewType>>,
      ) => void,
    ) => {
      const newViewList = produce(viewList, (drafts) => {
        for (let index = 0; index < drafts.length; index++) {
          const viewInfo = drafts[index];
          if (viewInfo.view_id !== viewId) continue;

          setServerViewInfoMap(viewId, current(drafts)[index]);

          updater(viewInfo);
          break;
        }
      });

      setViewList(newViewList);
    },
  );

  // 权限
  const authMap = useMemo(
    () =>
      viewList.reduce(
        (a, b) => {
          a[b.view_id] = getViewAuth(orgRole, userId, b);
          return a;
        },
        {} as Record<string, ViewAuthType>,
      ),
    [orgRole, userId, viewList],
  );

  // 返回列表
  const displayViewList = useMemo(() => {
    const result = getDisplayViewList(
      viewList,
      node.value.nodeType,
      !!originNodeSysHideChildren,
      originNodeSysToppings,
      filterViewType || [],
    );

    return result;
  }, [
    viewList,
    node.value.nodeType,
    originNodeSysHideChildren,
    originNodeSysToppings,
    filterViewType,
  ]);

  // 切换排序
  const handleSort = useMemoizedFn(async (viewId: string, newIndex: number) => {
    if (viewId !== 'content') {
      const view = viewList.find((v) => v.view_id === viewId);
      if (!view) return;
      const auth = getViewAuth(orgRole, userId, view);
      if (!auth) {
        return message.error('没有权限');
      }
    }

    const oldIndex = displayViewList.findIndex((x) => x.view_id === viewId);
    if (oldIndex === newIndex) return;

    // 通过before更新
    let beforeIndex = newIndex;
    if (oldIndex > newIndex) {
      beforeIndex--;
    }

    const beforeViewId = ~beforeIndex ? displayViewList[beforeIndex].view_id : null;

    /**
     * 计算视图的排序数据
     *
     * 需要 mutate state，避免 UI 抖动问题
     */
    const newOriginNodeSysToppings = (() => {
      const view_list = viewList;
      const _sys_topping_views = { ...originNodeSysToppings };
      if (!('content' in _sys_topping_views)) {
        for (const view_id in _sys_topping_views) {
          _sys_topping_views[view_id]++;
        }
        _sys_topping_views.content = 1;
      }
      let max = Math.max(...Object.values(_sys_topping_views));
      for (const view_id in view_list) {
        if (!(view_id in _sys_topping_views)) {
          _sys_topping_views[view_id] = ++max;
        }
      }
      const pos = beforeViewId === null ? 1 : (_sys_topping_views[beforeViewId] || 0) + 1;
      if (pos === _sys_topping_views[viewId]) {
        return _sys_topping_views;
      }
      for (const key in _sys_topping_views) {
        if (_sys_topping_views[key] >= pos) _sys_topping_views[key]++;
      }
      _sys_topping_views[viewId] = pos;
      return _sys_topping_views;
    })();

    setOriginNodeSysToppings(newOriginNodeSysToppings);

    const res = await request('/docapi/setToppingView', {
      method: 'POST',
      data: {
        org_id: orgId,
        node_id: originNodeId,
        view_id: viewId,
        before: beforeViewId,
      },
    });

    if (res.status !== 'ok') {
      message.error(res.message as string);
    }
  });

  return [displayViewList, fetchData, authMap, handleSort, mutateViewInfo] as const;
};

/**
 * 获取视图权限
 */
export const getViewAuth = (
  orgRole: ApiResponse.CurrentUser.OrgInfo['role'],
  userId: string,
  viewInfo: ApiResponse.ViewList.ViewListItem<any>,
): ViewAuthType => {
  if (!orgRole) {
    return false;
  }
  if (orgRole === ORG_ROLE.OWNER || orgRole === ORG_ROLE.ADMIN) {
    return 'admin';
  }
  if (viewInfo.account_id === userId) {
    return 'creator';
  }
  if (viewInfo.public) {
    if (
      orgRole === ORG_ROLE.EDIT ||
      orgRole === ORG_ROLE.READONLY ||
      orgRole === ORG_ROLE.ADDONLY
    ) {
      return true;
    }
  }

  return false;
};

// 移动数组元素
export function moveArrayItem<T>(arr: T[], oldIndex: number, newIndex: number): T[] {
  if (oldIndex === newIndex) return [];
  const item = arr.splice(oldIndex, 1);
  arr.splice(newIndex, 0, item[0]);
  return arr;
}

/**
 * 获取视图列表 通过节点本身判断
 */
export const getDisplayViewList = (
  viewList: ApiResponse.ViewList,
  curNodeType: number,
  originNodehideChildren: boolean | null,
  originNodeSysToppings: Record<string, number>,
  filterViewType: ApiResponse.ViewList.ViewType[],
) => {
  let list = viewList;
  if (curNodeType !== NODE_TYPE.FILTERNODE_PATH && !originNodehideChildren) {
    list = [
      ...list,
      {
        view_id: 'content',
        view_name: '主题树',
        create_time: dayjs().add(-10, 'year').valueOf(),
      } as unknown as ApiResponse.ViewList.ViewListItem<any>,
    ];
  }

  const displayList = list.filter((x) => {
    // !x.default_view && !x.dash_view_id
    if (x.default_view || x.dash_view_id) {
      return false;
    }
    return !filterViewType.includes(x.view_type);
  });
  const topping = originNodeSysToppings || {};
  return (
    displayList
      // 1. 按topping的升序
      // 2. 按创建时间升序
      .sort((a, b) => {
        if (topping[b.view_id] && topping[a.view_id])
          return topping[a.view_id] - topping[b.view_id];
        if (topping[b.view_id]) return 1;
        if (topping[a.view_id]) return -1;
        return a.create_time - b.create_time;
      })
  );
};
