import { ProFormTreeSelect } from '@ant-design/pro-form';
import type { ComponentProps, FC } from 'react';
import { useCallback, useMemo } from 'react';

import { useNodeTreeData } from '@/hook';
import { useOrgConnection } from '@/hook';

type TemplateTreeNodeSelectProp = {
  orgId: string;
  rootTitle?: string;
  disabledChildren?: string[];
} & ComponentProps<typeof ProFormTreeSelect>;

export const TemplateTreeNodeSelect: FC<React.PropsWithChildren<TemplateTreeNodeSelectProp>> = ({
  orgId,
  rootTitle,
  disabledChildren = [],
  ...props
}) => {
  const treeData = useNodeTreeData({ orgId, rootTitle });
  const orgConnection = useOrgConnection(orgId);

  const getTreeData = useCallback(() => {
    return Promise.resolve(treeData);
  }, [treeData]);

  const fieldProps = useMemo(
    () => ({
      ...(props.fieldProps || {}),
      showSearch: true,
      filterTreeNode: (v: string, node: any) => (node?.title as string).includes(v),
    }),
    [props.fieldProps],
  );

  return (
    <ProFormTreeSelect
      {...props}
      request={getTreeData}
      fieldProps={fieldProps}
      initialValue={orgConnection!.nodeManager._rootId}
    />
  );
};
