import { filterChecker } from '@linkpi/core';
import { throttle } from 'lodash';

import { genShades } from '@/utils/utils';

import AddRow from '../components/addRow';
import CollapseCell from '../components/collapseCell';
import CollapseGroupFunctionCell from '../components/collapseGroupFunctionCell';
import Footer from '../components/footer';
import Header from '../components/header';
import NodeRow from '../components/nodeRow';
import StatisticsRow from '../components/statisticsRow';
import FixCollapseCell from '../fixComponents/fixCollapseCell';
import FixNodeRow from '../fixComponents/fixNodeRow';
import $ from '../helper/dom';
import Log from '../helper/log';
import BaseModule from './_baseModule';
import type { RowType } from './dataManager';
export default class Render extends BaseModule {
  public static CSS = {
    canvas: 'pi-grid-canvas',
  };

  public headerHeight = 44;
  public footerHeight = 44;
  public collapseRowTextGap = 20;
  public headerCellMoreWidth = 10;

  public selectRowBg = '#f0f6ff';
  public hoverRowBg = '#fafafa';
  public hoverHeaderCellBg = '#f0f0f0';
  public headerCellBg = '#f8f9fb';
  public footerCellBg = '#f8f9fb';
  public hoverCollapseGroupFunctionCellBg = '#f0f0f0';
  public hoverFooterFunctionCell = '#f0f0f0';

  public prepare() {
    if (!this.config.container) {
      Log.error('config.container 不能为空');
      return;
    }

    // make wrapper
    this.doms.container = $.getContainer(this.config.container) as HTMLElement;
    this.doms.container.innerHTML = '';

    // canvas
    this.doms.canvas = $.make('canvas', [Render.CSS.canvas]);
    this.doms.container.append(this.doms.canvas);

    this.moduleInstances.Event.bootstrap();
  }

  // 需要设置的光标样式 设置时取最后一个
  private cursorList: string[] = [];

  public setCursor(cursor: string) {
    this.cursorList.push(cursor);
  }

  private renderCursor() {
    const lastCursor = this.cursorList.length ? this.cursorList[this.cursorList.length - 1] : null;
    this.doms.container.style.cursor = lastCursor || 'default';
  }

  private renderShadow() {
    const { HorizontalScrollbar, Draw, DataManager } = this.moduleInstances;
    const scrollLeft = HorizontalScrollbar.getScrollLeft();
    const fixedWidth = this.getFixedWidth();

    const shades = genShades(0.05, 40);
    if (scrollLeft > 10) {
      shades.forEach((shade: number, index: number) => {
        Draw.fillRect(
          fixedWidth + index,
          0,
          1,
          Draw.canvasHeight,
          `rgba(0, 0, 0, ${1 - shade / 255})`,
        );
      });
    }

    const actionWidth = DataManager.headerCells.find((cell) => cell.type === 'actions')?.width;

    const isScrollToRight = Draw.canvasWidth + scrollLeft === this.getContentWidth();
    if (actionWidth && !isScrollToRight) {
      shades.forEach((shade: number, index: number) => {
        Draw.fillRect(
          Draw.canvasWidth - actionWidth - index,
          0,
          1,
          Draw.canvasHeight,
          `rgba(0, 0, 0, ${1 - shade / 255})`,
        );
      });
    }
  }

  public runTriggerList() {
    this.triggerList.map((x) => x());
  }

  private triggerList: any[] = [];
  public addToTriggerList(action: () => void) {
    this.triggerList.push(action);
  }

  private fitTableHeight(rect: any) {
    // 判断是不是内容装修的表格
    const {
      config: { contentPageGeneralTableConfig },
      totalHeight,
    } = this.moduleInstances.DataManager;
    const { maxTableHeight } = contentPageGeneralTableConfig;

    if (maxTableHeight) {
      return Math.min(totalHeight, maxTableHeight);
    }
    return rect.height;
  }

  public render = throttle(() => {
    requestAnimationFrame(() => {
      // 子区块中当前主题的组件
      window.nowTemplateComponentsInChildArea = [];
      // 清空component
      window.__PI__GRID.mouseComponents = [];
      // 清空光标列表
      this.cursorList = [];
      // 清空triggerList
      this.triggerList = [];

      const { Draw, DataManager } = this.moduleInstances;
      const styleMode = Draw.config.styleMode;
      // resize canvas
      const rect = this.doms.container.getBoundingClientRect();
      Draw.resize(rect.width, this.fitTableHeight(rect));
      Draw.clear();

      DataManager.getColCells();
      // bg
      Draw.fillRect(
        0,
        0,
        Draw.canvasWidth,
        Draw.canvasHeight,
        styleMode === 'darkness' ? 'rgba(0,0,0,0)' : '#fff',
      );
      if (this.config.tableAttrs.length === 0) return;
      const {
        ImageLoader: { imageMap },
      } = this.moduleInstances;
      if (!imageMap.avatar.default) return;
      // if (!Object.keys(this.config.groupData).length) return;

      if (this.config.mode === 'default') {
        // 1. render grid
        this.renderRows();
        // 2. render line
        this.renderLine();
        // 3. render header
        this.renderHeader();
        if (!this.config.hiddenFooter) {
          // 4. render footer
          this.renderFooter();
        }
        // 5. renderShadow
        this.renderShadow();
        // 6. 某个时机触发
        this.runTriggerList();
      }

      if (this.config.mode === 'custom') {
        // 定制表格的渲染方式
        this.renderCustomRows();
        if (window.nowTemplateComponentsInChildArea.length) {
          window.nowTemplateComponentsInChildArea.forEach((c) => c.render());
        }
        this.runTriggerList();
        // this.renderLine();
      }

      // 设置光标
      this.renderCursor();
    });
  }, 16);

  public getContentWidth() {
    return this.moduleInstances.DataManager.headerCells.reduce((sum, cell) => sum + cell.width, 0);
  }

  public getFixedWidth() {
    const { DataManager, Draw } = this.moduleInstances;
    const headerCells = DataManager.headerCells;
    const fixedCells = headerCells.slice(0, Draw.config.freeze + 1);

    return fixedCells.reduce((sum: number, cell) => sum + cell.width, 0);
  }

  // 定制表格的渲染方式
  private renderCustomRows() {
    const { DataManager, HorizontalScrollbar, VerticalScrollbar, Draw } = this.moduleInstances;
    const { fixVirtualList } = DataManager;
    const scrollTop = VerticalScrollbar.getScrollTop();

    for (let index = 0; index < fixVirtualList.length; index++) {
      const row = fixVirtualList[index];
      const { type } = row;
      const y = row.y - scrollTop;

      if (y + row.height < 0) {
        if (
          !row.handleNowTemplateColConfig ||
          row.handleNowTemplateColConfig.nowChildNodeIndex !== 0
        )
          continue;
      }
      if (y > Draw.canvasHeight) {
        break;
      }

      // 折叠
      if (type === 'group') {
        const Collapse = new FixCollapseCell(
          {
            x: 0,
            y: y,
            width: Draw.canvasWidth,
            height: row.height,
          },
          this.moduleInstances,
          row as RowType<'group'>,
        );

        Collapse.render();
      }

      // 节点
      if (type === 'node' || type === 'childNode') {
        const node = new FixNodeRow(
          {
            x: 0,
            y: y,
            width: this.moduleInstances.DataManager.contentWidth,
            height: row.height,
          },
          this.moduleInstances,
          row as RowType<'node'>,
          false,
        );
        node.render();
      }

      // addRow
      if (type === 'addRow') {
        const addRow = new AddRow(
          {
            x: 0,
            y: y,
            width: this.moduleInstances.DataManager.contentWidth,
            height: row.height,
          },
          this.moduleInstances,
          row as RowType<'addRow'>,
          false,
        );
        addRow.render();
      }
    }
  }

  public renderFunctionCell(cell: any, fixed: boolean, y: number, row: any) {
    const currentLeft = fixed ? 0 : this.moduleInstances.HorizontalScrollbar.getScrollLeft();

    const functionCell = new CollapseGroupFunctionCell(
      {
        x: cell.x - currentLeft,
        y,
        width: cell.width,
        height: row.height,
      },
      this.moduleInstances,
      { row, cell },
      false,
    );

    functionCell.render();
  }

  private renderRows() {
    const { DataManager, HorizontalScrollbar, VerticalScrollbar, Draw } = this.moduleInstances;
    const { virtualList, headerCells } = DataManager;
    const scrollLeft = HorizontalScrollbar.getScrollLeft();
    const scrollTop = VerticalScrollbar.getScrollTop();
    const contentWidth = this.getContentWidth();

    for (let index = 0; index < virtualList.length; index++) {
      const row = virtualList[index];
      const { type } = row;
      const y = row.y - scrollTop;

      if (y + row.height < 0) {
        continue;
      }
      if (y > Draw.canvasHeight) {
        break;
      }

      // 折叠
      if (type === 'group') {
        const fixedCells = DataManager.headerCells.slice(2, DataManager.config.freeze + 1);
        const notFixedCells = DataManager.headerCells.slice(DataManager.config.freeze + 1);

        notFixedCells.forEach((cell: any) => {
          this.renderFunctionCell(cell, false, y, row);
        });
        fixedCells.forEach((cell: any) => {
          this.renderFunctionCell(cell, true, y, row);
        });

        const Collapse = new CollapseCell(
          {
            x: 0,
            y: y,

            width: this.getFixedWidth(),
            height: row.height,
          },
          this.moduleInstances,
          row as RowType<'group'>,
        );

        Collapse.render();
      }
      // 节点
      if (type === 'node') {
        const nowTemplate = DataManager.config.viewTemplate;
        // 属性条件规则
        const propCondition = nowTemplate?.temp_option?.propCondition || [];
        const ablePropCondition = propCondition.filter((p: any) => p.enable);
        const eligiblePropCondition = ablePropCondition.filter((p) =>
          filterChecker(p.condition)(row.node.metadata),
        );
        const map: any = {};
        eligiblePropCondition.forEach((p: any) => {
          p.results.forEach((i: any) => {
            if (i.type === 'property_color') {
              const { propIndex, propColor, propBg } = i;
              map[propIndex] = { propColor, propBg };
            }
          });
        });

        const node = new NodeRow(
          {
            x: 0 - scrollLeft,
            y: y,
            width: contentWidth,
            height: row.height,
          },
          this.moduleInstances,
          { ...row, propStyleMap: map } as any,
          false,
        );
        node.render();
      }
      // // addRow
      if (type === 'addRow') {
        const addRow = new AddRow(
          {
            x: 0,
            y: y,
            width: Math.max(contentWidth, Draw.canvasWidth),
            height: row.height,
          },
          this.moduleInstances,
          row as RowType<'addRow'>,
          false,
        );
        addRow.render();
      }

      // 统计
      if (type === 'statisticsRow') {
        const statisticsRow = new StatisticsRow(
          {
            x: 0,
            y: y,
            width: headerCells.slice(0, 2).reduce((sum, cell) => sum + cell.width, 0),
            height: row.height,
          },
          this.moduleInstances,
          row as RowType<'statisticsRow'>,
        );

        statisticsRow.render();
      }
    }
  }

  // 画线
  private renderLine() {
    const { VerticalScrollbar, Draw, DataManager } = this.moduleInstances;
    const styleMode = Draw.config.styleMode;
    const lineColor = styleMode === 'darkness' ? 'rgba(255,255,255,0.05)' : '#E4E4E4';
    const currentTop = VerticalScrollbar.getScrollTop();
    const list =
      this.config.mode === 'default' ? DataManager.virtualList : DataManager.fixVirtualList;

    list.forEach((row) => {
      const lineY = row.y - currentTop;
      Draw.line(
        [
          [0, lineY],
          [Draw.canvasWidth, lineY],
        ],
        lineColor,
      );
    });
  }

  private renderHeader() {
    const header = new Header(
      {
        x: 0,
        y: 0,
        width: this.moduleInstances.Draw.canvasWidth,
        height: this.headerHeight,
      },
      this.moduleInstances,
    );
    header.render();
  }

  private renderFooter() {
    const { headerCells } = this.moduleInstances.DataManager;
    const width = headerCells.slice(0, 2).reduce((sum, cell) => sum + cell.width, 0);
    const footer = new Footer(
      {
        x: 0,
        y: this.moduleInstances.Draw.canvasHeight - this.footerHeight,
        width: width,
        height: this.footerHeight,
      },
      this.moduleInstances,
    );
    footer.render();
  }
}
