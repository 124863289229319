import { useControllableValue, useMemoizedFn } from 'ahooks';
import type { RadioProps, SelectProps } from 'antd';
import { Checkbox, Radio, Space, Typography } from 'antd';
import type { CheckboxChangeEvent, CheckboxProps } from 'antd/lib/checkbox';
import cls from 'classnames';
import { defaultTo, intersection } from 'ramda';
import type { FC } from 'react';
import { useMemo, useState } from 'react';

import { SearchBar } from '@/components';
import { traverseTreeCreator } from '@/utils/tree';
import { toArray, toRecord } from '@/utils/utils';

import styles from './styles.module.less';

const defaultSearchFilter = (value: string, o: any) => {
  if (typeof o.label === 'string') {
    return o.label.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) >= 0;
  }
  return true;
};

type PiCheckBoxProp =
  | ({
      mode?: 'multiple';
    } & CheckboxProps)
  | ({ mode: 'single' } & RadioProps);

const PiCheckBox: FC<React.PropsWithChildren<PiCheckBoxProp>> = ({
  mode = 'multiple',
  ...rest
}) => {
  if (mode === 'multiple') return <Checkbox {...rest} />;
  return <Radio {...rest} />;
};

export type CheckboxSelectProp = Pick<SelectProps, 'options' | 'value' | 'onChange'> & {
  root?: boolean;
  allowSelectAll?: boolean;
  allowSearch?: boolean;
  searchPlaceholder?: string;
  mode?: 'multiple' | 'single';
  searchFilter?: (v: string, record: any) => boolean;
  searchValue?: string;
  onSearchChange?: (v: string) => void;
};

export const CheckboxSelect: FC<React.PropsWithChildren<CheckboxSelectProp>> = ({
  root = true,
  allowSelectAll = false,
  allowSearch = false,
  searchPlaceholder,
  options,
  mode = 'multiple',
  searchFilter = defaultSearchFilter,
  searchValue: _searchValue,
  onSearchChange,
  ...rest
}) => {
  const [value, onChange] = useControllableValue(rest);

  const valueMap = useMemo(() => {
    const v = toArray(value);
    return toRecord<string, Record<string, boolean>>((k) => ({ [k]: true }))(v);
  }, [value]);

  const checkChange = useMemoizedFn((e: CheckboxChangeEvent, option: any) => {
    if (typeof onChange !== 'function') return;
    const v: string[] = defaultTo([])(value);

    const optionValue = option.value;
    if (mode === 'multiple') {
      const result = e.target.checked ? [...v, optionValue] : v.filter((i) => i !== optionValue);

      onChange(result, option);
    } else if (mode === 'single') {
      onChange(optionValue, option);
    }
  });

  const allValue = useMemo(() => {
    if (!root) return [];
    const result: string[] = [];
    traverseTreeCreator(
      (o) => {
        if ('value' in o) {
          result.push(o.value);
        }
        return;
      },
      {
        childrenKeyName: 'options',
      },
    )(options || []);
    return result;
  }, [options, root]);

  const selectedAll = useMemo(() => {
    if (!(value?.length > 0)) return false;

    return intersection(allValue, value)?.length === allValue?.length;
  }, [allValue, value]);

  const selectedAllChange = useMemoizedFn((e: CheckboxChangeEvent) => {
    if (!e.target.checked) {
      onChange?.([], []);
    } else {
      onChange?.(allValue, []);
    }
  });

  const searchProps = _searchValue
    ? {
        value: _searchValue,
        onChange: onSearchChange,
      }
    : {};
  const [searchValue, setSearchValue] = useControllableValue<string>(searchProps);

  const filterdOptions = useMemo(() => {
    if (!root) return options;
    if (!searchValue) return options;

    return options
      ?.map((o) => {
        if ('options' in o && Array.isArray(o.options)) {
          return {
            ...o,
            options: o.options.filter((item: any) => searchFilter(searchValue, item)),
          };
        }
        return o;
      })
      .filter((o) => {
        if ('options' in o && o.options.length > 0) {
          return true;
        }
        return searchFilter(searchValue, o);
      });
  }, [options, root, searchFilter, searchValue]);

  return (
    <Space className={cls('full-w', root && styles.wrapper)} direction="vertical" size={6}>
      {root && allowSearch && (
        <SearchBar
          placeholder={searchPlaceholder}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
      )}
      <div>
        {root && mode === 'multiple' && allowSelectAll && (
          <PiCheckBox mode={mode} checked={selectedAll} onChange={selectedAllChange}>
            全选
          </PiCheckBox>
        )}
        {filterdOptions?.map((o) => {
          if ('options' in o && o.options.length) {
            return (
              <Space className="full-w" direction="vertical" key={o.label as string} size={0}>
                <div style={{ padding: '6px 10px' }}>
                  <Typography.Text style={{ color: '#B7BAC0' }}>{o.label}</Typography.Text>
                </div>
                <CheckboxSelect
                  mode={mode}
                  options={o.options as any[]}
                  value={value}
                  onChange={onChange}
                  root={false}
                />
              </Space>
            );
          }

          return (
            <PiCheckBox
              mode={mode}
              onChange={(e) => checkChange(e, o)}
              checked={!!o.value && valueMap[o.value]}
              key={o.value || (o.label as string)}
              disabled={o.disabled}
            >
              {o.label}
            </PiCheckBox>
          );
        }) || []}
      </div>
    </Space>
  );
};
