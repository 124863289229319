import type { PositionType } from '../components/_baseComponent';
import GridBaseComponent from '../components/_baseComponent';
import type { PiGridModules } from '../core';

class FixConditionMatchingAttr extends GridBaseComponent {
  public data: any;
  public type: string = 'fixConditionMatchingAttr';
  public captrue: boolean = false;
  public STYLE = {
    fontColor: '#316EF5',
  };
  constructor(position: PositionType, moduleInstances: PiGridModules, data: any) {
    super(position, moduleInstances);

    this.data = data;
  }

  public drawQuoteIcon(x: number, y: number, unicode: string) {
    const { Draw } = this.moduleInstances;
    const { ctx } = Draw;

    ctx.save();
    Draw.attr({
      fillStyle: this.STYLE.fontColor,
      textBaseline: 'top',
      textAlign: 'start',
      font: `${Draw.npx(14)}px iconfont`,
    });

    Draw.fillText(Draw.iconFont(unicode), x, y);
    ctx.restore();
  }

  public drawCount(text: string) {
    const { Draw } = this.moduleInstances;
    const { x, y, height, width } = this.position;
    Draw.attr({
      fillStyle: this.STYLE.fontColor,
      textBaseline: 'top',
      textAlign: 'start',
      font: `${Draw.npx(height)}px  sans-serif`,
    });
    Draw.fillText(text, x, y);
    // underline
    const underlineStart = [x, y + height];
    const underlineEnd = [x + width + 2, y + height];
    Draw.line([underlineStart, underlineEnd], this.STYLE.fontColor);
    return;
  }

  public drawTitle(text: string) {
    const { Draw } = this.moduleInstances;
    const { x, y, width } = this.position;

    Draw.attr({
      fillStyle: 'black',
      textBaseline: 'top',
      textAlign: 'start',
      font: `${Draw.npx(14)}px  sans-serif`,
    });

    Draw.fillText(Draw.fittingString(text, width - 16), x, y);
    this.drawQuoteIcon(x + width - 14, y, '&#xe70f;');
    return;
  }

  public click() {
    this.moduleInstances.DataManager.config.showQuoteNodes(this.data.propIndex, this.data.node);
  }

  public render() {
    const { type } = this.data;
    if (this.isHover) {
      this.moduleInstances.Render.setCursor('pointer');
    }
    if (type === 'conditionMatch_count') return this.drawCount(this.data.data);
    if (type === 'conditionMatch_title') return this.drawTitle(this.data.data);
  }
}

export default FixConditionMatchingAttr;
