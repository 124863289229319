import { PROP_TYPE } from '@linkpi/core';
import { displayCascadeValue, tempValueDisplay } from '@linkpi/core';
import { DatePicker, Modal } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';

import { useOrgTempMap, useOrgUserMap } from '@/hook';
import { useOrgConnection } from '@/hook';
import { useOrgDepartmentNodesMap } from '@/hook/useOrgStructure';
import request from '@/utils/request';

import AutomationLogModal from './components/AutomationLogModal';
import FormulaDetailModal from './components/FormulaDetailModal';
import QuoteModal from './components/QuoteModal';
import automationImg from './imgs/automation.png';

import styles from './styles.less';

interface IProps {
  id: string;
  closeModal: () => void;
  timeRange: Array<number>;
  orgId: string;
}

interface Tab {
  label: string;
  key: string;
}

interface DataType {
  q: string; //模板ID
  t: string; //操作类型
  ov: any; //历史值
  v: any; //修改值
  time: number; //操作时间
  ad: string[]; //操作人ID
  auto: string; //自动化ID
  i: number; // propIndex
  auto_mark: string;
}
const defaultUserAvatar = 'https://ljp-h5.oss-cn-hangzhou.aliyuncs.com/static/imgs/avatar.png';
// by zn 节点14天内历史修改
export default (props: IProps) => {
  const { id, closeModal, timeRange, orgId } = props;
  const { RangePicker } = DatePicker;

  const [nowTab, setNowTab] = useState('all');
  const [time, setTime] = useState<any>(timeRange);
  const [data, setData] = useState<DataType[]>([]);
  const tempMap = useOrgTempMap(orgId);
  const userMap = useOrgUserMap();
  const departmentMap = useOrgDepartmentNodesMap();
  const orgConnection = useOrgConnection(orgId);
  const [showAutomationLogModal, setShowAutomationLogModal] = useState(false);
  const [nowAutomationRecord, setNowAutomationRecord] = useState<DataType | null>(null);
  const [showFormulaDetailModal, setShowFormulaDetailModal] = useState(false);
  const [nowFormulaRecord, setNowFormulaRecord] = useState<DataType | null>(null);
  const [showQuoteModal, setShowQuoteModal] = useState(false);
  const [nowQuoteRecord, setNowQuoteRecord] = useState<DataType | null>(null);
  const init = async () => {
    const res: any = await request('/docapi/getNodeModifyLog', {
      method: 'POST',
      data: {
        org_id: orgId,
        node_id: id,
      },
    });
    const data: any[] = res.data || [];

    if (res.status === 'ok') setData(data.sort((a: any, b: any) => b.time - a.time) as any);
  };

  useEffect(() => {
    init();
  }, []);

  const getPropValue = (propConfig: any, propValue: any) => {
    if (propConfig.type === 'cascade')
      return displayCascadeValue({
        value: propValue,
        cascadeNodes: propConfig.cascade.nodes,
        multiple: propConfig.multiple,
        hideRoutes: propConfig.hideRoutes,
      });
    return tempValueDisplay({
      propConfig,
      propValue,
      userMap,
      tempMap,
      departmentMap,
    });
  };

  const changeTime = (v: any) => {
    setTime(v ? [v[0].valueOf(), v[1].valueOf()] : []);
  };

  const isTriggerAutomation = (record: DataType) => {
    const { auto, t } = record;

    return t === 'RunAutomation' && auto;
  };

  const getRangePickerValue = () => {
    if (Array.isArray(time) && time[0] && time[1]) return [moment(time[0]), moment(time[1])];

    return [];
  };

  const showLog = (record: DataType) => {
    setNowAutomationRecord(record);
    setShowAutomationLogModal(true);
  };
  const showQuoteDetail = (record: DataType) => {
    setNowQuoteRecord(record);
    setShowQuoteModal(true);
  };
  const showFormulaDetail = (record: DataType) => {
    setNowFormulaRecord(record);
    setShowFormulaDetailModal(true);
  };

  const renderPropChange = (ov: any, v: any, propConfig: any) => {
    if (propConfig.type === 'attachment') return 'attachment';

    return (
      <div style={{ display: 'flex', alignItems: 'center', color: '#242E41' }}>
        <div>{getPropValue(propConfig, ov)}</div>
        <i
          style={{ color: '#316EF5', margin: '0 4px' }}
          className="iconfont iconchangyong-xiayige"
        />
        <div>{getPropValue(propConfig, v)}</div>
      </div>
    );
  };

  const renderImg = (record: DataType) => {
    const { auto, ad } = record;
    if (auto)
      return (
        <div
          style={{
            backgroundImage: `url(${automationImg})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            width: 24,
            height: 24,
            borderRadius: 12,
          }}
        />
      );

    // user
    if (ad.length === 1) {
      const { avatar } = userMap[ad[0]];
      return (
        <div
          style={{
            backgroundImage: `url(${avatar || defaultUserAvatar})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            width: 24,
            height: 24,
            borderRadius: 12,
          }}
        />
      );
    }

    // many users
    return (
      <div style={{ display: 'flex' }}>
        {ad
          .filter((userId) => userMap[userId])
          .slice(0, 5)
          .map((userId, index) => (
            <div
              key={userId}
              style={{
                backgroundImage: `url(${userMap[userId].avatar || defaultUserAvatar})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                width: 24,
                height: 24,
                borderRadius: 12,
                position: 'relative',
                left: index === 0 ? 0 : -8,
              }}
            />
          ))}
      </div>
    );
  };

  const renderName = (record: DataType) => {
    const { auto, ad } = record;
    if (auto) return <div style={{ paddingLeft: 6, color: '#242E41' }}>自动化规则</div>;
    if (ad.length === 1)
      return <div style={{ paddingLeft: 6, color: '#242E41' }}>{userMap[ad[0]].nick_name}</div>;
    if (ad.length > 5)
      return <div style={{ paddingLeft: 6, color: '#242E41' }}>+{ad.length - 5}</div>;
  };
  const columns: any = [
    {
      title: '操作人',
      key: 'user',
      render: (record: DataType) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {renderImg(record)}
            {renderName(record)}
          </div>
        );
      },
      width: '12%',
    },
    {
      title: '操作',
      key: 'actionName',
      render: (record: DataType) => {
        const { t } = record;
        // 触发自动化
        if (isTriggerAutomation(record))
          return <span style={{ color: '#242E41' }}>触发自动化</span>;

        // 手动
        let actionName = '/';
        if (t === 'SetStatusIndex') actionName = '变更状态';
        if (t === 'InsertNode') actionName = '创建主题';
        if (t === 'SetTempProp') actionName = '变更属性值';
        if (t === 'SetStatusProp') actionName = '变更状态属性';
        if (t === 'SetTitle') actionName = '变更标题';
        if (t === 'MoveToRecycle') actionName = '删除主题';
        if (t === 'SetSysProp') actionName = '变更属性的可见权限'; //变更属性的编辑权限
        if (t === 'SetAcl') actionName = '变更主题权限';
        if (['MoveOut', 'MoveIn'].includes(t)) actionName = '移动路径';
        return <span style={{ color: '#242E41' }}>{actionName}</span>;
      },
      width: '16%',
    },
    {
      title: '详情',
      key: 'detail',
      render: (record: DataType) => {
        if (isTriggerAutomation(record) || record.t === 'InsertNode') return '';
        let { v, ov, q, i } = record;
        if (record.t === 'SetStatusIndex') {
          const s = (tempMap[q].task_status || [])[v] || {};
          const olds = (tempMap[q].task_status || [])[ov] || {};
          const name = s.name;
          const oldName = olds.name;
          return (
            <div style={{ display: 'flex', alignItems: 'center', color: '#242E41' }}>
              <div>{oldName}</div>
              <i
                style={{ color: '#316EF5', margin: '0 4px' }}
                className="iconfont iconchangyong-xiayige"
              />
              <div>{name}</div>
            </div>
          );
        }

        if (record.t === 'SetTitle') {
          return (
            <div style={{ display: 'flex', alignItems: 'center', color: '#242E41' }}>
              <div>{ov}</div>
              <i
                style={{ color: '#316EF5', margin: '0 4px' }}
                className="iconfont iconchangyong-xiayige"
              />
              <div>{v}</div>
            </div>
          );
        }

        if (record.t === 'SetStatusProp') {
          let statusPropName = '负责人';
          if (i === 0) {
            ov = ov ? userMap[ov].nick_name : '';
            v = v ? userMap[v].nick_name : '';
          }
          if (i === 1) {
            statusPropName = '参与者';
            ov = ov && Array.isArray(ov) ? ov.map((id) => userMap[id].nick_name).join('、') : '';
            v = v && Array.isArray(v) ? v.map((id) => userMap[id].nick_name).join('、') : '';
          }
          if (i === 2) {
            statusPropName = '开始时间';
            ov = ov ? moment(ov).format('YYYY/MM/DD HH:mm') : '';
            v = v ? moment(v).format('YYYY/MM/DD HH:mm') : '';
          }
          if (i === 3) {
            statusPropName = '结束时间';
            ov = ov ? moment(ov).format('YYYY/MM/DD HH:mm') : '';
            v = v ? moment(v).format('YYYY/MM/DD HH:mm') : '';
          }
          if (i === 4) {
            statusPropName = '备注';
          }
          return (
            <div style={{ display: 'flex', alignItems: 'center', color: '#242E41' }}>
              <div style={{ color: '#6B7A96' }}>{statusPropName}：</div>
              <div>{ov}</div>
              <i
                style={{ color: '#316EF5', margin: '0 4px' }}
                className="iconfont iconchangyong-xiayige"
              />
              <div>{v}</div>
            </div>
          );
        }

        if (record.t === 'SetTempProp') {
          const prop = (tempMap[q].prop || [])[i];
          if (!prop.type) return '';

          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div>
                <i
                  style={{ color: '#6B7A96', marginRight: 4 }}
                  className={`${PROP_TYPE[prop.type].icon} iconfont`}
                />
                <span style={{ color: '#6B7A96' }}>{prop.name}：</span>
              </div>
              {renderPropChange(ov, v, prop)}
            </div>
          );
        }
      },
      width: '44%',
    },
    {
      title: '操作时间',
      key: 'time',
      render: (record: DataType) => {
        const { time: t, q, i, auto, auto_mark } = record;
        // const prop = (tempMap[q].prop || [])[i];
        const v = t ? moment(t).format('YYYY/MM/DD HH:mm') : '';
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <span style={{ color: '#242E41', paddingRight: 42 }}>{v}</span>
            {auto && auto_mark && (
              <div onClick={() => showLog(record)} style={{ color: '#316EF5', cursor: 'pointer' }}>
                查看日志
              </div>
            )}
            {/* {prop?.type === 'formula' && (
              <div
                onClick={() => showFormulaDetail(record)}
                style={{ color: '#316EF5', cursor: 'pointer' }}
              >
                查看详情
              </div>
            )}
            {prop?.type === 'quote' && (
              <div
                onClick={() => showQuoteDetail(record)}
                style={{ color: '#316EF5', cursor: 'pointer' }}
              >
                查看详情
              </div>
            )} */}
          </div>
        );
      },
      width: '28%',
    },
  ];

  const tabs: Array<Tab> = [
    { label: '全部', key: 'all' },
    { label: '手动操作', key: '1' },
    { label: '执行自动化', key: '2' },
    { label: '触发自动化', key: '3' },
  ];

  const filterData = () => {
    return data.filter((i) => {
      const { time: actionTime } = i;
      let res = true;
      if (time[0] && time[1]) {
        res = actionTime >= time[0] && actionTime <= time[1];
      }

      if (nowTab === 'all') return res;

      if (nowTab === '1') return res && !i.auto;

      if (nowTab === '2') return res && i.auto && i.t !== 'RunAutomation';
      if (nowTab === '3') return res && i.auto && i.t === 'RunAutomation';
    });
  };

  const changeTab = (k: string) => setNowTab(k);

  return (
    <Modal
      open
      title={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ marginRight: 8, color: '#242D3F', fontSize: 18, fontWeight: 500 }}>
            动态
          </div>
          <div style={{ color: '#767C88' }}>可查看14天内的修改历史</div>
        </div>
      }
      onCancel={closeModal}
      footer={null}
      bodyStyle={{ padding: 0, overflow: 'hidden' }}
      style={{
        top: 40,
        bottom: 40,
        overflow: 'hidden',
        height: 'auto',
      }}
      width={1024}
    >
      <div
        style={{
          maxHeight: 'calc(100vh - 160px)',
          overflowY: 'auto',
          padding: '20px 24px',
        }}
      >
        <div className={styles.tabs}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {tabs.map((t) => (
              <div
                onClick={() => changeTab(t.key)}
                className={styles.tab + (nowTab === t.key ? ' ' + styles.active : '')}
                key={t.label}
              >
                {t.label}
              </div>
            ))}
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ color: '#767C88', marginRight: 8 }}>操作时间</div>
            <RangePicker
              allowClear
              onChange={changeTime}
              value={getRangePickerValue() as any}
              format="YYYY-MM-DD HH:mm"
              showTime
              style={{ width: 380 }}
            />
          </div>
        </div>

        <div className={styles.table}>
          <div className={styles.header}>
            {columns.map((i: any) => (
              <div className={styles.column} style={{ width: i.width }} key={i.key}>
                {i.title}
              </div>
            ))}
          </div>
          {filterData().map((i) => (
            <div key={i.id} className={styles.item}>
              {columns.map((j: any) => (
                <div
                  style={{
                    width: j.width,
                    display: 'flex',
                    alignItems: 'center',
                    paddingLeft: 28,
                  }}
                  key={j.key}
                >
                  {j.render(i)}
                </div>
              ))}
            </div>
          ))}
        </div>
        {showAutomationLogModal && (
          <AutomationLogModal
            orgConnection={orgConnection}
            orgId={orgId}
            record={nowAutomationRecord as DataType}
            closeModal={() => setShowAutomationLogModal(false)}
          />
        )}
        {showFormulaDetailModal && (
          <FormulaDetailModal
            record={nowFormulaRecord as DataType}
            closeModal={() => setShowFormulaDetailModal(false)}
          />
        )}
        {showQuoteModal && (
          <QuoteModal
            orgConnection={orgConnection}
            orgId={orgId}
            record={nowQuoteRecord as DataType}
            closeModal={() => setShowQuoteModal(false)}
          />
        )}
      </div>
    </Modal>
  );
};
