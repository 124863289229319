import { useControllableValue } from 'ahooks';
import type { RadioGroupProps } from 'antd';
import { Image, Space, Typography } from 'antd';
import cs from 'classnames';
import type { FunctionComponent, ReactElement } from 'react';

import { RegularIcon } from '@/components/IconFont';

import styles from './styles.module.less';

export type CardItemProps = {
  image?: string;
  icon?: string;
  value?: string;
  checked?: boolean;
  name: string | ReactElement;
  desc?: string | ReactElement;
  cardStyle?: {
    height?: number;
    width?: number;
  };
  onChange: () => void;
  setShareType: (type: any) => void;
  shareType: string;
  usingType: string;
  isLock: boolean;
};

const CardItem: FunctionComponent<CardItemProps> = (props) => {
  if (props.value === 'privilegeUp') return null;
  const use = () => {
    if (props.usingType === props.value) return;
    props.onChange();
  };
  return (
    <div
      className={cs([styles.card, props.shareType === props.value && styles.checked])}
      style={props.cardStyle}
      onClick={() => props.setShareType(props.value)}
    >
      {props.usingType === props.value && <div className={styles.using}>使用中</div>}
      <div
        onClick={() => use()}
        className={props.usingType === props.value ? styles.disabled : styles.use}
        style={props.isLock ? { display: 'none' } : {}}
      >
        使用
      </div>
      <div className={styles.cardContent}>
        <Space align="center" direction="vertical" size={10}>
          {props.image && <Image src={props.image} width={72} preview={false} />}
          {props.icon && (
            <div className={styles.icon}>
              <RegularIcon type={props.icon} size={24} />
            </div>
          )}
          <Typography.Text strong>{props.name}</Typography.Text>
        </Space>
        {props.desc && <div className={styles.cardDesc}>{props.desc}</div>}
      </div>
    </div>
  );
};

export type CardSelectOption = {
  value: number | string;
} & Omit<CardItemProps, 'checked' | 'cardStyle' | 'onChange'>;

type Props = {
  options: CardSelectOption[];
  layout: 'space' | 'flex';
  setShareType: (type: any) => void;
  shareType: string;
  usingType: string;
  isLock: boolean;
} & Pick<RadioGroupProps, 'value' | 'defaultValue' | 'onChange'> &
  Pick<CardItemProps, 'cardStyle'>;

const CardSelect: FunctionComponent<Props> = ({ options = [], layout = 'space', ...props }) => {
  const [value, onChange] = useControllableValue(props);
  const content = options.map((option) => (
    <CardItem
      {...option}
      cardStyle={props.cardStyle}
      key={option.value}
      checked={value === option.value}
      setShareType={props.setShareType}
      shareType={props.shareType}
      usingType={props.usingType}
      isLock={props.isLock}
      onChange={() => {
        onChange(option.value);
      }}
    />
  ));

  if (layout === 'flex') {
    return <div className={styles.flexContent}>{content}</div>;
  }

  return <Space size={56}>{content}</Space>;
};

export default CardSelect;
