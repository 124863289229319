/* eslint-disable react-refresh/only-export-components */
import { SearchOutlined } from '@ant-design/icons';
import { antdModalV5, create, show, useModal } from '@ebay/nice-modal-react';
import type { ViewList } from '@linkpi/core';
import { assertExists } from '@linkpi/utils';
import { useMemoizedFn } from 'ahooks';
import { Input, Modal } from 'antd';
import clsx from 'clsx';
import type { FC } from 'react';
import { useMemo, useState } from 'react';

import { ScrollArea } from '@/components/ui/scroll-area';
import { useCurrentOrgId, useOrgConnection } from '@/hook';
import { useOrgViewListByManagerRequest } from '@/hook/useOrgViewListRequest';

import { getViewIcon } from './utils';

import styles from './Modal.less';

const EMPTY_ARRAY: ViewList = [];

type PublicViewSelectModalProps = {
  initialValue: string;
};

const PublicViewSelectModal: FC<PublicViewSelectModalProps> = ({
  initialValue,
}) => {
  const orgId = useCurrentOrgId();
  const { data = EMPTY_ARRAY } = useOrgViewListByManagerRequest({
    org_id: orgId,
    staleTime: 60 * 1000 * 5,
  });
  const modal = useModal();
  const modalProps = antdModalV5(modal);
  const [search, setSearch] = useState('');

  const showViewList = useMemo(() => {
    const lowSearch = search.toLowerCase();
    return data.filter((item) => {
      if (!item.node_id) return false;

      if (lowSearch) {
        return item.view_name.toLowerCase().includes(lowSearch);
      }

      return true;
    });
  }, [data, search]);

  const orgConnection = useOrgConnection();

  const getViewPath = useMemoizedFn((nodeId: string) => {
    assertExists(orgConnection);
    const path = orgConnection.nodeManager.findChildrenPathDoc(nodeId);
    return [...(path || []).map((x) => x.title)].join(' > ');
  });

  const onViewClick = useMemoizedFn((viewId: string) => {
    modal.resolve(viewId);
    modalProps.onOk();
  });

  return (
    <Modal {...modalProps} footer={null} title="选择视图" centered width={600}>
      <Input
        style={{ backgroundColor: '#F8F9FB', flex: 'auto' }}
        size="large"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        placeholder="搜索视图"
        bordered={false}
        prefix={<SearchOutlined size={16} color="#BFC6D2" />}
      />
      {showViewList.length ? (
        <ScrollArea className={'h-[500px]'}>
          {showViewList.map((x) => (
            <div
              className={styles.viewItem}
              key={x.view_id}
              onClick={() => onViewClick(x.view_id)}
            >
              <div
                className={styles.viewItemIcon}
                style={{
                  backgroundColor: getViewIcon(x.view_type).color + '30',
                }}
              >
                <i
                  className={getViewIcon(x.view_type).icon}
                  style={{
                    fontSize: 12,
                    color: getViewIcon(x.view_type).color,
                  }}
                />
              </div>
              <div className={clsx(styles.viewItemName, 'text-omit')}>
                {x.view_name}
              </div>
              <div className={clsx(styles.viewItemPath, 'text-omit')}>
                {getViewPath(x.node_id)}
              </div>
            </div>
          ))}
        </ScrollArea>
      ) : (
        <div className={styles.viewListPlaceholder}>暂无可选视图</div>
      )}
    </Modal>
  );
};

const PublicViewSelectNiceModal = create(PublicViewSelectModal);

export const showPublicViewSelectModal = (
  options: PublicViewSelectModalProps,
) => {
  return show(PublicViewSelectNiceModal, options) as Promise<string>;
};
