import type { PositionType } from '../components/_baseComponent';
import GridBaseComponent from '../components/_baseComponent';
import type { PiGridModules } from '../core';
import type { RowType } from '../modules/dataManager';
import FixNodeCol from './fixNodeCol';

class FixNodeRow extends GridBaseComponent {
  public data: RowType<'node'>;
  public type: string = 'nodeRow';
  public STYLE = {
    hoverBg: '#ffffff',
    selectBg: '#f0f6ff',
  };

  constructor(position: PositionType, moduleInstances: PiGridModules, data: any, fixed: boolean) {
    super(position, moduleInstances, fixed);
    this.data = data;
  }

  public click() {
    this.moduleInstances.DataManager.changeCollapseChildNodes([this.data.node.id]);
  }

  public render() {
    const { DataManager, Draw } = this.moduleInstances;
    const { y, height, width } = this.position;
    const styleMode = Draw.config.styleMode;

    const cells =
      this.data.type === 'node'
        ? DataManager.parentNodeCells
        : DataManager.childNodeCellsMap[this.data.areaIndex];

    Draw.fillRect(0, y, width, height, styleMode === 'darkness' ? 'rgba(0,0,0,0)' : '#fff');

    cells.forEach((unit: any, index: number) => {
      this.renderNodeCol(unit, false, index);
    });

    // hover的时候画icon
    // if (this.isHover && this.data.type === 'node') {
    //   const openIcon = new OpenIcon(
    //     {
    //       height: openIconConfig.height,
    //       width: openIconConfig.width,
    //       x: 20 + checkboxConfig.size,
    //       y: y + (height - openIconConfig.height) / 2,
    //     },
    //     this.moduleInstances,
    //     this.data,
    //   );

    //   openIcon.render();
    // }
  }

  public renderNodeCol(unit: any, fixed: boolean, index: number) {
    const currentLeft = fixed ? 0 : this.moduleInstances.HorizontalScrollbar.getScrollLeft();
    const {
      moduleInstances: { DataManager, Draw },
      data: { handleNowTemplateColConfig },
    } = this;
    const nowTemplateId = DataManager.config.viewTemplate.template_id;

    const lineColor = Draw.config.styleMode === 'darkness' ? 'rgba(255,255,255,0.05)' : '#E4E4E4';

    let isNowTemplate = false;
    if (handleNowTemplateColConfig && unit.theme === nowTemplateId) {
      if (handleNowTemplateColConfig.nowChildNodeIndex !== 0) {
        const rowPosition = {
          x: unit.x - currentLeft,
          y: this.position.y,
          height: this.position.height,
          width: unit.cellWidth,
        };
        Draw.drawComponentBorder(rowPosition, ['left', 'right'], lineColor);
        Draw.fillRect(
          rowPosition.x,
          rowPosition.y,
          rowPosition.width,
          rowPosition.height,
          unit.backgroundColor,
        );
        return;
      }

      isNowTemplate = true;
    }

    const height = isNowTemplate ? handleNowTemplateColConfig?.height || 0 : this.position.height;
    const node = isNowTemplate ? this.data.parentNode : this.data.node;
    const nodeCol = new FixNodeCol(
      {
        x: unit.x - currentLeft,
        y: this.position.y,
        width: unit.cellWidth,
        height,
      },
      this.moduleInstances,
      unit,
      { node, cellIndex: index, nodeType: this.data.type, parentNode: this.data.parentNode },
    );

    if (isNowTemplate) {
      window.nowTemplateComponentsInChildArea.push(nodeCol);
      return;
    }
    nodeCol.render();
  }
}

export default FixNodeRow;
