import { getPiSDK } from '@linkpi/sdk';

export const initPiSDKAuth = () => {
  const piSDK = getPiSDK();
  const ad = window.localStorage.getItem('ad');
  const token = window.localStorage.getItem('token');

  if (ad && token) {
    piSDK.setAuthToken({
      ad,
      token,
    });
  }
};
