import type { ApiResponse } from '@linkpi/core';

import type { configType } from './core';
import Core from './core';

export default class PiGantt {
  public destroy: () => void;
  public setData: (groupData: any) => void;
  public setTempConfig: (tempConfig: ApiResponse.CurrentUser.TemplateInfo) => void;
  public getCurrentIds: () => string[];
  public updateCurViewData: (v: any) => void;
  public setIsDark: (isDark: boolean) => void;
  public ganttCollapse: (v: boolean) => void;

  constructor(config: configType) {
    window.__PI__GANTT = {
      components: [],
      mousePosition: { x: 0, y: 0 },
    };
    // instance gantt core
    let editor = new Core(config);

    this.setData = editor.setData.bind(editor);
    this.setTempConfig = editor.setTempConfig.bind(editor);
    this.getCurrentIds = editor.getCurrentIds.bind(editor);
    this.updateCurViewData = editor.updateCurViewData.bind(editor);
    this.setIsDark = editor.setIsDark.bind(editor);
    this.ganttCollapse = editor.ganttCollapse.bind(editor);

    this.destroy = () => {
      editor.destroy();
      window.__PI__GANTT = {
        components: [],
        mousePosition: { x: 0, y: 0 },
      };
      // @ts-ignore
      editor = null;
    };
  }
}
