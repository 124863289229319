import type { PositionType } from '../components/_baseComponent';
import GridBaseComponent from '../components/_baseComponent';
import type { PiGridModules } from '../core';

class OpenQuoteListIcon extends GridBaseComponent {
  public data: any;
  public type: string = 'openQuoteListIcon';
  public fontSize: number = 14;
  public captrue: boolean = false;

  constructor(
    position: PositionType,
    moduleInstances: PiGridModules,
    data: any,
    fontSize?: number,
  ) {
    super(position, moduleInstances);

    this.data = data;
    if (fontSize) this.fontSize = fontSize;
  }

  public drawQuoteIcon(x: number, y: number, unicode: string) {
    const { Draw } = this.moduleInstances;
    const { ctx } = Draw;
    const styleMode = Draw.config.styleMode;

    ctx.save();
    Draw.attr({
      fillStyle: styleMode === 'darkness' ? 'rgba(62,112,223,0.5)' : '#316EF5',
      textBaseline: 'top',
      textAlign: this.data.contentAlign === 'right' ? 'end' : 'start',
      font: `${Draw.npx(this.fontSize)}px iconfont`,
    });

    Draw.fillText(Draw.iconFont(unicode), x, y);
    ctx.restore();
  }

  public click() {
    this.moduleInstances.DataManager.config.showQuoteNodes(this.data.propIndex, this.data.node);
  }

  public render() {
    const { Render } = this.moduleInstances;

    this.drawQuoteIcon(this.position.x, this.position.y, '&#xe70f;');
    if (this.isHover) {
      Render.setCursor('pointer');
    }
  }
}

export default OpenQuoteListIcon;
