import { SearchOutlined } from '@ant-design/icons';
import type { InputRef } from 'antd';
import { Button, Input, Modal } from 'antd';
import cls from 'classnames';
import { debounce } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';

import AddrList from '@/components/LocationSelect/AddrList';
import type { MapEditorHandleType } from '@/components/LocationSelect/MapEditor';
import MapEditor from '@/components/LocationSelect/MapEditor';
import type { GaodeAddrDataType } from '@/components/LocationSelect/utils';
import {
  converGaodeDataToSysLocationData,
  converSysLocationDataToGaodeData,
  isEqualPoint,
} from '@/components/LocationSelect/utils';

import styles from '@/components/LocationSelect/styles.less';

const PositioningFilter = (props: {
  conditionPosition: SysLocationItemType | null;
  visible: boolean;
  setVisible: (v: boolean) => void;
  onSubmit: (p: SysLocationItemType | null) => void;
}) => {
  const { conditionPosition, visible, setVisible, onSubmit } = props;
  const [addrListLoading, setAddrListLoading] = useState(false);

  // 当前节点的sysLocation字段
  const [curAddrData, setCurAddrData] = useState<SysLocationItemType | null>(null);
  // 需要辅助显示的其他属性
  const [markers, setMarkers] = useState<SysLocationItemType[]>([]);
  // 搜索关键字
  const [searchText, setSearchText] = useState('');
  // 选择列表
  const [addrList, setAddrList] = useState<GaodeAddrDataType[]>([]);

  const searchIptRef = useRef<InputRef>(null);
  const mapEditorRef = useRef<MapEditorHandleType>(null);

  useEffect(() => {
    if (visible) {
      /** 地址信息 */
      const locationData = conditionPosition;
      if (locationData) {
        /**
         * 有数据时：
         * 1. 地图中心移动到数据位置(还是直接通过经纬度，创建地图实例)
         * 2. 通过获取列表
         */
        setCurAddrData(locationData);
        console.log('locationData', locationData);
      }
    }
  }, [visible, conditionPosition]);

  const onCancel = () => {
    setAddrListLoading(false);
    setCurAddrData(null);
    setMarkers([]);
    setSearchText('');
    setAddrList([]);

    const ipt = searchIptRef.current?.input as any;
    if (ipt) {
      ipt.value = '';
    }

    setVisible(false);
  };

  const onMapEditorLoad = async () => {
    if (!mapEditorRef.current) return;
    if (curAddrData) {
      // 有数据时：1. 通过获取列表
      const point: [number, number] = [curAddrData.lng, curAddrData.lat];
      setAddrListLoading(true);
      setAddrList([]);
      const res = (await mapEditorRef.current?.searchNearBy(point)) as GaodeAddrDataType[];
      // 判断第一个的名称和数据是否一致，不一致的话就把第一个push进去，确保列表里一定有
      const hasMatchIndex = res.findIndex((x) => isEqualPoint(curAddrData, x));
      if (~hasMatchIndex) {
        const firstMatch = res.splice(hasMatchIndex, 1)[0];
        res.unshift(firstMatch);
      } else {
        res.unshift(converSysLocationDataToGaodeData(curAddrData));
      }

      setAddrListLoading(false);
      setAddrList(res);
    } else {
      /**
       * 没有数据时：
       * 1. 获取中心点
       * 2. 通过中心点，获取列表
       * 3. 设置为第一个
       */
      setAddrListLoading(true);
      setAddrList([]);
      const curMapCenter = mapEditorRef.current?.getCenter() as {
        lng: number;
        lat: number;
      };
      const res = (await mapEditorRef.current?.searchNearBy([
        curMapCenter.lng,
        curMapCenter.lat,
      ])) as GaodeAddrDataType[];
      setAddrListLoading(false);
      setAddrList(res);

      if (res.length) {
        const addrData = res[0];
        setCurAddrData(converGaodeDataToSysLocationData(addrData));
      }
    }
  };
  const onMove = async (data: any) => {
    // 移动时，清空搜索框
    // 通过当前中心点 获取周围，并定位到搜索结果的第一个
    setAddrListLoading(true);
    setAddrList([]);
    setSearchText('');
    const ipt = searchIptRef.current?.input as any;
    if (ipt) {
      ipt.value = '';
    }
    const curMapCenter = mapEditorRef.current?.getCenter() as {
      lng: number;
      lat: number;
    };
    const res = (await mapEditorRef.current?.searchNearBy([
      curMapCenter.lng,
      curMapCenter.lat,
    ])) as GaodeAddrDataType[];
    setAddrListLoading(false);
    setAddrList(res);

    if (res.length) {
      const addrData = res[0];
      setCurAddrData(converGaodeDataToSysLocationData(addrData));
    }
  };

  const onSearch = async (text: string) => {
    setSearchText(text);
    setAddrListLoading(true);
    setAddrList([]);

    if (!text) {
      // 没有搜索关键字时，return
      setAddrListLoading(false);
      return;
    } else {
      // 有关键字时，搜索关键字
      // 定位到搜索结果的第一个
      const res = (await mapEditorRef.current?.search(text)) as GaodeAddrDataType[];
      setAddrList(res);
      if (res.length) {
        const addrData = res[0];
        setCurAddrData(converGaodeDataToSysLocationData(addrData));

        mapEditorRef.current?.move([addrData.location.lng, addrData.location.lat], true);
      }
    }

    setAddrListLoading(false);
  };
  const debounceSearch = useCallback(debounce(onSearch, 500), []);

  const onAddrClick = (addr: GaodeAddrDataType) => {
    setCurAddrData(converGaodeDataToSysLocationData(addr));

    mapEditorRef.current?.move([addr.location.lng, addr.location.lat], true);
  };
  const onLoadNextPage = async () => {
    // 加载下一页
    if (!mapEditorRef.current) return;
    const nextList = await mapEditorRef.current.loadNextPage();
    setAddrList((list) => [...list, ...nextList]);
  };

  const onSave = async () => {
    onSubmit(curAddrData);
    onCancel();
  };

  const modalRender = () => {
    return (
      <div className={styles.locationSelect}>
        <div className={styles.search}>
          <div className={styles.searchInput}>
            <Input
              className={styles.input}
              ref={searchIptRef}
              prefix={
                <SearchOutlined
                  style={{
                    fontSize: 16,
                    color: '#BFC6D2',
                  }}
                />
              }
              bordered={false}
              placeholder="搜索位置"
              allowClear
              onChange={(e) => debounceSearch(e.target.value)}
            />
          </div>
          <AddrList
            keyword={searchText}
            value={curAddrData}
            dataSource={addrList}
            onClick={onAddrClick}
            onLoadNextPage={onLoadNextPage}
            loading={addrListLoading}
          />
          <div className={styles.saveBtns}>
            <Button onClick={onCancel}>取消</Button>
            <Button onClick={onSave} type="primary" style={{ marginLeft: 16 }}>
              确认
            </Button>
          </div>
        </div>
        <div className={styles.map}>
          <MapEditor
            ref={mapEditorRef}
            onLoad={onMapEditorLoad}
            onMove={onMove}
            markers={markers}
            initCenter={curAddrData ? [curAddrData.lng, curAddrData.lat] : undefined}
            disableMove={false}
          />
        </div>
      </div>
    );
  };

  return (
    <Modal
      className={cls('ant-modal-content', styles.locationSelectModal)}
      open={visible}
      onCancel={onCancel}
      modalRender={modalRender}
      width={900}
      style={{ maxWidth: '70vw' }}
      destroyOnClose
    />
  );
};

export default PositioningFilter;
