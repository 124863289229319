import type { ApiResponse } from '@linkpi/core';
import { DEFAULT_AVATAR, STATUS_ICON } from '@linkpi/core';
import { getDateBeforePresent, propIsNull } from '@linkpi/core';
import { getPropHandledValue, tempValueDisplay } from '@linkpi/core';
import { getQuoteOriginProp } from '@linkpi/core';
import dayjs from 'dayjs';

import { btnConfig } from '@/pages/space/components/PageFix/btnConfig';
import {
  convertDeltaToPureText,
  filterFlowSetting,
  getAuxiliaryPropValue,
  getCurrentFlowSetting,
  getCustomBtn,
  getQuoteOptionVisible,
  getQuoteOrMatchingInfo,
  getTemplateProp,
  hexToRgba,
  isNodeDisabled,
  tableFixCellPadding,
  tableFixLineGap,
  transformFontSizeToAttachmentSize,
} from '@/utils/utils';

import CustomBtn from '../commonComponents/btn';
import FixConditionMatchingAttr from '../commonComponents/fixConditionMatchingAttr';
import OpenQuoteListIcon from '../commonComponents/openQuoteListIcon';
import QuoteItem from '../commonComponents/quoteItem';
import type { PositionType } from '../components/_baseComponent';
import GridBaseComponent from '../components/_baseComponent';
import CheckboxCell from '../components/checkboxCell';
import type { PiGridModules } from '../core';
import { checkboxConfig } from '../helper/config';
import { getQuoteData, getUserData } from '../helper/getNodeProp';
import { getUser } from '../helper/utils';
import EditAttrGroup from './editAttrGroup';
import FixAttachment from './fixAttachment';
import FixAttachmentUpload from './fixAttachmentUpload';
import FixLink from './fixLink';
import FixStatus from './fixStatus';
import FixUser from './fixUser';
import ShowUnitMoreDetail from './showUnitMoreDetail';

class FixNodeCol extends GridBaseComponent {
  public type: string = 'fixNodeCol';
  public padding: Array<number> = tableFixCellPadding;
  public STYLE = {
    selectBg: '#f0f6ff',
    hoverBg: '#fafafa',
    borderColor: '#EBEDF0',
  };

  private unit: any;
  private data: any;
  private node: PiNode;

  constructor(position: PositionType, moduleInstances: PiGridModules, unit: any, data: any) {
    super(position, moduleInstances);

    this.node = data.node;
    this.data = data;
    this.unit = unit;
  }

  public sysOrStatusAttr(index: string) {
    return [
      'title',
      'sysTag',
      'status',
      'createTime',
      'creator',
      'mainbody',
      'treeLevelId',
      'statusProp_common_0',
      'statusProp_common_1',
      'statusProp_common_2',
      'statusProp_common_3',
      'statusProp_common_4',
    ].includes(index);
  }

  public drawText(text: string, color: string, x: number, y: number, fontSize?: number) {
    const { Draw } = this.moduleInstances;

    Draw.attr({
      fillStyle: color,
      textBaseline: 'top',
      textAlign: 'start',
      font: `${Draw.npx(fontSize || 14)}px  sans-serif`,
    });

    Draw.fillText(text, x, y);
    return Draw.measureTextWidth(text);
  }

  public drawStatusIcon(x: number, y: number, color: string, unicode: string, fontSize?: number) {
    const { Draw } = this.moduleInstances;
    const { ctx } = Draw;

    ctx.save();
    Draw.attr({
      fillStyle: color,
      textBaseline: 'top',
      textAlign: 'start',
      font: `${Draw.npx(fontSize || 14)}px iconfont`,
    });

    Draw.fillText(Draw.iconFont(unicode), x, y);
    ctx.restore();
  }

  // 是否渲染 checkbox
  private canRenderCheckbox() {
    const { checkedNodesDetail } = this.moduleInstances.DataManager;
    const { nodeType, parentNode } = this.data;

    if (checkedNodesDetail === true) return true;

    // 渲染父节点
    if (nodeType === 'node') {
      return checkedNodesDetail === 'parent';
    }

    // 子节点
    return parentNode.id === checkedNodesDetail.parentId;
  }

  public render() {
    const { Draw } = this.moduleInstances;
    const { x, y, height, width } = this.position;
    const node = this.node;
    const { grid, theme, defaultLineNum } = this.unit;
    const content = this.unit.attrGroupContent || this.unit.content;
    let { backgroundColor } = this.unit;
    if (backgroundColor === 'white' && Draw.config.styleMode === 'darkness')
      backgroundColor = 'rgba(0,0,0,0)';

    const lineColor = Draw.config.styleMode === 'darkness' ? 'rgba(255,255,255,0.05)' : '#E4E4E4';

    Draw.fillRect(x, y, width, height, backgroundColor);

    // 格子border
    if (this.data.nodeType !== 'node')
      Draw.drawComponentBorder(this.position, ['left', 'right', 'top', 'bottom'], lineColor);

    // checkbox
    if (this.canRenderCheckbox() && this.data.cellIndex === 0) {
      const { size } = checkboxConfig;
      const checkBoxCell = new CheckboxCell(
        {
          x: x + 1,
          y: y + 1,
          width: size,
          height: size,
        },
        this.moduleInstances,
        { type: 'nodeCheckbox', node: { node: this.node } },
        () => {
          const id = this.node.id;
          this.moduleInstances.DataManager.config.onCheck(id);
        },
      );

      checkBoxCell.render(this.isHover);
    }

    // advance 总宽度
    const { chunks, maxLineWidth } = this.parseContent(content, theme, node, defaultLineNum);
    const position = this.positionByGrid(grid, maxLineWidth);

    // 当前格子渲染的起始坐标
    const colPos = {
      x: x + position.x, // 随着op的渲染一直增加
      y: y + position.y, // 换行时增加
      originX: x + position.x, // 换行后应该将x置回起始的x
      lineIndex: 0,
    };

    chunks.forEach((chunk) => {
      const { type } = chunk;
      if (type === 'text') this.renderText(colPos, chunk);
      if (type === 'attr') this.renderAttr(colPos, chunk, width);
      if (type === 'link') this.renderLink(colPos, chunk, node);
      if (type === 'btn') {
        // console.log('btn chunk', chunk);
        this.renderBtn(colPos, { ...chunk, unitBackgroundColor: backgroundColor }, node);
      }
    });

    // 存在属性组的时候，允许查看更多
    if (this.unit.attrGroupContent) {
      const showUnitMoreDetail = new ShowUnitMoreDetail(
        {
          x: x + width - 14,
          y: y + height - 14,
          width: 14,
          height: 14,
        },
        this.moduleInstances,
        { node, unit: this.unit, unitPosition: this.position },
      );
      if (
        this.unit.defaultLineNum &&
        this.unit.defaultLineNum < Object.keys(this.unit.lineConfig).length
      )
        showUnitMoreDetail.render();
    }
  }

  public renderText(colPos: any, chunk: any) {
    const words = chunk.text.replaceAll('\n', '&').split('');
    const { Draw } = this.moduleInstances;
    const fontSize = Number(chunk.fontSize || 14);

    words.forEach((word: string, index: number) => {
      if (word === '&') {
        // \n
        colPos.y += this.unit.lineConfig?.[colPos.lineIndex]?.lineHeight || 14 + tableFixLineGap;
        colPos.lineIndex += 1;
        colPos.x = colPos.originX;
      } else {
        const nowLineHeight =
          this.unit.lineConfig?.[colPos.lineIndex]?.lineHeight || 14 + tableFixLineGap;

        this.drawText(
          word,
          chunk.color,
          colPos.x,
          colPos.y + (nowLineHeight - fontSize) / 2,
          fontSize,
        );
        colPos.x += Draw.measureTextWidth(word);

        // 编辑属性组的属性
        if (chunk.groupProps && index === words.length - 1 && chunk.allowEdit) {
          const editAttrGroup = new EditAttrGroup(
            {
              x: colPos.x,
              y: colPos.y + (nowLineHeight - fontSize) / 2,
              width: fontSize,
              height: fontSize,
            },
            this.moduleInstances,
            {
              node: this.node,
              groupProps: chunk.groupProps,
              unitPosition: this.position,
            },
          );
          editAttrGroup.render();
          colPos.x += 6 + fontSize;
        }
      }
    });
  }

  public renderConditionMatchingAttr = (data: any) => {
    const { Draw } = this.moduleInstances;
    const { ctx } = Draw;
    if (data.type === 'conditionMatch_title') {
      const fixLink = new FixLink(
        {
          x: data.x,
          y: data.y,
          width: data.chunk.width,
          height: data.height,
        },
        this.moduleInstances,
        data.chunk,
        this.node,
        this.data.parentNode,
      );

      // 属性链接
      if (data.chunk.config) {
        fixLink.render();
      } else {
        ctx.save();
        Draw.attr({
          fillStyle: data.chunk.color,
          textBaseline: 'top',
          textAlign: 'start',
          font: `${Draw.npx(data.height)}px  sans-serif`,
        });

        Draw.fillText(data.data, data.x, data.y);
        ctx.restore();
      }

      const openQuoteListIcon = new OpenQuoteListIcon(
        {
          x: data.x + data.width - data.height,
          y: data.y,
          height: Number(data.height),
          width: Number(data.height),
        },
        this.moduleInstances,
        { ...data, node: this.node },
      );
      openQuoteListIcon.render();
      return;
    }
    const fixConditionMatchingAttr = new FixConditionMatchingAttr(
      {
        x: data.x,
        y: data.y,
        height: Number(data.height),
        width: Draw.measureTextWidth(data.data),
      },
      this.moduleInstances,
      { ...data, node: this.node },
    );

    fixConditionMatchingAttr.render();
  };

  public drawImg(src: string, x: number, y: number) {
    const { Draw, ImageLoader } = this.moduleInstances;

    const img = ImageLoader.loadImage(src || DEFAULT_AVATAR, 'avatar');
    Draw.drawRoundImg(30, x, y, 30, 30, img);
  }

  public renderAttr(colPos: any, chunk: any, colWidth: number) {
    const nowLineHeight =
      this.unit.lineConfig?.[colPos.lineIndex]?.lineHeight || 14 + tableFixLineGap;
    const fontSize = Number(chunk.fontSize || 14);

    const { x, y } = window.__PI__GRID.mousePosition;
    const { Draw, Render } = this.moduleInstances;

    if (
      x > colPos.x &&
      x < colPos.x + chunk.width &&
      y > colPos.y &&
      y < colPos.y + nowLineHeight &&
      chunk.auxiliaryPropValue
    ) {
      Render.setCursor('pointer');
      const rectStartY = colPos.y + 35;
      // center
      const rectStartX = chunk.width >= 200 ? colPos.x : colPos.x - 100 + chunk.width / 2;
      Render.addToTriggerList(() => {
        Draw.fillRoundRect(
          {
            x: rectStartX,
            y: rectStartY,
            width: 200,
            height: 35,
          },
          5,
          '#4a4b4f',
        );

        Draw.drawText(
          Draw.newFittingString(chunk.auxiliaryPropValue, 180),
          'white',
          'middle',
          'left',
          13,
          rectStartX + 10,
          rectStartY + 35 / 2,
        );
      });
    }

    if (chunk.conditionMatchingConfig) {
      const extraWidth =
        chunk.conditionMatchingConfig.type === 'conditionMatch_title' ? fontSize + 2 : 0;
      this.renderConditionMatchingAttr({
        type: chunk.conditionMatchingConfig.type,
        propIndex: chunk.conditionMatchingConfig.propIndex,
        data: chunk.text,
        // size
        width: chunk.width + extraWidth,
        x: colPos.x,
        y: colPos.y + (nowLineHeight - fontSize) / 2,
        height: fontSize,
        chunk,
      });
      colPos.x = colPos.x + chunk.width + extraWidth;
      return;
    }

    if (chunk.allowToOpenNodesPayload) {
      const { type, quoteValue, prop, propIndex } = chunk.allowToOpenNodesPayload;
      const { Draw } = this.moduleInstances;
      const v = getQuoteOrMatchingInfo(prop);
      let gap = 0;
      let propValues: any = this.node.tempInfo.prop[Number(propIndex)];
      propValues = Array.isArray(propValues) ? propValues : [propValues];

      (quoteValue || []).forEach((str: any, index: number) => {
        if (!str) return;
        const originValue = propValues[index];

        const quoteItem = new QuoteItem(
          {
            height: fontSize,
            width: Draw.measureTextWidth(str),
            x: colPos.x + gap,
            y: colPos.y + (nowLineHeight - fontSize) / 2,
          },
          this.moduleInstances,
          {
            propIndex,
            prop,
            value: str,
            type,
            node: this.node,
            originValue,
            colorAndBold: { color: chunk.color },
          },
          fontSize,
        );
        quoteItem.render();
        gap = gap + Draw.measureTextWidth(str) + 2;
      });

      // 展示引用列表
      if (v === 1 || v === 2) {
        // 查看引用列表
        const openQuoteListIcon = new OpenQuoteListIcon(
          {
            x: colPos.x + gap,
            y: colPos.y + (nowLineHeight - fontSize) / 2,
            height: fontSize,
            width: fontSize,
          },
          this.moduleInstances,
          { node: this.node, propIndex },
          fontSize,
        );
        openQuoteListIcon.render();
      }

      colPos.x += chunk.width;
      return;
    }

    if (chunk.showDateBeforePresent) {
      const { time, dateBeforePresent } = chunk.showDateBeforePresent;
      const { Draw } = this.moduleInstances;
      this.drawText(
        time,
        chunk.color,
        colPos.x,
        colPos.y + (nowLineHeight - fontSize) / 2,
        fontSize,
      );

      Draw.drawIcon(
        colPos.x + 10 + Draw.measureTextWidth(time),
        colPos.y + (nowLineHeight - fontSize) / 2,
        hexToRgba(dateBeforePresent.color, 0.5).rgba,
        '&#xe731;',
        fontSize,
        'start',
      );

      this.drawText(
        dateBeforePresent.toString,
        hexToRgba(dateBeforePresent.color, 0.5).rgba,
        colPos.x + 10 + Draw.measureTextWidth(time) + fontSize + 5,
        colPos.y + (nowLineHeight - fontSize) / 2,
        fontSize,
      );

      colPos.x += chunk.width;
      return;
    }

    if (chunk.text !== undefined) {
      // attrLink
      if (chunk.config && chunk.config.linkChecked) {
        const fixLink = new FixLink(
          {
            x: colPos.x,
            y: colPos.y + (nowLineHeight - fontSize) / 2,
            width: chunk.width,
            height: Number(fontSize),
          },
          this.moduleInstances,
          chunk,
          this.node,
          this.data.parentNode,
        );

        fixLink.render();
        colPos.x += chunk.width;
        return;
      }
      this.drawText(
        chunk.text,
        chunk.color,
        colPos.x,
        colPos.y + (nowLineHeight - fontSize) / 2,
        fontSize,
      );
      colPos.x += chunk.width;
      return;
    }
    if ('attachment' in chunk) {
      const srcAry = chunk.attachment;

      // 是否允许上传
      const allowUpload = chunk.allowUpload && !chunk.isQuoteAttachment;

      let advance = colPos.x;
      const attachmentSize = transformFontSizeToAttachmentSize(fontSize);
      srcAry.forEach((file: any, i: number) => {
        const fixAttachment = new FixAttachment(
          {
            height: chunk.attachmentHeight,
            width: attachmentSize,
            x: advance,
            y: colPos.y + (nowLineHeight - attachmentSize) / 2,
          },
          this.moduleInstances,
          file,
          {
            propConfig: getTemplateProp(
              this.unit.theme,
              chunk.propIndex,
              Render.config.templateList,
            ),
          },
        );
        fixAttachment.render();
        advance += attachmentSize + 10;
      });
      colPos.x += chunk.width;

      if (!allowUpload) return;
      // 附件上传icon
      const fixAttachmentUpload = new FixAttachmentUpload(
        {
          height: chunk.attachmentHeight,
          width: attachmentSize,
          x: advance,
          y: colPos.y + (nowLineHeight - attachmentSize) / 2,
        },
        this.moduleInstances,
        this.node,
        { ...chunk, theme: this.unit.theme },
      );
      fixAttachmentUpload.render();

      return;
    }

    // status
    if (chunk.status) {
      const fixStatus = new FixStatus(
        {
          x: colPos.x,
          y: colPos.y + (nowLineHeight - fontSize) / 2,
          width: colWidth,
          height: Number(fontSize),
        },
        this.moduleInstances,
        { ...chunk, fontSize, colWidth },
        this.node,
      );
      fixStatus.render();

      colPos.x += chunk.width;
      return;
    }

    // users
    const { users } = chunk;

    if (!users) return;
    // 渲染人员
    const fixUser = new FixUser(
      {
        x: colPos.x,
        y: colPos.y + (nowLineHeight - 30) / 2,
        width: chunk.width,
        height: 30,
      },
      this.moduleInstances,
      chunk,
      this.node,
    );

    fixUser.render();
    colPos.x += chunk.width;
  }

  public renderLink(colPos: any, chunk: any, node: PiNode) {
    const nowLineHeight =
      this.unit.lineConfig?.[colPos.lineIndex]?.lineHeight || 14 + tableFixLineGap;
    const { Draw } = this.moduleInstances;
    const fontSize = chunk.fontSize || 14;
    if (chunk.config.linkChecked) {
      const fixLink = new FixLink(
        {
          x: colPos.x,
          y: colPos.y + (nowLineHeight - fontSize) / 2,
          width: Draw.measureTextWidth(chunk.text),
          height: Number(fontSize),
        },
        this.moduleInstances,
        chunk,
        node,
        this.data.parentNode,
      );

      fixLink.render();
    } else {
      this.drawText(
        chunk.text,
        chunk.color,
        colPos.x,
        colPos.y + (nowLineHeight - fontSize) / 2,
        fontSize,
      );
    }
    colPos.x += chunk.width;
  }

  public renderBtn(colPos: any, chunk: any, node: PiNode) {
    const nowLineHeight =
      this.unit.lineConfig?.[colPos.lineIndex]?.lineHeight || 14 + tableFixLineGap;
    // console.log('⚠️', { chunk, nowLineHeight, colPos });
    const fixBtn = new CustomBtn(
      {
        x: colPos.x,
        y: colPos.y + (nowLineHeight - chunk.btnHeight) / 2,
        width: chunk.btnWidth,
        height: chunk.btnHeight,
      },
      this.moduleInstances,
      chunk,
      node,
    );

    fixBtn.render();
    colPos.x += chunk.width;
  }

  public positionByGrid(grid: string, maxLineWidth: number) {
    const { height, width } = this.position;
    const position = { x: this.padding[3], y: this.padding[0] };
    const innerHeight = this.unit.innerHeight || tableFixCellPadding[0] + tableFixCellPadding[2];

    switch (grid) {
      case 'top-center':
        position.x = (width - maxLineWidth) / 2;
        position.y = this.padding[0];
        break;
      case 'top-right':
        position.x = width - maxLineWidth - this.padding[1];
        position.y = this.padding[0];
        break;
      case 'center-left':
        position.x = this.padding[3];
        position.y = (height - innerHeight) / 2;
        break;
      case 'center-center':
        position.x = (width - maxLineWidth) / 2;
        position.y = (height - innerHeight) / 2;
        break;
      case 'center-right':
        position.x = width - maxLineWidth - this.padding[1];
        position.y = (height - innerHeight) / 2;
        break;
      case 'bottom-left':
        position.x = this.padding[3];
        position.y = height - innerHeight - this.padding[2];
        break;
      case 'bottom-center':
        position.x = (width - maxLineWidth) / 2;
        position.y = height - innerHeight - this.padding[2];
        break;
      case 'bottom-right':
        position.x = width - maxLineWidth - this.padding[1];
        position.y = height - innerHeight - this.padding[2];
        break;
      default:
        break;
    }

    if (maxLineWidth >= width) position.x = this.padding[3];

    return position;
  }

  // 将属性组中没有值的属性去掉不显示， 后面的op往上顶即可
  public parseOps = (ops: any[]) => {
    const newOps = [];
    const len = ops.length;
    const node = this.node;

    const getValue = (index: number, node: any) => {
      const { tempInfo = {} } = node || {};
      return tempInfo.prop[index];
    };

    for (let i = 0; i < len; i++) {
      const { insert, attributes } = ops[i];

      if (typeof insert === 'string' && attributes && attributes.attrGroupConfig) {
        if (
          propIsNull(getValue(attributes.attrGroupConfig.index, node)) &&
          !attributes.attrGroupConfig.showNopeValueProps
        )
          continue;
      }

      if (
        Object.prototype.toString.call(insert) === '[object Object]' &&
        insert.ThemeAttrSpan &&
        insert.ThemeAttrSpan.attrGroupConfig
      ) {
        if (
          propIsNull(getValue(insert.ThemeAttrSpan.attrGroupConfig.index, node)) &&
          !insert.ThemeAttrSpan.attrGroupConfig.showNopeValueProps
        )
          continue;
      }
      newOps.push(ops[i]);
    }
    return newOps;
  };

  // 区块存在行数的限制，所以要处理一下ops 将多余的行去掉
  public getNewChunks = (chunks: any[], lineNum: number) => {
    const newChunks: any[] = [];

    let advance = 0; // 每发现一行就要加1 直到等于限制的行数

    chunks.forEach((chunk) => {
      if (advance === lineNum) return;
      const text = String(chunk.text);

      if (!text) {
        // 不存在文本，说明是属性之类的，直接放进chunk
        newChunks.push(chunk);
        return;
      }

      // 文本中是否存在换行的情况
      const existWrap = text.includes('\n');

      // 如果不存在换行 也直接放进 chunk
      if (existWrap === false) return newChunks.push(chunk);

      // 存在换行，就要进行多段的处理了
      const lines = text.split('\n');
      lines.forEach((lineText: string, index: number) => {
        if (advance === lineNum) return;
        if (index !== lines.length - 1) advance += 1;
        const suf = index === lines.length - 1 ? '' : '\n';
        newChunks.push({ ...chunk, text: lineText + suf });
      });
    });

    return newChunks;
  };

  public parseContent(content: any, theme: string, node: any, defaultLineNum: any) {
    const { Draw, DataManager } = this.moduleInstances;
    const { ops = [] } = content || {};

    let advance = 0;
    let maxLineWidth = 0;
    let lineWidth = 0;
    let chunks: any[] = [];
    const newOps = this.parseOps(ops);

    newOps.forEach((op: any) => {
      const { attributes = {}, insert = {} } = op;
      const size = attributes.size || '14px';
      const fontSize = size.substr(0, 2);
      this.drawText('', 'black', 0, 0, fontSize);

      if (typeof insert === 'string') {
        const themeLink = attributes.ThemeLink;
        const textWidth = Draw.measureTextWidth(insert);
        const payload: any = { width: advance, text: insert, fontSize };
        advance += textWidth;

        const words = insert.replaceAll('\n', '&').split('');

        words.forEach((word: string) => {
          if (word === '&') {
            // \n
            maxLineWidth = Math.max(lineWidth, maxLineWidth);
            lineWidth = 0;
          } else {
            lineWidth += Draw.measureTextWidth(word);
          }
        });

        // 链接
        if (themeLink) {
          payload.color = attributes.color || 'black';
          payload.type = 'link';
          payload.width = textWidth;
          payload.config = JSON.parse(themeLink);
        } else {
          payload.color = attributes.color || 'black';
          payload.type = 'text';
        }

        if (attributes && attributes.attrGroupConfig && attributes.attrGroupConfig.groupProps) {
          payload.groupProps = attributes.attrGroupConfig.groupProps;
          payload.allowEdit = attributes.attrGroupConfig.allowEdit;
        }

        chunks.push(payload);
        return;
      }

      // 主题属性
      const themeAttr = insert.ThemeAttrSpan;
      if (themeAttr) {
        const template = DataManager.templateMap[theme];
        const {
          index,
          fontColor,
          fontSize,
          themeAttrLink,
          statusStyle,
          attrValueEmptyWidth,
          showAttrValueEmpty,
        } = themeAttr;
        if (themeAttr.templateId && themeAttr.templateId !== 'undefined') {
          const prop = getTemplateProp(
            themeAttr.templateId,
            index,
            this.moduleInstances.DataManager.config.templateList,
          );
          if (!prop.type) return;
        }
        this.drawText('', 'black', 0, 0, fontSize);

        const { value, propType, conditionMatchingConfig, allowToOpenNodesPayload } =
          this.sysOrStatusAttr(index)
            ? this.getSysOrStatusValue(template, index, node, statusStyle)
            : this.getNodePropValue(template, Number(index), node);
        const payload: any = { type: 'attr', color: fontColor, width: 0, fontSize };

        if (!this.sysOrStatusAttr(index)) {
          const auxiliaryPropValue = getAuxiliaryPropValue(template.prop[index], template, node);
          if (auxiliaryPropValue && auxiliaryPropValue.value) {
            const { value, position } = auxiliaryPropValue;
            // @ts-ignore
            const d = tempValueDisplay({
              propConfig: template.prop[index],
              propValue: node.tempInfo.prop[index],
            });
            payload.auxiliaryPropValue = position > 0 ? `${value}-${d}` : `${d}-${value}`;
          }
        }

        if (conditionMatchingConfig) payload.conditionMatchingConfig = conditionMatchingConfig;
        if (allowToOpenNodesPayload) payload.allowToOpenNodesPayload = allowToOpenNodesPayload;
        if (themeAttrLink) payload.config = JSON.parse(themeAttrLink);
        if (
          [
            'text',
            'str',
            'number',
            'currency',
            'datetime',
            'date',
            'enum',
            'tag',
            'formula',
            'quote',
            'positioning',
            'cascade',
            'auto_inc',
            'address',
            'department',
          ].includes(propType)
        ) {
          // 属性空位占位逻辑
          let attrValueWidth = Draw.measureTextWidth(value);
          if (showAttrValueEmpty === 't') {
            attrValueWidth = Number(attrValueEmptyWidth);
          }
          payload.width = attrValueWidth;
          payload.text = value || '';
        }

        if (propType === 'date-showBeforePresent') {
          payload.showDateBeforePresent = value;
          payload.width =
            Draw.measureTextWidth(value.time) +
            10 +
            Number(fontSize) +
            5 +
            Draw.measureTextWidth(value.dateBeforePresent.toString) +
            2;
        }

        if (propType === 'status') {
          const { statusName } = value;

          payload.width = Draw.measureTextWidth(statusName) + 18;
          payload.status = value;
        }

        if (propType === 'allowToOpenNodes') {
          let { quoteValue, prop } = allowToOpenNodesPayload;
          const v = getQuoteOrMatchingInfo(prop);
          quoteValue = quoteValue || [];
          let w = 0;
          quoteValue.forEach((s) => {
            if (s) {
              w = w + Draw.measureTextWidth(s) + 2;
            }
          });
          if (v === 1 || v === 2) w += Number(fontSize);

          payload.width = w;
          payload.allowToOpenNodesPayload = allowToOpenNodesPayload;
        }

        if (propType === 'buttonStatus') {
          const { statusName, buttons } = value;
          let w = Draw.measureTextWidth(statusName) + Number(fontSize) + 2;
          buttons.forEach((btn: any) => {
            w = w + 12 + Draw.measureTextWidth(btn.name) + 6;
          });

          payload.width = w;
          payload.status = value;
        }

        if (propType === 'user') {
          payload.width =
            value.reduce((w: number, user: any) => {
              const { nick_name } = user;

              w =
                w +
                30 /* imgWidth */ +
                5 /* gap */ +
                Draw.measureTextWidth(nick_name) +
                5 /* gap */;
              return w;
            }, 0) - 5;
          if (showAttrValueEmpty === 't') {
            payload.width = Number(attrValueEmptyWidth);
          }
          payload.users = value;
        }

        if (propType === 'attachment' || propType === 'quote-attachment') {
          const allowUpload = themeAttr.allowUpload === 'true' && propType !== 'quote-attachment';
          const uploadNum = allowUpload ? 1 : 0;
          const _sys_attach = (node.prop || {})._sys_attach || {};
          const srcAry = _sys_attach[index] || [];
          const attachmentSize = transformFontSizeToAttachmentSize(fontSize);
          const attachmentHeight = attachmentSize;
          const attachmentWidth = (srcAry.length + uploadNum) * (10 + attachmentSize) - 10; // gap + width

          payload.attachment = srcAry.map((src: string, i: number) => ({
            src,
            fileName: (value || [])[i] || '未知文件名',
          }));
          payload.attachmentHeight = attachmentHeight;
          payload.attachmentWidth = attachmentWidth;
          payload.width = attachmentWidth;
          payload.propIndex = index;
          payload.isQuoteAttachment = propType === 'quote-attachment';
          payload.allowUpload = themeAttr.allowUpload === 'true';

          if (showAttrValueEmpty === 't') {
            payload.width = Number(attrValueEmptyWidth);
          }
        }

        lineWidth += payload.width;
        chunks.push(payload);
      }

      // btn
      const btn = insert.CustomBtn;
      if (btn) {
        let config = JSON.parse(btn);
        if (config.templateId) {
          config = getCustomBtn(
            config.templateId,
            config.btnId,
            this.moduleInstances.DataManager.config.templateList,
          );
          if (!config) return;
        }
        const { size, text, pictureUrl, icon, hideWebText } = config;

        const payload: any = { width: advance, config, type: 'btn', btnHeight: 0, btnWidth: 0 };

        const { padding, height } = btnConfig.rectangle[size];
        payload.btnWidth = 2 * padding;
        if (!hideWebText) payload.btnWidth += Draw.measureTextWidth(text);
        if (icon) payload.btnWidth += hideWebText ? 22 : 24;
        if (pictureUrl) payload.btnWidth += hideWebText ? 22 : 24;
        payload.btnHeight = height;

        payload.width = payload.btnWidth;
        lineWidth += payload.width;
        chunks.push(payload);
      }
    });

    if (defaultLineNum) chunks = this.getNewChunks(chunks, defaultLineNum);
    return { chunks, maxLineWidth };
  }

  private getCommonStatusPropValue(
    template: ApiResponse.CurrentUser.TemplateInfo,
    node: PiNode,
    index: number,
  ) {
    const status = template.task_status?.[node.tempInfo.status];
    const config = status ? status.prop[index] : null;
    return config && config.display && config.type !== 'quote'
      ? node.tempInfo.statusProp[index]
      : null;
  }

  public getSysOrStatusValue(
    template: ApiResponse.CurrentUser.TemplateInfo,
    index: string,
    node: any,
    statusStyle?: string,
  ) {
    const { DataManager } = this.moduleInstances;
    const { treeLevelIdMap } = DataManager;

    if (index === 'title') return { value: node.title || '无标题', propType: 'text' };
    if (index === 'sysTag') {
      const tags =
        node.prop._sys_tag && typeof node.prop._sys_tag === 'object'
          ? Object.keys(node.prop._sys_tag)
          : [];
      return { value: tags.join('、'), propType: 'text' };
    }
    if (index === 'createTime') {
      const createTime = node.createTime ? dayjs(node.createTime).format('YYYY-MM-DD HH:mm') : '';
      return { value: createTime, propType: 'datetime' };
    }

    if (index === 'treeLevelId') {
      return { value: treeLevelIdMap[node.id] || '无', propType: 'text' };
    }

    if (index === 'creator') {
      return {
        value: [getUser(node.prop._sys_creator, DataManager.config.userMap)],
        propType: 'user',
      };
    }

    if (index === 'mainbody') {
      return {
        value: convertDeltaToPureText(node.metadata.content),
        propType: 'text',
      };
    }

    // 状态
    if (index === 'status') {
      const status = node.tempInfo.status;
      const task_status = template?.task_status || [];
      const statusInfo = task_status[status];

      if (statusInfo && !statusInfo.delete) {
        const iconInfo = STATUS_ICON[statusInfo.icon as keyof typeof STATUS_ICON];
        if (iconInfo) {
          // 按钮样式
          if (statusStyle === 'button') {
            const f = this.node.template.task_status[this.node.tempInfo.status].flowSetting || [];
            const c = {
              userid: DataManager.config.userInfo.account_id,
            };
            return {
              propType: 'buttonStatus',
              value: {
                statusName: statusInfo.name,
                statusIcon: {
                  unicode: iconInfo.unicode,
                  color: iconInfo.color,
                },
                buttons: f.length
                  ? filterFlowSetting(this.node, DataManager.config.orgInfo, c).filter(
                      (f) => !isNodeDisabled(c, this.node),
                    )
                  : getCurrentFlowSetting(this.node, DataManager.config.orgInfo, c),
                button: true,
              },
            };
          }
          return {
            value: {
              statusName: statusInfo.name,
              statusIcon: {
                unicode: iconInfo.unicode,
                color: iconInfo.color,
              },
            },
            propType: 'status',
          };
        }
      }
      return { value: '', propType: 'text' };
    }

    if (index === 'statusProp_common_0') {
      let data = this.getCommonStatusPropValue(template, node, 0);
      if (data === null) return { value: '', propType: 'text' };
      data = Array.isArray(data) ? data : [data];
      return {
        value: data.filter((x: any) => x).map((x: any) => getUser(x, DataManager.config.userMap)),
        propType: 'user',
      };
    }
    if (index === 'statusProp_common_1') {
      let data = this.getCommonStatusPropValue(template, node, 1);
      if (data === null) return { value: '', propType: 'text' };
      data = Array.isArray(data) ? data : [data];
      return {
        value: data.filter((x: any) => x).map((x: any) => getUser(x, DataManager.config.userMap)),
        propType: 'user',
      };
    }
    if (index === 'statusProp_common_2') {
      const data = this.getCommonStatusPropValue(template, node, 2);
      const createTime = data ? dayjs(data).format('YYYY-MM-DD HH:mm') : '';
      return { value: createTime, propType: 'datetime' };
    }
    if (index === 'statusProp_common_3') {
      const data = this.getCommonStatusPropValue(template, node, 3);
      const createTime = data ? dayjs(data).format('YYYY-MM-DD HH:mm') : '';
      return { value: createTime, propType: 'datetime' };
    }
    if (index === 'statusProp_common_4') {
      return {
        value: this.getCommonStatusPropValue(template, node, 4) || '无备注',
        propType: 'text',
      };
    }

    return { value: '', propType: 'text' };
  }

  public getNodePropValue(
    template: ApiResponse.CurrentUser.TemplateInfo,
    index: number, // propIndex
    node: any,
  ) {
    const { DataManager } = this.moduleInstances;

    const propConfig = template.prop[index]; // prop

    const { tempInfo = {} } = node || {};
    let data = tempInfo.prop[index]; // 未处理的值

    // 引用 选值匹配 允许查看主题
    const quoteOptionVisible = getQuoteOptionVisible(propConfig, DataManager.templateMap);
    if (quoteOptionVisible === 3) {
      // 引用
      const originProp = getQuoteOriginProp(propConfig, DataManager.templateMap);
      const quoteValue = getPropHandledValue(
        originProp,
        index,
        data,
        DataManager.config.userMap,
        node.prop._sys_cascade,
      );
      return {
        propType: 'allowToOpenNodes',
        allowToOpenNodesPayload: { quoteValue, type: 'quote', prop: propConfig, propIndex: index },
      };
    }

    if (quoteOptionVisible === 1 || quoteOptionVisible === 2) {
      // 匹配
      const matchingValue = Array.isArray(data) ? data : [data];
      return {
        propType: 'allowToOpenNodes',
        allowToOpenNodesPayload: {
          quoteValue: matchingValue,
          type: 'matching',
          prop: propConfig,
          propIndex: index,
        },
      };
    }

    const v = getQuoteOrMatchingInfo(propConfig);
    if (v) {
      // 说明这个属性是引用 或者 匹配
      return {
        value: tempValueDisplay({
          propConfig,
          propValue: data,
          propIndex: index,
          tempMap: DataManager.templateMap,
          userMap: DataManager.config.userMap,
          sysCascade: node.prop._sys_cascade,
          departmentMap: DataManager.config.departmentMapRef.current,
        }),
        propType: propConfig.type,
        conditionMatchingConfig: {
          propIndex: index,
          type: v === 3 ? 'conditionMatch_count' : 'conditionMatch_title',
        },
      };
    }

    const res = { value: data, propType: propConfig.type };
    if (propConfig.type === 'user') {
      res.value = getUserData(node, propConfig, data, DataManager.config.userMap);
    }

    if (propConfig.type === 'quote') {
      const quoteData = getQuoteData(
        node,
        propConfig,
        data,
        DataManager.config.userMap,
        DataManager.templateMap,
        index,
        DataManager.config.departmentMapRef.current,
      );
      if (quoteData.type === 'quote-attachment') {
        res.propType = 'quote-attachment';
        res.value = quoteData.data;
      } else {
        res.value = quoteData;
      }
    }

    if (['str', 'text'].includes(propConfig.type)) {
      // ...
    }

    if (['date', 'datetime'].includes(propConfig.type)) {
      const format = propConfig.dateFormat || 'YYYY-MM-DD HH:mm';
      const time = data ? dayjs(data).format(format) : '';
      const dateBeforePresent = getDateBeforePresent({ prop: propConfig, value: data });
      res.value = time;
      if (dateBeforePresent.display) {
        res.propType = 'date-showBeforePresent';
        res.value = {
          time,
          dateBeforePresent,
        };
      }
    }

    if (['tag', 'enum'].includes(propConfig.type)) {
      if (propIsNull(data)) data = '';
      res.value = data instanceof Array ? data.join('、') : data;
    }

    if (propConfig.type === 'currency') {
      const d = Number(data);
      data = !isNaN(d) ? d.toFixed(propConfig.extend.precision) : '';
      res.value = data;
    }

    if (propConfig.type === 'number') {
      res.value = tempValueDisplay({
        propConfig,
        propValue: data,
        userMap: {},
        tempMap: {},
        departmentMap: {},
      });
    }

    if (propConfig.type === 'formula' || propConfig.type === 'department') {
      res.value = tempValueDisplay({
        propConfig,
        propValue: data,
        userMap: {},
        tempMap: {},
        departmentMap: DataManager.config.departmentMapRef.current,
      });
    }

    if (propConfig.type === 'attachment') {
      res.value = data;
    }

    if (propConfig.type === 'positioning') {
      res.value = Array.isArray(data) ? data[0] : data;
    }

    if (propConfig.type === 'cascade') {
      const _sys_cascade = (node.prop || {})._sys_cascade || {};
      const data = _sys_cascade[index] || [];
      res.value = data
        .map((i: any) => {
          if (Array.isArray(i)) {
            if (propConfig.hideRoutes) return i[i.length - 1];
            return i.filter((j: any) => j).join('/');
          }
          return i;
        })
        .filter((j: any) => j)
        .join('、');
    }

    return res;
  }
}

export default FixNodeCol;
