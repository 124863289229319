import { useEffect, useRef, useState } from 'react';

const defaultOption = {
  step: () => Math.floor(Math.random() * 10),
};

export const useProgress = () => {
  const [status, setStatus] = useState(false);
  const [percent, setPercent] = useState(0);

  const interval = useRef(0);
  const percentRef = useRef(0);

  const startHandle = () => {
    clearInterval(interval.current);
    setPercent(0);
    setStatus(true);
    percentRef.current = 0;

    interval.current = window.setInterval(() => {
      const newPercent = percentRef.current + defaultOption.step();
      percentRef.current = newPercent;
      setPercent(newPercent >= 100 ? 99 : newPercent);
    }, 500);
  };
  const endHandle = () => {
    clearInterval(interval.current);

    setPercent(100);
    setStatus(false);
  };

  useEffect(() => {
    return clearInterval(interval.current);
  }, []);

  return [percent, status, startHandle, endHandle] as const;
};
