import type { CurrentUser,CustomButton as CButton } from '@linkpi/core';
import { useMemoizedFn } from 'ahooks';
import { intersection } from 'ramda';
import type { FC } from 'react';
import { useMemo, useState } from 'react';

import CustomButton from '@/components/CustomButton';
import { useComponentId, useOrgInfo } from '@/hook';
import {
  triggerCustomButton,
  TriggerCustomButtonNiceModal,
} from '@/pages/home/components/TriggerCustomButton';
import { isCustomButtonForbiddenClick } from '@/utils/utils';

import Styles from './index.less';

export const ModelCustomButtons: FC<{
  data: CurrentUser.CustomButton[];
  node: PiNode;
  isDrafts: boolean;
  grid?: CButton['grid'];
  readOnly?: boolean;
}> = ({ data, node, isDrafts, grid = 'left', readOnly }) => {
  const customButtonTriggerId = useComponentId();

  const [buttonLoadingMap, setButtonLoadingMap] = useState(() =>
    data.reduce<Record<string, boolean>>(
      (p, d) => ({ ...p, [d.id]: false }),
      {},
    ),
  );

  const [orgInfo] = useOrgInfo();

  const customButtonClick = useMemoizedFn(
    async (config: CurrentUser.CustomButton, forbiddenClick?: boolean) => {
      if (forbiddenClick || buttonLoadingMap[config.id]) return;

      const loading = buttonLoadingMap[config.id];

      switch (config.opType) {
        case 'automationTrigger': {
          setButtonLoadingMap((prev) => ({ ...prev, [config.id]: true }));

          await triggerCustomButton(
            {
              config,
              node,
              loading,
              force: true,
            },
            customButtonTriggerId,
          );

          setButtonLoadingMap((prev) => ({ ...prev, [config.id]: false }));

          break;
        }
        default: {
          await triggerCustomButton(
            {
              config,
              node,
              loading,
              force: true,
            },
            customButtonTriggerId,
          );
        }
      }
    },
  );

  const groupList = useMemo(() => {
    const list = orgInfo?.groupList || [];
    if (orgInfo?.role === 3 || orgInfo?.role === 1) {
      list.push('-2');
    }
    return list;
  }, [orgInfo?.groupList, orgInfo?.role]);

  return (
    <div
      className={Styles['modal-custom-button-wrapper']}
      style={{ justifyContent: grid }}
    >
      {data.map((config) => {
        if (!config) return null;
        if (config.hiddenWhenAdded && isDrafts) return null;

        const forbiddenClick = isCustomButtonForbiddenClick(config, node);
        if (forbiddenClick && config.hiddenByDisableCondition) return null;

        // 按钮分组判断
        if (config.groups && !config.groups?.includes('-1')) {
          if (
            config.groups?.length &&
            intersection(groupList, config.groups).length === 0
          )
            return null;
        }

        return (
          <div
            key={config.id}
            className={Styles['custom-button-wrapper']}
            onClick={() =>
              !readOnly && customButtonClick(config, forbiddenClick)
            }
          >
            <CustomButton
              loading={buttonLoadingMap[config.id]}
              config={config}
              forbiddenClick={forbiddenClick}
            />
          </div>
        );
      })}
      {/* NOTE NiceModal 嵌套死循环 */}
      {/* @ts-ignore */}
      <TriggerCustomButtonNiceModal id={customButtonTriggerId} />
    </div>
  );
};
