import type { ViewList } from '@linkpi/core';
import { useMemoizedFn } from 'ahooks';
import type { SelectProps } from 'antd';
import { Select } from 'antd';
import type { FC } from 'react';
import { useEffect, useMemo } from 'react';

import { useCurrentOrgId } from '@/hook';
import { useOrgViewListByManagerRequest } from '@/hook/useOrgViewListRequest';

import { showPublicViewSelectModal } from './Modal';

const EMPTY_ARRAY: ViewList = [];

export const PublicViewSelect: FC<React.PropsWithChildren<SelectProps>> = ({
  autoFocus,
  value,
  defaultValue,
  ...rest
}) => {
  const orgId = useCurrentOrgId();
  const { data = EMPTY_ARRAY } = useOrgViewListByManagerRequest({
    org_id: orgId,
    staleTime: 60 * 1000 * 5,
  });

  const options = useMemo(() => {
    return data.map((i) => ({
      label: i.view_name,
      value: i.view_id,
    }));
  }, [data]);

  const showModal = useMemoizedFn(() => {
    return (
      showPublicViewSelectModal({ initialValue: defaultValue })
        .then((res) => {
          rest.onChange?.(res, options);
        })
        // @ts-expect-error
        .catch(() => rest.onBlur?.({}))
    );
  });

  useEffect(() => {
    if (autoFocus) showModal();
  }, [autoFocus, showModal]);

  return (
    <Select
      value={value}
      defaultValue={defaultValue}
      {...rest}
      open={false}
      onClick={showModal}
      options={options}
    />
  );
};
