import { CloseOutlined } from '@ant-design/icons';
import { Checkbox, Popover } from 'antd';
import { useState } from 'react';

import { FILE_TYPE_MAP } from '@/components/WebOfficeModal/constants';
import { downLoadFile, getFileTypeIconByUrl } from '@/utils/utils';

import styles from './styles.less';

interface IProps {
  setNodeMainbodyAttachments: (v: any) => void;
  nodeMainbodyAttachments: null | {
    attachments: Array<{ src: string; fileName: string }>;
    position: any;
  };
  showBigImg: (src: string) => void;
  handleViewDoc: (v: any) => void;
}

export default (props: IProps) => {
  const { showBigImg, nodeMainbodyAttachments, setNodeMainbodyAttachments, handleViewDoc } = props;
  if (!nodeMainbodyAttachments) return null;

  const { position, attachments } = nodeMainbodyAttachments;

  const [open, setOpen] = useState<boolean>(true);
  const [checkedAttachments, setCheckedAttachments] = useState<
    Array<{ src: string; fileName: string }>
  >([]);

  const changeOpen = (v: boolean) => {
    if (!v) setNodeMainbodyAttachments(null);
    setOpen(v);
  };

  const downLoadAttachment = (attachment: { fileName: string; src: string }) => {
    downLoadFile(attachment.src, attachment.fileName);
  };

  const checkAttachment = (attachment: { fileName: string; src: string }) => {
    const a = checkedAttachments.find((c) => c.src === attachment.src);
    if (!a) return setCheckedAttachments([...checkedAttachments, attachment]);
    else setCheckedAttachments(checkedAttachments.filter((c) => c.src !== attachment.src));
  };

  const isAttachmentChecked = (attachment: { fileName: string; src: string }) => {
    const a = checkedAttachments.find((c) => c.src === attachment.src);
    return !!a;
  };

  const batchDownLoad = () => {
    checkedAttachments.forEach((attachment) => downLoadAttachment(attachment));
  };

  const viewDoc = (fileName: string, src: string, type: string) => {
    if (!FILE_TYPE_MAP[type]) return;
    setNodeMainbodyAttachments(null);
    handleViewDoc({
      src,
      name: fileName,
    });
  };

  const renderContent = () => {
    return (
      <div className={styles.container}>
        <div className={styles.header}>
          <div style={{ color: '#242D3F' }}>附件</div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                marginRight: 24,
                visibility: checkedAttachments.length ? 'visible' : 'hidden',
              }}
            >
              <div onClick={batchDownLoad} className={styles.batchDownLoad}>
                <i
                  style={{ color: '#96A1B5', fontSize: 14, paddingRight: 6 }}
                  className="iconfont iconContent_Download"
                />
                <span>批量下载</span>
              </div>
            </div>
            <CloseOutlined
              onClick={() => setNodeMainbodyAttachments(null)}
              style={{ color: '#D8D8D8' }}
            />
          </div>
        </div>
        <div className={styles.main}>
          {attachments.map((attachment: { src: string; fileName: string }) => {
            const { src: attachmentImgUrl, type: attachmentType } = getFileTypeIconByUrl(
              attachment.src,
            );
            return (
              <div key={attachment.src} className={styles.attachment}>
                {/* 是否选中 */}
                <div className={styles.showChecked}>
                  {isAttachmentChecked(attachment) && <Checkbox checked />}
                </div>
                {attachmentType === 'image' ? (
                  <div
                    onClick={() => {
                      setNodeMainbodyAttachments(null);
                      showBigImg(attachmentImgUrl);
                    }}
                    className={styles.attachmentImg}
                    style={{
                      backgroundImage: `url(${attachmentImgUrl})`,
                    }}
                  />
                ) : (
                  <div
                    onClick={() => viewDoc(attachment.fileName, attachment.src, attachmentType)}
                    className={styles.fileImg}
                  >
                    <div
                      style={{
                        backgroundImage: `url(${attachmentImgUrl})`,
                        height: 50,
                        width: 50,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                      }}
                    />
                  </div>
                )}
                <div className={styles.attachmentName}>{attachment.fileName}</div>
                {/* 遮罩 */}
                <div className={styles.mask}>
                  <Checkbox
                    onChange={() => checkAttachment(attachment)}
                    checked={isAttachmentChecked(attachment)}
                  />
                  <div>
                    <i
                      onClick={() => downLoadAttachment(attachment)}
                      className="iconContent_Download iconfont"
                      style={{
                        color: 'white',
                        marginRight: 0,
                        cursor: 'pointer',
                        lineHeight: '16px',
                      }}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <Popover
      visible={open}
      onVisibleChange={changeOpen}
      overlayClassName={styles.popover}
      content={renderContent()}
      placement="bottom"
    >
      <div
        style={{
          position: 'absolute',
          height: position.height,
          width: position.width,
          left: position.x,
          top: position.y,
        }}
      />
    </Popover>
  );
};
