import type { CurrentUser } from '@linkpi/core';
import { DEFAULT_TEMPLATE_INSTANCE } from '@linkpi/core';
import { assertExists } from '@linkpi/utils';
import { createContext, useContext } from 'react';

export const TemplateContext = createContext<CurrentUser.TemplateInfo | undefined>(undefined);

/**
 * 通过 Context 获取主题类型, 有以下两种场景：
 *
 * - home 页面获取当前节点的主题类型配置 (node.value.tempInfo.id)
 * - setting 页面获取当前配置的主题类型 (useCurrentTemplateSetting)
 */
export const useCurrentTemplate = () => {
  const TemplateInfo =
    useContext(TemplateContext) ||
    (DEFAULT_TEMPLATE_INSTANCE as unknown as CurrentUser.TemplateInfo);

  assertExists(TemplateInfo);

  return TemplateInfo;
};
