import { getAttachmentFromDelta } from '@/utils/utils';

import type { PositionType } from '../components/_baseComponent';
import GridBaseComponent from '../components/_baseComponent';
import type { PiGridModules } from '../core';
class MainbodyAttachment extends GridBaseComponent {
  public data: { node: PiNode };
  public attachments: Array<{ src: string; fileName: string }>;
  public type: string = 'mainbodyAttachment';
  public captrue: boolean = false;

  constructor(
    position: PositionType,
    moduleInstances: PiGridModules,
    data: any,
  ) {
    super(position, moduleInstances);
    this.data = data;
    if (this.data.node.metadata.content)
      this.attachments = getAttachmentFromDelta(
        this.data.node.metadata.content,
      );
    else this.attachments = [];
  }

  public click() {
    if (this.attachments.length === 0) return;
    this.moduleInstances.DataManager.config.setNodeMainbodyAttachments({
      attachments: this.attachments,
      position: this.position,
    });
  }

  public drawIcon(
    x: number,
    y: number,
    color: string,
    unicode: string,
    fontSize: number,
  ) {
    const { Draw } = this.moduleInstances;
    const { ctx } = Draw;

    ctx.save();
    Draw.attr({
      fillStyle: color,
      textBaseline: 'top',
      textAlign: 'start',
      font: `${Draw.npx(fontSize)}px iconfont`,
    });

    Draw.fillText(Draw.iconFont(unicode), x, y);
    ctx.restore();
  }

  public drawAttachmentCount(
    count: string,
    x: number,
    y: number,
    fontSize: number,
  ) {
    const { Draw } = this.moduleInstances;
    const styleMode = Draw.config.styleMode;

    Draw.attr({
      fillStyle: styleMode === 'darkness' ? 'rgba(214,224,255,0.3)' : '#B7BAC0',
      textBaseline: 'top',
      textAlign: 'start',
      font: `${Draw.npx(fontSize)}px  sans-serif`,
    });

    Draw.fillText(count, x, y);
  }

  public render() {
    const { x, y, height } = this.position;
    const { Draw } = this.moduleInstances;
    const styleMode = Draw.config.styleMode;
    Draw.fillRoundRect(
      this.position,
      12,
      styleMode === 'darkness' ? 'rgba(190,206,255,0.05)' : '#F8F9FB',
    );

    this.drawIcon(
      x + 5,
      y + (height - 16) / 2,
      styleMode === 'darkness' ? 'rgba(214,224,255,0.3)' : '#B7BAC0',
      '&#xe77d;',
      16,
    );

    // 附件数量
    this.drawAttachmentCount(
      this.attachments.length > 99 ? '99+' : this.attachments.length + '',
      x + 24,
      y + (height - 14) / 2,
      14,
    );

    if (this.isHover) {
      this.moduleInstances.Render.setCursor('pointer');
    }
  }
}

export default MainbodyAttachment;
