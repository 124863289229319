import type { CurrentUser } from '@linkpi/core';
import { getDepartmentByUser } from '@linkpi/core';
import { useMemoizedFn } from 'ahooks';
import type { SelectProps } from 'antd';
import { Select } from 'antd';
import { isEmpty, values } from 'ramda';
import type { FC } from 'react';
import { useEffect } from 'react';
import { useMemo } from 'react';

import { showDepartmentSelectModal } from '@/components/DepartmentSelectModal';
import { useOrgUserMap } from '@/hook';
import { useOrgDepartmentNodesMap } from '@/hook/useOrgStructure';
import { takeOne, toArray } from '@/utils/utils';

export const DepartmentSelect: FC<
  React.PropsWithChildren<
    SelectProps & {
      title?: string | (() => string);
      departmentConfig?: CurrentUser.DepartmentConfig;
      node?: PiNode;
    }
  >
> = ({ title = '', children, departmentConfig, autoFocus, node, value, defaultValue, ...rest }) => {
  const departmentNodeMap = useOrgDepartmentNodesMap();
  const userMap = useOrgUserMap();

  const filterIds = useMemo(() => {
    if (!departmentConfig?.range || isEmpty(departmentConfig.range)) return undefined;

    const result: string[] = [];
    departmentConfig.range.forEach((v) => {
      if (v.startsWith('group_')) result.push(v.replace('group_', ''));
      if (v.startsWith('dynamicDepartment_') && node) {
        const [, propStr, levelStr] = v.split('_');
        const level = Number(levelStr);
        let userIds: string[];
        if (propStr === 'creator') {
          userIds = [node.prop._sys_creator];
        }
        if (propStr.startsWith('prop')) {
          const propIndex = Number(propStr.replace('prop', ''));
          userIds = toArray(node.tempInfo.prop[propIndex]);
        }
        if (userIds!)
          userIds.forEach((userId) => {
            getDepartmentByUser(userMap[userId], values(departmentNodeMap), level).forEach((d) =>
              result.push(d.id.replace('group_', '')),
            );
          });
      }
    });
    return result;
  }, [departmentConfig?.range, departmentNodeMap, node, userMap]);

  const restOptions = useMemo(() => {
    return toArray(value || defaultValue)
      .filter(Boolean)
      ?.map((v: string) => {
        const id = v;
        return {
          label: departmentNodeMap[id]?.name,
          value: v,
        };
      });
  }, [departmentNodeMap, defaultValue, value]);

  const showModal = useMemoizedFn(() => {
    let _title = '';
    if (typeof title === 'function') {
      _title = title();
    } else if (typeof title === 'string') {
      _title = title;
    }

    return (
      showDepartmentSelectModal({
        checkStrictly: true,
        filterIds,
        mode: rest.mode === 'multiple' ? 'multiple' : 'single',
        title: _title,
        defaultValue: toArray(value || defaultValue)
          .filter(Boolean)
          .map((i) => 'group_' + i),
      })
        .then((res) => {
          if (rest.mode === 'multiple')
            rest.onChange?.(
              toArray(res)?.map((i) => i?.replace('group_', '')),
              [],
            );
          else rest.onChange?.(takeOne(res)?.replace('group_', ''), []);
        })
        // @ts-expect-error
        .catch(() => rest.onBlur?.({}))
    );
  });

  useEffect(() => {
    if (autoFocus) showModal();
  }, [autoFocus, showModal]);

  return (
    <Select
      value={value}
      defaultValue={defaultValue}
      {...rest}
      open={false}
      onClick={showModal}
      options={restOptions}
    />
  );
};
