import { Select } from 'antd';
import type { FC } from 'react';

import { useTempPropConfig } from '../hooks';

interface IDateType {}

export const DatetimeRangeType: FC<IDateType> = () => {
  const { isLock, propParams, setPropParams } = useTempPropConfig();

  return (
    <>
      <div className={'prop-item'} key={'dateFormat'}>
        <div className={'prop-item-label'}>日期格式</div>
        <div className={'prop-item-input'}>
          <Select
            disabled={isLock}
            placeholder="请选择日期格式"
            options={[
              {
                label: '年/月/日 时:分 - 年/月/日 时:分',
                value: 'YYYY/MM/DD HH:mm - YYYY/MM/DD HH:mm',
              },
            ]}
            value={'YYYY/MM/DD HH:mm - YYYY/MM/DD HH:mm'}
            onChange={(e) => setPropParams({ ...propParams, dateFormat: e })}
          />
        </div>
      </div>
    </>
  );
};
