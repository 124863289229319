import { useDebounceEffect } from 'ahooks';
import { useEffect, useRef, useState } from 'react';

import type { GridAttrItem } from '@/pages/pi/grid/react/useGridAttrs';
import orderBy from '@/utils/orderBy';

export default (
  dataSource: ViewGroupDataType,
  recordSorts: string[],
  isCustomTable?: boolean, // 实时更新 先fix 按创建时间
): [ViewGroupDataType, string[], (dataSource: any, sortInfo: any, columns: any) => string[]] => {
  if (isCustomTable) return [dataSource, [], () => []];
  // 在组内排序
  const [sortData, setSortData] = useState<ViewGroupDataType>(
    isCustomTable ? getSortData(dataSource, true)[0] : dataSource,
  );
  const [ids, setIds] = useState<string[]>([]);

  const recordSortMap = useRef<Record<string, number>>({});

  useEffect(() => {
    recordSortMap.current = recordSorts.reduce((res, itemId, index) => {
      res[itemId] = index;
      return res;
    }, {} as Record<string, number>);
  }, [recordSorts]);

  useDebounceEffect(
    () => {
      let newData;
      let newIds;

      if (isCustomTable) {
        [newData, newIds] = getSortData(dataSource, true);
      } else {
        [newData, newIds] = getSortData(dataSource);
      }
      setSortData(newData);
      setIds(newIds);
    },
    [dataSource, recordSorts, isCustomTable],
    {
      wait: 200,
    },
  );

  function getSortData(
    newDataSource: ViewGroupDataType,
    _isCustomTable?: boolean,
  ): [ViewGroupDataType, string[]] {
    const sorters = [
      (a: PiNode, b: PiNode) => {
        if (_isCustomTable) {
          // fix 按创建时间倒序
          return b.prop._sys_createTime - a.prop._sys_createTime;
        } else {
          const createA = a.metadata.h;
          const createB = b.metadata.h;

          const indexOfA = recordSortMap.current[a.id];
          const indexOfB = recordSortMap.current[b.id];

          // 没有排序的 按创建时间升序
          if (!~indexOfA && !~indexOfB) {
            return createA - createB;
          }

          // 如果a不存在 a排在b后面 return > 0
          if (!~indexOfA) {
            return 1;
          }

          // 如果b不存在 b排到a后面 return < 0
          if (!~indexOfB) {
            return -1;
          }

          // 如果都存在，升序
          return indexOfA - indexOfB;
        }
      },
    ];

    const sortIds: string[] = [];
    const newSortData = Object.keys(newDataSource).reduce((a, b) => {
      // order (list ,iteratees[] , orders[] )

      const sortList = orderBy(
        newDataSource[b].list,
        sorters.map(() => (y: PiNode) => y),
        sorters,
      );

      sortIds.push(...sortList.map((y: PiNode) => y.id));

      a[b] = {
        ...newDataSource[b],
        list: sortList,
      };

      return a;
    }, {} as ViewGroupDataType);

    return [newSortData, sortIds];
  }

  /**
   * 通过条件排序
   * 返回node[]
   */
  const getSortDataByOrder = (
    newDataSource: ViewGroupDataType,
    sortInfo: Record<string, { sort: 'asc' | 'desc' }>,
    columns: GridAttrItem[],
  ) => {
    const sortColumnsKey = columns.map((x: any) => x.key);
    const sorters = Object.keys(sortInfo)
      .sort((a, b) => {
        return sortColumnsKey.indexOf(a) - sortColumnsKey.indexOf(b);
      })
      .map((x) => {
        const column = columns.find((y) => y.key === x);
        return column && column.sorter && column.sorter[sortInfo[x].sort]
          ? column.sorter[sortInfo[x].sort]
          : () => {};
      });

    const nodeList = Object.keys(newDataSource).reduce(
      (a, b) => a.concat(newDataSource[b].list),
      [] as ViewGroupDataItem['list'],
    );

    const sortList = orderBy(
      nodeList,
      sorters.map(() => (y: any) => y),
      sorters,
    );

    return sortList.map((x: PiNode) => x.id);
  };

  return [sortData, ids, getSortDataByOrder];
};
