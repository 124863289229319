import { DEFAULT_TEMPLATE } from '@linkpi/core';
import { getEnumOptions, getTempMatchProps, getTempPropEnumOption, updateProp } from '@linkpi/core';
import { message, Modal } from 'antd';
import { cloneDeep } from 'lodash';
import { forwardRef, memo, useEffect, useImperativeHandle, useState } from 'react';

import EditProps from '@/components/EditProps';
import request from '@/utils/request';

/**
 * @deprecated
 */
const PropsEditModal = (props, ref) => {
  useImperativeHandle(ref, () => ({
    open: (type, ids) => {
      // 以第一个选值为默认值
      open(type, ids);
    },
    // 直接修改
    set: (type, ids, value) => {
      set(type, ids, value);
    },
  }));

  const { tempProps, userMap, tempId, node, orgId, userId, onHandle } = props;

  const [editIds, setEditIds] = useState([]);
  const [visible, setVisible] = useState(false);
  const [editType, setEditType] = useState('');
  const [editPropConfigs, setEditPropConfigs] = useState([]);
  const [editValue, setEditValue] = useState([]);
  const [modalTitle, setModalTitle] = useState('');
  const [enumOption, setEnumOption] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newEnumOption, setNewEnumOption] = useState({});

  const fetchEnumOptions = async () => {
    const v = await getEnumOptions({
      nodeId: node.value.id,
      orgId,
      request,
      template: node.value.template,
    });
    setNewEnumOption(v);
  };
  useEffect(() => {
    fetchEnumOptions();
  }, [node.value.id]);

  const set = async (type, ids, value) => {
    const [_, index] = type.split('_');
    const req = {
      nodeId: ids,
      org_id: orgId,
      temp_id: tempId,
    };
    req.modifyProp = [
      {
        type: 'p',
        key: Number(index),
        value: value,
      },
    ];

    const res = await request('/docapi/batchSetAction', {
      method: 'POST',
      data: req,
    });
    if (res.status !== 'ok') {
      message.error(res.message || '操作失败');
    }
  };
  const open = async (type, ids) => {
    const defaultId = ids[0];
    setEditIds(ids);

    // 所有的主题类型属性
    // const notHideItems = tempProps.filter((i) => !!i.type && !i.hide);
    /**
     * 修改模板属性，会影响所有 模板属性和 状态属性
     */
    const defaultNode = node.value.nodeManager.findChildren(defaultId);

    const tempInfo = defaultNode.tempInfo;
    let propConfig;
    let propValue;
    // 获取选项
    // 获取模板属性的条件匹配字段的选值
    const matchProps = await fetchTempMatchProps();
    const tempPropEnumOption = tempProps.map((prop, index) => {
      if (!prop || !Object.keys(prop).length) {
        return [];
      }
      if (!prop.conditionMatching && !prop.enumQuote) {
        return [];
      }
      const option = getTempPropEnumOption({
        propData: defaultNode.tempInfo.prop,
        dataIndex: index,
        dataType: prop.type,
        matchConfig: matchProps,
        creator: userId,
      });
      return option || [];
    });

    if (type === 'allProp') {
      // 全部模板属性
      propConfig = tempProps;
      propValue = tempInfo.prop;
      setModalTitle('编辑全部属性');
      setEnumOption(tempPropEnumOption);
    } else {
      // 修改单个
      const [mark, index] = type.split('_');
      propConfig = [tempProps[index]];
      propValue = [tempInfo.prop[index]];
      setModalTitle('编辑 ' + propConfig[0].name);
      setEnumOption([tempPropEnumOption[index]]);
    }

    setEditType(type);
    setEditPropConfigs(propConfig);
    setEditValue(propValue);
    setVisible(true);
    setLoading(false);
  };

  const fetchTempMatchProps = async () => {
    if (tempId === DEFAULT_TEMPLATE) return [];
    const [err, res] = await getTempMatchProps(request, { orgId, tempId });
    if (!err && res.status === 'ok') {
      return res.data;
    } else {
      console.log('条件匹配异常', { orgId, tempId }, { err, res });
      return [];
    }
  };

  const onEditFieldChange = (index, e) => {
    const newValues = cloneDeep(editValue);
    newValues[index] = e;
    setEditValue(newValues);
  };

  const handleBatchNodes = async (ids, param, successCb, failCb) => {
    const failIds = [];
    const dispatch = async (index) => {
      const node_id = ids[index];
      if (!node_id) return;
      const [err, res] = await updateProp(request, { ...param, node_id });

      if (res?.status !== 'ok') failIds.push(node_id);
      await dispatch(index + 1);
    };

    await dispatch(0);

    // 节点全部处理一轮
    if (failIds.length) failCb(failIds);
    else successCb();
  };

  const handleSave = async () => {
    const [_, propIndex] = editType.split('prop_');
    onHandle({
      org_id: orgId,
      temp_id: tempId,
      index: [Number(propIndex)],
      value: [editValue[0]],
      node_id: editIds,
    });
    setVisible(false);
  };

  return (
    <Modal
      open={visible}
      onCancel={(_) => setVisible(false)}
      destroyOnClose={true}
      title={modalTitle}
      onOk={handleSave}
      okButtonProps={{ loading: loading }}
    >
      {editPropConfigs.map((x, i) => (
        <EditProps
          key={i}
          config={x}
          userMap={userMap}
          value={editValue[i]}
          onChange={(e) => onEditFieldChange(i, e)}
          enumOption={enumOption[i]}
          newEnumOption={newEnumOption[editType.split('_')[1]]}
        />
      ))}
    </Modal>
  );
};

export default memo(forwardRef(PropsEditModal));
