import type { ApiResponse } from '@linkpi/core';
import { AUTO_UPDATE_RANGE_OPTIONS, Date_Format_Options, DEFAULT_AVATAR } from '@linkpi/core';
import type { SelectProps } from 'antd';
import {
  Avatar,
  Cascader,
  Checkbox,
  DatePicker,
  Input,
  InputNumber,
  Select,
  Space,
  Tooltip,
} from 'antd';
import cls from 'clsx';
import { cloneDeep } from 'lodash';
import type { Moment } from 'moment';
import moment from 'moment';
import type { RangeValue } from 'rc-picker/lib/interface';
import type { FC } from 'react';
import { useRef, useState } from 'react';

import AddressInput from '@/components/AddressInput';
import CascadeSelect from '@/components/CascadeSelect';
import LinkPiDate from '@/components/LinkPiDate';
import LocationSelect from '@/components/LocationSelect';
import FlowOption from '@/pages/home/components/View/ViewSetting/FlowOption';
import { StatusUserPropConfigSelect } from '@/pages/space/components/TaskStatus/components/StatusUserPropConfigSelect';
import { transRangeDate } from '@/pages/space/components/TemplateProp/components/TempPropModal/utils';
import { filterUsersByPinyin } from '@/utils/utils';

import type { FilterConditionConfigMapType } from '../ConditionFilter';
import { useConditionFilterConfigContext } from '../hook/useConditionFilterOption';
import type { getPropTypeOptions } from './ConditionOperator';
import NodeSelect from './NodeSelect';

import styles from '../styles.less';

const isEqualUserValue = (allKey: string[], value: string[] = []) => {
  if (!allKey || !value) return false;
  const str1 = allKey
    .map((x) => (x === null ? 'null' : x))
    .sort((a: string, b: string) => a.localeCompare(b, 'zh-CN'))
    .join('');
  const str2 = value
    .map((x) => (x === null ? 'null' : x))
    .sort((a: string, b: string) => a.localeCompare(b, 'zh-CN'))
    .join('');
  return str1 === str2;
};

type ConditionValuePropsType = {
  entry: 'view' | 'propSet';
  opOption: ReturnType<typeof getPropTypeOptions>;
  curOpConfig?: {
    label: string;
    value: any;
    multiple?: boolean | undefined;
    multipleRow?: string | undefined;
  };
  condition: ApiResponse.ViewList.ViewconditionV2Item;
  orgInfo: ApiResponse.CurrentUser.OrgInfo;
  getTreeData?: () => any[];
  onChange: (condition: ApiResponse.ViewList.Viewcondition | any) => void;
  disabled?: boolean;
  curTemplate: ApiResponse.CurrentUser.TemplateInfo;
  sysTags: any;
  tempMap: Record<string, ApiResponse.CurrentUser.TemplateInfo>;
  userMap: Record<string, ApiResponse.OrgUser.OrgUserItem>;
  filterConditionConfigMap: FilterConditionConfigMapType;
  userGroups: any; // 用户分组
  provData: any;
  allowTempIds?: string[];
  nodeSelectExtraCurrent?: string;
  conditionConfig: any;
  conditionConfigType: any;
  cascadeConfig: any;
  multiple: any;
  conditionConfigOptions: any[];
  originalProp: any;
};

export const ConditionValue = (props: ConditionValuePropsType) => {
  const { onChange, condition, curOpConfig, entry } = props;
  const { key, input, op } = props.condition;
  // op: or | any
  // input: string[] | ViewconditionV2Item[]

  const handleAddInput = () => {
    let newCondition = cloneDeep(condition);
    // 多行
    if (op === 'or' || op === 'and') {
      const inputItem = cloneDeep(newCondition.input[0]);
      newCondition.input.push({
        ...inputItem,
        input: [],
      });
    }
    // 转成多行
    else {
      const inputItem = cloneDeep(newCondition);
      newCondition = {
        ...inputItem,
        op: 'or',
        input: [
          inputItem,
          {
            ...inputItem,
            input: [],
          },
        ],
      };
    }

    onChange(newCondition);
  };

  const handleRemoveInput = (index: number) => {
    const newCondition = cloneDeep(condition);
    newCondition.input.splice(index, 1);
    onChange(newCondition);
  };

  // map input
  const getItem = (getItemProps: {
    index: number;
    condition: ApiResponse.ViewList.ViewconditionV2Item;
    onInputChange: any;
    onStatusFlowChange: any;
    disableRemoveBtn?: boolean;
  }) => {
    const displayAddIptBtn = curOpConfig?.multipleRow && entry !== 'view';
    const displayRemoveIptBtn =
      curOpConfig?.multipleRow && getItemProps.disableRemoveBtn && entry !== 'view';

    return (
      <div
        className={cls('condition-filter-value-item', styles.conditionValueItem)}
        key={getItemProps.index}
      >
        <div style={{ flex: 1, width: 0, position: 'relative' }}>
          <ConditionValueItem
            {...props}
            condition={getItemProps.condition}
            onInputChange={getItemProps.onInputChange}
            onStatusFlowChange={getItemProps.onStatusFlowChange}
          />
        </div>

        {/* 新增input */}
        {displayAddIptBtn ? (
          <div
            className={cls('condition-filter-value-btn', styles.btn)}
            onClick={handleAddInput}
            style={{ transform: 'rotate(45deg)' }}
          >
            <i className="iconfont iconButton_cancel" />
          </div>
        ) : null}

        {/* 移除input */}
        {displayRemoveIptBtn ? (
          <div className={styles.btn} onClick={() => handleRemoveInput(getItemProps.index)}>
            <i className="iconfont iconformula-subtract" />
          </div>
        ) : null}
      </div>
    );
  };

  const isMultiRow = op === 'and' || op === 'or';
  // TODO || key === 'ancestor';

  // 多行
  if (isMultiRow) {
    return (
      <div className={styles.conditionValueList}>
        {input.map((x: any, index: any) =>
          getItem({
            index,
            condition: x,
            disableRemoveBtn: input.length > 1,
            onInputChange: (newInput: any) => {
              const newCondition = cloneDeep(condition);
              newCondition.input[index] = {
                ...newCondition.input[index],
                input: newInput,
              };
              onChange(newCondition);
            },
            onStatusFlowChange: (e: any) => {
              const newCondition = cloneDeep(condition);
              newCondition.input[index] = {
                ...newCondition.input[index],
                statusFlow: e,
              };
              onChange(newCondition);
            },
          }),
        )}
      </div>
    );
  } else {
    return (
      <div className={styles.conditionValueList}>
        {getItem({
          index: 0,
          condition: condition,
          onInputChange: (newInput: any) => {
            onChange({
              ...condition,
              input: newInput,
            });
          },
          onStatusFlowChange: (e: any) => {
            onChange({
              ...condition,
              statusFlow: e,
            });
          },
        })}
      </div>
    );
  }
};

// 单行input
export const ConditionValueItem: FC<
  ConditionValuePropsType & {
    onInputChange: any;
    onStatusFlowChange: any;
  }
> = (props) => {
  const {
    condition,
    sysTags,
    getTreeData,
    entry,
    onChange,
    onInputChange,
    onStatusFlowChange,
    orgInfo,
    disabled: _disabled = false,
    curTemplate,
    filterConditionConfigMap,
    tempMap,
    userMap,
    userGroups,
    provData,
    allowTempIds,
    nodeSelectExtraCurrent,
    conditionConfig,
    conditionConfigType,
    cascadeConfig,
    multiple,
    conditionConfigOptions,
    originalProp,
  } = props;
  const {
    maxTagCount,
    curTemplateList,
    experimental_disableHistoryStatus = false,
    experimental_userLiteMode = false,
  } = useConditionFilterConfigContext();

  const { key, op, input } = condition;
  const disabled = _disabled || op === 'empty' || op === 'notEmpty';

  const defautlSelectProps = {
    condition,
    disabled,
    onInputChange,
    placeholder: '请选择',
    input: input,

    options: [],
    handleCheckAll: () => {},
  };

  const [visibleRangePicker, setVisibleRangePicker] = useState(false);
  const locationSelectRef = useRef<any>(null);

  if (!key)
    return getDefaultSelect({
      ...defautlSelectProps,
      maxTagCount,
      isCheckAll: false,
      isMultiple: false,
      selectMode: undefined,
    });

  /**
   *
   *
   * 特殊condition
   *
   *
   */

  // 范围
  if (key === 'ancestor') {
    const treeData = getTreeData ? getTreeData() : [];
    return (
      <NodeSelect
        value={input as string[]}
        treeData={treeData}
        rootId={orgInfo.rootId}
        disabled={disabled}
        onChange={(e) => {
          onInputChange(e);
        }}
        extraCurrent={nodeSelectExtraCurrent}
      />
    );
  }

  // 状态(单主题类型)
  if (key === 'status' && !curTemplateList) {
    const optionData = filterConditionConfigMap[key]?.options || [];
    const hasHistory = !experimental_disableHistoryStatus && entry === 'view';
    const options: any = [
      {
        label: '当前状态',
        options: optionData.map((x, index) => ({
          label: x.name,
          value: x.key,
          filterText: x.name,
        })),
      },
    ];
    if (hasHistory) {
      options.push({
        label: (
          <div>
            历史状态
            <Tooltip title={'状态多次流转时，仅筛选该状态最近一次被流转的记录'} placement={'right'}>
              <i
                className={'iconfont iconNav_Help'}
                style={{ fontSize: 12, color: '#316EF5', marginLeft: 8 }}
              />
            </Tooltip>
          </div>
        ),
        options: optionData.map((x, index) => ({
          label: '历史-' + x.name,
          value: x.key + '_history',
          filterText: x.name,
        })),
      });
    }

    const all: any = optionData.map((x) => x.key);
    if (hasHistory) {
      all.push(...optionData.map((x) => x.key + '_history'));
    }
    const isCheckAll = input?.length === all.length;

    return getDefaultSelect({
      ...defautlSelectProps,
      maxTagCount,
      options: options,
      selectMode: 'multiple',
      isMultiple: true,
      isCheckAll: isCheckAll,
      handleCheckAll: () => {
        onInputChange(isCheckAll ? [] : all);
      },
    });
    // 状态(多主题类型)
  } else if (key === 'status' && curTemplateList) {
    const all: string[] = [];
    const options: any = filterConditionConfigMap[key]?.options.map((i) => ({
      label: i.name,
      options: i.options!.map((s) => {
        const value = [s.templateId, s.key].join('_');

        all.push(value);

        return {
          fullLabel: [i.name, s.name].join('-'),
          label: s.name,
          value,
        };
      }),
    }));
    const isCheckAll = input?.length === all.length;
    return getDefaultSelect({
      ...defautlSelectProps,
      optionLabelProp: 'fullLabel',
      maxTagCount,
      options: options,
      selectMode: 'multiple',
      isMultiple: true,
      isCheckAll: isCheckAll,
      handleCheckAll: () => {
        onInputChange(isCheckAll ? [] : all);
      },
    });
  }

  // 状态流转
  if (key === 'statusFlow') {
    return (
      <FlowOption
        status={curTemplate.task_status || []}
        curFlowOption={condition.statusFlow}
        setCurFlowOption={(e: any) => {
          // TODO
          onStatusFlowChange(e);
        }}
      />
    );
  }

  // 系统标签
  if (key === 'sysTag') {
    const isCheckAll = (input as string[])?.length === sysTags?.length;

    return getDefaultSelect({
      ...defautlSelectProps,
      maxTagCount,
      options: sysTags.map((x: any) => ({
        label: x.tag,
        value: x.tag,
        filterText: x.tag,
      })),
      selectMode: 'multiple',
      isMultiple: true,
      isCheckAll: isCheckAll,
      handleCheckAll: () => {
        onInputChange(isCheckAll ? [] : sysTags.map((x: any) => x.tag));
      },
    });
  }

  // 主题类型
  if (key === 'templateId') {
    return getDefaultSelect({
      ...defautlSelectProps,
      maxTagCount,
      isMultiple: false,
      isCheckAll: false,
      options: Object.values(tempMap)
        .filter((x) => (allowTempIds ? allowTempIds.includes(x.template_id) : true))
        .map((x) => ({
          label: x.name,
          value: x.template_id,
          filterText: x.name,
        })),
      selectMode: undefined,
    });
  }

  // 主题类型
  if (key === 'templateIds') {
    return getDefaultSelect({
      ...defautlSelectProps,
      maxTagCount,
      isMultiple: true,
      isCheckAll: false,
      options: Object.values(tempMap)
        .filter((x) => (allowTempIds ? allowTempIds.includes(x.template_id) : true))
        .map((x) => ({
          label: x.name,
          value: x.template_id,
          filterText: x.name,
        })),
      selectMode: 'multiple',
    });
  }

  /**
   *
   *
   * filterConditionConfigMap 的 通过类型判断
   *
   *
   */
  if (!conditionConfig)
    return getDefaultSelect({
      ...defautlSelectProps,
      maxTagCount,
      isCheckAll: false,
      isMultiple: false,
      selectMode: undefined,
    });

  // 其他配置

  if (conditionConfigType === 'userRoles') {
    return (
      <StatusUserPropConfigSelect
        allowTypes={['group']}
        className="w-full"
        // maxTagCount="responsive"
        mode="multiple"
        // value={input?.map((i: string) => 'group_' + i)}
        value={input}
        onChange={(e) => {
          const roles = e.map((i: string) => i.replace('group_', ''));
          onInputChange(e);
        }}
      />
    );
  }

  // 人员
  if (conditionConfigType === 'user') {
    const iconStyle = {
      color: '#FFF',
      marginRight: 10,
      padding: 4,
      fontSize: 14,
      borderRadius: '100%',
      background: '#316EF5',
    };
    const options = [
      !experimental_userLiteMode && {
        label: (
          <>
            <i className={'iconfont iconuser'} style={iconStyle} />
            当前账户
          </>
        ),
        value: null,
        filterText: '当前账户',
      },
      {
        label: '成员',
        options: Object.values(userMap).map((x) => ({
          label: (
            <>
              <Avatar size={20} src={x.avatar || DEFAULT_AVATAR} style={{ marginRight: 10 }} />
              {x.nick_name}
            </>
          ),
          value: x.account_id,
          filterText: x.nick_name,
          type: 'user',
        })),
      },
      !experimental_userLiteMode && {
        label: '成员组',
        options: userGroups
          .filter((x: any) => x.group_id !== '-1')
          .map((x: any) => ({
            label: (
              <>
                <i
                  className={'iconfont iconChat_Member'}
                  style={{
                    ...iconStyle,
                    background: '#5ABCB0',
                    fontSize: 12,
                    marginRight: 2,
                  }}
                />
                {x.group_name}
              </>
            ),
            value: x.group_id,
            filterText: x.group_name,
          })),
      },
    ].filter(Boolean);

    const allUserKey = Object.values(userMap).map((x) => x.account_id);
    const isCheckAll = isEqualUserValue(allUserKey, input as string[]);
    return getDefaultSelect({
      ...defautlSelectProps,
      maxTagCount,
      options: options,
      selectMode: 'multiple',
      isCheckAll: isCheckAll,
      isMultiple: multiple,
      handleCheckAll: () => {
        onInputChange(isCheckAll ? [] : allUserKey);
      },
      userSelectConfig: Object.values(userMap).reduce((res, cur) => {
        res[cur.nick_name] = cur;

        return res;
      }, {} as any),
    });
  }

  // 日期
  if (conditionConfigType === 'date' || conditionConfigType === 'datetime') {
    // TODO 数据格式不一样？
    const [startDate, endDate, extraKey] = (input as string[]) || [];

    let display = '';
    let datePickerValue: RangeValue<Moment> | undefined = undefined;

    const dateFormat =
      conditionConfig.data?.dateFormat ||
      (conditionConfigType === 'date' ? 'YYYY/MM/DD' : 'YYYY/MM/DD HH:mm');
    const dateConfig =
      Date_Format_Options.find((x) => x.value === dateFormat) || Date_Format_Options[0];
    const dateModule = dateConfig.range || 'date';
    const conditionFilterFormat = dateConfig.conditionFilterFormat;
    const showTime = (dateConfig as any).pickerShowTime || undefined;

    if (input && input.length) {
      datePickerValue = [moment(startDate), moment(endDate)];

      // 自动更新 近多少天 需要自动更新日期 而且是一个时间范围
      if (extraKey !== undefined) {
        datePickerValue = [
          moment().subtract(extraKey, 'd').hour(0).minute(0).second(0).millisecond(0),
          moment().hour(23).minute(59).second(59).millisecond(999),
        ];
      }

      if (extraKey !== undefined) {
        // 自动更新
        display = AUTO_UPDATE_RANGE_OPTIONS.find((x) => x.value === Number(extraKey))?.label || '';
      } else {
        display =
          startDate && endDate
            ? [startDate, endDate].map((v) => moment(v).format(conditionFilterFormat)).join(' ~ ')
            : '';
      }
    }

    if (op === 'timeBein') {
      const isTimeModule = dateModule === 'time';
      return (
        <>
          {/* 时分 不显示 */}
          {/* {!isTimeModule ? (
            <div
              className="ant-select ant-select-single"
              style={{ width: '100%', lineHeight: '30px' }}
              onClick={() => setVisibleRangePicker(true)}
            >
              <div className="ant-select-selector">
                {display || <span className="ant-select-selection-placeholder">请选择</span>}
              </div>
            </div>
          ) : null} */}
          <DatePicker.RangePicker
            // @ts-ignore
            picker={dateModule}
            showTime={showTime}
            open={visibleRangePicker}
            onOpenChange={setVisibleRangePicker}
            disabled={disabled}
            style={{ width: '100%' }}
            // className={isTimeModule ? '' : styles.hiddenPicker}
            ranges={
              dateModule === 'date'
                ? {
                    今日: [moment(), moment()],
                    七天内: [moment().subtract(6, 'd'), moment()],
                    本月: [moment().startOf('month'), moment().endOf('month')],
                  }
                : {}
            }
            allowClear
            value={datePickerValue}
            onChange={(e) => {
              if (e) {
                const [start, end] = e;

                // 毫秒后三位会出现误差，非 000 和 999
                onInputChange([start?.startOf('day').valueOf(), end?.endOf('day').valueOf()]);
              } else onInputChange([]);
            }}
            showSecond={false}
            popupClassName="view-range-picker"
            format={conditionFilterFormat}
            renderExtraFooter={(mode) =>
              // TODO 只有按日时分 才有自动更新
              mode === 'date' && entry === 'view' ? (
                <div className="view-range-picker-extra">
                  <Space wrap>
                    <div className={'extra-title'}>自动更新：</div>
                    {AUTO_UPDATE_RANGE_OPTIONS.map((r) => (
                      <div
                        key={r.value}
                        className={`extra-title ${Number(extraKey) === r.value ? 'active' : ''}`}
                        onClick={() => {
                          setVisibleRangePicker(false);
                          onInputChange(transRangeDate(r.value));
                        }}
                      >
                        {r.label}
                      </div>
                    ))}
                  </Space>
                </div>
              ) : null
            }
          />
        </>
      );
    } else {
      return (
        <LinkPiDate
          style={{ width: '100%' }}
          dateFormat={conditionFilterFormat}
          allowClear
          onChange={(e: any) => {
            onInputChange(e ? [e.valueOf()] : []);
          }}
          dateValue={input[0] || undefined}
          disabled={disabled}
        />
      );
    }
  }

  // 数字
  if (conditionConfigType === 'number' || conditionConfigType === 'currency') {
    if (op === 'beIncluded') {
      return (
        <Input.Group compact style={{ display: 'flex' }}>
          <Input
            style={{ textAlign: 'center', flex: 1 }}
            placeholder="最小值"
            value={!isNaN(input[0]) ? input[0] : ''}
            disabled={disabled}
            onChange={(e) => {
              const newInput = input;
              newInput[0] = Number(e.target.value);
              onInputChange(newInput);
            }}
          />
          <Input
            style={{
              width: 30,
              borderLeft: 0,
              borderRight: 0,
              pointerEvents: 'none',
            }}
            placeholder="~"
            disabled
          />
          <Input
            style={{ textAlign: 'center', flex: 1 }}
            disabled={disabled}
            placeholder="最大值"
            value={!isNaN(input[1]) ? input[1] : ''}
            onChange={(e) => {
              const newInput = input;
              newInput[1] = Number(e.target.value);
              onInputChange(newInput);
            }}
            allowClear
          />
        </Input.Group>
      );
    }

    return (
      <InputNumber
        onChange={(e) => {
          onInputChange([e]);
        }}
        placeholder="请输入"
        value={!isNaN(input[0]) ? input[0] : ''}
        style={{ width: '100%' }}
        disabled={disabled}
      />
    );
  }

  // 多级选值
  if (conditionConfigType === 'cascade') {
    let nodes = cascadeConfig?.nodes;
    if (op.startsWith('rangeEqualCascade_')) {
      const [_, level] = op.split('rangeEqualCascade_');
      nodes = getCascadeNodesByLevel(cascadeConfig?.nodes, Number(level));
    }
    // 0-xx key
    // 1-xx routeKey

    return (
      <CascadeSelect
        value={input}
        disabled={disabled}
        handleChange={(e) => {
          onInputChange(e);
        }}
        placeholder="请选择"
        changeOnSelect
        prop={originalProp}
        orgId={orgInfo.orgId}
        propIndex={0}
        multiple={multiple}
        template={curTemplate}
      />
      // <Cascader
      //   style={{ width: '100%' }}
      //   options={nodes}
      //   fieldNames={{
      //     label: 'title',
      //     value: 'routeKey', // 之前存的都是routeKey
      //     children: 'children',
      //   }}
      //   disabled={disabled}
      //   showSearch={{
      //     filter: (inputValue: string, path: any[]) =>
      //       path.some(
      //         (option) =>
      //           (option.title as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1,
      //       ),
      //   }}
      //   changeOnSelect
      //   multiple={multiple}
      //   value={input as string[]}
      //   onChange={(e: any) => {
      //     onInputChange(e);
      //   }}
      // />
    );
  }

  // 地址
  if (conditionConfigType === 'address' || conditionConfigType === 'positioning') {
    if (op === 'equal') {
      return (
        <Cascader
          allowClear
          style={{ width: '100%' }}
          options={provData}
          fieldNames={{
            label: 'name',
            value: 'adcode',
            children: 'districts',
          }}
          disabled={disabled}
          changeOnSelect
          multiple={false}
          value={input as string[]}
          onChange={(e: any) => {
            onInputChange(e);
          }}
        />
      );
    }
    if (op === 'distance') {
      const handleLocationSelect = () => {
        locationSelectRef.current?.show({
          locationData: input[0] || null,
          onSave: (newData: any) => {
            const newInput = input;
            newInput[0] = newData;
            onInputChange(newInput);
          },
        });
      };

      return (
        <div>
          <div
            className="ant-select ant-select-single"
            style={{ width: '100%', lineHeight: '30px', marginBottom: 8 }}
            onClick={handleLocationSelect}
          >
            <div className="ant-select-selector text-omit">
              {!input[0] ? '请选择' : input[0].name}
            </div>
          </div>
          <div style={{ display: 'flex' }}>
            <InputNumber
              style={{ flex: 1 }}
              placeholder="请输入距离范围"
              value={input[1] || ''}
              disabled={disabled}
              onChange={(e) => {
                const newInput = input;
                newInput[1] = e;
                onInputChange(newInput);
              }}
            />
            <p style={{ lineHeight: '30px', marginLeft: 5 }}>公里以内</p>
          </div>

          {/*  */}
          <LocationSelect ref={locationSelectRef} />
        </div>
      );
    }
    if (
      op === 'rangeEqualProv' ||
      op === 'rangeEqualCity' ||
      op === 'rangeEqualDist' ||
      op === 'rangeEqualStreet'
    ) {
      const scopeLevel = {
        rangeEqualProv: 'province',
        rangeEqualCity: 'city',
        rangeEqualDist: 'district',
        rangeEqualStreet: 'street',
      }[op];
      return (
        <AddressInput
          // address 要数组
          address={input[0] ? input : null}
          disabled={disabled}
          prop={{
            ...filterConditionConfigMap[key].data,
            conditionMatching: false,
            accuracy: scopeLevel,
          }}
          onConfirm={(e: any) => {
            onInputChange([e]);
          }}
          autoOpen={false}
          quoteOptions={[]}
          style={{
            lineHeight: '30px',
            border: '1px solid #EBEDF0',
            borderRadius: 8,
          }}
        />
      );
    }

    return null;
  }

  if (conditionConfigType === 'text' || conditionConfigType === 'string') {
    return (
      <Input
        allowClear
        style={{ width: '100%' }}
        value={input[0] as string}
        onChange={(e) => {
          if (e.target.value === '') {
            onInputChange([]);
          } else {
            onInputChange([e.target.value]);
          }
        }}
        placeholder="请输入"
        disabled={disabled}
      />
    );
  }

  let enumSelectProps = {};
  if (conditionConfigType === 'enum' && originalProp.extendColorMode) {
    const { extendColor, extend } = originalProp;
    enumSelectProps = {
      tagRender: (tagProps) => (
        <span
          className="mr-1 px-2 text-white rounded-full"
          style={{
            backgroundColor: extendColor[extend.findIndex((e: string) => e === tagProps.value)],
          }}
        >
          {tagProps.value}
        </span>
      ),
      options: conditionConfigOptions.map((o, index) => ({
        ...o,
        label: (
          <span
            className="px-2 text-white rounded-full"
            style={{ backgroundColor: extendColor[index] }}
          >
            {o.label}
          </span>
        ),
      })),
    } as {
      tagRender: SelectProps['tagRender'];
      options: SelectProps['options'];
    };
  }

  return getDefaultSelect({
    ...defautlSelectProps,
    maxTagCount,
    options: conditionConfigOptions,
    isMultiple: false,
    isCheckAll: false,
    selectMode: 'multiple',
    placeholder: '请选择',
    ...enumSelectProps,
  });
};

const getDefaultSelect = (props: {
  condition: ApiResponse.ViewList.ViewconditionV2Item;
  selectMode: 'multiple' | undefined;
  disabled: boolean;
  isMultiple: boolean;
  isCheckAll: boolean;
  handleCheckAll: () => void;
  onInputChange: any;
  options: any;
  placeholder?: string;
  input: any;
  optionLabelProp?: SelectProps['optionLabelProp'];
  maxTagCount: SelectProps['maxTagCount'];
  allowClear?: SelectProps['allowClear'];
  userSelectConfig?: any;
  tagRender?: SelectProps['tagRender'];
}) => {
  const {
    condition,
    selectMode,
    disabled,
    isMultiple,
    options,
    isCheckAll,
    handleCheckAll,
    onInputChange,
    placeholder = '请选择',
    input,
    ...restProps
  } = props;

  let normalValue = input;

  if (input === null && selectMode === 'multiple') {
    normalValue = [];
  }

  // 将字符串null处理成null
  const handleValueNullStringToNull = (data: any) => {
    if (!Array.isArray(data)) return data;
    return data.map((d) => {
      if (d === 'null') return null;
      return d;
    });
  };

  return (
    <Select
      disabled={disabled}
      className="filter-value-select w-full"
      placeholder={placeholder}
      {...restProps}
      dropdownRender={(_) => {
        // 渲染全选
        let chooseAll = null;
        if (isMultiple) {
          chooseAll = (
            <div style={{ padding: 6, display: 'flex' }}>
              <Checkbox indeterminate={false} onChange={handleCheckAll} checked={isCheckAll}>
                全选
              </Checkbox>
            </div>
          );
        }

        return (
          <>
            {chooseAll}
            {_}
          </>
        );
      }}
      showSearch // 单选可搜索
      filterOption={(input, option: any) => {
        if (props.userSelectConfig && option.type === 'user')
          return filterUsersByPinyin(
            input,
            { children: option?.filterText },
            props.userSelectConfig,
          );
        const filterText = option?.filterText || option?.label;
        if (!filterText) return false;

        if (option.options) return false;

        return (filterText as string).toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }}
      mode={selectMode}
      maxTagTextLength={7}
      value={normalValue || ''}
      onChange={(e) => {
        const newInput = handleValueNullStringToNull(e);
        onInputChange(Array.isArray(newInput) ? newInput : [newInput]);
      }}
      options={options}
    />
  );
};

// 获取Nodes指定的层级
const getCascadeNodesByLevel = (nodes: any[], level: number, curLevel: number = 1): any[] => {
  if (curLevel > level) return [];
  return nodes.map((x: any) => {
    return {
      ...x,
      children: getCascadeNodesByLevel(x.children, level, curLevel + 1),
    };
  });
};
