import sha512 from 'sha512';

import request from '@/utils/request';

type LoginParams = {
  type: 'account' | 'Captcha';
  password?: string;
  userName?: string;
  token?: string;
  func?: string;
};

export async function login(params: LoginParams) {
  if (params.type === 'account') {
    params.password = sha512(`${params.password}${params.userName}LinkPi20200903!`).toString('hex');
  }
  return request('/api/login/account', {
    method: 'POST',
    data: params,
    credentials: 'same-origin',
  });
}

export async function getCaptcha(mobile: string) {
  return request(`/api/login/captcha?type=login&mobile=${mobile}`, { method: 'POST', data: {} });
}

export async function getRegCaptcha(mobile: string) {
  return request(`/api/login/captcha?type=register&mobile=${mobile}`, { method: 'POST', data: {} });
}

export async function getLoginCaptcha(mobile: string) {
  return request(`/api/login/captcha?type=login&mobile=${mobile}`, { method: 'POST', data: {} });
}

export async function getBindCaptcha(mobile: string) {
  return request(`/api/login/captcha?type=bind&mobile=${mobile}`, { method: 'POST', data: {} });
}

export async function getModifyCaptcha(mobile: string) {
  return request(`/api/login/captcha?type=modify&mobile=${mobile}`, { method: 'POST', data: {} });
}
