import { useControllableValue } from 'ahooks';
import { Popover } from 'antd';
import { type FC } from 'react';

export const LineHeightForView: FC<{
  value?: number;
  onChange?: (v: number) => void;
  isDark?: boolean;
}> = (props) => {
  const [lineHeight, setLineHeight] = useControllableValue(
    {
      defaultValue: 52,
      ...props,
    },
    {
      valuePropName: 'lineHeight',
    },
  );

  return (
    <Popover
      content={
        <ul className="view-setting-list">
          <li key={0}>行高</li>
          <li
            key={1}
            className={`view-setting-item ${lineHeight === 44 ? 'active' : ''}`}
            onClick={() => setLineHeight(44)}
          >
            小
          </li>
          <li
            key={2}
            className={`view-setting-item ${lineHeight === 52 ? 'active' : ''}`}
            onClick={() => setLineHeight(52)}
          >
            默认
          </li>
          <li
            key={3}
            className={`view-setting-item ${lineHeight === 98 ? 'active' : ''}`}
            onClick={() => setLineHeight(98)}
          >
            大
          </li>
        </ul>
      }
      trigger={['click']}
      overlayClassName="view-setting-pop"
      placement={'bottomRight'}
    >
      <div className={`view-setting-block${props.isDark ? ' view-setting-block-dark' : ''}`}>
        <i className={`iconfont icona-View_lineheight`} />
        行高
      </div>
    </Popover>
  );
};
