// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import model_1 from '/home/xufan/build/run_build/src/models/community';
import model_2 from '/home/xufan/build/run_build/src/models/global';
import model_3 from '/home/xufan/build/run_build/src/models/intro';
import model_4 from '/home/xufan/build/run_build/src/models/login';
import model_5 from '/home/xufan/build/run_build/src/models/lpchat';
import model_6 from '/home/xufan/build/run_build/src/models/setting';
import model_7 from '/home/xufan/build/run_build/src/models/space';
import model_8 from '/home/xufan/build/run_build/src/models/task';
import model_9 from '/home/xufan/build/run_build/src/models/user';
import model_10 from '/home/xufan/build/run_build/src/models/workspace';
import model_11 from '/home/xufan/build/run_build/src/pages/account/settings/model';
import model_12 from '/home/xufan/build/run_build/src/pages/user/register/model';

export const models = {
model_1: { namespace: 'community', model: model_1 },
model_2: { namespace: 'global', model: model_2 },
model_3: { namespace: 'intro', model: model_3 },
model_4: { namespace: 'login', model: model_4 },
model_5: { namespace: 'lpchat', model: model_5 },
model_6: { namespace: 'setting', model: model_6 },
model_7: { namespace: 'space', model: model_7 },
model_8: { namespace: 'task', model: model_8 },
model_9: { namespace: 'user', model: model_9 },
model_10: { namespace: 'workspace', model: model_10 },
model_11: { namespace: 'account.settings.model', model: model_11 },
model_12: { namespace: 'user.register.model', model: model_12 },
} as const
