import request from '@/utils/request';

interface IRunAutomation {
  orgId: string;
  autoId: string;
  nodeId: string;
}
export const runAutomation = (payload: IRunAutomation) =>
  request('/docapi/runAutomation', {
    method: 'POST',
    data: {
      org_id: payload.orgId,
      auto_id: payload.autoId,
      node_id: payload.nodeId,
      debug: false,
      wait: true,
    },
  });
