import type { PiGridModules } from '../core';
import type { HeaderCellType, RowType } from '../modules/dataManager';
import type { PositionType } from './_baseComponent';
import GridBaseComponent from './_baseComponent';
import StatisticsRowFunctionCell from './statisticsRowFunctionCell';

class StatisticsRow extends GridBaseComponent {
  public data: RowType<'statisticsRow'>;
  public type: string = 'statisticsRow';

  constructor(
    position: PositionType,
    moduleInstances: PiGridModules,
    data: RowType<'statisticsRow'>,
  ) {
    super(position, moduleInstances);
    this.data = data;
  }

  public click() {}

  public renderFunctionCell(cell: HeaderCellType, fixed: boolean) {
    const currentLeft = fixed ? 0 : this.moduleInstances.HorizontalScrollbar.getScrollLeft();

    const functionCell = new StatisticsRowFunctionCell(
      {
        x: cell.x - currentLeft,
        y: this.position.y,
        width: cell.width,
        height: this.position.height,
      },
      this.moduleInstances,
      { row: this.data, cell },
    );

    functionCell.render();
  }

  public render() {
    const { Draw, DataManager } = this.moduleInstances;

    const styleMode = Draw.config.styleMode;
    const containerBgColor = Draw.config.contentPageGeneralTableConfig?.containerBgColor || '#fff';
    const fixedCells = DataManager.headerCells.slice(2, DataManager.config.freeze + 1);
    const notFixedCells = DataManager.headerCells.slice(DataManager.config.freeze + 1);

    notFixedCells.forEach((cell: HeaderCellType) => {
      this.renderFunctionCell(cell, false);
    });

    fixedCells.forEach((cell: HeaderCellType) => {
      this.renderFunctionCell(cell, true);
    });

    Draw.fillRect(
      this.position.x,
      this.position.y,
      this.position.width,
      this.position.height,
      styleMode === 'darkness' ? containerBgColor : '#fff',
    );
  }
}

export default StatisticsRow;
