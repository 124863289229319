import type { GetterPiNode } from '@linkpi/core/web';
import { usePiNode } from '@linkpi/core/web';
import { CURRENT_NODE_ID_IN_SHAERED_STATE, useWidgetSharedState } from '@mylinkpi/widget-react';
import { useMemo } from 'react';
import { match } from 'ts-pattern';

import type { ISharedStateNodeSetting } from '@/components/PageModelEditor/src/components';
import { useOrgConnection } from '@/hook';

export type UseShareStatePiNodeOption = { currentGetterPinode: GetterPiNode } & Pick<
  ISharedStateNodeSetting,
  'nodeSource'
>;

export const useShareStatePiNode = ({
  nodeSource,
  currentGetterPinode,
}: UseShareStatePiNodeOption) => {
  /**
   * 节点来自于 sharedState
   *
   * TODO 封装 hook
   * 如果其他组件也要添加该功能时，一定要封装
   */
  const [sharedState] = useWidgetSharedState();
  const sharedStateNodeId = sharedState[CURRENT_NODE_ID_IN_SHAERED_STATE] as string;
  const orgConnection = useOrgConnection();
  const [sharedStateGetterPiNode] = usePiNode(orgConnection, sharedStateNodeId);

  const getterPiNode = useMemo(
    () =>
      match(nodeSource)
        .with('sharedState', () => {
          const nodeId = sharedState[CURRENT_NODE_ID_IN_SHAERED_STATE];
          if (!nodeId) return null;

          return sharedStateGetterPiNode;
        })
        .otherwise(() => currentGetterPinode),
    [nodeSource, currentGetterPinode, sharedState, sharedStateGetterPiNode],
  );
  return getterPiNode;
};
