import type { CurrentUser } from '@linkpi/core';
import type { ButtonProps, MenuProps } from 'antd';
import { Space } from 'antd';
import { Menu } from 'antd';
import { Dropdown } from 'antd';
import { head, tail } from 'ramda';
import type { FC } from 'react';
import { useRef } from 'react';

import { useCurrentUser } from '@/hook';
import type { StatusMenuRef } from '@/pages/home/components/StatusMenu/StatusMenu';
import { StatusMenu } from '@/pages/home/components/StatusMenu/StatusMenu';
import { filterFlowSetting, getCurrentFlowSetting } from '@/utils/utils';

import { PiButton } from '../Button';

const StatusItem: FC<{
  flowConfig: CurrentUser.FlowRoute;
}> = ({ flowConfig }) => {
  return <Space align="center">{flowConfig.name}</Space>;
};

interface IStatusSwitchButton extends ButtonProps {
  orgInfo: any;
  node: PiNode;
  onFinish?: () => void;
  checkBeforeChangeStatus: () => boolean;
}
export const StatusSwitchButton: FC<React.PropsWithChildren<IStatusSwitchButton>> = ({
  orgInfo,
  node,
  type = 'primary',
  onFinish,
  checkBeforeChangeStatus,
  ...props
}) => {
  const statusMenuRef = useRef<StatusMenuRef>(null);
  const templateInfo = node.template;
  const currentUser = useCurrentUser();

  const nowFlowSetting = node.template?.task_status?.[node?.tempInfo?.status]?.flowSetting || [];
  const orgId = orgInfo.orgId;

  // 根据条件过滤出状态可转的按钮
  const currentFlowSetting = nowFlowSetting.length
    ? filterFlowSetting(node, orgInfo, currentUser)
    : getCurrentFlowSetting(node, orgInfo, currentUser);

  const setStatus = async (statusIndex: number) => {
    if (!templateInfo || !checkBeforeChangeStatus() || !templateInfo.task_status[statusIndex])
      return;

    await statusMenuRef.current?.setStatus(
      templateInfo.task_status[statusIndex],
      statusIndex,
      '状态转换',
      templateInfo,
    );
    onFinish?.();
  };

  const firstFlow = head(currentFlowSetting);

  const items: MenuProps['items'] = tail(currentFlowSetting).map((f) => ({
    label: <StatusItem flowConfig={f} />,
    key: f.index,
    onClick: () => setStatus(f.index),
  }));

  return (
    <>
      {!!currentFlowSetting?.length &&
        (currentFlowSetting.length > 1 ? (
          <Dropdown.Button
            {...props}
            type={type}
            menu={{ items }}
            onClick={async () => {
              setStatus(firstFlow!.index);
            }}
          >
            <StatusItem flowConfig={firstFlow!} />
          </Dropdown.Button>
        ) : (
          <PiButton
            {...props}
            onClick={async () => {
              setStatus(firstFlow!.index);
            }}
            type={type}
          >
            <StatusItem flowConfig={firstFlow!} />
          </PiButton>
        ))}
      <StatusMenu ref={statusMenuRef} useModal spaceId={orgId} data={node} />
    </>
  );
};
