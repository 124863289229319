import type { EChartOption, EChartsOption, XAXisComponentOption } from 'echarts';

export const TEXT_COLOR = {
  LIGHT: {
    LEGEND_TEXT: '#767C88',
    AXIS_TEXT: '#B6B8B9',
    HEAD_TEXT: '#242D3F',
  },
  DARK: {
    LEGEND_TEXT: '#CACFDE',
    AXIS_TEXT: '#919AB5',
    HEAD_TEXT: '#FFFFFF',
  },
};

//极简模式颜色变化
export const EASY_DEEP_COLOR = [
  '#7547F3',
  '#5065F7',
  '#F8656A',
  '#FF9E37',
  '#17B5B5',
  '#50A6F5',
  '#41C45F',
  '#F66C47',
];

export const EASY_LIGHT_COLOR = [
  '#AD8BFF',
  '#8198FF',
  '#FE878D',
  '#F8CC84',
  '#85EFEF',
  '#74DCFF',
  '#ACF3A0',
  '#FF9879',
];

export const xAxisOption: EChartOption.XAxis = {
  axisLabel: {
    color: '#B6B8B9',
    width: 140,
    overflow: 'truncate',
    interval: 0,
    hideOverlap: true,
  },
  axisLine: { lineStyle: { color: '#F6F6F6' } },
  axisTick: { show: false },
};

export const getBorderRadiusMap = (data: number[]) => {
  const tempData = data.map((x: any) => x.slice(1));
  const brMap = [];

  for (let i = 0; i < tempData.length; i++) {
    const newData = [];
    const curData = tempData[i];
    const exsistValues = curData.filter((y: any) => !!y);
    let start = false;
    for (let j = 0; j < curData.length; j++) {
      const v = curData[j];
      if (!v) {
        newData.push('none');
      } else {
        if (!start) {
          start = true;

          newData.push('none');
          // FIXME: 演示用注释
          // newData.push(exsistValues.length > 1 ? 'bottom' : 'all');
        } else {
          newData.push('none');
          // FIXME: 演示用注释
          // newData.push(exsistValues.length > 1 ? 'none' : 'top');
        }
        exsistValues.shift();
      }
    }

    brMap.push(newData);
  }

  return brMap;
};

export const getNewSeriesData = (
  data: any[],
  type: number | string,
  j: number,
  brMap: any[],
  echarts: any,
) => {
  const tempData: any[] = [];

  if (type == 1 && data.length) {
    for (let i = 0; i < data.length; i++) {
      const brType = brMap[i][j];

      const v = data[i];
      const br =
        brType == 'bottom'
          ? [0, 0, 10, 10]
          : brType == 'top'
          ? [10, 10, 0, 0]
          : brType == 'all'
          ? 10
          : 0;

      tempData.push({
        value: v,
        itemStyle: {
          borderRadius: br,
          color: new echarts.graphic.LinearGradient(1, 0, 0, 1, [
            {
              offset: 0,
              color: EASY_DEEP_COLOR[j % 8],
            },
            {
              offset: 1,
              color: EASY_LIGHT_COLOR[j % 8], //colorOffset[i % colorOffset.length],
            },
          ]),
        },
      });
    }

    return tempData;
  }
  return data;
};
