import { UserOutlined } from '@ant-design/icons';
import type { AvatarProps } from 'antd';
import { Avatar, Tooltip } from 'antd';
import { type FC, memo, useMemo } from 'react';
import { match, P } from 'ts-pattern';

import { useOrgUserMap } from '@/hook';
import { cn, getUserNickName } from '@/utils/utils';

function getFirstCharacter(str: string): string {
  // 使用 Array.from 将字符串转换为一个数组，正确处理 emoji 和其他复合 Unicode 字符
  const characters = Array.from(str);
  // 返回第一个字符，如果字符串为空则返回空字符串
  return characters.length > 0 ? characters[0] : '';
}

type UserAvatarProps = AvatarProps & {
  showName?: boolean;
  tooltip?: boolean;
  className?: string;
} & (
    | {
        src: string;
        nickName?: string;
      }
    | {
        userId: string;
      }
  );

export const UserAvatar: FC<UserAvatarProps> = memo(
  ({ size = 24, showName, tooltip, className, ...restProps }) => {
    const userMap = useOrgUserMap();

    const { name, avatarProps } = useMemo((): {
      name?: string;
      avatarProps: Partial<AvatarProps>;
    } => {
      return match(restProps)
        .with({ userId: P.select() }, (userId) => {
          const userInfo = userMap[userId];
          userInfo;
          const userName = getUserNickName(userInfo);
          return {
            name: userName,
            avatarProps: match(userInfo)
              // 图片头像
              .with({ avatar: P.string }, ({ avatar }) => ({ src: avatar }))
              // 昵称首字
              .with(
                { user_name: P.string },
                {
                  nick_name: P.string,
                },
                { org_nick_name: P.string },
                () => ({ children: getFirstCharacter(userName) }),
              )
              // 默认 icon
              .otherwise(() => ({ icon: <UserOutlined /> })),
          };
        })
        .otherwise(({ nickName, src }) => {
          return { name: nickName, avatarProps: { src } };
        });
    }, [restProps, userMap]);

    const content = (
      <>
        <Avatar {...avatarProps} size={size} />

        {!!showName && <span className="nickname">{name}</span>}
      </>
    );

    return (
      <div className={cn('inline-flex items-center gap-1', className)}>
        {tooltip ? <Tooltip title={name}>{content}</Tooltip> : content}
      </div>
    );
  },
);

export default UserAvatar;
