import { convertPropDefaultValueToPropDefaultFormula } from '@linkpi/core';
import { Date_Format_Options } from '@linkpi/core';
import { Select } from 'antd';
import { defaultTo, isNil } from 'ramda';
import type { FC } from 'react';
import { useMemo } from 'react';

import type { PropOption } from '@/pages/space/components/TemplateProp/components/TempPropModal/types';
import { extractInput } from '@/utils/formulaExtract';

import { useTempPropConfig } from '../hooks';

interface IDateType {
  tempProps: PropOption[];
}

export const DateType: FC<IDateType> = (props) => {
  const { tempProps } = props;

  const { isLock, propParams, setPropParams, savePropParams } = useTempPropConfig();

  const defaultOptionGroups = useMemo(() => {
    return [
      {
        label: '系统属性',
        key: 'sys',
        options: [{ label: '创建时间', value: 'createTime' }],
      },
      {
        label: '状态属性',
        key: 'status',
        options: [
          { label: '开始时间', value: 'status-2' },
          { label: '结束时间', value: 'status-3' },
        ],
      },
      {
        label: '自定义属性',
        key: 'prop',
        options: tempProps
          .filter((p) => p.type === 'datetime' || p.type === 'date')
          .map((p) => ({
            label: p.name,
            value: 'prop-' + p.index,
          })),
      },
    ];
  }, [tempProps]);

  const defaultValue = useMemo(() => {
    if (isNil(propParams.defaultFormula)) {
      if (propParams.defaultValue) return propParams.defaultValue;
      return undefined;
    }

    const { defaultFormula } = propParams;
    if (defaultFormula.type === 'formula') {
      const input = extractInput(defaultFormula);

      if (input?.type === 'create_time') return 'createTime';
      else if (input?.type === 's_prop') return `status-${input.prop}`;
    } else if (defaultFormula.type === 'prop') {
      return `prop-${defaultFormula.prop}`;
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propParams.defaultFormula]);

  return (
    <>
      <div className={'prop-item'} key={'dateFormat'}>
        <div className={'prop-item-label'}>日期格式</div>
        <div className={'prop-item-input'}>
          <Select
            disabled={isLock}
            placeholder="请选择日期格式"
            options={Date_Format_Options}
            value={propParams.dateFormat}
            onChange={(e) => setPropParams({ ...propParams, dateFormat: e })}
          />
        </div>
      </div>
      <div className={'prop-item'} key={'defaultValue'}>
        <div className={'prop-item-label'}>默认值</div>
        <div className={'prop-item-input'}>
          <Select
            disabled={isLock}
            placeholder="请选择"
            options={defaultOptionGroups}
            value={defaultValue}
            onChange={(e: string) => {
              if (isNil(e)) {
                savePropParams(null, 'defaultValue');
                savePropParams(null, 'defaultFormula');
                return;
              }

              savePropParams(defaultTo(null)(e), 'defaultValue');
              const defaultFormula = convertPropDefaultValueToPropDefaultFormula(e);
              savePropParams(defaultFormula, 'defaultFormula');
            }}
            allowClear={true}
          />
        </div>
      </div>
    </>
  );
};
