import { createFromIconfontCN } from '@ant-design/icons';
import type { IconFontProps } from '@ant-design/icons/lib/components/IconFont';
import type { FC } from 'react';

import '@/assets/font/iconfont';

export const IconFont = createFromIconfontCN({
  scriptUrl: '',
});

export const RegularIcon: FC<
  IconFontProps & {
    color?: React.CSSProperties['color'];
  }
> = (props) => {
  const { type, size, color, className, onClick } = props;

  return (
    <IconFont
      className={className}
      style={{ fontSize: size == null ? '18px' : size, color: color }}
      type={type}
      onClick={onClick}
    />
  );
};

type StatusIconPropType = {
  size?: number;
  data: {
    status: 'normal' | 'action';
  };
};
export const StatusIcon: FC<StatusIconPropType> = (props) => {
  const { data, size } = props;

  const type =
    data.status === 'normal'
      ? 'iconlinggan'
      : data.status === 'action'
      ? 'iconzhunweixingdong'
      : 'iconwanchengxingdong';
  return <RegularIcon size={size} type={type} />;
};
