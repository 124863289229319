import type { OrgGroups } from '@linkpi/core';
import { useSelector } from '@umijs/max';
import { useMemo } from 'react';

export function useOrgUserGroup(): OrgGroups.OrgGroupsItem[] {
  const groups = useSelector((state: any) => state.space?.groups || []);

  return groups;
}

export function useOrgUserGroupOptions() {
  const groups = useOrgUserGroup();
  const groupOptions = useMemo(() => {
    return groups
      .filter((i) => i.group_id !== '-1' && i.group_id !== '-8')
      .map((i) => ({
        label: i.group_name,
        value: i.group_id,
      }));
  }, [groups]);

  return groupOptions;
}

export function useOrgDepartment(options?: {
  orgId?: string;
  update?: number;
}): OrgGroups.OrgGroupsItem[] {
  const departments = useSelector((state: any) => state.space?.orgStruct || []);

  return departments;
}
