import type { CurrentUser } from '@linkpi/core';
import imageExtensions from '@mylinkpi/image-extensions';
import type { FormInstance, FormProps, UploadFile } from 'antd';
import { Button, Divider, Form, Input, message, Modal, Spin, Upload } from 'antd';
import type { UploadChangeParam } from 'antd/lib/upload';
import moment from 'moment';
import type { FC, ReactNode } from 'react';
import { isValidElement, useEffect, useState } from 'react';

import { previewImages } from '@/components';
import CascadeSelect from '@/components/CascadeSelect';
import IconPark from '@/components/IconPark';

import type { StatusItemType } from '../TempStatus';

import './index.less';
import styles from './index.module.less';

const { TextArea } = Input;

function isValidJSXElement(v: unknown): v is JSX.Element {
  return isValidElement(v);
}

type StatusModalProp = {
  modalTitle: string | ReactNode;
  scheduleShow: boolean;
  onCancel: (args: any) => void;
  onSubmit: (...args: any[]) => Promise<void>;
  scheduleLoading: boolean;
  formItems: StatusItemType[];
  editForm: FormInstance;
  node: PiNode;
  transformType: string;
  orgId: string;
  routeConfig?: CurrentUser.FlowRoute;
};
const StatusModal: FC<StatusModalProp> = (props) => {
  const {
    modalTitle,
    scheduleShow,
    onCancel,
    onSubmit,
    scheduleLoading,
    formItems,
    editForm,
    node,
    transformType,
    orgId,
    routeConfig,
  } = props;

  const [oldStart, setOldStart] = useState(
    transformType === '状态转换' ? moment.now().valueOf() : node.prop?._sys_task_status?.prop[2],
  );
  const [oldEnd, setOldEnd] = useState(
    transformType === '状态转换' ? null : node.prop?._sys_task_status?.prop[3],
  );

  const getAttachmentValue = (node?: PiNode, index: number = 0) => {
    const fileNames = node?.tempInfo?.prop?.[index] || [];
    const _sys_attach = (node?.prop || {})._sys_attach || {};
    return (Array.isArray(_sys_attach[index]) ? _sys_attach[index] : []).map((src, j) => ({
      url: src,
      name: fileNames[j] || '未知文件名',
      status: 'done',
      uid: j,
      exist: true, // 表明这是之前的文件不用再上传了
    }));
  };
  type AttachmentValue = ReturnType<typeof getAttachmentValue>;

  const [fileMap, setFileMap] = useState<Record<string, AttachmentValue>>({});
  const [cascadeValue, setCascadeValue] = useState({});

  const onFileChange = ({ fileList: newFileList }: UploadChangeParam, index: number) => {
    setFileMap({
      ...fileMap,
      [`${index}`]: newFileList.map((f) => ({ ...f, status: 'done' })),
    } as typeof fileMap);
  };

  const changeCascadeValue = (cValue: unknown, index: number) => {
    editForm.setFieldsValue({
      [`tempProp${index}`]: cValue,
    });
    setCascadeValue({
      ...cascadeValue,
      [`${index}`]: cValue,
    });
  };

  useEffect(() => {
    const attachments = formItems.filter((f) => !isValidJSXElement(f) && f.attachment);
    if (!attachments.length) {
      setFileMap({});
      return;
    }

    const map = attachments.reduce(
      (res, cur) => {
        if (!isValidJSXElement(cur)) res[cur.index] = getAttachmentValue(cur.node!, cur.index);
        return res;
      },
      {} as typeof fileMap,
    );
    setFileMap(map);
  }, [formItems]);

  useEffect(() => {
    setOldStart(
      transformType === '状态转换' ? moment.now().valueOf() : node.prop?._sys_task_status?.prop[2],
    );
    setOldEnd(transformType === '状态转换' ? null : node.prop?._sys_task_status?.prop[3]);
  }, [node, scheduleShow, scheduleLoading, transformType]);

  const cancel = async (i: number, _v: number) => {
    if (i === 2) {
      editForm.setFieldsValue({
        prop3: moment(_v).add(1, 'hours'),
      });
      setOldEnd(moment(_v).add(1, 'hours').valueOf());
    }

    if (i === 2.5) {
      editForm.setFieldsValue({
        prop3: moment(oldEnd),
      });
      setOldEnd(oldEnd);
    }

    if (i === 3) {
      editForm.setFieldsValue({
        prop2: moment(_v).subtract(1, 'hours'),
      });
      setOldStart(moment(_v).subtract(1, 'hours').valueOf());
    }

    message.destroy();
    message.success('已取消');
  };

  const fieldChange: FormProps['onFieldsChange'] = (arr) => {
    if (!editForm) return;
    if (arr.length === 0) return;
    const index = arr[0].name[0].slice('prop'.length);
    if (index !== '2' && index !== '3') return;

    // 已保存的时间差
    const diff = oldStart && oldEnd ? moment(oldEnd).diff(moment(oldStart)) : null;
    const now: number | null = arr[0].value ? arr[0].value.valueOf() : null;

    if (transformType === '状态转换') {
      if (index === '2') {
        if (oldEnd && now >= oldEnd) {
          editForm.setFieldsValue({
            prop3: moment(now).add(1, 'hours'),
          });

          setOldEnd(moment(now).add(1, 'hours').valueOf());
        }

        setOldStart(now);
      }

      if (index === '3') {
        setOldEnd(now);
        if (now === null) return;
        if (oldStart && oldStart >= now) {
          editForm.setFieldsValue({
            prop2: moment(now).subtract(1, 'hours'),
          });

          setOldStart(moment(now).subtract(1, 'hours').valueOf());
        }
      }
    } else {
      if (index === '3') {
        setOldEnd(now);
        if (now === null) return;
        // 结束时间早于开始时间
        if (diff && now <= oldStart) {
          editForm.setFieldsValue({
            prop2: moment(now).subtract(diff),
          });
          setOldStart(moment(now).subtract(diff).valueOf());

          // 取消顺延
          message.info(
            <>
              <span>开始时间已自动提前</span>
              <Button type="link" onClick={() => cancel(3, now)} children="取消本次提前" />
            </>,
          );
        }

        if (!oldEnd && now <= oldStart) {
          message.warning('结束时间必须大于开始时间');
          editForm.setFieldsValue({
            prop3: null,
          });
          return;
        }
      }

      if (index === '2') {
        // 开始时间晚于上次结束时间
        if (diff && now >= oldEnd) {
          editForm.setFieldsValue({
            prop3: moment(now).add(diff),
          });
          setOldEnd(moment(now).add(diff).valueOf());

          // 取消顺延
          message.info(
            <>
              <span>结束时间已自动顺延</span>
              <Button type="link" onClick={() => cancel(2, now)} children="取消本次顺延" />
            </>,
          );
        }

        // 开始时间早于上次结束时间，但晚于上次开始时间
        if (diff && now < oldEnd && now > oldStart) {
          editForm.setFieldsValue({
            prop3: moment(now).add(diff),
          });
          setOldEnd(moment(now).add(diff).valueOf());

          // 取消顺延
          message.info(
            <>
              <span>结束时间已自动顺延</span>
              <Button type="link" onClick={() => cancel(2.5, now)} children="取消本次顺延" />
            </>,
          );
        }

        // 开始时间早于上次设置的时间
        if (diff && now < oldStart) {
          editForm.setFieldsValue({
            prop3: moment(now).add(diff),
          });
          setOldEnd(moment(now).add(diff).valueOf());

          // 取消顺延
          message.info(
            <>
              <span>结束时间已自动提前</span>
              <Button type="link" onClick={() => cancel(2.5, now)} children="取消本次提前" />
            </>,
          );
        }

        setOldStart(now);
      }
    }
  };

  const delAttachment = (file: AttachmentValue[number], index: number) => {
    setFileMap({
      ...fileMap,
      [`${index}`]: fileMap[index].filter((f) => f.uid !== file.uid),
    });
  };

  const showImg = (file: UploadFile) => {
    const src = file.url || (file.thumbUrl as string);
    previewImages({
      images: [{ src, alt: '' }],
      noNavbar: true,
      changeable: false,
      attribute: false,
      scalable: false,
      noClose: true,
    });
  };

  const uploadItemRender = (file: UploadFile, index: number) => {
    const ary = file.name.split('.');
    const ext = ary[ary.length - 1];
    let item = (
      <img height={28} width={28} src="https://www.mylinkpi.com/icon/unknown file.png" alt="" />
    );
    if (imageExtensions.includes(ext)) {
      const backgroundImage = `url(${file.url || file.thumbUrl})`;
      item = <div className="uploadItem" style={{ backgroundImage }} />;
    }

    return (
      <div className="uploadItemContainer">
        {item}
        <div className="mask">
          <i onClick={() => delAttachment(file, index)} className="icondelete iconfont" />
          {imageExtensions.includes(ext) && (
            <i onClick={() => showImg(file)} className="iconfont icontable_open" />
          )}
        </div>
      </div>
    );
  };

  return (
    <Modal
      title={modalTitle}
      width={548}
      bodyStyle={{ padding: '31px 40px 0 24px', maxHeight: 400, overflowY: 'auto' }}
      destroyOnClose
      wrapClassName="status-modal"
      open={scheduleShow}
      centered
      onCancel={onCancel}
      onOk={(_) => editForm.submit()}
      okButtonProps={{ loading: scheduleLoading }}
    >
      <Spin spinning={scheduleLoading}>
        <Form
          className={styles.form}
          form={editForm}
          labelAlign="left"
          labelCol={{ span: 5 }}
          wrapperCol={{ span: 19 }}
          layout="horizontal"
          colon={false}
          onFinish={(e) => onSubmit(e, fileMap, cascadeValue)}
          onFieldsChange={fieldChange}
        >
          {routeConfig?.transType?.commit && [
            <Form.Item
              key="commit"
              label="审批意见"
              name="commit"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <TextArea placeholder="请输入..." />
            </Form.Item>,
            <Divider dashed key="Divider" />,
          ]}
          {formItems.map((comp) => {
            if (isValidJSXElement(comp)) return comp;

            if (comp.attachment)
              return (
                <Form.Item name={comp.name} {...comp.formOption} rules={comp.rules}>
                  <Upload
                    listType="picture-card"
                    fileList={fileMap[comp.index] || []}
                    onChange={(e) => onFileChange(e, comp.index)}
                    customRequest={() => {}}
                    itemRender={(n, file) => uploadItemRender(file, comp.index)}
                  >
                    <IconPark type="tianjia" style={{ fontSize: 14, color: '#6a717c' }} />
                  </Upload>
                </Form.Item>
              );
            if (comp.cascade) {
              const p = comp.node!.template.prop[comp.index];
              return (
                <Form.Item name={comp.name} {...comp.formOption} rules={comp.rules}>
                  <CascadeSelect
                    prop={p}
                    defaultValue={comp.node!.tempInfo.prop[comp.index]}
                    multiple={p.multiple}
                    changeOnSelect={p.changeOnSelect}
                    template={comp.node!.template}
                    propIndex={comp.index}
                    orgId={orgId}
                    handleChange={(cValue) => changeCascadeValue(cValue, comp.index)}
                  />
                </Form.Item>
              );
            }
            return comp;
          })}
        </Form>
      </Spin>
    </Modal>
  );
};

export default StatusModal;
