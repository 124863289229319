import type { PrimitiveAtom } from 'jotai';
import { atom, useAtom } from 'jotai';
import { isNil } from 'lodash';
import { useEffect } from 'react';

// const vAtom=  atom(1)
const atomMap = new Map();
export function useAtomized<T>(key: string, _v?: T): T | undefined;
export function useAtomized<T>(key: string, _v?: T, defaultValue?: T): T;
export function useAtomized<T>(key: string, _v?: T, defaultValue?: T): T | undefined {
  let vAtom: PrimitiveAtom<T>;
  if (!atomMap.get(key)) {
    const _atom = atom(_v);
    atomMap.set(key, _atom);
    _atom.debugLabel = key;
  }
  // eslint-disable-next-line prefer-const
  vAtom = atomMap.get(key);

  const [v, setV] = useAtom(vAtom);
  useEffect(() => {
    if (isNil(_v)) return;

    setV(_v);
  }, [_v, setV]);

  return v ?? defaultValue;
}
