export const checkboxConfig = {
  size: 16,
  radius: 4,
  borderColor: '#d9d9d9',
  bg: '#316EF5',
};

export const sortTriangleConfig = {
  width: 6,
  height: 5,
  bg: '#ddd',
  selectBg: '#0083fa',
  containerHeight: 15,
};

export const menuConfig = {
  containerWidth: 12,
  containerHeight: 14,
};

export const statisticsLabels = {
  count: { label: '条数', key: 'count' },
  sum: { label: '求和', key: 'sum' },
  ave: { label: '平均值', key: 'ave' },
  max: { label: '最大值', key: 'max' },
  min: { label: '最小值', key: 'min' },
  uniqueCount: { label: '去重计数', key: 'uniqueCount' },
  notEmptyUniqueCount: { label: '非空去重计数', key: 'notEmptyUniqueCount' },
  enumUniqueCount: { label: '选值去重计数', key: 'enumUniqueCount' },
};

export const openIconConfig = {
  height: 30,
  width: 30,
};

export const resizerConfig = {
  width: 10,
};
